







import { Component, Vue } from 'nuxt-property-decorator'
import ElfsightWidget from '~/components/ElfsightWidget.vue'

@Component({
  components: {
    ElfsightWidget
  }
})
export default class BlankLayout extends Vue {
  get isAppHelpPage () {
    return (this.$route.path.includes('/app-help'))
  }
}

