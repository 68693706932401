import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2b209ba0&scoped=true&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=2b209ba0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b209ba0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentHeader: require('/var/www/dockerize-nuxt/agora-app/components/content/Header.vue').default,ElfsightWidget: require('/var/www/dockerize-nuxt/agora-app/components/ElfsightWidget.vue').default})
