import { Auth } from '@nuxtjs/auth-next'
import { AxiosError } from 'axios'

// { $auth, redirect, $axios, store, route } : {[k: string] : any}
export default function ({ $auth, redirect, $axios, store, route } : {[k: string] : any}) {
  ($auth as Auth).onError((error: any) => {
    const axiosError : AxiosError = error
    if (axiosError.response?.status === 401) {
      // ($auth as Auth).login()
      ($auth as Auth).logout()
    }
  })
  $axios.onError((error: any) => {
    if (error.response.status === 401) {
      store.commit('setAuthentication', { authenticated: false, user: null })
      redirect('/sign-in')
    }
  })
}
