export const AddressInput = () => import('../../components/AddressInput.vue' /* webpackChunkName: "components/address-input" */).then(c => wrapFunctional(c.default || c))
export const BadgeIcon = () => import('../../components/BadgeIcon.vue' /* webpackChunkName: "components/badge-icon" */).then(c => wrapFunctional(c.default || c))
export const ElfsightWidget = () => import('../../components/ElfsightWidget.vue' /* webpackChunkName: "components/elfsight-widget" */).then(c => wrapFunctional(c.default || c))
export const FlyoutMenu = () => import('../../components/FlyoutMenu.vue' /* webpackChunkName: "components/flyout-menu" */).then(c => wrapFunctional(c.default || c))
export const MultiSelect = () => import('../../components/MultiSelect.vue' /* webpackChunkName: "components/multi-select" */).then(c => wrapFunctional(c.default || c))
export const MyLoading = () => import('../../components/MyLoading.vue' /* webpackChunkName: "components/my-loading" */).then(c => wrapFunctional(c.default || c))
export const NotifyEmailInput = () => import('../../components/NotifyEmailInput.vue' /* webpackChunkName: "components/notify-email-input" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const Rating = () => import('../../components/Rating.vue' /* webpackChunkName: "components/rating" */).then(c => wrapFunctional(c.default || c))
export const AfterSchoolCareFeature = () => import('../../components/after-school-care/AfterSchoolCareFeature.vue' /* webpackChunkName: "components/after-school-care-feature" */).then(c => wrapFunctional(c.default || c))
export const AfterSchoolCareFeature2 = () => import('../../components/after-school-care/AfterSchoolCareFeature2.vue' /* webpackChunkName: "components/after-school-care-feature2" */).then(c => wrapFunctional(c.default || c))
export const AfterSchoolCareHero = () => import('../../components/after-school-care/AfterSchoolCareHero.vue' /* webpackChunkName: "components/after-school-care-hero" */).then(c => wrapFunctional(c.default || c))
export const AfterSchoolCareTestimonial = () => import('../../components/after-school-care/AfterSchoolCareTestimonial.vue' /* webpackChunkName: "components/after-school-care-testimonial" */).then(c => wrapFunctional(c.default || c))
export const AfterSchoolCareTestimonialOverlapImage = () => import('../../components/after-school-care/AfterSchoolCareTestimonialOverlapImage.vue' /* webpackChunkName: "components/after-school-care-testimonial-overlap-image" */).then(c => wrapFunctional(c.default || c))
export const AgoraSubscription = () => import('../../components/agora-care/AgoraSubscription.vue' /* webpackChunkName: "components/agora-subscription" */).then(c => wrapFunctional(c.default || c))
export const AgoraCareCancelSubscription = () => import('../../components/agora-care/CancelSubscription.vue' /* webpackChunkName: "components/agora-care-cancel-subscription" */).then(c => wrapFunctional(c.default || c))
export const AgoraCareTable = () => import('../../components/agora-care/Table.vue' /* webpackChunkName: "components/agora-care-table" */).then(c => wrapFunctional(c.default || c))
export const AtSchoolBrowseCourseDropdown = () => import('../../components/at-school/BrowseCourseDropdown.vue' /* webpackChunkName: "components/at-school-browse-course-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AtSchoolBrowseCourseFilter = () => import('../../components/at-school/BrowseCourseFilter.vue' /* webpackChunkName: "components/at-school-browse-course-filter" */).then(c => wrapFunctional(c.default || c))
export const AtSchoolBrowseCoursesHeader = () => import('../../components/at-school/BrowseCoursesHeader.vue' /* webpackChunkName: "components/at-school-browse-courses-header" */).then(c => wrapFunctional(c.default || c))
export const BlogNewAuthor = () => import('../../components/blog-new/Author.vue' /* webpackChunkName: "components/blog-new-author" */).then(c => wrapFunctional(c.default || c))
export const BlogNewContact = () => import('../../components/blog-new/Contact.vue' /* webpackChunkName: "components/blog-new-contact" */).then(c => wrapFunctional(c.default || c))
export const BlogNewPostItem = () => import('../../components/blog-new/PostItem.vue' /* webpackChunkName: "components/blog-new-post-item" */).then(c => wrapFunctional(c.default || c))
export const BlogNewPostList = () => import('../../components/blog-new/PostList.vue' /* webpackChunkName: "components/blog-new-post-list" */).then(c => wrapFunctional(c.default || c))
export const BlogNewTopBanner = () => import('../../components/blog-new/TopBanner.vue' /* webpackChunkName: "components/blog-new-top-banner" */).then(c => wrapFunctional(c.default || c))
export const BlogNewDetailMoreLikeThis = () => import('../../components/blog-new-detail/MoreLikeThis.vue' /* webpackChunkName: "components/blog-new-detail-more-like-this" */).then(c => wrapFunctional(c.default || c))
export const BlogNewDetailPostContent = () => import('../../components/blog-new-detail/PostContent.vue' /* webpackChunkName: "components/blog-new-detail-post-content" */).then(c => wrapFunctional(c.default || c))
export const BlogNewDetailTopBanner = () => import('../../components/blog-new-detail/TopBanner.vue' /* webpackChunkName: "components/blog-new-detail-top-banner" */).then(c => wrapFunctional(c.default || c))
export const CampaignsCampaignCard = () => import('../../components/campaigns/CampaignCard.vue' /* webpackChunkName: "components/campaigns-campaign-card" */).then(c => wrapFunctional(c.default || c))
export const CampaignsCampaignShowcasePanel = () => import('../../components/campaigns/CampaignShowcasePanel.vue' /* webpackChunkName: "components/campaigns-campaign-showcase-panel" */).then(c => wrapFunctional(c.default || c))
export const CampaignsNotifyCampaign = () => import('../../components/campaigns/NotifyCampaign.vue' /* webpackChunkName: "components/campaigns-notify-campaign" */).then(c => wrapFunctional(c.default || c))
export const CampaignsPromoBannerOld = () => import('../../components/campaigns/PromoBannerOld.vue' /* webpackChunkName: "components/campaigns-promo-banner-old" */).then(c => wrapFunctional(c.default || c))
export const CampaignsYellowPanel = () => import('../../components/campaigns/YellowPanel.vue' /* webpackChunkName: "components/campaigns-yellow-panel" */).then(c => wrapFunctional(c.default || c))
export const CarouselList = () => import('../../components/carousel/list.vue' /* webpackChunkName: "components/carousel-list" */).then(c => wrapFunctional(c.default || c))
export const ContentAgoraIndexPage = () => import('../../components/content/AgoraIndexPage.vue' /* webpackChunkName: "components/content-agora-index-page" */).then(c => wrapFunctional(c.default || c))
export const ContentAtSchoolIndexPage = () => import('../../components/content/AtSchoolIndexPage.vue' /* webpackChunkName: "components/content-at-school-index-page" */).then(c => wrapFunctional(c.default || c))
export const ContentEducationProviders = () => import('../../components/content/EducationProviders.vue' /* webpackChunkName: "components/content-education-providers" */).then(c => wrapFunctional(c.default || c))
export const ContentEducationProvidersList = () => import('../../components/content/EducationProvidersList.vue' /* webpackChunkName: "components/content-education-providers-list" */).then(c => wrapFunctional(c.default || c))
export const ContentFacilities = () => import('../../components/content/Facilities.vue' /* webpackChunkName: "components/content-facilities" */).then(c => wrapFunctional(c.default || c))
export const ContentFeature = () => import('../../components/content/Feature.vue' /* webpackChunkName: "components/content-feature" */).then(c => wrapFunctional(c.default || c))
export const ContentFeedbacks = () => import('../../components/content/Feedbacks.vue' /* webpackChunkName: "components/content-feedbacks" */).then(c => wrapFunctional(c.default || c))
export const ContentGetNotifiedEmail = () => import('../../components/content/GetNotifiedEmail.vue' /* webpackChunkName: "components/content-get-notified-email" */).then(c => wrapFunctional(c.default || c))
export const ContentHeader = () => import('../../components/content/Header.vue' /* webpackChunkName: "components/content-header" */).then(c => wrapFunctional(c.default || c))
export const ContentHomepageHeroRework = () => import('../../components/content/HomepageHeroRework.vue' /* webpackChunkName: "components/content-homepage-hero-rework" */).then(c => wrapFunctional(c.default || c))
export const ContentLandingMessage = () => import('../../components/content/LandingMessage.vue' /* webpackChunkName: "components/content-landing-message" */).then(c => wrapFunctional(c.default || c))
export const ContentLocation = () => import('../../components/content/Location.vue' /* webpackChunkName: "components/content-location" */).then(c => wrapFunctional(c.default || c))
export const ContentOverview = () => import('../../components/content/Overview.vue' /* webpackChunkName: "components/content-overview" */).then(c => wrapFunctional(c.default || c))
export const ContentSlider = () => import('../../components/content/Slider.vue' /* webpackChunkName: "components/content-slider" */).then(c => wrapFunctional(c.default || c))
export const ContentSubjects = () => import('../../components/content/Subjects.vue' /* webpackChunkName: "components/content-subjects" */).then(c => wrapFunctional(c.default || c))
export const ContentTheSpace3D = () => import('../../components/content/TheSpace3D.vue' /* webpackChunkName: "components/content-the-space3-d" */).then(c => wrapFunctional(c.default || c))
export const ContentTickerTape = () => import('../../components/content/TickerTape.vue' /* webpackChunkName: "components/content-ticker-tape" */).then(c => wrapFunctional(c.default || c))
export const CourseDetailContent = () => import('../../components/course-detail/CourseDetailContent.vue' /* webpackChunkName: "components/course-detail-content" */).then(c => wrapFunctional(c.default || c))
export const CourseDetail = () => import('../../components/course-detail/index.ts' /* webpackChunkName: "components/course-detail" */).then(c => wrapFunctional(c.default || c))
export const CoursesAlertCalendarCourseLogin = () => import('../../components/courses/AlertCalendarCourseLogin.vue' /* webpackChunkName: "components/courses-alert-calendar-course-login" */).then(c => wrapFunctional(c.default || c))
export const CoursesCalendarCourses = () => import('../../components/courses/CalendarCourses.vue' /* webpackChunkName: "components/courses-calendar-courses" */).then(c => wrapFunctional(c.default || c))
export const CoursesCard = () => import('../../components/courses/Card.vue' /* webpackChunkName: "components/courses-card" */).then(c => wrapFunctional(c.default || c))
export const CoursesCheckboxItem = () => import('../../components/courses/CheckboxItem.vue' /* webpackChunkName: "components/courses-checkbox-item" */).then(c => wrapFunctional(c.default || c))
export const CoursesCourseCardNew = () => import('../../components/courses/CourseCardNew.vue' /* webpackChunkName: "components/courses-course-card-new" */).then(c => wrapFunctional(c.default || c))
export const CoursesCourseFilterMobile = () => import('../../components/courses/CourseFilterMobile.vue' /* webpackChunkName: "components/courses-course-filter-mobile" */).then(c => wrapFunctional(c.default || c))
export const CoursesCourseItem = () => import('../../components/courses/CourseItem.vue' /* webpackChunkName: "components/courses-course-item" */).then(c => wrapFunctional(c.default || c))
export const CoursesCourseListNew = () => import('../../components/courses/CourseListNew.vue' /* webpackChunkName: "components/courses-course-list-new" */).then(c => wrapFunctional(c.default || c))
export const CoursesDayOfWeek = () => import('../../components/courses/DayOfWeek.vue' /* webpackChunkName: "components/courses-day-of-week" */).then(c => wrapFunctional(c.default || c))
export const CoursesDropDown = () => import('../../components/courses/DropDown.vue' /* webpackChunkName: "components/courses-drop-down" */).then(c => wrapFunctional(c.default || c))
export const CoursesFilterButton = () => import('../../components/courses/FilterButton.vue' /* webpackChunkName: "components/courses-filter-button" */).then(c => wrapFunctional(c.default || c))
export const CoursesLearnerAgeSwitch = () => import('../../components/courses/LearnerAgeSwitch.vue' /* webpackChunkName: "components/courses-learner-age-switch" */).then(c => wrapFunctional(c.default || c))
export const CoursesProviderDetail = () => import('../../components/courses/ProviderDetail.vue' /* webpackChunkName: "components/courses-provider-detail" */).then(c => wrapFunctional(c.default || c))
export const CoursesResetFilterButton = () => import('../../components/courses/ResetFilterButton.vue' /* webpackChunkName: "components/courses-reset-filter-button" */).then(c => wrapFunctional(c.default || c))
export const CoursesSessionPackList = () => import('../../components/courses/SessionPackList.vue' /* webpackChunkName: "components/courses-session-pack-list" */).then(c => wrapFunctional(c.default || c))
export const CoursesTimeSelect = () => import('../../components/courses/TimeSelect.vue' /* webpackChunkName: "components/courses-time-select" */).then(c => wrapFunctional(c.default || c))
export const CoursesTimeSelectButton = () => import('../../components/courses/TimeSelectButton.vue' /* webpackChunkName: "components/courses-time-select-button" */).then(c => wrapFunctional(c.default || c))
export const CoursesTrialAvailableButton = () => import('../../components/courses/TrialAvailableButton.vue' /* webpackChunkName: "components/courses-trial-available-button" */).then(c => wrapFunctional(c.default || c))
export const CurriculumContent = () => import('../../components/curriculum/CurriculumContent.vue' /* webpackChunkName: "components/curriculum-content" */).then(c => wrapFunctional(c.default || c))
export const CurriculumContentBk = () => import('../../components/curriculum/CurriculumContentBk.vue' /* webpackChunkName: "components/curriculum-content-bk" */).then(c => wrapFunctional(c.default || c))
export const CurriculumHero = () => import('../../components/curriculum/CurriculumHero.vue' /* webpackChunkName: "components/curriculum-hero" */).then(c => wrapFunctional(c.default || c))
export const CurriculumTopBanner = () => import('../../components/curriculum/CurriculumTopBanner.vue' /* webpackChunkName: "components/curriculum-top-banner" */).then(c => wrapFunctional(c.default || c))
export const EarlyYearFeature = () => import('../../components/early-year/EarlyYearFeature.vue' /* webpackChunkName: "components/early-year-feature" */).then(c => wrapFunctional(c.default || c))
export const EarlyYearFeature2 = () => import('../../components/early-year/EarlyYearFeature2.vue' /* webpackChunkName: "components/early-year-feature2" */).then(c => wrapFunctional(c.default || c))
export const EarlyYearHero = () => import('../../components/early-year/EarlyYearHero.vue' /* webpackChunkName: "components/early-year-hero" */).then(c => wrapFunctional(c.default || c))
export const EarlyYearTestimonial = () => import('../../components/early-year/EarlyYearTestimonial.vue' /* webpackChunkName: "components/early-year-testimonial" */).then(c => wrapFunctional(c.default || c))
export const EarlyYearTestimonialOverlapImage = () => import('../../components/early-year/EarlyYearTestimonialOverlapImage.vue' /* webpackChunkName: "components/early-year-testimonial-overlap-image" */).then(c => wrapFunctional(c.default || c))
export const EarlyYearsPricingHeader = () => import('../../components/early-years/EarlyYearsPricingHeader.vue' /* webpackChunkName: "components/early-years-pricing-header" */).then(c => wrapFunctional(c.default || c))
export const EarlyYearsPlayschoolContent = () => import('../../components/early-years/PlayschoolContent.vue' /* webpackChunkName: "components/early-years-playschool-content" */).then(c => wrapFunctional(c.default || c))
export const EarlyYearsPlayschoolHeader = () => import('../../components/early-years/PlayschoolHeader.vue' /* webpackChunkName: "components/early-years-playschool-header" */).then(c => wrapFunctional(c.default || c))
export const EarlyYearsPricingTable = () => import('../../components/early-years/PricingTable.vue' /* webpackChunkName: "components/early-years-pricing-table" */).then(c => wrapFunctional(c.default || c))
export const EarlyYearsPricingTableRework = () => import('../../components/early-years/PricingTableRework.vue' /* webpackChunkName: "components/early-years-pricing-table-rework" */).then(c => wrapFunctional(c.default || c))
export const EarlyYearsCurriculumContent = () => import('../../components/early-years-curriculum/CurriculumContent.vue' /* webpackChunkName: "components/early-years-curriculum-content" */).then(c => wrapFunctional(c.default || c))
export const EarlyYearsCurriculumTopBanner = () => import('../../components/early-years-curriculum/CurriculumTopBanner.vue' /* webpackChunkName: "components/early-years-curriculum-top-banner" */).then(c => wrapFunctional(c.default || c))
export const EventHostingFAQItem = () => import('../../components/event-hosting/FAQItem.vue' /* webpackChunkName: "components/event-hosting-f-a-q-item" */).then(c => wrapFunctional(c.default || c))
export const EventHostingFAQs = () => import('../../components/event-hosting/FAQs.vue' /* webpackChunkName: "components/event-hosting-f-a-qs" */).then(c => wrapFunctional(c.default || c))
export const EventHostingFeatures = () => import('../../components/event-hosting/Features.vue' /* webpackChunkName: "components/event-hosting-features" */).then(c => wrapFunctional(c.default || c))
export const EventHostingHeader = () => import('../../components/event-hosting/Header.vue' /* webpackChunkName: "components/event-hosting-header" */).then(c => wrapFunctional(c.default || c))
export const EventHostingIntroduceSpaces = () => import('../../components/event-hosting/IntroduceSpaces.vue' /* webpackChunkName: "components/event-hosting-introduce-spaces" */).then(c => wrapFunctional(c.default || c))
export const EventHostingPackages = () => import('../../components/event-hosting/Packages.vue' /* webpackChunkName: "components/event-hosting-packages" */).then(c => wrapFunctional(c.default || c))
export const EventHostingSocialProofs = () => import('../../components/event-hosting/SocialProofs.vue' /* webpackChunkName: "components/event-hosting-social-proofs" */).then(c => wrapFunctional(c.default || c))
export const Faqs = () => import('../../components/faqs/Faqs.vue' /* webpackChunkName: "components/faqs" */).then(c => wrapFunctional(c.default || c))
export const GettingThereContentList = () => import('../../components/getting-there/ContentList.vue' /* webpackChunkName: "components/getting-there-content-list" */).then(c => wrapFunctional(c.default || c))
export const GettingThereLocation = () => import('../../components/getting-there/Location.vue' /* webpackChunkName: "components/getting-there-location" */).then(c => wrapFunctional(c.default || c))
export const GettingThereSlider = () => import('../../components/getting-there/Slider.vue' /* webpackChunkName: "components/getting-there-slider" */).then(c => wrapFunctional(c.default || c))
export const HowItWorks = () => import('../../components/how-it-works/HowItWorks.vue' /* webpackChunkName: "components/how-it-works" */).then(c => wrapFunctional(c.default || c))
export const IconsBuySessionPackCheckIcon = () => import('../../components/icons/BuySessionPackCheckIcon.vue' /* webpackChunkName: "components/icons-buy-session-pack-check-icon" */).then(c => wrapFunctional(c.default || c))
export const InvoicesInvoiceBankDetailContent = () => import('../../components/invoices/InvoiceBankDetailContent.vue' /* webpackChunkName: "components/invoices-invoice-bank-detail-content" */).then(c => wrapFunctional(c.default || c))
export const InvoicesInvoiceDetailContent = () => import('../../components/invoices/InvoiceDetailContent.vue' /* webpackChunkName: "components/invoices-invoice-detail-content" */).then(c => wrapFunctional(c.default || c))
export const InvoicesInvoiceUENDisplay = () => import('../../components/invoices/InvoiceUENDisplay.vue' /* webpackChunkName: "components/invoices-invoice-u-e-n-display" */).then(c => wrapFunctional(c.default || c))
export const JoinUsApply = () => import('../../components/join-us/Apply.vue' /* webpackChunkName: "components/join-us-apply" */).then(c => wrapFunctional(c.default || c))
export const JoinUsOpportunities = () => import('../../components/join-us/Opportunities.vue' /* webpackChunkName: "components/join-us-opportunities" */).then(c => wrapFunctional(c.default || c))
export const JoinUsReasons = () => import('../../components/join-us/Reasons.vue' /* webpackChunkName: "components/join-us-reasons" */).then(c => wrapFunctional(c.default || c))
export const JoinUsSlider = () => import('../../components/join-us/Slider.vue' /* webpackChunkName: "components/join-us-slider" */).then(c => wrapFunctional(c.default || c))
export const JoinUsTopBanner = () => import('../../components/join-us/TopBanner.vue' /* webpackChunkName: "components/join-us-top-banner" */).then(c => wrapFunctional(c.default || c))
export const ModalsChangePassSuccess = () => import('../../components/modals/ChangePassSuccess.vue' /* webpackChunkName: "components/modals-change-pass-success" */).then(c => wrapFunctional(c.default || c))
export const ModalsGetInformationsModal = () => import('../../components/modals/GetInformationsModal.vue' /* webpackChunkName: "components/modals-get-informations-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalCancelAgoraCare = () => import('../../components/modals/ModalCancelAgoraCare.vue' /* webpackChunkName: "components/modals-modal-cancel-agora-care" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalDeleteChild = () => import('../../components/modals/ModalDeleteChild.vue' /* webpackChunkName: "components/modals-modal-delete-child" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalEditImage = () => import('../../components/modals/ModalEditImage.vue' /* webpackChunkName: "components/modals-modal-edit-image" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalLoadingPayment = () => import('../../components/modals/ModalLoadingPayment.vue' /* webpackChunkName: "components/modals-modal-loading-payment" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalRemoveCart = () => import('../../components/modals/ModalRemoveCart.vue' /* webpackChunkName: "components/modals-modal-remove-cart" */).then(c => wrapFunctional(c.default || c))
export const ModalsMorningPassPopup = () => import('../../components/modals/MorningPassPopup.vue' /* webpackChunkName: "components/modals-morning-pass-popup" */).then(c => wrapFunctional(c.default || c))
export const ModalsPromptMessageAfterSignedUp = () => import('../../components/modals/PromptMessageAfterSignedUp.vue' /* webpackChunkName: "components/modals-prompt-message-after-signed-up" */).then(c => wrapFunctional(c.default || c))
export const ModalsRequestTrialCourseForm = () => import('../../components/modals/RequestTrialCourseForm.vue' /* webpackChunkName: "components/modals-request-trial-course-form" */).then(c => wrapFunctional(c.default || c))
export const ModalsRequestTrialCourseSuccess = () => import('../../components/modals/RequestTrialCourseSuccess.vue' /* webpackChunkName: "components/modals-request-trial-course-success" */).then(c => wrapFunctional(c.default || c))
export const PlansAgoraPackage = () => import('../../components/plans/AgoraPackage.vue' /* webpackChunkName: "components/plans-agora-package" */).then(c => wrapFunctional(c.default || c))
export const PlansAgoraPlanning = () => import('../../components/plans/AgoraPlanning.vue' /* webpackChunkName: "components/plans-agora-planning" */).then(c => wrapFunctional(c.default || c))
export const PlansAgoraSubscription = () => import('../../components/plans/AgoraSubscription.vue' /* webpackChunkName: "components/plans-agora-subscription" */).then(c => wrapFunctional(c.default || c))
export const PlansPayAsYouGo = () => import('../../components/plans/PayAsYouGo.vue' /* webpackChunkName: "components/plans-pay-as-you-go" */).then(c => wrapFunctional(c.default || c))
export const PlansPricingForYouCard = () => import('../../components/plans/PricingForYouCard.vue' /* webpackChunkName: "components/plans-pricing-for-you-card" */).then(c => wrapFunctional(c.default || c))
export const PricingFeaturePrice = () => import('../../components/pricing/FeaturePrice.vue' /* webpackChunkName: "components/pricing-feature-price" */).then(c => wrapFunctional(c.default || c))
export const PricingFeatureThreeColumnPrice = () => import('../../components/pricing/FeatureThreeColumnPrice.vue' /* webpackChunkName: "components/pricing-feature-three-column-price" */).then(c => wrapFunctional(c.default || c))
export const PricingHeaderSimpleCenteredPrice = () => import('../../components/pricing/HeaderSimpleCenteredPrice.vue' /* webpackChunkName: "components/pricing-header-simple-centered-price" */).then(c => wrapFunctional(c.default || c))
export const PricingPromotions = () => import('../../components/pricing/Promotions.vue' /* webpackChunkName: "components/pricing-promotions" */).then(c => wrapFunctional(c.default || c))
export const PricingSchoolList = () => import('../../components/pricing/SchoolList.vue' /* webpackChunkName: "components/pricing-school-list" */).then(c => wrapFunctional(c.default || c))
export const PricingSinglePriceDetails = () => import('../../components/pricing/SinglePriceDetails.vue' /* webpackChunkName: "components/pricing-single-price-details" */).then(c => wrapFunctional(c.default || c))
export const ProductItem = () => import('../../components/product/ProductItem.vue' /* webpackChunkName: "components/product-item" */).then(c => wrapFunctional(c.default || c))
export const ProductTable = () => import('../../components/product/ProductTable.vue' /* webpackChunkName: "components/product-table" */).then(c => wrapFunctional(c.default || c))
export const ProfileAddChildren = () => import('../../components/profile/AddChildren.vue' /* webpackChunkName: "components/profile-add-children" */).then(c => wrapFunctional(c.default || c))
export const ProfileAgoraItem = () => import('../../components/profile/AgoraItem.vue' /* webpackChunkName: "components/profile-agora-item" */).then(c => wrapFunctional(c.default || c))
export const ProfileLeftMenu = () => import('../../components/profile/LeftMenu.vue' /* webpackChunkName: "components/profile-left-menu" */).then(c => wrapFunctional(c.default || c))
export const ProfilePersonInformationForm = () => import('../../components/profile/PersonInformationForm.vue' /* webpackChunkName: "components/profile-person-information-form" */).then(c => wrapFunctional(c.default || c))
export const ProfilePhoneInput = () => import('../../components/profile/PhoneInput.vue' /* webpackChunkName: "components/profile-phone-input" */).then(c => wrapFunctional(c.default || c))
export const ProfileParentBox = () => import('../../components/profile/ProfileParentBox.vue' /* webpackChunkName: "components/profile-parent-box" */).then(c => wrapFunctional(c.default || c))
export const ProfileSubscriptionCard = () => import('../../components/profile/SubscriptionCard.vue' /* webpackChunkName: "components/profile-subscription-card" */).then(c => wrapFunctional(c.default || c))
export const ProviderHeader = () => import('../../components/provider/Header.vue' /* webpackChunkName: "components/provider-header" */).then(c => wrapFunctional(c.default || c))
export const ProviderItem = () => import('../../components/provider/ProviderItem.vue' /* webpackChunkName: "components/provider-item" */).then(c => wrapFunctional(c.default || c))
export const ProviderSidebar = () => import('../../components/provider/Sidebar.vue' /* webpackChunkName: "components/provider-sidebar" */).then(c => wrapFunctional(c.default || c))
export const RegistrationsChildInfoForm = () => import('../../components/registrations/ChildInfoForm.vue' /* webpackChunkName: "components/registrations-child-info-form" */).then(c => wrapFunctional(c.default || c))
export const RegistrationsGuardianInfoForm = () => import('../../components/registrations/GuardianInfoForm.vue' /* webpackChunkName: "components/registrations-guardian-info-form" */).then(c => wrapFunctional(c.default || c))
export const RegistrationsThankYouAfterRegistration = () => import('../../components/registrations/ThankYouAfterRegistration.vue' /* webpackChunkName: "components/registrations-thank-you-after-registration" */).then(c => wrapFunctional(c.default || c))
export const ScheduleAddToCalendar = () => import('../../components/schedule/AddToCalendar.vue' /* webpackChunkName: "components/schedule-add-to-calendar" */).then(c => wrapFunctional(c.default || c))
export const ScheduleAttendSession = () => import('../../components/schedule/AttendSession.vue' /* webpackChunkName: "components/schedule-attend-session" */).then(c => wrapFunctional(c.default || c))
export const ScheduleCalendarBox = () => import('../../components/schedule/CalendarBox.vue' /* webpackChunkName: "components/schedule-calendar-box" */).then(c => wrapFunctional(c.default || c))
export const ScheduleCalendarBoxDetail = () => import('../../components/schedule/CalendarBoxDetail.vue' /* webpackChunkName: "components/schedule-calendar-box-detail" */).then(c => wrapFunctional(c.default || c))
export const ScheduleCalendarBoxOverView = () => import('../../components/schedule/CalendarBoxOverView.vue' /* webpackChunkName: "components/schedule-calendar-box-over-view" */).then(c => wrapFunctional(c.default || c))
export const ScheduleListViewBox = () => import('../../components/schedule/ListViewBox.vue' /* webpackChunkName: "components/schedule-list-view-box" */).then(c => wrapFunctional(c.default || c))
export const ScheduleSampleStudentSchedule = () => import('../../components/schedule/SampleStudentSchedule.vue' /* webpackChunkName: "components/schedule-sample-student-schedule" */).then(c => wrapFunctional(c.default || c))
export const ScheduleHeader = () => import('../../components/schedule/ScheduleHeader.vue' /* webpackChunkName: "components/schedule-header" */).then(c => wrapFunctional(c.default || c))
export const SharedAttachmentFiles = () => import('../../components/shared/AttachmentFiles.vue' /* webpackChunkName: "components/shared-attachment-files" */).then(c => wrapFunctional(c.default || c))
export const SharedBaseButton = () => import('../../components/shared/BaseButton.vue' /* webpackChunkName: "components/shared-base-button" */).then(c => wrapFunctional(c.default || c))
export const SharedContact = () => import('../../components/shared/Contact.vue' /* webpackChunkName: "components/shared-contact" */).then(c => wrapFunctional(c.default || c))
export const SharedContentAndImageSplitType1 = () => import('../../components/shared/ContentAndImageSplitType1.vue' /* webpackChunkName: "components/shared-content-and-image-split-type1" */).then(c => wrapFunctional(c.default || c))
export const SharedContentAndImageSplitType2 = () => import('../../components/shared/ContentAndImageSplitType2.vue' /* webpackChunkName: "components/shared-content-and-image-split-type2" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomBanner = () => import('../../components/shared/CustomBanner.vue' /* webpackChunkName: "components/shared-custom-banner" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomButton = () => import('../../components/shared/CustomButton.vue' /* webpackChunkName: "components/shared-custom-button" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomCard = () => import('../../components/shared/CustomCard.vue' /* webpackChunkName: "components/shared-custom-card" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomCheckbox = () => import('../../components/shared/CustomCheckbox.vue' /* webpackChunkName: "components/shared-custom-checkbox" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomDateRangePicker = () => import('../../components/shared/CustomDateRangePicker.vue' /* webpackChunkName: "components/shared-custom-date-range-picker" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomDialog = () => import('../../components/shared/CustomDialog.vue' /* webpackChunkName: "components/shared-custom-dialog" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomDialogSelect = () => import('../../components/shared/CustomDialogSelect.vue' /* webpackChunkName: "components/shared-custom-dialog-select" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomDropDown = () => import('../../components/shared/CustomDropDown.vue' /* webpackChunkName: "components/shared-custom-drop-down" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomInput = () => import('../../components/shared/CustomInput.vue' /* webpackChunkName: "components/shared-custom-input" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomMultipleSelect = () => import('../../components/shared/CustomMultipleSelect.vue' /* webpackChunkName: "components/shared-custom-multiple-select" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomSearchBox = () => import('../../components/shared/CustomSearchBox.vue' /* webpackChunkName: "components/shared-custom-search-box" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomStatusLabel = () => import('../../components/shared/CustomStatusLabel.vue' /* webpackChunkName: "components/shared-custom-status-label" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomTable = () => import('../../components/shared/CustomTable.vue' /* webpackChunkName: "components/shared-custom-table" */).then(c => wrapFunctional(c.default || c))
export const SharedFaqsContent = () => import('../../components/shared/FaqsContent.vue' /* webpackChunkName: "components/shared-faqs-content" */).then(c => wrapFunctional(c.default || c))
export const SharedFaqsHeader = () => import('../../components/shared/FaqsHeader.vue' /* webpackChunkName: "components/shared-faqs-header" */).then(c => wrapFunctional(c.default || c))
export const SharedHeaderImageCard = () => import('../../components/shared/HeaderImageCard.vue' /* webpackChunkName: "components/shared-header-image-card" */).then(c => wrapFunctional(c.default || c))
export const SharedHeroSection = () => import('../../components/shared/HeroSection.vue' /* webpackChunkName: "components/shared-hero-section" */).then(c => wrapFunctional(c.default || c))
export const SharedImageCard = () => import('../../components/shared/ImageCard.vue' /* webpackChunkName: "components/shared-image-card" */).then(c => wrapFunctional(c.default || c))
export const SharedPillLabel = () => import('../../components/shared/PillLabel.vue' /* webpackChunkName: "components/shared-pill-label" */).then(c => wrapFunctional(c.default || c))
export const SharedPolygonBackground = () => import('../../components/shared/PolygonBackground.vue' /* webpackChunkName: "components/shared-polygon-background" */).then(c => wrapFunctional(c.default || c))
export const SharedSearchBoxWithIcon = () => import('../../components/shared/SearchBoxWithIcon.vue' /* webpackChunkName: "components/shared-search-box-with-icon" */).then(c => wrapFunctional(c.default || c))
export const SharedSliderCard = () => import('../../components/shared/SliderCard.vue' /* webpackChunkName: "components/shared-slider-card" */).then(c => wrapFunctional(c.default || c))
export const SharedSpinner = () => import('../../components/shared/Spinner.vue' /* webpackChunkName: "components/shared-spinner" */).then(c => wrapFunctional(c.default || c))
export const SharedSquareBadge = () => import('../../components/shared/SquareBadge.vue' /* webpackChunkName: "components/shared-square-badge" */).then(c => wrapFunctional(c.default || c))
export const SharedStudentProgrammer = () => import('../../components/shared/StudentProgrammer.vue' /* webpackChunkName: "components/shared-student-programmer" */).then(c => wrapFunctional(c.default || c))
export const SharedSubMenu = () => import('../../components/shared/SubMenu.vue' /* webpackChunkName: "components/shared-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const SharedSubMenuEarlyYears = () => import('../../components/shared/SubMenuEarlyYears.vue' /* webpackChunkName: "components/shared-sub-menu-early-years" */).then(c => wrapFunctional(c.default || c))
export const SharedTermsAndConditions = () => import('../../components/shared/TermsAndConditions.vue' /* webpackChunkName: "components/shared-terms-and-conditions" */).then(c => wrapFunctional(c.default || c))
export const SharedTermsTemplate = () => import('../../components/shared/TermsTemplate.vue' /* webpackChunkName: "components/shared-terms-template" */).then(c => wrapFunctional(c.default || c))
export const SharedToggleButton = () => import('../../components/shared/ToggleButton.vue' /* webpackChunkName: "components/shared-toggle-button" */).then(c => wrapFunctional(c.default || c))
export const ShoppingCartItem = () => import('../../components/shopping-cart/CartItem.vue' /* webpackChunkName: "components/shopping-cart-item" */).then(c => wrapFunctional(c.default || c))
export const ShoppingCartHandleCartItem = () => import('../../components/shopping-cart/HandleCartItem.ts' /* webpackChunkName: "components/shopping-cart-handle-cart-item" */).then(c => wrapFunctional(c.default || c))
export const StaticPagesFacilities = () => import('../../components/static-pages/Facilities.vue' /* webpackChunkName: "components/static-pages-facilities" */).then(c => wrapFunctional(c.default || c))
export const StaticPagesFooter = () => import('../../components/static-pages/Footer.vue' /* webpackChunkName: "components/static-pages-footer" */).then(c => wrapFunctional(c.default || c))
export const StaticPagesNotifyEmail = () => import('../../components/static-pages/NotifyEmail.vue' /* webpackChunkName: "components/static-pages-notify-email" */).then(c => wrapFunctional(c.default || c))
export const StudentCarePricingTable = () => import('../../components/student-care/PricingTable.vue' /* webpackChunkName: "components/student-care-pricing-table" */).then(c => wrapFunctional(c.default || c))
export const StudentCareRegistrationChatIcon = () => import('../../components/student-care-registration/ChatIcon.vue' /* webpackChunkName: "components/student-care-registration-chat-icon" */).then(c => wrapFunctional(c.default || c))
export const StudentCareRegistrationLocationIcon = () => import('../../components/student-care-registration/LocationIcon.vue' /* webpackChunkName: "components/student-care-registration-location-icon" */).then(c => wrapFunctional(c.default || c))
export const StudentCareRegistrationPhoneIcon = () => import('../../components/student-care-registration/PhoneIcon.vue' /* webpackChunkName: "components/student-care-registration-phone-icon" */).then(c => wrapFunctional(c.default || c))
export const SubjectEducationProvidersList = () => import('../../components/subject/EducationProvidersList.vue' /* webpackChunkName: "components/subject-education-providers-list" */).then(c => wrapFunctional(c.default || c))
export const SubjectFilter = () => import('../../components/subject/SubjectFilter.vue' /* webpackChunkName: "components/subject-filter" */).then(c => wrapFunctional(c.default || c))
export const SubjectList = () => import('../../components/subject/SubjectList.vue' /* webpackChunkName: "components/subject-list" */).then(c => wrapFunctional(c.default || c))
export const SubjectsCourseHeader = () => import('../../components/subjects/CourseHeader.vue' /* webpackChunkName: "components/subjects-course-header" */).then(c => wrapFunctional(c.default || c))
export const SubjectsCourseItem = () => import('../../components/subjects/CourseItem.vue' /* webpackChunkName: "components/subjects-course-item" */).then(c => wrapFunctional(c.default || c))
export const SubjectsCourseList = () => import('../../components/subjects/CourseList.vue' /* webpackChunkName: "components/subjects-course-list" */).then(c => wrapFunctional(c.default || c))
export const TechnologyFeature = () => import('../../components/technology/TechnologyFeature.vue' /* webpackChunkName: "components/technology-feature" */).then(c => wrapFunctional(c.default || c))
export const TechnologyHero = () => import('../../components/technology/TechnologyHero.vue' /* webpackChunkName: "components/technology-hero" */).then(c => wrapFunctional(c.default || c))
export const WhatsHappeningCardItem = () => import('../../components/whats-happening/CardItem.vue' /* webpackChunkName: "components/whats-happening-card-item" */).then(c => wrapFunctional(c.default || c))
export const WhatsHappeningHostAnEvent = () => import('../../components/whats-happening/HostAnEvent.vue' /* webpackChunkName: "components/whats-happening-host-an-event" */).then(c => wrapFunctional(c.default || c))
export const AuthChallengePasswordHandle = () => import('../../components/auth/ChallengePassword/handle.ts' /* webpackChunkName: "components/auth-challenge-password-handle" */).then(c => wrapFunctional(c.default || c))
export const AuthChallengePassword = () => import('../../components/auth/ChallengePassword/index.vue' /* webpackChunkName: "components/auth-challenge-password" */).then(c => wrapFunctional(c.default || c))
export const AuthForgotPasswordFormConfirm = () => import('../../components/auth/ForgotPassword/FormConfirm.vue' /* webpackChunkName: "components/auth-forgot-password-form-confirm" */).then(c => wrapFunctional(c.default || c))
export const AuthForgotPasswordFormGetCode = () => import('../../components/auth/ForgotPassword/FormGetCode.vue' /* webpackChunkName: "components/auth-forgot-password-form-get-code" */).then(c => wrapFunctional(c.default || c))
export const AuthForgotPasswordHandle = () => import('../../components/auth/ForgotPassword/handle.ts' /* webpackChunkName: "components/auth-forgot-password-handle" */).then(c => wrapFunctional(c.default || c))
export const AuthForgotPassword = () => import('../../components/auth/ForgotPassword/index.vue' /* webpackChunkName: "components/auth-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const ProfilePaymentCardDetails = () => import('../../components/profile/payment/CardDetails.vue' /* webpackChunkName: "components/profile-payment-card-details" */).then(c => wrapFunctional(c.default || c))
export const ProfilePaymentPastTransactions = () => import('../../components/profile/payment/PastTransactions.vue' /* webpackChunkName: "components/profile-payment-past-transactions" */).then(c => wrapFunctional(c.default || c))
export const ProfilePaymentMethod = () => import('../../components/profile/payment/PaymentMethod.vue' /* webpackChunkName: "components/profile-payment-method" */).then(c => wrapFunctional(c.default || c))
export const ProfilePaymentRefundHistories = () => import('../../components/profile/payment/RefundHistories.vue' /* webpackChunkName: "components/profile-payment-refund-histories" */).then(c => wrapFunctional(c.default || c))
export const ProviderDashboardAlert = () => import('../../components/provider/dashboard/Alert.vue' /* webpackChunkName: "components/provider-dashboard-alert" */).then(c => wrapFunctional(c.default || c))
export const ProviderDashboardFilter = () => import('../../components/provider/dashboard/Filter.vue' /* webpackChunkName: "components/provider-dashboard-filter" */).then(c => wrapFunctional(c.default || c))
export const ProviderDashboardInfoCards = () => import('../../components/provider/dashboard/InfoCards.vue' /* webpackChunkName: "components/provider-dashboard-info-cards" */).then(c => wrapFunctional(c.default || c))
export const ProviderScheduleCalendarBox = () => import('../../components/provider/schedule/CalendarBox.vue' /* webpackChunkName: "components/provider-schedule-calendar-box" */).then(c => wrapFunctional(c.default || c))
export const ProviderScheduleListViewBox = () => import('../../components/provider/schedule/ListViewBox.vue' /* webpackChunkName: "components/provider-schedule-list-view-box" */).then(c => wrapFunctional(c.default || c))
export const ProviderScheduleHeader = () => import('../../components/provider/schedule/ScheduleHeader.vue' /* webpackChunkName: "components/provider-schedule-header" */).then(c => wrapFunctional(c.default || c))
export const ProviderTeacherAddTeacherModal = () => import('../../components/provider/teacher/AddTeacherModal.vue' /* webpackChunkName: "components/provider-teacher-add-teacher-modal" */).then(c => wrapFunctional(c.default || c))
export const ProviderScheduleCourseDetailHeader = () => import('../../components/provider/schedule/course/CourseDetailHeader.vue' /* webpackChunkName: "components/provider-schedule-course-detail-header" */).then(c => wrapFunctional(c.default || c))
export const ProviderScheduleCourseStudentListBox = () => import('../../components/provider/schedule/course/StudentListBox.vue' /* webpackChunkName: "components/provider-schedule-course-student-list-box" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
