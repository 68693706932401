import { Middleware } from '@nuxt/types'
import Cookies from 'cookie';
import { canAccessEPPortal } from '~/utils/helpers'

const authenticated: Middleware = (context) => {
  const user = context.store.state.user
  const path = context.route.path
  console.log('Middleware authenticated start', { path, user })
  const venueSlug = context.route.params.venueSlug
  const isRegistrationPage = path.includes('registrations')
  const isSecretPage = path.includes('education-provider') || isRegistrationPage
  const isAccessEPPortal = canAccessEPPortal(user)
  let hasLoggedInBefore = false
  let redirectPage = 'sign-in'
  if (!user && isSecretPage) {
    if (isRegistrationPage) {
      const currentPath = encodeURIComponent(path || '')
      const locationCookie = `latest_location=${currentPath}; path=/; max-age=3600`
      context.res.setHeader('Set-Cookie', locationCookie)
      const parsedCookies = Cookies.parse(context.req.headers?.cookie || '')
      hasLoggedInBefore = parsedCookies.isLogin === '1'
      if (!hasLoggedInBefore) {
        redirectPage = 'sign-up'
      }
    }

    const redirectPath = path.startsWith('/venue/') && venueSlug
      ? `/venue/${venueSlug}/${redirectPage}`
      : `/${redirectPage}`
    return context.redirect(redirectPath)
  }

  if (user && isSecretPage && !(isAccessEPPortal || isRegistrationPage)) {
    return context.redirect('/')
  }

  if (user && !isSecretPage && isAccessEPPortal) {
    return context.redirect('/education-provider')
  }
}

export default authenticated
