/* tslint:disable */
/* eslint-disable */
/**
 * agora-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AboutPageContentDto
 */
export interface AboutPageContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<string>}
     * @memberof AboutPageContentDto
     */
    heroDescription: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AboutPageContentDto
     */
    introduceDescription: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AboutPageContentDto
     */
    newsletterDescription: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AboutPageContentDto
     */
    heroImage: string;
    /**
     * 
     * @type {string}
     * @memberof AboutPageContentDto
     */
    introduceImageMobile: string;
    /**
     * 
     * @type {string}
     * @memberof AboutPageContentDto
     */
    introduceImage: string;
    /**
     * 
     * @type {string}
     * @memberof AboutPageContentDto
     */
    newsletterImage: string;
    /**
     * 
     * @type {Array<AboutUsSafetyContentDto>}
     * @memberof AboutPageContentDto
     */
    safetyList: Array<AboutUsSafetyContentDto>;
    /**
     * 
     * @type {Array<AboutUsLeaderContentDto>}
     * @memberof AboutPageContentDto
     */
    leaderList: Array<AboutUsLeaderContentDto>;
    /**
     * 
     * @type {MetaDto}
     * @memberof AboutPageContentDto
     */
    meta: MetaDto;
}
/**
 * 
 * @export
 * @interface AboutUsLeaderContentDto
 */
export interface AboutUsLeaderContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AboutUsLeaderContentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AboutUsLeaderContentDto
     */
    level: string;
    /**
     * 
     * @type {string}
     * @memberof AboutUsLeaderContentDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof AboutUsLeaderContentDto
     */
    imageUrl: string;
}
/**
 * 
 * @export
 * @interface AboutUsSafetyContentDto
 */
export interface AboutUsSafetyContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AboutUsSafetyContentDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof AboutUsSafetyContentDto
     */
    backgroundColor: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AccessScopeEnum {
    All = 'all',
    Venue = 'venue',
    Course = 'course'
}

/**
 * 
 * @export
 * @interface AddEventsResponse
 */
export interface AddEventsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {boolean}
     * @memberof AddEventsResponse
     */
    success?: boolean;
    /**
     * 
     * @type {Array<any>}
     * @memberof AddEventsResponse
     */
    data: Array<any>;
}
/**
 * 
 * @export
 * @interface AddMessageRequest
 */
export interface AddMessageRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AddMessageRequest
     */
    groupMessageId: string;
    /**
     * 
     * @type {string}
     * @memberof AddMessageRequest
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof AddMessageRequest
     */
    contentType?: AddMessageRequestContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AddMessageRequest
     */
    fileName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AddMessageRequestContentTypeEnum {
    Text = 'text',
    Image = 'image',
    Document = 'document'
}

/**
 * 
 * @export
 * @interface AddToMediaRequest
 */
export interface AddToMediaRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<MediaDto>}
     * @memberof AddToMediaRequest
     */
    files: Array<MediaDto>;
}
/**
 * 
 * @export
 * @interface AddToMediaResponse
 */
export interface AddToMediaResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<MediaDto>}
     * @memberof AddToMediaResponse
     */
    data: Array<MediaDto>;
}
/**
 * 
 * @export
 * @interface AdditionalAttendanceReportToSpreadSheetDto
 */
export interface AdditionalAttendanceReportToSpreadSheetDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdditionalAttendanceReportToSpreadSheetDto
     */
    spreadSheetId: string;
    /**
     * 
     * @type {number}
     * @memberof AdditionalAttendanceReportToSpreadSheetDto
     */
    sheetIndex: number;
    /**
     * 
     * @type {string}
     * @memberof AdditionalAttendanceReportToSpreadSheetDto
     */
    venueId: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalAttendanceReportToSpreadSheetDto
     */
    courseInstanceId: string;
}
/**
 * 
 * @export
 * @interface AdminAddCreditDto
 */
export interface AdminAddCreditDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminAddCreditDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddCreditDto
     */
    parentId?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminAddCreditDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminAddCreditDto
     */
    expireDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminAddCreditDto
     */
    balanceAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminAddCreditDto
     */
    creditReasonId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddCreditDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddCreditDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface AdminAddCreditRequest
 */
export interface AdminAddCreditRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminAddCreditRequest
     */
    parentId: string;
    /**
     * 
     * @type {number}
     * @memberof AdminAddCreditRequest
     */
    creditAmount: number;
    /**
     * 
     * @type {string}
     * @memberof AdminAddCreditRequest
     */
    creditReasonId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddCreditRequest
     */
    expireDate: string;
}
/**
 * 
 * @export
 * @interface AdminAddEpAgreementLinkRequest
 */
export interface AdminAddEpAgreementLinkRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminAddEpAgreementLinkRequest
     */
    agreementLink?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddEpAgreementLinkRequest
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddEpAgreementLinkRequest
     */
    providerId?: string;
}
/**
 * 
 * @export
 * @interface AdminAddSessionPackToBasketRequest
 */
export interface AdminAddSessionPackToBasketRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminAddSessionPackToBasketRequest
     */
    parentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddSessionPackToBasketRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddSessionPackToBasketRequest
     */
    sessionPackId: string;
}
/**
 * 
 * @export
 * @interface AdminAddTrialSessionRequest
 */
export interface AdminAddTrialSessionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminAddTrialSessionRequest
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddTrialSessionRequest
     */
    courseSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddTrialSessionRequest
     */
    courseSessionStartTime: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddTrialSessionRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddTrialSessionRequest
     */
    roomName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddTrialSessionRequest
     */
    approverId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddTrialSessionRequest
     */
    studentSessionType?: AdminAddTrialSessionRequestStudentSessionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminAddTrialSessionRequestStudentSessionTypeEnum {
    Trial = 'trial',
    Makeup = 'makeup'
}

/**
 * 
 * @export
 * @interface AdminAddTrialSingleSessionRequest
 */
export interface AdminAddTrialSingleSessionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminAddTrialSingleSessionRequest
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddTrialSingleSessionRequest
     */
    courseSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddTrialSingleSessionRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddTrialSingleSessionRequest
     */
    parentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddTrialSingleSessionRequest
     */
    expireDate: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddTrialSingleSessionRequest
     */
    expireTime: string;
    /**
     * 
     * @type {number}
     * @memberof AdminAddTrialSingleSessionRequest
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof AdminAddTrialSingleSessionRequest
     */
    remark: string;
}
/**
 * 
 * @export
 * @interface AdminApproveCourseRequest
 */
export interface AdminApproveCourseRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminApproveCourseRequest
     */
    courseId: string;
}
/**
 * 
 * @export
 * @interface AdminAssignEpRequest
 */
export interface AdminAssignEpRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminAssignEpRequest
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminAssignEpRequest
     */
    educationProviderIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminAssignEpRequest
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAssignEpRequest
     */
    status?: AdminAssignEpRequestStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AdminAssignEpRequest
     */
    isShareParentContact?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminAssignEpRequestStatusEnum {
    Active = 'active',
    Inactive = 'inactive'
}

/**
 * 
 * @export
 * @interface AdminBulkCreateReserveRoomResponse
 */
export interface AdminBulkCreateReserveRoomResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<RoomReserveDto>}
     * @memberof AdminBulkCreateReserveRoomResponse
     */
    data: Array<RoomReserveDto>;
}
/**
 * 
 * @export
 * @interface AdminCRUDStudentSessionRequest
 */
export interface AdminCRUDStudentSessionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminCRUDStudentSessionRequest
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCRUDStudentSessionRequest
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCRUDStudentSessionRequest
     */
    originalSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCRUDStudentSessionRequest
     */
    courseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCRUDStudentSessionRequest
     */
    replacementInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCRUDStudentSessionRequest
     */
    replacementSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCRUDStudentSessionRequest
     */
    replacementApproverId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCRUDStudentSessionRequest
     */
    replacementTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCRUDStudentSessionRequest
     */
    replacementDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCRUDStudentSessionRequest
     */
    remark?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCRUDStudentSessionRequest
     */
    isReplaced?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCRUDStudentSessionRequest
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCRUDStudentSessionRequest
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface AdminCancelTrialSingleSessionRequest
 */
export interface AdminCancelTrialSingleSessionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminCancelTrialSingleSessionRequest
     */
    reservedId: string;
}
/**
 * 
 * @export
 * @interface AdminChangeUserRoleRequest
 */
export interface AdminChangeUserRoleRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminChangeUserRoleRequest
     */
    role: AdminChangeUserRoleRequestRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminChangeUserRoleRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminChangeUserRoleRequest
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminChangeUserRoleRequest
     */
    venueIds?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminChangeUserRoleRequestRoleEnum {
    Admin = 'admin',
    Manager = 'manager',
    Finance = 'finance',
    StandardUser = 'standard_user'
}

/**
 * 
 * @export
 * @interface AdminChangeUserRoleResponse
 */
export interface AdminChangeUserRoleResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {UserDto}
     * @memberof AdminChangeUserRoleResponse
     */
    data: UserDto;
}
/**
 * 
 * @export
 * @interface AdminChangeUserStatusRequest
 */
export interface AdminChangeUserStatusRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminChangeUserStatusRequest
     */
    status: AdminChangeUserStatusRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminChangeUserStatusRequest
     */
    userId: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminChangeUserStatusRequestStatusEnum {
    Active = 'active',
    Inactive = 'inactive'
}

/**
 * 
 * @export
 * @interface AdminChangeUserStatusResponse
 */
export interface AdminChangeUserStatusResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {UserDto}
     * @memberof AdminChangeUserStatusResponse
     */
    data: UserDto;
}
/**
 * 
 * @export
 * @interface AdminCheckInCheckOutRequest
 */
export interface AdminCheckInCheckOutRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminRequestCheckinOut>}
     * @memberof AdminCheckInCheckOutRequest
     */
    checkInCheckOutData: Array<AdminRequestCheckinOut>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCheckInCheckOutRequest
     */
    isCheckOutCentre?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCheckInCheckOutRequest
     */
    isCheckOutCourse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCheckInCheckOutRequest
     */
    isCheckInCentre?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCheckInCheckOutRequest
     */
    isCheckInCourse?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCheckInCheckOutRequest
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCheckInCheckOutRequest
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCheckInCheckOutRequest
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCheckInCheckOutRequest
     */
    courseSessionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCheckInCheckOutRequest
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCheckInCheckOutRequest
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCheckInCheckOutRequest
     */
    checkinOutType?: AdminCheckInCheckOutRequestCheckinOutTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCheckInCheckOutRequest
     */
    venueId?: string;
    /**
     * 
     * @type {CheckinOutSourceEnum}
     * @memberof AdminCheckInCheckOutRequest
     */
    checkinOutSource?: CheckinOutSourceEnum;
    /**
     * 
     * @type {AdminCheckInCheckOutRequestSendMailAndNotificationCondition}
     * @memberof AdminCheckInCheckOutRequest
     */
    sendMailAndNotificationCondition?: AdminCheckInCheckOutRequestSendMailAndNotificationCondition;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminCheckInCheckOutRequestCheckinOutTypeEnum {
    Single = 'single',
    Multiple = 'multiple'
}

/**
 * 
 * @export
 * @interface AdminCheckInCheckOutRequestSendMailAndNotificationCondition
 */
export interface AdminCheckInCheckOutRequestSendMailAndNotificationCondition {
    /**
     * 
     * @type {boolean}
     * @memberof AdminCheckInCheckOutRequestSendMailAndNotificationCondition
     */
    isSendNotification: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCheckInCheckOutRequestSendMailAndNotificationCondition
     */
    isSendMail: boolean;
}
/**
 * 
 * @export
 * @interface AdminCheckOverlapRequest
 */
export interface AdminCheckOverlapRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminCheckOverlapRequest
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCheckOverlapRequest
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCheckOverlapRequest
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCheckOverlapRequest
     */
    originalSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCheckOverlapRequest
     */
    courseSessionId?: string;
}
/**
 * 
 * @export
 * @interface AdminCheckOverlapResponse
 */
export interface AdminCheckOverlapResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CheckOverlapDto}
     * @memberof AdminCheckOverlapResponse
     */
    data: CheckOverlapDto;
}
/**
 * 
 * @export
 * @interface AdminCopyCourseInstanceRequest
 */
export interface AdminCopyCourseInstanceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminCopyCourseInstanceRequest
     */
    seatsOption?: AdminCopyCourseInstanceRequestSeatsOptionEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCopyCourseInstanceRequest
     */
    courseInstanceReferenceId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCopyCourseInstanceRequest
     */
    courseId: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCopyCourseInstanceRequest
     */
    isCopyCourseSessions: boolean;
    /**
     * 
     * @type {Array<CourseSessionRequest>}
     * @memberof AdminCopyCourseInstanceRequest
     */
    courseSessionsListRequest?: Array<CourseSessionRequest>;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminCopyCourseInstanceRequestSeatsOptionEnum {
    ReservedSeatForRegisteredStudents = 'reserved_seat_for_registered_students',
    ConfirmSeatsForRegisteredStudents = 'confirm_seats_for_registered_students',
    DoNotCopy = 'do_not_copy'
}

/**
 * 
 * @export
 * @interface AdminCopyCourseInstanceResponse
 */
export interface AdminCopyCourseInstanceResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminCopyCourseInstanceResponseDto}
     * @memberof AdminCopyCourseInstanceResponse
     */
    data: AdminCopyCourseInstanceResponseDto;
}
/**
 * 
 * @export
 * @interface AdminCopyCourseInstanceResponseDto
 */
export interface AdminCopyCourseInstanceResponseDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminCopyCourseInstanceResponseDto
     */
    courseId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCopyCourseInstanceResponseDto
     */
    courseSlug: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCopyCourseInstanceResponseDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCopyCourseInstanceResponseDto
     */
    courseReferenceId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCopyCourseInstanceResponseDto
     */
    courseInstanceReferenceId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCopyCourseInstanceResponseDto
     */
    courseInstanceStartDate: string;
    /**
     * 
     * @type {Array<PaymentAutoConfirmWithZeroCostRequest>}
     * @memberof AdminCopyCourseInstanceResponseDto
     */
    paymentAutoConfirmWithZeroCostRequestList?: Array<PaymentAutoConfirmWithZeroCostRequest>;
}
/**
 * 
 * @export
 * @interface AdminCountryPriceDto
 */
export interface AdminCountryPriceDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminCountryPriceDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCountryPriceDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCountryPriceDto
     */
    code?: string;
}
/**
 * 
 * @export
 * @interface AdminCourseSessionDto
 */
export interface AdminCourseSessionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminCourseSessionDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSessionDto
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSessionDto
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSessionDto
     */
    day?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSessionDto
     */
    room?: string;
    /**
     * 
     * @type {string | number}
     * @memberof AdminCourseSessionDto
     */
    availableSeats?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof AdminCourseSessionDto
     */
    bufferSeats?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof AdminCourseSessionDto
     */
    signedUp?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof AdminCourseSessionDto
     */
    replacement?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof AdminCourseSessionDto
     */
    trial?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof AdminCourseSessionDto
     */
    makeup?: string | number;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSessionDto
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminCourseSessionDto
     */
    enrollFromSession?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSessionDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSessionDto
     */
    endTime?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminCourseSessionDto
     */
    capacity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCourseSessionDto
     */
    allowTrialSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCourseSessionDto
     */
    allowMakeupSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSessionDto
     */
    applyTrialSession?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSessionDto
     */
    applyMakeupSession?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSessionDto
     */
    courseStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSessionDto
     */
    roomBookingId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSessionDto
     */
    roomId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSessionDto
     */
    courseInstanceStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminCourseSessionDto
     */
    studentNumberSession?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCourseSessionDto
     */
    isRecurring?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminCourseSessionDto
     */
    roomPrice?: number;
}
/**
 * 
 * @export
 * @interface AdminCreateCourseMediaResponse
 */
export interface AdminCreateCourseMediaResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseMediaDto>}
     * @memberof AdminCreateCourseMediaResponse
     */
    data: Array<CourseMediaDto>;
}
/**
 * 
 * @export
 * @interface AdminCreateCreditResponse
 */
export interface AdminCreateCreditResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminAddCreditDto}
     * @memberof AdminCreateCreditResponse
     */
    data: AdminAddCreditDto;
}
/**
 * 
 * @export
 * @interface AdminCreateNotificationReplacementRequest
 */
export interface AdminCreateNotificationReplacementRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminCreateNotificationReplacementRequest
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateNotificationReplacementRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateNotificationReplacementRequest
     */
    content?: string;
}
/**
 * 
 * @export
 * @interface AdminCreateOrEditCourseMediaRequest
 */
export interface AdminCreateOrEditCourseMediaRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrEditCourseMediaRequest
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrEditCourseMediaRequest
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrEditCourseMediaRequest
     */
    description?: string;
    /**
     * 
     * @type {CourseMediaDto}
     * @memberof AdminCreateOrEditCourseMediaRequest
     */
    brochure?: CourseMediaDto;
    /**
     * 
     * @type {Array<CourseMediaDto>}
     * @memberof AdminCreateOrEditCourseMediaRequest
     */
    media: Array<CourseMediaDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateOrEditCourseMediaRequest
     */
    courseMediaWillBeDeleted?: Array<string>;
}
/**
 * 
 * @export
 * @interface AdminCreateOrUpdateInvoiceAttachmentsRequest
 */
export interface AdminCreateOrUpdateInvoiceAttachmentsRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateInvoiceAttachmentsRequest
     */
    invoiceReferenceId: string;
    /**
     * 
     * @type {Array<MediaDto>}
     * @memberof AdminCreateOrUpdateInvoiceAttachmentsRequest
     */
    attachments: Array<MediaDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateOrUpdateInvoiceAttachmentsRequest
     */
    attachmentRemovedIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface AdminCreateOrUpdateInvoiceRequest
 */
export interface AdminCreateOrUpdateInvoiceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateInvoiceRequest
     */
    id?: string;
    /**
     * 
     * @type {CompanyDto}
     * @memberof AdminCreateOrUpdateInvoiceRequest
     */
    from?: CompanyDto;
    /**
     * 
     * @type {CreateInvoiceToDto}
     * @memberof AdminCreateOrUpdateInvoiceRequest
     */
    to?: CreateInvoiceToDto;
    /**
     * 
     * @type {Array<InvoiceLineDto>}
     * @memberof AdminCreateOrUpdateInvoiceRequest
     */
    invoiceLines?: Array<InvoiceLineDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateInvoiceRequest
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateInvoiceRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateInvoiceRequest
     */
    dueOn?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateInvoiceRequest
     */
    revenueRecognitionDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateOrUpdateInvoiceRequest
     */
    subTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateOrUpdateInvoiceRequest
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateOrUpdateInvoiceRequest
     */
    totalGst?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateOrUpdateInvoiceRequest
     */
    totalDiscount?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateInvoiceRequest
     */
    bankDetails?: string;
    /**
     * 
     * @type {Array<BillingOrganisationPaymentMethodEnum>}
     * @memberof AdminCreateOrUpdateInvoiceRequest
     */
    paymentMethods?: Array<BillingOrganisationPaymentMethodEnum>;
}
/**
 * 
 * @export
 * @interface AdminCreateOrUpdateInvoiceResponse
 */
export interface AdminCreateOrUpdateInvoiceResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {InvoiceDto}
     * @memberof AdminCreateOrUpdateInvoiceResponse
     */
    data: InvoiceDto;
}
/**
 * 
 * @export
 * @interface AdminCreateOrUpdateMessageCategoryResponseDto
 */
export interface AdminCreateOrUpdateMessageCategoryResponseDto {
    [key: string]: object | any;

    /**
     * 
     * @type {CategoryParentMessageDto}
     * @memberof AdminCreateOrUpdateMessageCategoryResponseDto
     */
    data: CategoryParentMessageDto;
}
/**
 * 
 * @export
 * @interface AdminCreateOrUpdateMessageRequest
 */
export interface AdminCreateOrUpdateMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateMessageRequest
     */
    richTextContent?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateMessageRequest
     */
    categoryParentMessageId?: string;
    /**
     * 
     * @type {MessageNewTypeEnum}
     * @memberof AdminCreateOrUpdateMessageRequest
     */
    type?: MessageNewTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateOrUpdateMessageRequest
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateOrUpdateMessageRequest
     */
    courseClassIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateOrUpdateMessageRequest
     */
    courseInstanceIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateOrUpdateMessageRequest
     */
    studentIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateMessageRequest
     */
    parentId?: string;
    /**
     * 
     * @type {MessageNewStatus}
     * @memberof AdminCreateOrUpdateMessageRequest
     */
    status?: MessageNewStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateOrUpdateMessageRequest
     */
    mediaIds?: Array<string>;
    /**
     * 
     * @type {MessageSentTo}
     * @memberof AdminCreateOrUpdateMessageRequest
     */
    sentTo: MessageSentTo;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateMessageRequest
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateMessageRequest
     */
    id: string;
}
/**
 * 
 * @export
 * @interface AdminCreateOrUpdateStudentNoteAttachmentsRequest
 */
export interface AdminCreateOrUpdateStudentNoteAttachmentsRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateStudentNoteAttachmentsRequest
     */
    studentNoteId: string;
    /**
     * 
     * @type {Array<MediaDto>}
     * @memberof AdminCreateOrUpdateStudentNoteAttachmentsRequest
     */
    attachments: Array<MediaDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateOrUpdateStudentNoteAttachmentsRequest
     */
    attachmentRemovedIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface AdminCreateOrUpdateSubscriptionRequest
 */
export interface AdminCreateOrUpdateSubscriptionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    id?: string;
    /**
     * 
     * @type {CompanyDto}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    from?: CompanyDto;
    /**
     * 
     * @type {CreateInvoiceToDto}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    to: CreateInvoiceToDto;
    /**
     * 
     * @type {Array<SubscriptionLineDto>}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    subscriptionLines?: Array<SubscriptionLineDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    status?: AdminCreateOrUpdateSubscriptionRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    collectionDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    invoiceDueDays?: number;
    /**
     * 
     * @type {SubscriptionPaymentMethod}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    paymentMethod: SubscriptionPaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    invoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    recurringBillingId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    fromMigration?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    subTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    totalGst?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateOrUpdateSubscriptionRequest
     */
    totalDiscount?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminCreateOrUpdateSubscriptionRequestStatusEnum {
    Draft = 'draft',
    Sent = 'sent',
    Active = 'active',
    Failed = 'failed',
    Canceled = 'canceled'
}

/**
 * 
 * @export
 * @interface AdminCreateVoucherCampaignsRequest
 */
export interface AdminCreateVoucherCampaignsRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminCreateVoucherCampaignsRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVoucherCampaignsRequest
     */
    batchName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVoucherCampaignsRequest
     */
    batchDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVoucherCampaignsRequest
     */
    valueOfVoucher?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVoucherCampaignsRequest
     */
    minBasketValue?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateVoucherCampaignsRequest
     */
    subjectIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateVoucherCampaignsRequest
     */
    educationProviderIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateVoucherCampaignsRequest
     */
    coursePeriodIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVoucherCampaignsRequest
     */
    numberOfVouchersToGenerate?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVoucherCampaignsRequest
     */
    numberOfVouchersAllowed?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVoucherCampaignsRequest
     */
    venueId?: string;
}
/**
 * 
 * @export
 * @interface AdminDeleteCourseSessionRequest
 */
export interface AdminDeleteCourseSessionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminDeleteCourseSessionRequest
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDeleteCourseSessionRequest
     */
    courseSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDeleteCourseSessionRequest
     */
    roomBookingId?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminDeleteCourseSessionRequest
     */
    numberOfSession: number;
    /**
     * 
     * @type {string}
     * @memberof AdminDeleteCourseSessionRequest
     */
    courseInstanceStatus: string;
}
/**
 * 
 * @export
 * @interface AdminEditCourseDescriptionRequest
 */
export interface AdminEditCourseDescriptionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminEditCourseDescriptionRequest
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEditCourseDescriptionRequest
     */
    referenceId: string;
}
/**
 * 
 * @export
 * @interface AdminEditCreditConfigRequest
 */
export interface AdminEditCreditConfigRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminEditCreditConfigRequest
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof AdminEditCreditConfigRequest
     */
    expirationMonthNumber: number;
    /**
     * 
     * @type {number}
     * @memberof AdminEditCreditConfigRequest
     */
    minCheckoutAmount: number;
}
/**
 * 
 * @export
 * @interface AdminEducationProviderResponse
 */
export interface AdminEducationProviderResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminEducationProvidersDto}
     * @memberof AdminEducationProviderResponse
     */
    data: AdminEducationProvidersDto;
}
/**
 * 
 * @export
 * @interface AdminEducationProvidersDto
 */
export interface AdminEducationProvidersDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    referenceId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminEducationProvidersDto
     */
    availableHome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminEducationProvidersDto
     */
    isDisplayWebsite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    createdByFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    updatedByFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminEducationProvidersDto
     */
    isGST?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    uenNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    gstNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    bankSwiftBic?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    mailingAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    slug?: string;
    /**
     * 
     * @type {EducationProviderStatus}
     * @memberof AdminEducationProvidersDto
     */
    status?: EducationProviderStatus;
    /**
     * 
     * @type {string}
     * @memberof AdminEducationProvidersDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminEducationProvidersDto
     */
    tagIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminEducationProvidersDto
     */
    tagNames?: Array<string>;
}
/**
 * 
 * @export
 * @interface AdminEducationProvidersResponse
 */
export interface AdminEducationProvidersResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminEducationProvidersDto>}
     * @memberof AdminEducationProvidersResponse
     */
    data: Array<AdminEducationProvidersDto>;
}
/**
 * 
 * @export
 * @interface AdminFilterDto
 */
export interface AdminFilterDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminFilterDto
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof AdminFilterDto
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof AdminFilterDto
     */
    key: string;
}
/**
 * 
 * @export
 * @interface AdminGetAdminGetTeacherResponse
 */
export interface AdminGetAdminGetTeacherResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetTeacherDto>}
     * @memberof AdminGetAdminGetTeacherResponse
     */
    data: Array<AdminGetTeacherDto>;
}
/**
 * 
 * @export
 * @interface AdminGetAgoraCareUsagesParentDto
 */
export interface AdminGetAgoraCareUsagesParentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetAgoraCareUsagesParentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAgoraCareUsagesParentDto
     */
    subscriptionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAgoraCareUsagesParentDto
     */
    agoraType?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAgoraCareUsagesParentDto
     */
    plan?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAgoraCareUsagesParentDto
     */
    plusOrMinus?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetAgoraCareUsagesParentDto
     */
    hours?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAgoraCareUsagesParentDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAgoraCareUsagesParentDto
     */
    endTime?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetAgoraCareUsagesParentDto
     */
    balanceHours?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetAgoraCareUsagesParentDto
     */
    cost_perHour?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAgoraCareUsagesParentDto
     */
    childName?: string;
}
/**
 * 
 * @export
 * @interface AdminGetAgoraCareUsagesParentResponse
 */
export interface AdminGetAgoraCareUsagesParentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetAgoraCareUsagesParentDto>}
     * @memberof AdminGetAgoraCareUsagesParentResponse
     */
    data: Array<AdminGetAgoraCareUsagesParentDto>;
}
/**
 * 
 * @export
 * @interface AdminGetAllCourseInstanceListViewDto
 */
export interface AdminGetAllCourseInstanceListViewDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    teacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    teacherName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    gradeLevelName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    endDate?: string;
    /**
     * 
     * @type {string | number}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    seats?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    remaining?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    booking?: string | number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    courseReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    numOfSession?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    maxAge?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    customMinAge?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    customMaxAge?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    spaceName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    shareLink?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    firstSessionStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    firstSessionEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewDto
     */
    courseSeriesId?: string;
}
/**
 * 
 * @export
 * @interface AdminGetAllCourseInstanceListViewRequestBody
 */
export interface AdminGetAllCourseInstanceListViewRequestBody {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewRequestBody
     */
    searchKey?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewRequestBody
     */
    statusFillter?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewRequestBody
     */
    courseId?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetAllCourseInstanceListViewRequestBody
     */
    start?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetAllCourseInstanceListViewRequestBody
     */
    end?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewRequestBody
     */
    sortColumn?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAllCourseInstanceListViewRequestBody
     */
    sortType?: string;
    /**
     * 
     * @type {any}
     * @memberof AdminGetAllCourseInstanceListViewRequestBody
     */
    filterModel?: any | null;
}
/**
 * 
 * @export
 * @interface AdminGetAllCourseInstanceListViewResponse
 */
export interface AdminGetAllCourseInstanceListViewResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetAllCourseInstanceListViewDto>}
     * @memberof AdminGetAllCourseInstanceListViewResponse
     */
    data: Array<AdminGetAllCourseInstanceListViewDto>;
}
/**
 * 
 * @export
 * @interface AdminGetAllKisiConfigurationsResponse
 */
export interface AdminGetAllKisiConfigurationsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<KisiConfigurationDto>}
     * @memberof AdminGetAllKisiConfigurationsResponse
     */
    data: Array<KisiConfigurationDto>;
}
/**
 * 
 * @export
 * @interface AdminGetAttendanceBySessionResponse
 */
export interface AdminGetAttendanceBySessionResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AttendanceBySessionDto>}
     * @memberof AdminGetAttendanceBySessionResponse
     */
    data: Array<AttendanceBySessionDto>;
}
/**
 * 
 * @export
 * @interface AdminGetAttendanceReportsByVenueResponse
 */
export interface AdminGetAttendanceReportsByVenueResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseInstanceDto>}
     * @memberof AdminGetAttendanceReportsByVenueResponse
     */
    data: Array<CourseInstanceDto>;
}
/**
 * 
 * @export
 * @interface AdminGetAttendanceStudentBySessionRequest
 */
export interface AdminGetAttendanceStudentBySessionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetAttendanceStudentBySessionRequest
     */
    sessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetAttendanceStudentBySessionRequest
     */
    search?: string;
}
/**
 * 
 * @export
 * @interface AdminGetAuditHistoriesResponse
 */
export interface AdminGetAuditHistoriesResponse {
    /**
     * 
     * @type {Array<AuditHistoryDto>}
     * @memberof AdminGetAuditHistoriesResponse
     */
    data: Array<AuditHistoryDto>;
}
/**
 * 
 * @export
 * @interface AdminGetCampaignsParentDto
 */
export interface AdminGetCampaignsParentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetCampaignsParentDto
     */
    giverId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCampaignsParentDto
     */
    giverName?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetCampaignsParentDto
     */
    receiverId?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCampaignsParentDto
     */
    receiverName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCampaignsParentDto
     */
    campaignName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCampaignsParentDto
     */
    campaignType?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCampaignsParentDto
     */
    offerDetails?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCampaignsParentDto
     */
    earnedHour?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCampaignsParentDto
     */
    usedHour?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCampaignsParentDto
     */
    activationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCampaignsParentDto
     */
    expirationHour?: string;
}
/**
 * 
 * @export
 * @interface AdminGetCampaignsParentResponse
 */
export interface AdminGetCampaignsParentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetCampaignsParentDto>}
     * @memberof AdminGetCampaignsParentResponse
     */
    data: Array<AdminGetCampaignsParentDto>;
}
/**
 * 
 * @export
 * @interface AdminGetCategoryListResponse
 */
export interface AdminGetCategoryListResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof AdminGetCategoryListResponse
     */
    data: Array<CategoryDto>;
}
/**
 * 
 * @export
 * @interface AdminGetCategoryParentMessageListResponse
 */
export interface AdminGetCategoryParentMessageListResponse {
    /**
     * 
     * @type {Array<CategoryParentMessageDto>}
     * @memberof AdminGetCategoryParentMessageListResponse
     */
    data: Array<CategoryParentMessageDto>;
}
/**
 * 
 * @export
 * @interface AdminGetClassesForMessageResponse
 */
export interface AdminGetClassesForMessageResponse {
    /**
     * 
     * @type {Array<CourseClassDto>}
     * @memberof AdminGetClassesForMessageResponse
     */
    data: Array<CourseClassDto>;
}
/**
 * 
 * @export
 * @interface AdminGetCourseDetailResponse
 */
export interface AdminGetCourseDetailResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseDto}
     * @memberof AdminGetCourseDetailResponse
     */
    data: CourseDto;
}
/**
 * 
 * @export
 * @interface AdminGetCourseEnrolledStudentsResponse
 */
export interface AdminGetCourseEnrolledStudentsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {ICourseEnrolledStudent}
     * @memberof AdminGetCourseEnrolledStudentsResponse
     */
    data: ICourseEnrolledStudent;
}
/**
 * 
 * @export
 * @interface AdminGetCourseEnrolmentsViewDataResponse
 */
export interface AdminGetCourseEnrolmentsViewDataResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseEnrolmentViewDto>}
     * @memberof AdminGetCourseEnrolmentsViewDataResponse
     */
    data: Array<CourseEnrolmentViewDto>;
}
/**
 * 
 * @export
 * @interface AdminGetCourseInstanceCode
 */
export interface AdminGetCourseInstanceCode {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminGetCourseInstanceCodeData}
     * @memberof AdminGetCourseInstanceCode
     */
    data: AdminGetCourseInstanceCodeData;
}
/**
 * 
 * @export
 * @interface AdminGetCourseInstanceCodeData
 */
export interface AdminGetCourseInstanceCodeData {
    /**
     * 
     * @type {string}
     * @memberof AdminGetCourseInstanceCodeData
     */
    courseInstanceCode?: string;
}
/**
 * 
 * @export
 * @interface AdminGetCourseInstanceDetailsResponse
 */
export interface AdminGetCourseInstanceDetailsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseInstanceDetailDto}
     * @memberof AdminGetCourseInstanceDetailsResponse
     */
    data: CourseInstanceDetailDto;
}
/**
 * 
 * @export
 * @interface AdminGetCourseInstanceListToAddStudentCareResponse
 */
export interface AdminGetCourseInstanceListToAddStudentCareResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseInstanceDto>}
     * @memberof AdminGetCourseInstanceListToAddStudentCareResponse
     */
    data: Array<CourseInstanceDto>;
}
/**
 * 
 * @export
 * @interface AdminGetCourseInstanceReservation
 */
export interface AdminGetCourseInstanceReservation {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetCourseInstanceReservation
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCourseInstanceReservation
     */
    expiredTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCourseInstanceReservation
     */
    expiredDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCourseInstanceReservation
     */
    status?: AdminGetCourseInstanceReservationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCourseInstanceReservation
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCourseInstanceReservation
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCourseInstanceReservation
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCourseInstanceReservation
     */
    parentName?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetCourseInstanceReservation
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCourseInstanceReservation
     */
    courseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCourseInstanceReservation
     */
    remark?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetCourseInstanceReservation
     */
    courseSessionIds?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminGetCourseInstanceReservationStatusEnum {
    Open = 'open',
    Expired = 'expired',
    Canceled = 'canceled',
    Paid = 'paid'
}

/**
 * 
 * @export
 * @interface AdminGetCourseInstancesForMessageResponse
 */
export interface AdminGetCourseInstancesForMessageResponse {
    /**
     * 
     * @type {Array<CourseInstanceDto>}
     * @memberof AdminGetCourseInstancesForMessageResponse
     */
    data: Array<CourseInstanceDto>;
}
/**
 * 
 * @export
 * @interface AdminGetCourseSeriesDetailsResponse
 */
export interface AdminGetCourseSeriesDetailsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseSeriesDto}
     * @memberof AdminGetCourseSeriesDetailsResponse
     */
    data: CourseSeriesDto;
}
/**
 * 
 * @export
 * @interface AdminGetCourseSeriesForAssignStudentResponse
 */
export interface AdminGetCourseSeriesForAssignStudentResponse {
    /**
     * 
     * @type {Array<CourseSeriesDto>}
     * @memberof AdminGetCourseSeriesForAssignStudentResponse
     */
    data: Array<CourseSeriesDto>;
}
/**
 * 
 * @export
 * @interface AdminGetCourseSeriesResponse
 */
export interface AdminGetCourseSeriesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseSeriesDto>}
     * @memberof AdminGetCourseSeriesResponse
     */
    data: Array<CourseSeriesDto>;
}
/**
 * 
 * @export
 * @interface AdminGetCourseSessionDetailsResponse
 */
export interface AdminGetCourseSessionDetailsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseSessionDetailDto}
     * @memberof AdminGetCourseSessionDetailsResponse
     */
    data: CourseSessionDetailDto;
}
/**
 * 
 * @export
 * @interface AdminGetCourseSessionsResponse
 */
export interface AdminGetCourseSessionsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminCourseSessionDto>}
     * @memberof AdminGetCourseSessionsResponse
     */
    data: Array<AdminCourseSessionDto>;
}
/**
 * 
 * @export
 * @interface AdminGetCoursesByProviderResponse
 */
export interface AdminGetCoursesByProviderResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseDto>}
     * @memberof AdminGetCoursesByProviderResponse
     */
    data: Array<CourseDto>;
}
/**
 * 
 * @export
 * @interface AdminGetCoursesResponse
 */
export interface AdminGetCoursesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseInstanceDto>}
     * @memberof AdminGetCoursesResponse
     */
    data: Array<CourseInstanceDto>;
}
/**
 * 
 * @export
 * @interface AdminGetCreditConfigDto
 */
export interface AdminGetCreditConfigDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetCreditConfigDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetCreditConfigDto
     */
    expirationMonthNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetCreditConfigDto
     */
    minCheckoutAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCreditConfigDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCreditConfigDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface AdminGetCreditConfigResponse
 */
export interface AdminGetCreditConfigResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetCreditConfigDto>}
     * @memberof AdminGetCreditConfigResponse
     */
    data: Array<AdminGetCreditConfigDto>;
}
/**
 * 
 * @export
 * @interface AdminGetCreditListDto
 */
export interface AdminGetCreditListDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetCreditListDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCreditListDto
     */
    parentId?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetCreditListDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetCreditListDto
     */
    balanceAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCreditListDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCreditListDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCreditListDto
     */
    type?: AdminGetCreditListDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCreditListDto
     */
    invoiceReferenceId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminGetCreditListDtoTypeEnum {
    Credited = 'credited',
    Used = 'used',
    Expired = 'expired'
}

/**
 * 
 * @export
 * @interface AdminGetCreditListResponse
 */
export interface AdminGetCreditListResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetCreditListDto>}
     * @memberof AdminGetCreditListResponse
     */
    data: Array<AdminGetCreditListDto>;
}
/**
 * 
 * @export
 * @interface AdminGetCreditReasonDto
 */
export interface AdminGetCreditReasonDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetCreditReasonDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCreditReasonDto
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCreditReasonDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetCreditReasonDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface AdminGetCreditReasonResponse
 */
export interface AdminGetCreditReasonResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetCreditReasonDto>}
     * @memberof AdminGetCreditReasonResponse
     */
    data: Array<AdminGetCreditReasonDto>;
}
/**
 * 
 * @export
 * @interface AdminGetDailyScheduleRequest
 */
export interface AdminGetDailyScheduleRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetDailyScheduleRequest
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetDailyScheduleRequest
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetDailyScheduleRequest
     */
    findStudentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetDailyScheduleRequest
     */
    venueId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetDailyScheduleRequest
     */
    excludeAdhocCheckin?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetDailyScheduleRequest
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetDailyScheduleRequest
     */
    businessUnitIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetDailyScheduleRequest
     */
    courseIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetDailyScheduleRequest
     */
    courseClassIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminGetDailyScheduleRequest
     */
    teacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetDailyScheduleRequest
     */
    educationProviderId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetDailyScheduleRequest
     */
    showStudentUnassignedClass?: boolean;
}
/**
 * 
 * @export
 * @interface AdminGetDailyScheduleResponse
 */
export interface AdminGetDailyScheduleResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<DailyScheduleDto>}
     * @memberof AdminGetDailyScheduleResponse
     */
    data: Array<DailyScheduleDto>;
}
/**
 * 
 * @export
 * @interface AdminGetDiscountListResponse
 */
export interface AdminGetDiscountListResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<DiscountDto>}
     * @memberof AdminGetDiscountListResponse
     */
    data: Array<DiscountDto>;
}
/**
 * 
 * @export
 * @interface AdminGetEnrolledInstanceByStudentRequest
 */
export interface AdminGetEnrolledInstanceByStudentRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminGetEnrolledInstanceByStudentRequest
     */
    courseSeriesId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetEnrolledInstanceByStudentRequest
     */
    courseId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetEnrolledInstanceByStudentRequest
     */
    studentId: string;
}
/**
 * 
 * @export
 * @interface AdminGetEnrolledInstanceByStudentResponse
 */
export interface AdminGetEnrolledInstanceByStudentResponse {
    /**
     * 
     * @type {Array<CourseInstanceDto>}
     * @memberof AdminGetEnrolledInstanceByStudentResponse
     */
    data: Array<CourseInstanceDto>;
}
/**
 * 
 * @export
 * @interface AdminGetEnrolledStudentsResponse
 */
export interface AdminGetEnrolledStudentsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<EnrolledStudentDto>}
     * @memberof AdminGetEnrolledStudentsResponse
     */
    data: Array<EnrolledStudentDto>;
}
/**
 * 
 * @export
 * @interface AdminGetEpsByVenueIdResponse
 */
export interface AdminGetEpsByVenueIdResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<GetEpsByVenueIdDto>}
     * @memberof AdminGetEpsByVenueIdResponse
     */
    data: Array<GetEpsByVenueIdDto>;
}
/**
 * 
 * @export
 * @interface AdminGetEquipmentResponse
 */
export interface AdminGetEquipmentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<EquipmentDto>}
     * @memberof AdminGetEquipmentResponse
     */
    data: Array<EquipmentDto>;
}
/**
 * 
 * @export
 * @interface AdminGetExtraRoomResponse
 */
export interface AdminGetExtraRoomResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<RoomBookingDto>}
     * @memberof AdminGetExtraRoomResponse
     */
    data: Array<RoomBookingDto>;
}
/**
 * 
 * @export
 * @interface AdminGetFilterUserResponse
 */
export interface AdminGetFilterUserResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminFilterDto>}
     * @memberof AdminGetFilterUserResponse
     */
    data: Array<AdminFilterDto>;
}
/**
 * 
 * @export
 * @interface AdminGetInfoEpAgreementRequest
 */
export interface AdminGetInfoEpAgreementRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetInfoEpAgreementRequest
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetInfoEpAgreementRequest
     */
    educationProviderId: string;
}
/**
 * 
 * @export
 * @interface AdminGetInvoiceLinesResponse
 */
export interface AdminGetInvoiceLinesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminGetInvoiceLinesResponseData}
     * @memberof AdminGetInvoiceLinesResponse
     */
    data: AdminGetInvoiceLinesResponseData;
}
/**
 * 
 * @export
 * @interface AdminGetInvoiceLinesResponseData
 */
export interface AdminGetInvoiceLinesResponseData {
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetInvoiceLinesResponseData
     */
    reservedIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetInvoiceLinesResponseData
     */
    invoiceLineWithSessionPackAdditionIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetInvoiceLinesResponseData
     */
    courseInstanceIds?: Array<string>;
    /**
     * 
     * @type {Array<InvoiceLineNewDto>}
     * @memberof AdminGetInvoiceLinesResponseData
     */
    invoiceLineList: Array<InvoiceLineNewDto>;
    /**
     * 
     * @type {number}
     * @memberof AdminGetInvoiceLinesResponseData
     */
    total: number;
}
/**
 * 
 * @export
 * @interface AdminGetMailDomainsResponse
 */
export interface AdminGetMailDomainsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<MailDomain>}
     * @memberof AdminGetMailDomainsResponse
     */
    data: Array<MailDomain>;
}
/**
 * 
 * @export
 * @interface AdminGetMessageCategoryByIdResponseDto
 */
export interface AdminGetMessageCategoryByIdResponseDto {
    [key: string]: object | any;

    /**
     * 
     * @type {CategoryParentMessageDto}
     * @memberof AdminGetMessageCategoryByIdResponseDto
     */
    data: CategoryParentMessageDto;
}
/**
 * 
 * @export
 * @interface AdminGetMessageDetailsResponse
 */
export interface AdminGetMessageDetailsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {MessageNewDto}
     * @memberof AdminGetMessageDetailsResponse
     */
    data: MessageNewDto;
}
/**
 * 
 * @export
 * @interface AdminGetMessageListResponse
 */
export interface AdminGetMessageListResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<MessageNewDto>}
     * @memberof AdminGetMessageListResponse
     */
    data: Array<MessageNewDto>;
}
/**
 * 
 * @export
 * @interface AdminGetMessagesResponse
 */
export interface AdminGetMessagesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminGetMessagesResponseData}
     * @memberof AdminGetMessagesResponse
     */
    data: AdminGetMessagesResponseData;
}
/**
 * 
 * @export
 * @interface AdminGetMessagesResponseData
 */
export interface AdminGetMessagesResponseData {
    /**
     * 
     * @type {number}
     * @memberof AdminGetMessagesResponseData
     */
    count: number;
    /**
     * 
     * @type {Array<MessageNewDto>}
     * @memberof AdminGetMessagesResponseData
     */
    messages: Array<MessageNewDto>;
}
/**
 * 
 * @export
 * @interface AdminGetMoreDataRoomViewResponse
 */
export interface AdminGetMoreDataRoomViewResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetMoreDataRoomViewDto}
     * @memberof AdminGetMoreDataRoomViewResponse
     */
    data: GetMoreDataRoomViewDto;
}
/**
 * 
 * @export
 * @interface AdminGetParentByStudentIdResponse
 */
export interface AdminGetParentByStudentIdResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetParentByStudentIdDto}
     * @memberof AdminGetParentByStudentIdResponse
     */
    data: GetParentByStudentIdDto;
}
/**
 * 
 * @export
 * @interface AdminGetParentInfoDto
 */
export interface AdminGetParentInfoDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    fullname?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    userType?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    otherUserType?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    grade?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetParentInfoDto
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetParentInfoDto
     */
    maxAge?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    parentRelationShipType?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    gender?: AdminGetParentInfoDtoGenderEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetParentInfoDto
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetParentInfoDto
     */
    venueNames?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetParentInfoDto
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminGetParentInfoDto
     */
    kisiUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetParentInfoDto
     */
    isInvited?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    invoiceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    invoiceEmailAddress?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetParentInfoDto
     */
    isEmergencyContact?: boolean;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof AdminGetParentInfoDto
     */
    venues?: Array<CompanyDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    tempPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    postCode?: string;
    /**
     * 
     * @type {Array<IBrand>}
     * @memberof AdminGetParentInfoDto
     */
    brands?: Array<IBrand>;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetParentInfoDto
     */
    totalPurchasedCourses?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetParentInfoDto
     */
    totalPurchasedPlanHours?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetParentInfoDto
     */
    totalRemainingAgoraAccessHours?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetParentInfoDto
     */
    totalRemainingAgoraSubscriptionHours?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetParentInfoDto
     */
    balanceCredit?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetParentInfoDto
     */
    balanceCreditExpiredDate?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetParentInfoDto
     */
    creditExpiredDateList?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminGetParentInfoDtoGenderEnum {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface AdminGetParentInfoResponse
 */
export interface AdminGetParentInfoResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminGetParentInfoDto}
     * @memberof AdminGetParentInfoResponse
     */
    data: AdminGetParentInfoDto;
}
/**
 * 
 * @export
 * @interface AdminGetParentNotes
 */
export interface AdminGetParentNotes {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetParentNotes
     */
    data?: string;
}
/**
 * 
 * @export
 * @interface AdminGetParentStudentScheduleResponse
 */
export interface AdminGetParentStudentScheduleResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SchedulesDto>}
     * @memberof AdminGetParentStudentScheduleResponse
     */
    data: Array<SchedulesDto>;
}
/**
 * 
 * @export
 * @interface AdminGetParentsForMessageResponse
 */
export interface AdminGetParentsForMessageResponse {
    /**
     * 
     * @type {Array<ParentDto>}
     * @memberof AdminGetParentsForMessageResponse
     */
    data: Array<ParentDto>;
}
/**
 * 
 * @export
 * @interface AdminGetPermissionsResponse
 */
export interface AdminGetPermissionsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<PermissionsAttributes>}
     * @memberof AdminGetPermissionsResponse
     */
    data: Array<PermissionsAttributes>;
}
/**
 * 
 * @export
 * @interface AdminGetProductBySKUResponse
 */
export interface AdminGetProductBySKUResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminProductDto}
     * @memberof AdminGetProductBySKUResponse
     */
    data: AdminProductDto;
}
/**
 * 
 * @export
 * @interface AdminGetProductListResponse
 */
export interface AdminGetProductListResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof AdminGetProductListResponse
     */
    data: Array<ProductDto>;
}
/**
 * 
 * @export
 * @interface AdminGetRateCardDto
 */
export interface AdminGetRateCardDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetRateCardDto
     */
    roomTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRateCardDto
     */
    roomTypeName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRateCardDto
     */
    rateCardRoomTypeId?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetRateCardDto
     */
    peakTakeRate?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetRateCardDto
     */
    peakFlatFee?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetRateCardDto
     */
    offPeakTakeRate?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetRateCardDto
     */
    offPeakFlatFee?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetRateCardDto
     */
    costPerStudent?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRateCardDto
     */
    rateCardType: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRateCardDto
     */
    dateRange: string;
}
/**
 * 
 * @export
 * @interface AdminGetRateCardResponse
 */
export interface AdminGetRateCardResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetRateCardDto>}
     * @memberof AdminGetRateCardResponse
     */
    data: Array<AdminGetRateCardDto>;
}
/**
 * 
 * @export
 * @interface AdminGetRefundConfigDto
 */
export interface AdminGetRefundConfigDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetRefundConfigDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRefundConfigDto
     */
    refundType?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetRefundConfigDto
     */
    minNoticePeriod?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetRefundConfigDto
     */
    penalty?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminGetRefundConfigDto
     */
    percentOfPenalty?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetRefundConfigDto
     */
    approval?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminGetRefundConfigDto
     */
    maxNoticePeriod?: number;
}
/**
 * 
 * @export
 * @interface AdminGetRefundConfigResponse
 */
export interface AdminGetRefundConfigResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetRefundConfigDto>}
     * @memberof AdminGetRefundConfigResponse
     */
    data: Array<AdminGetRefundConfigDto>;
}
/**
 * 
 * @export
 * @interface AdminGetRefundParentDto
 */
export interface AdminGetRefundParentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetRefundParentDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRefundParentDto
     */
    studentChineseName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRefundParentDto
     */
    courseName?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetRefundParentDto
     */
    refundAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRefundParentDto
     */
    refundDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRefundParentDto
     */
    approvalName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRefundParentDto
     */
    approvalDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRefundParentDto
     */
    refund_status?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRefundParentDto
     */
    hitpay_error?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRefundParentDto
     */
    refundType?: string;
    /**
     * 
     * @type {RefundMethod}
     * @memberof AdminGetRefundParentDto
     */
    refundMethod?: RefundMethod;
}
/**
 * 
 * @export
 * @interface AdminGetRefundParentResponse
 */
export interface AdminGetRefundParentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetRefundParentDto>}
     * @memberof AdminGetRefundParentResponse
     */
    data: Array<AdminGetRefundParentDto>;
}
/**
 * 
 * @export
 * @interface AdminGetRegistrationResponse
 */
export interface AdminGetRegistrationResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<RegistrationLogDto>}
     * @memberof AdminGetRegistrationResponse
     */
    data: Array<RegistrationLogDto>;
}
/**
 * 
 * @export
 * @interface AdminGetReplacementConfiguration
 */
export interface AdminGetReplacementConfiguration {
    [key: string]: object | any;

    /**
     * 
     * @type {ReplacementConfigAttributes}
     * @memberof AdminGetReplacementConfiguration
     */
    data: ReplacementConfigAttributes | null;
}
/**
 * 
 * @export
 * @interface AdminGetReplacementResponse
 */
export interface AdminGetReplacementResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<GetReplacementHistory>}
     * @memberof AdminGetReplacementResponse
     */
    data: Array<GetReplacementHistory>;
}
/**
 * 
 * @export
 * @interface AdminGetReplacementSessionAvailableRequest
 */
export interface AdminGetReplacementSessionAvailableRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetReplacementSessionAvailableRequest
     */
    startTimeOriginal?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetReplacementSessionAvailableRequest
     */
    endTimeOriginal?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetReplacementSessionAvailableRequest
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetReplacementSessionAvailableRequest
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetReplacementSessionAvailableRequest
     */
    originalSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetReplacementSessionAvailableRequest
     */
    courseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetReplacementSessionAvailableRequest
     */
    courseInstanceOriginalId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetReplacementSessionAvailableRequest
     */
    courseInstanceReferenceId?: string;
}
/**
 * 
 * @export
 * @interface AdminGetReplacementSessionAvailableResponse
 */
export interface AdminGetReplacementSessionAvailableResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ReplacementAvailabilityDto>}
     * @memberof AdminGetReplacementSessionAvailableResponse
     */
    data: Array<ReplacementAvailabilityDto>;
}
/**
 * 
 * @export
 * @interface AdminGetReservedDataResponse
 */
export interface AdminGetReservedDataResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ReservedSeatDto>}
     * @memberof AdminGetReservedDataResponse
     */
    data: Array<ReservedSeatDto>;
}
/**
 * 
 * @export
 * @interface AdminGetReservedSeatsResponse
 */
export interface AdminGetReservedSeatsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ReservedSeatDto>}
     * @memberof AdminGetReservedSeatsResponse
     */
    data: Array<ReservedSeatDto>;
}
/**
 * 
 * @export
 * @interface AdminGetRevenueRefundDto
 */
export interface AdminGetRevenueRefundDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetRevenueRefundDto
     */
    revenueId?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetRevenueRefundDto
     */
    sessionValue?: number;
}
/**
 * 
 * @export
 * @interface AdminGetRevenueRefundRequest
 */
export interface AdminGetRevenueRefundRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetRevenueRefundRequest
     */
    studentId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetRevenueRefundRequest
     */
    courseSessionIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRevenueRefundRequest
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRevenueRefundRequest
     */
    invoiceLineId: string;
}
/**
 * 
 * @export
 * @interface AdminGetRevenueRefundResponse
 */
export interface AdminGetRevenueRefundResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetRevenueRefundDto>}
     * @memberof AdminGetRevenueRefundResponse
     */
    data: Array<AdminGetRevenueRefundDto>;
}
/**
 * 
 * @export
 * @interface AdminGetRolesPermissionsResponse
 */
export interface AdminGetRolesPermissionsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<RolesPermissionsDto>}
     * @memberof AdminGetRolesPermissionsResponse
     */
    data: Array<RolesPermissionsDto>;
}
/**
 * 
 * @export
 * @interface AdminGetRolesResponse
 */
export interface AdminGetRolesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<RolesAttributes>}
     * @memberof AdminGetRolesResponse
     */
    data: Array<RolesAttributes>;
}
/**
 * 
 * @export
 * @interface AdminGetRoomBookingInvoicesRequest
 */
export interface AdminGetRoomBookingInvoicesRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetRoomBookingInvoicesRequest
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetRoomBookingInvoicesRequest
     */
    providerIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetRoomBookingInvoicesRequest
     */
    businessUnitIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRoomBookingInvoicesRequest
     */
    status?: string;
    /**
     * 
     * @type {Array<InvoiceListType>}
     * @memberof AdminGetRoomBookingInvoicesRequest
     */
    invoiceListType?: Array<InvoiceListType>;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRoomBookingInvoicesRequest
     */
    search?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetRoomBookingInvoicesRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetRoomBookingInvoicesRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetRoomBookingInvoicesRequest
     */
    type?: AdminGetRoomBookingInvoicesRequestTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminGetRoomBookingInvoicesRequestTypeEnum {
    Invoice = 'invoice',
    InvoiceLine = 'invoice-line'
}

/**
 * 
 * @export
 * @interface AdminGetRoomCalendarResponse
 */
export interface AdminGetRoomCalendarResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<RoomBookingChartDto>}
     * @memberof AdminGetRoomCalendarResponse
     */
    data: Array<RoomBookingChartDto>;
}
/**
 * 
 * @export
 * @interface AdminGetRoomCheckInMediaResponse
 */
export interface AdminGetRoomCheckInMediaResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<GetRoomCheckInMediaDto>}
     * @memberof AdminGetRoomCheckInMediaResponse
     */
    data: Array<GetRoomCheckInMediaDto>;
}
/**
 * 
 * @export
 * @interface AdminGetRoomCheckInReportResponse
 */
export interface AdminGetRoomCheckInReportResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<GetRoomCheckInReportDto>}
     * @memberof AdminGetRoomCheckInReportResponse
     */
    data: Array<GetRoomCheckInReportDto>;
}
/**
 * 
 * @export
 * @interface AdminGetScheduleJobsResponse
 */
export interface AdminGetScheduleJobsResponse {
    /**
     * 
     * @type {Array<ScheduleJobAttributes>}
     * @memberof AdminGetScheduleJobsResponse
     */
    data: Array<ScheduleJobAttributes>;
}
/**
 * 
 * @export
 * @interface AdminGetSchoolRequest
 */
export interface AdminGetSchoolRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SchoolDto>}
     * @memberof AdminGetSchoolRequest
     */
    data: Array<SchoolDto>;
}
/**
 * 
 * @export
 * @interface AdminGetSelectResponse
 */
export interface AdminGetSelectResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {SelectDto}
     * @memberof AdminGetSelectResponse
     */
    data: SelectDto;
}
/**
 * 
 * @export
 * @interface AdminGetSentEmailsByUserIdResponse
 */
export interface AdminGetSentEmailsByUserIdResponse {
    /**
     * 
     * @type {Array<SentEmailsAttributes>}
     * @memberof AdminGetSentEmailsByUserIdResponse
     */
    data: Array<SentEmailsAttributes>;
}
/**
 * 
 * @export
 * @interface AdminGetSessionPackResponse
 */
export interface AdminGetSessionPackResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SessionPackDto>}
     * @memberof AdminGetSessionPackResponse
     */
    data: Array<SessionPackDto>;
}
/**
 * 
 * @export
 * @interface AdminGetSessionStudentNotAttendingDto
 */
export interface AdminGetSessionStudentNotAttendingDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetSessionStudentNotAttendingDto
     */
    courseSessionId?: string;
}
/**
 * 
 * @export
 * @interface AdminGetSessionStudentNotAttendingResponse
 */
export interface AdminGetSessionStudentNotAttendingResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetSessionStudentNotAttendingDto>}
     * @memberof AdminGetSessionStudentNotAttendingResponse
     */
    data: Array<AdminGetSessionStudentNotAttendingDto>;
}
/**
 * 
 * @export
 * @interface AdminGetSessionsByCourseIdResponse
 */
export interface AdminGetSessionsByCourseIdResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseSessionDto>}
     * @memberof AdminGetSessionsByCourseIdResponse
     */
    data: Array<CourseSessionDto>;
}
/**
 * 
 * @export
 * @interface AdminGetSessionsByDateResponse
 */
export interface AdminGetSessionsByDateResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseSessionDto>}
     * @memberof AdminGetSessionsByDateResponse
     */
    data: Array<CourseSessionDto>;
}
/**
 * 
 * @export
 * @interface AdminGetSiblingCheckinRequest
 */
export interface AdminGetSiblingCheckinRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetSiblingCheckinRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetSiblingCheckinRequest
     */
    date: string;
}
/**
 * 
 * @export
 * @interface AdminGetSiblingCheckinResponse
 */
export interface AdminGetSiblingCheckinResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<UserCheckin>}
     * @memberof AdminGetSiblingCheckinResponse
     */
    data: Array<UserCheckin>;
}
/**
 * 
 * @export
 * @interface AdminGetStartDateForInvoiceLineRequest
 */
export interface AdminGetStartDateForInvoiceLineRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetStartDateForInvoiceLineRequest
     */
    courseInstanceIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetStartDateForInvoiceLineRequest
     */
    invoiceLineIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetStartDateForInvoiceLineRequest
     */
    reservedIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface AdminGetStartDateForInvoiceLineResponse
 */
export interface AdminGetStartDateForInvoiceLineResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<StartDateForInvoiceLineDto>}
     * @memberof AdminGetStartDateForInvoiceLineResponse
     */
    data: Array<StartDateForInvoiceLineDto>;
}
/**
 * 
 * @export
 * @interface AdminGetStudentCanReserve
 */
export interface AdminGetStudentCanReserve {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCanReserve
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCanReserve
     */
    parentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCanReserve
     */
    studentName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCanReserve
     */
    parentName: string;
}
/**
 * 
 * @export
 * @interface AdminGetStudentCanTrialSessionRequest
 */
export interface AdminGetStudentCanTrialSessionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCanTrialSessionRequest
     */
    applyTrialSession?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCanTrialSessionRequest
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCanTrialSessionRequest
     */
    courseSessionId?: string;
}
/**
 * 
 * @export
 * @interface AdminGetStudentCanTrialSessionResponse
 */
export interface AdminGetStudentCanTrialSessionResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ChildDto>}
     * @memberof AdminGetStudentCanTrialSessionResponse
     */
    data: Array<ChildDto>;
}
/**
 * 
 * @export
 * @interface AdminGetStudentCheckInCheckOutRequest
 */
export interface AdminGetStudentCheckInCheckOutRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCheckInCheckOutRequest
     */
    date: string;
}
/**
 * 
 * @export
 * @interface AdminGetStudentCheckInCheckOutResponse
 */
export interface AdminGetStudentCheckInCheckOutResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<StudentCheckInCheckOutDto>}
     * @memberof AdminGetStudentCheckInCheckOutResponse
     */
    data: Array<StudentCheckInCheckOutDto>;
}
/**
 * 
 * @export
 * @interface AdminGetStudentCourseInstancesLevelResponse
 */
export interface AdminGetStudentCourseInstancesLevelResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<StudentCoursesLevelDto>}
     * @memberof AdminGetStudentCourseInstancesLevelResponse
     */
    data: Array<StudentCoursesLevelDto>;
}
/**
 * 
 * @export
 * @interface AdminGetStudentCourses
 */
export interface AdminGetStudentCourses {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCourses
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCourses
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCourses
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCourses
     */
    provider?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCourses
     */
    teacher: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCourses
     */
    fromSession: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCourses
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCourses
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCourses
     */
    numOfSessions: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCourses
     */
    status: string;
    /**
     * 
     * @type {string | number}
     * @memberof AdminGetStudentCourses
     */
    price: string | number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentCourses
     */
    startTime: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetStudentCourses
     */
    allowReschedule?: boolean;
}
/**
 * 
 * @export
 * @interface AdminGetStudentCoursesLevelResponse
 */
export interface AdminGetStudentCoursesLevelResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<StudentCoursesLevelDto>}
     * @memberof AdminGetStudentCoursesLevelResponse
     */
    data: Array<StudentCoursesLevelDto>;
}
/**
 * 
 * @export
 * @interface AdminGetStudentCoursesResponse
 */
export interface AdminGetStudentCoursesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetStudentCourses>}
     * @memberof AdminGetStudentCoursesResponse
     */
    data: Array<AdminGetStudentCourses>;
}
/**
 * 
 * @export
 * @interface AdminGetStudentDto
 */
export interface AdminGetStudentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    fullname?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    userType?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    otherUserType?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    grade?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetStudentDto
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetStudentDto
     */
    maxAge?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    parentRelationShipType?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    gender?: AdminGetStudentDtoGenderEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetStudentDto
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetStudentDto
     */
    venueNames?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetStudentDto
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminGetStudentDto
     */
    kisiUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetStudentDto
     */
    isInvited?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    invoiceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    invoiceEmailAddress?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetStudentDto
     */
    isEmergencyContact?: boolean;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof AdminGetStudentDto
     */
    venues?: Array<CompanyDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    tempPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    postCode?: string;
    /**
     * 
     * @type {Array<IBrand>}
     * @memberof AdminGetStudentDto
     */
    brands?: Array<IBrand>;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    schoolName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetStudentDto
     */
    isAllowStudentLeave?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    chineseName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    schoolId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    otherSchool?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    classId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    otherClass?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentDto
     */
    gradeLevelId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminGetStudentDtoGenderEnum {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface AdminGetStudentInfoResponse
 */
export interface AdminGetStudentInfoResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminGetStudentDto}
     * @memberof AdminGetStudentInfoResponse
     */
    data: AdminGetStudentDto;
}
/**
 * 
 * @export
 * @interface AdminGetStudentListToAddStudentCareResponse
 */
export interface AdminGetStudentListToAddStudentCareResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ChildrenDto>}
     * @memberof AdminGetStudentListToAddStudentCareResponse
     */
    data: Array<ChildrenDto>;
}
/**
 * 
 * @export
 * @interface AdminGetStudentNoteTypesResponse
 */
export interface AdminGetStudentNoteTypesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<StudentNoteTypeDto>}
     * @memberof AdminGetStudentNoteTypesResponse
     */
    data: Array<StudentNoteTypeDto>;
}
/**
 * 
 * @export
 * @interface AdminGetStudentNotesResponse
 */
export interface AdminGetStudentNotesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<StudentNotesDto>}
     * @memberof AdminGetStudentNotesResponse
     */
    data: Array<StudentNotesDto>;
}
/**
 * 
 * @export
 * @interface AdminGetStudentPaymentHistoryGroupedType
 */
export interface AdminGetStudentPaymentHistoryGroupedType {
    /**
     * 
     * @type {CourseEnrolmentDto}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    courseEnrolment?: CourseEnrolmentDto;
    /**
     * 
     * @type {ProviderDto}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    provider: ProviderDto;
    /**
     * 
     * @type {CourseDto}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    course: CourseDto;
    /**
     * 
     * @type {GstDto}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    gst: GstDto;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    isAllowEditSubscription: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    isAllowCreateSubscription: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    isAllowWithdraw: boolean;
    /**
     * 
     * @type {CourseSeriesDto}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    courseSeries?: CourseSeriesDto;
    /**
     * 
     * @type {Array<AdminGetStudentPaymentHistoryType>}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    histories: Array<AdminGetStudentPaymentHistoryType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    classes?: Array<string>;
    /**
     * 
     * @type {Array<StudentSessionTimeCheckinDto>}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    sessionTimes?: Array<StudentSessionTimeCheckinDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    hasSessionPack?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    sessionPackLeft?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    paymentStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    enrolmentStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    startDate?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    parent?: UserDto;
    /**
     * 
     * @type {ChildDto}
     * @memberof AdminGetStudentPaymentHistoryGroupedType
     */
    student: ChildDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AdminGetStudentPaymentHistoryMethodType {
    Checkout = 'checkout',
    Invoice = 'invoice',
    Subscription = 'subscription'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum AdminGetStudentPaymentHistoryModelType {
    RevenueShare = 'revenue_share',
    SessionPack = 'session_pack',
    Subscription = 'subscription'
}

/**
 * 
 * @export
 * @interface AdminGetStudentPaymentHistoryType
 */
export interface AdminGetStudentPaymentHistoryType {
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    sentOn?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    rescheduleToCourseInstanceStartTime?: string;
    /**
     * 
     * @type {TransactionPaymentType}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    transactionType?: TransactionPaymentType;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    hasSessionPack?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    status?: string;
    /**
     * 
     * @type {CourseEnrolmentType}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    courseEnrolment?: CourseEnrolmentType;
    /**
     * 
     * @type {EnrollmentType}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    enrollment?: EnrollmentType;
    /**
     * 
     * @type {AdminGetStudentPaymentHistoryModelType}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    model?: AdminGetStudentPaymentHistoryModelType;
    /**
     * 
     * @type {AdminGetStudentPaymentHistoryMethodType}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    method?: AdminGetStudentPaymentHistoryMethodType;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    paymentDate?: string;
    /**
     * 
     * @type {CompanyDto}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    venue?: CompanyDto;
    /**
     * 
     * @type {SessionPackDto}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    sessionPack?: SessionPackDto;
    /**
     * 
     * @type {CourseSeriesDto}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    courseSeries?: CourseSeriesDto;
    /**
     * 
     * @type {SubscriptionDto}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    subscription?: SubscriptionDto;
    /**
     * 
     * @type {CourseDto}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    course?: CourseDto;
    /**
     * 
     * @type {number}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    refundAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    paidAmount?: number;
    /**
     * 
     * @type {InvoiceDto}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    invoice?: InvoiceDto;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    instanceStart?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    dueDate?: string;
    /**
     * 
     * @type {ProviderDto}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    provider?: ProviderDto;
    /**
     * 
     * @type {UserDto}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    parent?: UserDto;
    /**
     * 
     * @type {ChildDto}
     * @memberof AdminGetStudentPaymentHistoryType
     */
    student?: ChildDto;
}
/**
 * 
 * @export
 * @interface AdminGetStudentReservedCourseInstancesDto
 */
export interface AdminGetStudentReservedCourseInstancesDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentReservedCourseInstancesDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentReservedCourseInstancesDto
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentReservedCourseInstancesDto
     */
    teacherName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentReservedCourseInstancesDto
     */
    fromSession?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentReservedCourseInstancesDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentReservedCourseInstancesDto
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentReservedCourseInstancesDto
     */
    sessions?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentReservedCourseInstancesDto
     */
    price?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentReservedCourseInstancesDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentReservedCourseInstancesDto
     */
    expiredTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminGetStudentReservedCourseInstancesDto
     */
    isTrialSingleSession?: boolean;
}
/**
 * 
 * @export
 * @interface AdminGetStudentReservedCourseInstancesResponse
 */
export interface AdminGetStudentReservedCourseInstancesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetStudentReservedCourseInstancesDto>}
     * @memberof AdminGetStudentReservedCourseInstancesResponse
     */
    data: Array<AdminGetStudentReservedCourseInstancesDto>;
}
/**
 * 
 * @export
 * @interface AdminGetStudentWithPaymentHistoryNewRequest
 */
export interface AdminGetStudentWithPaymentHistoryNewRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentWithPaymentHistoryNewRequest
     */
    paymentStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentWithPaymentHistoryNewRequest
     */
    enrolStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentWithPaymentHistoryNewRequest
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetStudentWithPaymentHistoryNewRequest
     */
    courseId: string;
}
/**
 * 
 * @export
 * @interface AdminGetStudentWithPaymentHistoryNewResponse
 */
export interface AdminGetStudentWithPaymentHistoryNewResponse {
    /**
     * 
     * @type {Array<AdminGetStudentPaymentHistoryGroupedType>}
     * @memberof AdminGetStudentWithPaymentHistoryNewResponse
     */
    data: Array<AdminGetStudentPaymentHistoryGroupedType>;
}
/**
 * 
 * @export
 * @interface AdminGetStudentsCanReserveResponse
 */
export interface AdminGetStudentsCanReserveResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetStudentCanReserve>}
     * @memberof AdminGetStudentsCanReserveResponse
     */
    data: Array<AdminGetStudentCanReserve>;
}
/**
 * 
 * @export
 * @interface AdminGetStudentsForMessageResponse
 */
export interface AdminGetStudentsForMessageResponse {
    /**
     * 
     * @type {Array<ChildDto>}
     * @memberof AdminGetStudentsForMessageResponse
     */
    data: Array<ChildDto>;
}
/**
 * 
 * @export
 * @interface AdminGetSubscriptionByIdResponse
 */
export interface AdminGetSubscriptionByIdResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {SubscriptionDto}
     * @memberof AdminGetSubscriptionByIdResponse
     */
    data: SubscriptionDto;
}
/**
 * 
 * @export
 * @interface AdminGetSubscriptionListRequest
 */
export interface AdminGetSubscriptionListRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetSubscriptionListRequest
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetSubscriptionListRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetSubscriptionListRequest
     */
    search?: string;
}
/**
 * 
 * @export
 * @interface AdminGetSubscriptionListResponse
 */
export interface AdminGetSubscriptionListResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SubscriptionDto>}
     * @memberof AdminGetSubscriptionListResponse
     */
    data: Array<SubscriptionDto>;
}
/**
 * 
 * @export
 * @interface AdminGetTagResponse
 */
export interface AdminGetTagResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminTagDto>}
     * @memberof AdminGetTagResponse
     */
    data: Array<AdminTagDto>;
}
/**
 * 
 * @export
 * @interface AdminGetTeacherDto
 */
export interface AdminGetTeacherDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetTeacherDto
     */
    teacherId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTeacherDto
     */
    teacherName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTeacherDto
     */
    teacherAvatar: string;
}
/**
 * 
 * @export
 * @interface AdminGetTeacherMOEAreasResponse
 */
export interface AdminGetTeacherMOEAreasResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminMOEAreaDto>}
     * @memberof AdminGetTeacherMOEAreasResponse
     */
    data: Array<AdminMOEAreaDto>;
}
/**
 * 
 * @export
 * @interface AdminGetTeacherMOEsResponse
 */
export interface AdminGetTeacherMOEsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminTeacherMOEDto>}
     * @memberof AdminGetTeacherMOEsResponse
     */
    data: Array<AdminTeacherMOEDto>;
}
/**
 * 
 * @export
 * @interface AdminGetTeachersResponse
 */
export interface AdminGetTeachersResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminTeacherDto>}
     * @memberof AdminGetTeachersResponse
     */
    data: Array<AdminTeacherDto>;
}
/**
 * 
 * @export
 * @interface AdminGetTeamDetailResponse
 */
export interface AdminGetTeamDetailResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminTeacherDto}
     * @memberof AdminGetTeamDetailResponse
     */
    data: AdminTeacherDto;
}
/**
 * 
 * @export
 * @interface AdminGetTeamResponse
 */
export interface AdminGetTeamResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminTeacherDto>}
     * @memberof AdminGetTeamResponse
     */
    data: Array<AdminTeacherDto>;
}
/**
 * 
 * @export
 * @interface AdminGetTicketParentDto
 */
export interface AdminGetTicketParentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetTicketParentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTicketParentDto
     */
    subject?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetTicketParentDto
     */
    description?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTicketParentDto
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface AdminGetTicketParentResponse
 */
export interface AdminGetTicketParentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetTicketParentDto>}
     * @memberof AdminGetTicketParentResponse
     */
    data: Array<AdminGetTicketParentDto>;
}
/**
 * 
 * @export
 * @interface AdminGetTotalParentsRequest
 */
export interface AdminGetTotalParentsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetTotalParentsRequest
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetTotalParentsRequest
     */
    courseInstanceIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetTotalParentsRequest
     */
    courseClassIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetTotalParentsRequest
     */
    studentIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTotalParentsRequest
     */
    parentId?: string;
    /**
     * 
     * @type {MessageSentTo}
     * @memberof AdminGetTotalParentsRequest
     */
    sentTo: MessageSentTo;
}
/**
 * 
 * @export
 * @interface AdminGetTransactionRefundedDto
 */
export interface AdminGetTransactionRefundedDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof AdminGetTransactionRefundedDto
     */
    refundAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionRefundedDto
     */
    refundMethod?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetTransactionRefundedDto
     */
    courseSessionIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface AdminGetTransactionRefundedResponse
 */
export interface AdminGetTransactionRefundedResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetTransactionRefundedDto>}
     * @memberof AdminGetTransactionRefundedResponse
     */
    data: Array<AdminGetTransactionRefundedDto>;
}
/**
 * 
 * @export
 * @interface AdminGetTransactionsParentDto
 */
export interface AdminGetTransactionsParentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    financialTransactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    paymentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    transactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    courseReferenceId?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetTransactionsParentDto
     */
    courseInstanceId?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    courseSessionStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    productType?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    invoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    invoiceReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    courseName?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetTransactionsParentDto
     */
    originalCourseFee?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    epName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    csNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    enroll_from?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    sessionPackAdditionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    sessionPacksStatus?: AdminGetTransactionsParentDtoSessionPacksStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AdminGetTransactionsParentDto
     */
    numberOfSessions?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetTransactionsParentDto
     */
    numberOfSessionsRemaining?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    refundHistoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    paymentCreatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetTransactionsParentDto
     */
    campaignPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetTransactionsParentDto
     */
    creditAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetTransactionsParentDto
     */
    discountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetTransactionsParentDto
     */
    financialTransactionAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    enrollmentId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetTransactionsParentDto
     */
    courseSessionIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetTransactionsParentDto
     */
    refundProcessingSessionIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    enrollmentStatus?: AdminGetTransactionsParentDtoEnrollmentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    productSku?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminGetTransactionsParentDto
     */
    sessionRefundedIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTransactionsParentDto
     */
    data?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminGetTransactionsParentDtoSessionPacksStatusEnum {
    Reserved = 'reserved',
    Active = 'active',
    Expired = 'expired',
    Consumed = 'consumed'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminGetTransactionsParentDtoEnrollmentStatusEnum {
    Refunded = 'refunded',
    Available = 'available',
    Rescheduled = 'rescheduled'
}

/**
 * 
 * @export
 * @interface AdminGetTransactionsParentResponse
 */
export interface AdminGetTransactionsParentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetTransactionsParentDto>}
     * @memberof AdminGetTransactionsParentResponse
     */
    data: Array<AdminGetTransactionsParentDto>;
}
/**
 * 
 * @export
 * @interface AdminGetTrialCourseInstanceResponse
 */
export interface AdminGetTrialCourseInstanceResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<TrialCourseInstanceDto>}
     * @memberof AdminGetTrialCourseInstanceResponse
     */
    data: Array<TrialCourseInstanceDto>;
}
/**
 * 
 * @export
 * @interface AdminGetTrialSessionsOfStudentDto
 */
export interface AdminGetTrialSessionsOfStudentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGetTrialSessionsOfStudentDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTrialSessionsOfStudentDto
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTrialSessionsOfStudentDto
     */
    teacherName?: string;
    /**
     * 
     * @type {string | number}
     * @memberof AdminGetTrialSessionsOfStudentDto
     */
    price?: string | number;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTrialSessionsOfStudentDto
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTrialSessionsOfStudentDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTrialSessionsOfStudentDto
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetTrialSessionsOfStudentDto
     */
    sessionNo?: string;
}
/**
 * 
 * @export
 * @interface AdminGetTrialSessionsOfStudentResponse
 */
export interface AdminGetTrialSessionsOfStudentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AdminGetTrialSessionsOfStudentDto>}
     * @memberof AdminGetTrialSessionsOfStudentResponse
     */
    data: Array<AdminGetTrialSessionsOfStudentDto>;
}
/**
 * 
 * @export
 * @interface AdminGetUserResponse
 */
export interface AdminGetUserResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<UserDto>}
     * @memberof AdminGetUserResponse
     */
    data: Array<UserDto>;
}
/**
 * 
 * @export
 * @interface AdminGetVenueResponse
 */
export interface AdminGetVenueResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof AdminGetVenueResponse
     */
    data: Array<CompanyDto>;
}
/**
 * 
 * @export
 * @interface AdminGetVenueRoomsResponse
 */
export interface AdminGetVenueRoomsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<RoomDto>}
     * @memberof AdminGetVenueRoomsResponse
     */
    data: Array<RoomDto>;
}
/**
 * 
 * @export
 * @interface AdminGetVenuesForMessageResponse
 */
export interface AdminGetVenuesForMessageResponse {
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof AdminGetVenuesForMessageResponse
     */
    data: Array<CompanyDto>;
}
/**
 * 
 * @export
 * @interface AdminGetVoucherCampaignsResponse
 */
export interface AdminGetVoucherCampaignsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CampaignDto>}
     * @memberof AdminGetVoucherCampaignsResponse
     */
    data: Array<CampaignDto>;
}
/**
 * 
 * @export
 * @interface AdminGetVoucherHistoriesResponse
 */
export interface AdminGetVoucherHistoriesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<VoucherHistoryDto>}
     * @memberof AdminGetVoucherHistoriesResponse
     */
    data: Array<VoucherHistoryDto>;
}
/**
 * 
 * @export
 * @interface AdminGetVoucherRecordsResponse
 */
export interface AdminGetVoucherRecordsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CampaignVoucherRecordAttributes>}
     * @memberof AdminGetVoucherRecordsResponse
     */
    data: Array<CampaignVoucherRecordAttributes>;
}
/**
 * 
 * @export
 * @interface AdminGstRatePriceDto
 */
export interface AdminGstRatePriceDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminGstRatePriceDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminGstRatePriceDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminGstRatePriceDto
     */
    rate?: number;
}
/**
 * 
 * @export
 * @interface AdminHandleAfterConfirmSeatsCopyCourseInstanceRequest
 */
export interface AdminHandleAfterConfirmSeatsCopyCourseInstanceRequest {
    /**
     * 
     * @type {PaymentAutoConfirmWithZeroCostRequest}
     * @memberof AdminHandleAfterConfirmSeatsCopyCourseInstanceRequest
     */
    data: PaymentAutoConfirmWithZeroCostRequest;
}
/**
 * 
 * @export
 * @interface AdminMOEAreaDto
 */
export interface AdminMOEAreaDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminMOEAreaDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMOEAreaDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMOEAreaDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMOEAreaDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface AdminMarkAttendingSessionRequest
 */
export interface AdminMarkAttendingSessionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminMarkAttendingSessionRequest
     */
    sessionId?: string;
    /**
     * 
     * @type {Array<StudentAttendingSessionDto>}
     * @memberof AdminMarkAttendingSessionRequest
     */
    students?: Array<StudentAttendingSessionDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminMarkAttendingSessionRequest
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface AdminMarkAttendingSessionResponse
 */
export interface AdminMarkAttendingSessionResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminMarkAttendingSessionResponseData}
     * @memberof AdminMarkAttendingSessionResponse
     */
    data?: AdminMarkAttendingSessionResponseData;
}
/**
 * 
 * @export
 * @interface AdminMarkAttendingSessionResponseData
 */
export interface AdminMarkAttendingSessionResponseData {
    /**
     * 
     * @type {number}
     * @memberof AdminMarkAttendingSessionResponseData
     */
    numOfStudentUpdated?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminMarkAttendingSessionResponseData
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface AdminMergeParentsAndStudentsRequest
 */
export interface AdminMergeParentsAndStudentsRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminMergeParentsAndStudentsRequestParents}
     * @memberof AdminMergeParentsAndStudentsRequest
     */
    parents: AdminMergeParentsAndStudentsRequestParents;
    /**
     * 
     * @type {Array<AdminMergeParentsAndStudentsRequestStudents>}
     * @memberof AdminMergeParentsAndStudentsRequest
     */
    students: Array<AdminMergeParentsAndStudentsRequestStudents>;
}
/**
 * 
 * @export
 * @interface AdminMergeParentsAndStudentsRequestParents
 */
export interface AdminMergeParentsAndStudentsRequestParents {
    /**
     * 
     * @type {string}
     * @memberof AdminMergeParentsAndStudentsRequestParents
     */
    toId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMergeParentsAndStudentsRequestParents
     */
    fromId: string;
}
/**
 * 
 * @export
 * @interface AdminMergeParentsAndStudentsRequestStudents
 */
export interface AdminMergeParentsAndStudentsRequestStudents {
    /**
     * 
     * @type {boolean}
     * @memberof AdminMergeParentsAndStudentsRequestStudents
     */
    isCreateNew: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminMergeParentsAndStudentsRequestStudents
     */
    toId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMergeParentsAndStudentsRequestStudents
     */
    fromId: string;
}
/**
 * 
 * @export
 * @interface AdminMigrateExistingStudentCareStudentsRequest
 */
export interface AdminMigrateExistingStudentCareStudentsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentCareStudentsRequest
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentCareStudentsRequest
     */
    studentName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentCareStudentsRequest
     */
    studentCareStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentCareStudentsRequest
     */
    subscriptionStartDate: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentCareStudentsRequest
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminMigrateExistingStudentCareStudentsRequest
     */
    transportPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMigrateExistingStudentCareStudentsRequest
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentCareStudentsRequest
     */
    recurringBillingId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentCareStudentsRequest
     */
    parentEmail: string;
}
/**
 * 
 * @export
 * @interface AdminMigrateExistingStudentRequest
 */
export interface AdminMigrateExistingStudentRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentRequest
     */
    sessionPackId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentRequest
     */
    courseSeriesId?: string;
    /**
     * 
     * @type {AdminMigrateExistingStudentRequestEmergency}
     * @memberof AdminMigrateExistingStudentRequest
     */
    emergency?: AdminMigrateExistingStudentRequestEmergency;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentRequest
     */
    transportProductPrefix?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentRequest
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentRequest
     */
    status?: AdminMigrateExistingStudentRequestStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AdminMigrateExistingStudentRequest
     */
    transportPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMigrateExistingStudentRequest
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentRequest
     */
    parentEmail: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentRequest
     */
    recurringBillingId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentRequest
     */
    courseId: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminMigrateExistingStudentRequestStatusEnum {
    Draft = 'draft',
    Sent = 'sent',
    Active = 'active',
    Failed = 'failed',
    Canceled = 'canceled'
}

/**
 * 
 * @export
 * @interface AdminMigrateExistingStudentRequestEmergency
 */
export interface AdminMigrateExistingStudentRequestEmergency {
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentRequestEmergency
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMigrateExistingStudentRequestEmergency
     */
    email: string;
}
/**
 * 
 * @export
 * @interface AdminPostCourseInstanceReservation
 */
export interface AdminPostCourseInstanceReservation {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseInstanceReservation
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseInstanceReservation
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseInstanceReservation
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseInstanceReservation
     */
    courseInstanceReferenceId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseInstanceReservation
     */
    expiredTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseInstanceReservation
     */
    expiredDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseInstanceReservation
     */
    status?: AdminPostCourseInstanceReservationStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AdminPostCourseInstanceReservation
     */
    price?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminPostCourseInstanceReservation
     */
    exclusiveSessionIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminPostCourseInstanceReservation
     */
    courseSessionIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseInstanceReservation
     */
    courseSessionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseInstanceReservation
     */
    confirmEnrollment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseInstanceReservation
     */
    remark?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseInstanceReservation
     */
    isNotSendReceipt?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseInstanceReservation
     */
    isSendConfirmationEmail?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminPostCourseInstanceReservationStatusEnum {
    Open = 'open',
    Paid = 'paid',
    Canceled = 'canceled'
}

/**
 * 
 * @export
 * @interface AdminPostCourseRequest
 */
export interface AdminPostCourseRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminPostCourseRequest
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminPostCourseRequest
     */
    maxAge?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminPostCourseRequest
     */
    customMinAge?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminPostCourseRequest
     */
    customMaxAge?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminPostCourseRequest
     */
    replacementExchangeAllowed?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminPostCourseRequest
     */
    replacementNoticePeriod?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    replacementAllowOverriding?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    replacementPenalty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    isUnlimitedReplacement?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    status?: AdminPostCourseRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    gradeLevelId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    image?: string;
    /**
     * 
     * @type {Array<string> | string}
     * @memberof AdminPostCourseRequest
     */
    subjectType?: Array<string> | string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminPostCourseRequest
     */
    subjectTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    approverId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    subjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    epCourseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    coursePeriodId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    allowSignUpStartMidWay?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    allowConcurrentSessions?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminPostCourseRequest
     */
    areaIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    allowTrialSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    applyTrialSession?: AdminPostCourseRequestApplyTrialSessionEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    courseAreaId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    trialSessionPrice?: AdminPostCourseRequestTrialSessionPriceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    isAutoReEnrollStudent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseRequest
     */
    businessUnitId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    isSuppressCourseFeedback?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    sendCourseReminder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    hideTimingOptions: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    requireCheckoutPicture: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    enableGuardianCheckoutOption: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminPostCourseRequest
     */
    otherCheckoutOptions?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    requireCheckinPicture: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    requireCheckinTemperature: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseRequest
     */
    enableGuardianCheckinOption: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminPostCourseRequest
     */
    otherCheckinOptions?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminPostCourseRequestStatusEnum {
    Draft = 'draft',
    PendingApproval = 'pending_approval',
    Approved = 'approved',
    Inactive = 'inactive'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminPostCourseRequestApplyTrialSessionEnum {
    FirstSession = 'first_session',
    AllSession = 'all_session'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminPostCourseRequestTrialSessionPriceEnum {
    Exclusive = 'exclusive',
    Inclusive = 'inclusive'
}

/**
 * 
 * @export
 * @interface AdminPostCourseResponse
 */
export interface AdminPostCourseResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseDto}
     * @memberof AdminPostCourseResponse
     */
    data: CourseDto;
}
/**
 * 
 * @export
 * @interface AdminPostCourseSeriesRequest
 */
export interface AdminPostCourseSeriesRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequest
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequest
     */
    frequency?: AdminPostCourseSeriesRequestFrequencyEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseSeriesRequest
     */
    includePublicHolidays?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseSeriesRequest
     */
    includeWeekends?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequest
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequest
     */
    endTime?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminPostCourseSeriesRequest
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminPostCourseSeriesRequest
     */
    takeRate?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequest
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequest
     */
    validFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequest
     */
    validTo?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequest
     */
    recurs?: AdminPostCourseSeriesRequestRecursEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminPostCourseSeriesRequest
     */
    days?: Array<AdminPostCourseSeriesRequestDaysEnum>;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequest
     */
    roomId?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminPostCourseSeriesRequest
     */
    numOfInstancesAhead?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseSeriesRequest
     */
    hasPriceModelSessionPacks?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequest
     */
    teacherId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseSeriesRequest
     */
    allowTrialSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequest
     */
    applyTrialSession?: AdminPostCourseSeriesRequestApplyTrialSessionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseSeriesRequest
     */
    autoCheckin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseSeriesRequest
     */
    autoEnrolNextInstanceWithoutPayment?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminPostCourseSeriesRequest
     */
    additionalTeacherIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPostCourseSeriesRequest
     */
    hasPriceModelRevShare?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequest
     */
    updateFromInstanceId?: string;
    /**
     * 
     * @type {CourseSeriesUpdateType}
     * @memberof AdminPostCourseSeriesRequest
     */
    updateType?: CourseSeriesUpdateType;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminPostCourseSeriesRequestFrequencyEnum {
    Monthly = 'monthly'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminPostCourseSeriesRequestRecursEnum {
    Monthly = 'monthly'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminPostCourseSeriesRequestDaysEnum {
    Mon = 'Mon',
    Tue = 'Tue',
    Wed = 'Wed',
    Thu = 'Thu',
    Fri = 'Fri',
    Sat = 'Sat',
    Sun = 'Sun'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminPostCourseSeriesRequestApplyTrialSessionEnum {
    AllSession = 'all_session',
    FirstSession = 'first_session'
}

/**
 * 
 * @export
 * @interface AdminPostCourseSeriesRequestAllOf
 */
export interface AdminPostCourseSeriesRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof AdminPostCourseSeriesRequestAllOf
     */
    updateFromInstanceId?: string;
    /**
     * 
     * @type {CourseSeriesUpdateType}
     * @memberof AdminPostCourseSeriesRequestAllOf
     */
    updateType?: CourseSeriesUpdateType;
}
/**
 * 
 * @export
 * @interface AdminPostCourseSeriesResponse
 */
export interface AdminPostCourseSeriesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseSeriesDto}
     * @memberof AdminPostCourseSeriesResponse
     */
    data: CourseSeriesDto;
}
/**
 * 
 * @export
 * @interface AdminPostUserRequest
 */
export interface AdminPostUserRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminPostUserRequest
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostUserRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPostUserRequest
     */
    role: AdminPostUserRequestRoleEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminPostUserRequest
     */
    venueIds?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminPostUserRequestRoleEnum {
    Admin = 'admin',
    Manager = 'manager',
    Finance = 'finance',
    StandardUser = 'standard_user'
}

/**
 * 
 * @export
 * @interface AdminPostUserResponse
 */
export interface AdminPostUserResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {UserDto}
     * @memberof AdminPostUserResponse
     */
    data: UserDto;
}
/**
 * 
 * @export
 * @interface AdminPriceDto
 */
export interface AdminPriceDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminPriceDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPriceDto
     */
    productId?: string;
    /**
     * 
     * @type {AdminVenuePriceDto}
     * @memberof AdminPriceDto
     */
    venue?: AdminVenuePriceDto;
    /**
     * 
     * @type {AdminCountryPriceDto}
     * @memberof AdminPriceDto
     */
    country?: AdminCountryPriceDto;
    /**
     * 
     * @type {number}
     * @memberof AdminPriceDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminPriceDto
     */
    validFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPriceDto
     */
    validTo?: string;
    /**
     * 
     * @type {AdminGstRatePriceDto}
     * @memberof AdminPriceDto
     */
    gst?: AdminGstRatePriceDto;
    /**
     * 
     * @type {string}
     * @memberof AdminPriceDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPriceDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPriceDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPriceDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPriceDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminPriceDto
     */
    currencyUnit?: string;
}
/**
 * 
 * @export
 * @interface AdminProductDto
 */
export interface AdminProductDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    sku?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    businessUnitName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    categoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    businessUnitId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    courseId?: string;
    /**
     * 
     * @type {ProductStatus}
     * @memberof AdminProductDto
     */
    status?: ProductStatus;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    updatedByFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    createdByFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {Array<AdminPriceDto>}
     * @memberof AdminProductDto
     */
    priceModels?: Array<AdminPriceDto>;
}
/**
 * 
 * @export
 * @interface AdminProductRequest
 */
export interface AdminProductRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminProductRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductRequest
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductRequest
     */
    categoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductRequest
     */
    businessUnitId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductRequest
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductRequest
     */
    status?: AdminProductRequestStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductRequest
     */
    isGenerateSKU?: boolean;
    /**
     * 
     * @type {Array<PriceModelRequest>}
     * @memberof AdminProductRequest
     */
    priceModels?: Array<PriceModelRequest>;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminProductRequestStatusEnum {
    Active = 'active',
    Archived = 'archived'
}

/**
 * 
 * @export
 * @interface AdminQuickSightEmbedUrlResponse
 */
export interface AdminQuickSightEmbedUrlResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminQuickSightEmbedUrlResponse
     */
    url: string;
}
/**
 * 
 * @export
 * @interface AdminRefundCourseInstanceRequest
 */
export interface AdminRefundCourseInstanceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    parentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    parentName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    parentEmail: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    paymentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    paymentMethod: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    financialTransactionId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    transactionId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    invoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    enrollmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminRefundCourseInstanceRequest
     */
    refundAmount: number;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    refundMethod: AdminRefundCourseInstanceRequestRefundMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    refundStatus: AdminRefundCourseInstanceRequestRefundStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    refundDate: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    refundRemarks: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    refundHistoryId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    approvalId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    approvalDate?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminRefundCourseInstanceRequest
     */
    courseSessionIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminRefundCourseInstanceRequest
     */
    startTimeFirstSessionsSelected?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminRefundCourseInstanceRequest
     */
    isRefundAllSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminRefundCourseInstanceRequest
     */
    isMaximumSessionPack?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminRefundCourseInstanceRequest
     */
    isEpSharing?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminRefundCourseInstanceRequest
     */
    coursePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminRefundCourseInstanceRequest
     */
    numberOfSessions?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    refundType?: AdminRefundCourseInstanceRequestRefundTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminRefundCourseInstanceRequest
     */
    sessionPackAdditionId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminRefundCourseInstanceRequestRefundMethodEnum {
    Hitpay = 'hitpay',
    Manually = 'manually',
    Credit = 'credit'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminRefundCourseInstanceRequestRefundStatusEnum {
    PendingApproval = 'pending_approval',
    ApprovedRefundProcessing = 'approved_refund_processing',
    ApprovedRefunded = 'approved_refunded',
    Rejected = 'rejected'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminRefundCourseInstanceRequestRefundTypeEnum {
    Course = 'course',
    SessionPack = 'session_pack'
}

/**
 * 
 * @export
 * @interface AdminRemoveCourseMediaRequest
 */
export interface AdminRemoveCourseMediaRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminRemoveCourseMediaRequest
     */
    mediaId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRemoveCourseMediaRequest
     */
    courseMediaId: string;
}
/**
 * 
 * @export
 * @interface AdminRequestCheckinOut
 */
export interface AdminRequestCheckinOut {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminRequestCheckinOut
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRequestCheckinOut
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRequestCheckinOut
     */
    checkinTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRequestCheckinOut
     */
    checkoutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRequestCheckinOut
     */
    checkinLogId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRequestCheckinOut
     */
    remark?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRequestCheckinOut
     */
    studentCourseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRequestCheckinOut
     */
    courseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRequestCheckinOut
     */
    roomCheckInId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminRequestCheckinOut
     */
    isUseSessionPack?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminRequestCheckinOut
     */
    sharedSessionPackStudentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRequestCheckinOut
     */
    parentId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminRequestCheckinOut
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminRequestCheckinOut
     */
    kisiUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminRequestCheckinOut
     */
    isAllowStudentLeave?: boolean;
}
/**
 * 
 * @export
 * @interface AdminSaveParentNotesRequest
 */
export interface AdminSaveParentNotesRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminSaveParentNotesRequest
     */
    parentReferenceId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSaveParentNotesRequest
     */
    notes: string;
}
/**
 * 
 * @export
 * @interface AdminSendInvoiceResponse
 */
export interface AdminSendInvoiceResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {InvoiceDto}
     * @memberof AdminSendInvoiceResponse
     */
    data: InvoiceDto;
}
/**
 * 
 * @export
 * @interface AdminSendMailEPFinanceRequest
 */
export interface AdminSendMailEPFinanceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminSendMailEPFinanceRequest
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSendMailEPFinanceRequest
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSendMailEPFinanceRequest
     */
    epAdminName: string;
    /**
     * 
     * @type {Array<AttachmentsAdminSendMailEPFinanceRequest>}
     * @memberof AdminSendMailEPFinanceRequest
     */
    attachments: Array<AttachmentsAdminSendMailEPFinanceRequest>;
}
/**
 * 
 * @export
 * @interface AdminSendMailRequest
 */
export interface AdminSendMailRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminSendMailRequest
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSendMailRequest
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSendMailRequest
     */
    htmlBody: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSendMailRequest
     */
    textBody: string;
    /**
     * 
     * @type {AdminSendReservationLinksRequestOriginalEmail}
     * @memberof AdminSendMailRequest
     */
    originalEmail?: AdminSendReservationLinksRequestOriginalEmail;
}
/**
 * 
 * @export
 * @interface AdminSendOrResendLoginInviteRequest
 */
export interface AdminSendOrResendLoginInviteRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminSendOrResendLoginInviteRequest
     */
    venueEmailSender: string;
    /**
     * 
     * @type {UserDto}
     * @memberof AdminSendOrResendLoginInviteRequest
     */
    parentInfo: UserDto;
    /**
     * 
     * @type {IModifiedEmailTemplate}
     * @memberof AdminSendOrResendLoginInviteRequest
     */
    emailTemplate?: IModifiedEmailTemplate;
}
/**
 * 
 * @export
 * @interface AdminSendReservationLinksRequest
 */
export interface AdminSendReservationLinksRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminSendReservationLinksRequest
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSendReservationLinksRequest
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSendReservationLinksRequest
     */
    htmlBody: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSendReservationLinksRequest
     */
    textBody: string;
    /**
     * 
     * @type {AdminSendReservationLinksRequestOriginalEmail}
     * @memberof AdminSendReservationLinksRequest
     */
    originalEmail?: AdminSendReservationLinksRequestOriginalEmail;
    /**
     * 
     * @type {string}
     * @memberof AdminSendReservationLinksRequest
     */
    reserveId: string;
}
/**
 * 
 * @export
 * @interface AdminSendReservationLinksRequestOriginalEmail
 */
export interface AdminSendReservationLinksRequestOriginalEmail {
    /**
     * 
     * @type {string}
     * @memberof AdminSendReservationLinksRequestOriginalEmail
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSendReservationLinksRequestOriginalEmail
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSendReservationLinksRequestOriginalEmail
     */
    templateAlias: string;
}
/**
 * 
 * @export
 * @interface AdminSendRoomBookingInvoiceMailRequest
 */
export interface AdminSendRoomBookingInvoiceMailRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminSendRoomBookingInvoiceMailRequest
     */
    invoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSendRoomBookingInvoiceMailRequest
     */
    emailTemplateId?: string;
    /**
     * 
     * @type {Array<AdminSendMailRequest>}
     * @memberof AdminSendRoomBookingInvoiceMailRequest
     */
    mailList: Array<AdminSendMailRequest>;
}
/**
 * 
 * @export
 * @interface AdminSendSubscriptionMailRequest
 */
export interface AdminSendSubscriptionMailRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminSendSubscriptionMailRequest
     */
    subscriptionId: string;
    /**
     * 
     * @type {Array<AdminSendMailRequest>}
     * @memberof AdminSendSubscriptionMailRequest
     */
    mailList: Array<AdminSendMailRequest>;
}
/**
 * 
 * @export
 * @interface AdminSessionPackAdditionResponse
 */
export interface AdminSessionPackAdditionResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SessionPackAdditionDto>}
     * @memberof AdminSessionPackAdditionResponse
     */
    data: Array<SessionPackAdditionDto>;
}
/**
 * 
 * @export
 * @interface AdminSessionPackUsageResponse
 */
export interface AdminSessionPackUsageResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SessionPackUsageDto>}
     * @memberof AdminSessionPackUsageResponse
     */
    data: Array<SessionPackUsageDto>;
}
/**
 * 
 * @export
 * @interface AdminTagDto
 */
export interface AdminTagDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminTagDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTagDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTagDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTagDto
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface AdminTeacherDto
 */
export interface AdminTeacherDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    userType: AdminTeacherDtoUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    teacherName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    teacherPreferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    teacherPhoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    teacherEmail: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    teacherRegistrationDate: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    teacherAvatar: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    educationProviderId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    moeRegistrationStatus?: AdminTeacherDtoMoeRegistrationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    moeExpiredDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminTeacherDto
     */
    enableEpPortalAccess?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    status?: AdminTeacherDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    referenceId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminTeacherDto
     */
    userTagIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminTeacherDto
     */
    userTagNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    createdByFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherDto
     */
    updatedByFullName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminTeacherDto
     */
    isTeacherHasInstance?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminTeacherDtoUserTypeEnum {
    Teacher = 'teacher',
    EpAdminTeacher = 'ep_admin_teacher',
    EpAdmin = 'ep_admin'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminTeacherDtoMoeRegistrationStatusEnum {
    Registered = 'registered',
    Unregistered = 'unregistered'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminTeacherDtoStatusEnum {
    Active = 'active',
    Inactive = 'inactive'
}

/**
 * 
 * @export
 * @interface AdminTeacherGetVenueRoomDetailResponse
 */
export interface AdminTeacherGetVenueRoomDetailResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {RoomDto}
     * @memberof AdminTeacherGetVenueRoomDetailResponse
     */
    data: RoomDto;
}
/**
 * 
 * @export
 * @interface AdminTeacherMOEDto
 */
export interface AdminTeacherMOEDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminTeacherMOEDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherMOEDto
     */
    type: AdminTeacherMOEDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherMOEDto
     */
    status: AdminTeacherMOEDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherMOEDto
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherMOEDto
     */
    expirationDate: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminTeacherMOEDto
     */
    areaIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminTeacherMOEDto
     */
    areaNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherMOEDto
     */
    documentLink?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherMOEDto
     */
    documentName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherMOEDto
     */
    documentType?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherMOEDto
     */
    teacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherMOEDto
     */
    teacherNameMOE?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTeacherMOEDto
     */
    teacherIdMOE?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminTeacherMOEDtoTypeEnum {
    Qualification = 'qualification',
    Registration = 'registration',
    CodeOfConduct = 'code_of_conduct',
    NrocSeniorCoach = 'nroc_senior_coach',
    NrocCoach = 'nroc_coach',
    NrocProvisionalCoach = 'nroc_provisional_coach'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminTeacherMOEDtoStatusEnum {
    Active = 'active',
    Expired = 'expired'
}

/**
 * 
 * @export
 * @interface AdminUpdateCourseMetaRequest
 */
export interface AdminUpdateCourseMetaRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCourseMetaRequest
     */
    courseId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCourseMetaRequest
     */
    metaDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCourseMetaRequest
     */
    metaKeywords?: string;
}
/**
 * 
 * @export
 * @interface AdminUpdateReservationRequest
 */
export interface AdminUpdateReservationRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpdateReservationRequest
     */
    reservedId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateReservationRequest
     */
    status: AdminUpdateReservationRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateReservationRequest
     */
    expireDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateReservationRequest
     */
    expireTime?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminUpdateReservationRequestStatusEnum {
    Canceled = 'canceled',
    Paid = 'paid',
    Expired = 'expired',
    Open = 'open'
}

/**
 * 
 * @export
 * @interface AdminUpsertCourseInstanceDto
 */
export interface AdminUpsertCourseInstanceDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceDto
     */
    courseSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceDto
     */
    courseReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceDto
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceDto
     */
    teacherId?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertCourseInstanceDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceDto
     */
    status?: AdminUpsertCourseInstanceDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertCourseInstanceDto
     */
    numOfSeat?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminUpsertCourseInstanceDtoStatusEnum {
    Draft = 'draft',
    PendingApproval = 'pending_approval',
    Approved = 'approved',
    FullyBooked = 'fully_booked',
    CurrentlyUnderway = 'currently_underway',
    Completed = 'completed',
    Cancelled = 'cancelled'
}

/**
 * 
 * @export
 * @interface AdminUpsertCourseInstanceRequest
 */
export interface AdminUpsertCourseInstanceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    courseId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    teacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    approverId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    courseFormatId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    courseTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    coursePeriodId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    referenceId?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    numOfSeat?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    status?: AdminUpsertCourseInstanceRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    rateType?: AdminUpsertCourseInstanceRequestRateTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    flatFeeAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    takeRateAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    studentBookingFeeAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    epCourseInstanceCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    isTeacherDisplayed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    allowTrialSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    allowMakeupSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    applyMakeupSession?: AdminUpsertCourseInstanceRequestApplyMakeupSessionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    allowBookingCourseStarted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    promotionName?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    promotionPublishPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    coursePeriodStart?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    coursePeriodEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    allowSignUpStartMidWay?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    trialSingleSessionPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    applyTrialSession?: AdminUpsertCourseInstanceRequestApplyTrialSessionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    numOfSessions?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    venueId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    hasPriceModelSessionPacks?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    hasPriceModelRevShare?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    hasPriceModelRoomBooking?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    isCalcFeeFromSessions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    isGenerateInvoice?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    roomBookingPrice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    additionalInfo?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    additionalTeacherIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertCourseInstanceRequest
     */
    autoCheckin?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminUpsertCourseInstanceRequestStatusEnum {
    Draft = 'draft',
    PendingApproval = 'pending_approval',
    Approved = 'approved',
    FullyBooked = 'fully_booked',
    CurrentlyUnderway = 'currently_underway',
    Completed = 'completed',
    Cancelled = 'cancelled'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminUpsertCourseInstanceRequestRateTypeEnum {
    TakeRate = 'take_rate',
    FlatFee = 'flat_fee'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminUpsertCourseInstanceRequestApplyMakeupSessionEnum {
    FirstSession = 'first_session',
    AllSession = 'all_session'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminUpsertCourseInstanceRequestApplyTrialSessionEnum {
    FirstSession = 'first_session',
    AllSession = 'all_session'
}

/**
 * 
 * @export
 * @interface AdminUpsertCourseInstanceResponse
 */
export interface AdminUpsertCourseInstanceResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminUpsertCourseInstanceDto}
     * @memberof AdminUpsertCourseInstanceResponse
     */
    data: AdminUpsertCourseInstanceDto;
}
/**
 * 
 * @export
 * @interface AdminUpsertCreditReasonRequest
 */
export interface AdminUpsertCreditReasonRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCreditReasonRequest
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertCreditReasonRequest
     */
    reason?: string;
}
/**
 * 
 * @export
 * @interface AdminUpsertCreditReasonResponse
 */
export interface AdminUpsertCreditReasonResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminGetCreditReasonDto}
     * @memberof AdminUpsertCreditReasonResponse
     */
    data: AdminGetCreditReasonDto;
}
/**
 * 
 * @export
 * @interface AdminUpsertEducationProviderRequest
 */
export interface AdminUpsertEducationProviderRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertEducationProviderRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertEducationProviderRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertEducationProviderRequest
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertEducationProviderRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertEducationProviderRequest
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertEducationProviderRequest
     */
    availableHome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertEducationProviderRequest
     */
    isGST?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertEducationProviderRequest
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertEducationProviderRequest
     */
    uenNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertEducationProviderRequest
     */
    gstNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertEducationProviderRequest
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertEducationProviderRequest
     */
    bankSwiftBic?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertEducationProviderRequest
     */
    mailingAddress?: string;
    /**
     * 
     * @type {EducationProviderStatus}
     * @memberof AdminUpsertEducationProviderRequest
     */
    status?: EducationProviderStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpsertEducationProviderRequest
     */
    bookingConfirmationIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertEducationProviderRequest
     */
    isDisplayWebsite?: boolean;
}
/**
 * 
 * @export
 * @interface AdminUpsertEducationProviderResponse
 */
export interface AdminUpsertEducationProviderResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertEducationProviderResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertEducationProviderResponse
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertEducationProviderResponse
     */
    referenceId?: string;
}
/**
 * 
 * @export
 * @interface AdminUpsertMemberRequest
 */
export interface AdminUpsertMemberRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberRequest
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberRequest
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberRequest
     */
    userType: AdminUpsertMemberRequestUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberRequest
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberRequest
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberRequest
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberRequest
     */
    status?: AdminUpsertMemberRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberRequest
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberRequest
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberRequest
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberRequest
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertMemberRequest
     */
    enableEpPortalAccess?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpsertMemberRequest
     */
    tagIds?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminUpsertMemberRequestUserTypeEnum {
    EpAdmin = 'ep_admin',
    EpAdminTeacher = 'ep_admin_teacher',
    Teacher = 'teacher'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminUpsertMemberRequestStatusEnum {
    Active = 'active',
    Inactive = 'inactive'
}

/**
 * 
 * @export
 * @interface AdminUpsertMemberResponse
 */
export interface AdminUpsertMemberResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberResponse
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertMemberResponse
     */
    referenceId?: string;
}
/**
 * 
 * @export
 * @interface AdminUpsertParentRequest
 */
export interface AdminUpsertParentRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentRequest
     */
    currentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentRequest
     */
    invoiceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentRequest
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentRequest
     */
    invoiceEmailAddress?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpsertParentRequest
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertParentRequest
     */
    isSendLoginInvite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentRequest
     */
    brandId?: string;
    /**
     * 
     * @type {IModifiedEmailTemplate}
     * @memberof AdminUpsertParentRequest
     */
    emailTemplate?: IModifiedEmailTemplate;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentRequest
     */
    venueEmailSender?: string;
}
/**
 * 
 * @export
 * @interface AdminUpsertParentResponse
 */
export interface AdminUpsertParentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentResponse
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertParentResponse
     */
    referenceId?: string;
}
/**
 * 
 * @export
 * @interface AdminUpsertRolePermissionRequest
 */
export interface AdminUpsertRolePermissionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertRolePermissionRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertRolePermissionRequest
     */
    roleId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertRolePermissionRequest
     */
    permissionId: string;
    /**
     * 
     * @type {AccessScopeEnum | string}
     * @memberof AdminUpsertRolePermissionRequest
     */
    accessScope: AccessScopeEnum | string;
}
/**
 * 
 * @export
 * @interface AdminUpsertSessionPackRequest
 */
export interface AdminUpsertSessionPackRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertSessionPackRequest
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertSessionPackRequest
     */
    numberOfSessions?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertSessionPackRequest
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertSessionPackRequest
     */
    validFor?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertSessionPackRequest
     */
    revenueShare?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertSessionPackRequest
     */
    status?: AdminUpsertSessionPackRequestStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertSessionPackRequest
     */
    showOnWebsite?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertSessionPackRequest
     */
    isSuppressExpiryRunningLowAlerts?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertSessionPackRequest
     */
    name?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminUpsertSessionPackRequestStatusEnum {
    Active = 'active',
    Inactive = 'inactive'
}

/**
 * 
 * @export
 * @interface AdminUpsertSessionPackResponse
 */
export interface AdminUpsertSessionPackResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {SessionPackDto}
     * @memberof AdminUpsertSessionPackResponse
     */
    data: SessionPackDto;
}
/**
 * 
 * @export
 * @interface AdminUpsertSpaceRoomRequest
 */
export interface AdminUpsertSpaceRoomRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertSpaceRoomRequest
     */
    roomId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertSpaceRoomRequest
     */
    roomTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertSpaceRoomRequest
     */
    roomName: string;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertSpaceRoomRequest
     */
    roomSize: number;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertSpaceRoomRequest
     */
    roomCapacity: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertSpaceRoomRequest
     */
    location?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertSpaceRoomRequest
     */
    roomBufferNumber: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertSpaceRoomRequest
     */
    roomImage?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertSpaceRoomRequest
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertSpaceRoomRequest
     */
    allowOverbooking: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertSpaceRoomRequest
     */
    venueId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertSpaceRoomRequest
     */
    createdBy?: string;
    /**
     * 
     * @type {Array<RoomEquipmentDto>}
     * @memberof AdminUpsertSpaceRoomRequest
     */
    equipments?: Array<RoomEquipmentDto>;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertSpaceRoomRequest
     */
    price?: number;
}
/**
 * 
 * @export
 * @interface AdminUpsertStudentNoteRequest
 */
export interface AdminUpsertStudentNoteRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertStudentNoteRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertStudentNoteRequest
     */
    typeId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertStudentNoteRequest
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertStudentNoteRequest
     */
    note: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertStudentNoteRequest
     */
    pin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertStudentNoteRequest
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertStudentNoteRequest
     */
    parentId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpsertStudentNoteRequest
     */
    courseInstanceIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpsertStudentNoteRequest
     */
    courseSessionIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertStudentNoteRequest
     */
    showOnCheckin?: boolean;
}
/**
 * 
 * @export
 * @interface AdminUpsertStudentNoteResponse
 */
export interface AdminUpsertStudentNoteResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {StudentNotesDto}
     * @memberof AdminUpsertStudentNoteResponse
     */
    data: StudentNotesDto;
}
/**
 * 
 * @export
 * @interface AdminUpsertStudentNoteTypeRequest
 */
export interface AdminUpsertStudentNoteTypeRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertStudentNoteTypeRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertStudentNoteTypeRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertStudentNoteTypeRequest
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertStudentNoteTypeRequest
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface AdminUpsertVenueRequest
 */
export interface AdminUpsertVenueRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    status?: AdminUpsertVenueRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    venueEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    contactNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    uenNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpsertVenueRequest
     */
    isGst?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    gstNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpsertVenueRequest
     */
    classIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    schoolId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    billingOrganisationId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    brandId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpsertVenueRequest
     */
    senderEmail?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminUpsertVenueRequest
     */
    messageDaysAfterCourseFinished?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminUpsertVenueRequestStatusEnum {
    Registered = 'registered',
    Unregistered = 'unregistered'
}

/**
 * 
 * @export
 * @interface AdminValidateChangeTeacherRequest
 */
export interface AdminValidateChangeTeacherRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminValidateChangeTeacherRequest
     */
    teacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminValidateChangeTeacherRequest
     */
    courseInstanceId?: string;
}
/**
 * 
 * @export
 * @interface AdminValidateClassUsedRequest
 */
export interface AdminValidateClassUsedRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminValidateClassUsedRequest
     */
    venueId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminValidateClassUsedRequest
     */
    classIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface AdminValidateTrialSessionRequestRequest
 */
export interface AdminValidateTrialSessionRequestRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminValidateTrialSessionRequestRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminValidateTrialSessionRequestRequest
     */
    courseSessionStartTime: string;
    /**
     * 
     * @type {string}
     * @memberof AdminValidateTrialSessionRequestRequest
     */
    courseSessionEndTime: string;
    /**
     * 
     * @type {string}
     * @memberof AdminValidateTrialSessionRequestRequest
     */
    courseSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminValidateTrialSessionRequestRequest
     */
    courseInstanceId: string;
}
/**
 * 
 * @export
 * @interface AdminValidateTrialSessionRequestResponse
 */
export interface AdminValidateTrialSessionRequestResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AdminValidateTrialSessionRequestResponseData}
     * @memberof AdminValidateTrialSessionRequestResponse
     */
    data: AdminValidateTrialSessionRequestResponseData;
}
/**
 * 
 * @export
 * @interface AdminValidateTrialSessionRequestResponseData
 */
export interface AdminValidateTrialSessionRequestResponseData {
    /**
     * 
     * @type {boolean}
     * @memberof AdminValidateTrialSessionRequestResponseData
     */
    isOverlap: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminValidateTrialSessionRequestResponseData
     */
    isTrialedThisSession: boolean;
    /**
     * 
     * @type {Array<CourseSessionDto>}
     * @memberof AdminValidateTrialSessionRequestResponseData
     */
    courseSessionsByStudent: Array<CourseSessionDto>;
}
/**
 * 
 * @export
 * @interface AdminVenuePriceDto
 */
export interface AdminVenuePriceDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminVenuePriceDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVenuePriceDto
     */
    shortName?: string;
}
/**
 * 
 * @export
 * @interface AdminWithdrawTrialStudentRequest
 */
export interface AdminWithdrawTrialStudentRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AdminWithdrawTrialStudentRequest
     */
    studentCourseSessionId: string;
}
/**
 * 
 * @export
 * @interface AgeFilterDto
 */
export interface AgeFilterDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AgeFilterDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AgeFilterDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof AgeFilterDto
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof AgeFilterDto
     */
    maxAge?: number;
    /**
     * 
     * @type {string}
     * @memberof AgeFilterDto
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface AgeGroupDto
 */
export interface AgeGroupDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AgeGroupDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AgeGroupDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof AgeGroupDto
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof AgeGroupDto
     */
    maxAge?: number;
    /**
     * 
     * @type {number}
     * @memberof AgeGroupDto
     */
    dateOfBirth?: number;
}
/**
 * 
 * @export
 * @interface AgoraAccessHeaderDto
 */
export interface AgoraAccessHeaderDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AgoraAccessHeaderDto
     */
    expirationDate?: string;
    /**
     * 
     * @type {number | string}
     * @memberof AgoraAccessHeaderDto
     */
    includeFreeHours?: number | string;
    /**
     * 
     * @type {number}
     * @memberof AgoraAccessHeaderDto
     */
    currentBalance: number;
}
/**
 * 
 * @export
 * @interface AgoraCareDto
 */
export interface AgoraCareDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AgoraCareDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof AgoraCareDto
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof AgoraCareDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof AgoraCareDto
     */
    originalPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgoraCareDto
     */
    allowMulti?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareDto
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareDto
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface AgoraCareHoursDto
 */
export interface AgoraCareHoursDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AgoraCareHoursDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof AgoraCareHoursDto
     */
    remainingHours?: number;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareHoursDto
     */
    userId?: string;
    /**
     * 
     * @type {number}
     * @memberof AgoraCareHoursDto
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof AgoraCareHoursDto
     */
    balanceHours?: number;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareHoursDto
     */
    dateRedeemed?: string;
    /**
     * 
     * @type {number}
     * @memberof AgoraCareHoursDto
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareHoursDto
     */
    expireDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareHoursDto
     */
    campaignHistoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareHoursDto
     */
    subscriptionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareHoursDto
     */
    agoraAccessId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareHoursDto
     */
    createdAt?: string;
}
/**
 * 
 * @export
 * @interface AgoraCareUsageDto
 */
export interface AgoraCareUsageDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AgoraCareUsageDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareUsageDto
     */
    childId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareUsageDto
     */
    childName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareUsageDto
     */
    useType?: AgoraCareUsageDtoUseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AgoraCareUsageDto
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof AgoraCareUsageDto
     */
    balanceHours?: number;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareUsageDto
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareUsageDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareUsageDto
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareUsageDto
     */
    expireDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareUsageDto
     */
    agoraCareType?: AgoraCareUsageDtoAgoraCareTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AgoraCareUsageDto
     */
    agoraCareId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AgoraCareUsageDtoUseTypeEnum {
    Added = 'added',
    Used = 'used',
    Expired = 'expired'
}
/**
    * @export
    * @enum {string}
    */
export enum AgoraCareUsageDtoAgoraCareTypeEnum {
    Care = 'agora-care',
    Access = 'agora-access'
}

/**
 * 
 * @export
 * @interface AgoraPlanDto
 */
export interface AgoraPlanDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof AgoraPlanDto
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof AgoraPlanDto
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraPlanDto
     */
    value: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgoraPlanDto
     */
    isPopular: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgoraPlanDto
     */
    popularText?: string;
}
/**
 * 
 * @export
 * @interface AgoraPlanFeatureDto
 */
export interface AgoraPlanFeatureDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof AgoraPlanFeatureDto
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof AgoraPlanFeatureDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraPlanFeatureDto
     */
    display: AgoraPlanFeatureDtoDisplayEnum;
    /**
     * 
     * @type {any | Array<string>}
     * @memberof AgoraPlanFeatureDto
     */
    planValues: any | Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum AgoraPlanFeatureDtoDisplayEnum {
    Icon = 'icon',
    Text = 'text',
    Note = 'note'
}

/**
 * 
 * @export
 * @interface AgoraSubscriptionDto
 */
export interface AgoraSubscriptionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AgoraSubscriptionDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraSubscriptionDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof AgoraSubscriptionDto
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof AgoraSubscriptionDto
     */
    costPerHour?: number;
    /**
     * 
     * @type {number}
     * @memberof AgoraSubscriptionDto
     */
    pricePerMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof AgoraSubscriptionDto
     */
    discount?: number;
    /**
     * 
     * @type {string}
     * @memberof AgoraSubscriptionDto
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraSubscriptionDto
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface AgoraSubscriptionHeaderDto
 */
export interface AgoraSubscriptionHeaderDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AgoraSubscriptionHeaderDto
     */
    startDate: string;
    /**
     * 
     * @type {number}
     * @memberof AgoraSubscriptionHeaderDto
     */
    currentBalance?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgoraSubscriptionHeaderDto
     */
    isCancelled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgoraSubscriptionHeaderDto
     */
    expireDate?: string;
}
/**
 * 
 * @export
 * @interface AgoraTopContentDto
 */
export interface AgoraTopContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AgoraTopContentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraTopContentDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraTopContentDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraTopContentDto
     */
    moreInfo: string;
    /**
     * 
     * @type {string}
     * @memberof AgoraTopContentDto
     */
    image: string;
}
/**
 * 
 * @export
 * @interface AgoraUsagesResponse
 */
export interface AgoraUsagesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AgoraCareUsageDto>}
     * @memberof AgoraUsagesResponse
     */
    data?: Array<AgoraCareUsageDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ApplyTrialSession {
    FirstSession = 'first_session',
    AllSession = 'all_session'
}

/**
 * 
 * @export
 * @interface ApproveCourseInstanceRequest
 */
export interface ApproveCourseInstanceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ApproveCourseInstanceRequest
     */
    referenceId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApproveCourseInstanceRequest
     */
    skipCheckMinCapacity?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApproveCourseInstanceRequest
     */
    isGenerateInvoice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApproveCourseInstanceRequest
     */
    invoiceId?: string;
}
/**
 * 
 * @export
 * @interface ApproveCourseInstanceResponse
 */
export interface ApproveCourseInstanceResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {boolean}
     * @memberof ApproveCourseInstanceResponse
     */
    isValidMinCapacity: boolean;
}
/**
 * 
 * @export
 * @interface AreaDto
 */
export interface AreaDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AreaDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AreaDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface AssignStudentToCourseSeriesRequest
 */
export interface AssignStudentToCourseSeriesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignStudentToCourseSeriesRequest
     */
    courseInstanceIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignStudentToCourseSeriesRequest
     */
    removedEnrolledCourseSeriesIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignStudentToCourseSeriesRequest
     */
    courseSeriesIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssignStudentToCourseSeriesRequest
     */
    studentId: string;
}
/**
 * 
 * @export
 * @interface AtSchoolBrowseCoursesHeaderContentResponse
 */
export interface AtSchoolBrowseCoursesHeaderContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {MetaDto}
     * @memberof AtSchoolBrowseCoursesHeaderContentResponse
     */
    data: MetaDto;
}
/**
 * 
 * @export
 * @interface AtSchoolGetSchoolListResponse
 */
export interface AtSchoolGetSchoolListResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SchoolDto>}
     * @memberof AtSchoolGetSchoolListResponse
     */
    data: Array<SchoolDto>;
}
/**
 * 
 * @export
 * @interface AtSchoolHeaderDto
 */
export interface AtSchoolHeaderDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AtSchoolHeaderDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AtSchoolHeaderDto
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof AtSchoolHeaderDto
     */
    image?: string;
}
/**
 * 
 * @export
 * @interface AttachmentsAdminSendMailEPFinanceRequest
 */
export interface AttachmentsAdminSendMailEPFinanceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AttachmentsAdminSendMailEPFinanceRequest
     */
    ContentID: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentsAdminSendMailEPFinanceRequest
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentsAdminSendMailEPFinanceRequest
     */
    Content: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentsAdminSendMailEPFinanceRequest
     */
    ContentType: AttachmentsAdminSendMailEPFinanceRequestContentTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AttachmentsAdminSendMailEPFinanceRequestContentTypeEnum {
    ApplicationPdf = 'application/pdf'
}

/**
 * 
 * @export
 * @interface AttendanceBySessionDto
 */
export interface AttendanceBySessionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    studentChineseName?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    studentAvatar?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    attendanceCheckedTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    attendanceStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    status?: string;
    /**
     * 
     * @type {string | number}
     * @memberof AttendanceBySessionDto
     */
    replacement?: string | number;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    originalSessionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceBySessionDto
     */
    isTrialSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceBySessionDto
     */
    isReplaced?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    startTimeOriginal?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    endTimeOriginal?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    courseInstanceOriginalId?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    checkInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    checkOutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceBySessionDto
     */
    sessionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceBySessionDto
     */
    isOffPlatform?: boolean;
}
/**
 * 
 * @export
 * @interface AttendanceCourseSessionDto
 */
export interface AttendanceCourseSessionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AttendanceCourseSessionDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceCourseSessionDto
     */
    subjectType?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceCourseSessionDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceCourseSessionDto
     */
    endTime?: string;
}
/**
 * 
 * @export
 * @interface AttendanceDto
 */
export interface AttendanceDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AttendanceDto
     */
    sub: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceDto
     */
    childrenId?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceDto
     */
    courseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceDto
     */
    attendId?: string;
}
/**
 * 
 * @export
 * @interface AttendanceHistoryDto
 */
export interface AttendanceHistoryDto {
    [key: string]: object | any;

    /**
     * 
     * @type {PickCourseAttributesNameOrIdOrReferenceIdOrProviderIdOrBusinessUnitId}
     * @memberof AttendanceHistoryDto
     */
    course?: PickCourseAttributesNameOrIdOrReferenceIdOrProviderIdOrBusinessUnitId;
    /**
     * 
     * @type {PickCourseInstanceAttributesReferenceIdOrStartDateOrVenueId}
     * @memberof AttendanceHistoryDto
     */
    courseInstance?: PickCourseInstanceAttributesReferenceIdOrStartDateOrVenueId;
    /**
     * 
     * @type {PickCourseSessionAttributesStartTimeOrId}
     * @memberof AttendanceHistoryDto
     */
    courseSession?: PickCourseSessionAttributesStartTimeOrId;
    /**
     * 
     * @type {string}
     * @memberof AttendanceHistoryDto
     */
    checkinAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceHistoryDto
     */
    checkinVenueAt: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceHistoryDto
     */
    checkoutAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceHistoryDto
     */
    checkoutVenueAt?: string;
    /**
     * 
     * @type {PickUserDtoIdOrFullname}
     * @memberof AttendanceHistoryDto
     */
    checkinBy?: PickUserDtoIdOrFullname;
    /**
     * 
     * @type {CheckinOutSourceEnum}
     * @memberof AttendanceHistoryDto
     */
    checkinSource?: CheckinOutSourceEnum;
}
/**
 * 
 * @export
 * @interface AttendanceStatusDto
 */
export interface AttendanceStatusDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof AttendanceStatusDto
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof AttendanceStatusDto
     */
    attendanceId: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceStatusDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceStatusDto
     */
    avatarStudent?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceStatusDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceStatusDto
     */
    attendanceStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceStatusDto
     */
    attendanceCheckedTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceStatusDto
     */
    isReplaced?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceStatusDto
     */
    isTrial?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceStatusDto
     */
    isOffPlatform?: boolean;
}
/**
 * 
 * @export
 * @interface AttendanceTeacherDto
 */
export interface AttendanceTeacherDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    childrenName?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    childrenAvatar?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    childrenChineseName?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    courseSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    sessionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    checkinTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    checkoutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    sessionCheckinTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    sessionCheckoutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    checkinNote?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    orginalSessionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceTeacherDto
     */
    isTrialSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceTeacherDto
     */
    isMakeupSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    rescheduleInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    attendanceCheckedTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    venueId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceTeacherDto
     */
    isPrimaryTeacher?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceTeacherDto
     */
    isAllowDisplayParentInfo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    courseId?: string;
    /**
     * 
     * @type {number}
     * @memberof AttendanceTeacherDto
     */
    numberOfStudentNotes?: number;
    /**
     * 
     * @type {InvoiceProductType}
     * @memberof AttendanceTeacherDto
     */
    productType?: InvoiceProductType;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    studentCourseSessionId?: string;
    /**
     * 
     * @type {number}
     * @memberof AttendanceTeacherDto
     */
    numberOfSessionPackRemaining?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceTeacherDto
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceTeacherDto
     */
    isSessionPack?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AttendanceTeacherDto
     */
    numberOfTemperatures?: number;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    checkInPictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    checkOutPictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    checkInPictureId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceTeacherDto
     */
    isRequiredPictureAndTemperature?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    checkedIntoCourseName?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    courseClassId?: string;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    checkInByName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceTeacherDto
     */
    requireCheckinPicture?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceTeacherDto
     */
    requireCheckinTemperature?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceTeacherDto
     */
    enableGuardianCheckinOption?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AttendanceTeacherDto
     */
    otherCheckinOptions?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AttendanceTeacherDto
     */
    checkOutByName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceTeacherDto
     */
    requireCheckoutPicture?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceTeacherDto
     */
    enableGuardianCheckOutOption?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AttendanceTeacherDto
     */
    otherCheckOutOptions?: Array<string>;
}
/**
 * 
 * @export
 * @interface Attributes
 */
export interface Attributes {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    ApproximateReceiveCount: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    SentTimestamp: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    SequenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    MessageGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    SenderId: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    MessageDeduplicationId: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    ApproximateFirstReceiveTimestamp: string;
}
/**
 * 
 * @export
 * @interface AuditHistoryDto
 */
export interface AuditHistoryDto {
    [key: string]: object | any;

    /**
     * 
     * @type {ObjectAuditHistory}
     * @memberof AuditHistoryDto
     */
    object?: ObjectAuditHistory;
    /**
     * 
     * @type {string}
     * @memberof AuditHistoryDto
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditHistoryDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditHistoryDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditHistoryDto
     */
    paymentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditHistoryDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AuditHistoryDto
     */
    paidAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditHistoryDto
     */
    oldValue?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditHistoryDto
     */
    newValue?: string;
}
/**
 * 
 * @export
 * @interface AvailableDiscountCodeDto
 */
export interface AvailableDiscountCodeDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ShoppingCartDto>}
     * @memberof AvailableDiscountCodeDto
     */
    validCartItemWithDiscountCode: Array<ShoppingCartDto>;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof AvailableDiscountCodeDto
     */
    studentUsedDiscountCode: Array<UserDto>;
    /**
     * 
     * @type {number | string}
     * @memberof AvailableDiscountCodeDto
     */
    discountPercent: number | string;
    /**
     * 
     * @type {number}
     * @memberof AvailableDiscountCodeDto
     */
    discountAmount: number;
    /**
     * 
     * @type {number}
     * @memberof AvailableDiscountCodeDto
     */
    agoraBears: number;
    /**
     * 
     * @type {number}
     * @memberof AvailableDiscountCodeDto
     */
    epBears: number;
    /**
     * 
     * @type {string}
     * @memberof AvailableDiscountCodeDto
     */
    campaignId: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableDiscountCodeDto
     */
    campaignCode: string;
}
/**
 * 
 * @export
 * @interface AvailableRoomsResponse
 */
export interface AvailableRoomsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof AvailableRoomsResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableRoomsResponse
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface AvailableVoucherCampaignDto
 */
export interface AvailableVoucherCampaignDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ShoppingCartDto>}
     * @memberof AvailableVoucherCampaignDto
     */
    validCartItem: Array<ShoppingCartDto>;
    /**
     * 
     * @type {string}
     * @memberof AvailableVoucherCampaignDto
     */
    campaignId: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableVoucherCampaignDto
     */
    campaignVoucherRecordId: string;
    /**
     * 
     * @type {number | string}
     * @memberof AvailableVoucherCampaignDto
     */
    valueOfVoucher: number | string;
    /**
     * 
     * @type {number | string}
     * @memberof AvailableVoucherCampaignDto
     */
    minBasketValue: number | string;
    /**
     * 
     * @type {string}
     * @memberof AvailableVoucherCampaignDto
     */
    errorMessage?: string;
}
/**
 * 
 * @export
 * @interface BatchNotificationEmailDto
 */
export interface BatchNotificationEmailDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof BatchNotificationEmailDto
     */
    TemplateId: number;
    /**
     * 
     * @type {string}
     * @memberof BatchNotificationEmailDto
     */
    From: string;
    /**
     * 
     * @type {string}
     * @memberof BatchNotificationEmailDto
     */
    To: string;
    /**
     * 
     * @type {NotificationEmailDto}
     * @memberof BatchNotificationEmailDto
     */
    TemplateModel: NotificationEmailDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BillingOrganisationPaymentMethodEnum {
    HitpayPaynow = 'hitpay_paynow',
    HitpayCreditCard = 'hitpay_credit_card',
    PaynowDirect = 'paynow_direct',
    BankTransfer = 'bank_transfer'
}

/**
 * 
 * @export
 * @interface BlogNewAuthor
 */
export interface BlogNewAuthor {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof BlogNewAuthor
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewAuthor
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewAuthor
     */
    photo?: string;
}
/**
 * 
 * @export
 * @interface BlogNewContactDto
 */
export interface BlogNewContactDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof BlogNewContactDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewContactDto
     */
    desc?: string;
}
/**
 * 
 * @export
 * @interface BlogNewDetailDto
 */
export interface BlogNewDetailDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof BlogNewDetailDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewDetailDto
     */
    bannerImageDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewDetailDto
     */
    publishedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewDetailDto
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewDetailDto
     */
    imageSmall?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewDetailDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewDetailDto
     */
    pageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewDetailDto
     */
    metaDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewDetailDto
     */
    metaKeywords?: string;
    /**
     * 
     * @type {BlogNewDetailDtoThumbnail}
     * @memberof BlogNewDetailDto
     */
    thumbnail?: BlogNewDetailDtoThumbnail;
    /**
     * 
     * @type {BlogNewAuthor}
     * @memberof BlogNewDetailDto
     */
    authorOnBlogPost?: BlogNewAuthor;
}
/**
 * 
 * @export
 * @interface BlogNewDetailDtoThumbnail
 */
export interface BlogNewDetailDtoThumbnail {
    /**
     * 
     * @type {string}
     * @memberof BlogNewDetailDtoThumbnail
     */
    alt?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewDetailDtoThumbnail
     */
    url: string;
}
/**
 * 
 * @export
 * @interface BlogNewPageContentDto
 */
export interface BlogNewPageContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<BlogNewPostDto>}
     * @memberof BlogNewPageContentDto
     */
    postList: Array<BlogNewPostDto>;
}
/**
 * 
 * @export
 * @interface BlogNewPageDetailContentDto
 */
export interface BlogNewPageDetailContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {BlogNewContactDto}
     * @memberof BlogNewPageDetailContentDto
     */
    contact?: BlogNewContactDto;
    /**
     * 
     * @type {BlogNewDetailDto}
     * @memberof BlogNewPageDetailContentDto
     */
    postContent?: BlogNewDetailDto;
    /**
     * 
     * @type {Array<BlogNewPostDto>}
     * @memberof BlogNewPageDetailContentDto
     */
    moreLikeThis?: Array<BlogNewPostDto>;
    /**
     * 
     * @type {MetaDto}
     * @memberof BlogNewPageDetailContentDto
     */
    meta?: MetaDto;
}
/**
 * 
 * @export
 * @interface BlogNewPostDto
 */
export interface BlogNewPostDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof BlogNewPostDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewPostDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewPostDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewPostDto
     */
    publishedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewPostDto
     */
    postType?: string;
    /**
     * 
     * @type {BlogNewPostDtoThumbnail}
     * @memberof BlogNewPostDto
     */
    thumbnail?: BlogNewPostDtoThumbnail;
}
/**
 * 
 * @export
 * @interface BlogNewPostDtoThumbnail
 */
export interface BlogNewPostDtoThumbnail {
    /**
     * 
     * @type {string}
     * @memberof BlogNewPostDtoThumbnail
     */
    alt: string;
    /**
     * 
     * @type {string}
     * @memberof BlogNewPostDtoThumbnail
     */
    url: string;
}
/**
 * 
 * @export
 * @interface BookAVisitExplanationDto
 */
export interface BookAVisitExplanationDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof BookAVisitExplanationDto
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof BookAVisitExplanationDto
     */
    content?: string;
}
/**
 * 
 * @export
 * @interface BookAVisitExplanationsDto
 */
export interface BookAVisitExplanationsDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<BookAVisitExplanationDto>}
     * @memberof BookAVisitExplanationsDto
     */
    explanations: Array<BookAVisitExplanationDto>;
    /**
     * 
     * @type {string}
     * @memberof BookAVisitExplanationsDto
     */
    button?: string;
}
/**
 * 
 * @export
 * @interface BookAVisitExplanationsResponse
 */
export interface BookAVisitExplanationsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {BookAVisitExplanationsDto}
     * @memberof BookAVisitExplanationsResponse
     */
    data: BookAVisitExplanationsDto;
}
/**
 * 
 * @export
 * @interface BrowseCourseButtonDto
 */
export interface BrowseCourseButtonDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof BrowseCourseButtonDto
     */
    Name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrowseCourseButtonDto
     */
    Link?: string;
    /**
     * 
     * @type {string}
     * @memberof BrowseCourseButtonDto
     */
    BackgroundColor?: string;
}
/**
 * 
 * @export
 * @interface BrowseCourseButtonResponse
 */
export interface BrowseCourseButtonResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {BrowseCourseButtonDto}
     * @memberof BrowseCourseButtonResponse
     */
    data: BrowseCourseButtonDto;
}
/**
 * 
 * @export
 * @interface BulkEnrolCoursesDto
 */
export interface BulkEnrolCoursesDto {
    [key: string]: object | any;

    /**
     * 
     * @type {ChildDto}
     * @memberof BulkEnrolCoursesDto
     */
    student: ChildDto;
    /**
     * 
     * @type {Array<CourseInstanceDto>}
     * @memberof BulkEnrolCoursesDto
     */
    courses: Array<CourseInstanceDto>;
}
/**
 * 
 * @export
 * @interface BulkEnrolOffPlatformStudentsRequest
 */
export interface BulkEnrolOffPlatformStudentsRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<string>}
     * @memberof BulkEnrolOffPlatformStudentsRequest
     */
    studentNameList: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BulkEnrolOffPlatformStudentsRequest
     */
    educationProviderId: string;
    /**
     * 
     * @type {string}
     * @memberof BulkEnrolOffPlatformStudentsRequest
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof BulkEnrolOffPlatformStudentsRequest
     */
    startTimeFrom?: string;
}
/**
 * 
 * @export
 * @interface BulkEnrolOffPlatformStudentsResponse
 */
export interface BulkEnrolOffPlatformStudentsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {BulkEnrolOffPlatformStudentsResponseDto}
     * @memberof BulkEnrolOffPlatformStudentsResponse
     */
    data: BulkEnrolOffPlatformStudentsResponseDto;
}
/**
 * 
 * @export
 * @interface BulkEnrolOffPlatformStudentsResponseDto
 */
export interface BulkEnrolOffPlatformStudentsResponseDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ChildDto>}
     * @memberof BulkEnrolOffPlatformStudentsResponseDto
     */
    newStudents: Array<ChildDto>;
    /**
     * 
     * @type {Array<GetBulkEnrolDuplicateStudentsResponseDto>}
     * @memberof BulkEnrolOffPlatformStudentsResponseDto
     */
    duplicateStudents: Array<GetBulkEnrolDuplicateStudentsResponseDto>;
}
/**
 * 
 * @export
 * @interface BusinessUnitDto
 */
export interface BusinessUnitDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof BusinessUnitDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessUnitDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessUnitDto
     */
    code?: string;
}
/**
 * 
 * @export
 * @interface ButtonLinkDto
 */
export interface ButtonLinkDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ButtonLinkDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ButtonLinkDto
     */
    backgroundColor: string;
    /**
     * 
     * @type {string}
     * @memberof ButtonLinkDto
     */
    url: string;
}
/**
 * 
 * @export
 * @interface CampaignDto
 */
export interface CampaignDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    campaignType?: CampaignDtoCampaignTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    numberActivation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    minCheckoutAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    courseStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    courseEndDate?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignDto
     */
    courseClassTypes?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    earnedHour?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    earnedCredit?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string | number}
     * @memberof CampaignDto
     */
    expirationHour?: string | number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    expirationSignUp?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    expirationCredit?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignDto
     */
    isPublishWebsite?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignDto
     */
    isUnlimitNumberActivation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    termsAndConditions?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    discountPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignDto
     */
    isFirstTimeBuyers?: boolean;
    /**
     * 
     * @type {Array<PriceRangeCampaignDto>}
     * @memberof CampaignDto
     */
    priceRangesApplied?: Array<PriceRangeCampaignDto>;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    applyTo?: CampaignDtoApplyToEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignDto
     */
    coursePeriodIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignDto
     */
    coursePeriodNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    transactionDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignDto
     */
    isUsedCredit?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    batchName?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    batchDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    valueOfVoucher?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    minBasketValue?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignDto
     */
    subjectIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignDto
     */
    subjectNames?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignDto
     */
    educationProviderIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    numberOfVouchersToGenerate?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    numberOfVouchersAllowed?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    totalVouchers?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    vouchersRedeemed?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    remainingVouchers?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    campaignVoucherRecordId?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    type?: CampaignDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    venueName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignDto
     */
    courseIds?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum CampaignDtoCampaignTypeEnum {
    GiveGet = 'give_get',
    FreeHoursGiveAway = 'free_hours_give_away',
    FreeHoursCoursePurchase = 'free_hours_course_purchase',
    DiscountCodes = 'discount_codes',
    FixedDiscount = 'fixed_discount',
    GiveGetCredit = 'give_get_credit',
    Vouchers = 'vouchers'
}
/**
    * @export
    * @enum {string}
    */
export enum CampaignDtoApplyToEnum {
    OncePerChild = 'once_per_child',
    OncePerParent = 'once_per_parent',
    Unlimited = 'unlimited'
}
/**
    * @export
    * @enum {string}
    */
export enum CampaignDtoTypeEnum {
    Campaign = 'campaign',
    Manual = 'manual'
}

/**
 * 
 * @export
 * @interface CampaignPageContentDto
 */
export interface CampaignPageContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CampaignPageContentDto
     */
    headerTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignPageContentDto
     */
    headerSubTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignPageContentDto
     */
    headerImage?: string;
    /**
     * 
     * @type {CampaignPageContentDtoHeaderLink}
     * @memberof CampaignPageContentDto
     */
    headerLink?: CampaignPageContentDtoHeaderLink;
}
/**
 * 
 * @export
 * @interface CampaignPageContentDtoHeaderLink
 */
export interface CampaignPageContentDtoHeaderLink {
    /**
     * 
     * @type {string}
     * @memberof CampaignPageContentDtoHeaderLink
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignPageContentDtoHeaderLink
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface CampaignShowcaseDto
 */
export interface CampaignShowcaseDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CampaignShowcaseDto
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignShowcaseDto
     */
    earnedHour?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignShowcaseDto
     */
    descriptions?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignShowcaseDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignShowcaseDto
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignShowcaseDto
     */
    campaignType?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CampaignTypeStatusEnum {
    Active = 'active',
    Inactive = 'inactive'
}

/**
 * 
 * @export
 * @interface CampaignVoucherRecordAttributes
 */
export interface CampaignVoucherRecordAttributes {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CampaignVoucherRecordAttributes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignVoucherRecordAttributes
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignVoucherRecordAttributes
     */
    campaignId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignVoucherRecordAttributes
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CampaignVoucherRecordAttributes
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignVoucherRecordAttributes
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignVoucherRecordAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignVoucherRecordAttributes
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface CancelCourseInstanceRequest
 */
export interface CancelCourseInstanceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CancelCourseInstanceRequest
     */
    courseInstanceReferenceId: string;
    /**
     * 
     * @type {string}
     * @memberof CancelCourseInstanceRequest
     */
    courseInstanceCancellationReasonId: string;
    /**
     * 
     * @type {string}
     * @memberof CancelCourseInstanceRequest
     */
    courseInstanceCancellationAdditionalRemark?: string;
}
/**
 * 
 * @export
 * @interface CancelRevenueDto
 */
export interface CancelRevenueDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<string>}
     * @memberof CancelRevenueDto
     */
    ids: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CancelRevenueDto
     */
    isEpSharing: boolean;
    /**
     * 
     * @type {string}
     * @memberof CancelRevenueDto
     */
    status: CancelRevenueDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CancelRevenueDto
     */
    entryType: CancelRevenueDtoEntryTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CancelRevenueDtoStatusEnum {
    Refunded = 'refunded',
    Rescheduled = 'rescheduled',
    Open = 'open',
    Voided = 'voided'
}
/**
    * @export
    * @enum {string}
    */
export enum CancelRevenueDtoEntryTypeEnum {
    Refund = 'refund',
    Reschedule = 'reschedule',
    Revenue = 'revenue'
}

/**
 * 
 * @export
 * @interface CancelSubscriptionRequest
 */
export interface CancelSubscriptionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CancelSubscriptionRequest
     */
    cancellationReason: string;
    /**
     * 
     * @type {string}
     * @memberof CancelSubscriptionRequest
     */
    subscriptionId: string;
}
/**
 * 
 * @export
 * @interface CartItemDto
 */
export interface CartItemDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CartItemDto
     */
    childrenId?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDto
     */
    sessionPackId?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDto
     */
    agoraCareId?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDto
     */
    cartType?: CartItemDtoCartTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CartItemDto
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof CartItemDto
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDto
     */
    reservedId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CartItemDtoCartTypeEnum {
    Course = 'course',
    AgoraCare = 'agora_care',
    SessionPack = 'session_pack'
}

/**
 * 
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CategoryParentMessageDto
 */
export interface CategoryParentMessageDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CategoryParentMessageDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryParentMessageDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryParentMessageDto
     */
    numberOfAssociatedMessages?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryParentMessageDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryParentMessageDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface ChallengePasswordRequest
 */
export interface ChallengePasswordRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ChallengePasswordRequest
     */
    newPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengePasswordRequest
     */
    tempPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengePasswordRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface ChangeTicketResponse
 */
export interface ChangeTicketResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {ITicket}
     * @memberof ChangeTicketResponse
     */
    data: ITicket;
}
/**
 * 
 * @export
 * @interface ChangeTicketStatusDto
 */
export interface ChangeTicketStatusDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ChangeTicketStatusDto
     */
    ticketId: string;
    /**
     * 
     * @type {TicketStatusEnum}
     * @memberof ChangeTicketStatusDto
     */
    status: TicketStatusEnum;
}
/**
 * 
 * @export
 * @interface ChargeAgoraHoursRequest
 */
export interface ChargeAgoraHoursRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ChargeAgoraHoursRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeAgoraHoursRequest
     */
    checkinLogId: string;
}
/**
 * 
 * @export
 * @interface ChargeWithSavedCardRequest
 */
export interface ChargeWithSavedCardRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ChargeWithSavedCardRequest
     */
    cardId: string;
    /**
     * 
     * @type {number}
     * @memberof ChargeWithSavedCardRequest
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof ChargeWithSavedCardRequest
     */
    subscriptionId: string;
    /**
     * 
     * @type {string | number}
     * @memberof ChargeWithSavedCardRequest
     */
    subscriptionHour: string | number;
    /**
     * 
     * @type {string}
     * @memberof ChargeWithSavedCardRequest
     */
    subscriptionName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeWithSavedCardRequest
     */
    currency?: string;
}
/**
 * 
 * @export
 * @interface ChargeWithSavedCardResponse
 */
export interface ChargeWithSavedCardResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ChargeWithSavedCardResponse
     */
    payment_id: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeWithSavedCardResponse
     */
    recurring_billing_id?: string;
    /**
     * 
     * @type {number}
     * @memberof ChargeWithSavedCardResponse
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ChargeWithSavedCardResponse
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeWithSavedCardResponse
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeWithSavedCardResponse
     */
    invoiceId?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CheckInType {
    In = 'check_in',
    Out = 'check_out'
}

/**
 * 
 * @export
 * @interface CheckOutMultipleStudentRequest
 */
export interface CheckOutMultipleStudentRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<string>}
     * @memberof CheckOutMultipleStudentRequest
     */
    studentIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface CheckOutStudentRequest
 */
export interface CheckOutStudentRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CheckOutStudentRequest
     */
    studentCourseSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof CheckOutStudentRequest
     */
    mediaId?: string;
    /**
     * 
     * @type {UpdateAttendanceRequestCheckInBy}
     * @memberof CheckOutStudentRequest
     */
    checkOutBy?: UpdateAttendanceRequestCheckInBy;
}
/**
 * 
 * @export
 * @interface CheckOverlapDto
 */
export interface CheckOverlapDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CheckOverlapDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckOverlapDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckOverlapDto
     */
    endTime?: string;
}
/**
 * 
 * @export
 * @interface CheckRedirectParamsDto
 */
export interface CheckRedirectParamsDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CheckRedirectParamsDto
     */
    subjectName?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckRedirectParamsDto
     */
    courseInstanceReferenceId?: string;
}
/**
 * 
 * @export
 * @interface CheckVenueResponse
 */
export interface CheckVenueResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CheckVenueResponseData}
     * @memberof CheckVenueResponse
     */
    data: CheckVenueResponseData;
}
/**
 * 
 * @export
 * @interface CheckVenueResponseData
 */
export interface CheckVenueResponseData {
    /**
     * 
     * @type {CompanyDto}
     * @memberof CheckVenueResponseData
     */
    venue?: CompanyDto;
    /**
     * 
     * @type {boolean}
     * @memberof CheckVenueResponseData
     */
    exist: boolean;
}
/**
 * 
 * @export
 * @interface CheckinLogDto
 */
export interface CheckinLogDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CheckinLogDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckinLogDto
     */
    checkinTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckinLogDto
     */
    checkoutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckinLogDto
     */
    remark?: string;
    /**
     * 
     * @type {CompanyDto}
     * @memberof CheckinLogDto
     */
    venue?: CompanyDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CheckinOutSourceEnum {
    AdminPortal = 'admin-portal',
    MobileApp = 'mobile-app',
    Kisi = 'kisi'
}

/**
 * 
 * @export
 * @interface CheckingIsPaymentCourseOrSessionPackResponse
 */
export interface CheckingIsPaymentCourseOrSessionPackResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CheckingIsPaymentCourseOrSessionPackResponse
     */
    data: CheckingIsPaymentCourseOrSessionPackResponseDataEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CheckingIsPaymentCourseOrSessionPackResponseDataEnum {
    Course = 'payment_course',
    SessionPack = 'payment_session_pack'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ChildColorEnum {
    Ffcb05 = '#FFCB05',
    F7916D = '#F7916D',
    _2Bddb9 = '#2BDDB9',
    _00B51A = '#00B51A',
    F75708 = '#F75708',
    _3851E5 = '#3851E5',
    _323232 = '#323232',
    _525252 = '#525252'
}

/**
 * 
 * @export
 * @interface ChildDto
 */
export interface ChildDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    fullname?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    userType?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    otherUserType?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    grade?: string;
    /**
     * 
     * @type {number}
     * @memberof ChildDto
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof ChildDto
     */
    maxAge?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    parentRelationShipType?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    gender?: ChildDtoGenderEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChildDto
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChildDto
     */
    venueNames?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ChildDto
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ChildDto
     */
    kisiUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ChildDto
     */
    isInvited?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    invoiceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    invoiceEmailAddress?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChildDto
     */
    isEmergencyContact?: boolean;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof ChildDto
     */
    venues?: Array<CompanyDto>;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    tempPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    postCode?: string;
    /**
     * 
     * @type {Array<IBrand>}
     * @memberof ChildDto
     */
    brands?: Array<IBrand>;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    parentPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    parentAvatar?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChildDto
     */
    isTrialSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChildDto
     */
    isShareParentContact?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    chineseName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    allergies?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    school?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    schoolId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    otherSchool?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {MediaDto}
     * @memberof ChildDto
     */
    birthdayCertificate?: MediaDto;
    /**
     * 
     * @type {number}
     * @memberof ChildDto
     */
    age?: number;
    /**
     * 
     * @type {number}
     * @memberof ChildDto
     */
    totalCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    studentReferenceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChildDto
     */
    hasBirthdayCertificate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    checkInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    checkOutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildDto
     */
    subName?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof ChildDto
     */
    parent?: UserDto;
}

/**
    * @export
    * @enum {string}
    */
export enum ChildDtoGenderEnum {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface ChildrenDto
 */
export interface ChildrenDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    level: string;
    /**
     * 
     * @type {number}
     * @memberof ChildrenDto
     */
    numberOfCourse: number;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    alias: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    dateOfBirth: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    avatar: string;
    /**
     * 
     * @type {AgeGroupDto}
     * @memberof ChildrenDto
     */
    ageGroup: AgeGroupDto;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    school?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    classId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChildrenDto
     */
    isValidDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    childColor?: string;
    /**
     * 
     * @type {number}
     * @memberof ChildrenDto
     */
    age?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    otherClass?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    chineseName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenDto
     */
    className?: string;
}
/**
 * 
 * @export
 * @interface ChineseAboutUsDto
 */
export interface ChineseAboutUsDto {
    [key: string]: object | any;

    /**
     * 
     * @type {ChineseAboutUsSingleContentDto}
     * @memberof ChineseAboutUsDto
     */
    singleContent: ChineseAboutUsSingleContentDto;
    /**
     * 
     * @type {Array<ChineseAboutUsOverviewDto>}
     * @memberof ChineseAboutUsDto
     */
    overviews: Array<ChineseAboutUsOverviewDto>;
    /**
     * 
     * @type {Array<ChineseAboutUsServiceDto>}
     * @memberof ChineseAboutUsDto
     */
    services: Array<ChineseAboutUsServiceDto>;
}
/**
 * 
 * @export
 * @interface ChineseAboutUsOverviewDto
 */
export interface ChineseAboutUsOverviewDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ChineseAboutUsOverviewDto
     */
    overviewTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ChineseAboutUsOverviewDto
     */
    overviewImage?: string;
    /**
     * 
     * @type {string}
     * @memberof ChineseAboutUsOverviewDto
     */
    overviewContent?: string;
    /**
     * 
     * @type {number}
     * @memberof ChineseAboutUsOverviewDto
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface ChineseAboutUsServiceDto
 */
export interface ChineseAboutUsServiceDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ChineseAboutUsServiceDto
     */
    ServiceTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ChineseAboutUsServiceDto
     */
    ServiceContent?: string;
    /**
     * 
     * @type {number}
     * @memberof ChineseAboutUsServiceDto
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface ChineseAboutUsSingleContentDto
 */
export interface ChineseAboutUsSingleContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ChineseAboutUsSingleContentDto
     */
    heroHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof ChineseAboutUsSingleContentDto
     */
    heroDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ChineseAboutUsSingleContentDto
     */
    heroImage?: string;
    /**
     * 
     * @type {string}
     * @memberof ChineseAboutUsSingleContentDto
     */
    overviewTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ChineseAboutUsSingleContentDto
     */
    agoraServiceTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ChineseAboutUsSingleContentDto
     */
    placeTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ChineseAboutUsSingleContentDto
     */
    placeContent?: string;
    /**
     * 
     * @type {string}
     * @memberof ChineseAboutUsSingleContentDto
     */
    thanksContent?: string;
    /**
     * 
     * @type {string}
     * @memberof ChineseAboutUsSingleContentDto
     */
    contactContent?: string;
}
/**
 * 
 * @export
 * @interface ClassDto
 */
export interface ClassDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ClassDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassDto
     */
    schoolId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    address?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyDto
     */
    addressToArray?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    uenNo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    isGST?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    gstNo?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    contactNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    originationId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    createdByFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    updatedByFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    status?: CompanyDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    postcode?: string;
    /**
     * 
     * @type {Array<ClassDto>}
     * @memberof CompanyDto
     */
    classes?: Array<ClassDto>;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    schoolId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    schoolName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    schoolSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    venueEmail?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyDto
     */
    gstPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    venueType?: CompanyDtoVenueTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyDto
     */
    parentIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    billingOrganisationId?: string;
    /**
     * 
     * @type {IBillingOrganisation}
     * @memberof CompanyDto
     */
    billingOrganisation?: IBillingOrganisation;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    brandId?: string;
    /**
     * 
     * @type {IBrand}
     * @memberof CompanyDto
     */
    brand?: IBrand;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    senderEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    slug?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyDto
     */
    messageDaysAfterCourseFinished?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CompanyDtoStatusEnum {
    Registered = 'registered',
    Unregistered = 'unregistered'
}
/**
    * @export
    * @enum {string}
    */
export enum CompanyDtoVenueTypeEnum {
    Agora = 'agora',
    AtSchool = 'at_school'
}

/**
 * 
 * @export
 * @interface ConfirmPasswordRequest
 */
export interface ConfirmPasswordRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ConfirmPasswordRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmPasswordRequest
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmPasswordRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface ConfirmationPaymentTermsDto
 */
export interface ConfirmationPaymentTermsDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ConfirmationPaymentTermsDto
     */
    page: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmationPaymentTermsDto
     */
    content: string;
}
/**
 * 
 * @export
 * @interface ContentAndImageSplitDto
 */
export interface ContentAndImageSplitDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ContentAndImageSplitDto
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ContentAndImageSplitDto
     */
    content: string;
}
/**
 * 
 * @export
 * @interface ContextSensitiveHelpDto
 */
export interface ContextSensitiveHelpDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ContextSensitiveHelpDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ContextSensitiveHelpDto
     */
    section?: string;
    /**
     * 
     * @type {string}
     * @memberof ContextSensitiveHelpDto
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof ContextSensitiveHelpDto
     */
    forObject?: ContextSensitiveHelpDtoForObjectEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ContextSensitiveHelpDtoForObjectEnum {
    Course = 'course',
    CourseInstance = 'course_instance',
    Refund = 'refund'
}

/**
 * 
 * @export
 * @interface CourseAreaDto
 */
export interface CourseAreaDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseAreaDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseAreaDto
     */
    name?: string;
    /**
     * 
     * @type {number | string}
     * @memberof CourseAreaDto
     */
    amount?: number | string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseAreaDto
     */
    subjectIds?: Array<string>;
    /**
     * 
     * @type {Array<CourseAreaDto>}
     * @memberof CourseAreaDto
     */
    subjects?: Array<CourseAreaDto>;
}
/**
 * 
 * @export
 * @interface CourseCalendarDto
 */
export interface CourseCalendarDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseCalendarDto
     */
    courseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseCalendarDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseCalendarDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseCalendarDto
     */
    coursePeriodColor?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseCalendarDto
     */
    coursePeriodId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseCalendarDto
     */
    courseSlug?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseCalendarDto
     */
    customMaxAge?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseCalendarDto
     */
    customMinAge?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseCalendarDto
     */
    educationProviderImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseCalendarDto
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseCalendarDto
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseCalendarDto
     */
    gradeLevelName?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseCalendarDto
     */
    maxAge?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseCalendarDto
     */
    minAge?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseCalendarDto
     */
    sessionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseCalendarDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseCalendarDto
     */
    subjectName?: string;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof CourseCalendarDto
     */
    students?: Array<UserDto>;
}
/**
 * 
 * @export
 * @interface CourseClassDto
 */
export interface CourseClassDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseClassDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClassDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClassDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClassDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClassDto
     */
    venueName?: string;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof CourseClassDto
     */
    teachers?: Array<UserDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseClassDto
     */
    teacherIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseClassDto
     */
    studentIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseClassDto
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClassDto
     */
    status?: CourseClassDtoStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseClassDto
     */
    parentIds?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum CourseClassDtoStatusEnum {
    Active = 'active',
    Inactive = 'inactive'
}

/**
 * 
 * @export
 * @interface CourseClassesResponse
 */
export interface CourseClassesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseClassDto>}
     * @memberof CourseClassesResponse
     */
    data: Array<CourseClassDto>;
}
/**
 * 
 * @export
 * @interface CourseDetailDto
 */
export interface CourseDetailDto {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseDto}
     * @memberof CourseDetailDto
     */
    course: CourseDto;
    /**
     * 
     * @type {Array<CourseInstanceDto>}
     * @memberof CourseDetailDto
     */
    instances: Array<CourseInstanceDto>;
    /**
     * 
     * @type {Array<CourseSessionDto>}
     * @memberof CourseDetailDto
     */
    sessions: Array<CourseSessionDto>;
    /**
     * 
     * @type {ProviderDto}
     * @memberof CourseDetailDto
     */
    provider: ProviderDto;
    /**
     * 
     * @type {SubjectDto}
     * @memberof CourseDetailDto
     */
    subject: SubjectDto;
    /**
     * 
     * @type {AgeGroupDto}
     * @memberof CourseDetailDto
     */
    gradeLevel?: AgeGroupDto;
    /**
     * 
     * @type {Array<AreaDto>}
     * @memberof CourseDetailDto
     */
    subjectAreas: Array<AreaDto>;
    /**
     * 
     * @type {Array<CourseMediaDto>}
     * @memberof CourseDetailDto
     */
    courseMedias: Array<CourseMediaDto>;
    /**
     * 
     * @type {MetaDto}
     * @memberof CourseDetailDto
     */
    meta?: MetaDto;
    /**
     * 
     * @type {SEODataDto}
     * @memberof CourseDetailDto
     */
    seoData?: SEODataDto;
    /**
     * 
     * @type {Array<SessionPackDto>}
     * @memberof CourseDetailDto
     */
    sessionPacks: Array<SessionPackDto>;
}
/**
 * 
 * @export
 * @interface CourseDto
 */
export interface CourseDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    rawDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    review?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    maxAge?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    customMinAge?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    customMaxAge?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    replacementExchangeAllowed?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    replacementNoticePeriod?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    replacementAllowOverriding?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    replacementPenalty?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    isUnlimitedReplacement?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    courseStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    approvedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    courseSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    gradeLevelId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    gradeLevelName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    updatedByFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    createdByFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    image?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseDto
     */
    subjectType?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    providerId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseDto
     */
    areaNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    areaIds?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    subjectName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    providerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    approverId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    approverName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    subjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    epCourseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    trialSessionPrice?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    allowTrialSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    allowMakeupSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    applyTrialSession?: CourseDtoApplyTrialSessionEnum;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    applyMakeupSession?: CourseDtoApplyMakeupSessionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    allowSignUpStartMidWay?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    allowConcurrentSessions?: boolean;
    /**
     * 
     * @type {Array<CourseMediaDto>}
     * @memberof CourseDto
     */
    media?: Array<CourseMediaDto>;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    coursePeriodId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    numOfSignUps?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    numOfSessions?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    numOfStudentTrial?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    numOfReservedSeats?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    coursePeriodName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    metaDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    metaKeywords?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    schoolName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseDto
     */
    schoolSlug?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    isAutoReEnrollStudent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    isSuppressCourseFeedback?: boolean;
    /**
     * 
     * @type {BusinessUnitDto}
     * @memberof CourseDto
     */
    businessUnit?: BusinessUnitDto;
    /**
     * 
     * @type {ProductDto}
     * @memberof CourseDto
     */
    product?: ProductDto;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    sendCourseReminder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    hideTimingOptions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    requireCheckoutPicture?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    enableGuardianCheckoutOption?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseDto
     */
    otherCheckoutOptions?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    requireCheckinPicture?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    requireCheckinTemperature?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    enableGuardianCheckinOption?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseDto
     */
    otherCheckinOptions?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum CourseDtoApplyTrialSessionEnum {
    FirstSession = 'first_session',
    AllSession = 'all_session'
}
/**
    * @export
    * @enum {string}
    */
export enum CourseDtoApplyMakeupSessionEnum {
    FirstSession = 'first_session',
    AllSession = 'all_session'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum CourseEnrolledStudentHistoryMethodEnum {
    Invoice = 'invoice',
    Checkout = 'checkout',
    Subscription = 'subscription'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum CourseEnrolledStudentHistoryModelEnum {
    RevenueShare = 'revenue_share',
    SessionPack = 'session_pack'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum CourseEnrolledStudentHistoryRefundStatusEnum {
    Refunded = 'refunded',
    PartiallyRefunded = 'partially_refunded'
}

/**
 * 
 * @export
 * @interface CourseEnrolmentDto
 */
export interface CourseEnrolmentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentDto
     */
    subscriptionId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentDto
     */
    courseSeriesId?: string;
    /**
     * 
     * @type {CourseEnrolmentStatus}
     * @memberof CourseEnrolmentDto
     */
    status?: CourseEnrolmentStatus;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentDto
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentDto
     */
    lastDateWithdrawal?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentDto
     */
    enrollmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentDto
     */
    invoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentDto
     */
    courseId?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CourseEnrolmentStatus {
    Canceled = 'canceled',
    WithdrawalPending = 'withdrawal_pending',
    Withdrawn = 'withdrawn',
    New = 'new',
    TransferPending = 'transfer_pending',
    Transferred = 'transferred',
    EnrolmentPending = 'enrolment_pending',
    Enrolled = 'enrolled'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum CourseEnrolmentStatusType {
    Active = 'active',
    Canceled = 'canceled',
    WithdrawalPending = 'withdrawal_pending',
    Withdrawn = 'withdrawn',
    New = 'new'
}

/**
 * 
 * @export
 * @interface CourseEnrolmentType
 */
export interface CourseEnrolmentType {
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentType
     */
    lastDateWithdrawal?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentType
     */
    startDate?: string;
    /**
     * 
     * @type {CourseEnrolmentStatusType}
     * @memberof CourseEnrolmentType
     */
    status?: CourseEnrolmentStatusType;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentType
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface CourseEnrolmentViewDto
 */
export interface CourseEnrolmentViewDto {
    [key: string]: object | any;

    /**
     * 
     * @type {UserDto}
     * @memberof CourseEnrolmentViewDto
     */
    parent?: UserDto;
    /**
     * 
     * @type {ChildDto}
     * @memberof CourseEnrolmentViewDto
     */
    student?: ChildDto;
    /**
     * 
     * @type {CourseDto}
     * @memberof CourseEnrolmentViewDto
     */
    course?: CourseDto;
    /**
     * 
     * @type {ProviderDto}
     * @memberof CourseEnrolmentViewDto
     */
    provider?: ProviderDto;
    /**
     * 
     * @type {ProductDto}
     * @memberof CourseEnrolmentViewDto
     */
    product?: ProductDto;
    /**
     * 
     * @type {Array<InvoiceNewDto>}
     * @memberof CourseEnrolmentViewDto
     */
    invoices?: Array<InvoiceNewDto>;
    /**
     * 
     * @type {InvoiceNewDto}
     * @memberof CourseEnrolmentViewDto
     */
    invoice?: InvoiceNewDto;
    /**
     * 
     * @type {SubscriptionDto}
     * @memberof CourseEnrolmentViewDto
     */
    subscription?: SubscriptionDto;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentViewDto
     */
    paymentStatus?: string;
    /**
     * 
     * @type {CourseEnrolmentViewDtoCreatedData}
     * @memberof CourseEnrolmentViewDto
     */
    createdData?: CourseEnrolmentViewDtoCreatedData;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseEnrolmentViewDto
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseEnrolmentViewDto
     */
    providerIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseEnrolmentViewDto
     */
    businessUnitIds?: Array<string>;
    /**
     * 
     * @type {CourseEnrolmentViewDtoRowActions}
     * @memberof CourseEnrolmentViewDto
     */
    rowActions?: CourseEnrolmentViewDtoRowActions;
    /**
     * 
     * @type {CourseEnrolmentViewDtoCourseEnrolment}
     * @memberof CourseEnrolmentViewDto
     */
    courseEnrolment?: CourseEnrolmentViewDtoCourseEnrolment;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentViewDto
     */
    registrationLogId?: string;
}
/**
 * 
 * @export
 * @interface CourseEnrolmentViewDtoCourseEnrolment
 */
export interface CourseEnrolmentViewDtoCourseEnrolment {
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentViewDtoCourseEnrolment
     */
    startDate: string;
    /**
     * 
     * @type {CourseEnrolmentStatus}
     * @memberof CourseEnrolmentViewDtoCourseEnrolment
     */
    status?: CourseEnrolmentStatus;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentViewDtoCourseEnrolment
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CourseEnrolmentViewDtoCreatedData
 */
export interface CourseEnrolmentViewDtoCreatedData {
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentViewDtoCreatedData
     */
    createdSource: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEnrolmentViewDtoCreatedData
     */
    createdDate: string;
}
/**
 * 
 * @export
 * @interface CourseEnrolmentViewDtoRowActions
 */
export interface CourseEnrolmentViewDtoRowActions {
    /**
     * 
     * @type {boolean}
     * @memberof CourseEnrolmentViewDtoRowActions
     */
    allowUpdateSubscription?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseEnrolmentViewDtoRowActions
     */
    allowCreateSubscription?: boolean;
}
/**
 * 
 * @export
 * @interface CourseEventsHomepageDto
 */
export interface CourseEventsHomepageDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseEventsHomepageDto
     */
    courseMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEventsHomepageDto
     */
    courseName?: string;
    /**
     * 
     * @type {number | string}
     * @memberof CourseEventsHomepageDto
     */
    minAge?: number | string;
    /**
     * 
     * @type {number | string}
     * @memberof CourseEventsHomepageDto
     */
    maxAge?: number | string;
    /**
     * 
     * @type {string}
     * @memberof CourseEventsHomepageDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEventsHomepageDto
     */
    coursePeriodId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEventsHomepageDto
     */
    coursePeriodName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEventsHomepageDto
     */
    courseSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEventsHomepageDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEventsHomepageDto
     */
    subjectName?: string;
}
/**
 * 
 * @export
 * @interface CourseFilterContentDto
 */
export interface CourseFilterContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseFilterContentDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterContentDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterContentDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterContentDto
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterContentDto
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterContentDto
     */
    maxAge?: number;
}
/**
 * 
 * @export
 * @interface CourseFilterDataDto
 */
export interface CourseFilterDataDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseFilterContentDto>}
     * @memberof CourseFilterDataDto
     */
    gradeLevels: Array<CourseFilterContentDto>;
    /**
     * 
     * @type {Array<CourseFilterContentDto>}
     * @memberof CourseFilterDataDto
     */
    coursePeriods: Array<CourseFilterContentDto>;
    /**
     * 
     * @type {Array<CourseFilterContentDto>}
     * @memberof CourseFilterDataDto
     */
    educationProviders: Array<CourseFilterContentDto>;
    /**
     * 
     * @type {Array<CourseAreaDto>}
     * @memberof CourseFilterDataDto
     */
    areas: Array<CourseAreaDto>;
}
/**
 * 
 * @export
 * @interface CourseFilterDto
 */
export interface CourseFilterDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    ageGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    agegroupName?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    amountOfSessions?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    bookmarks?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseFilterDto
     */
    courseAreaIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseFormatId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseId: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseImage?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseFilterDto
     */
    courseInstanceCourseType?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseInstanceDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseFilterDto
     */
    courseInstanceFormat?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseInstanceId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseFilterDto
     */
    courseInstancePeriod?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {Array<CourseFilterDto>}
     * @memberof CourseFilterDto
     */
    courseInstances?: Array<CourseFilterDto>;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseInstanceStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseInstanceUpdatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseMediaImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    coursePeriodId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    coursePeriodColor?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseSessionId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    courseSessionIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    courseUpdatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    dateSessionEnd?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    dateSessionStart?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    duration?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    gradeLevelId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    gradleLevelName?: string;
    /**
     * 
     * @type {string | number}
     * @memberof CourseFilterDto
     */
    hoursOfSession?: string | number;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    invoiceLineId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    isAllowTrial?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseFilterDto
     */
    isComingSoon?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseFilterDto
     */
    isTrialSingleSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    maxAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    maxAgeGradleLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    minAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    minAgeGradleLevel?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    newestCourseInstanceStatusValue?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    newestInstanceSessionStart?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    numberOfSession?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    periodName?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    priceOriginal?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    pricePerSession?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    promotionEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    promotionStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    providerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    providerSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    rangeTime?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    reviewers?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    room?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    sessionEndTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    sessionStartTime?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    students?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    subjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    subjectImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    subjectName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseFilterDto
     */
    subjectTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    teacher?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    timeSessionEnd?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    timeSessionStart?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    sessionPackId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    sessionPackNumberOfSessions?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseFilterDto
     */
    sessionPackPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseFilterDto
     */
    hasPriceModelSessionPacks?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseFilterDto
     */
    hasPriceModelRevShare?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseFilterDto
     */
    hasPriceModelRoomBooking?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseFilterDto
     */
    schoolSlug?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseFilterDto
     */
    allowBookingCourseStarted?: boolean;
}
/**
 * 
 * @export
 * @interface CourseFormatDto
 */
export interface CourseFormatDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseFormatDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseFormatDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface CourseHeroDataDto
 */
export interface CourseHeroDataDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseHeroDataDto
     */
    schoolName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseHeroDataDto
     */
    schoolSlug?: string;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof CourseHeroDataDto
     */
    schoolVenues?: Array<CompanyDto>;
}
/**
 * 
 * @export
 * @interface CourseInstanceCancellationReasonDto
 */
export interface CourseInstanceCancellationReasonDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseInstanceCancellationReasonDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceCancellationReasonDto
     */
    reason?: string;
}
/**
 * 
 * @export
 * @interface CourseInstanceDetailDto
 */
export interface CourseInstanceDetailDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    gradeLevelName?: string;
    /**
     * 
     * @type {number | string}
     * @memberof CourseInstanceDetailDto
     */
    minAge?: number | string;
    /**
     * 
     * @type {number | string}
     * @memberof CourseInstanceDetailDto
     */
    maxAge?: number | string;
    /**
     * 
     * @type {number | string}
     * @memberof CourseInstanceDetailDto
     */
    customMinAge?: number | string;
    /**
     * 
     * @type {number | string}
     * @memberof CourseInstanceDetailDto
     */
    customMaxAge?: number | string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    courseStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    sessionStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    sessionEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    maxSessionEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    duration?: string;
    /**
     * 
     * @type {string | number}
     * @memberof CourseInstanceDetailDto
     */
    price?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof CourseInstanceDetailDto
     */
    noOfSeats?: string | number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    teacherName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    rateType?: string;
    /**
     * 
     * @type {string | number}
     * @memberof CourseInstanceDetailDto
     */
    takeRateAmount?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof CourseInstanceDetailDto
     */
    flatFeeAmount?: string | number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    referenceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDetailDto
     */
    allowTrialSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDetailDto
     */
    allowMakeupSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    applyTrialSession?: CourseInstanceDetailDtoApplyTrialSessionEnum;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    applyMakeupSession?: CourseInstanceDetailDtoApplyMakeupSessionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDetailDto
     */
    allowSignUpStartMidway?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    promotionName?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDetailDto
     */
    promotionPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    promotionStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    promotionEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    coursePeriodName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    coursePeriodId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    coursePeriodStart?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    coursePeriodEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    courseFormatId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    courseTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    teacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    epCourseInstanceCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDetailDto
     */
    trialSingleSessionPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDetailDto
     */
    isTeacherDisplayed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    approverId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDetailDto
     */
    numOfSessions?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDetailDto
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDetailDto
     */
    numberOfEnrollment?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDetailDto
     */
    studentBookingFeeAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    approvedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDetailDto
     */
    validSession?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    approvedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    approvedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    spaceName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    agreementLink?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDetailDto
     */
    allowBookingCourseStarted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDetailDto
     */
    hasPriceModalRevSessionPacks?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDetailDto
     */
    hasPriceModelRevShare?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDetailDto
     */
    hasPriceModelRoomBooking?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDetailDto
     */
    roomBookingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDetailDto
     */
    roomBookingInvoicePrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDetailDto
     */
    isCalcFeeFromSessions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDetailDto
     */
    isGenerateInvoice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDetailDto
     */
    hasPriceModelSessionPacks?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    invoiceStatus?: CourseInstanceDetailDtoInvoiceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    invoiceReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    invoiceProductType?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    invoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    invoiceUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    additionalInfo?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceDetailDto
     */
    additionalTeacherIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceDetailDto
     */
    additionalTeacherNames?: Array<string>;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof CourseInstanceDetailDto
     */
    teachers?: Array<UserDto>;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDetailDto
     */
    isValidToCancel?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    cancellationReason?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    cancellationRemark?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDetailDto
     */
    invoiceTitle?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDetailDto
     */
    autoCheckin?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CourseInstanceDetailDtoApplyTrialSessionEnum {
    FirstSession = 'first_session',
    AllSession = 'all_session'
}
/**
    * @export
    * @enum {string}
    */
export enum CourseInstanceDetailDtoApplyMakeupSessionEnum {
    FirstSession = 'first_session',
    AllSession = 'all_session'
}
/**
    * @export
    * @enum {string}
    */
export enum CourseInstanceDetailDtoInvoiceStatusEnum {
    Draft = 'draft',
    Pending = 'pending',
    Deleted = 'deleted',
    Sent = 'sent',
    Paid = 'paid',
    Voided = 'voided',
    Overdue = 'overdue'
}

/**
 * 
 * @export
 * @interface CourseInstanceDto
 */
export interface CourseInstanceDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    courseId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDto
     */
    originalPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDto
     */
    bookmarks?: number;
    /**
     * 
     * @type {UserDto}
     * @memberof CourseInstanceDto
     */
    teacher?: UserDto;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDto
     */
    numOfSeat?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDto
     */
    numOfChild?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDto
     */
    isFull?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDto
     */
    allowTrialSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    applyTrialSession?: CourseInstanceDtoApplyTrialSessionEnum;
    /**
     * 
     * @type {CourseFormatDto}
     * @memberof CourseInstanceDto
     */
    courseFormat?: CourseFormatDto;
    /**
     * 
     * @type {CoursePeriodDto}
     * @memberof CourseInstanceDto
     */
    coursePeriod?: CoursePeriodDto;
    /**
     * 
     * @type {CourseTypeDto}
     * @memberof CourseInstanceDto
     */
    courseType?: CourseTypeDto;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    promotionName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    updateAt?: string;
    /**
     * 
     * @type {number | string}
     * @memberof CourseInstanceDto
     */
    duration?: number | string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDto
     */
    remaining?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDto
     */
    numOfSessions?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    rateType?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDto
     */
    flatFeeAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDto
     */
    takeRateAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    courseSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    gradeLevelId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    gradeLevelName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    minAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    maxAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    customMinAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    customMaxAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    teacherName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    teacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    instanceCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceDto
     */
    subjectTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDto
     */
    isTeacherDisplayed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDto
     */
    studentBookingFeeAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    promotionStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    promotionEndDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDto
     */
    allowSignUpStartMidway?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDto
     */
    allowBookingCourseStarted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDto
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    schoolSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    subjectName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    agreementLink?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    courseReferenceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDto
     */
    hasPriceModelSessionPacks?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceDto
     */
    hasPriceModelRevShare?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceDto
     */
    gstPercent?: number;
    /**
     * 
     * @type {Array<CourseSessionDto>}
     * @memberof CourseInstanceDto
     */
    courseSessions?: Array<CourseSessionDto>;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    courseSeriesId?: string;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof CourseInstanceDto
     */
    parentEnrolledList?: Array<UserDto>;
    /**
     * 
     * @type {CourseDto}
     * @memberof CourseInstanceDto
     */
    course?: CourseDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceDto
     */
    additionalTeacherIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceDto
     */
    parentIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceDto
     */
    venueName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CourseInstanceDtoApplyTrialSessionEnum {
    FirstSession = 'first_session',
    AllSession = 'all_session'
}

/**
 * 
 * @export
 * @interface CourseInstanceForPaymentDto
 */
export interface CourseInstanceForPaymentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    ageGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    agegroupName?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    amountOfSessions?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    bookmarks?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceForPaymentDto
     */
    courseAreaIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseFormatId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseId: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseImage?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceForPaymentDto
     */
    courseInstanceCourseType?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseInstanceDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceForPaymentDto
     */
    courseInstanceFormat?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseInstanceId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceForPaymentDto
     */
    courseInstancePeriod?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {Array<CourseFilterDto>}
     * @memberof CourseInstanceForPaymentDto
     */
    courseInstances?: Array<CourseFilterDto>;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseInstanceStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseInstanceUpdatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseMediaImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    coursePeriodId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    coursePeriodColor?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseSessionId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    courseSessionIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    courseUpdatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    dateSessionEnd?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    dateSessionStart?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    duration?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    gradeLevelId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    gradleLevelName?: string;
    /**
     * 
     * @type {string | number}
     * @memberof CourseInstanceForPaymentDto
     */
    hoursOfSession?: string | number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    invoiceLineId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    isAllowTrial?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceForPaymentDto
     */
    isComingSoon?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceForPaymentDto
     */
    isTrialSingleSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    maxAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    maxAgeGradleLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    minAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    minAgeGradleLevel?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    newestCourseInstanceStatusValue?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    newestInstanceSessionStart?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    numberOfSession?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    periodName?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    priceOriginal?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    pricePerSession?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    promotionEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    promotionStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    providerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    providerSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    rangeTime?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    reviewers?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    room?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    sessionEndTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    sessionStartTime?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    students?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    subjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    subjectImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    subjectName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceForPaymentDto
     */
    subjectTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    teacher?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    timeSessionEnd?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    timeSessionStart?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    sessionPackId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    sessionPackNumberOfSessions?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceForPaymentDto
     */
    sessionPackPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceForPaymentDto
     */
    hasPriceModelSessionPacks?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceForPaymentDto
     */
    hasPriceModelRevShare?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceForPaymentDto
     */
    hasPriceModelRoomBooking?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceForPaymentDto
     */
    schoolSlug?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceForPaymentDto
     */
    allowBookingCourseStarted?: boolean;
}
/**
 * 
 * @export
 * @interface CourseInstanceRescheduleDto
 */
export interface CourseInstanceRescheduleDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    courseId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    originalPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    bookmarks?: number;
    /**
     * 
     * @type {UserDto}
     * @memberof CourseInstanceRescheduleDto
     */
    teacher?: UserDto;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    numOfSeat?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    numOfChild?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceRescheduleDto
     */
    isFull?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceRescheduleDto
     */
    allowTrialSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    applyTrialSession?: CourseInstanceRescheduleDtoApplyTrialSessionEnum;
    /**
     * 
     * @type {CourseFormatDto}
     * @memberof CourseInstanceRescheduleDto
     */
    courseFormat?: CourseFormatDto;
    /**
     * 
     * @type {CoursePeriodDto}
     * @memberof CourseInstanceRescheduleDto
     */
    coursePeriod?: CoursePeriodDto;
    /**
     * 
     * @type {CourseTypeDto}
     * @memberof CourseInstanceRescheduleDto
     */
    courseType?: CourseTypeDto;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    promotionName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    updateAt?: string;
    /**
     * 
     * @type {number | string}
     * @memberof CourseInstanceRescheduleDto
     */
    duration?: number | string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    remaining?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    numOfSessions?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    rateType?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    flatFeeAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    takeRateAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    courseSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    gradeLevelId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    gradeLevelName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    minAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    maxAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    customMinAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    customMaxAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    teacherName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    teacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    instanceCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceRescheduleDto
     */
    subjectTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceRescheduleDto
     */
    isTeacherDisplayed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    studentBookingFeeAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    promotionStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    promotionEndDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceRescheduleDto
     */
    allowSignUpStartMidway?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceRescheduleDto
     */
    allowBookingCourseStarted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceRescheduleDto
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    schoolSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    subjectName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    agreementLink?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    courseReferenceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceRescheduleDto
     */
    hasPriceModelSessionPacks?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceRescheduleDto
     */
    hasPriceModelRevShare?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    gstPercent?: number;
    /**
     * 
     * @type {Array<CourseSessionDto>}
     * @memberof CourseInstanceRescheduleDto
     */
    courseSessions?: Array<CourseSessionDto>;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    courseSeriesId?: string;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof CourseInstanceRescheduleDto
     */
    parentEnrolledList?: Array<UserDto>;
    /**
     * 
     * @type {CourseDto}
     * @memberof CourseInstanceRescheduleDto
     */
    course?: CourseDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceRescheduleDto
     */
    additionalTeacherIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceRescheduleDto
     */
    parentIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    venueName?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    revenueValue?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    numberOfEnrollment?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceRescheduleDto
     */
    courseSessionIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    attendedSessions?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseInstanceRescheduleDto
     */
    revenueIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    enrolledTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    enrollmentId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceRescheduleDto
     */
    isBeforePeriod?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseInstanceRescheduleDto
     */
    studentId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInstanceRescheduleDto
     */
    gstEpPercent?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseInstanceRescheduleDto
     */
    isStarted?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CourseInstanceRescheduleDtoApplyTrialSessionEnum {
    FirstSession = 'first_session',
    AllSession = 'all_session'
}

/**
 * 
 * @export
 * @interface CourseInstancesDto
 */
export interface CourseInstancesDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseInstancesDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstancesDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstancesDto
     */
    sessionDateStart: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInstancesDto
     */
    sessionDateEnd: string;
}
/**
 * 
 * @export
 * @interface CourseMediaDto
 */
export interface CourseMediaDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseMediaDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseMediaDto
     */
    type?: CourseMediaDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CourseMediaDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseMediaDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseMediaDto
     */
    mediaId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseMediaDto
     */
    courseMediaId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseMediaDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseMediaDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseMediaDto
     */
    order?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseMediaDto
     */
    isExistingFile?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CourseMediaDtoTypeEnum {
    Brochure = 'brochure',
    Image = 'image',
    Video = 'video'
}

/**
 * 
 * @export
 * @interface CoursePageContentDto
 */
export interface CoursePageContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CoursePageContentDto
     */
    topSectionImage?: string;
}
/**
 * 
 * @export
 * @interface CoursePeriodDto
 */
export interface CoursePeriodDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CoursePeriodDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursePeriodDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursePeriodDto
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface CoursePolicyDto
 */
export interface CoursePolicyDto {
    [key: string]: object | any;

    /**
     * 
     * @type {TermsTemplatePageDto}
     * @memberof CoursePolicyDto
     */
    pageDecor: TermsTemplatePageDto;
    /**
     * 
     * @type {Array<TermsTemplateContentDto>}
     * @memberof CoursePolicyDto
     */
    contents: Array<TermsTemplateContentDto>;
}
/**
 * 
 * @export
 * @interface CourseSeriesDto
 */
export interface CourseSeriesDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseSeriesDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSeriesDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSeriesDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSeriesDto
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSeriesDto
     */
    frequency?: CourseSeriesDtoFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof CourseSeriesDto
     */
    recurs?: CourseSeriesDtoRecursEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSeriesDto
     */
    includeWeekends?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSeriesDto
     */
    includePublicHolidays?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseSeriesDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSeriesDto
     */
    venueName?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseSeriesDto
     */
    takeRate?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseSeriesDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseSeriesDto
     */
    gstPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseSeriesDto
     */
    name?: string;
    /**
     * 
     * @type {Array<DayEnum>}
     * @memberof CourseSeriesDto
     */
    days?: Array<DayEnum>;
    /**
     * 
     * @type {string}
     * @memberof CourseSeriesDto
     */
    roomName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSeriesDto
     */
    roomId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseSeriesDto
     */
    numOfInstancesAhead?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSeriesDto
     */
    hasPriceModelSessionPacks?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSeriesDto
     */
    hasPriceModelRevShare?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CourseSeriesDto
     */
    totalNumberOfCourseInstances?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseSeriesDto
     */
    activeNumberOfCourseInstancesIncludingCurrentAndFeature?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseSeriesDto
     */
    numberOfStudentsInCurrentCourseInstance?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSeriesDto
     */
    isStudentEnrolled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseSeriesDto
     */
    teacherId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSeriesDto
     */
    allowTrialSession?: boolean;
    /**
     * 
     * @type {ApplyTrialSession}
     * @memberof CourseSeriesDto
     */
    applyTrialSession?: ApplyTrialSession | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSeriesDto
     */
    autoCheckin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSeriesDto
     */
    autoEnrolNextInstanceWithoutPayment?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseSeriesDto
     */
    additionalTeacherIds?: Array<string>;
    /**
     * 
     * @type {Array<CourseInstanceDto>}
     * @memberof CourseSeriesDto
     */
    courseInstancesSorted?: Array<CourseInstanceDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum CourseSeriesDtoFrequencyEnum {
    Monthly = 'monthly'
}
/**
    * @export
    * @enum {string}
    */
export enum CourseSeriesDtoRecursEnum {
    Monthly = 'monthly'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum CourseSeriesUpdateType {
    CurrentInstanceOnly = 'CURRENT_INSTANCE_ONLY',
    CurrentAndFuture = 'CURRENT_AND_FUTURE',
    FromCurrentOnwards = 'FROM_CURRENT_ONWARDS'
}

/**
 * 
 * @export
 * @interface CourseSessionDetailDto
 */
export interface CourseSessionDetailDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseSessionDetailDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDetailDto
     */
    gradeLevelName?: string;
    /**
     * 
     * @type {number | string}
     * @memberof CourseSessionDetailDto
     */
    minAge?: number | string;
    /**
     * 
     * @type {number | string}
     * @memberof CourseSessionDetailDto
     */
    maxAge?: number | string;
    /**
     * 
     * @type {number | string}
     * @memberof CourseSessionDetailDto
     */
    customMinAge?: number | string;
    /**
     * 
     * @type {number | string}
     * @memberof CourseSessionDetailDto
     */
    customMaxAge?: number | string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDetailDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDetailDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDetailDto
     */
    sessionStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDetailDto
     */
    sessionEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDetailDto
     */
    sessionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDetailDto
     */
    duration?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDetailDto
     */
    roomName?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseSessionDetailDto
     */
    capacity?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseSessionDetailDto
     */
    bufferSeats?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseSessionDetailDto
     */
    studentNumberSession?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDetailDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDetailDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDetailDto
     */
    courseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDetailDto
     */
    teacherName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDetailDto
     */
    courseReferenceId?: string;
}
/**
 * 
 * @export
 * @interface CourseSessionDto
 */
export interface CourseSessionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    sessionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseSessionDto
     */
    courseSessionIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    courseSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    roomName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    subjectName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    subjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    teacherName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    subjectImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    epImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    epId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    epName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    attendId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    attendStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    originalCourseSessionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSessionDto
     */
    isReplaced?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSessionDto
     */
    isTrialSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    schoolSlug?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSessionDto
     */
    isCourseInstanceBookingRoom?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionDto
     */
    venueId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSessionDto
     */
    hasPriceModelSessionPacks?: boolean;
}
/**
 * 
 * @export
 * @interface CourseSessionRequest
 */
export interface CourseSessionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseSessionRequest
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionRequest
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionRequest
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionRequest
     */
    roomId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionRequest
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSessionRequest
     */
    isRecurring?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionRequest
     */
    teacherId?: string;
}
/**
 * 
 * @export
 * @interface CourseSessionTeacherDto
 */
export interface CourseSessionTeacherDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    courseSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    providerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    courseMinAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    courseMaxAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    gradeLevelName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    gradeLevelMinAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    gradeLevelMaxAge?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    sessionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    roomName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    roomId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    roomTypeId?: string;
    /**
     * 
     * @type {string | number}
     * @memberof CourseSessionTeacherDto
     */
    numberOfSeat?: string | number;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    roomBookingId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    venueName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSessionTeacherDto
     */
    courseId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseSessionTeacherDto
     */
    numOfStudentsEnrolled?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseSessionTeacherDto
     */
    numOfStudentsAbsent?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseSessionTeacherDto
     */
    numOfStudentsCheckedIn?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseSessionTeacherDto
     */
    numOfStudentsCheckedOut?: number;
}
/**
 * 
 * @export
 * @interface CourseStudentDto
 */
export interface CourseStudentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseStudentDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseStudentDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseStudentDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseStudentDto
     */
    avatar?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseStudentDto
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CourseStudentDto
     */
    sessionPackLeft?: number;
}
/**
 * 
 * @export
 * @interface CourseTypeDto
 */
export interface CourseTypeDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CourseTypeDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseTypeDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface CoursesByChildDto
 */
export interface CoursesByChildDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CoursesByChildDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesByChildDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesByChildDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CoursesByChildDto
     */
    isTrialSingleSession?: boolean;
}
/**
 * 
 * @export
 * @interface CreateAbsenceNoticeTicketsRequest
 */
export interface CreateAbsenceNoticeTicketsRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAbsenceNoticeTicketsRequest
     */
    courseIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAbsenceNoticeTicketsRequest
     */
    studentIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAbsenceNoticeTicketsRequest
     */
    sessionDates: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateAbsenceNoticeTicketsRequest
     */
    note?: string;
}
/**
 * 
 * @export
 * @interface CreateBillingOrganisationDto
 */
export interface CreateBillingOrganisationDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CreateBillingOrganisationDto
     */
    registeredName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillingOrganisationDto
     */
    registeredAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillingOrganisationDto
     */
    uen?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillingOrganisationDto
     */
    accountNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillingOrganisationDto
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillingOrganisationDto
     */
    bankName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillingOrganisationDto
     */
    swiftBicCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillingOrganisationDto
     */
    bankCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillingOrganisationDto
     */
    branchCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillingOrganisationDto
     */
    bankAddress?: string;
    /**
     * 
     * @type {Array<BillingOrganisationPaymentMethodEnum>}
     * @memberof CreateBillingOrganisationDto
     */
    parentManualInvoicePaymentMethods: Array<BillingOrganisationPaymentMethodEnum>;
    /**
     * 
     * @type {Array<BillingOrganisationPaymentMethodEnum>}
     * @memberof CreateBillingOrganisationDto
     */
    epManualInvoicePaymentMethods: Array<BillingOrganisationPaymentMethodEnum>;
}
/**
 * 
 * @export
 * @interface CreateBillingOrganisationResponse
 */
export interface CreateBillingOrganisationResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {IBillingOrganisation}
     * @memberof CreateBillingOrganisationResponse
     */
    data: IBillingOrganisation;
}
/**
 * 
 * @export
 * @interface CreateBrandDto
 */
export interface CreateBrandDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CreateBrandDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandDto
     */
    parentAppHelpScreenUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandDto
     */
    postmarkServerToken?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandDto
     */
    senderEmail?: string;
}
/**
 * 
 * @export
 * @interface CreateCartItemResponse
 */
export interface CreateCartItemResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CreateCartItemResponse
     */
    childrenId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCartItemResponse
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCartItemResponse
     */
    sessionPackId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCartItemResponse
     */
    agoraCareId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCartItemResponse
     */
    cartType?: CreateCartItemResponseCartTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateCartItemResponse
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCartItemResponse
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCartItemResponse
     */
    reservedId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCartItemResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCartItemResponse
     */
    shoppingCartId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCartItemResponse
     */
    isExist: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateCartItemResponseCartTypeEnum {
    Course = 'course',
    AgoraCare = 'agora_care',
    SessionPack = 'session_pack'
}

/**
 * 
 * @export
 * @interface CreateCourseSessionResponse
 */
export interface CreateCourseSessionResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {boolean}
     * @memberof CreateCourseSessionResponse
     */
    success: boolean;
    /**
     * 
     * @type {Array<ValidateCourseSessionResponse>}
     * @memberof CreateCourseSessionResponse
     */
    data: Array<ValidateCourseSessionResponse>;
}
/**
 * 
 * @export
 * @interface CreateInvoiceToDto
 */
export interface CreateInvoiceToDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CreateInvoiceToDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvoiceToDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvoiceToDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvoiceToDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvoiceToDto
     */
    address?: string;
}
/**
 * 
 * @export
 * @interface CreateMessageGroupRequest
 */
export interface CreateMessageGroupRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CreateMessageGroupRequest
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMessageGroupRequest
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMessageGroupRequest
     */
    receiverId?: string;
}
/**
 * 
 * @export
 * @interface CreateMessageGroupResponse
 */
export interface CreateMessageGroupResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {MessageGroupDto}
     * @memberof CreateMessageGroupResponse
     */
    data: MessageGroupDto;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateMessageCategoryRequestDto
 */
export interface CreateOrUpdateMessageCategoryRequestDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateMessageCategoryRequestDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CreateRecurringPlanRequest
 */
export interface CreateRecurringPlanRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CreateRecurringPlanRequest
     */
    planId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurringPlanRequest
     */
    customerEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurringPlanRequest
     */
    customerName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurringPlanRequest
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurringPlanRequest
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurringPlanRequest
     */
    redirectUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurringPlanRequest
     */
    reference: string;
    /**
     * 
     * @type {string | number}
     * @memberof CreateRecurringPlanRequest
     */
    amount?: string | number;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurringPlanRequest
     */
    type: CreateRecurringPlanRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurringPlanRequest
     */
    subscriptionId?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateRecurringPlanRequest
     */
    subscriptionHour?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurringPlanRequest
     */
    subscriptionName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateRecurringPlanRequestTypeEnum {
    Create = 'create',
    SaveCard = 'save-card'
}

/**
 * 
 * @export
 * @interface CreateSOAOrInvoiceReportDataRequest
 */
export interface CreateSOAOrInvoiceReportDataRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ProviderDto>}
     * @memberof CreateSOAOrInvoiceReportDataRequest
     */
    providers: Array<ProviderDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateSOAOrInvoiceReportDataRequest
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSOAOrInvoiceReportDataRequest
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSOAOrInvoiceReportDataRequest
     */
    type: CreateSOAOrInvoiceReportDataRequestTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateSOAOrInvoiceReportDataRequestTypeEnum {
    Soa = 'soa',
    Invoice = 'invoice'
}

/**
 * 
 * @export
 * @interface CreateSOAOrInvoiceReportDataResponse
 */
export interface CreateSOAOrInvoiceReportDataResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<DataForInsertTransactionDto>}
     * @memberof CreateSOAOrInvoiceReportDataResponse
     */
    data: Array<DataForInsertTransactionDto>;
}
/**
 * 
 * @export
 * @interface CreateSubscriptionPlanRequest
 */
export interface CreateSubscriptionPlanRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionPlanRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionPlanRequest
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionPlanRequest
     */
    cycle: CreateSubscriptionPlanRequestCycleEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionPlanRequest
     */
    reference: string;
    /**
     * 
     * @type {string | number}
     * @memberof CreateSubscriptionPlanRequest
     */
    amount: string | number;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateSubscriptionPlanRequestCycleEnum {
    Weekly = 'weekly',
    Monthly = 'monthly',
    Yearly = 'yearly'
}

/**
 * 
 * @export
 * @interface CreateSubscriptionPlanResponse
 */
export interface CreateSubscriptionPlanResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionPlanResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionPlanResponse
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionPlanResponse
     */
    cycle: CreateSubscriptionPlanResponseCycleEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionPlanResponse
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionPlanResponse
     */
    id: string;
    /**
     * 
     * @type {string | number}
     * @memberof CreateSubscriptionPlanResponse
     */
    price: string | number;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionPlanResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionPlanResponse
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionPlanResponse
     */
    updated_at: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateSubscriptionPlanResponseCycleEnum {
    Weekly = 'weekly',
    Monthly = 'monthly',
    Yearly = 'yearly'
}

/**
 * 
 * @export
 * @interface CreateTicketDto
 */
export interface CreateTicketDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CreateTicketDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTicketDto
     */
    studentId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTicketDto
     */
    teacherIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateTicketDto
     */
    courseId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTicketDto
     */
    comments?: Array<string>;
    /**
     * 
     * @type {TicketTypeEnum}
     * @memberof CreateTicketDto
     */
    type?: TicketTypeEnum;
}
/**
 * 
 * @export
 * @interface CreateTicketResponse
 */
export interface CreateTicketResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {ITicket}
     * @memberof CreateTicketResponse
     */
    data: ITicket;
}
/**
 * 
 * @export
 * @interface CreateUserByAdminRequest
 */
export interface CreateUserByAdminRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminRequest
     */
    userType: CreateUserByAdminRequestUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminRequest
     */
    action?: CreateUserByAdminRequestActionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminRequest
     */
    password?: string;
    /**
     * 
     * @type {IBrand}
     * @memberof CreateUserByAdminRequest
     */
    brand?: IBrand | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminRequest
     */
    templateAlias?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateUserByAdminRequestUserTypeEnum {
    AgoraAdmin = 'agora_admin',
    Assistant = 'assistant',
    Brother = 'brother',
    BusinessDevelopment = 'business_development',
    Ceo = 'ceo',
    CurriculumDevelopment = 'curriculum_development',
    EpAdmin = 'ep_admin',
    EpAdminTeacher = 'ep_admin_teacher',
    Father = 'father',
    Finance = 'finance',
    Grandfather = 'grandfather',
    Grandmother = 'grandmother',
    Helper = 'helper',
    Mother = 'mother',
    Others = 'others',
    Owner = 'owner',
    Parent = 'parent',
    ProgramManager = 'program_manager',
    Sister = 'sister',
    Student = 'student',
    Teacher = 'teacher'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateUserByAdminRequestActionEnum {
    Suppress = 'SUPPRESS',
    Resend = 'RESEND'
}

/**
 * 
 * @export
 * @interface CreateUserByAdminResponse
 */
export interface CreateUserByAdminResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminResponse
     */
    userType: CreateUserByAdminResponseUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminResponse
     */
    fullName: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateUserByAdminResponseUserTypeEnum {
    AgoraAdmin = 'agora_admin',
    Assistant = 'assistant',
    Brother = 'brother',
    BusinessDevelopment = 'business_development',
    Ceo = 'ceo',
    CurriculumDevelopment = 'curriculum_development',
    EpAdmin = 'ep_admin',
    EpAdminTeacher = 'ep_admin_teacher',
    Father = 'father',
    Finance = 'finance',
    Grandfather = 'grandfather',
    Grandmother = 'grandmother',
    Helper = 'helper',
    Mother = 'mother',
    Others = 'others',
    Owner = 'owner',
    Parent = 'parent',
    ProgramManager = 'program_manager',
    Sister = 'sister',
    Student = 'student',
    Teacher = 'teacher'
}

/**
 * 
 * @export
 * @interface CurrentUser
 */
export interface CurrentUser {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof CurrentUser
     */
    sub: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUser
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUser
     */
    userType?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUser
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUser
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUser
     */
    adminImpersonationId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CurrentUser
     */
    assignedVenueIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentUser
     */
    isVenueAccessScope?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentUser
     */
    isCourseAccessScope?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CurrentUser
     */
    relationshipId?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUser
     */
    relationshipType?: CurrentUserRelationshipTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CurrentUserRelationshipTypeEnum {
    Guardian = 'guardian',
    Parent = 'parent'
}

/**
 * 
 * @export
 * @interface DailyScheduleDto
 */
export interface DailyScheduleDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    courseName: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    courseReferenceId: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    courseSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleDto
     */
    courseRequiredPictureAndTemperature?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    centreCheckInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    centreCheckOutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    courseCheckInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    courseCheckOutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    parentReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    studentName: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    studentChineseName?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    studentAvatar: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    studentRef?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    attendanceStatus: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    remark: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    checkInLogId?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    studentCourseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    roomName?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    roomCheckInId?: string;
    /**
     * 
     * @type {number}
     * @memberof DailyScheduleDto
     */
    numberOfSessionPackRemaining?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleDto
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleDto
     */
    isCourseInstanceRoomBooking?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleDto
     */
    isTrialSession?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DailyScheduleDto
     */
    numberOfTemperatures?: number;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    checkedInPictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    checkedOutPictureUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleDto
     */
    isSessionPack?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DailyScheduleDto
     */
    studentNoteCount: number;
    /**
     * 
     * @type {Array<StudentNotesDto>}
     * @memberof DailyScheduleDto
     */
    studentNotes?: Array<StudentNotesDto>;
    /**
     * 
     * @type {number}
     * @memberof DailyScheduleDto
     */
    kisiUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleDto
     */
    isAllowStudentLeave?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleDto
     */
    autoCheckIn?: boolean;
    /**
     * 
     * @type {DailyScheduleDtoCheckBySource}
     * @memberof DailyScheduleDto
     */
    checkBySource?: DailyScheduleDtoCheckBySource;
    /**
     * 
     * @type {StudentSessionType}
     * @memberof DailyScheduleDto
     */
    studentSessionType: StudentSessionType;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    venueId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleDto
     */
    shareParentContact?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    courseInstanceTeacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    checkInByName?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleDto
     */
    checkOutByName?: string;
}
/**
 * 
 * @export
 * @interface DailyScheduleDtoCheckBySource
 */
export interface DailyScheduleDtoCheckBySource {
    /**
     * 
     * @type {CheckinOutSourceEnum}
     * @memberof DailyScheduleDtoCheckBySource
     */
    checkoutSessionSource?: CheckinOutSourceEnum;
    /**
     * 
     * @type {CheckinOutSourceEnum}
     * @memberof DailyScheduleDtoCheckBySource
     */
    checkinSessionSource?: CheckinOutSourceEnum;
    /**
     * 
     * @type {CheckinOutSourceEnum}
     * @memberof DailyScheduleDtoCheckBySource
     */
    checkoutCentreSource?: CheckinOutSourceEnum;
    /**
     * 
     * @type {CheckinOutSourceEnum}
     * @memberof DailyScheduleDtoCheckBySource
     */
    checkinCentreSource?: CheckinOutSourceEnum;
}
/**
 * 
 * @export
 * @interface DataForInsertTransactionDto
 */
export interface DataForInsertTransactionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof DataForInsertTransactionDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DataForInsertTransactionDto
     */
    type: DataForInsertTransactionDtoTypeEnum;
    /**
     * 
     * @type {DataForInsertTransactionDtoProvider}
     * @memberof DataForInsertTransactionDto
     */
    provider: DataForInsertTransactionDtoProvider;
    /**
     * 
     * @type {string}
     * @memberof DataForInsertTransactionDto
     */
    invoiceNo?: string;
    /**
     * 
     * @type {DataForInsertTransactionDtoDateRange}
     * @memberof DataForInsertTransactionDto
     */
    dateRange: DataForInsertTransactionDtoDateRange;
    /**
     * 
     * @type {string}
     * @memberof DataForInsertTransactionDto
     */
    createdBy: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DataForInsertTransactionDtoTypeEnum {
    Soa = 'soa',
    Invoice = 'invoice'
}

/**
 * 
 * @export
 * @interface DataForInsertTransactionDtoDateRange
 */
export interface DataForInsertTransactionDtoDateRange {
    /**
     * 
     * @type {string}
     * @memberof DataForInsertTransactionDtoDateRange
     */
    end: string;
    /**
     * 
     * @type {string}
     * @memberof DataForInsertTransactionDtoDateRange
     */
    start: string;
}
/**
 * 
 * @export
 * @interface DataForInsertTransactionDtoProvider
 */
export interface DataForInsertTransactionDtoProvider {
    /**
     * 
     * @type {string}
     * @memberof DataForInsertTransactionDtoProvider
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DataForInsertTransactionDtoProvider
     */
    id: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DayEnum {
    Mon = 'Mon',
    Tue = 'Tue',
    Wed = 'Wed',
    Thu = 'Thu',
    Fri = 'Fri',
    Sat = 'Sat',
    Sun = 'Sun'
}

/**
 * 
 * @export
 * @interface DeductSessionPackDto
 */
export interface DeductSessionPackDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof DeductSessionPackDto
     */
    sessionPackAdditionalId?: string;
    /**
     * 
     * @type {string}
     * @memberof DeductSessionPackDto
     */
    sharedSessionPackStudentId: string;
    /**
     * 
     * @type {string}
     * @memberof DeductSessionPackDto
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof DeductSessionPackDto
     */
    studentId: string;
    /**
     * 
     * @type {CheckinOutSourceEnum}
     * @memberof DeductSessionPackDto
     */
    checkinOutSource?: CheckinOutSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof DeductSessionPackDto
     */
    note?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeductSessionPackDto
     */
    ignoreStartDate?: boolean;
}
/**
 * 
 * @export
 * @interface DeleteEPReportRequest
 */
export interface DeleteEPReportRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof DeleteEPReportRequest
     */
    reportId: string;
}
/**
 * 
 * @export
 * @interface DeleteUserEmailAccountRequest
 */
export interface DeleteUserEmailAccountRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof DeleteUserEmailAccountRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUserEmailAccountRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface DiscountCodesCampaignDto
 */
export interface DiscountCodesCampaignDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof DiscountCodesCampaignDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodesCampaignDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodesCampaignDto
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodesCampaignDto
     */
    discountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodesCampaignDto
     */
    discountPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodesCampaignDto
     */
    redemption?: number;
    /**
     * 
     * @type {CampaignTypeStatusEnum}
     * @memberof DiscountCodesCampaignDto
     */
    status?: CampaignTypeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodesCampaignDto
     */
    venueName?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodesCampaignDto
     */
    venueId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DiscountCodesCampaignDto
     */
    coursePeriodIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DiscountCodesCampaignDto
     */
    educationProviderIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DiscountCodesCampaignDto
     */
    courseIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DiscountCodesCampaignDto
     */
    courseInstanceIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountCodesCampaignDto
     */
    isDiscountPercentOption?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodesCampaignDto
     */
    epBears?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodesCampaignDto
     */
    agoraBears?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountCodesCampaignDto
     */
    limitToOneTimePerChild?: boolean;
}
/**
 * 
 * @export
 * @interface DiscountDto
 */
export interface DiscountDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    type: DiscountDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    status?: DiscountDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    inputValue?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    updatedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DiscountDtoTypeEnum {
    Percent = 'percent',
    Amount = 'amount'
}
/**
    * @export
    * @enum {string}
    */
export enum DiscountDtoStatusEnum {
    Active = 'active',
    Archived = 'archived'
}

/**
 * 
 * @export
 * @interface DiscountRequest
 */
export interface DiscountRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof DiscountRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountRequest
     */
    type?: DiscountRequestTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DiscountRequest
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountRequest
     */
    status?: DiscountRequestStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DiscountRequestTypeEnum {
    Percent = 'percent',
    Amount = 'amount'
}
/**
    * @export
    * @enum {string}
    */
export enum DiscountRequestStatusEnum {
    Active = 'active',
    Archived = 'archived'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum DiscountType {
    Percent = 'percent',
    Amount = 'amount'
}

/**
 * 
 * @export
 * @interface DiscountUpsertResponse
 */
export interface DiscountUpsertResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {DiscountDto}
     * @memberof DiscountUpsertResponse
     */
    data: DiscountDto;
}
/**
 * 
 * @export
 * @interface DownloadDBSReportRequest
 */
export interface DownloadDBSReportRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ProviderDto>}
     * @memberof DownloadDBSReportRequest
     */
    providers?: Array<ProviderDto>;
    /**
     * 
     * @type {string}
     * @memberof DownloadDBSReportRequest
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadDBSReportRequest
     */
    endDate: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DynamicFunctionNameEnum {
    SendNotificationEmailSmsCheckinOut = 'sendNotificationEmailSmsCheckinOut',
    AddStudentInCheckoutGroup = 'addStudentInCheckoutGroup',
    SendSlackNotificationError = 'sendSlackNotificationError',
    RemoveStudentFromCheckinGroup = 'removeStudentFromCheckinGroup',
    AddStudentToCheckinGroup = 'addStudentToCheckinGroup'
}

/**
 * 
 * @export
 * @interface EPReportDto
 */
export interface EPReportDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EPReportDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EPReportDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EPReportDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof EPReportDto
     */
    type?: EPReportDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EPReportDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof EPReportDto
     */
    providerName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum EPReportDtoTypeEnum {
    Soa = 'soa',
    Invoice = 'invoice'
}

/**
 * 
 * @export
 * @interface EarlyYearFeature2Dto
 */
export interface EarlyYearFeature2Dto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<FeatureDto>}
     * @memberof EarlyYearFeature2Dto
     */
    featureList?: Array<FeatureDto>;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearFeature2Dto
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface EarlyYearFeatureDto
 */
export interface EarlyYearFeatureDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<FeatureDto>}
     * @memberof EarlyYearFeatureDto
     */
    featureList?: Array<FeatureDto>;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearFeatureDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearFeatureDto
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface EarlyYearFeedbackDto
 */
export interface EarlyYearFeedbackDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EarlyYearFeedbackDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearFeedbackDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearFeedbackDto
     */
    relationship: string;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearFeedbackDto
     */
    avatar: string;
}
/**
 * 
 * @export
 * @interface EarlyYearHelpingChildDto
 */
export interface EarlyYearHelpingChildDto {
    [key: string]: object | any;

    /**
     * 
     * @type {FeatureDto}
     * @memberof EarlyYearHelpingChildDto
     */
    feature?: FeatureDto;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearHelpingChildDto
     */
    imageUrl?: string;
}
/**
 * 
 * @export
 * @interface EarlyYearHeroSectionDto
 */
export interface EarlyYearHeroSectionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EarlyYearHeroSectionDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearHeroSectionDto
     */
    subTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearHeroSectionDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {StrapiHomepageButtonDto}
     * @memberof EarlyYearHeroSectionDto
     */
    primaryButton?: StrapiHomepageButtonDto;
    /**
     * 
     * @type {StrapiHomepageButtonDto}
     * @memberof EarlyYearHeroSectionDto
     */
    ghostButton?: StrapiHomepageButtonDto;
    /**
     * 
     * @type {SEODataDto}
     * @memberof EarlyYearHeroSectionDto
     */
    seo?: SEODataDto;
}
/**
 * 
 * @export
 * @interface EarlyYearPagePlayschoolResponse
 */
export interface EarlyYearPagePlayschoolResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {EarlyYearPagePlayschoolResponseData}
     * @memberof EarlyYearPagePlayschoolResponse
     */
    data: EarlyYearPagePlayschoolResponseData;
}
/**
 * 
 * @export
 * @interface EarlyYearPagePlayschoolResponseData
 */
export interface EarlyYearPagePlayschoolResponseData {
    /**
     * 
     * @type {EarlyYearPagePlayschoolResponseDataSpaceSection}
     * @memberof EarlyYearPagePlayschoolResponseData
     */
    spaceSection: EarlyYearPagePlayschoolResponseDataSpaceSection;
    /**
     * 
     * @type {FeatureBlockDto}
     * @memberof EarlyYearPagePlayschoolResponseData
     */
    transitionSection: FeatureBlockDto;
    /**
     * 
     * @type {EarlyYearPagePlayschoolResponseDataProgrammeSection}
     * @memberof EarlyYearPagePlayschoolResponseData
     */
    programmeSection: EarlyYearPagePlayschoolResponseDataProgrammeSection;
    /**
     * 
     * @type {EarlyYearPagePlayschoolResponseDataIntroduceSection}
     * @memberof EarlyYearPagePlayschoolResponseData
     */
    introduceSection: EarlyYearPagePlayschoolResponseDataIntroduceSection;
    /**
     * 
     * @type {HeroSectionDto}
     * @memberof EarlyYearPagePlayschoolResponseData
     */
    heroSection?: HeroSectionDto;
}
/**
 * 
 * @export
 * @interface EarlyYearPagePlayschoolResponseDataIntroduceSection
 */
export interface EarlyYearPagePlayschoolResponseDataIntroduceSection {
    /**
     * 
     * @type {Array<FeatureDto>}
     * @memberof EarlyYearPagePlayschoolResponseDataIntroduceSection
     */
    features: Array<FeatureDto>;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearPagePlayschoolResponseDataIntroduceSection
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearPagePlayschoolResponseDataIntroduceSection
     */
    title: string;
}
/**
 * 
 * @export
 * @interface EarlyYearPagePlayschoolResponseDataProgrammeSection
 */
export interface EarlyYearPagePlayschoolResponseDataProgrammeSection {
    /**
     * 
     * @type {Array<FeatureBlockDto>}
     * @memberof EarlyYearPagePlayschoolResponseDataProgrammeSection
     */
    programmes: Array<FeatureBlockDto>;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearPagePlayschoolResponseDataProgrammeSection
     */
    title: string;
}
/**
 * 
 * @export
 * @interface EarlyYearPagePlayschoolResponseDataSpaceSection
 */
export interface EarlyYearPagePlayschoolResponseDataSpaceSection {
    /**
     * 
     * @type {Array<EarlyYearPagePlayschoolResponseDataSpaceSectionSpaces>}
     * @memberof EarlyYearPagePlayschoolResponseDataSpaceSection
     */
    spaces: Array<EarlyYearPagePlayschoolResponseDataSpaceSectionSpaces>;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearPagePlayschoolResponseDataSpaceSection
     */
    title: string;
}
/**
 * 
 * @export
 * @interface EarlyYearPagePlayschoolResponseDataSpaceSectionSpaces
 */
export interface EarlyYearPagePlayschoolResponseDataSpaceSectionSpaces {
    /**
     * 
     * @type {FeatureDto}
     * @memberof EarlyYearPagePlayschoolResponseDataSpaceSectionSpaces
     */
    feature: FeatureDto;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearPagePlayschoolResponseDataSpaceSectionSpaces
     */
    imageUrl: string;
}
/**
 * 
 * @export
 * @interface EarlyYearPageResponse
 */
export interface EarlyYearPageResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {EarlyYearPageResponseData}
     * @memberof EarlyYearPageResponse
     */
    data: EarlyYearPageResponseData;
}
/**
 * 
 * @export
 * @interface EarlyYearPageResponseData
 */
export interface EarlyYearPageResponseData {
    /**
     * 
     * @type {Array<EventHostingFAQItemDto>}
     * @memberof EarlyYearPageResponseData
     */
    faqsSection: Array<EventHostingFAQItemDto>;
    /**
     * 
     * @type {EarlyYearFeedbackDto}
     * @memberof EarlyYearPageResponseData
     */
    feedback?: EarlyYearFeedbackDto;
    /**
     * 
     * @type {EarlyYearHelpingChildDto}
     * @memberof EarlyYearPageResponseData
     */
    helpingChild?: EarlyYearHelpingChildDto;
    /**
     * 
     * @type {EarlyYearFeature2Dto}
     * @memberof EarlyYearPageResponseData
     */
    feature2Section?: EarlyYearFeature2Dto;
    /**
     * 
     * @type {EarlyYearSliderDto}
     * @memberof EarlyYearPageResponseData
     */
    slider?: EarlyYearSliderDto;
    /**
     * 
     * @type {EarlyYearFeatureDto}
     * @memberof EarlyYearPageResponseData
     */
    featureSection?: EarlyYearFeatureDto;
    /**
     * 
     * @type {EarlyYearHeroSectionDto}
     * @memberof EarlyYearPageResponseData
     */
    heroSection?: EarlyYearHeroSectionDto;
}
/**
 * 
 * @export
 * @interface EarlyYearSliderDto
 */
export interface EarlyYearSliderDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SliderDto>}
     * @memberof EarlyYearSliderDto
     */
    sliderList?: Array<SliderDto>;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearSliderDto
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface EarlyYearsPricingItemDto
 */
export interface EarlyYearsPricingItemDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EarlyYearsPricingItemDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearsPricingItemDto
     */
    subTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EarlyYearsPricingItemDto
     */
    description?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum EarlyYearsValidDaysPerWeek {
    Mon = 'mon',
    Tue = 'tue',
    Wed = 'wed',
    Thu = 'thu',
    Fri = 'fri'
}

/**
 * 
 * @export
 * @interface EditCourseSessionRequestDto
 */
export interface EditCourseSessionRequestDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EditCourseSessionRequestDto
     */
    courseSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof EditCourseSessionRequestDto
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof EditCourseSessionRequestDto
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof EditCourseSessionRequestDto
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof EditCourseSessionRequestDto
     */
    bookingRoomId?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCourseSessionRequestDto
     */
    roomId?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCourseSessionRequestDto
     */
    date: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditCourseSessionRequestDto
     */
    isRecurring?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditCourseSessionRequestDto
     */
    teacherId: string;
    /**
     * 
     * @type {string}
     * @memberof EditCourseSessionRequestDto
     */
    status?: EditCourseSessionRequestDtoStatusEnum;
    /**
     * 
     * @type {Array<RoomBookingDto>}
     * @memberof EditCourseSessionRequestDto
     */
    extraRooms?: Array<RoomBookingDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditCourseSessionRequestDto
     */
    additionalTeacherIds?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum EditCourseSessionRequestDtoStatusEnum {
    Cancelled = 'cancelled',
    Completed = 'completed'
}

/**
 * 
 * @export
 * @interface EditInvoiceRequest
 */
export interface EditInvoiceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EditInvoiceRequest
     */
    invoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof EditInvoiceRequest
     */
    expiredDate: string;
    /**
     * 
     * @type {Array<InvoiceLineDto>}
     * @memberof EditInvoiceRequest
     */
    invoiceLines: Array<InvoiceLineDto>;
    /**
     * 
     * @type {string}
     * @memberof EditInvoiceRequest
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInvoiceRequest
     */
    revenueRecognitionDate?: string;
    /**
     * 
     * @type {number}
     * @memberof EditInvoiceRequest
     */
    subTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof EditInvoiceRequest
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof EditInvoiceRequest
     */
    totalGst?: number;
    /**
     * 
     * @type {number}
     * @memberof EditInvoiceRequest
     */
    totalDiscount?: number;
    /**
     * 
     * @type {string}
     * @memberof EditInvoiceRequest
     */
    bankDetails?: string;
    /**
     * 
     * @type {Array<BillingOrganisationPaymentMethodEnum>}
     * @memberof EditInvoiceRequest
     */
    paymentMethods?: Array<BillingOrganisationPaymentMethodEnum>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum EducationProviderStatus {
    Active = 'active',
    Inactive = 'inactive',
    Draft = 'draft'
}

/**
 * 
 * @export
 * @interface EmailTemplateAttributes
 */
export interface EmailTemplateAttributes {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EmailTemplateAttributes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateAttributes
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateAttributes
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateAttributes
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailTemplateAttributes
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateAttributes
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateAttributes
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateAttributes
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateAttributes
     */
    entity?: EmailTemplateAttributesEntityEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EmailTemplateAttributesEntityEnum {
    Invoice = 'invoice',
    Subscription = 'subscription'
}

/**
 * 
 * @export
 * @interface EnrolledStudentDto
 */
export interface EnrolledStudentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    studentChineseName?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    studentAvatar?: string;
    /**
     * 
     * @type {string | number}
     * @memberof EnrolledStudentDto
     */
    age?: string | number;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    parentContactNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    enrollFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    paymentId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    paymentCreatedAt?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnrolledStudentDto
     */
    sessionRefundedIds?: Array<string> | null;
    /**
     * 
     * @type {number | string}
     * @memberof EnrolledStudentDto
     */
    discountPrice?: number | string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    hitpayError?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    rescheduleFromCourseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    transactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    enrollmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    invoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    invoiceLineId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnrolledStudentDto
     */
    refundProcessingSessionIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    refundHistoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    campaignHistoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    financialTransactionId?: string;
    /**
     * 
     * @type {number}
     * @memberof EnrolledStudentDto
     */
    financialTransactionAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    courseName?: string;
    /**
     * 
     * @type {number | string}
     * @memberof EnrolledStudentDto
     */
    originalCourseFee?: number | string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    refundDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    refundStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    approvalId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    approvalDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    refundRemarks?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    paymentMethod?: string;
    /**
     * 
     * @type {number | string}
     * @memberof EnrolledStudentDto
     */
    refundAmount?: number | string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    refundMethod?: EnrolledStudentDtoRefundMethodEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnrolledStudentDto
     */
    courseSessionIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    data?: string;
    /**
     * 
     * @type {number}
     * @memberof EnrolledStudentDto
     */
    campaignPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof EnrolledStudentDto
     */
    creditAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EnrolledStudentDto
     */
    allowReschedule?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    reservedId?: string;
    /**
     * 
     * @type {number}
     * @memberof EnrolledStudentDto
     */
    reservedPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    reservedRemark?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EnrolledStudentDto
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnrolledStudentDto
     */
    isSessionPack?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    schoolName?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrolledStudentDto
     */
    gradeLevelName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum EnrolledStudentDtoRefundMethodEnum {
    Hitpay = 'hitpay',
    Manually = 'manually',
    Credit = 'credit'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum EnrollmentStatusType {
    Refunded = 'refunded',
    Available = 'available',
    Rescheduled = 'rescheduled',
    New = 'new',
    Withdrawn = 'withdrawn'
}

/**
 * 
 * @export
 * @interface EnrollmentType
 */
export interface EnrollmentType {
    /**
     * 
     * @type {EnrollmentStatusType}
     * @memberof EnrollmentType
     */
    status?: EnrollmentStatusType;
    /**
     * 
     * @type {string}
     * @memberof EnrollmentType
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface EquipmentDto
 */
export interface EquipmentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EquipmentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDto
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentDto
     */
    showInDefaultList?: boolean;
}
/**
 * 
 * @export
 * @interface EventHostingCommonDto
 */
export interface EventHostingCommonDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EventHostingCommonDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingCommonDto
     */
    description?: string;
    /**
     * 
     * @type {StrapiMediaDto}
     * @memberof EventHostingCommonDto
     */
    image?: StrapiMediaDto;
    /**
     * 
     * @type {string}
     * @memberof EventHostingCommonDto
     */
    icon?: string;
}
/**
 * 
 * @export
 * @interface EventHostingContactDto
 */
export interface EventHostingContactDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EventHostingContactDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingContactDto
     */
    description?: string;
    /**
     * 
     * @type {StrapiMediaDto}
     * @memberof EventHostingContactDto
     */
    image?: StrapiMediaDto;
    /**
     * 
     * @type {string}
     * @memberof EventHostingContactDto
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingContactDto
     */
    hubspotFormID?: string;
}
/**
 * 
 * @export
 * @interface EventHostingFAQItemDto
 */
export interface EventHostingFAQItemDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EventHostingFAQItemDto
     */
    question?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingFAQItemDto
     */
    answer?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingFAQItemDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface EventHostingFeatureSectionDto
 */
export interface EventHostingFeatureSectionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EventHostingFeatureSectionDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingFeatureSectionDto
     */
    description?: string;
    /**
     * 
     * @type {StrapiMediaDto}
     * @memberof EventHostingFeatureSectionDto
     */
    image?: StrapiMediaDto;
    /**
     * 
     * @type {string}
     * @memberof EventHostingFeatureSectionDto
     */
    icon?: string;
    /**
     * 
     * @type {Array<EventHostingCommonDto>}
     * @memberof EventHostingFeatureSectionDto
     */
    featureListItem?: Array<EventHostingCommonDto>;
}
/**
 * 
 * @export
 * @interface EventHostingHeroSectionDto
 */
export interface EventHostingHeroSectionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EventHostingHeroSectionDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingHeroSectionDto
     */
    description?: string;
    /**
     * 
     * @type {StrapiMediaDto}
     * @memberof EventHostingHeroSectionDto
     */
    image?: StrapiMediaDto;
    /**
     * 
     * @type {string}
     * @memberof EventHostingHeroSectionDto
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingHeroSectionDto
     */
    subTitle?: string;
    /**
     * 
     * @type {EventHostingHeroSectionDtoButton}
     * @memberof EventHostingHeroSectionDto
     */
    button?: EventHostingHeroSectionDtoButton;
    /**
     * 
     * @type {EventHostingHeroSectionDtoDownloadBrochureBtn}
     * @memberof EventHostingHeroSectionDto
     */
    downloadBrochureBtn?: EventHostingHeroSectionDtoDownloadBrochureBtn;
    /**
     * 
     * @type {EventHostingHeroSectionDtoBrochure}
     * @memberof EventHostingHeroSectionDto
     */
    brochure?: EventHostingHeroSectionDtoBrochure;
    /**
     * 
     * @type {string}
     * @memberof EventHostingHeroSectionDto
     */
    pageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingHeroSectionDto
     */
    metaDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingHeroSectionDto
     */
    metaKeywords?: string;
}
/**
 * 
 * @export
 * @interface EventHostingHeroSectionDtoBrochure
 */
export interface EventHostingHeroSectionDtoBrochure {
    /**
     * 
     * @type {string}
     * @memberof EventHostingHeroSectionDtoBrochure
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingHeroSectionDtoBrochure
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface EventHostingHeroSectionDtoButton
 */
export interface EventHostingHeroSectionDtoButton {
    /**
     * 
     * @type {string}
     * @memberof EventHostingHeroSectionDtoButton
     */
    backgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingHeroSectionDtoButton
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingHeroSectionDtoButton
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface EventHostingHeroSectionDtoDownloadBrochureBtn
 */
export interface EventHostingHeroSectionDtoDownloadBrochureBtn {
    /**
     * 
     * @type {string}
     * @memberof EventHostingHeroSectionDtoDownloadBrochureBtn
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface EventHostingPackageListItemDto
 */
export interface EventHostingPackageListItemDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EventHostingPackageListItemDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingPackageListItemDto
     */
    description?: string;
    /**
     * 
     * @type {StrapiMediaDto}
     * @memberof EventHostingPackageListItemDto
     */
    image?: StrapiMediaDto;
    /**
     * 
     * @type {string}
     * @memberof EventHostingPackageListItemDto
     */
    icon?: string;
    /**
     * 
     * @type {StrapiMediaDto}
     * @memberof EventHostingPackageListItemDto
     */
    brochure?: StrapiMediaDto;
    /**
     * 
     * @type {EventHostingHeroSectionDtoButton}
     * @memberof EventHostingPackageListItemDto
     */
    downloadBrochureBtn?: EventHostingHeroSectionDtoButton;
    /**
     * 
     * @type {Array<EventHostingCommonDto>}
     * @memberof EventHostingPackageListItemDto
     */
    featureList?: Array<EventHostingCommonDto>;
}
/**
 * 
 * @export
 * @interface EventHostingPackageSectionDto
 */
export interface EventHostingPackageSectionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EventHostingPackageSectionDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingPackageSectionDto
     */
    description?: string;
    /**
     * 
     * @type {StrapiMediaDto}
     * @memberof EventHostingPackageSectionDto
     */
    image?: StrapiMediaDto;
    /**
     * 
     * @type {string}
     * @memberof EventHostingPackageSectionDto
     */
    icon?: string;
    /**
     * 
     * @type {Array<EventHostingPackageListItemDto>}
     * @memberof EventHostingPackageSectionDto
     */
    packageList?: Array<EventHostingPackageListItemDto>;
}
/**
 * 
 * @export
 * @interface EventHostingPageContentResponse
 */
export interface EventHostingPageContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {EventHostingPageContentResponseData}
     * @memberof EventHostingPageContentResponse
     */
    data: EventHostingPageContentResponseData;
}
/**
 * 
 * @export
 * @interface EventHostingPageContentResponseData
 */
export interface EventHostingPageContentResponseData {
    /**
     * 
     * @type {EventHostingContactDto}
     * @memberof EventHostingPageContentResponseData
     */
    contactSection: EventHostingContactDto;
    /**
     * 
     * @type {Array<EventHostingSocialProofDto>}
     * @memberof EventHostingPageContentResponseData
     */
    socialProofsSection: Array<EventHostingSocialProofDto>;
    /**
     * 
     * @type {Array<EventHostingFAQItemDto>}
     * @memberof EventHostingPageContentResponseData
     */
    faqsSection: Array<EventHostingFAQItemDto>;
    /**
     * 
     * @type {EventHostingSpaceSectionDto}
     * @memberof EventHostingPageContentResponseData
     */
    spaceSection: EventHostingSpaceSectionDto;
    /**
     * 
     * @type {EventHostingPackageSectionDto}
     * @memberof EventHostingPageContentResponseData
     */
    packageSection: EventHostingPackageSectionDto;
    /**
     * 
     * @type {EventHostingFeatureSectionDto}
     * @memberof EventHostingPageContentResponseData
     */
    featureSection: EventHostingFeatureSectionDto;
    /**
     * 
     * @type {EventHostingHeroSectionDto}
     * @memberof EventHostingPageContentResponseData
     */
    heroSection: EventHostingHeroSectionDto;
}
/**
 * 
 * @export
 * @interface EventHostingSocialProofDto
 */
export interface EventHostingSocialProofDto {
    [key: string]: object | any;

    /**
     * 
     * @type {StrapiMediaDto}
     * @memberof EventHostingSocialProofDto
     */
    avatar?: StrapiMediaDto;
    /**
     * 
     * @type {string}
     * @memberof EventHostingSocialProofDto
     */
    clientName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingSocialProofDto
     */
    clientRelationship?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingSocialProofDto
     */
    quote?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingSocialProofDto
     */
    displayedDate?: string;
}
/**
 * 
 * @export
 * @interface EventHostingSpaceListItemDto
 */
export interface EventHostingSpaceListItemDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EventHostingSpaceListItemDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingSpaceListItemDto
     */
    description?: string;
    /**
     * 
     * @type {StrapiMediaDto}
     * @memberof EventHostingSpaceListItemDto
     */
    image?: StrapiMediaDto;
    /**
     * 
     * @type {string}
     * @memberof EventHostingSpaceListItemDto
     */
    icon?: string;
    /**
     * 
     * @type {number}
     * @memberof EventHostingSpaceListItemDto
     */
    index?: number;
    /**
     * 
     * @type {string}
     * @memberof EventHostingSpaceListItemDto
     */
    capacity?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingSpaceListItemDto
     */
    seatedCapacity?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingSpaceListItemDto
     */
    pricing?: string;
}
/**
 * 
 * @export
 * @interface EventHostingSpaceSectionDto
 */
export interface EventHostingSpaceSectionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EventHostingSpaceSectionDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHostingSpaceSectionDto
     */
    description?: string;
    /**
     * 
     * @type {StrapiMediaDto}
     * @memberof EventHostingSpaceSectionDto
     */
    image?: StrapiMediaDto;
    /**
     * 
     * @type {string}
     * @memberof EventHostingSpaceSectionDto
     */
    icon?: string;
    /**
     * 
     * @type {Array<EventHostingSpaceListItemDto>}
     * @memberof EventHostingSpaceSectionDto
     */
    spaceList?: Array<EventHostingSpaceListItemDto>;
}
/**
 * 
 * @export
 * @interface EventsPageCalendarContent
 */
export interface EventsPageCalendarContent {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EventsPageCalendarContent
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageCalendarContent
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageCalendarContent
     */
    calendarImage: string;
}
/**
 * 
 * @export
 * @interface EventsPageDto
 */
export interface EventsPageDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof EventsPageDto
     */
    heroImage?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageDto
     */
    activitiesImages?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageDto
     */
    activitiesDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageDto
     */
    buttonEventText?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageDto
     */
    activitiesSubTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageDto
     */
    calendarTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageDto
     */
    eventTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageDto
     */
    subEventTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageDto
     */
    publicEventText?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageDto
     */
    publicEventColor?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageDto
     */
    allHourlyPlansText?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageDto
     */
    allHourlyPlansColor?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageDto
     */
    monthlyHourlyPlansOnlyText?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsPageDto
     */
    monthlyHourlyPlansOnlyColor?: string;
    /**
     * 
     * @type {Array<HowItWorksContentDto>}
     * @memberof EventsPageDto
     */
    contents?: Array<HowItWorksContentDto>;
    /**
     * 
     * @type {Array<EventsPageCalendarContent>}
     * @memberof EventsPageDto
     */
    calendarContents?: Array<EventsPageCalendarContent>;
    /**
     * 
     * @type {MetaDto}
     * @memberof EventsPageDto
     */
    meta?: MetaDto;
}
/**
 * 
 * @export
 * @interface EventsPageResponse
 */
export interface EventsPageResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {EventsPageDto}
     * @memberof EventsPageResponse
     */
    data: EventsPageDto;
}
/**
 * 
 * @export
 * @interface ExtendExpirationDateSessionPackDto
 */
export interface ExtendExpirationDateSessionPackDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ExtendExpirationDateSessionPackDto
     */
    sessionPackAdditionalId: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendExpirationDateSessionPackDto
     */
    expirationDate: string;
}
/**
 * 
 * @export
 * @interface FAQCategoriesDto
 */
export interface FAQCategoriesDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof FAQCategoriesDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FAQCategoriesDto
     */
    name: string;
    /**
     * 
     * @type {Array<FAQDto>}
     * @memberof FAQCategoriesDto
     */
    faqs: Array<FAQDto>;
}
/**
 * 
 * @export
 * @interface FAQDto
 */
export interface FAQDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof FAQDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof FAQDto
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof FAQDto
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof FAQDto
     */
    answer: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FAQDto
     */
    showOnPages?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FAQDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQDto
     */
    publishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQDto
     */
    category?: string;
    /**
     * 
     * @type {number}
     * @memberof FAQDto
     */
    categoryOrder?: number;
}
/**
 * 
 * @export
 * @interface FeatureBlockDto
 */
export interface FeatureBlockDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof FeatureBlockDto
     */
    heading: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureBlockDto
     */
    description?: string;
    /**
     * 
     * @type {Array<FeatureDto>}
     * @memberof FeatureBlockDto
     */
    features: Array<FeatureDto>;
    /**
     * 
     * @type {string}
     * @memberof FeatureBlockDto
     */
    imageUrl?: string;
}
/**
 * 
 * @export
 * @interface FeatureDto
 */
export interface FeatureDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof FeatureDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureDto
     */
    imageUrl?: string;
}
/**
 * 
 * @export
 * @interface FindSubscriptionPaymentCardResponse
 */
export interface FindSubscriptionPaymentCardResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {UserCardDto}
     * @memberof FindSubscriptionPaymentCardResponse
     */
    data: UserCardDto | null;
}
/**
 * 
 * @export
 * @interface FooterContentDto
 */
export interface FooterContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof FooterContentDto
     */
    logo: string;
    /**
     * 
     * @type {Array<FooterIconDto>}
     * @memberof FooterContentDto
     */
    socialIcons: Array<FooterIconDto>;
    /**
     * 
     * @type {Array<FooterFeatureDto>}
     * @memberof FooterContentDto
     */
    featuresList: Array<FooterFeatureDto>;
    /**
     * 
     * @type {CompanyDto}
     * @memberof FooterContentDto
     */
    venueInfo?: CompanyDto;
}
/**
 * 
 * @export
 * @interface FooterContentResponse
 */
export interface FooterContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {FooterContentDto}
     * @memberof FooterContentResponse
     */
    data: FooterContentDto;
}
/**
 * 
 * @export
 * @interface FooterFeatureDto
 */
export interface FooterFeatureDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof FooterFeatureDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FooterFeatureDto
     */
    name: string;
    /**
     * 
     * @type {Array<FooterFeatureDtoRouters>}
     * @memberof FooterFeatureDto
     */
    routers: Array<FooterFeatureDtoRouters>;
}
/**
 * 
 * @export
 * @interface FooterFeatureDtoRouters
 */
export interface FooterFeatureDtoRouters {
    /**
     * 
     * @type {string}
     * @memberof FooterFeatureDtoRouters
     */
    newPath?: string;
    /**
     * 
     * @type {string}
     * @memberof FooterFeatureDtoRouters
     */
    newName?: string;
    /**
     * 
     * @type {string}
     * @memberof FooterFeatureDtoRouters
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof FooterFeatureDtoRouters
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface FooterIconDto
 */
export interface FooterIconDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof FooterIconDto
     */
    order: string;
    /**
     * 
     * @type {string}
     * @memberof FooterIconDto
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof FooterIconDto
     */
    image: string;
}
/**
 * 
 * @export
 * @interface ForgotPasswordRequest
 */
export interface ForgotPasswordRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    platform: ForgotPasswordRequestPlatformEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ForgotPasswordRequestPlatformEnum {
    AdminPortal = 'admin_portal',
    Agora = 'agora',
    AtSchool = 'at_school',
    TeacherApp = 'teacher_app',
    ParentApp = 'parent_app'
}

/**
 * 
 * @export
 * @interface GenerateEPAgreementRequest
 */
export interface GenerateEPAgreementRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequest
     */
    dated: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateEPAgreementRequest
     */
    to: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateEPAgreementRequest
     */
    epAdminName: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequest
     */
    epName: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequest
     */
    epLegalName: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequest
     */
    courseName: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequest
     */
    epCourseCode: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequest
     */
    agoraCourseCode: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequest
     */
    agoraInstanceCode: string;
    /**
     * 
     * @type {Array<GenerateEPAgreementRequestCourseSessions>}
     * @memberof GenerateEPAgreementRequest
     */
    courseSessions: Array<GenerateEPAgreementRequestCourseSessions>;
    /**
     * 
     * @type {string | number}
     * @memberof GenerateEPAgreementRequest
     */
    capacity: string | number;
    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequest
     */
    bookedBy: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequest
     */
    venueLegalName: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequest
     */
    bookingDate: string;
    /**
     * 
     * @type {GenerateEPAgreementRequestContact}
     * @memberof GenerateEPAgreementRequest
     */
    contact: GenerateEPAgreementRequestContact;
    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequest
     */
    companyLogo: string;
}
/**
 * 
 * @export
 * @interface GenerateEPAgreementRequestContact
 */
export interface GenerateEPAgreementRequestContact {
    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequestContact
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequestContact
     */
    email: string;
}
/**
 * 
 * @export
 * @interface GenerateEPAgreementRequestCourseSessions
 */
export interface GenerateEPAgreementRequestCourseSessions {
    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequestCourseSessions
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateEPAgreementRequestCourseSessions
     */
    startTime: string;
}
/**
 * 
 * @export
 * @interface GenerateInvoicePdfResponse
 */
export interface GenerateInvoicePdfResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {InvoicePdfDto}
     * @memberof GenerateInvoicePdfResponse
     */
    data: InvoicePdfDto;
}
/**
 * 
 * @export
 * @interface GeneratePDFRequest
 */
export interface GeneratePDFRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GeneratePDFRequest
     */
    providerId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneratePDFRequest
     */
    providerIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GeneratePDFRequest
     */
    issueDate: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePDFRequest
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePDFRequest
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePDFRequest
     */
    status?: GeneratePDFRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GeneratePDFRequest
     */
    invoiceNo?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePDFRequest
     */
    reportId?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePDFRequest
     */
    epName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GeneratePDFRequestStatusEnum {
    Open = 'open',
    Refunded = 'refunded'
}

/**
 * 
 * @export
 * @interface GeneratePDFResponse
 */
export interface GeneratePDFResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GeneratePDFResponse
     */
    base64: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePDFResponse
     */
    fileName: string;
}
/**
 * 
 * @export
 * @interface GeneratePdfDto
 */
export interface GeneratePdfDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GeneratePdfDto
     */
    reportId?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePdfDto
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePdfDto
     */
    pdfFile: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePdfDto
     */
    pdfUrl: string;
}
/**
 * 
 * @export
 * @interface GenerateSingleReportRequest
 */
export interface GenerateSingleReportRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {DataForInsertTransactionDto}
     * @memberof GenerateSingleReportRequest
     */
    data: DataForInsertTransactionDto;
    /**
     * 
     * @type {string}
     * @memberof GenerateSingleReportRequest
     */
    type: GenerateSingleReportRequestTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GenerateSingleReportRequestTypeEnum {
    Soa = 'soa',
    Invoice = 'invoice'
}

/**
 * 
 * @export
 * @interface GenerateSingleReportResponse
 */
export interface GenerateSingleReportResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GeneratePdfDto}
     * @memberof GenerateSingleReportResponse
     */
    data: GeneratePdfDto;
}
/**
 * 
 * @export
 * @interface GetAboutPageContentResponse
 */
export interface GetAboutPageContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AboutPageContentDto}
     * @memberof GetAboutPageContentResponse
     */
    data: AboutPageContentDto;
}
/**
 * 
 * @export
 * @interface GetAcceptedTermsResponse
 */
export interface GetAcceptedTermsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<TermsAcceptedDto>}
     * @memberof GetAcceptedTermsResponse
     */
    data: Array<TermsAcceptedDto>;
}
/**
 * 
 * @export
 * @interface GetAdminByProviderOptionResponse
 */
export interface GetAdminByProviderOptionResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SelectOptionDto>}
     * @memberof GetAdminByProviderOptionResponse
     */
    data: Array<SelectOptionDto>;
}
/**
 * 
 * @export
 * @interface GetAgeGroupResponse
 */
export interface GetAgeGroupResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetAgeGroupResponseData}
     * @memberof GetAgeGroupResponse
     */
    data: GetAgeGroupResponseData;
}
/**
 * 
 * @export
 * @interface GetAgeGroupResponseData
 */
export interface GetAgeGroupResponseData {
    /**
     * 
     * @type {Array<AgeGroupDto>}
     * @memberof GetAgeGroupResponseData
     */
    ageGroup: Array<AgeGroupDto>;
}
/**
 * 
 * @export
 * @interface GetAgoraAccessHeaderResponse
 */
export interface GetAgoraAccessHeaderResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AgoraAccessHeaderDto}
     * @memberof GetAgoraAccessHeaderResponse
     */
    data?: AgoraAccessHeaderDto;
}
/**
 * 
 * @export
 * @interface GetAgoraCareHoursResponse
 */
export interface GetAgoraCareHoursResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AgoraCareHoursDto}
     * @memberof GetAgoraCareHoursResponse
     */
    data?: AgoraCareHoursDto;
}
/**
 * 
 * @export
 * @interface GetAgoraCarePacksResponse
 */
export interface GetAgoraCarePacksResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AgoraCareDto>}
     * @memberof GetAgoraCarePacksResponse
     */
    data?: Array<AgoraCareDto>;
}
/**
 * 
 * @export
 * @interface GetAgoraCareSubscriptionResponse
 */
export interface GetAgoraCareSubscriptionResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AgoraSubscriptionDto>}
     * @memberof GetAgoraCareSubscriptionResponse
     */
    data?: Array<AgoraSubscriptionDto>;
}
/**
 * 
 * @export
 * @interface GetAgoraPlanResponse
 */
export interface GetAgoraPlanResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetAgoraPlanResponseData}
     * @memberof GetAgoraPlanResponse
     */
    data: GetAgoraPlanResponseData;
}
/**
 * 
 * @export
 * @interface GetAgoraPlanResponseData
 */
export interface GetAgoraPlanResponseData {
    /**
     * 
     * @type {Array<AgoraPlanFeatureDto>}
     * @memberof GetAgoraPlanResponseData
     */
    features: Array<AgoraPlanFeatureDto>;
    /**
     * 
     * @type {Array<AgoraPlanDto>}
     * @memberof GetAgoraPlanResponseData
     */
    agoraPlans: Array<AgoraPlanDto>;
}
/**
 * 
 * @export
 * @interface GetAgoraSubscriptionHeaderResponse
 */
export interface GetAgoraSubscriptionHeaderResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AgoraSubscriptionHeaderDto}
     * @memberof GetAgoraSubscriptionHeaderResponse
     */
    data?: AgoraSubscriptionHeaderDto;
}
/**
 * 
 * @export
 * @interface GetAgoraTopContentResponse
 */
export interface GetAgoraTopContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AgoraTopContentDto}
     * @memberof GetAgoraTopContentResponse
     */
    data: AgoraTopContentDto;
}
/**
 * 
 * @export
 * @interface GetAllTicketsResponse
 */
export interface GetAllTicketsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ITicket>}
     * @memberof GetAllTicketsResponse
     */
    data: Array<ITicket>;
}
/**
 * 
 * @export
 * @interface GetAtSchoolCourseFilterDataDto
 */
export interface GetAtSchoolCourseFilterDataDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CoursePeriodDto>}
     * @memberof GetAtSchoolCourseFilterDataDto
     */
    coursePeriods: Array<CoursePeriodDto>;
    /**
     * 
     * @type {Array<AgeGroupDto>}
     * @memberof GetAtSchoolCourseFilterDataDto
     */
    gradeLevels: Array<AgeGroupDto>;
}
/**
 * 
 * @export
 * @interface GetAtSchoolCourseFilterDataResponse
 */
export interface GetAtSchoolCourseFilterDataResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetAtSchoolCourseFilterDataDto}
     * @memberof GetAtSchoolCourseFilterDataResponse
     */
    data: GetAtSchoolCourseFilterDataDto;
}
/**
 * 
 * @export
 * @interface GetAtSchoolHeaderResponse
 */
export interface GetAtSchoolHeaderResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AtSchoolHeaderDto>}
     * @memberof GetAtSchoolHeaderResponse
     */
    data: Array<AtSchoolHeaderDto>;
}
/**
 * 
 * @export
 * @interface GetAtSchoolIndexPageResponse
 */
export interface GetAtSchoolIndexPageResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetAtSchoolIndexPageResponseData}
     * @memberof GetAtSchoolIndexPageResponse
     */
    data: GetAtSchoolIndexPageResponseData;
}
/**
 * 
 * @export
 * @interface GetAtSchoolIndexPageResponseData
 */
export interface GetAtSchoolIndexPageResponseData {
    /**
     * 
     * @type {GetAtSchoolIndexPageResponseDataBody}
     * @memberof GetAtSchoolIndexPageResponseData
     */
    body: GetAtSchoolIndexPageResponseDataBody;
    /**
     * 
     * @type {GetAtSchoolIndexPageResponseDataHero}
     * @memberof GetAtSchoolIndexPageResponseData
     */
    hero: GetAtSchoolIndexPageResponseDataHero;
}
/**
 * 
 * @export
 * @interface GetAtSchoolIndexPageResponseDataBody
 */
export interface GetAtSchoolIndexPageResponseDataBody {
    /**
     * 
     * @type {string}
     * @memberof GetAtSchoolIndexPageResponseDataBody
     */
    formButtonContent: string;
    /**
     * 
     * @type {string}
     * @memberof GetAtSchoolIndexPageResponseDataBody
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetAtSchoolIndexPageResponseDataBody
     */
    title: string;
}
/**
 * 
 * @export
 * @interface GetAtSchoolIndexPageResponseDataHero
 */
export interface GetAtSchoolIndexPageResponseDataHero {
    /**
     * 
     * @type {string}
     * @memberof GetAtSchoolIndexPageResponseDataHero
     */
    subTitle: string;
    /**
     * 
     * @type {string}
     * @memberof GetAtSchoolIndexPageResponseDataHero
     */
    title: string;
}
/**
 * 
 * @export
 * @interface GetAttendanceHistoryResponse
 */
export interface GetAttendanceHistoryResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<AttendanceHistoryDto>}
     * @memberof GetAttendanceHistoryResponse
     */
    data: Array<AttendanceHistoryDto>;
}
/**
 * 
 * @export
 * @interface GetAttendancesTeacherResponse
 */
export interface GetAttendancesTeacherResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof GetAttendancesTeacherResponse
     */
    sessionNo: number;
    /**
     * 
     * @type {Array<AttendanceTeacherDto>}
     * @memberof GetAttendancesTeacherResponse
     */
    attendances: Array<AttendanceTeacherDto>;
}
/**
 * 
 * @export
 * @interface GetAvailableRoomsBySessionsRequest
 */
export interface GetAvailableRoomsBySessionsRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetAvailableRoomsBySessionsRequest
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof GetAvailableRoomsBySessionsRequest
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof GetAvailableRoomsBySessionsRequest
     */
    dates: string;
    /**
     * 
     * @type {number}
     * @memberof GetAvailableRoomsBySessionsRequest
     */
    numberOfSeat: number;
    /**
     * 
     * @type {string}
     * @memberof GetAvailableRoomsBySessionsRequest
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAvailableRoomsBySessionsRequest
     */
    roomTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAvailableRoomsBySessionsRequest
     */
    keepRoomIdsInList?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAvailableRoomsBySessionsRequest
     */
    exceptRoomBookingIds?: string;
}
/**
 * 
 * @export
 * @interface GetBalanceCreditAmountResponse
 */
export interface GetBalanceCreditAmountResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetBalanceCreditAmountResponseData}
     * @memberof GetBalanceCreditAmountResponse
     */
    data: GetBalanceCreditAmountResponseData;
}
/**
 * 
 * @export
 * @interface GetBalanceCreditAmountResponseData
 */
export interface GetBalanceCreditAmountResponseData {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetBalanceCreditAmountResponseData
     */
    expireDateList?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetBalanceCreditAmountResponseData
     */
    expiredDate?: string;
    /**
     * 
     * @type {number}
     * @memberof GetBalanceCreditAmountResponseData
     */
    balanceCredit: number;
}
/**
 * 
 * @export
 * @interface GetBillingOrganisationByIdResponse
 */
export interface GetBillingOrganisationByIdResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {IBillingOrganisation}
     * @memberof GetBillingOrganisationByIdResponse
     */
    data: IBillingOrganisation;
}
/**
 * 
 * @export
 * @interface GetBillingOrganisationsResponse
 */
export interface GetBillingOrganisationsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<IBillingOrganisation>}
     * @memberof GetBillingOrganisationsResponse
     */
    data: Array<IBillingOrganisation>;
}
/**
 * 
 * @export
 * @interface GetBlogNewContentResponse
 */
export interface GetBlogNewContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {BlogNewPageContentDto}
     * @memberof GetBlogNewContentResponse
     */
    data: BlogNewPageContentDto;
}
/**
 * 
 * @export
 * @interface GetBlogNewDetailContentResponse
 */
export interface GetBlogNewDetailContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {BlogNewPageDetailContentDto}
     * @memberof GetBlogNewDetailContentResponse
     */
    data: BlogNewPageDetailContentDto;
}
/**
 * 
 * @export
 * @interface GetBookedCoursesForCreateTicketResponse
 */
export interface GetBookedCoursesForCreateTicketResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseDto>}
     * @memberof GetBookedCoursesForCreateTicketResponse
     */
    data: Array<CourseDto>;
}
/**
 * 
 * @export
 * @interface GetBookingResponse
 */
export interface GetBookingResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetBookingResponseData}
     * @memberof GetBookingResponse
     */
    data?: GetBookingResponseData;
}
/**
 * 
 * @export
 * @interface GetBookingResponseData
 */
export interface GetBookingResponseData {
    /**
     * 
     * @type {CourseFilterDto}
     * @memberof GetBookingResponseData
     */
    courseInstance?: CourseFilterDto;
    /**
     * 
     * @type {ChildrenDto}
     * @memberof GetBookingResponseData
     */
    children?: ChildrenDto;
}
/**
 * 
 * @export
 * @interface GetBulkEnrolDuplicateStudentsResponseDto
 */
export interface GetBulkEnrolDuplicateStudentsResponseDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetBulkEnrolDuplicateStudentsResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetBulkEnrolDuplicateStudentsResponseDto
     */
    existingStudentName: string;
    /**
     * 
     * @type {number}
     * @memberof GetBulkEnrolDuplicateStudentsResponseDto
     */
    numberOfExistingStudentByName: number;
    /**
     * 
     * @type {string}
     * @memberof GetBulkEnrolDuplicateStudentsResponseDto
     */
    newStudentName: string;
    /**
     * 
     * @type {CourseInstanceDetailDto}
     * @memberof GetBulkEnrolDuplicateStudentsResponseDto
     */
    lastCourseTaken: CourseInstanceDetailDto;
}
/**
 * 
 * @export
 * @interface GetCampaignPageContentResponse
 */
export interface GetCampaignPageContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CampaignPageContentDto}
     * @memberof GetCampaignPageContentResponse
     */
    data: CampaignPageContentDto;
}
/**
 * 
 * @export
 * @interface GetCampaignResponse
 */
export interface GetCampaignResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CampaignDto}
     * @memberof GetCampaignResponse
     */
    data: CampaignDto;
}
/**
 * 
 * @export
 * @interface GetCampaignShowcasePanelResponse
 */
export interface GetCampaignShowcasePanelResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CampaignShowcaseDto}
     * @memberof GetCampaignShowcasePanelResponse
     */
    data: CampaignShowcaseDto;
}
/**
 * 
 * @export
 * @interface GetCampaignsResponse
 */
export interface GetCampaignsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CampaignDto>}
     * @memberof GetCampaignsResponse
     */
    data: Array<CampaignDto>;
}
/**
 * 
 * @export
 * @interface GetCheckTeacherHasSessionAvailableResponse
 */
export interface GetCheckTeacherHasSessionAvailableResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetCheckTeacherHasSessionAvailableResponse
     */
    count: string;
}
/**
 * 
 * @export
 * @interface GetCheckinAbleSessionsForStudentAndDateWithSessionPackResponse
 */
export interface GetCheckinAbleSessionsForStudentAndDateWithSessionPackResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SessionsAbleCheckinWithSessionPackDto>}
     * @memberof GetCheckinAbleSessionsForStudentAndDateWithSessionPackResponse
     */
    data: Array<SessionsAbleCheckinWithSessionPackDto>;
}
/**
 * 
 * @export
 * @interface GetCheckinLogListBeforeUncheckinResponse
 */
export interface GetCheckinLogListBeforeUncheckinResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CheckinLogDto>}
     * @memberof GetCheckinLogListBeforeUncheckinResponse
     */
    data: Array<CheckinLogDto>;
}
/**
 * 
 * @export
 * @interface GetChildCoursesRequest
 */
export interface GetChildCoursesRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetChildCoursesRequest
     */
    childId: string;
}
/**
 * 
 * @export
 * @interface GetChildCoursesResponse
 */
export interface GetChildCoursesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetChildCoursesResponseData}
     * @memberof GetChildCoursesResponse
     */
    data: GetChildCoursesResponseData;
}
/**
 * 
 * @export
 * @interface GetChildCoursesResponseData
 */
export interface GetChildCoursesResponseData {
    /**
     * 
     * @type {Array<CourseFilterDto>}
     * @memberof GetChildCoursesResponseData
     */
    pastCourses: Array<CourseFilterDto>;
    /**
     * 
     * @type {Array<CourseFilterDto>}
     * @memberof GetChildCoursesResponseData
     */
    currentCourses: Array<CourseFilterDto>;
    /**
     * 
     * @type {number}
     * @memberof GetChildCoursesResponseData
     */
    totalCourses: number;
}
/**
 * 
 * @export
 * @interface GetChildrenInfoResponse
 */
export interface GetChildrenInfoResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetChildrenInfoResponseData}
     * @memberof GetChildrenInfoResponse
     */
    data: GetChildrenInfoResponseData;
}
/**
 * 
 * @export
 * @interface GetChildrenInfoResponseData
 */
export interface GetChildrenInfoResponseData {
    /**
     * 
     * @type {ChildrenDto}
     * @memberof GetChildrenInfoResponseData
     */
    children: ChildrenDto;
}
/**
 * 
 * @export
 * @interface GetChildrenListForRegistrationResponse
 */
export interface GetChildrenListForRegistrationResponse {
    /**
     * 
     * @type {Array<ChildDto>}
     * @memberof GetChildrenListForRegistrationResponse
     */
    data: Array<ChildDto>;
}
/**
 * 
 * @export
 * @interface GetChineseAboutUsPageResponse
 */
export interface GetChineseAboutUsPageResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {ChineseAboutUsDto}
     * @memberof GetChineseAboutUsPageResponse
     */
    data: ChineseAboutUsDto;
}
/**
 * 
 * @export
 * @interface GetCompanyResponse
 */
export interface GetCompanyResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CompanyDto}
     * @memberof GetCompanyResponse
     */
    data?: CompanyDto;
}
/**
 * 
 * @export
 * @interface GetConfirmationPaymentTermsResponse
 */
export interface GetConfirmationPaymentTermsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ConfirmationPaymentTermsDto>}
     * @memberof GetConfirmationPaymentTermsResponse
     */
    data: Array<ConfirmationPaymentTermsDto>;
}
/**
 * 
 * @export
 * @interface GetContactableTeacherByStudentIdResponseDto
 */
export interface GetContactableTeacherByStudentIdResponseDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<TeacherDto>}
     * @memberof GetContactableTeacherByStudentIdResponseDto
     */
    data: Array<TeacherDto>;
}
/**
 * 
 * @export
 * @interface GetContextSensitiveHelpResponse
 */
export interface GetContextSensitiveHelpResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ContextSensitiveHelpDto>}
     * @memberof GetContextSensitiveHelpResponse
     */
    data: Array<ContextSensitiveHelpDto>;
}
/**
 * 
 * @export
 * @interface GetConversationDetailResponse
 */
export interface GetConversationDetailResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof GetConversationDetailResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<MessageDto>}
     * @memberof GetConversationDetailResponse
     */
    data: Array<MessageDto>;
}
/**
 * 
 * @export
 * @interface GetConversationListResponse
 */
export interface GetConversationListResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<MessageDto>}
     * @memberof GetConversationListResponse
     */
    data: Array<MessageDto>;
}
/**
 * 
 * @export
 * @interface GetCourseAttendanceOptionsByIdResponseDto
 */
export interface GetCourseAttendanceOptionsByIdResponseDto {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseDto}
     * @memberof GetCourseAttendanceOptionsByIdResponseDto
     */
    data: CourseDto | null;
}
/**
 * 
 * @export
 * @interface GetCourseByChildResponse
 */
export interface GetCourseByChildResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CoursesByChildDto>}
     * @memberof GetCourseByChildResponse
     */
    data?: Array<CoursesByChildDto>;
}
/**
 * 
 * @export
 * @interface GetCourseCalendarResponse
 */
export interface GetCourseCalendarResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetCourseCalendarResponseData}
     * @memberof GetCourseCalendarResponse
     */
    data: GetCourseCalendarResponseData;
}
/**
 * 
 * @export
 * @interface GetCourseCalendarResponseData
 */
export interface GetCourseCalendarResponseData {
    /**
     * 
     * @type {Array<CourseCalendarDto>}
     * @memberof GetCourseCalendarResponseData
     */
    sessions: Array<CourseCalendarDto>;
}
/**
 * 
 * @export
 * @interface GetCourseCheckoutOptionsByIdResponseDto
 */
export interface GetCourseCheckoutOptionsByIdResponseDto {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseDto}
     * @memberof GetCourseCheckoutOptionsByIdResponseDto
     */
    data: CourseDto | null;
}
/**
 * 
 * @export
 * @interface GetCourseDetailRequest
 */
export interface GetCourseDetailRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetCourseDetailRequest
     */
    requestFromUrl: string;
    /**
     * 
     * @type {string}
     * @memberof GetCourseDetailRequest
     */
    courseSlug: string;
    /**
     * 
     * @type {string}
     * @memberof GetCourseDetailRequest
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCourseDetailRequest
     */
    courseInstanceReferenceId?: string;
}
/**
 * 
 * @export
 * @interface GetCourseDetailResponse
 */
export interface GetCourseDetailResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseDto}
     * @memberof GetCourseDetailResponse
     */
    data: CourseDto;
}
/**
 * 
 * @export
 * @interface GetCourseDetailsTeacherResponse
 */
export interface GetCourseDetailsTeacherResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetCourseDetailsTeacherResponse
     */
    description: string;
    /**
     * 
     * @type {Array<CourseSessionTeacherDto>}
     * @memberof GetCourseDetailsTeacherResponse
     */
    sessions: Array<CourseSessionTeacherDto>;
}
/**
 * 
 * @export
 * @interface GetCourseEventsHomepageResponse
 */
export interface GetCourseEventsHomepageResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseEventsHomepageDto}
     * @memberof GetCourseEventsHomepageResponse
     */
    data: CourseEventsHomepageDto;
}
/**
 * 
 * @export
 * @interface GetCourseHeroDataResponse
 */
export interface GetCourseHeroDataResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseHeroDataDto}
     * @memberof GetCourseHeroDataResponse
     */
    data: CourseHeroDataDto;
}
/**
 * 
 * @export
 * @interface GetCourseInstanceCancellationReasonResponse
 */
export interface GetCourseInstanceCancellationReasonResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseInstanceCancellationReasonDto>}
     * @memberof GetCourseInstanceCancellationReasonResponse
     */
    data: Array<CourseInstanceCancellationReasonDto>;
}
/**
 * 
 * @export
 * @interface GetCourseInstanceDetailResponse
 */
export interface GetCourseInstanceDetailResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseInstanceDto}
     * @memberof GetCourseInstanceDetailResponse
     */
    data: CourseInstanceDto | null;
}
/**
 * 
 * @export
 * @interface GetCourseInstancePaymentResponse
 */
export interface GetCourseInstancePaymentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseInstanceForPaymentDto}
     * @memberof GetCourseInstancePaymentResponse
     */
    data: CourseInstanceForPaymentDto | null;
}
/**
 * 
 * @export
 * @interface GetCourseInstancesOfBulkEnrolStudentsResponse
 */
export interface GetCourseInstancesOfBulkEnrolStudentsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<BulkEnrolCoursesDto>}
     * @memberof GetCourseInstancesOfBulkEnrolStudentsResponse
     */
    data: Array<BulkEnrolCoursesDto>;
}
/**
 * 
 * @export
 * @interface GetCourseInstancesResponse
 */
export interface GetCourseInstancesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetCourseInstancesResponseData}
     * @memberof GetCourseInstancesResponse
     */
    data: GetCourseInstancesResponseData;
}
/**
 * 
 * @export
 * @interface GetCourseInstancesResponseData
 */
export interface GetCourseInstancesResponseData {
    /**
     * 
     * @type {Array<CourseInstanceDto>}
     * @memberof GetCourseInstancesResponseData
     */
    instances: Array<CourseInstanceDto>;
    /**
     * 
     * @type {number}
     * @memberof GetCourseInstancesResponseData
     */
    numOfInstanceMissingTeacher?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCourseInstancesResponseData
     */
    totalCount: number;
}
/**
 * 
 * @export
 * @interface GetCoursePageContentResponse
 */
export interface GetCoursePageContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CoursePageContentDto}
     * @memberof GetCoursePageContentResponse
     */
    data: CoursePageContentDto;
}
/**
 * 
 * @export
 * @interface GetCoursePolicyResponse
 */
export interface GetCoursePolicyResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CoursePolicyDto}
     * @memberof GetCoursePolicyResponse
     */
    data: CoursePolicyDto;
}
/**
 * 
 * @export
 * @interface GetCourseRelatedRequest
 */
export interface GetCourseRelatedRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetCourseRelatedRequest
     */
    requestFromUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCourseRelatedRequest
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCourseRelatedRequest
     */
    courseSlug: string;
    /**
     * 
     * @type {string}
     * @memberof GetCourseRelatedRequest
     */
    ageGroupId?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCourseRelatedRequest
     */
    maxAge?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCourseRelatedRequest
     */
    minAge?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCourseRelatedRequest
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCourseRelatedRequest
     */
    timezone?: string;
}
/**
 * 
 * @export
 * @interface GetCourseResponse
 */
export interface GetCourseResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseDetailDto}
     * @memberof GetCourseResponse
     */
    data: CourseDetailDto | null;
}
/**
 * 
 * @export
 * @interface GetCourseSessionDetailsResponse
 */
export interface GetCourseSessionDetailsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseSessionTeacherDto}
     * @memberof GetCourseSessionDetailsResponse
     */
    data: CourseSessionTeacherDto;
}
/**
 * 
 * @export
 * @interface GetCourseSessionRequestDto
 */
export interface GetCourseSessionRequestDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetCourseSessionRequestDto
     */
    date: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseSessionRequestDto
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseSessionRequestDto
     */
    businessUnitIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseSessionRequestDto
     */
    courseIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetCourseWithEnhancedRequest
 */
export interface GetCourseWithEnhancedRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetCourseWithEnhancedRequest
     */
    schoolSlug?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    subjectTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    subjectAreas?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    subjects?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    ages?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    gradeLevels?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    months?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    days?: Array<string>;
    /**
     * 
     * @type {Array<GetCourseWithEnhancedRequestTimes>}
     * @memberof GetCourseWithEnhancedRequest
     */
    times?: Array<GetCourseWithEnhancedRequestTimes>;
    /**
     * 
     * @type {string}
     * @memberof GetCourseWithEnhancedRequest
     */
    timeQuery?: GetCourseWithEnhancedRequestTimeQueryEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    dayQuery?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    monthQuery?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    educationProviders?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    educationProviderSlugs?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    courseStatuses?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    courseFormats?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    coursePeriods?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    courseTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetCourseWithEnhancedRequest
     */
    timezone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetCourseWithEnhancedRequest
     */
    getProviderCourseList?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetCourseWithEnhancedRequest
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCourseWithEnhancedRequest
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCourseWithEnhancedRequest
     */
    searchCoursesString?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCourseWithEnhancedRequest
     */
    trialAvailable?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetCourseWithEnhancedRequest
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCourseWithEnhancedRequest
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCourseWithEnhancedRequest
     */
    userId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetCourseWithEnhancedRequest
     */
    isCheckedBookmark?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetCourseWithEnhancedRequest
     */
    isCheckedSchedule?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetCourseWithEnhancedRequest
     */
    allowTrialSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCourseWithEnhancedRequest
     */
    providerSlug?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetCourseWithEnhancedRequestTimeQueryEnum {
    Am = 'am',
    Pm = 'pm',
    Both = 'both'
}

/**
 * 
 * @export
 * @interface GetCourseWithEnhancedRequestTimes
 */
export interface GetCourseWithEnhancedRequestTimes {
    /**
     * 
     * @type {number}
     * @memberof GetCourseWithEnhancedRequestTimes
     */
    maxTime: number;
    /**
     * 
     * @type {number}
     * @memberof GetCourseWithEnhancedRequestTimes
     */
    minTime: number;
}
/**
 * 
 * @export
 * @interface GetCourseWithEnhancedResponse
 */
export interface GetCourseWithEnhancedResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetCourseWithEnhancedResponseData}
     * @memberof GetCourseWithEnhancedResponse
     */
    data: GetCourseWithEnhancedResponseData;
}
/**
 * 
 * @export
 * @interface GetCourseWithEnhancedResponseData
 */
export interface GetCourseWithEnhancedResponseData {
    /**
     * 
     * @type {Array<CourseFilterDto>}
     * @memberof GetCourseWithEnhancedResponseData
     */
    courses: Array<CourseFilterDto>;
    /**
     * 
     * @type {number}
     * @memberof GetCourseWithEnhancedResponseData
     */
    numOfCourseInstance?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCourseWithEnhancedResponseData
     */
    numOfCourse?: number;
}
/**
 * 
 * @export
 * @interface GetCoursesHeroResponse
 */
export interface GetCoursesHeroResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {SEODataDto}
     * @memberof GetCoursesHeroResponse
     */
    data: SEODataDto;
}
/**
 * 
 * @export
 * @interface GetCoursesRequest
 */
export interface GetCoursesRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetCoursesRequest
     */
    providerReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCoursesRequest
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCoursesRequest
     */
    courseName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCoursesRequest
     */
    providerSlugs?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCoursesRequest
     */
    courseSlugs?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCoursesRequest
     */
    gradeLevels?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCoursesRequest
     */
    statuses?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GetCoursesRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCoursesRequest
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetCoursesRequest
     */
    filterMissingTeacher?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCoursesRequest
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCoursesRequest
     */
    status?: GetCoursesRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetCoursesRequest
     */
    venueId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetCoursesRequestStatusEnum {
    Draft = 'draft',
    Completed = 'completed',
    PendingApproval = 'pending_approval',
    Approved = 'approved',
    CurrentlyUnderway = 'currently_underway',
    FullyBooked = 'fully_booked',
    Inactive = 'inactive',
    Cancelled = 'cancelled'
}

/**
 * 
 * @export
 * @interface GetCoursesResponse
 */
export interface GetCoursesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetCoursesResponseData}
     * @memberof GetCoursesResponse
     */
    data: GetCoursesResponseData;
}
/**
 * 
 * @export
 * @interface GetCoursesResponseData
 */
export interface GetCoursesResponseData {
    /**
     * 
     * @type {Array<CourseDto>}
     * @memberof GetCoursesResponseData
     */
    courses: Array<CourseDto>;
    /**
     * 
     * @type {number}
     * @memberof GetCoursesResponseData
     */
    totalCount: number;
}
/**
 * 
 * @export
 * @interface GetCoursesSupportDataResponse
 */
export interface GetCoursesSupportDataResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetCoursesSupportDataResponseData}
     * @memberof GetCoursesSupportDataResponse
     */
    data?: GetCoursesSupportDataResponseData;
}
/**
 * 
 * @export
 * @interface GetCoursesSupportDataResponseData
 */
export interface GetCoursesSupportDataResponseData {
    /**
     * 
     * @type {Array<AgeFilterDto>}
     * @memberof GetCoursesSupportDataResponseData
     */
    ageFilters?: Array<AgeFilterDto>;
    /**
     * 
     * @type {Array<AgeGroupDto>}
     * @memberof GetCoursesSupportDataResponseData
     */
    ageGroups?: Array<AgeGroupDto>;
    /**
     * 
     * @type {Array<ProviderDto>}
     * @memberof GetCoursesSupportDataResponseData
     */
    providers?: Array<ProviderDto>;
    /**
     * 
     * @type {Array<SubjectDto>}
     * @memberof GetCoursesSupportDataResponseData
     */
    subjects?: Array<SubjectDto>;
}
/**
 * 
 * @export
 * @interface GetCoursesWithResponse
 */
export interface GetCoursesWithResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseFilterDto>}
     * @memberof GetCoursesWithResponse
     */
    data?: Array<CourseFilterDto>;
}
/**
 * 
 * @export
 * @interface GetCurrentUserResponse
 */
export interface GetCurrentUserResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    sub: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    userType?: GetCurrentUserResponseUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    inviteCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetCurrentUserResponse
     */
    enableEpPortalAccess?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    status?: GetCurrentUserResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    roleName?: string;
    /**
     * 
     * @type {Array<GetCurrentUserResponsePermissions>}
     * @memberof GetCurrentUserResponse
     */
    permissions?: Array<GetCurrentUserResponsePermissions>;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    googleId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    postCode?: string;
    /**
     * 
     * @type {EducationProviderStatus}
     * @memberof GetCurrentUserResponse
     */
    educationProviderStatus?: EducationProviderStatus;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponse
     */
    relationshipId?: string;
    /**
     * 
     * @type {RelationshipTypeEnum}
     * @memberof GetCurrentUserResponse
     */
    relationshipType?: RelationshipTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCurrentUserResponse
     */
    assignedVenueIds?: Array<string>;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof GetCurrentUserResponse
     */
    venues?: Array<CompanyDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetCurrentUserResponseUserTypeEnum {
    AgoraAdmin = 'agora_admin',
    Assistant = 'assistant',
    Brother = 'brother',
    BusinessDevelopment = 'business_development',
    Ceo = 'ceo',
    CurriculumDevelopment = 'curriculum_development',
    EpAdmin = 'ep_admin',
    EpAdminTeacher = 'ep_admin_teacher',
    Father = 'father',
    Finance = 'finance',
    Grandfather = 'grandfather',
    Grandmother = 'grandmother',
    Helper = 'helper',
    Mother = 'mother',
    Others = 'others',
    Owner = 'owner',
    Parent = 'parent',
    ProgramManager = 'program_manager',
    Sister = 'sister',
    Student = 'student',
    Teacher = 'teacher'
}
/**
    * @export
    * @enum {string}
    */
export enum GetCurrentUserResponseStatusEnum {
    Active = 'active',
    Inactive = 'inactive'
}

/**
 * 
 * @export
 * @interface GetCurrentUserResponsePermissions
 */
export interface GetCurrentUserResponsePermissions {
    /**
     * 
     * @type {AccessScopeEnum}
     * @memberof GetCurrentUserResponsePermissions
     */
    accessScope: AccessScopeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUserResponsePermissions
     */
    name: string;
}
/**
 * 
 * @export
 * @interface GetDiscountCodesByCampaignIdResponse
 */
export interface GetDiscountCodesByCampaignIdResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {DiscountCodesCampaignDto}
     * @memberof GetDiscountCodesByCampaignIdResponse
     */
    data: DiscountCodesCampaignDto;
}
/**
 * 
 * @export
 * @interface GetDiscountCodesCampaignResponse
 */
export interface GetDiscountCodesCampaignResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<DiscountCodesCampaignDto>}
     * @memberof GetDiscountCodesCampaignResponse
     */
    data: Array<DiscountCodesCampaignDto>;
}
/**
 * 
 * @export
 * @interface GetDiscountListResponse
 */
export interface GetDiscountListResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<DiscountDto>}
     * @memberof GetDiscountListResponse
     */
    data: Array<DiscountDto>;
}
/**
 * 
 * @export
 * @interface GetEPDashboardFiltersResponse
 */
export interface GetEPDashboardFiltersResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetEPDashboardFiltersResponseData}
     * @memberof GetEPDashboardFiltersResponse
     */
    data: GetEPDashboardFiltersResponseData;
}
/**
 * 
 * @export
 * @interface GetEPDashboardFiltersResponseData
 */
export interface GetEPDashboardFiltersResponseData {
    /**
     * 
     * @type {Array<AgeGroupDto>}
     * @memberof GetEPDashboardFiltersResponseData
     */
    gradeLevels: Array<AgeGroupDto>;
    /**
     * 
     * @type {Array<SubjectDto>}
     * @memberof GetEPDashboardFiltersResponseData
     */
    subjects: Array<SubjectDto>;
}
/**
 * 
 * @export
 * @interface GetEPDetailResponse
 */
export interface GetEPDetailResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {ProviderDetailDto}
     * @memberof GetEPDetailResponse
     */
    data: ProviderDetailDto;
}
/**
 * 
 * @export
 * @interface GetEPReportsResponse
 */
export interface GetEPReportsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<EPReportDto>}
     * @memberof GetEPReportsResponse
     */
    data: Array<EPReportDto>;
}
/**
 * 
 * @export
 * @interface GetEarlyYearPricingPageResponse
 */
export interface GetEarlyYearPricingPageResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetEarlyYearPricingPageResponseData}
     * @memberof GetEarlyYearPricingPageResponse
     */
    data: GetEarlyYearPricingPageResponseData;
}
/**
 * 
 * @export
 * @interface GetEarlyYearPricingPageResponseData
 */
export interface GetEarlyYearPricingPageResponseData {
    /**
     * 
     * @type {Array<EarlyYearsPricingItemDto>}
     * @memberof GetEarlyYearPricingPageResponseData
     */
    pricingItems: Array<EarlyYearsPricingItemDto>;
    /**
     * 
     * @type {HeroSectionDto}
     * @memberof GetEarlyYearPricingPageResponseData
     */
    heroSection: HeroSectionDto;
    /**
     * 
     * @type {SEODataDto}
     * @memberof GetEarlyYearPricingPageResponseData
     */
    seo: SEODataDto;
}
/**
 * 
 * @export
 * @interface GetEducationProviderOptionsResponse
 */
export interface GetEducationProviderOptionsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SelectOptionDto>}
     * @memberof GetEducationProviderOptionsResponse
     */
    data: Array<SelectOptionDto>;
}
/**
 * 
 * @export
 * @interface GetEmailTemplateOptionResponse
 */
export interface GetEmailTemplateOptionResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SelectOptionDto>}
     * @memberof GetEmailTemplateOptionResponse
     */
    data: Array<SelectOptionDto>;
}
/**
 * 
 * @export
 * @interface GetEmailTemplateResponse
 */
export interface GetEmailTemplateResponse {
    /**
     * 
     * @type {Array<EmailTemplateAttributes>}
     * @memberof GetEmailTemplateResponse
     */
    data: Array<EmailTemplateAttributes>;
}
/**
 * 
 * @export
 * @interface GetEmailTemplatesByBrandResponse
 */
export interface GetEmailTemplatesByBrandResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<TemplateInList>}
     * @memberof GetEmailTemplatesByBrandResponse
     */
    data: Array<TemplateInList>;
}
/**
 * 
 * @export
 * @interface GetEmailTemplatesDetailsResponse
 */
export interface GetEmailTemplatesDetailsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Template}
     * @memberof GetEmailTemplatesDetailsResponse
     */
    data: Template | null;
}
/**
 * 
 * @export
 * @interface GetEpConversations
 */
export interface GetEpConversations {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<MessageDto>}
     * @memberof GetEpConversations
     */
    data: Array<MessageDto>;
}
/**
 * 
 * @export
 * @interface GetEpsByVenueIdDto
 */
export interface GetEpsByVenueIdDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetEpsByVenueIdDto
     */
    venueEpId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEpsByVenueIdDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEpsByVenueIdDto
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEpsByVenueIdDto
     */
    educationProviderId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEpsByVenueIdDto
     */
    status?: GetEpsByVenueIdDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetEpsByVenueIdDto
     */
    isShareParentContact?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum GetEpsByVenueIdDtoStatusEnum {
    Active = 'active',
    Inactive = 'inactive'
}

/**
 * 
 * @export
 * @interface GetFAQCategoriesResponse
 */
export interface GetFAQCategoriesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<FAQCategoriesDto>}
     * @memberof GetFAQCategoriesResponse
     */
    data: Array<FAQCategoriesDto>;
}
/**
 * 
 * @export
 * @interface GetFAQHeroResponse
 */
export interface GetFAQHeroResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {SEODataDto}
     * @memberof GetFAQHeroResponse
     */
    data: SEODataDto;
}
/**
 * 
 * @export
 * @interface GetFAQResponse
 */
export interface GetFAQResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<FAQDto>}
     * @memberof GetFAQResponse
     */
    data: Array<FAQDto>;
    /**
     * 
     * @type {PaginationDto}
     * @memberof GetFAQResponse
     */
    paginationMeta?: PaginationDto;
}
/**
 * 
 * @export
 * @interface GetGettingThereHeroResponse
 */
export interface GetGettingThereHeroResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {SEODataDto}
     * @memberof GetGettingThereHeroResponse
     */
    data: SEODataDto;
}
/**
 * 
 * @export
 * @interface GetGettingTherePageContentResponse
 */
export interface GetGettingTherePageContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GettingTherePageContentDto}
     * @memberof GetGettingTherePageContentResponse
     */
    data: GettingTherePageContentDto;
}
/**
 * 
 * @export
 * @interface GetHelpDto
 */
export interface GetHelpDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetHelpDto
     */
    content: string;
}
/**
 * 
 * @export
 * @interface GetHelpForEpDto
 */
export interface GetHelpForEpDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetHelpForEpDto
     */
    guidelinesURL: string;
}
/**
 * 
 * @export
 * @interface GetHelpForEpResponse
 */
export interface GetHelpForEpResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetHelpForEpDto}
     * @memberof GetHelpForEpResponse
     */
    data: GetHelpForEpDto;
}
/**
 * 
 * @export
 * @interface GetHelpPageResponse
 */
export interface GetHelpPageResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetHelpDto}
     * @memberof GetHelpPageResponse
     */
    data: GetHelpDto;
}
/**
 * 
 * @export
 * @interface GetHolidaysBySession
 */
export interface GetHolidaysBySession {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof GetHolidaysBySession
     */
    numOfWeek: number;
    /**
     * 
     * @type {Array<DayEnum>}
     * @memberof GetHolidaysBySession
     */
    days: Array<DayEnum>;
    /**
     * 
     * @type {string}
     * @memberof GetHolidaysBySession
     */
    date: string;
}
/**
 * 
 * @export
 * @interface GetHomePageImageResponse
 */
export interface GetHomePageImageResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {HomePageImageDto}
     * @memberof GetHomePageImageResponse
     */
    data: HomePageImageDto;
}
/**
 * 
 * @export
 * @interface GetHomePageWhatWeDoResponse
 */
export interface GetHomePageWhatWeDoResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {HomePageWhatWeDoDto}
     * @memberof GetHomePageWhatWeDoResponse
     */
    data: HomePageWhatWeDoDto;
}
/**
 * 
 * @export
 * @interface GetHomepageContentResponse
 */
export interface GetHomepageContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {HomePageContentDto}
     * @memberof GetHomepageContentResponse
     */
    data: HomePageContentDto;
}
/**
 * 
 * @export
 * @interface GetHostAnEventWithUsPageContentResponse
 */
export interface GetHostAnEventWithUsPageContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {HostAnEventWithUsPageContentDto}
     * @memberof GetHostAnEventWithUsPageContentResponse
     */
    data: HostAnEventWithUsPageContentDto;
}
/**
 * 
 * @export
 * @interface GetInvoiceDraftOrPendingResponse
 */
export interface GetInvoiceDraftOrPendingResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<InvoiceDto>}
     * @memberof GetInvoiceDraftOrPendingResponse
     */
    data: Array<InvoiceDto>;
}
/**
 * 
 * @export
 * @interface GetJoinUsPageContentResponse
 */
export interface GetJoinUsPageContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {JoinUsPageContentDto}
     * @memberof GetJoinUsPageContentResponse
     */
    data: JoinUsPageContentDto;
}
/**
 * 
 * @export
 * @interface GetLandingMessageResponse
 */
export interface GetLandingMessageResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {LandingMessageDto}
     * @memberof GetLandingMessageResponse
     */
    data: LandingMessageDto;
}
/**
 * 
 * @export
 * @interface GetLegalAndPrivacyPolicyResponse
 */
export interface GetLegalAndPrivacyPolicyResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {PrivacyDto}
     * @memberof GetLegalAndPrivacyPolicyResponse
     */
    data: PrivacyDto;
}
/**
 * 
 * @export
 * @interface GetMessageGroupInfoResponse
 */
export interface GetMessageGroupInfoResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {MessageGroupDto}
     * @memberof GetMessageGroupInfoResponse
     */
    data: MessageGroupDto;
}
/**
 * 
 * @export
 * @interface GetMinCheckoutToAppliedResponse
 */
export interface GetMinCheckoutToAppliedResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CampaignDto}
     * @memberof GetMinCheckoutToAppliedResponse
     */
    data: CampaignDto;
}
/**
 * 
 * @export
 * @interface GetMoreDataRoomViewDto
 */
export interface GetMoreDataRoomViewDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetMoreDataRoomViewDto
     */
    roomName: string;
    /**
     * 
     * @type {string}
     * @memberof GetMoreDataRoomViewDto
     */
    subjectName: string;
    /**
     * 
     * @type {string}
     * @memberof GetMoreDataRoomViewDto
     */
    gradeLevel: string;
    /**
     * 
     * @type {string}
     * @memberof GetMoreDataRoomViewDto
     */
    gradeLevelCustom: string;
    /**
     * 
     * @type {string}
     * @memberof GetMoreDataRoomViewDto
     */
    epName: string;
    /**
     * 
     * @type {string}
     * @memberof GetMoreDataRoomViewDto
     */
    teacherName: string;
    /**
     * 
     * @type {string}
     * @memberof GetMoreDataRoomViewDto
     */
    minTimeRecurringSession: string;
    /**
     * 
     * @type {string}
     * @memberof GetMoreDataRoomViewDto
     */
    maxTimeRecurringSession: string;
    /**
     * 
     * @type {string}
     * @memberof GetMoreDataRoomViewDto
     */
    courseName: string;
    /**
     * 
     * @type {string}
     * @memberof GetMoreDataRoomViewDto
     */
    courseInstanceReferenceId: string;
    /**
     * 
     * @type {string}
     * @memberof GetMoreDataRoomViewDto
     */
    roomBookingId: string;
}
/**
 * 
 * @export
 * @interface GetMorningPassPageContentResponse
 */
export interface GetMorningPassPageContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetMorningPassPageContentResponseData}
     * @memberof GetMorningPassPageContentResponse
     */
    data: GetMorningPassPageContentResponseData;
}
/**
 * 
 * @export
 * @interface GetMorningPassPageContentResponseData
 */
export interface GetMorningPassPageContentResponseData {
    /**
     * 
     * @type {Array<MorningPassPageCardDto>}
     * @memberof GetMorningPassPageContentResponseData
     */
    morningPassPageCards?: Array<MorningPassPageCardDto>;
    /**
     * 
     * @type {Array<MorningPassPageContentDto>}
     * @memberof GetMorningPassPageContentResponseData
     */
    morningPassPageContent?: Array<MorningPassPageContentDto>;
    /**
     * 
     * @type {MorningPassPagePopupDto}
     * @memberof GetMorningPassPageContentResponseData
     */
    morningPassPagePopup?: MorningPassPagePopupDto;
    /**
     * 
     * @type {MorningPassPageDecorationDto}
     * @memberof GetMorningPassPageContentResponseData
     */
    morningPassPageDecoration?: MorningPassPageDecorationDto;
}
/**
 * 
 * @export
 * @interface GetNotifyConfigResponse
 */
export interface GetNotifyConfigResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {NotificationConfigDto}
     * @memberof GetNotifyConfigResponse
     */
    data: NotificationConfigDto;
}
/**
 * 
 * @export
 * @interface GetNotifyResponse
 */
export interface GetNotifyResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetNotifyResponseData}
     * @memberof GetNotifyResponse
     */
    data: GetNotifyResponseData;
}
/**
 * 
 * @export
 * @interface GetNotifyResponseData
 */
export interface GetNotifyResponseData {
    /**
     * 
     * @type {number}
     * @memberof GetNotifyResponseData
     */
    unreadNotify: number;
    /**
     * 
     * @type {Array<NotificationDto>}
     * @memberof GetNotifyResponseData
     */
    notifications: Array<NotificationDto>;
}
/**
 * 
 * @export
 * @interface GetParentByStudentIdDto
 */
export interface GetParentByStudentIdDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetParentByStudentIdDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentByStudentIdDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentByStudentIdDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentByStudentIdDto
     */
    parentPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentByStudentIdDto
     */
    studentName?: string;
}
/**
 * 
 * @export
 * @interface GetParentCourseReminderEmailDataWithConditionRequest
 */
export interface GetParentCourseReminderEmailDataWithConditionRequest {
    /**
     * 
     * @type {string}
     * @memberof GetParentCourseReminderEmailDataWithConditionRequest
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentCourseReminderEmailDataWithConditionRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentCourseReminderEmailDataWithConditionRequest
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentCourseReminderEmailDataWithConditionRequest
     */
    startDate: string;
}
/**
 * 
 * @export
 * @interface GetParentListForImpersonationResponseInterface
 */
export interface GetParentListForImpersonationResponseInterface {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ParentDto>}
     * @memberof GetParentListForImpersonationResponseInterface
     */
    data: Array<ParentDto>;
}
/**
 * 
 * @export
 * @interface GetParentListViewDto
 */
export interface GetParentListViewDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetParentListViewDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentListViewDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentListViewDto
     */
    avartarURL?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentListViewDto
     */
    fullname?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentListViewDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentListViewDto
     */
    childrenNames?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentListViewDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentListViewDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentListViewDto
     */
    parentReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentListViewDto
     */
    signUpDate?: string;
    /**
     * 
     * @type {number}
     * @memberof GetParentListViewDto
     */
    totalCount?: number;
    /**
     * 
     * @type {string}
     * @memberof GetParentListViewDto
     */
    invoiceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentListViewDto
     */
    status?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetParentListViewDto
     */
    brandIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetParentListViewDto
     */
    venueIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetParentListViewRequestBody
 */
export interface GetParentListViewRequestBody {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetParentListViewRequestBody
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentListViewRequestBody
     */
    searchKey?: string;
    /**
     * 
     * @type {number}
     * @memberof GetParentListViewRequestBody
     */
    start?: number;
    /**
     * 
     * @type {number}
     * @memberof GetParentListViewRequestBody
     */
    end?: number;
    /**
     * 
     * @type {string}
     * @memberof GetParentListViewRequestBody
     */
    sortColumn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetParentListViewRequestBody
     */
    sortType?: string;
    /**
     * 
     * @type {any}
     * @memberof GetParentListViewRequestBody
     */
    filterModel: any | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetParentListViewRequestBody
     */
    sortParentList?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetParentListViewResponse
 */
export interface GetParentListViewResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<GetParentListViewDto>}
     * @memberof GetParentListViewResponse
     */
    data: Array<GetParentListViewDto>;
}
/**
 * 
 * @export
 * @interface GetPastTransactionsResponse
 */
export interface GetPastTransactionsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<TransactionDto>}
     * @memberof GetPastTransactionsResponse
     */
    data: Array<TransactionDto>;
}
/**
 * 
 * @export
 * @interface GetPaymentByTransactionResponse
 */
export interface GetPaymentByTransactionResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetPaymentByTransactionResponseData}
     * @memberof GetPaymentByTransactionResponse
     */
    data: GetPaymentByTransactionResponseData;
}
/**
 * 
 * @export
 * @interface GetPaymentByTransactionResponseData
 */
export interface GetPaymentByTransactionResponseData {
    /**
     * 
     * @type {Array<UTMParamDto>}
     * @memberof GetPaymentByTransactionResponseData
     */
    utmParams?: Array<UTMParamDto>;
    /**
     * 
     * @type {string | number}
     * @memberof GetPaymentByTransactionResponseData
     */
    discount: string | number;
    /**
     * 
     * @type {CampaignDto}
     * @memberof GetPaymentByTransactionResponseData
     */
    offer: CampaignDto;
    /**
     * 
     * @type {Array<InvoiceLineDto>}
     * @memberof GetPaymentByTransactionResponseData
     */
    payments: Array<InvoiceLineDto>;
}
/**
 * 
 * @export
 * @interface GetPolicyStrapiResponse
 */
export interface GetPolicyStrapiResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {PolicyDto}
     * @memberof GetPolicyStrapiResponse
     */
    data: PolicyDto;
}
/**
 * 
 * @export
 * @interface GetPresignedPutURLRequest
 */
export interface GetPresignedPutURLRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetPresignedPutURLRequest
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof GetPresignedPutURLRequest
     */
    fileType?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPresignedPutURLRequest
     */
    uploadTo?: string;
}
/**
 * 
 * @export
 * @interface GetPresignedPutURLResponse
 */
export interface GetPresignedPutURLResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetPresignedPutURLResponseData}
     * @memberof GetPresignedPutURLResponse
     */
    data: GetPresignedPutURLResponseData;
}
/**
 * 
 * @export
 * @interface GetPresignedPutURLResponseData
 */
export interface GetPresignedPutURLResponseData {
    /**
     * 
     * @type {string}
     * @memberof GetPresignedPutURLResponseData
     */
    fileAccessURL: string;
    /**
     * 
     * @type {string}
     * @memberof GetPresignedPutURLResponseData
     */
    preSignedPutURL: string;
}
/**
 * 
 * @export
 * @interface GetProductServicePageContentResponse
 */
export interface GetProductServicePageContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetProductServicePageContentResponseData}
     * @memberof GetProductServicePageContentResponse
     */
    data: GetProductServicePageContentResponseData;
}
/**
 * 
 * @export
 * @interface GetProductServicePageContentResponseData
 */
export interface GetProductServicePageContentResponseData {
    /**
     * 
     * @type {MetaDto}
     * @memberof GetProductServicePageContentResponseData
     */
    meta?: MetaDto;
    /**
     * 
     * @type {Array<ProductServicePageTableItemsDto>}
     * @memberof GetProductServicePageContentResponseData
     */
    productServicePageTable?: Array<ProductServicePageTableItemsDto>;
    /**
     * 
     * @type {ProductServicePageEventDto}
     * @memberof GetProductServicePageContentResponseData
     */
    productServicePageEvent?: ProductServicePageEventDto;
    /**
     * 
     * @type {Array<ProductServicePageContentDto>}
     * @memberof GetProductServicePageContentResponseData
     */
    productServicePageContent?: Array<ProductServicePageContentDto>;
    /**
     * 
     * @type {ProductServicePageDecorationDto}
     * @memberof GetProductServicePageContentResponseData
     */
    productServicePageDecoration?: ProductServicePageDecorationDto;
}
/**
 * 
 * @export
 * @interface GetPromoListResponse
 */
export interface GetPromoListResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CampaignShowcaseDto>}
     * @memberof GetPromoListResponse
     */
    data: Array<CampaignShowcaseDto>;
}
/**
 * 
 * @export
 * @interface GetPromptMessageAfterSignedUp
 */
export interface GetPromptMessageAfterSignedUp {
    [key: string]: object | any;

    /**
     * 
     * @type {PromptMessageAfterSignedUpDto}
     * @memberof GetPromptMessageAfterSignedUp
     */
    data: PromptMessageAfterSignedUpDto;
}
/**
 * 
 * @export
 * @interface GetProviderBookingListRequest
 */
export interface GetProviderBookingListRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof GetProviderBookingListRequest
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProviderBookingListRequest
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProviderBookingListRequest
     */
    fromDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProviderBookingListRequest
     */
    toDate?: string;
}
/**
 * 
 * @export
 * @interface GetProviderBookingListResponse
 */
export interface GetProviderBookingListResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetProviderBookingListResponseData}
     * @memberof GetProviderBookingListResponse
     */
    data: GetProviderBookingListResponseData;
}
/**
 * 
 * @export
 * @interface GetProviderBookingListResponseData
 */
export interface GetProviderBookingListResponseData {
    /**
     * 
     * @type {Array<ProviderBookingListDto>}
     * @memberof GetProviderBookingListResponseData
     */
    bookingList: Array<ProviderBookingListDto>;
    /**
     * 
     * @type {number}
     * @memberof GetProviderBookingListResponseData
     */
    totalCount: number;
}
/**
 * 
 * @export
 * @interface GetProviderInfoResponse
 */
export interface GetProviderInfoResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {ProviderInfoDto}
     * @memberof GetProviderInfoResponse
     */
    data: ProviderInfoDto;
}
/**
 * 
 * @export
 * @interface GetProvidersResponse
 */
export interface GetProvidersResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetProvidersResponseData}
     * @memberof GetProvidersResponse
     */
    data: GetProvidersResponseData;
}
/**
 * 
 * @export
 * @interface GetProvidersResponseData
 */
export interface GetProvidersResponseData {
    /**
     * 
     * @type {Array<ProviderSummaryDto>}
     * @memberof GetProvidersResponseData
     */
    summary: Array<ProviderSummaryDto>;
    /**
     * 
     * @type {Array<ProviderDto>}
     * @memberof GetProvidersResponseData
     */
    providers?: Array<ProviderDto>;
}
/**
 * 
 * @export
 * @interface GetReasonListRequest
 */
export interface GetReasonListRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetReasonListRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface GetReasonListResponse
 */
export interface GetReasonListResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ReasonDto>}
     * @memberof GetReasonListResponse
     */
    data: Array<ReasonDto>;
}
/**
 * 
 * @export
 * @interface GetRecurringPaymentResponseInterface
 */
export interface GetRecurringPaymentResponseInterface {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SubscriptionDto>}
     * @memberof GetRecurringPaymentResponseInterface
     */
    data: Array<SubscriptionDto>;
}
/**
 * 
 * @export
 * @interface GetRefreshTokenRequest
 */
export interface GetRefreshTokenRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetRefreshTokenRequest
     */
    refreshToken: string;
}
/**
 * 
 * @export
 * @interface GetRefundHistoriesResponse
 */
export interface GetRefundHistoriesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<RefundDto>}
     * @memberof GetRefundHistoriesResponse
     */
    data?: Array<RefundDto>;
}
/**
 * 
 * @export
 * @interface GetReplacementHistory
 */
export interface GetReplacementHistory {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetReplacementHistory
     */
    originalSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetReplacementHistory
     */
    fromStartTime?: string;
    /**
     * 
     * @type {string}
     * @memberof GetReplacementHistory
     */
    fromEndTime?: string;
    /**
     * 
     * @type {string}
     * @memberof GetReplacementHistory
     */
    toStartTime?: string;
    /**
     * 
     * @type {string}
     * @memberof GetReplacementHistory
     */
    toEndTime?: string;
    /**
     * 
     * @type {string}
     * @memberof GetReplacementHistory
     */
    approverName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetReplacementHistory
     */
    replacementTime?: string;
    /**
     * 
     * @type {string}
     * @memberof GetReplacementHistory
     */
    remark?: string;
}
/**
 * 
 * @export
 * @interface GetRoomBookingInvoiceDetailResponse
 */
export interface GetRoomBookingInvoiceDetailResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {InvoiceDto}
     * @memberof GetRoomBookingInvoiceDetailResponse
     */
    data: InvoiceDto;
}
/**
 * 
 * @export
 * @interface GetRoomBookingInvoicesDetailResponse
 */
export interface GetRoomBookingInvoicesDetailResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {InvoiceDto}
     * @memberof GetRoomBookingInvoicesDetailResponse
     */
    data: InvoiceDto;
}
/**
 * 
 * @export
 * @interface GetRoomCheckInMediaDto
 */
export interface GetRoomCheckInMediaDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetRoomCheckInMediaDto
     */
    checkInType?: GetRoomCheckInMediaDtoCheckInTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetRoomCheckInMediaDto
     */
    checkInNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoomCheckInMediaDto
     */
    checkOutNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoomCheckInMediaDto
     */
    mediaUrl?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetRoomCheckInMediaDtoCheckInTypeEnum {
    In = 'check_in',
    Out = 'check_out'
}

/**
 * 
 * @export
 * @interface GetRoomCheckInReportDto
 */
export interface GetRoomCheckInReportDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetRoomCheckInReportDto
     */
    roomName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoomCheckInReportDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoomCheckInReportDto
     */
    epName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoomCheckInReportDto
     */
    teacherName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoomCheckInReportDto
     */
    roomCheckInId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoomCheckInReportDto
     */
    roomBookingId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoomCheckInReportDto
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoomCheckInReportDto
     */
    checkInType?: GetRoomCheckInReportDtoCheckInTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetRoomCheckInReportDto
     */
    checkInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoomCheckInReportDto
     */
    checkOutTime?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRoomCheckInReportDto
     */
    numberOfStudents?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum GetRoomCheckInReportDtoCheckInTypeEnum {
    In = 'check_in',
    Out = 'check_out'
}

/**
 * 
 * @export
 * @interface GetSchoolForChildResponse
 */
export interface GetSchoolForChildResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetSchoolForChildResponseData}
     * @memberof GetSchoolForChildResponse
     */
    data?: GetSchoolForChildResponseData;
}
/**
 * 
 * @export
 * @interface GetSchoolForChildResponseData
 */
export interface GetSchoolForChildResponseData {
    /**
     * 
     * @type {Array<SchoolDto>}
     * @memberof GetSchoolForChildResponseData
     */
    schools: Array<SchoolDto>;
}
/**
 * 
 * @export
 * @interface GetSessionPackDetailResponse
 */
export interface GetSessionPackDetailResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {SessionPackDto}
     * @memberof GetSessionPackDetailResponse
     */
    data: SessionPackDto;
}
/**
 * 
 * @export
 * @interface GetSessionsOfCourseInstanceRequest
 */
export interface GetSessionsOfCourseInstanceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof GetSessionsOfCourseInstanceRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSessionsOfCourseInstanceRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSessionsOfCourseInstanceRequest
     */
    courseInstanceId: string;
}
/**
 * 
 * @export
 * @interface GetSessionsOfCourseInstanceResponse
 */
export interface GetSessionsOfCourseInstanceResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetSessionsOfCourseInstanceResponseData}
     * @memberof GetSessionsOfCourseInstanceResponse
     */
    data: GetSessionsOfCourseInstanceResponseData;
}
/**
 * 
 * @export
 * @interface GetSessionsOfCourseInstanceResponseData
 */
export interface GetSessionsOfCourseInstanceResponseData {
    /**
     * 
     * @type {Array<CourseSessionDto>}
     * @memberof GetSessionsOfCourseInstanceResponseData
     */
    sessions: Array<CourseSessionDto>;
    /**
     * 
     * @type {number}
     * @memberof GetSessionsOfCourseInstanceResponseData
     */
    totalCount: number;
}
/**
 * 
 * @export
 * @interface GetShoppingCartResponse
 */
export interface GetShoppingCartResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetShoppingCartResponseData}
     * @memberof GetShoppingCartResponse
     */
    data?: GetShoppingCartResponseData;
}
/**
 * 
 * @export
 * @interface GetShoppingCartResponseData
 */
export interface GetShoppingCartResponseData {
    /**
     * 
     * @type {string}
     * @memberof GetShoppingCartResponseData
     */
    shoppingCartData: string;
    /**
     * 
     * @type {number}
     * @memberof GetShoppingCartResponseData
     */
    balanceCredit: number;
    /**
     * 
     * @type {Array<CampaignDto>}
     * @memberof GetShoppingCartResponseData
     */
    availableOffers: Array<CampaignDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetShoppingCartResponseData
     */
    isValidPayment: boolean;
    /**
     * 
     * @type {Array<ShoppingCartDto>}
     * @memberof GetShoppingCartResponseData
     */
    cartList: Array<ShoppingCartDto>;
}
/**
 * 
 * @export
 * @interface GetShowcaseCampaignPanelResponse
 */
export interface GetShowcaseCampaignPanelResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {ShowcaseCampaignPanelDto}
     * @memberof GetShowcaseCampaignPanelResponse
     */
    data: ShowcaseCampaignPanelDto;
}
/**
 * 
 * @export
 * @interface GetStatusRecurringBillingResponse
 */
export interface GetStatusRecurringBillingResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    customer_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    customer_email: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    cycle: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof GetStatusRecurringBillingResponse
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof GetStatusRecurringBillingResponse
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    redirect_url: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetStatusRecurringBillingResponse
     */
    payment_methods: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    webhook: string;
    /**
     * 
     * @type {number}
     * @memberof GetStatusRecurringBillingResponse
     */
    total_charge: number;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponse
     */
    payment_provider_charge_method: string;
    /**
     * 
     * @type {GetStatusRecurringBillingResponsePaymentMethod}
     * @memberof GetStatusRecurringBillingResponse
     */
    payment_method?: GetStatusRecurringBillingResponsePaymentMethod;
}
/**
 * 
 * @export
 * @interface GetStatusRecurringBillingResponsePaymentMethod
 */
export interface GetStatusRecurringBillingResponsePaymentMethod {
    /**
     * 
     * @type {GetStatusRecurringBillingResponsePaymentMethodCard}
     * @memberof GetStatusRecurringBillingResponsePaymentMethod
     */
    card?: GetStatusRecurringBillingResponsePaymentMethodCard;
}
/**
 * 
 * @export
 * @interface GetStatusRecurringBillingResponsePaymentMethodCard
 */
export interface GetStatusRecurringBillingResponsePaymentMethodCard {
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponsePaymentMethodCard
     */
    last4?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRecurringBillingResponsePaymentMethodCard
     */
    brand?: string;
}
/**
 * 
 * @export
 * @interface GetStudentCareCurriculumPageContentResponse
 */
export interface GetStudentCareCurriculumPageContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {StudentCareCurriculumDto}
     * @memberof GetStudentCareCurriculumPageContentResponse
     */
    data: StudentCareCurriculumDto;
}
/**
 * 
 * @export
 * @interface GetStudentCarePricingHeroResponse
 */
export interface GetStudentCarePricingHeroResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {SEODataDto}
     * @memberof GetStudentCarePricingHeroResponse
     */
    data: SEODataDto;
}
/**
 * 
 * @export
 * @interface GetStudentCarePricingPageResponse
 */
export interface GetStudentCarePricingPageResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetStudentCarePricingPageResponseData}
     * @memberof GetStudentCarePricingPageResponse
     */
    data: GetStudentCarePricingPageResponseData;
}
/**
 * 
 * @export
 * @interface GetStudentCarePricingPageResponseData
 */
export interface GetStudentCarePricingPageResponseData {
    /**
     * 
     * @type {StudentCarePricingSchoolDto}
     * @memberof GetStudentCarePricingPageResponseData
     */
    school: StudentCarePricingSchoolDto;
    /**
     * 
     * @type {StudentCarePricingPromotionDto}
     * @memberof GetStudentCarePricingPageResponseData
     */
    promotion: StudentCarePricingPromotionDto;
    /**
     * 
     * @type {StudentCarePricingAddonsDto}
     * @memberof GetStudentCarePricingPageResponseData
     */
    addOns: StudentCarePricingAddonsDto;
    /**
     * 
     * @type {StudentCarePricingTableDto}
     * @memberof GetStudentCarePricingPageResponseData
     */
    table?: StudentCarePricingTableDto;
    /**
     * 
     * @type {SEODataDto}
     * @memberof GetStudentCarePricingPageResponseData
     */
    seo: SEODataDto;
    /**
     * 
     * @type {string}
     * @memberof GetStudentCarePricingPageResponseData
     */
    subTitle: string;
    /**
     * 
     * @type {string}
     * @memberof GetStudentCarePricingPageResponseData
     */
    title: string;
}
/**
 * 
 * @export
 * @interface GetStudentCareTechnologyPageResponse
 */
export interface GetStudentCareTechnologyPageResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetStudentCareTechnologyPageResponseData}
     * @memberof GetStudentCareTechnologyPageResponse
     */
    data: GetStudentCareTechnologyPageResponseData;
}
/**
 * 
 * @export
 * @interface GetStudentCareTechnologyPageResponseData
 */
export interface GetStudentCareTechnologyPageResponseData {
    /**
     * 
     * @type {Array<ContentAndImageSplitDto>}
     * @memberof GetStudentCareTechnologyPageResponseData
     */
    contentAndImageSplitItems: Array<ContentAndImageSplitDto>;
    /**
     * 
     * @type {StudentCareTechnologyHero}
     * @memberof GetStudentCareTechnologyPageResponseData
     */
    heroSection?: StudentCareTechnologyHero;
}
/**
 * 
 * @export
 * @interface GetStudentCoursesRequest
 */
export interface GetStudentCoursesRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<string>}
     * @memberof GetStudentCoursesRequest
     */
    studentIds: Array<string>;
}
/**
 * 
 * @export
 * @interface GetStudentCoursesResponse
 */
export interface GetStudentCoursesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<StudentCoursesDto>}
     * @memberof GetStudentCoursesResponse
     */
    data: Array<StudentCoursesDto>;
}
/**
 * 
 * @export
 * @interface GetStudentSessionDatesRequest
 */
export interface GetStudentSessionDatesRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<string>}
     * @memberof GetStudentSessionDatesRequest
     */
    studentIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetStudentSessionDatesRequest
     */
    courseIds: Array<string>;
}
/**
 * 
 * @export
 * @interface GetStudentSessionDatesResponse
 */
export interface GetStudentSessionDatesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<StudentSessionDatesDto>}
     * @memberof GetStudentSessionDatesResponse
     */
    data: Array<StudentSessionDatesDto>;
}
/**
 * 
 * @export
 * @interface GetStudentsOfCourseInstanceRequest
 */
export interface GetStudentsOfCourseInstanceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof GetStudentsOfCourseInstanceRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof GetStudentsOfCourseInstanceRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof GetStudentsOfCourseInstanceRequest
     */
    courseInstanceId: string;
}
/**
 * 
 * @export
 * @interface GetStudentsOfCourseInstanceResponse
 */
export interface GetStudentsOfCourseInstanceResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetStudentsOfCourseInstanceResponseData}
     * @memberof GetStudentsOfCourseInstanceResponse
     */
    data: GetStudentsOfCourseInstanceResponseData;
}
/**
 * 
 * @export
 * @interface GetStudentsOfCourseInstanceResponseData
 */
export interface GetStudentsOfCourseInstanceResponseData {
    /**
     * 
     * @type {Array<ChildDto>}
     * @memberof GetStudentsOfCourseInstanceResponseData
     */
    students: Array<ChildDto>;
    /**
     * 
     * @type {number}
     * @memberof GetStudentsOfCourseInstanceResponseData
     */
    totalCount: number;
}
/**
 * 
 * @export
 * @interface GetSubjectResponse
 */
export interface GetSubjectResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {SubjectDto}
     * @memberof GetSubjectResponse
     */
    data?: SubjectDto;
}
/**
 * 
 * @export
 * @interface GetSubjectsResponse
 */
export interface GetSubjectsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetSubjectsResponseData}
     * @memberof GetSubjectsResponse
     */
    data: GetSubjectsResponseData;
}
/**
 * 
 * @export
 * @interface GetSubjectsResponseData
 */
export interface GetSubjectsResponseData {
    /**
     * 
     * @type {Array<SubjectSummaryDto>}
     * @memberof GetSubjectsResponseData
     */
    summary?: Array<SubjectSummaryDto>;
    /**
     * 
     * @type {Array<SubjectDto>}
     * @memberof GetSubjectsResponseData
     */
    subjects?: Array<SubjectDto>;
}
/**
 * 
 * @export
 * @interface GetSubjectsWithResponse
 */
export interface GetSubjectsWithResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SubjectFilterDto>}
     * @memberof GetSubjectsWithResponse
     */
    data: Array<SubjectFilterDto>;
}
/**
 * 
 * @export
 * @interface GetSupportDataEnhancedResponse
 */
export interface GetSupportDataEnhancedResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetSupportDataEnhancedResponseData}
     * @memberof GetSupportDataEnhancedResponse
     */
    data: GetSupportDataEnhancedResponseData;
}
/**
 * 
 * @export
 * @interface GetSupportDataEnhancedResponseData
 */
export interface GetSupportDataEnhancedResponseData {
    /**
     * 
     * @type {Array<CourseFilterContentDto>}
     * @memberof GetSupportDataEnhancedResponseData
     */
    coursePeriods: Array<CourseFilterContentDto>;
    /**
     * 
     * @type {Array<CourseFilterContentDto>}
     * @memberof GetSupportDataEnhancedResponseData
     */
    educationProviders: Array<CourseFilterContentDto>;
    /**
     * 
     * @type {Array<CourseFilterContentDto>}
     * @memberof GetSupportDataEnhancedResponseData
     */
    gradeLevels: Array<CourseFilterContentDto>;
    /**
     * 
     * @type {Array<CourseAreaDto>}
     * @memberof GetSupportDataEnhancedResponseData
     */
    subjectAreas: Array<CourseAreaDto>;
}
/**
 * 
 * @export
 * @interface GetSupportDataResponse
 */
export interface GetSupportDataResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetSupportDataResponseData}
     * @memberof GetSupportDataResponse
     */
    data?: GetSupportDataResponseData;
}
/**
 * 
 * @export
 * @interface GetSupportDataResponseData
 */
export interface GetSupportDataResponseData {
    /**
     * 
     * @type {Array<AgeGroupDto>}
     * @memberof GetSupportDataResponseData
     */
    ageGroups?: Array<AgeGroupDto>;
    /**
     * 
     * @type {Array<AreaDto>}
     * @memberof GetSupportDataResponseData
     */
    subjectAreas?: Array<AreaDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSupportDataResponseData
     */
    subjectTypes?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetTeacherConversationResponse
 */
export interface GetTeacherConversationResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<TeacherConversationDto>}
     * @memberof GetTeacherConversationResponse
     */
    data: Array<TeacherConversationDto>;
}
/**
 * 
 * @export
 * @interface GetTeachersResponse
 */
export interface GetTeachersResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<UserDto>}
     * @memberof GetTeachersResponse
     */
    data: Array<UserDto>;
}
/**
 * 
 * @export
 * @interface GetTermsAndConditionsResponse
 */
export interface GetTermsAndConditionsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {TermsAndConditionsDto}
     * @memberof GetTermsAndConditionsResponse
     */
    data: TermsAndConditionsDto;
}
/**
 * 
 * @export
 * @interface GetTheSpacePageContentResponse
 */
export interface GetTheSpacePageContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {TheSpacePageContentDto}
     * @memberof GetTheSpacePageContentResponse
     */
    data: TheSpacePageContentDto;
}
/**
 * 
 * @export
 * @interface GetTicketByIdResponse
 */
export interface GetTicketByIdResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {ITicket}
     * @memberof GetTicketByIdResponse
     */
    data: ITicket | null;
}
/**
 * 
 * @export
 * @interface GetTicketFilterResponse
 */
export interface GetTicketFilterResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {ITicketFilter}
     * @memberof GetTicketFilterResponse
     */
    data: ITicketFilter;
}
/**
 * 
 * @export
 * @interface GetUnreadMessageAmount
 */
export interface GetUnreadMessageAmount {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof GetUnreadMessageAmount
     */
    unreadMessageAmount: number;
}
/**
 * 
 * @export
 * @interface GetUnreadTicketsCountResponse
 */
export interface GetUnreadTicketsCountResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof GetUnreadTicketsCountResponse
     */
    data: number;
}
/**
 * 
 * @export
 * @interface GetUserActivityFeedResponse
 */
export interface GetUserActivityFeedResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<MixpanelDto>}
     * @memberof GetUserActivityFeedResponse
     */
    data: Array<MixpanelDto>;
}
/**
 * 
 * @export
 * @interface GetUserCardResponse
 */
export interface GetUserCardResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {UserCardDto}
     * @memberof GetUserCardResponse
     */
    data: UserCardDto;
}
/**
 * 
 * @export
 * @interface GetUserCardsByUserIdResponse
 */
export interface GetUserCardsByUserIdResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<UserCardDto>}
     * @memberof GetUserCardsByUserIdResponse
     */
    data: Array<UserCardDto>;
}
/**
 * 
 * @export
 * @interface GetUserInfoResponse
 */
export interface GetUserInfoResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {GetUserInfoResponseData}
     * @memberof GetUserInfoResponse
     */
    data: GetUserInfoResponseData;
}
/**
 * 
 * @export
 * @interface GetUserInfoResponseData
 */
export interface GetUserInfoResponseData {
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof GetUserInfoResponseData
     */
    guardians?: Array<UserDto>;
    /**
     * 
     * @type {UserDto}
     * @memberof GetUserInfoResponseData
     */
    info: UserDto;
    /**
     * 
     * @type {Array<ChildrenDto>}
     * @memberof GetUserInfoResponseData
     */
    childrens: Array<ChildrenDto>;
}
/**
 * 
 * @export
 * @interface GetUserSubscriptionPlanResponse
 */
export interface GetUserSubscriptionPlanResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {UserSubscriptionPlan}
     * @memberof GetUserSubscriptionPlanResponse
     */
    data: UserSubscriptionPlan | null;
}
/**
 * 
 * @export
 * @interface GetUserSubscriptionsResponse
 */
export interface GetUserSubscriptionsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SubscriptionDto>}
     * @memberof GetUserSubscriptionsResponse
     */
    data: Array<SubscriptionDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum GetVenuesForCreateMessageType {
    AgoraVenues = 'all_agora_venues',
    AtSchoolVenues = 'all_at_school_venues'
}

/**
 * 
 * @export
 * @interface GettingThereDto
 */
export interface GettingThereDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof GettingThereDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof GettingThereDto
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof GettingThereDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof GettingThereDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof GettingThereDto
     */
    description?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GettingThereDto
     */
    content?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface GettingTherePageContentDto
 */
export interface GettingTherePageContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<GettingThereDto>}
     * @memberof GettingTherePageContentDto
     */
    contentList: Array<GettingThereDto>;
    /**
     * 
     * @type {Array<GettingThereSliderDto>}
     * @memberof GettingTherePageContentDto
     */
    sliderList: Array<GettingThereSliderDto>;
}
/**
 * 
 * @export
 * @interface GettingThereSliderDto
 */
export interface GettingThereSliderDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof GettingThereSliderDto
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof GettingThereSliderDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof GettingThereSliderDto
     */
    image: string;
}
/**
 * 
 * @export
 * @interface GstDto
 */
export interface GstDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GstDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GstDto
     */
    code?: GstDtoCodeEnum;
    /**
     * 
     * @type {string | number}
     * @memberof GstDto
     */
    percent?: string | number;
}

/**
    * @export
    * @enum {string}
    */
export enum GstDtoCodeEnum {
    Sr = 'SR',
    Es = 'ES'
}

/**
 * 
 * @export
 * @interface GuardianImportDto
 */
export interface GuardianImportDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GuardianImportDto
     */
    guardianFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianImportDto
     */
    guardianLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianImportDto
     */
    guardianEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianImportDto
     */
    guardianPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianImportDto
     */
    guardianAccessToApp?: GuardianImportDtoGuardianAccessToAppEnum;
    /**
     * 
     * @type {string}
     * @memberof GuardianImportDto
     */
    guardianEmergencyContact?: GuardianImportDtoGuardianEmergencyContactEnum;
    /**
     * 
     * @type {string}
     * @memberof GuardianImportDto
     */
    guardianRelationshipToChild?: GuardianImportDtoGuardianRelationshipToChildEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GuardianImportDtoGuardianAccessToAppEnum {
    Yes = 'yes',
    No = 'no'
}
/**
    * @export
    * @enum {string}
    */
export enum GuardianImportDtoGuardianEmergencyContactEnum {
    Yes = 'yes',
    No = 'no'
}
/**
    * @export
    * @enum {string}
    */
export enum GuardianImportDtoGuardianRelationshipToChildEnum {
    Mother = 'mother',
    Father = 'father',
    Grandfather = 'grandfather',
    Grandmother = 'grandmother',
    Brother = 'brother',
    Sister = 'sister',
    Helper = 'helper',
    Others = 'others'
}

/**
 * 
 * @export
 * @interface HandleAfterPurchaseSubscriptionRequest
 */
export interface HandleAfterPurchaseSubscriptionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof HandleAfterPurchaseSubscriptionRequest
     */
    cardId?: string;
}
/**
 * 
 * @export
 * @interface HandleAfterPurchaseSubscriptionResponse
 */
export interface HandleAfterPurchaseSubscriptionResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {HandleAfterPurchaseSubscriptionResponseData}
     * @memberof HandleAfterPurchaseSubscriptionResponse
     */
    data: HandleAfterPurchaseSubscriptionResponseData;
}
/**
 * 
 * @export
 * @interface HandleAfterPurchaseSubscriptionResponseData
 */
export interface HandleAfterPurchaseSubscriptionResponseData {
    /**
     * 
     * @type {string}
     * @memberof HandleAfterPurchaseSubscriptionResponseData
     */
    transactionId?: string;
}
/**
 * 
 * @export
 * @interface HandleAfterSavedCardRequest
 */
export interface HandleAfterSavedCardRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof HandleAfterSavedCardRequest
     */
    cardId: string;
}
/**
 * 
 * @export
 * @interface HeroSectionDto
 */
export interface HeroSectionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof HeroSectionDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof HeroSectionDto
     */
    subTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof HeroSectionDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof HeroSectionDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {StrapiHomepageButtonDto}
     * @memberof HeroSectionDto
     */
    primaryButton?: StrapiHomepageButtonDto;
    /**
     * 
     * @type {StrapiHomepageButtonDto}
     * @memberof HeroSectionDto
     */
    ghostButton?: StrapiHomepageButtonDto;
    /**
     * 
     * @type {SEODataDto}
     * @memberof HeroSectionDto
     */
    seo?: SEODataDto;
}
/**
 * 
 * @export
 * @interface HitPayCreateRequestResponse
 */
export interface HitPayCreateRequestResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof HitPayCreateRequestResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayCreateRequestResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayCreateRequestResponse
     */
    email?: string;
    /**
     * 
     * @type {any}
     * @memberof HitPayCreateRequestResponse
     */
    phone?: any | null;
    /**
     * 
     * @type {string}
     * @memberof HitPayCreateRequestResponse
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayCreateRequestResponse
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayCreateRequestResponse
     */
    status?: string;
    /**
     * 
     * @type {any}
     * @memberof HitPayCreateRequestResponse
     */
    purpose?: any | null;
    /**
     * 
     * @type {any}
     * @memberof HitPayCreateRequestResponse
     */
    reference_number?: any | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof HitPayCreateRequestResponse
     */
    payment_methods?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof HitPayCreateRequestResponse
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayCreateRequestResponse
     */
    redirect_url?: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayCreateRequestResponse
     */
    webhook?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HitPayCreateRequestResponse
     */
    send_sms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HitPayCreateRequestResponse
     */
    send_email?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HitPayCreateRequestResponse
     */
    sms_status?: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayCreateRequestResponse
     */
    email_status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HitPayCreateRequestResponse
     */
    allow_repeated_payments?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HitPayCreateRequestResponse
     */
    expiry_date?: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayCreateRequestResponse
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayCreateRequestResponse
     */
    updated_at?: string;
    /**
     * 
     * @type {Array<UTMParamDto>}
     * @memberof HitPayCreateRequestResponse
     */
    utmParams?: Array<UTMParamDto>;
}
/**
 * 
 * @export
 * @interface HitPayGetPaymentStatusResponse
 */
export interface HitPayGetPaymentStatusResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof HitPayGetPaymentStatusResponse
     */
    id: string;
    /**
     * 
     * @type {any}
     * @memberof HitPayGetPaymentStatusResponse
     */
    name?: any | null;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetPaymentStatusResponse
     */
    email: string;
    /**
     * 
     * @type {any}
     * @memberof HitPayGetPaymentStatusResponse
     */
    phone?: any | null;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetPaymentStatusResponse
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetPaymentStatusResponse
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetPaymentStatusResponse
     */
    status: string;
    /**
     * 
     * @type {any}
     * @memberof HitPayGetPaymentStatusResponse
     */
    purpose?: any | null;
    /**
     * 
     * @type {any}
     * @memberof HitPayGetPaymentStatusResponse
     */
    reference_number?: any | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof HitPayGetPaymentStatusResponse
     */
    payment_methods: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetPaymentStatusResponse
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetPaymentStatusResponse
     */
    redirect_url: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetPaymentStatusResponse
     */
    webhook: string;
    /**
     * 
     * @type {boolean}
     * @memberof HitPayGetPaymentStatusResponse
     */
    send_sms: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HitPayGetPaymentStatusResponse
     */
    send_email: boolean;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetPaymentStatusResponse
     */
    sms_status: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetPaymentStatusResponse
     */
    email_status: string;
    /**
     * 
     * @type {boolean}
     * @memberof HitPayGetPaymentStatusResponse
     */
    allow_repeated_payments: boolean;
    /**
     * 
     * @type {any}
     * @memberof HitPayGetPaymentStatusResponse
     */
    expiry_date?: any | null;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetPaymentStatusResponse
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetPaymentStatusResponse
     */
    updated_at: string;
    /**
     * 
     * @type {Array<HitPayPayment>}
     * @memberof HitPayGetPaymentStatusResponse
     */
    payments: Array<HitPayPayment>;
    /**
     * 
     * @type {Array<HitPayGetStatusPaymentLineItemObjectInterface>}
     * @memberof HitPayGetPaymentStatusResponse
     */
    line_items?: Array<HitPayGetStatusPaymentLineItemObjectInterface>;
    /**
     * 
     * @type {HitPayGetStatusPaymentAddressInterface}
     * @memberof HitPayGetPaymentStatusResponse
     */
    address?: HitPayGetStatusPaymentAddressInterface;
}
/**
 * 
 * @export
 * @interface HitPayGetStatusPaymentAddressInterface
 */
export interface HitPayGetStatusPaymentAddressInterface {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof HitPayGetStatusPaymentAddressInterface
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetStatusPaymentAddressInterface
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetStatusPaymentAddressInterface
     */
    line2: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetStatusPaymentAddressInterface
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetStatusPaymentAddressInterface
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetStatusPaymentAddressInterface
     */
    postal_code: string;
}
/**
 * 
 * @export
 * @interface HitPayGetStatusPaymentLineItemObjectInterface
 */
export interface HitPayGetStatusPaymentLineItemObjectInterface {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof HitPayGetStatusPaymentLineItemObjectInterface
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetStatusPaymentLineItemObjectInterface
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetStatusPaymentLineItemObjectInterface
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetStatusPaymentLineItemObjectInterface
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof HitPayGetStatusPaymentLineItemObjectInterface
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetStatusPaymentLineItemObjectInterface
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof HitPayGetStatusPaymentLineItemObjectInterface
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof HitPayGetStatusPaymentLineItemObjectInterface
     */
    description: string;
}
/**
 * 
 * @export
 * @interface HitPayPayment
 */
export interface HitPayPayment {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof HitPayPayment
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof HitPayPayment
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof HitPayPayment
     */
    status: string;
    /**
     * 
     * @type {any}
     * @memberof HitPayPayment
     */
    buyer_name?: any | null;
    /**
     * 
     * @type {any}
     * @memberof HitPayPayment
     */
    buyer_phone?: any | null;
    /**
     * 
     * @type {string}
     * @memberof HitPayPayment
     */
    buyer_email: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayPayment
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayPayment
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayPayment
     */
    payment_type: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayPayment
     */
    fees: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayPayment
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof HitPayPayment
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @interface HomePageContentDto
 */
export interface HomePageContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof HomePageContentDto
     */
    heroImage?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageContentDto
     */
    heroBackgroundImage?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageContentDto
     */
    MainMenuFontColour?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageContentDto
     */
    LogoFontColour?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageContentDto
     */
    HeroTextColor?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageContentDto
     */
    NewsletterImage?: string;
    /**
     * 
     * @type {Array<HomepageFeatureDto>}
     * @memberof HomePageContentDto
     */
    featureList: Array<HomepageFeatureDto>;
    /**
     * 
     * @type {Array<HomepageSliderDto>}
     * @memberof HomePageContentDto
     */
    sliderList: Array<HomepageSliderDto>;
    /**
     * 
     * @type {Array<HomepageFeedbackDto>}
     * @memberof HomePageContentDto
     */
    feedbackList: Array<HomepageFeedbackDto>;
    /**
     * 
     * @type {MetaDto}
     * @memberof HomePageContentDto
     */
    meta?: MetaDto;
    /**
     * 
     * @type {BrowseCourseButtonDto}
     * @memberof HomePageContentDto
     */
    browseCourseButton?: BrowseCourseButtonDto;
    /**
     * 
     * @type {StrapiHomepageButtonDto}
     * @memberof HomePageContentDto
     */
    primaryButton?: StrapiHomepageButtonDto;
    /**
     * 
     * @type {StrapiHomepageButtonDto}
     * @memberof HomePageContentDto
     */
    ghostButton?: StrapiHomepageButtonDto;
    /**
     * 
     * @type {string}
     * @memberof HomePageContentDto
     */
    metaDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageContentDto
     */
    metaKeywords?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageContentDto
     */
    pageTitle?: string;
}
/**
 * 
 * @export
 * @interface HomePageImageDto
 */
export interface HomePageImageDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof HomePageImageDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof HomePageImageDto
     */
    image?: string;
}
/**
 * 
 * @export
 * @interface HomePageWhatWeDoDto
 */
export interface HomePageWhatWeDoDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof HomePageWhatWeDoDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageWhatWeDoDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageWhatWeDoDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageWhatWeDoDto
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageWhatWeDoDto
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageWhatWeDoDto
     */
    path?: string;
}
/**
 * 
 * @export
 * @interface HomepageFeatureDto
 */
export interface HomepageFeatureDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof HomepageFeatureDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HomepageFeatureDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof HomepageFeatureDto
     */
    backgroundColor: string;
    /**
     * 
     * @type {string}
     * @memberof HomepageFeatureDto
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof HomepageFeatureDto
     */
    title: string;
    /**
     * 
     * @type {ButtonLinkDto}
     * @memberof HomepageFeatureDto
     */
    button: ButtonLinkDto;
}
/**
 * 
 * @export
 * @interface HomepageFeedbackDto
 */
export interface HomepageFeedbackDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof HomepageFeedbackDto
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof HomepageFeedbackDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof HomepageFeedbackDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof HomepageFeedbackDto
     */
    quote: string;
    /**
     * 
     * @type {string}
     * @memberof HomepageFeedbackDto
     */
    backgroundImageUrl: string;
}
/**
 * 
 * @export
 * @interface HomepageSliderDto
 */
export interface HomepageSliderDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof HomepageSliderDto
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof HomepageSliderDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof HomepageSliderDto
     */
    backgroundMain: string;
    /**
     * 
     * @type {string}
     * @memberof HomepageSliderDto
     */
    backgroundContent: string;
    /**
     * 
     * @type {string}
     * @memberof HomepageSliderDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof HomepageSliderDto
     */
    dotColor: string;
    /**
     * 
     * @type {string}
     * @memberof HomepageSliderDto
     */
    dotColorActive: string;
    /**
     * 
     * @type {string}
     * @memberof HomepageSliderDto
     */
    imageUrl: string;
}
/**
 * 
 * @export
 * @interface HostAnEventWithUsBodyContent
 */
export interface HostAnEventWithUsBodyContent {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof HostAnEventWithUsBodyContent
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof HostAnEventWithUsBodyContent
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof HostAnEventWithUsBodyContent
     */
    subTitle: string;
    /**
     * 
     * @type {string}
     * @memberof HostAnEventWithUsBodyContent
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof HostAnEventWithUsBodyContent
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof HostAnEventWithUsBodyContent
     */
    shadowColor?: string;
}
/**
 * 
 * @export
 * @interface HostAnEventWithUsFacilitiesContent
 */
export interface HostAnEventWithUsFacilitiesContent {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof HostAnEventWithUsFacilitiesContent
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof HostAnEventWithUsFacilitiesContent
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof HostAnEventWithUsFacilitiesContent
     */
    description: string;
}
/**
 * 
 * @export
 * @interface HostAnEventWithUsPageContentDto
 */
export interface HostAnEventWithUsPageContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof HostAnEventWithUsPageContentDto
     */
    heroImage: string;
    /**
     * 
     * @type {string}
     * @memberof HostAnEventWithUsPageContentDto
     */
    activitiesImages: string;
    /**
     * 
     * @type {Array<HostAnEventWithUsBodyContent>}
     * @memberof HostAnEventWithUsPageContentDto
     */
    contentList: Array<HostAnEventWithUsBodyContent>;
    /**
     * 
     * @type {Array<HostAnEventWithUsFacilitiesContent>}
     * @memberof HostAnEventWithUsPageContentDto
     */
    facilityList: Array<HostAnEventWithUsFacilitiesContent>;
    /**
     * 
     * @type {string}
     * @memberof HostAnEventWithUsPageContentDto
     */
    activitiesTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof HostAnEventWithUsPageContentDto
     */
    activitiesSubTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof HostAnEventWithUsPageContentDto
     */
    activitiesDescription?: string;
    /**
     * 
     * @type {MetaDto}
     * @memberof HostAnEventWithUsPageContentDto
     */
    meta?: MetaDto;
}
/**
 * 
 * @export
 * @interface HowItWorksContentDto
 */
export interface HowItWorksContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof HowItWorksContentDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof HowItWorksContentDto
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof HowItWorksContentDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof HowItWorksContentDto
     */
    buttonContent?: string;
    /**
     * 
     * @type {string}
     * @memberof HowItWorksContentDto
     */
    buttonColor?: string;
    /**
     * 
     * @type {string}
     * @memberof HowItWorksContentDto
     */
    buttonRoute?: string;
    /**
     * 
     * @type {string}
     * @memberof HowItWorksContentDto
     */
    imageBoxShadowColor?: string;
    /**
     * 
     * @type {string}
     * @memberof HowItWorksContentDto
     */
    publishedAt?: string;
}
/**
 * 
 * @export
 * @interface HowItWorksDto
 */
export interface HowItWorksDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof HowItWorksDto
     */
    headlineTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof HowItWorksDto
     */
    headlineDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof HowItWorksDto
     */
    courseListTitle?: string;
    /**
     * 
     * @type {Array<HowItWorksContentDto>}
     * @memberof HowItWorksDto
     */
    contents?: Array<HowItWorksContentDto>;
}
/**
 * 
 * @export
 * @interface HowItWorksResponse
 */
export interface HowItWorksResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {HowItWorksDto}
     * @memberof HowItWorksResponse
     */
    data: HowItWorksDto;
}
/**
 * 
 * @export
 * @interface IAdminGetEmailTemplateDetailsWithoutBrandResponse
 */
export interface IAdminGetEmailTemplateDetailsWithoutBrandResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Template}
     * @memberof IAdminGetEmailTemplateDetailsWithoutBrandResponse
     */
    data: Template | null;
}
/**
 * 
 * @export
 * @interface IAdminGetEmailTemplatesWithoutBrandResponse
 */
export interface IAdminGetEmailTemplatesWithoutBrandResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<TemplateInList>}
     * @memberof IAdminGetEmailTemplatesWithoutBrandResponse
     */
    data: Array<TemplateInList>;
}
/**
 * 
 * @export
 * @interface IAdminGetInvoiceListCount
 */
export interface IAdminGetInvoiceListCount {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof IAdminGetInvoiceListCount
     */
    data: number;
}
/**
 * 
 * @export
 * @interface IAdminGetInvoiceListResponse
 */
export interface IAdminGetInvoiceListResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {IAdminGetInvoiceListResponseData}
     * @memberof IAdminGetInvoiceListResponse
     */
    data: IAdminGetInvoiceListResponseData;
}
/**
 * 
 * @export
 * @interface IAdminGetInvoiceListResponseData
 */
export interface IAdminGetInvoiceListResponseData {
    /**
     * 
     * @type {Array<InvoiceNewDto>}
     * @memberof IAdminGetInvoiceListResponseData
     */
    invoices: Array<InvoiceNewDto>;
    /**
     * 
     * @type {number}
     * @memberof IAdminGetInvoiceListResponseData
     */
    total: number;
}
/**
 * 
 * @export
 * @interface IAvailableDates
 */
export interface IAvailableDates {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof IAvailableDates
     */
    instanceReferenceId: string;
    /**
     * 
     * @type {string}
     * @memberof IAvailableDates
     */
    instanceStatus: string;
    /**
     * 
     * @type {string}
     * @memberof IAvailableDates
     */
    instanceStartTime: string;
    /**
     * 
     * @type {number}
     * @memberof IAvailableDates
     */
    instancePrice: number;
    /**
     * 
     * @type {number}
     * @memberof IAvailableDates
     */
    instanceNumberOfSessions: number;
    /**
     * 
     * @type {string}
     * @memberof IAvailableDates
     */
    url: string;
}
/**
 * 
 * @export
 * @interface IBillingOrganisation
 */
export interface IBillingOrganisation {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<string>}
     * @memberof IBillingOrganisation
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {Array<BillingOrganisationPaymentMethodEnum>}
     * @memberof IBillingOrganisation
     */
    parentManualInvoicePaymentMethods?: Array<BillingOrganisationPaymentMethodEnum>;
    /**
     * 
     * @type {Array<BillingOrganisationPaymentMethodEnum>}
     * @memberof IBillingOrganisation
     */
    epManualInvoicePaymentMethods?: Array<BillingOrganisationPaymentMethodEnum>;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    registeredName?: string;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    registeredAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    uen?: string;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    swiftBicCode?: string;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    bankCode?: string;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    branchCode?: string;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    bankAddress?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IBillingOrganisation
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof IBillingOrganisation
     */
    organizationId?: string;
}
/**
 * 
 * @export
 * @interface IBrand
 */
export interface IBrand {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof IBrand
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof IBrand
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof IBrand
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IBrand
     */
    parentAppHelpScreenUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IBrand
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IBrand
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof IBrand
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof IBrand
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof IBrand
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof IBrand
     */
    postmarkServerToken?: string;
    /**
     * 
     * @type {string}
     * @memberof IBrand
     */
    senderEmail?: string;
}
/**
 * 
 * @export
 * @interface ICalendarCourseSession
 */
export interface ICalendarCourseSession {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ICalendarCourseSession
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof ICalendarCourseSession
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof ICalendarCourseSession
     */
    courseSlug: string;
    /**
     * 
     * @type {string}
     * @memberof ICalendarCourseSession
     */
    courseName: string;
    /**
     * 
     * @type {string}
     * @memberof ICalendarCourseSession
     */
    instanceReferenceId: string;
    /**
     * 
     * @type {string}
     * @memberof ICalendarCourseSession
     */
    providerName: string;
    /**
     * 
     * @type {string}
     * @memberof ICalendarCourseSession
     */
    subjectName: string;
    /**
     * 
     * @type {string}
     * @memberof ICalendarCourseSession
     */
    gradeLevelName: string;
    /**
     * 
     * @type {Array<DataForInsertTransactionDtoProvider>}
     * @memberof ICalendarCourseSession
     */
    students?: Array<DataForInsertTransactionDtoProvider>;
    /**
     * 
     * @type {string}
     * @memberof ICalendarCourseSession
     */
    coursePeriodColor: string;
}
/**
 * 
 * @export
 * @interface ICourse
 */
export interface ICourse {
    [key: string]: object | any;

    /**
     * 
     * @type {PartialEducationProviderAttributes}
     * @memberof ICourse
     */
    educationProvider?: PartialEducationProviderAttributes;
    /**
     * 
     * @type {PartialCoursePeriodAttributes}
     * @memberof ICourse
     */
    coursePeriod?: PartialCoursePeriodAttributes;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ICourse
     */
    courseNo?: number;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    gradeLevelId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    subjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    status?: ICourseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    providerId?: string;
    /**
     * 
     * @type {number}
     * @memberof ICourse
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof ICourse
     */
    maxAge?: number;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    updatedAt?: string;
    /**
     * 
     * @type {Array<string> | string}
     * @memberof ICourse
     */
    subjectType?: Array<string> | string;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    approverId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    referenceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    epCourseCode?: string;
    /**
     * 
     * @type {number}
     * @memberof ICourse
     */
    replacementExchangeAllowed?: number;
    /**
     * 
     * @type {number}
     * @memberof ICourse
     */
    replacementNoticePeriod?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    replacementAllowOverriding?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    replacementPenalty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    allowTrialSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    applyTrialSession?: ICourseApplyTrialSessionEnum;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    trialSessionPrice?: ICourseTrialSessionPriceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    isUnlimitedReplacement?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    coursePeriodId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    allowSignUpStartMidWay?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    allowConcurrentSessions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    approvedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    userApprovalId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    metaDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    metaKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    isAutoReEnrollStudent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    businessUnitId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    isSuppressCourseFeedback?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    allowMakeupSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICourse
     */
    applyMakeupSession?: ICourseApplyMakeupSessionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    sendCourseReminder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    hideTimingOptions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    requireCheckoutPicture?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    enableGuardianCheckoutOption?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ICourse
     */
    otherCheckoutOptions?: { [key: string]: object; };
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    requireCheckinTemperature?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    requireCheckinPicture?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICourse
     */
    enableGuardianCheckinOption?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ICourse
     */
    otherCheckinOptions?: { [key: string]: object; };
}

/**
    * @export
    * @enum {string}
    */
export enum ICourseStatusEnum {
    Approved = 'approved',
    Draft = 'draft',
    Inactive = 'inactive',
    PendingApproval = 'pending_approval'
}
/**
    * @export
    * @enum {string}
    */
export enum ICourseApplyTrialSessionEnum {
    AllSession = 'all_session',
    FirstSession = 'first_session'
}
/**
    * @export
    * @enum {string}
    */
export enum ICourseTrialSessionPriceEnum {
    Exclusive = 'exclusive',
    Inclusive = 'inclusive'
}
/**
    * @export
    * @enum {string}
    */
export enum ICourseApplyMakeupSessionEnum {
    AllSession = 'all_session',
    FirstSession = 'first_session'
}

/**
 * 
 * @export
 * @interface ICourseEnrolledStudent
 */
export interface ICourseEnrolledStudent {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseDto}
     * @memberof ICourseEnrolledStudent
     */
    course: CourseDto;
    /**
     * 
     * @type {ProviderDto}
     * @memberof ICourseEnrolledStudent
     */
    provider: ProviderDto;
    /**
     * 
     * @type {GstDto}
     * @memberof ICourseEnrolledStudent
     */
    gst: GstDto;
    /**
     * 
     * @type {Array<ICourseEnrolledStudentHistory>}
     * @memberof ICourseEnrolledStudent
     */
    courseEnrolledStudentHistories: Array<ICourseEnrolledStudentHistory>;
}
/**
 * 
 * @export
 * @interface ICourseEnrolledStudentHistory
 */
export interface ICourseEnrolledStudentHistory {
    [key: string]: object | any;

    /**
     * 
     * @type {UserDto}
     * @memberof ICourseEnrolledStudentHistory
     */
    parent?: UserDto;
    /**
     * 
     * @type {ChildDto}
     * @memberof ICourseEnrolledStudentHistory
     */
    student: ChildDto;
    /**
     * 
     * @type {Array<StudentSessionTimeCheckinDto>}
     * @memberof ICourseEnrolledStudentHistory
     */
    sessionTimes?: Array<StudentSessionTimeCheckinDto>;
    /**
     * 
     * @type {number}
     * @memberof ICourseEnrolledStudentHistory
     */
    sessionPackLeft?: number;
    /**
     * 
     * @type {ICourseEnrolment}
     * @memberof ICourseEnrolledStudentHistory
     */
    courseEnrolment: ICourseEnrolment;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICourseEnrolledStudentHistory
     */
    classes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ICourseEnrolledStudentHistory
     */
    paymentStatus: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseEnrolledStudentHistory
     */
    recurringPaymentStatus: string;
    /**
     * 
     * @type {Array<ICourseEnrolledStudentInvoice>}
     * @memberof ICourseEnrolledStudentHistory
     */
    invoices: Array<ICourseEnrolledStudentInvoice>;
    /**
     * 
     * @type {Array<SubscriptionDto>}
     * @memberof ICourseEnrolledStudentHistory
     */
    subscriptions: Array<SubscriptionDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ICourseEnrolledStudentHistory
     */
    isAllowWithdraw: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICourseEnrolledStudentHistory
     */
    isAllowCreateSubscription: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICourseEnrolledStudentHistory
     */
    isAllowEditSubscription: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICourseEnrolledStudentHistory
     */
    isAllowCancelEnrolment: boolean;
}
/**
 * 
 * @export
 * @interface ICourseEnrolledStudentInvoice
 */
export interface ICourseEnrolledStudentInvoice {
    [key: string]: object | any;

    /**
     * 
     * @type {InvoiceNewDto}
     * @memberof ICourseEnrolledStudentInvoice
     */
    invoice: InvoiceNewDto;
    /**
     * 
     * @type {CompanyDto}
     * @memberof ICourseEnrolledStudentInvoice
     */
    venue?: CompanyDto;
    /**
     * 
     * @type {SessionPackDto}
     * @memberof ICourseEnrolledStudentInvoice
     */
    sessionPack?: SessionPackDto;
    /**
     * 
     * @type {CourseSeriesDto}
     * @memberof ICourseEnrolledStudentInvoice
     */
    courseSeries?: CourseSeriesDto;
    /**
     * 
     * @type {string}
     * @memberof ICourseEnrolledStudentInvoice
     */
    instanceStart: string;
    /**
     * 
     * @type {CourseEnrolledStudentHistoryMethodEnum}
     * @memberof ICourseEnrolledStudentInvoice
     */
    method: CourseEnrolledStudentHistoryMethodEnum;
    /**
     * 
     * @type {CourseEnrolledStudentHistoryModelEnum}
     * @memberof ICourseEnrolledStudentInvoice
     */
    model: CourseEnrolledStudentHistoryModelEnum;
    /**
     * 
     * @type {CourseEnrolledStudentHistoryRefundStatusEnum}
     * @memberof ICourseEnrolledStudentInvoice
     */
    refundStatus?: CourseEnrolledStudentHistoryRefundStatusEnum;
}
/**
 * 
 * @export
 * @interface ICourseEnrolment
 */
export interface ICourseEnrolment {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ICourseEnrolment
     */
    id?: string;
    /**
     * 
     * @type {CourseEnrolmentStatus}
     * @memberof ICourseEnrolment
     */
    status?: CourseEnrolmentStatus;
    /**
     * 
     * @type {string}
     * @memberof ICourseEnrolment
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseEnrolment
     */
    lastDateWithdrawal?: string;
}
/**
 * 
 * @export
 * @interface ICourseFilter
 */
export interface ICourseFilter {
    [key: string]: object | any;

    /**
     * 
     * @type {ICourseInstance}
     * @memberof ICourseFilter
     */
    firstInstance?: ICourseInstance;
    /**
     * 
     * @type {ICourseFilterCourse}
     * @memberof ICourseFilter
     */
    course: ICourseFilterCourse;
    /**
     * 
     * @type {Array<IAvailableDates>}
     * @memberof ICourseFilter
     */
    otherAvailableDates: Array<IAvailableDates>;
    /**
     * 
     * @type {number}
     * @memberof ICourseFilter
     */
    numOfAvailableCourses?: number;
    /**
     * 
     * @type {ISessionPack}
     * @memberof ICourseFilter
     */
    sessionPack?: ISessionPack;
}
/**
 * 
 * @export
 * @interface ICourseFilterCourse
 */
export interface ICourseFilterCourse {
    /**
     * 
     * @type {ICourseFilterCourseProvider}
     * @memberof ICourseFilterCourse
     */
    provider: ICourseFilterCourseProvider;
    /**
     * 
     * @type {ICourseFilterCourseSubject}
     * @memberof ICourseFilterCourse
     */
    subject: ICourseFilterCourseSubject;
    /**
     * 
     * @type {ICourseFilterCourseGradeLevel}
     * @memberof ICourseFilterCourse
     */
    gradeLevel: ICourseFilterCourseGradeLevel;
    /**
     * 
     * @type {ICourseFilterCourseMedia}
     * @memberof ICourseFilterCourse
     */
    media?: ICourseFilterCourseMedia;
    /**
     * 
     * @type {ICourseFilterCourseCoursePeriod}
     * @memberof ICourseFilterCourse
     */
    coursePeriod: ICourseFilterCourseCoursePeriod;
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourse
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface ICourseFilterCourseCoursePeriod
 */
export interface ICourseFilterCourseCoursePeriod {
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourseCoursePeriod
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ICourseFilterCourseGradeLevel
 */
export interface ICourseFilterCourseGradeLevel {
    /**
     * 
     * @type {number}
     * @memberof ICourseFilterCourseGradeLevel
     */
    maxAge: number;
    /**
     * 
     * @type {number}
     * @memberof ICourseFilterCourseGradeLevel
     */
    minAge: number;
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourseGradeLevel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourseGradeLevel
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface ICourseFilterCourseMedia
 */
export interface ICourseFilterCourseMedia {
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourseMedia
     */
    url: string;
}
/**
 * 
 * @export
 * @interface ICourseFilterCourseProvider
 */
export interface ICourseFilterCourseProvider {
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourseProvider
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourseProvider
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourseProvider
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourseProvider
     */
    id: string;
}
/**
 * 
 * @export
 * @interface ICourseFilterCourseSubject
 */
export interface ICourseFilterCourseSubject {
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourseSubject
     */
    mediaUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourseSubject
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseFilterCourseSubject
     */
    id: string;
}
/**
 * 
 * @export
 * @interface ICourseInstance
 */
export interface ICourseInstance {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ICourseInstance
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseInstance
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseInstance
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseInstance
     */
    teacherId?: string;
    /**
     * 
     * @type {number}
     * @memberof ICourseInstance
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof ICourseInstance
     */
    numOfSeat?: number;
    /**
     * 
     * @type {string}
     * @memberof ICourseInstance
     */
    status?: ICourseInstanceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ICourseInstance
     */
    venueId?: string;
    /**
     * 
     * @type {ICourse}
     * @memberof ICourseInstance
     */
    course?: ICourse;
    /**
     * 
     * @type {PartialCompanyAttributes}
     * @memberof ICourseInstance
     */
    venue?: PartialCompanyAttributes;
    /**
     * 
     * @type {string}
     * @memberof ICourseInstance
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ICourseInstance
     */
    endTime?: string;
    /**
     * 
     * @type {PartialUserInfoAttributes}
     * @memberof ICourseInstance
     */
    teacher?: PartialUserInfoAttributes;
    /**
     * 
     * @type {number}
     * @memberof ICourseInstance
     */
    numberOfReserved?: number;
    /**
     * 
     * @type {number}
     * @memberof ICourseInstance
     */
    numberOfEnrolled?: number;
    /**
     * 
     * @type {number}
     * @memberof ICourseInstance
     */
    numberOfRemainingOfSeats?: number;
    /**
     * 
     * @type {PartialCoursePeriodAttributes}
     * @memberof ICourseInstance
     */
    coursePeriod?: PartialCoursePeriodAttributes;
}

/**
    * @export
    * @enum {string}
    */
export enum ICourseInstanceStatusEnum {
    Approved = 'approved',
    Cancelled = 'cancelled',
    Completed = 'completed',
    CurrentlyUnderway = 'currently_underway',
    Draft = 'draft',
    FullyBooked = 'fully_booked',
    PendingApproval = 'pending_approval'
}

/**
 * 
 * @export
 * @interface ICreateBrandResponse
 */
export interface ICreateBrandResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {IBrand}
     * @memberof ICreateBrandResponse
     */
    data: IBrand;
}
/**
 * 
 * @export
 * @interface ICreateUser
 */
export interface ICreateUser {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    fullname?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    gender?: ICreateUserGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    userType?: ICreateUserUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    otherUserType?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    parentRelationshipType?: ICreateUserParentRelationshipTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ICreateUser
     */
    isEmergencyContact?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICreateUser
     */
    isResendInvite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    brandId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    relationshipId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateUser
     */
    venueEmailSender?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ICreateUserGenderEnum {
    Female = 'female',
    Male = 'male',
    Other = 'other'
}
/**
    * @export
    * @enum {string}
    */
export enum ICreateUserUserTypeEnum {
    AgoraAdmin = 'agora_admin',
    Assistant = 'assistant',
    Brother = 'brother',
    BusinessDevelopment = 'business_development',
    Ceo = 'ceo',
    CurriculumDevelopment = 'curriculum_development',
    EpAdmin = 'ep_admin',
    EpAdminTeacher = 'ep_admin_teacher',
    Father = 'father',
    Finance = 'finance',
    Grandfather = 'grandfather',
    Grandmother = 'grandmother',
    Helper = 'helper',
    Mother = 'mother',
    Others = 'others',
    Owner = 'owner',
    Parent = 'parent',
    ProgramManager = 'program_manager',
    Sister = 'sister',
    Student = 'student',
    Teacher = 'teacher'
}
/**
    * @export
    * @enum {string}
    */
export enum ICreateUserParentRelationshipTypeEnum {
    Brother = 'brother',
    Father = 'father',
    Grandfather = 'grandfather',
    Grandmother = 'grandmother',
    Helper = 'helper',
    Mother = 'mother',
    Others = 'others',
    Sister = 'sister'
}

/**
 * 
 * @export
 * @interface IExportTeacherData
 */
export interface IExportTeacherData {
    [key: string]: object | any;

    /**
     * 
     * @type {PartialUserInfoAttributes & object}
     * @memberof IExportTeacherData
     */
    teacher: PartialUserInfoAttributes & object;
    /**
     * 
     * @type {ICourseInstance}
     * @memberof IExportTeacherData
     */
    courseInstance: ICourseInstance;
    /**
     * 
     * @type {ICourse}
     * @memberof IExportTeacherData
     */
    course: ICourse;
    /**
     * 
     * @type {string}
     * @memberof IExportTeacherData
     */
    qualificationMoeExpiryDate?: string;
}
/**
 * 
 * @export
 * @interface IExportTeacherDataResponse
 */
export interface IExportTeacherDataResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<IExportTeacherData>}
     * @memberof IExportTeacherDataResponse
     */
    data: Array<IExportTeacherData>;
}
/**
 * 
 * @export
 * @interface IGalleryPageContent
 */
export interface IGalleryPageContent {
    [key: string]: object | any;

    /**
     * 
     * @type {SEODataDto}
     * @memberof IGalleryPageContent
     */
    seo?: SEODataDto;
    /**
     * 
     * @type {EventHostingHeroSectionDto}
     * @memberof IGalleryPageContent
     */
    header?: EventHostingHeroSectionDto;
    /**
     * 
     * @type {string}
     * @memberof IGalleryPageContent
     */
    galleryBlockScript?: string;
    /**
     * 
     * @type {EventHostingContactDto}
     * @memberof IGalleryPageContent
     */
    contactForm?: EventHostingContactDto;
}
/**
 * 
 * @export
 * @interface IGetAllCoursesResponse
 */
export interface IGetAllCoursesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CourseDto>}
     * @memberof IGetAllCoursesResponse
     */
    data: Array<CourseDto>;
}
/**
 * 
 * @export
 * @interface IGetAllStudentsResponse
 */
export interface IGetAllStudentsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ChildDto>}
     * @memberof IGetAllStudentsResponse
     */
    data: Array<ChildDto>;
}
/**
 * 
 * @export
 * @interface IGetBrandDetailByIdResponse
 */
export interface IGetBrandDetailByIdResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {IBrand}
     * @memberof IGetBrandDetailByIdResponse
     */
    data: IBrand | null;
}
/**
 * 
 * @export
 * @interface IGetBrandsResponse
 */
export interface IGetBrandsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<IBrand>}
     * @memberof IGetBrandsResponse
     */
    data: Array<IBrand>;
}
/**
 * 
 * @export
 * @interface IGetCalendarCourseSessionsWithFilterResponse
 */
export interface IGetCalendarCourseSessionsWithFilterResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ICalendarCourseSession>}
     * @memberof IGetCalendarCourseSessionsWithFilterResponse
     */
    data: Array<ICalendarCourseSession>;
}
/**
 * 
 * @export
 * @interface IGetCourseListWithFilterRefactorResponse
 */
export interface IGetCourseListWithFilterRefactorResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {IGetCourseListWithFilterRefactorResponseData}
     * @memberof IGetCourseListWithFilterRefactorResponse
     */
    data: IGetCourseListWithFilterRefactorResponseData;
}
/**
 * 
 * @export
 * @interface IGetCourseListWithFilterRefactorResponseData
 */
export interface IGetCourseListWithFilterRefactorResponseData {
    /**
     * 
     * @type {number}
     * @memberof IGetCourseListWithFilterRefactorResponseData
     */
    total: number;
    /**
     * 
     * @type {Array<ICourseFilter>}
     * @memberof IGetCourseListWithFilterRefactorResponseData
     */
    courses: Array<ICourseFilter>;
}
/**
 * 
 * @export
 * @interface IGetGalleryPageContentResponse
 */
export interface IGetGalleryPageContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {IGalleryPageContent}
     * @memberof IGetGalleryPageContentResponse
     */
    data: IGalleryPageContent | null;
}
/**
 * 
 * @export
 * @interface IGetRegistrationLogResponse
 */
export interface IGetRegistrationLogResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {RegistrationLogDto}
     * @memberof IGetRegistrationLogResponse
     */
    data: RegistrationLogDto;
}
/**
 * 
 * @export
 * @interface IGetReservedSeatsResponse
 */
export interface IGetReservedSeatsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<IReservedSeat>}
     * @memberof IGetReservedSeatsResponse
     */
    data: Array<IReservedSeat>;
}
/**
 * 
 * @export
 * @interface IGetTeachersForExportFilters
 */
export interface IGetTeachersForExportFilters {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<string>}
     * @memberof IGetTeachersForExportFilters
     */
    courseInstanceIds: Array<string>;
}
/**
 * 
 * @export
 * @interface IModifiedEmailTemplate
 */
export interface IModifiedEmailTemplate {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof IModifiedEmailTemplate
     */
    brandId: string;
    /**
     * 
     * @type {string}
     * @memberof IModifiedEmailTemplate
     */
    templateAlias: string;
}
/**
 * 
 * @export
 * @interface IReservedSeat
 */
export interface IReservedSeat {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    expiredDate?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    expiredTime?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    status?: IReservedSeatStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof IReservedSeat
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    courseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    exclusiveSessionId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IReservedSeat
     */
    exclusiveSessionIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    remark?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IReservedSeat
     */
    isTrialSingleSession?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof IReservedSeat
     */
    courseSessionIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof IReservedSeat
     */
    confirmEnrollment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    postmarkMessageId?: string;
    /**
     * 
     * @type {ICourseInstance}
     * @memberof IReservedSeat
     */
    courseInstance?: ICourseInstance;
    /**
     * 
     * @type {PartialUserInfoAttributes}
     * @memberof IReservedSeat
     */
    parent?: PartialUserInfoAttributes;
    /**
     * 
     * @type {PartialUserInfoAttributes}
     * @memberof IReservedSeat
     */
    student?: PartialUserInfoAttributes;
    /**
     * 
     * @type {PartialUserInfoAttributes}
     * @memberof IReservedSeat
     */
    createdByUser?: PartialUserInfoAttributes;
    /**
     * 
     * @type {PartialUserInfoAttributes}
     * @memberof IReservedSeat
     */
    updatedByUser?: PartialUserInfoAttributes;
    /**
     * 
     * @type {PartialBookingAttributes}
     * @memberof IReservedSeat
     */
    booking?: PartialBookingAttributes;
    /**
     * 
     * @type {string}
     * @memberof IReservedSeat
     */
    reservedSeatLink?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum IReservedSeatStatusEnum {
    Canceled = 'canceled',
    Expired = 'expired',
    Open = 'open',
    Paid = 'paid'
}

/**
 * 
 * @export
 * @interface ISessionPack
 */
export interface ISessionPack {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ISessionPack
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ISessionPack
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof ISessionPack
     */
    numberOfSessions: number;
}
/**
 * 
 * @export
 * @interface ITicket
 */
export interface ITicket {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    title?: string;
    /**
     * 
     * @type {CourseDto}
     * @memberof ITicket
     */
    course?: CourseDto;
    /**
     * 
     * @type {ChildDto}
     * @memberof ITicket
     */
    student?: ChildDto;
    /**
     * 
     * @type {TicketStatusEnum}
     * @memberof ITicket
     */
    status?: TicketStatusEnum;
    /**
     * 
     * @type {TicketTypeEnum}
     * @memberof ITicket
     */
    type?: TicketTypeEnum;
    /**
     * 
     * @type {Array<ITicketComment>}
     * @memberof ITicket
     */
    ticketComments?: Array<ITicketComment>;
    /**
     * 
     * @type {ITicketComment}
     * @memberof ITicket
     */
    lastTicketComment?: ITicketComment;
    /**
     * 
     * @type {UserDto}
     * @memberof ITicket
     */
    lastUserComment?: UserDto;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof ITicket
     */
    assignedTeachers?: Array<UserDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ITicket
     */
    isRead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITicket
     */
    isAllowComment?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ITicket
     */
    courseNames?: Array<string>;
}
/**
 * 
 * @export
 * @interface ITicketComment
 */
export interface ITicketComment {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ITicketComment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ITicketComment
     */
    commentText?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof ITicketComment
     */
    user?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof ITicketComment
     */
    commentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ITicketComment
     */
    createdAt?: string;
    /**
     * 
     * @type {Array<MediaDto>}
     * @memberof ITicketComment
     */
    attachments?: Array<MediaDto>;
    /**
     * 
     * @type {string}
     * @memberof ITicketComment
     */
    ticketId?: string;
}
/**
 * 
 * @export
 * @interface ITicketFilter
 */
export interface ITicketFilter {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SelectOptionDto>}
     * @memberof ITicketFilter
     */
    statuses: Array<SelectOptionDto>;
    /**
     * 
     * @type {Array<SelectOptionDto>}
     * @memberof ITicketFilter
     */
    businessUnits: Array<SelectOptionDto>;
    /**
     * 
     * @type {Array<SelectOptionDto>}
     * @memberof ITicketFilter
     */
    ticketTeachers: Array<SelectOptionDto>;
}
/**
 * 
 * @export
 * @interface ImportEnrolmentDataDto
 */
export interface ImportEnrolmentDataDto {
    [key: string]: object | any;

    /**
     * 
     * @type {StudentImportDto}
     * @memberof ImportEnrolmentDataDto
     */
    student: StudentImportDto;
    /**
     * 
     * @type {ParentImportDto}
     * @memberof ImportEnrolmentDataDto
     */
    parent: ParentImportDto;
    /**
     * 
     * @type {GuardianImportDto}
     * @memberof ImportEnrolmentDataDto
     */
    guardian1?: GuardianImportDto;
    /**
     * 
     * @type {GuardianImportDto}
     * @memberof ImportEnrolmentDataDto
     */
    guardian2?: GuardianImportDto;
    /**
     * 
     * @type {ImportEnrolmentDto}
     * @memberof ImportEnrolmentDataDto
     */
    enrolment: ImportEnrolmentDto;
}
/**
 * 
 * @export
 * @interface ImportEnrolmentDto
 */
export interface ImportEnrolmentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ImportEnrolmentDto
     */
    halfOrFullDay: ImportEnrolmentDtoHalfOrFullDayEnum;
    /**
     * 
     * @type {string}
     * @memberof ImportEnrolmentDto
     */
    feesSchool: string;
    /**
     * 
     * @type {string}
     * @memberof ImportEnrolmentDto
     */
    feesSchoolSubsidy?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportEnrolmentDto
     */
    feesAftercare?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportEnrolmentDto
     */
    feesBus?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportEnrolmentDto
     */
    feesMeals?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ImportEnrolmentDtoHalfOrFullDayEnum {
    HalfDay = 'half day',
    FullDay = 'full day'
}

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject
     */
    instanceIds: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    providerId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    venueId: string;
}
/**
 * 
 * @export
 * @interface InlineObject10
 */
export interface InlineObject10 {
    /**
     * 
     * @type {Array<RevenueRecognitionDto>}
     * @memberof InlineObject10
     */
    revenues: Array<RevenueRecognitionDto>;
}
/**
 * 
 * @export
 * @interface InlineObject12
 */
export interface InlineObject12 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject12
     */
    segmentId: string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    subscriptionId: string;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    courseSeriesId?: string;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    studentId: string;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    userIdRequest: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    invoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    courseInstanceId: string;
}
/**
 * 
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject6
     */
    referenceIds: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineObject7
 */
export interface InlineObject7 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject7
     */
    availableHomepage: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject8
 */
export interface InlineObject8 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject8
     */
    email: string;
}
/**
 * 
 * @export
 * @interface InlineObject9
 */
export interface InlineObject9 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    reserveId: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    csvContent: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    fileName: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {Array<ICourseInstance>}
     * @memberof InlineResponse2001
     */
    data: Array<ICourseInstance>;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    data: string;
}
/**
 * 
 * @export
 * @interface InviteGuardianResponse
 */
export interface InviteGuardianResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {UserDto}
     * @memberof InviteGuardianResponse
     */
    data: UserDto | null;
}
/**
 * 
 * @export
 * @interface InvoiceDto
 */
export interface InvoiceDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    userEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    userAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    invoiceDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string | number}
     * @memberof InvoiceDto
     */
    total?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof InvoiceDto
     */
    amountLeftToPay?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof InvoiceDto
     */
    discount?: string | number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    sentEPDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    expiredDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    revenueRecognitionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    collectionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    overdueBy?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    paidAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    lastStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    dueAmount?: number;
    /**
     * 
     * @type {Array<InvoiceLineDto>}
     * @memberof InvoiceDto
     */
    invoiceLines?: Array<InvoiceLineDto>;
    /**
     * 
     * @type {ProviderDto}
     * @memberof InvoiceDto
     */
    provider?: ProviderDto;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    subTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    gst?: number;
    /**
     * 
     * @type {Array<TransactionDto>}
     * @memberof InvoiceDto
     */
    transactions?: Array<TransactionDto>;
    /**
     * 
     * @type {CompanyDto}
     * @memberof InvoiceDto
     */
    venueFrom?: CompanyDto;
    /**
     * 
     * @type {CompanyDto}
     * @memberof InvoiceDto
     */
    venueTo?: CompanyDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    invoiceUrl?: string;
    /**
     * 
     * @type {Array<AuditHistoryDto>}
     * @memberof InvoiceDto
     */
    invoiceActivities?: Array<AuditHistoryDto>;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    paymentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    paymentDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    gstConfigAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDto
     */
    isRoomBookingInvoice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDto
     */
    isInvoiceProduct?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    invoiceTo?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    invoiceToUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    sentDate?: string;
    /**
     * 
     * @type {ProviderDto | UserDto}
     * @memberof InvoiceDto
     */
    invoiceToObj?: ProviderDto | UserDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    notes?: string;
    /**
     * 
     * @type {Array<MediaDto>}
     * @memberof InvoiceDto
     */
    invoiceMedia?: Array<MediaDto>;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    userCardId?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    usedCredit?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    balanceDue?: number;
    /**
     * 
     * @type {UserDto}
     * @memberof InvoiceDto
     */
    createdBy?: UserDto;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDto
     */
    isCreatedFromWebsite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    subscriptionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDto
     */
    isAppliedDiscount?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    parentInvoiceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    parentEmailAddress?: string;
    /**
     * 
     * @type {Array<BillingOrganisationPaymentMethodEnum>}
     * @memberof InvoiceDto
     */
    paymentMethods?: Array<BillingOrganisationPaymentMethodEnum>;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof InvoiceDto
     */
    bankDetails?: { [key: string]: any; };
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDto
     */
    isShowBankDetails?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDto
     */
    isShowPaynowDirect?: boolean;
    /**
     * 
     * @type {SubscriptionDto}
     * @memberof InvoiceDto
     */
    recurringPayment?: SubscriptionDto;
}
/**
 * 
 * @export
 * @interface InvoiceLineAttributes
 */
export interface InvoiceLineAttributes {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    invoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    productType?: InvoiceLineAttributesProductTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    enrollmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    agoraCareId?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineAttributes
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineAttributes
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    referenceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLineAttributes
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLineAttributes
     */
    isGst?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    subscriptionId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    campaignHistoryId?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineAttributes
     */
    gstPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    sessionPackAdditionId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    roomId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    productCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    discountId?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineAttributes
     */
    discountAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    discountType?: InvoiceLineAttributesDiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineAttributes
     */
    discountInput?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLineAttributes
     */
    isSuppressCourseConfirmationEmail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    status?: InvoiceLineAttributesStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    sessionPackId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineAttributes
     */
    sessionPackStartDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineAttributes
     */
    unitPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineAttributes
     */
    nett?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineAttributes
     */
    gstAmount?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum InvoiceLineAttributesProductTypeEnum {
    AgoraCare = 'agora_care',
    Course = 'course',
    Other = 'other',
    RoomBooking = 'room_booking',
    SaveCard = 'save-card',
    SessionPack = 'session_pack',
    Subscription = 'subscription'
}
/**
    * @export
    * @enum {string}
    */
export enum InvoiceLineAttributesDiscountTypeEnum {
    Amount = 'amount',
    Percent = 'percent'
}
/**
    * @export
    * @enum {string}
    */
export enum InvoiceLineAttributesStatusEnum {
    Refunded = 'refunded'
}

/**
 * 
 * @export
 * @interface InvoiceLineDto
 */
export interface InvoiceLineDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    invoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    invoiceReferenceId?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    invoiceTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    invoiceStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    invoiceCreatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    invoiceSentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    invoiceExpiredDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    courseReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    courseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    agoraCareReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    invoiceLineId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    agoraName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    providerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    timeStart?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    timeEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    dayStart?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    dayEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    hourStart?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    hourEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    description?: string;
    /**
     * 
     * @type {string | number}
     * @memberof InvoiceLineDto
     */
    quantity?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof InvoiceLineDto
     */
    unitPrice?: string | number;
    /**
     * 
     * @type {GstDto}
     * @memberof InvoiceLineDto
     */
    gst?: GstDto;
    /**
     * 
     * @type {string | number}
     * @memberof InvoiceLineDto
     */
    gstAmount?: string | number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    total?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    createdDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    coursePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    agoraPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLineDto
     */
    isShowBtnCalendar?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    subjectName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    subjectType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLineDto
     */
    isAgoraSubscription?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    discount?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    sessionPackId?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    sessionPackNumberOfSessions?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    sessionPackValidForMonth?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    sessionPackStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    numberOfSessionsText?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    validForMonthText?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    studentFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    venueName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    venueLegalName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    venueAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    venueGstNo?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    productType?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLineDto
     */
    isGst?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    gstPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    taxCode?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    discountId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    discountName?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    discountAmount?: number;
    /**
     * 
     * @type {DiscountType}
     * @memberof InvoiceLineDto
     */
    discountType?: DiscountType;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    discountInput?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    discountLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    productCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    studentId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceLineDto
     */
    courseCourseSessionIds?: Array<string>;
    /**
     * 
     * @type {Array<CourseSessionDto>}
     * @memberof InvoiceLineDto
     */
    selectedCourseSessions?: Array<CourseSessionDto>;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    courseSeriesId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    enrollmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    reservedId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    invoiceProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    invoiceProviderReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    invoiceParentName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    invoiceParentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    invoiceParentReferenceId?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    invoicePaidAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    invoiceTransactionAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLineDto
     */
    isSuppressCourseConfirmationEmail?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    invoiceCampaignPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    nett?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    gstValue?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    discountValue?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    unitPrice2?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    nett2?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    gstAmount2?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    amount2?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    status?: InvoiceLineDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    overdueBy?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    invoiceTo?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    invoiceToUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    numOfRows?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    courseInstanceVenueId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InvoiceLineDtoStatusEnum {
    Refunded = 'refunded'
}

/**
 * 
 * @export
 * @interface InvoiceLineNewDto
 */
export interface InvoiceLineNewDto {
    [key: string]: object | any;

    /**
     * 
     * @type {InvoiceNewDto}
     * @memberof InvoiceLineNewDto
     */
    invoice?: InvoiceNewDto;
    /**
     * 
     * @type {UserDto}
     * @memberof InvoiceLineNewDto
     */
    parent?: UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof InvoiceLineNewDto
     */
    student?: UserDto;
    /**
     * 
     * @type {ProviderDto}
     * @memberof InvoiceLineNewDto
     */
    provider?: ProviderDto;
    /**
     * 
     * @type {BusinessUnitDto}
     * @memberof InvoiceLineNewDto
     */
    businessUnit?: BusinessUnitDto;
    /**
     * 
     * @type {ProductDto}
     * @memberof InvoiceLineNewDto
     */
    product?: ProductDto;
    /**
     * 
     * @type {ProductTypeDto}
     * @memberof InvoiceLineNewDto
     */
    productType?: ProductTypeDto;
    /**
     * 
     * @type {DiscountDto}
     * @memberof InvoiceLineNewDto
     */
    discount?: DiscountDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    referenceId?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineNewDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineNewDto
     */
    quantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLineNewDto
     */
    isGst?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineNewDto
     */
    gstPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineNewDto
     */
    numOfRows?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    rescheduleCourseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    sessionPackAdditionId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    reservedId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    enrollmentStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    courseEnrolmentStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    enrolmentStartDate?: string;
    /**
     * 
     * @type {InvoiceLineNewDtoRefundInfo}
     * @memberof InvoiceLineNewDto
     */
    refundInfo?: InvoiceLineNewDtoRefundInfo;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    enrollmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    instanceStart?: string;
    /**
     * 
     * @type {InvoiceLineNewDtoEnrolmentData}
     * @memberof InvoiceLineNewDto
     */
    enrolmentData?: InvoiceLineNewDtoEnrolmentData;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLineNewDto
     */
    isProductInvoiceLine?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDto
     */
    venueId?: string;
}
/**
 * 
 * @export
 * @interface InvoiceLineNewDtoEnrolmentData
 */
export interface InvoiceLineNewDtoEnrolmentData {
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDtoEnrolmentData
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDtoEnrolmentData
     */
    startDate: string;
}
/**
 * 
 * @export
 * @interface InvoiceLineNewDtoRefundInfo
 */
export interface InvoiceLineNewDtoRefundInfo {
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDtoRefundInfo
     */
    method: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineNewDtoRefundInfo
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineNewDtoRefundInfo
     */
    isRefunded: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum InvoiceListType {
    Provider = 'provider',
    Parent = 'parent'
}

/**
 * 
 * @export
 * @interface InvoiceNewDto
 */
export interface InvoiceNewDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDto
     */
    referenceId?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceNewDto
     */
    total?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDto
     */
    sentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDto
     */
    expiredDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceNewDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceNewDto
     */
    paidAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceNewDto
     */
    transactionAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceNewDto
     */
    campaignPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDto
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDto
     */
    toUrl?: string;
    /**
     * 
     * @type {InvoiceNewDtoInvoiceToData}
     * @memberof InvoiceNewDto
     */
    invoiceToData?: InvoiceNewDtoInvoiceToData;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDto
     */
    revenueRecognitionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDto
     */
    paymentDate?: string;
    /**
     * 
     * @type {CourseInstanceDto}
     * @memberof InvoiceNewDto
     */
    courseInstance?: CourseInstanceDto;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceNewDto
     */
    isRoomBookingInvoice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDto
     */
    overdueBy?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceNewDto
     */
    dueAmount?: number;
    /**
     * 
     * @type {UserDto}
     * @memberof InvoiceNewDto
     */
    parent?: UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof InvoiceNewDto
     */
    student?: UserDto;
    /**
     * 
     * @type {ProviderDto}
     * @memberof InvoiceNewDto
     */
    provider?: ProviderDto;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceNewDto
     */
    isProviderInvoice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDto
     */
    method?: string;
    /**
     * 
     * @type {Array<InvoiceLineNewDto>}
     * @memberof InvoiceNewDto
     */
    invoiceLines?: Array<InvoiceLineNewDto>;
    /**
     * 
     * @type {CompanyDto}
     * @memberof InvoiceNewDto
     */
    venue?: CompanyDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDto
     */
    refundStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceNewDto
     */
    refundAmount?: number;
    /**
     * 
     * @type {SubscriptionDto}
     * @memberof InvoiceNewDto
     */
    recurringPayment?: SubscriptionDto;
}
/**
 * 
 * @export
 * @interface InvoiceNewDtoInvoiceToData
 */
export interface InvoiceNewDtoInvoiceToData {
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDtoInvoiceToData
     */
    type: InvoiceNewDtoInvoiceToDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDtoInvoiceToData
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNewDtoInvoiceToData
     */
    name: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InvoiceNewDtoInvoiceToDataTypeEnum {
    Parent = 'parent',
    Provider = 'provider'
}

/**
 * 
 * @export
 * @interface InvoicePdfDto
 */
export interface InvoicePdfDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof InvoicePdfDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof InvoicePdfDto
     */
    fileName: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum InvoiceProductType {
    Course = 'course',
    AgoraCare = 'agora_care',
    Subscription = 'subscription',
    SaveCard = 'save-card',
    SessionPack = 'session_pack',
    RoomBooking = 'room_booking',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface InvoiceResponse
 */
export interface InvoiceResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {InvoiceResponseData}
     * @memberof InvoiceResponse
     */
    data?: InvoiceResponseData;
}
/**
 * 
 * @export
 * @interface InvoiceResponseData
 */
export interface InvoiceResponseData {
    /**
     * 
     * @type {CompanyDto}
     * @memberof InvoiceResponseData
     */
    company: CompanyDto;
    /**
     * 
     * @type {Array<InvoiceLineDto>}
     * @memberof InvoiceResponseData
     */
    invoiceLines: Array<InvoiceLineDto>;
    /**
     * 
     * @type {InvoiceDto}
     * @memberof InvoiceResponseData
     */
    invoice: InvoiceDto;
}
/**
 * 
 * @export
 * @interface ItemSelectDto
 */
export interface ItemSelectDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ItemSelectDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof ItemSelectDto
     */
    label: string;
}
/**
 * 
 * @export
 * @interface JoinUsOpportunityDto
 */
export interface JoinUsOpportunityDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof JoinUsOpportunityDto
     */
    position: string;
    /**
     * 
     * @type {string}
     * @memberof JoinUsOpportunityDto
     */
    quantity: string;
    /**
     * 
     * @type {string}
     * @memberof JoinUsOpportunityDto
     */
    notionUrl: string;
    /**
     * 
     * @type {string}
     * @memberof JoinUsOpportunityDto
     */
    notionText: string;
}
/**
 * 
 * @export
 * @interface JoinUsPageContentDto
 */
export interface JoinUsPageContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<JoinUsOpportunityDto>}
     * @memberof JoinUsPageContentDto
     */
    opportunityList: Array<JoinUsOpportunityDto>;
    /**
     * 
     * @type {Array<JoinUsReasonDto>}
     * @memberof JoinUsPageContentDto
     */
    reasonList: Array<JoinUsReasonDto>;
    /**
     * 
     * @type {Array<JoinUsSliderDto>}
     * @memberof JoinUsPageContentDto
     */
    sliderList: Array<JoinUsSliderDto>;
}
/**
 * 
 * @export
 * @interface JoinUsReasonDto
 */
export interface JoinUsReasonDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof JoinUsReasonDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof JoinUsReasonDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof JoinUsReasonDto
     */
    image: string;
}
/**
 * 
 * @export
 * @interface JoinUsSliderDto
 */
export interface JoinUsSliderDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof JoinUsSliderDto
     */
    backgroundColor: string;
    /**
     * 
     * @type {string}
     * @memberof JoinUsSliderDto
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof JoinUsSliderDto
     */
    activeColor: string;
    /**
     * 
     * @type {string}
     * @memberof JoinUsSliderDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof JoinUsSliderDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof JoinUsSliderDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof JoinUsSliderDto
     */
    position: string;
    /**
     * 
     * @type {string}
     * @memberof JoinUsSliderDto
     */
    avatar: string;
}
/**
 * 
 * @export
 * @interface KisiConfigurationDto
 */
export interface KisiConfigurationDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof KisiConfigurationDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof KisiConfigurationDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof KisiConfigurationDto
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof KisiConfigurationDto
     */
    requestExpirySeconds?: number;
}
/**
 * 
 * @export
 * @interface LandingMessageDto
 */
export interface LandingMessageDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof LandingMessageDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingMessageDto
     */
    backgroundImage?: string;
}
/**
 * 
 * @export
 * @interface ListViewsDto
 */
export interface ListViewsDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ListViewsDto
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof ListViewsDto
     */
    childrenId?: string;
    /**
     * 
     * @type {string}
     * @memberof ListViewsDto
     */
    childrenName?: string;
    /**
     * 
     * @type {string}
     * @memberof ListViewsDto
     */
    ageGroupName?: string;
    /**
     * 
     * @type {Array<CourseFilterDto>}
     * @memberof ListViewsDto
     */
    courseInstances?: Array<CourseFilterDto>;
    /**
     * 
     * @type {string}
     * @memberof ListViewsDto
     */
    oneInvoiceLineId?: string;
}
/**
 * 
 * @export
 * @interface LogEventRequestDto
 */
export interface LogEventRequestDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof LogEventRequestDto
     */
    logLevel: LogEventRequestDtoLogLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof LogEventRequestDto
     */
    platform?: string;
    /**
     * 
     * @type {string}
     * @memberof LogEventRequestDto
     */
    appVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof LogEventRequestDto
     */
    appBuild?: string;
    /**
     * 
     * @type {any}
     * @memberof LogEventRequestDto
     */
    context?: any | null;
    /**
     * 
     * @type {string}
     * @memberof LogEventRequestDto
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof LogEventRequestDto
     */
    contextCode?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LogEventRequestDtoLogLevelEnum {
    Info = 'info',
    Error = 'error'
}

/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    platform: LoginRequestPlatformEnum;
    /**
     * 
     * @type {MobileType}
     * @memberof LoginRequest
     */
    mobileType?: MobileType;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    venueSlug?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LoginRequestPlatformEnum {
    Web = 'web',
    Mobile = 'mobile',
    AdminPortal = 'admin-portal'
}

/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    sub?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    refreshToken?: string;
    /**
     * 
     * @type {GetCurrentUserResponse}
     * @memberof LoginResponse
     */
    userInfo?: GetCurrentUserResponse;
}
/**
 * 
 * @export
 * @interface MailDomain
 */
export interface MailDomain {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof MailDomain
     */
    name: string;
}
/**
 * 
 * @export
 * @interface MediaDto
 */
export interface MediaDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof MediaDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDto
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaDto
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDto
     */
    thumbnailMediaId?: string;
    /**
     * 
     * @type {MediaDto}
     * @memberof MediaDto
     */
    thumbnailMedium?: MediaDto;
    /**
     * 
     * @type {string}
     * @memberof MediaDto
     */
    thumbnailUrl?: string;
}
/**
 * 
 * @export
 * @interface MessageDto
 */
export interface MessageDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    fromId?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    toId?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    studentNames?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    toGroupId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MessageDto
     */
    userSeenMessage?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    fromUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    groupId?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    messageTime?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    contentType?: MessageDtoContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    fileName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MessageDtoContentTypeEnum {
    Text = 'text',
    Image = 'image',
    Document = 'document'
}

/**
 * 
 * @export
 * @interface MessageGroupDto
 */
export interface MessageGroupDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof MessageGroupDto
     */
    messageGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageGroupDto
     */
    messageGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageGroupDto
     */
    avatar?: string;
}
/**
 * 
 * @export
 * @interface MessageNewDto
 */
export interface MessageNewDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof MessageNewDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageNewDto
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageNewDto
     */
    sentOn?: string;
    /**
     * 
     * @type {MessageSentTo}
     * @memberof MessageNewDto
     */
    sentTo?: MessageSentTo;
    /**
     * 
     * @type {string}
     * @memberof MessageNewDto
     */
    sentBy?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageNewDto
     */
    sentByAvatar?: string;
    /**
     * 
     * @type {MessageNewStatus}
     * @memberof MessageNewDto
     */
    status?: MessageNewStatus;
    /**
     * 
     * @type {string}
     * @memberof MessageNewDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageNewDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageNewDto
     */
    numbersOfSent?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageNewDto
     */
    numbersOfRead?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageNewDto
     */
    numbersOfShared?: number;
    /**
     * 
     * @type {Array<MediaDto>}
     * @memberof MessageNewDto
     */
    media?: Array<MediaDto>;
    /**
     * 
     * @type {string}
     * @memberof MessageNewDto
     */
    richTextContent?: string;
    /**
     * 
     * @type {Array<RecipientDto>}
     * @memberof MessageNewDto
     */
    recipients?: Array<RecipientDto>;
    /**
     * 
     * @type {string}
     * @memberof MessageNewDto
     */
    categoryParentMessageId?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageNewDto
     */
    categoryParentMessageName?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageNewDto
     */
    descriptionSentTo?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MessageNewDto
     */
    courseClassIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MessageNewDto
     */
    courseInstanceIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MessageNewDto
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MessageNewDto
     */
    userSent?: string;
    /**
     * 
     * @type {MessageNewTypeEnum}
     * @memberof MessageNewDto
     */
    type?: MessageNewTypeEnum;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MessageNewStatus {
    Draft = 'draft',
    Sent = 'sent',
    Processing = 'processing',
    Deleted = 'deleted'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum MessageNewTypeEnum {
    Update = 'update',
    Pdf = 'pdf'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum MessageRecipientStatus {
    Pending = 'pending',
    Sent = 'sent',
    Read = 'read'
}

/**
 * 
 * @export
 * @interface MessageSendingResponse
 */
export interface MessageSendingResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof MessageSendingResponse
     */
    ErrorCode: number;
    /**
     * 
     * @type {string}
     * @memberof MessageSendingResponse
     */
    Message: string;
    /**
     * 
     * @type {string}
     * @memberof MessageSendingResponse
     */
    To?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageSendingResponse
     */
    Cc?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageSendingResponse
     */
    Bcc?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageSendingResponse
     */
    SubmittedAt: string;
    /**
     * 
     * @type {string}
     * @memberof MessageSendingResponse
     */
    MessageID?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MessageSentTo {
    Parent = 'parent',
    Class = 'class',
    CourseInstance = 'course_instance',
    BusinessUnit = 'business_unit',
    Venue = 'venue',
    AllAgoraVenues = 'all_agora_venues',
    AllAtSchoolVenues = 'all_at_school_venues'
}

/**
 * 
 * @export
 * @interface MetaDto
 */
export interface MetaDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof MetaDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaDto
     */
    categories?: string;
}
/**
 * 
 * @export
 * @interface MigrateParentEmergencyContactRequest
 */
export interface MigrateParentEmergencyContactRequest {
    /**
     * 
     * @type {string}
     * @memberof MigrateParentEmergencyContactRequest
     */
    emergencyEmail: string;
    /**
     * 
     * @type {string}
     * @memberof MigrateParentEmergencyContactRequest
     */
    emergencyName: string;
    /**
     * 
     * @type {string}
     * @memberof MigrateParentEmergencyContactRequest
     */
    parentEmail: string;
}
/**
 * 
 * @export
 * @interface MixpanelDto
 */
export interface MixpanelDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof MixpanelDto
     */
    time: string;
    /**
     * 
     * @type {string}
     * @memberof MixpanelDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof MixpanelDto
     */
    currentUrl: string;
    /**
     * 
     * @type {string}
     * @memberof MixpanelDto
     */
    currentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof MixpanelDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof MixpanelDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof MixpanelDto
     */
    os?: string;
    /**
     * 
     * @type {string}
     * @memberof MixpanelDto
     */
    browser?: string;
    /**
     * 
     * @type {MixpanelDtoCourseInfo}
     * @memberof MixpanelDto
     */
    courseInfo?: MixpanelDtoCourseInfo;
}
/**
 * 
 * @export
 * @interface MixpanelDtoCourseInfo
 */
export interface MixpanelDtoCourseInfo {
    /**
     * 
     * @type {string}
     * @memberof MixpanelDtoCourseInfo
     */
    subjectArea: string;
    /**
     * 
     * @type {string}
     * @memberof MixpanelDtoCourseInfo
     */
    subjectName: string;
    /**
     * 
     * @type {number}
     * @memberof MixpanelDtoCourseInfo
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof MixpanelDtoCourseInfo
     */
    providerName: string;
    /**
     * 
     * @type {string}
     * @memberof MixpanelDtoCourseInfo
     */
    courseType: string;
    /**
     * 
     * @type {string}
     * @memberof MixpanelDtoCourseInfo
     */
    courseName: string;
    /**
     * 
     * @type {string}
     * @memberof MixpanelDtoCourseInfo
     */
    age: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MobilePlatformType {
    Parent = 'parent',
    Teacher = 'teacher',
    AdminWeb = 'admin_web'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum MobileType {
    Teacher = 'teacher',
    Parent = 'parent'
}

/**
 * 
 * @export
 * @interface MorningPassPageCardDto
 */
export interface MorningPassPageCardDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof MorningPassPageCardDto
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof MorningPassPageCardDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof MorningPassPageCardDto
     */
    backgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof MorningPassPageCardDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MorningPassPageCardDto
     */
    buttonLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof MorningPassPageCardDto
     */
    buttonColor?: string;
}
/**
 * 
 * @export
 * @interface MorningPassPageContentDto
 */
export interface MorningPassPageContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof MorningPassPageContentDto
     */
    heading?: string;
    /**
     * 
     * @type {string}
     * @memberof MorningPassPageContentDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MorningPassPageContentDto
     */
    imageBoxShadowColor?: string;
    /**
     * 
     * @type {string}
     * @memberof MorningPassPageContentDto
     */
    image?: string;
}
/**
 * 
 * @export
 * @interface MorningPassPageDecorationDto
 */
export interface MorningPassPageDecorationDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof MorningPassPageDecorationDto
     */
    headerTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof MorningPassPageDecorationDto
     */
    headerSubTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof MorningPassPageDecorationDto
     */
    headerBackgroundColor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MorningPassPageDecorationDto
     */
    showFAQ?: boolean;
}
/**
 * 
 * @export
 * @interface MorningPassPagePopupDto
 */
export interface MorningPassPagePopupDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof MorningPassPagePopupDto
     */
    heading?: string;
    /**
     * 
     * @type {string}
     * @memberof MorningPassPagePopupDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MorningPassPagePopupDto
     */
    image?: string;
    /**
     * 
     * @type {MorningPassPagePopupDtoLink}
     * @memberof MorningPassPagePopupDto
     */
    link?: MorningPassPagePopupDtoLink;
}
/**
 * 
 * @export
 * @interface MorningPassPagePopupDtoLink
 */
export interface MorningPassPagePopupDtoLink {
    /**
     * 
     * @type {string}
     * @memberof MorningPassPagePopupDtoLink
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof MorningPassPagePopupDtoLink
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface NotificationAttributes
 */
export interface NotificationAttributes {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof NotificationAttributes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAttributes
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAttributes
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAttributes
     */
    notifyType: NotificationAttributesNotifyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationAttributes
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAttributes
     */
    status?: NotificationAttributesStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAttributes
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationAttributes
     */
    isDeleted?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationAttributesNotifyTypeEnum {
    Attendance = 'attendance',
    Expire = 'expire',
    OnPrem = 'on_prem',
    Payment = 'payment',
    Reminder = 'reminder',
    Replacement = 'replacement',
    Reward = 'reward',
    Withdraw = 'withdraw'
}
/**
    * @export
    * @enum {string}
    */
export enum NotificationAttributesStatusEnum {
    New = 'new',
    Seen = 'seen'
}

/**
 * 
 * @export
 * @interface NotificationConfigDto
 */
export interface NotificationConfigDto {
    [key: string]: object | any;

    /**
     * 
     * @type {NotificationStatusEnum}
     * @memberof NotificationConfigDto
     */
    onPremStatus: NotificationStatusEnum;
    /**
     * 
     * @type {NotificationStatusEnum}
     * @memberof NotificationConfigDto
     */
    attendanceStatus: NotificationStatusEnum;
    /**
     * 
     * @type {NotificationStatusEnum}
     * @memberof NotificationConfigDto
     */
    scheduledClass: NotificationStatusEnum;
    /**
     * 
     * @type {NotificationStatusEnum}
     * @memberof NotificationConfigDto
     */
    dailySchedule: NotificationStatusEnum;
    /**
     * 
     * @type {NotificationStatusEnum}
     * @memberof NotificationConfigDto
     */
    weeklySchedule: NotificationStatusEnum;
    /**
     * 
     * @type {NotificationStatusEnum}
     * @memberof NotificationConfigDto
     */
    payment: NotificationStatusEnum;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    notifyType: NotificationDtoNotifyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    status: NotificationDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    updatedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationDtoNotifyTypeEnum {
    OnPrem = 'on_prem',
    Attendance = 'attendance',
    Payment = 'payment',
    Reminder = 'reminder'
}
/**
    * @export
    * @enum {string}
    */
export enum NotificationDtoStatusEnum {
    New = 'new',
    Seen = 'seen'
}

/**
 * 
 * @export
 * @interface NotificationEmailDto
 */
export interface NotificationEmailDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof NotificationEmailDto
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailDto
     */
    parentName: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailDto
     */
    atSchoolVenueSupportEmail?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum NotificationStatusEnum {
    SmsEmail = 'sms_email',
    Email = 'email',
    Sms = 'sms',
    None = 'none'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ObjectAuditHistory {
    Invoice = 'invoice',
    Subscription = 'subscription',
    Student = 'student',
    Parent = 'parent',
    Message = 'message'
}

/**
 * 
 * @export
 * @interface PaginationDto
 */
export interface PaginationDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof PaginationDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationDto
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationDto
     */
    pageCount: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ParentDto
 */
export interface ParentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    fullname?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    userType?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    otherUserType?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    grade?: string;
    /**
     * 
     * @type {number}
     * @memberof ParentDto
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof ParentDto
     */
    maxAge?: number;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    parentRelationShipType?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    gender?: ParentDtoGenderEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParentDto
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParentDto
     */
    venueNames?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ParentDto
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParentDto
     */
    kisiUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParentDto
     */
    isInvited?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    invoiceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    invoiceEmailAddress?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParentDto
     */
    isEmergencyContact?: boolean;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof ParentDto
     */
    venues?: Array<CompanyDto>;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    tempPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDto
     */
    postCode?: string;
    /**
     * 
     * @type {Array<IBrand>}
     * @memberof ParentDto
     */
    brands?: Array<IBrand>;
    /**
     * 
     * @type {Array<ChildDto>}
     * @memberof ParentDto
     */
    children?: Array<ChildDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum ParentDtoGenderEnum {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface ParentImportDto
 */
export interface ParentImportDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ParentImportDto
     */
    parentFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof ParentImportDto
     */
    parentLastName: string;
    /**
     * 
     * @type {string}
     * @memberof ParentImportDto
     */
    parentEmail: string;
    /**
     * 
     * @type {string}
     * @memberof ParentImportDto
     */
    parentPhone: string;
    /**
     * 
     * @type {string}
     * @memberof ParentImportDto
     */
    parentHomeAddress: string;
    /**
     * 
     * @type {string}
     * @memberof ParentImportDto
     */
    parentInvoiceEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentImportDto
     */
    parentInvoiceAddress?: string;
}
/**
 * 
 * @export
 * @interface ParentPhotoUpdateDto
 */
export interface ParentPhotoUpdateDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ParentPhotoUpdateDto
     */
    messageId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentPhotoUpdateDto
     */
    parentId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParentPhotoUpdateDto
     */
    studentIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ParentPhotoUpdateDto
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentPhotoUpdateDto
     */
    sentOn?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentPhotoUpdateDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentPhotoUpdateDto
     */
    mediaType?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentPhotoUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentPhotoUpdateDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {MessageRecipientStatus}
     * @memberof ParentPhotoUpdateDto
     */
    messageRecipientStatus?: MessageRecipientStatus;
    /**
     * 
     * @type {string}
     * @memberof ParentPhotoUpdateDto
     */
    categoryParentMessageId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentPhotoUpdateDto
     */
    categoryParentMessageName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentPhotoUpdateDto
     */
    richTextContent?: string;
    /**
     * 
     * @type {MessageNewTypeEnum}
     * @memberof ParentPhotoUpdateDto
     */
    type?: MessageNewTypeEnum;
}
/**
 * 
 * @export
 * @interface ParentsStudentsCoursesDto
 */
export interface ParentsStudentsCoursesDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    parentReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    signUpDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string | number}
     * @memberof ParentsStudentsCoursesDto
     */
    age?: string | number;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    grade?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    school?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    studentReferenceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParentsStudentsCoursesDto
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    courseReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    coursePeriodName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    educationProviderReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    venueName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    subjectName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParentsStudentsCoursesDto
     */
    subjectAreas?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    teacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    teacherName?: string;
    /**
     * 
     * @type {number}
     * @memberof ParentsStudentsCoursesDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof ParentsStudentsCoursesDto
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ParentsStudentsCoursesDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface ParentsStudentsCoursesResponse
 */
export interface ParentsStudentsCoursesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ParentsStudentsCoursesDto>}
     * @memberof ParentsStudentsCoursesResponse
     */
    data: Array<ParentsStudentsCoursesDto>;
}
/**
 * Make all properties in T optional
 * @export
 * @interface PartialBookingAttributes
 */
export interface PartialBookingAttributes {
    /**
     * 
     * @type {string}
     * @memberof PartialBookingAttributes
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookingAttributes
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookingAttributes
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookingAttributes
     */
    startOn?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookingAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookingAttributes
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBookingAttributes
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialBookingAttributes
     */
    rescheduleFromCourseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookingAttributes
     */
    status?: PartialBookingAttributesStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBookingAttributes
     */
    isSentEmailToEp?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialBookingAttributes
     */
    reservedId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookingAttributes
     */
    cancellationReasonId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBookingAttributes
     */
    isSessionPack?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialBookingAttributes
     */
    originalEnrollmentId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PartialBookingAttributesStatusEnum {
    Available = 'available',
    New = 'new',
    Refunded = 'refunded',
    Rescheduled = 'rescheduled',
    Withdrawn = 'withdrawn'
}

/**
 * Make all properties in T optional
 * @export
 * @interface PartialCompanyAttributes
 */
export interface PartialCompanyAttributes {
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    address?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialCompanyAttributes
     */
    isGst?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    gstNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialCompanyAttributes
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    contactNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    status?: PartialCompanyAttributesStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    schoolId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    vendorName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    venueEmail?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialCompanyAttributes
     */
    postmarkSenderId?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    venueType?: PartialCompanyAttributesVenueTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    billingOrganisationId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    brandId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    senderEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyAttributes
     */
    slug?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialCompanyAttributes
     */
    messageDaysAfterCourseFinished?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PartialCompanyAttributesStatusEnum {
    Registered = 'registered',
    Unregistered = 'unregistered'
}
/**
    * @export
    * @enum {string}
    */
export enum PartialCompanyAttributesVenueTypeEnum {
    Agora = 'agora',
    AtSchool = 'at_school'
}

/**
 * Make all properties in T optional
 * @export
 * @interface PartialCoursePeriodAttributes
 */
export interface PartialCoursePeriodAttributes {
    /**
     * 
     * @type {string}
     * @memberof PartialCoursePeriodAttributes
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCoursePeriodAttributes
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCoursePeriodAttributes
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCoursePeriodAttributes
     */
    updatedBy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialCoursePeriodAttributes
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialCoursePeriodAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCoursePeriodAttributes
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialCoursePeriodAttributes
     */
    isEvent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartialCoursePeriodAttributes
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialCoursePeriodAttributes
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCoursePeriodAttributes
     */
    color?: string;
}
/**
 * Make all properties in T optional
 * @export
 * @interface PartialCreateBillingOrganisationDto
 */
export interface PartialCreateBillingOrganisationDto {
    /**
     * 
     * @type {string}
     * @memberof PartialCreateBillingOrganisationDto
     */
    registeredName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCreateBillingOrganisationDto
     */
    registeredAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCreateBillingOrganisationDto
     */
    uen?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCreateBillingOrganisationDto
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCreateBillingOrganisationDto
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCreateBillingOrganisationDto
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCreateBillingOrganisationDto
     */
    swiftBicCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCreateBillingOrganisationDto
     */
    bankCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCreateBillingOrganisationDto
     */
    branchCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCreateBillingOrganisationDto
     */
    bankAddress?: string;
    /**
     * 
     * @type {Array<BillingOrganisationPaymentMethodEnum>}
     * @memberof PartialCreateBillingOrganisationDto
     */
    parentManualInvoicePaymentMethods?: Array<BillingOrganisationPaymentMethodEnum>;
    /**
     * 
     * @type {Array<BillingOrganisationPaymentMethodEnum>}
     * @memberof PartialCreateBillingOrganisationDto
     */
    epManualInvoicePaymentMethods?: Array<BillingOrganisationPaymentMethodEnum>;
}
/**
 * Make all properties in T optional
 * @export
 * @interface PartialCreateBrandDto
 */
export interface PartialCreateBrandDto {
    /**
     * 
     * @type {string}
     * @memberof PartialCreateBrandDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCreateBrandDto
     */
    parentAppHelpScreenUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCreateBrandDto
     */
    postmarkServerToken?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCreateBrandDto
     */
    senderEmail?: string;
}
/**
 * Make all properties in T optional
 * @export
 * @interface PartialEducationProviderAttributes
 */
export interface PartialEducationProviderAttributes {
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialEducationProviderAttributes
     */
    availableHome?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialEducationProviderAttributes
     */
    isGst?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    uenNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    gstNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    contactNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    bankSwiftBic?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    mailingAddress?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialEducationProviderAttributes
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    educationProviderStatus?: PartialEducationProviderAttributesEducationProviderStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PartialEducationProviderAttributes
     */
    brochure?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialEducationProviderAttributes
     */
    isDisplayWebsite?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PartialEducationProviderAttributesEducationProviderStatusEnum {
    Active = 'active',
    Draft = 'draft',
    Inactive = 'inactive'
}

/**
 * Make all properties in T optional
 * @export
 * @interface PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
 */
export interface PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy {
    /**
     * 
     * @type {string}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    frequency?: PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedByFrequencyEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    includePublicHolidays?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    includeWeekends?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    endTime?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    takeRate?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    validFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    validTo?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    recurs?: PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedByRecursEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    days?: Array<PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedByDaysEnum>;
    /**
     * 
     * @type {string}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    roomId?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    numOfInstancesAhead?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    hasPriceModelSessionPacks?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    teacherId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    allowTrialSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    applyTrialSession?: PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedByApplyTrialSessionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    autoCheckin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    autoEnrolNextInstanceWithoutPayment?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    additionalTeacherIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedBy
     */
    hasPriceModelRevShare?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedByFrequencyEnum {
    Monthly = 'monthly'
}
/**
    * @export
    * @enum {string}
    */
export enum PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedByRecursEnum {
    Monthly = 'monthly'
}
/**
    * @export
    * @enum {string}
    */
export enum PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedByDaysEnum {
    Mon = 'Mon',
    Tue = 'Tue',
    Wed = 'Wed',
    Thu = 'Thu',
    Fri = 'Fri',
    Sat = 'Sat',
    Sun = 'Sun'
}
/**
    * @export
    * @enum {string}
    */
export enum PartialOmitCourseSeriesAttributesIsDeletedOrCreatedAtOrUpdatedAtOrCreatedByOrUpdatedByApplyTrialSessionEnum {
    AllSession = 'all_session',
    FirstSession = 'first_session'
}

/**
 * Make all properties in T optional
 * @export
 * @interface PartialUserInfoAttributes
 */
export interface PartialUserInfoAttributes {
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    fullname?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    userType?: PartialUserInfoAttributesUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialUserInfoAttributes
     */
    allowEmailContact?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialUserInfoAttributes
     */
    allowNotification?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialUserInfoAttributes
     */
    allowTextMessage?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialUserInfoAttributes
     */
    allowSms?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialUserInfoAttributes
     */
    allowEmailUpdates?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    relationshipId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    relationshipType?: PartialUserInfoAttributesRelationshipTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    gradeLevelId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    otherUserType?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    schoolId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    educationProviderId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    referenceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialUserInfoAttributes
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    mobileNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    parentRelationshipType?: PartialUserInfoAttributesParentRelationshipTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    invitedBy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialUserInfoAttributes
     */
    isRegisteredKisi?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    inviteCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    childColor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialUserInfoAttributes
     */
    isAllowStudentLeave?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartialUserInfoAttributes
     */
    enableEpPortalAccess?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    status?: PartialUserInfoAttributesStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    gender?: PartialUserInfoAttributesGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    otherSchool?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    classId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    signUpFromPage?: PartialUserInfoAttributesSignUpFromPageEnum;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    otherClass?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    chineseName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialUserInfoAttributes
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    subName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    googleId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    mergeToUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    childAllergies?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialUserInfoAttributes
     */
    kisiUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    invoiceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    invoiceEmailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    lastActiveDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialUserInfoAttributes
     */
    isEmergencyContact?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialUserInfoAttributes
     */
    invitationEmailSentAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PartialUserInfoAttributesUserTypeEnum {
    AgoraAdmin = 'agora_admin',
    Assistant = 'assistant',
    Brother = 'brother',
    BusinessDevelopment = 'business_development',
    Ceo = 'ceo',
    CurriculumDevelopment = 'curriculum_development',
    EpAdmin = 'ep_admin',
    EpAdminTeacher = 'ep_admin_teacher',
    Father = 'father',
    Finance = 'finance',
    Grandfather = 'grandfather',
    Grandmother = 'grandmother',
    Helper = 'helper',
    Mother = 'mother',
    Others = 'others',
    Owner = 'owner',
    Parent = 'parent',
    ProgramManager = 'program_manager',
    Sister = 'sister',
    Student = 'student',
    Teacher = 'teacher'
}
/**
    * @export
    * @enum {string}
    */
export enum PartialUserInfoAttributesRelationshipTypeEnum {
    Guardian = 'guardian',
    Parent = 'parent'
}
/**
    * @export
    * @enum {string}
    */
export enum PartialUserInfoAttributesParentRelationshipTypeEnum {
    Brother = 'brother',
    Father = 'father',
    Grandfather = 'grandfather',
    Grandmother = 'grandmother',
    Helper = 'helper',
    Mother = 'mother',
    Others = 'others',
    Sister = 'sister'
}
/**
    * @export
    * @enum {string}
    */
export enum PartialUserInfoAttributesStatusEnum {
    Active = 'active',
    Created = 'created',
    Inactive = 'inactive',
    InvitationSent = 'invitation_sent'
}
/**
    * @export
    * @enum {string}
    */
export enum PartialUserInfoAttributesGenderEnum {
    Female = 'female',
    Male = 'male',
    Other = 'other'
}
/**
    * @export
    * @enum {string}
    */
export enum PartialUserInfoAttributesSignUpFromPageEnum {
    Agora = 'agora',
    AtSchool = 'at_school'
}

/**
 * 
 * @export
 * @interface PasswordResetResponse
 */
export interface PasswordResetResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PasswordResetResponse
     */
    userStatus?: string;
}
/**
 * 
 * @export
 * @interface PatchChildInfoRequest
 */
export interface PatchChildInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchChildInfoRequest
     */
    childId?: string;
    /**
     * 
     * @type {ChildColorEnum}
     * @memberof PatchChildInfoRequest
     */
    childColor?: ChildColorEnum;
}
/**
 * 
 * @export
 * @interface PaymentAutoConfirmWithZeroCostRequest
 */
export interface PaymentAutoConfirmWithZeroCostRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PaymentAutoConfirmWithZeroCostRequest
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentAutoConfirmWithZeroCostRequest
     */
    parentId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentAutoConfirmWithZeroCostRequest
     */
    parentEmail: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentAutoConfirmWithZeroCostRequest
     */
    studentId: string;
}
/**
 * 
 * @export
 * @interface PaymentReserveSeatZeroCostRequest
 */
export interface PaymentReserveSeatZeroCostRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PaymentReserveSeatZeroCostRequest
     */
    reserveId: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentReserveSeatZeroCostRequest
     */
    isNotSendReceipt?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentReserveSeatZeroCostRequest
     */
    isSendConfirmationEmail?: boolean;
}
/**
 * 
 * @export
 * @interface PermissionsAttributes
 */
export interface PermissionsAttributes {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PermissionsAttributes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PermissionsAttributes
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PermissionsAttributes
     */
    groupName?: string;
    /**
     * 
     * @type {string}
     * @memberof PermissionsAttributes
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionsAttributes
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PermissionsAttributes
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PermissionsAttributes
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PermissionsAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PermissionsAttributes
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface PhotoUpdateDetailResponse
 */
export interface PhotoUpdateDetailResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {MessageNewDto}
     * @memberof PhotoUpdateDetailResponse
     */
    data: MessageNewDto;
}
/**
 * 
 * @export
 * @interface PhotoUpdateFilterResponse
 */
export interface PhotoUpdateFilterResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<CategoryParentMessageDto>}
     * @memberof PhotoUpdateFilterResponse
     */
    data: Array<CategoryParentMessageDto>;
}
/**
 * 
 * @export
 * @interface PhotoUpdateRequest
 */
export interface PhotoUpdateRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PhotoUpdateRequest
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof PhotoUpdateRequest
     */
    categoryId?: string;
    /**
     * 
     * @type {number}
     * @memberof PhotoUpdateRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoUpdateRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof PhotoUpdateRequest
     */
    maxUpdatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PhotoUpdateRequest
     */
    minUpdatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhotoUpdateRequest
     */
    includeDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface PhotoUpdateResponse
 */
export interface PhotoUpdateResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ParentPhotoUpdateDto>}
     * @memberof PhotoUpdateResponse
     */
    data: Array<ParentPhotoUpdateDto>;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickCourseAttributesNameOrIdOrReferenceIdOrProviderIdOrBusinessUnitId
 */
export interface PickCourseAttributesNameOrIdOrReferenceIdOrProviderIdOrBusinessUnitId {
    /**
     * 
     * @type {string}
     * @memberof PickCourseAttributesNameOrIdOrReferenceIdOrProviderIdOrBusinessUnitId
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PickCourseAttributesNameOrIdOrReferenceIdOrProviderIdOrBusinessUnitId
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PickCourseAttributesNameOrIdOrReferenceIdOrProviderIdOrBusinessUnitId
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof PickCourseAttributesNameOrIdOrReferenceIdOrProviderIdOrBusinessUnitId
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof PickCourseAttributesNameOrIdOrReferenceIdOrProviderIdOrBusinessUnitId
     */
    businessUnitId?: string;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickCourseInstanceAttributesReferenceIdOrStartDateOrVenueId
 */
export interface PickCourseInstanceAttributesReferenceIdOrStartDateOrVenueId {
    /**
     * 
     * @type {string}
     * @memberof PickCourseInstanceAttributesReferenceIdOrStartDateOrVenueId
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PickCourseInstanceAttributesReferenceIdOrStartDateOrVenueId
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof PickCourseInstanceAttributesReferenceIdOrStartDateOrVenueId
     */
    referenceId?: string;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickCourseSessionAttributesStartTimeOrId
 */
export interface PickCourseSessionAttributesStartTimeOrId {
    /**
     * 
     * @type {string}
     * @memberof PickCourseSessionAttributesStartTimeOrId
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PickCourseSessionAttributesStartTimeOrId
     */
    startTime?: string;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickPermissionsAttributesIdOrName
 */
export interface PickPermissionsAttributesIdOrName {
    /**
     * 
     * @type {string}
     * @memberof PickPermissionsAttributesIdOrName
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PickPermissionsAttributesIdOrName
     */
    name?: string;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickRolesAttributesIdOrName
 */
export interface PickRolesAttributesIdOrName {
    /**
     * 
     * @type {string}
     * @memberof PickRolesAttributesIdOrName
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PickRolesAttributesIdOrName
     */
    name?: string;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickSessionPackAdditionAttributesIdOrStartDateOrExpiredDateOrStudentIdOrParentId
 */
export interface PickSessionPackAdditionAttributesIdOrStartDateOrExpiredDateOrStudentIdOrParentId {
    /**
     * 
     * @type {string}
     * @memberof PickSessionPackAdditionAttributesIdOrStartDateOrExpiredDateOrStudentIdOrParentId
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PickSessionPackAdditionAttributesIdOrStartDateOrExpiredDateOrStudentIdOrParentId
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PickSessionPackAdditionAttributesIdOrStartDateOrExpiredDateOrStudentIdOrParentId
     */
    expiredDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PickSessionPackAdditionAttributesIdOrStartDateOrExpiredDateOrStudentIdOrParentId
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof PickSessionPackAdditionAttributesIdOrStartDateOrExpiredDateOrStudentIdOrParentId
     */
    parentId?: string;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickUserDtoIdOrFullname
 */
export interface PickUserDtoIdOrFullname {
    /**
     * 
     * @type {string}
     * @memberof PickUserDtoIdOrFullname
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PickUserDtoIdOrFullname
     */
    fullname?: string;
}
/**
 * 
 * @export
 * @interface PlansContentDto
 */
export interface PlansContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {PlansSingleFieldsDto}
     * @memberof PlansContentDto
     */
    singleFields?: PlansSingleFieldsDto;
    /**
     * 
     * @type {Array<PlansPricingForYouDto>}
     * @memberof PlansContentDto
     */
    pricingForYou?: Array<PlansPricingForYouDto>;
}
/**
 * 
 * @export
 * @interface PlansContentResponse
 */
export interface PlansContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {PlansContentDto}
     * @memberof PlansContentResponse
     */
    data: PlansContentDto;
}
/**
 * 
 * @export
 * @interface PlansPricingForYouDto
 */
export interface PlansPricingForYouDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PlansPricingForYouDto
     */
    typePricing?: string;
    /**
     * 
     * @type {string}
     * @memberof PlansPricingForYouDto
     */
    backgroundColor?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlansPricingForYouDto
     */
    pricingValues?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PlansPricingForYouDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof PlansPricingForYouDto
     */
    subTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PlansPricingForYouDto
     */
    image?: string;
}
/**
 * 
 * @export
 * @interface PlansSingleFieldsDto
 */
export interface PlansSingleFieldsDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PlansSingleFieldsDto
     */
    headingTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PlansSingleFieldsDto
     */
    headingSubTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PlansSingleFieldsDto
     */
    bodyForYouText?: string;
    /**
     * 
     * @type {string}
     * @memberof PlansSingleFieldsDto
     */
    bodyPlansTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PlansSingleFieldsDto
     */
    headingBackgroundColor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlansSingleFieldsDto
     */
    showFAQ?: boolean;
}
/**
 * 
 * @export
 * @interface PolicyDto
 */
export interface PolicyDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    content: string;
}
/**
 * 
 * @export
 * @interface PostAlertStatusRequest
 */
export interface PostAlertStatusRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostAlertStatusRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof PostAlertStatusRequest
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAlertStatusRequest
     */
    alertAction: PostAlertStatusRequestAlertActionEnum;
    /**
     * 
     * @type {string}
     * @memberof PostAlertStatusRequest
     */
    courseId: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PostAlertStatusRequestAlertActionEnum {
    CourseDatesAvailable = 'course_dates_available',
    Waitlist = 'waitlist',
    RequestJoin = 'request_join'
}

/**
 * 
 * @export
 * @interface PostAttendingSessionRequest
 */
export interface PostAttendingSessionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostAttendingSessionRequest
     */
    sub?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAttendingSessionRequest
     */
    childrenId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAttendingSessionRequest
     */
    courseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAttendingSessionRequest
     */
    classDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAttendingSessionRequest
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAttendingSessionRequest
     */
    attendId?: string;
}
/**
 * 
 * @export
 * @interface PostAttendingSessionResponse
 */
export interface PostAttendingSessionResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {PostAttendingSessionResponseData}
     * @memberof PostAttendingSessionResponse
     */
    data?: PostAttendingSessionResponseData;
}
/**
 * 
 * @export
 * @interface PostAttendingSessionResponseData
 */
export interface PostAttendingSessionResponseData {
    /**
     * 
     * @type {boolean}
     * @memberof PostAttendingSessionResponseData
     */
    isAdd?: boolean;
    /**
     * 
     * @type {AttendanceDto}
     * @memberof PostAttendingSessionResponseData
     */
    attendance?: AttendanceDto;
}
/**
 * 
 * @export
 * @interface PostBookmarkRequest
 */
export interface PostBookmarkRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostBookmarkRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof PostBookmarkRequest
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof PostBookmarkRequest
     */
    alertAction?: string;
    /**
     * 
     * @type {string}
     * @memberof PostBookmarkRequest
     */
    courseId?: string;
}
/**
 * 
 * @export
 * @interface PostCampaignHistoryRequest
 */
export interface PostCampaignHistoryRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostCampaignHistoryRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof PostCampaignHistoryRequest
     */
    invitedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCampaignHistoryRequest
     */
    campaignId: string;
    /**
     * 
     * @type {number}
     * @memberof PostCampaignHistoryRequest
     */
    earnedHour?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCampaignHistoryRequest
     */
    earnedCredit?: number;
    /**
     * 
     * @type {string}
     * @memberof PostCampaignHistoryRequest
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCampaignHistoryRequest
     */
    status: PostCampaignHistoryRequestStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PostCampaignHistoryRequestStatusEnum {
    InProgress = 'in_progress',
    Completed = 'completed'
}

/**
 * 
 * @export
 * @interface PostChildrenInfoRequest
 */
export interface PostChildrenInfoRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    alias: string;
    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    gender?: PostChildrenInfoRequestGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    avatar: string;
    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    parent: string;
    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    level: string;
    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    school?: string;
    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    otherSchool?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostChildrenInfoRequest
     */
    isAllowStudentLeave: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    classId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    otherClass?: string;
    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    chineseName?: string;
    /**
     * 
     * @type {string}
     * @memberof PostChildrenInfoRequest
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PostChildrenInfoRequestGenderEnum {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface PostConversationListRequest
 */
export interface PostConversationListRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostConversationListRequest
     */
    search: string;
}
/**
 * 
 * @export
 * @interface PostCourseFilterResponse
 */
export interface PostCourseFilterResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {CourseFilterDataDto}
     * @memberof PostCourseFilterResponse
     */
    data: CourseFilterDataDto;
}
/**
 * 
 * @export
 * @interface PostCreateEventRequest
 */
export interface PostCreateEventRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostCreateEventRequest
     */
    code: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostCreateEventRequest
     */
    courseInstanceIds: Array<string>;
}
/**
 * 
 * @export
 * @interface PostCreateLeadRequest
 */
export interface PostCreateLeadRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostCreateLeadRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface PostCreatePaymentRequest
 */
export interface PostCreatePaymentRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    redirectUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    webhook?: string;
    /**
     * 
     * @type {number}
     * @memberof PostCreatePaymentRequest
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCreatePaymentRequest
     */
    originalPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    buyerId: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostCreatePaymentRequest
     */
    isMulti?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    paymentType?: PostCreatePaymentRequestPaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    packId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    sessionPackId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    shoppingCartId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    cartItemId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    promoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    discountCodeCampaignId?: string;
    /**
     * 
     * @type {AvailableDiscountCodeDto}
     * @memberof PostCreatePaymentRequest
     */
    discountCodeApplied?: AvailableDiscountCodeDto;
    /**
     * 
     * @type {number}
     * @memberof PostCreatePaymentRequest
     */
    discountPrice?: number;
    /**
     * 
     * @type {Array<ShoppingCartDto>}
     * @memberof PostCreatePaymentRequest
     */
    products: Array<ShoppingCartDto>;
    /**
     * 
     * @type {number}
     * @memberof PostCreatePaymentRequest
     */
    creditUsageAmount?: number;
    /**
     * 
     * @type {Array<UTMParamDto>}
     * @memberof PostCreatePaymentRequest
     */
    utmParams?: Array<UTMParamDto>;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    requestFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    invoiceId?: string;
    /**
     * 
     * @type {Array<InvoiceLineAttributes>}
     * @memberof PostCreatePaymentRequest
     */
    invoiceLines?: Array<InvoiceLineAttributes>;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    educationProviderId?: string;
    /**
     * 
     * @type {TermsAcceptedDto}
     * @memberof PostCreatePaymentRequest
     */
    terms?: TermsAcceptedDto;
    /**
     * 
     * @type {string}
     * @memberof PostCreatePaymentRequest
     */
    venueId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PostCreatePaymentRequestPaymentTypeEnum {
    Course = 'course',
    AgoraCare = 'agora_care',
    SessionPack = 'session_pack',
    Both = 'both',
    Product = 'product'
}

/**
 * 
 * @export
 * @interface PostCreateUserSegmentRequest
 */
export interface PostCreateUserSegmentRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostCreateUserSegmentRequest
     */
    segmentId: string;
}
/**
 * 
 * @export
 * @interface PostEPAgreementRequest
 */
export interface PostEPAgreementRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostEPAgreementRequest
     */
    search?: string;
    /**
     * 
     * @type {number}
     * @memberof PostEPAgreementRequest
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof PostEPAgreementRequest
     */
    limit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PostEPAgreementRequest
     */
    isForEdit?: boolean;
}
/**
 * 
 * @export
 * @interface PostEPAgreementResponse
 */
export interface PostEPAgreementResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof PostEPAgreementResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<ProviderAgreementsDto>}
     * @memberof PostEPAgreementResponse
     */
    data: Array<ProviderAgreementsDto>;
}
/**
 * 
 * @export
 * @interface PostEPAttendanceCourseSessionRequest
 */
export interface PostEPAttendanceCourseSessionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostEPAttendanceCourseSessionRequest
     */
    courseSessionId: string;
}
/**
 * 
 * @export
 * @interface PostEPAttendanceCourseSessionResponse
 */
export interface PostEPAttendanceCourseSessionResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AttendanceCourseSessionDto}
     * @memberof PostEPAttendanceCourseSessionResponse
     */
    data: AttendanceCourseSessionDto;
}
/**
 * 
 * @export
 * @interface PostEPAttendanceRequest
 */
export interface PostEPAttendanceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostEPAttendanceRequest
     */
    courseSessionId: string;
    /**
     * 
     * @type {number}
     * @memberof PostEPAttendanceRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof PostEPAttendanceRequest
     */
    offset?: number;
}
/**
 * 
 * @export
 * @interface PostEPAttendanceResponse
 */
export interface PostEPAttendanceResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof PostEPAttendanceResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<AttendanceStatusDto>}
     * @memberof PostEPAttendanceResponse
     */
    data: Array<AttendanceStatusDto>;
}
/**
 * 
 * @export
 * @interface PostEPDashBoardRequest
 */
export interface PostEPDashBoardRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostEPDashBoardRequest
     */
    subjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostEPDashBoardRequest
     */
    gradeLevelId?: string;
    /**
     * 
     * @type {number}
     * @memberof PostEPDashBoardRequest
     */
    amountOfDays: number;
}
/**
 * 
 * @export
 * @interface PostEPDashboardResponse
 */
export interface PostEPDashboardResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {PostEPDashboardResponseData}
     * @memberof PostEPDashboardResponse
     */
    data: PostEPDashboardResponseData;
}
/**
 * 
 * @export
 * @interface PostEPDashboardResponseData
 */
export interface PostEPDashboardResponseData {
    /**
     * 
     * @type {number}
     * @memberof PostEPDashboardResponseData
     */
    numOfInstanceMissingTeacher: number;
    /**
     * 
     * @type {number}
     * @memberof PostEPDashboardResponseData
     */
    occupancy: number;
    /**
     * 
     * @type {number}
     * @memberof PostEPDashboardResponseData
     */
    students: number;
    /**
     * 
     * @type {number}
     * @memberof PostEPDashboardResponseData
     */
    remittedRevenue: number;
    /**
     * 
     * @type {number}
     * @memberof PostEPDashboardResponseData
     */
    agoraGst: number;
    /**
     * 
     * @type {number}
     * @memberof PostEPDashboardResponseData
     */
    agoraFee: number;
    /**
     * 
     * @type {number}
     * @memberof PostEPDashboardResponseData
     */
    epRevenue: number;
    /**
     * 
     * @type {number}
     * @memberof PostEPDashboardResponseData
     */
    epGstAmount: number;
    /**
     * 
     * @type {number}
     * @memberof PostEPDashboardResponseData
     */
    courseBooking: number;
    /**
     * 
     * @type {number}
     * @memberof PostEPDashboardResponseData
     */
    revenue: number;
}
/**
 * 
 * @export
 * @interface PostEPSchedulesRequest
 */
export interface PostEPSchedulesRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostEPSchedulesRequest
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof PostEPSchedulesRequest
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof PostEPSchedulesRequest
     */
    search?: string;
    /**
     * 
     * @type {number}
     * @memberof PostEPSchedulesRequest
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof PostEPSchedulesRequest
     */
    limit?: number;
}
/**
 * 
 * @export
 * @interface PostEPSchedulesResponse
 */
export interface PostEPSchedulesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof PostEPSchedulesResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<CourseFilterDto>}
     * @memberof PostEPSchedulesResponse
     */
    data: Array<CourseFilterDto>;
}
/**
 * 
 * @export
 * @interface PostEPTeacherDetailRequest
 */
export interface PostEPTeacherDetailRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostEPTeacherDetailRequest
     */
    search?: string;
    /**
     * 
     * @type {number}
     * @memberof PostEPTeacherDetailRequest
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof PostEPTeacherDetailRequest
     */
    limit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PostEPTeacherDetailRequest
     */
    isForEdit?: boolean;
}
/**
 * 
 * @export
 * @interface PostEPTeacherDetailResponse
 */
export interface PostEPTeacherDetailResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof PostEPTeacherDetailResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<TeacherDetailDto>}
     * @memberof PostEPTeacherDetailResponse
     */
    data: Array<TeacherDetailDto>;
}
/**
 * 
 * @export
 * @interface PostIntercomUserInfoRequest
 */
export interface PostIntercomUserInfoRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostIntercomUserInfoRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PostIntercomUserInfoRequest
     */
    email_verified?: string;
    /**
     * 
     * @type {string}
     * @memberof PostIntercomUserInfoRequest
     */
    sub?: string;
    /**
     * 
     * @type {string}
     * @memberof PostIntercomUserInfoRequest
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof PostIntercomUserInfoRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PostIntercomUserInfoRequest
     */
    nickname?: string;
}
/**
 * 
 * @export
 * @interface PostRegisterCourseRequest
 */
export interface PostRegisterCourseRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseRequest
     */
    courseId: string;
    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseRequest
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseRequest
     */
    courseName: string;
    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseRequest
     */
    courseInstanceDate: string;
    /**
     * 
     * @type {number}
     * @memberof PostRegisterCourseRequest
     */
    courseCost: number;
    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseRequest
     */
    parentName: string;
    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseRequest
     */
    parentFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseRequest
     */
    parentLastName: string;
    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseRequest
     */
    parentChildName: string;
    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseRequest
     */
    parentChildDOB: string;
    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseRequest
     */
    parentEmail: string;
    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseRequest
     */
    parentContactNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseRequest
     */
    parentMessage: string;
    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseRequest
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseRequest
     */
    type: PostRegisterCourseRequestTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PostRegisterCourseRequestTypeEnum {
    Trial = 'trial',
    RequestJoin = 'request_join'
}

/**
 * 
 * @export
 * @interface PostRegisterCourseResponse
 */
export interface PostRegisterCourseResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {boolean}
     * @memberof PostRegisterCourseResponse
     */
    zendeskSuccess: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostRegisterCourseResponse
     */
    statusText?: string;
}
/**
 * 
 * @export
 * @interface PostSchedulesRequest
 */
export interface PostSchedulesRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostSchedulesRequest
     */
    sub: string;
    /**
     * 
     * @type {string}
     * @memberof PostSchedulesRequest
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PostSchedulesRequest
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PostSchedulesRequest
     */
    timezone?: string;
    /**
     * 
     * @type {string}
     * @memberof PostSchedulesRequest
     */
    requestFrom?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostSchedulesRequest
     */
    isAdminRequest?: boolean;
}
/**
 * 
 * @export
 * @interface PostSchedulesResponse
 */
export interface PostSchedulesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {PostSchedulesResponseData}
     * @memberof PostSchedulesResponse
     */
    data: PostSchedulesResponseData;
}
/**
 * 
 * @export
 * @interface PostSchedulesResponseData
 */
export interface PostSchedulesResponseData {
    /**
     * 
     * @type {Array<ListViewsDto>}
     * @memberof PostSchedulesResponseData
     */
    listView?: Array<ListViewsDto>;
    /**
     * 
     * @type {Array<SchedulesDto>}
     * @memberof PostSchedulesResponseData
     */
    schedules?: Array<SchedulesDto>;
}
/**
 * 
 * @export
 * @interface PostUserInfoByAdminRequest
 */
export interface PostUserInfoByAdminRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminRequest
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminRequest
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminRequest
     */
    userType: PostUserInfoByAdminRequestUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminRequest
     */
    status?: PostUserInfoByAdminRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminRequest
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminRequest
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostUserInfoByAdminRequest
     */
    userTags?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PostUserInfoByAdminRequest
     */
    enableEpPortalAccess?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminRequest
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminRequest
     */
    referenceId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PostUserInfoByAdminRequestUserTypeEnum {
    EpAdmin = 'ep_admin',
    EpAdminTeacher = 'ep_admin_teacher',
    Teacher = 'teacher'
}
/**
    * @export
    * @enum {string}
    */
export enum PostUserInfoByAdminRequestStatusEnum {
    Active = 'active',
    Inactive = 'inactive'
}

/**
 * 
 * @export
 * @interface PostUserInfoByAdminResponse
 */
export interface PostUserInfoByAdminResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminResponse
     */
    userType: string;
}
/**
 * 
 * @export
 * @interface PostUserInfoByAdminRetoolRequest
 */
export interface PostUserInfoByAdminRetoolRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {PostUserInfoByAdminRetoolRequestData}
     * @memberof PostUserInfoByAdminRetoolRequest
     */
    data: PostUserInfoByAdminRetoolRequestData;
}
/**
 * 
 * @export
 * @interface PostUserInfoByAdminRetoolRequestData
 */
export interface PostUserInfoByAdminRetoolRequestData {
    /**
     * 
     * @type {PostUserInfoByAdminRequest}
     * @memberof PostUserInfoByAdminRetoolRequestData
     */
    userAdmin: PostUserInfoByAdminRequest;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminRetoolRequestData
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoByAdminRetoolRequestData
     */
    subId: string;
}
/**
 * 
 * @export
 * @interface PostUserInfoRequest
 */
export interface PostUserInfoRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PostUserInfoRequest
     */
    fullname: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoRequest
     */
    avatar: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoRequest
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoRequest
     */
    address?: string;
    /**
     * 
     * @type {any}
     * @memberof PostUserInfoRequest
     */
    userType?: any | null;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoRequest
     */
    otherUserType?: string;
    /**
     * 
     * @type {any}
     * @memberof PostUserInfoRequest
     */
    parentRelationshipType?: any | null;
    /**
     * 
     * @type {string}
     * @memberof PostUserInfoRequest
     */
    gender?: PostUserInfoRequestGenderEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PostUserInfoRequest
     */
    isEmergencyContact?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostUserInfoRequest
     */
    isResendInvite?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PostUserInfoRequestGenderEnum {
    Female = 'female',
    Male = 'male',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface PrepareCheckinPlayschoolRequest
 */
export interface PrepareCheckinPlayschoolRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PrepareCheckinPlayschoolRequest
     */
    studentRef: string;
    /**
     * 
     * @type {string}
     * @memberof PrepareCheckinPlayschoolRequest
     */
    checkinDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrepareCheckinPlayschoolRequest
     */
    isTrial?: boolean;
}
/**
 * 
 * @export
 * @interface PrepareCheckinPlayschoolResponse
 */
export interface PrepareCheckinPlayschoolResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PrepareCheckinPlayschoolResponse
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof PrepareCheckinPlayschoolResponse
     */
    venueId: string;
    /**
     * 
     * @type {string}
     * @memberof PrepareCheckinPlayschoolResponse
     */
    studentId: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrepareCheckinPlayschoolResponse
     */
    isTrial?: boolean;
    /**
     * 
     * @type {PickSessionPackAdditionAttributesIdOrStartDateOrExpiredDateOrStudentIdOrParentId}
     * @memberof PrepareCheckinPlayschoolResponse
     */
    sessionPackAddition?: PickSessionPackAdditionAttributesIdOrStartDateOrExpiredDateOrStudentIdOrParentId;
}
/**
 * 
 * @export
 * @interface PrepareInvoiceMigrateRequest
 */
export interface PrepareInvoiceMigrateRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PrepareInvoiceMigrateRequest
     */
    studentName: string;
    /**
     * 
     * @type {string}
     * @memberof PrepareInvoiceMigrateRequest
     */
    parentEmail: string;
    /**
     * 
     * @type {string}
     * @memberof PrepareInvoiceMigrateRequest
     */
    parentFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PrepareInvoiceMigrateRequest
     */
    parentLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PrepareInvoiceMigrateRequest
     */
    instanceDate: string;
    /**
     * 
     * @type {number}
     * @memberof PrepareInvoiceMigrateRequest
     */
    transportFee: number;
    /**
     * 
     * @type {number}
     * @memberof PrepareInvoiceMigrateRequest
     */
    courseFee: number;
    /**
     * 
     * @type {string}
     * @memberof PrepareInvoiceMigrateRequest
     */
    courseName: string;
    /**
     * 
     * @type {string}
     * @memberof PrepareInvoiceMigrateRequest
     */
    sessionPackName?: string;
    /**
     * 
     * @type {number}
     * @memberof PrepareInvoiceMigrateRequest
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof PrepareInvoiceMigrateRequest
     */
    sessionPackFee?: number;
}
/**
 * 
 * @export
 * @interface PriceModelRequest
 */
export interface PriceModelRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PriceModelRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceModelRequest
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceModelRequest
     */
    venueId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceModelRequest
     */
    countryId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceModelRequest
     */
    gstId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriceModelRequest
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceModelRequest
     */
    validFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceModelRequest
     */
    validTo?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PriceModelRequest
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface PriceRangeCampaignDto
 */
export interface PriceRangeCampaignDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof PriceRangeCampaignDto
     */
    priceFrom: number;
    /**
     * 
     * @type {number}
     * @memberof PriceRangeCampaignDto
     */
    priceTo: number;
    /**
     * 
     * @type {number}
     * @memberof PriceRangeCampaignDto
     */
    discountPrice: number;
}
/**
 * 
 * @export
 * @interface PrivacyDto
 */
export interface PrivacyDto {
    [key: string]: object | any;

    /**
     * 
     * @type {TermsTemplatePageDto}
     * @memberof PrivacyDto
     */
    pageDecor: TermsTemplatePageDto;
    /**
     * 
     * @type {Array<TermsTemplateContentDto>}
     * @memberof PrivacyDto
     */
    contents: Array<TermsTemplateContentDto>;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    sku?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    categoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    businessUnitId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    status?: string;
    /**
     * 
     * @type {Array<AdminPriceDto>}
     * @memberof ProductDto
     */
    priceList?: Array<AdminPriceDto>;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    courseStatus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    isAutoReEnrolStudent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface ProductServicePageContentDto
 */
export interface ProductServicePageContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProductServicePageContentDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductServicePageContentDto
     */
    description?: string;
    /**
     * 
     * @type {Array<ProductServicePageContentItemDto>}
     * @memberof ProductServicePageContentDto
     */
    items?: Array<ProductServicePageContentItemDto>;
}
/**
 * 
 * @export
 * @interface ProductServicePageContentItemDto
 */
export interface ProductServicePageContentItemDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProductServicePageContentItemDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductServicePageContentItemDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductServicePageContentItemDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductServicePageContentItemDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductServicePageContentItemDto
     */
    redirectTo?: string;
}
/**
 * 
 * @export
 * @interface ProductServicePageDecorationDto
 */
export interface ProductServicePageDecorationDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProductServicePageDecorationDto
     */
    headerTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductServicePageDecorationDto
     */
    headerSubTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductServicePageDecorationDto
     */
    headerBackgroundColor?: string;
}
/**
 * 
 * @export
 * @interface ProductServicePageEventDto
 */
export interface ProductServicePageEventDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProductServicePageEventDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductServicePageEventDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductServicePageEventDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductServicePageEventDto
     */
    backgroundColor?: string;
}
/**
 * 
 * @export
 * @interface ProductServicePageTableCellDto
 */
export interface ProductServicePageTableCellDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProductServicePageTableCellDto
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductServicePageTableCellDto
     */
    isChecked?: boolean;
}
/**
 * 
 * @export
 * @interface ProductServicePageTableItemsDto
 */
export interface ProductServicePageTableItemsDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProductServicePageTableItemsDto
     */
    title?: string;
    /**
     * 
     * @type {Array<ProductServicePageTableCellDto>}
     * @memberof ProductServicePageTableItemsDto
     */
    items?: Array<ProductServicePageTableCellDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductServicePageTableItemsDto
     */
    isTitleWithIcon?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ProductStatus {
    Active = 'active',
    Archived = 'archived'
}

/**
 * 
 * @export
 * @interface ProductTypeDto
 */
export interface ProductTypeDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProductTypeDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductTypeDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface PromptMessageAfterSignedUpDto
 */
export interface PromptMessageAfterSignedUpDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PromptMessageAfterSignedUpDto
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof PromptMessageAfterSignedUpDto
     */
    background: string;
    /**
     * 
     * @type {string}
     * @memberof PromptMessageAfterSignedUpDto
     */
    backgroundColor: string;
    /**
     * 
     * @type {string}
     * @memberof PromptMessageAfterSignedUpDto
     */
    textColor: string;
    /**
     * 
     * @type {string}
     * @memberof PromptMessageAfterSignedUpDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PromptMessageAfterSignedUpDto
     */
    description: string;
}
/**
 * 
 * @export
 * @interface ProviderAgreementsDto
 */
export interface ProviderAgreementsDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProviderAgreementsDto
     */
    courseInstanceName: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderAgreementsDto
     */
    courseInstanceCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderAgreementsDto
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderAgreementsDto
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderAgreementsDto
     */
    agreementLink: string;
}
/**
 * 
 * @export
 * @interface ProviderBookingListDto
 */
export interface ProviderBookingListDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProviderBookingListDto
     */
    studentName: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderBookingListDto
     */
    courseSlug: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderBookingListDto
     */
    courseName: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderBookingListDto
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderBookingListDto
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderBookingListDto
     */
    bookingDate: string;
    /**
     * 
     * @type {number}
     * @memberof ProviderBookingListDto
     */
    amount: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderBookingListDto
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {DataForInsertTransactionDtoProvider}
     * @memberof ProviderBookingListDto
     */
    venue?: DataForInsertTransactionDtoProvider;
}
/**
 * 
 * @export
 * @interface ProviderContactDto
 */
export interface ProviderContactDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProviderContactDto
     */
    contactNamePerson?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderContactDto
     */
    contactNumberPerson?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderContactDto
     */
    memberSince?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderContactDto
     */
    rolePerson?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderContactDto
     */
    mainOfficeNumberPerson?: string;
}
/**
 * 
 * @export
 * @interface ProviderDetailDto
 */
export interface ProviderDetailDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    providerId: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    providerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    uenNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    gstNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    officialAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    mailingAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    bankSwiftBic?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    adminAvatar?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    adminContactName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    adminContactNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    adminEmail?: string;
    /**
     * 
     * @type {Array<ProviderContactDto>}
     * @memberof ProviderDetailDto
     */
    contactInfo?: Array<ProviderContactDto>;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailDto
     */
    referenceId?: string;
}
/**
 * 
 * @export
 * @interface ProviderDto
 */
export interface ProviderDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    contactNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    firstName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDto
     */
    isGst?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProviderDto
     */
    referenceId?: string;
    /**
     * 
     * @type {GstDto}
     * @memberof ProviderDto
     */
    gst?: GstDto;
}
/**
 * 
 * @export
 * @interface ProviderInfoDto
 */
export interface ProviderInfoDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProviderInfoDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderInfoDto
     */
    userEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderInfoDto
     */
    userAvatar?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderInfoDto
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderInfoDto
     */
    providerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderInfoDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderInfoDto
     */
    providerDescription?: string;
}
/**
 * 
 * @export
 * @interface ProviderSummaryDto
 */
export interface ProviderSummaryDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProviderSummaryDto
     */
    providerId?: string;
    /**
     * 
     * @type {number}
     * @memberof ProviderSummaryDto
     */
    availability?: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderSummaryDto
     */
    review?: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderSummaryDto
     */
    star?: number;
    /**
     * 
     * @type {string}
     * @memberof ProviderSummaryDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderSummaryDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderSummaryDto
     */
    slug?: string;
}
/**
 * 
 * @export
 * @interface PurchaseHiddenPlanRequest
 */
export interface PurchaseHiddenPlanRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanRequest
     */
    careId?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanRequest
     */
    accessId?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanRequest
     */
    requestFrom?: string;
}
/**
 * 
 * @export
 * @interface PurchaseHiddenPlanResponse
 */
export interface PurchaseHiddenPlanResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    email?: string;
    /**
     * 
     * @type {any}
     * @memberof PurchaseHiddenPlanResponse
     */
    phone?: any | null;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    status?: string;
    /**
     * 
     * @type {any}
     * @memberof PurchaseHiddenPlanResponse
     */
    purpose?: any | null;
    /**
     * 
     * @type {any}
     * @memberof PurchaseHiddenPlanResponse
     */
    reference_number?: any | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PurchaseHiddenPlanResponse
     */
    payment_methods?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    redirect_url?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    webhook?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseHiddenPlanResponse
     */
    send_sms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseHiddenPlanResponse
     */
    send_email?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    sms_status?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    email_status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseHiddenPlanResponse
     */
    allow_repeated_payments?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    expiry_date?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    updated_at?: string;
    /**
     * 
     * @type {Array<UTMParamDto>}
     * @memberof PurchaseHiddenPlanResponse
     */
    utmParams?: Array<UTMParamDto>;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    business_recurring_plans_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    customer_email?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    customer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    cycle?: string;
    /**
     * 
     * @type {string | number}
     * @memberof PurchaseHiddenPlanResponse
     */
    price?: string | number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    times_to_be_charged?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    times_charged?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    expires_at?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseHiddenPlanResponse
     */
    reference?: string;
}
/**
 * 
 * @export
 * @interface PutSeenMessagesRequest
 */
export interface PutSeenMessagesRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof PutSeenMessagesRequest
     */
    messageGroupId: string;
}
/**
 * 
 * @export
 * @interface PutSubscriptionLinesRequest
 */
export interface PutSubscriptionLinesRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<string>}
     * @memberof PutSubscriptionLinesRequest
     */
    subscriptionLineIds: Array<string>;
    /**
     * 
     * @type {SubscriptionLineStatusType}
     * @memberof PutSubscriptionLinesRequest
     */
    status: SubscriptionLineStatusType;
}
/**
 * 
 * @export
 * @interface QueueMessageDto
 */
export interface QueueMessageDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof QueueMessageDto
     */
    messageId?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueMessageDto
     */
    receiptHandle?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueMessageDto
     */
    body?: string;
    /**
     * 
     * @type {Attributes}
     * @memberof QueueMessageDto
     */
    attributes?: Attributes;
    /**
     * 
     * @type {string}
     * @memberof QueueMessageDto
     */
    md5OfMessageAttributes?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueMessageDto
     */
    md5OfBody?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueMessageDto
     */
    eventSource?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueMessageDto
     */
    eventSourceARN?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueMessageDto
     */
    awsRegion?: string;
}
/**
 * 
 * @export
 * @interface ReActiveSubscriptionRequest
 */
export interface ReActiveSubscriptionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ReActiveSubscriptionRequest
     */
    subscriptionId: string;
}
/**
 * 
 * @export
 * @interface ReasonDto
 */
export interface ReasonDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ReasonDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReasonDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof ReasonDto
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface RecipientDto
 */
export interface RecipientDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RecipientDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientDto
     */
    status?: RecipientDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RecipientDto
     */
    pushStatus?: RecipientDtoPushStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RecipientDto
     */
    parentId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecipientDto
     */
    taggedStudents?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RecipientDto
     */
    numberOfShared?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RecipientDtoStatusEnum {
    Pending = 'pending',
    Sent = 'sent',
    Read = 'read'
}
/**
    * @export
    * @enum {string}
    */
export enum RecipientDtoPushStatusEnum {
    Failure = 'failure',
    Success = 'success'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum RecordPaymentMethodEnum {
    BankTransfer = 'bank_transfer',
    Dbs = 'dbs',
    Hitpay = 'hitpay',
    Other = 'other',
    Credit = 'credit',
    Egiro = 'egiro'
}

/**
 * 
 * @export
 * @interface RecordPaymentRequest
 */
export interface RecordPaymentRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RecordPaymentRequest
     */
    invoiceReferenceId: string;
    /**
     * 
     * @type {number}
     * @memberof RecordPaymentRequest
     */
    amount: number;
    /**
     * 
     * @type {RecordPaymentMethodEnum}
     * @memberof RecordPaymentRequest
     */
    paymentMethod: RecordPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof RecordPaymentRequest
     */
    transactionReference?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordPaymentRequest
     */
    transactionDate?: string;
}
/**
 * 
 * @export
 * @interface RecurringPlanDto
 */
export interface RecurringPlanDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    business_recurring_plans_id?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    customer_email?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    customer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    cycle?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    currency?: string;
    /**
     * 
     * @type {string | number}
     * @memberof RecurringPlanDto
     */
    price?: string | number;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    times_to_be_charged?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    times_charged?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    redirect_url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecurringPlanDto
     */
    payment_methods?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    expires_at?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringPlanDto
     */
    reference?: string;
}
/**
 * 
 * @export
 * @interface RefundDto
 */
export interface RefundDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RefundDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof RefundDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof RefundDto
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof RefundDto
     */
    invoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof RefundDto
     */
    refundDate?: string;
    /**
     * 
     * @type {string | number}
     * @memberof RefundDto
     */
    amount?: string | number;
    /**
     * 
     * @type {string}
     * @memberof RefundDto
     */
    type?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RefundMethod {
    Credit = 'credit',
    Hitpay = 'hitpay',
    Manually = 'manually'
}

/**
 * 
 * @export
 * @interface RefundPaymentRequest
 */
export interface RefundPaymentRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RefundPaymentRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof RefundPaymentRequest
     */
    email: string;
    /**
     * 
     * @type {string | number}
     * @memberof RefundPaymentRequest
     */
    amount: string | number;
    /**
     * 
     * @type {string}
     * @memberof RefundPaymentRequest
     */
    payment_id: string;
    /**
     * 
     * @type {string}
     * @memberof RefundPaymentRequest
     */
    webhook: string;
}
/**
 * 
 * @export
 * @interface RefundPaymentResponse
 */
export interface RefundPaymentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RefundPaymentResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RefundPaymentResponse
     */
    payment_id: string;
    /**
     * 
     * @type {number}
     * @memberof RefundPaymentResponse
     */
    amount_refunded: number;
    /**
     * 
     * @type {number}
     * @memberof RefundPaymentResponse
     */
    total_amount: number;
    /**
     * 
     * @type {string}
     * @memberof RefundPaymentResponse
     */
    currency: string;
    /**
     * 
     * @type {any}
     * @memberof RefundPaymentResponse
     */
    status: any | null;
    /**
     * 
     * @type {string}
     * @memberof RefundPaymentResponse
     */
    payment_method: string;
    /**
     * 
     * @type {string}
     * @memberof RefundPaymentResponse
     */
    created_at: string;
}
/**
 * 
 * @export
 * @interface RegisterToAtSchoolRequest
 */
export interface RegisterToAtSchoolRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RegisterToAtSchoolRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterToAtSchoolRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterToAtSchoolRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterToAtSchoolRequest
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterToAtSchoolRequest
     */
    schoolName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterToAtSchoolRequest
     */
    message: string;
}
/**
 * 
 * @export
 * @interface RegistrationLogDto
 */
export interface RegistrationLogDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RegistrationLogDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationLogDto
     */
    parentId?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof RegistrationLogDto
     */
    parent?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof RegistrationLogDto
     */
    childId?: string;
    /**
     * 
     * @type {ChildDto}
     * @memberof RegistrationLogDto
     */
    child?: ChildDto;
    /**
     * 
     * @type {string}
     * @memberof RegistrationLogDto
     */
    emergencyId?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof RegistrationLogDto
     */
    emergencyContact?: UserDto;
    /**
     * 
     * @type {RegistrationLogTypeEnum}
     * @memberof RegistrationLogDto
     */
    type?: RegistrationLogTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationLogDto
     */
    enrollmentRequestData?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationLogDto
     */
    courseId?: string;
    /**
     * 
     * @type {CourseDto}
     * @memberof RegistrationLogDto
     */
    course?: CourseDto;
    /**
     * 
     * @type {string}
     * @memberof RegistrationLogDto
     */
    venueId?: string;
    /**
     * 
     * @type {CompanyDto}
     * @memberof RegistrationLogDto
     */
    venue?: CompanyDto;
    /**
     * 
     * @type {string}
     * @memberof RegistrationLogDto
     */
    birthdayCertificateId?: string;
    /**
     * 
     * @type {MediaDto}
     * @memberof RegistrationLogDto
     */
    birthdayCertificate?: MediaDto;
    /**
     * 
     * @type {string}
     * @memberof RegistrationLogDto
     */
    promoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationLogDto
     */
    programme?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationLogDto
     */
    requireTransport?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegistrationLogDto
     */
    businessUnitId?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationLogDto
     */
    enrolmentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationLogDto
     */
    createdAt?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RegistrationLogProgrammeEnum {
    StudentCare5Days = 'student_care_5_days',
    StudentCare3Days = 'student_care_3_days',
    Playschool2HourSessionsSessionPacks = 'playschool_2_hour_sessions_session_packs',
    Playschool2HourSessionsSubscription = 'playschool_2_hour_sessions_subscription',
    PlayschoolHalfDaySessionsSessionPacks = 'playschool_half_day_sessions_session_packs',
    PlayschoolHalfDaySessionsSubscription = 'playschool_half_day_sessions_subscription',
    Preschool = 'preschool',
    PreschoolTransition = 'preschool_transition',
    PreschoolTransition3Day = 'preschool_transition_3_day',
    PlayschoolPlayAndBloom = 'playschool_play_and_bloom',
    PlayschoolMiniExplorers = 'playschool_mini_explorers'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum RegistrationLogTypeEnum {
    StudentCare = 'student_care',
    EarlyYears = 'early_years'
}

/**
 * 
 * @export
 * @interface RegistrationRequest
 */
export interface RegistrationRequest {
    /**
     * 
     * @type {RegistrationRequestEnrollmentRequest}
     * @memberof RegistrationRequest
     */
    enrollmentRequest: RegistrationRequestEnrollmentRequest;
    /**
     * 
     * @type {RegistrationRequestTerms}
     * @memberof RegistrationRequest
     */
    terms: RegistrationRequestTerms;
    /**
     * 
     * @type {RegistrationRequestChild}
     * @memberof RegistrationRequest
     */
    child: RegistrationRequestChild;
    /**
     * 
     * @type {RegistrationRequestEmergencyContact}
     * @memberof RegistrationRequest
     */
    emergencyContact?: RegistrationRequestEmergencyContact;
    /**
     * 
     * @type {RegistrationRequestParent}
     * @memberof RegistrationRequest
     */
    parent: RegistrationRequestParent;
}
/**
 * 
 * @export
 * @interface RegistrationRequestChild
 */
export interface RegistrationRequestChild {
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestChild
     */
    chineseName?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestChild
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestChild
     */
    avatarUrl: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestChild
     */
    gender: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestChild
     */
    allergies?: string;
    /**
     * 
     * @type {MediaDto}
     * @memberof RegistrationRequestChild
     */
    oldBirthdayCertificate: MediaDto | null;
    /**
     * 
     * @type {MediaDto}
     * @memberof RegistrationRequestChild
     */
    birthdayCertificate: MediaDto | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestChild
     */
    dateOfBirth: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestChild
     */
    schoolName: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestChild
     */
    schoolId: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestChild
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestChild
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestChild
     */
    id: string;
}
/**
 * 
 * @export
 * @interface RegistrationRequestEmergencyContact
 */
export interface RegistrationRequestEmergencyContact {
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestEmergencyContact
     */
    otherUserType: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestEmergencyContact
     */
    userType: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestEmergencyContact
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestEmergencyContact
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestEmergencyContact
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestEmergencyContact
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestEmergencyContact
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface RegistrationRequestEnrollmentRequest
 */
export interface RegistrationRequestEnrollmentRequest {
    /**
     * 
     * @type {Array<EarlyYearsValidDaysPerWeek>}
     * @memberof RegistrationRequestEnrollmentRequest
     */
    daysPerWeek?: Array<EarlyYearsValidDaysPerWeek>;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestEnrollmentRequest
     */
    promoCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationRequestEnrollmentRequest
     */
    transportRequired: boolean;
    /**
     * 
     * @type {RegistrationLogProgrammeEnum}
     * @memberof RegistrationRequestEnrollmentRequest
     */
    programme: RegistrationLogProgrammeEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestEnrollmentRequest
     */
    startDate: string;
    /**
     * 
     * @type {RegistrationLogTypeEnum}
     * @memberof RegistrationRequestEnrollmentRequest
     */
    type: RegistrationLogTypeEnum;
}
/**
 * 
 * @export
 * @interface RegistrationRequestParent
 */
export interface RegistrationRequestParent {
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestParent
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestParent
     */
    postCode: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestParent
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestParent
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestParent
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestParent
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestParent
     */
    firstName: string;
}
/**
 * 
 * @export
 * @interface RegistrationRequestTerms
 */
export interface RegistrationRequestTerms {
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationRequestTerms
     */
    isAccepted: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestTerms
     */
    acceptedPhrase: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequestTerms
     */
    name: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RelationshipTypeEnum {
    Parent = 'parent',
    Guardian = 'guardian'
}

/**
 * 
 * @export
 * @interface RemoveBulkEnrolRequest
 */
export interface RemoveBulkEnrolRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<string>}
     * @memberof RemoveBulkEnrolRequest
     */
    bulkEnrolIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RemoveBulkEnrolRequest
     */
    courseInstanceReferenceId: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveBulkEnrolRequest
     */
    reasonId: string;
}
/**
 * 
 * @export
 * @interface RemoveRoomBookingInvoiceLineRequest
 */
export interface RemoveRoomBookingInvoiceLineRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RemoveRoomBookingInvoiceLineRequest
     */
    invoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveRoomBookingInvoiceLineRequest
     */
    courseInstanceId: string;
}
/**
 * 
 * @export
 * @interface ReplacementAvailabilityDto
 */
export interface ReplacementAvailabilityDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ReplacementAvailabilityDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementAvailabilityDto
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementAvailabilityDto
     */
    roomName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementAvailabilityDto
     */
    teacherName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementAvailabilityDto
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReplacementAvailabilityDto
     */
    isSameTime?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplacementAvailabilityDto
     */
    isBeforePeriod?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReplacementAvailabilityDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string | number}
     * @memberof ReplacementAvailabilityDto
     */
    roomCapacity?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof ReplacementAvailabilityDto
     */
    bufferNumber?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof ReplacementAvailabilityDto
     */
    numOfSeat?: string | number;
    /**
     * 
     * @type {string | number}
     * @memberof ReplacementAvailabilityDto
     */
    studentCount?: string | number;
}
/**
 * 
 * @export
 * @interface ReplacementConfigAttributes
 */
export interface ReplacementConfigAttributes {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ReplacementConfigAttributes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementConfigAttributes
     */
    configuration?: string;
    /**
     * 
     * @type {number}
     * @memberof ReplacementConfigAttributes
     */
    exchangeAllowed?: number;
    /**
     * 
     * @type {number}
     * @memberof ReplacementConfigAttributes
     */
    noticePeriod?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplacementConfigAttributes
     */
    penalty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplacementConfigAttributes
     */
    allowOverriding?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReplacementConfigAttributes
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementConfigAttributes
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementConfigAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementConfigAttributes
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReplacementConfigAttributes
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplacementConfigAttributes
     */
    isUnlimitedReplacement?: boolean;
}
/**
 * 
 * @export
 * @interface ReplacementConfigDto
 */
export interface ReplacementConfigDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string | number}
     * @memberof ReplacementConfigDto
     */
    exchangeAllowed: string | number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplacementConfigDto
     */
    allowOverriding: boolean;
    /**
     * 
     * @type {string | number}
     * @memberof ReplacementConfigDto
     */
    noticePeriod: string | number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplacementConfigDto
     */
    penalty: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplacementConfigDto
     */
    isUnlimitedReplacement: boolean;
}
/**
 * 
 * @export
 * @interface ReplacementForEpTeacherRequest
 */
export interface ReplacementForEpTeacherRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ReplacementForEpTeacherRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementForEpTeacherRequest
     */
    oldSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementForEpTeacherRequest
     */
    newSessionId: string;
}
/**
 * 
 * @export
 * @interface ReplacementHistoryAttributes
 */
export interface ReplacementHistoryAttributes {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ReplacementHistoryAttributes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementHistoryAttributes
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementHistoryAttributes
     */
    fromSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementHistoryAttributes
     */
    toSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementHistoryAttributes
     */
    approverId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementHistoryAttributes
     */
    replacementTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementHistoryAttributes
     */
    remark?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementHistoryAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementHistoryAttributes
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementHistoryAttributes
     */
    originalSession?: string;
}
/**
 * 
 * @export
 * @interface ReplyTicketDto
 */
export interface ReplyTicketDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ReplyTicketDto
     */
    ticketId: string;
    /**
     * 
     * @type {string}
     * @memberof ReplyTicketDto
     */
    commentText?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReplyTicketDto
     */
    attachmentIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface RescheduleCourseInstanceEPDto
 */
export interface RescheduleCourseInstanceEPDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RescheduleCourseInstanceEPDto
     */
    epId?: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleCourseInstanceEPDto
     */
    epName?: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleCourseInstanceEPDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleCourseInstanceEPDto
     */
    oldCourse?: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleCourseInstanceEPDto
     */
    newCourse?: string;
}
/**
 * 
 * @export
 * @interface RescheduleForEpTeacherRequest
 */
export interface RescheduleForEpTeacherRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RescheduleForEpTeacherRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleForEpTeacherRequest
     */
    oldInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleForEpTeacherRequest
     */
    newSessionId: string;
}
/**
 * 
 * @export
 * @interface RescheduleRequest
 */
export interface RescheduleRequest {
    /**
     * 
     * @type {string}
     * @memberof RescheduleRequest
     */
    remark?: string;
    /**
     * 
     * @type {number}
     * @memberof RescheduleRequest
     */
    price: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RescheduleRequest
     */
    toCourseSessionIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RescheduleRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleRequest
     */
    toCourseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleRequest
     */
    fromCourseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleRequest
     */
    enrollmentId?: string;
}
/**
 * 
 * @export
 * @interface ReservedSeatDto
 */
export interface ReservedSeatDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    studentAvatar?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    studentChineseName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    parentFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    expiredDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    expiredTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    shareLink?: string;
    /**
     * 
     * @type {number}
     * @memberof ReservedSeatDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    remark?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    courseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    endDateSession?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReservedSeatDto
     */
    courseSessionIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    remarks?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    courseSessionStartTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    courseSessionEndTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    reservedSeatsType?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservedSeatDto
     */
    sendEnrollmentLinkStatus?: string;
}
/**
 * 
 * @export
 * @interface RevenueRecognitionDto
 */
export interface RevenueRecognitionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RevenueRecognitionDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {number}
     * @memberof RevenueRecognitionDto
     */
    flatFeeAmount: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueRecognitionDto
     */
    takeRateAmount: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueRecognitionDto
     */
    studentBookingFeeAmount: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueRecognitionDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof RevenueRecognitionDto
     */
    courseSessionId?: string;
    /**
     * 
     * @type {number}
     * @memberof RevenueRecognitionDto
     */
    gstPercent: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueRecognitionDto
     */
    gstCompanyPercent: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueRecognitionDto
     */
    numberOfSession: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueRecognitionDto
     */
    numberOfStudent?: number;
    /**
     * 
     * @type {string}
     * @memberof RevenueRecognitionDto
     */
    rateType: string;
    /**
     * 
     * @type {string}
     * @memberof RevenueRecognitionDto
     */
    educationProviderId: string;
    /**
     * 
     * @type {string}
     * @memberof RevenueRecognitionDto
     */
    invoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof RevenueRecognitionDto
     */
    userId?: string;
    /**
     * 
     * @type {number}
     * @memberof RevenueRecognitionDto
     */
    sessionValue?: number;
    /**
     * 
     * @type {string}
     * @memberof RevenueRecognitionDto
     */
    sessionDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RevenueRecognitionDto
     */
    isTrialSingleSession?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RevenueRecognitionDto
     */
    sessionPackRevenueShare?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RevenueRecognitionDto
     */
    isEpPaid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RevenueRecognitionDto
     */
    showOnSoaInvoice?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RevenueRecognitionDto
     */
    discountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueRecognitionDto
     */
    epBears?: number;
}
/**
 * 
 * @export
 * @interface RolesAttributes
 */
export interface RolesAttributes {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RolesAttributes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RolesAttributes
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesAttributes
     */
    key?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RolesAttributes
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RolesAttributes
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesAttributes
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesAttributes
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface RolesPermissionsDto
 */
export interface RolesPermissionsDto {
    [key: string]: object | any;

    /**
     * 
     * @type {PickRolesAttributesIdOrName}
     * @memberof RolesPermissionsDto
     */
    role: PickRolesAttributesIdOrName;
    /**
     * 
     * @type {PickPermissionsAttributesIdOrName}
     * @memberof RolesPermissionsDto
     */
    permission: PickPermissionsAttributesIdOrName;
    /**
     * 
     * @type {string}
     * @memberof RolesPermissionsDto
     */
    id: string;
    /**
     * 
     * @type {AccessScopeEnum}
     * @memberof RolesPermissionsDto
     */
    accessScope: AccessScopeEnum;
}
/**
 * 
 * @export
 * @interface RoomBookingChartDto
 */
export interface RoomBookingChartDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    bookedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    roomTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    roomName?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    roomId: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    checkInType?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    bookedFor?: RoomBookingChartDtoBookedForEnum;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    courseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    groupSession?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    notes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RoomBookingChartDto
     */
    isRecurringSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof RoomBookingChartDto
     */
    capacity?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomBookingChartDto
     */
    roomSize?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingChartDto
     */
    epName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RoomBookingChartDtoBookedForEnum {
    Course = 'course',
    Meeting = 'meeting'
}

/**
 * 
 * @export
 * @interface RoomBookingDto
 */
export interface RoomBookingDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RoomBookingDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingDto
     */
    bookedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingDto
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingDto
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingDto
     */
    roomTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingDto
     */
    roomName?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingDto
     */
    roomId: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingDto
     */
    checkInType?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomBookingDto
     */
    bookedFor?: RoomBookingDtoBookedForEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RoomBookingDtoBookedForEnum {
    Course = 'course',
    Meeting = 'meeting'
}

/**
 * 
 * @export
 * @interface RoomDto
 */
export interface RoomDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RoomDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RoomDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof RoomDto
     */
    capacity?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomDto
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomDto
     */
    updateBy?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomDto
     */
    updatedByName?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomDto
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomDto
     */
    roomTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomDto
     */
    roomTypeName?: string;
    /**
     * 
     * @type {number}
     * @memberof RoomDto
     */
    bufferNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomDto
     */
    createdBy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RoomDto
     */
    allowOverbooking?: boolean;
    /**
     * 
     * @type {Array<RoomEquipmentDto>}
     * @memberof RoomDto
     */
    equipments?: Array<RoomEquipmentDto>;
    /**
     * 
     * @type {number}
     * @memberof RoomDto
     */
    price?: number;
}
/**
 * 
 * @export
 * @interface RoomEquipmentDto
 */
export interface RoomEquipmentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RoomEquipmentDto
     */
    equipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomEquipmentDto
     */
    equipmentName?: string;
    /**
     * 
     * @type {number}
     * @memberof RoomEquipmentDto
     */
    quantity?: number;
}
/**
 * 
 * @export
 * @interface RoomReserveDto
 */
export interface RoomReserveDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof RoomReserveDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomReserveDto
     */
    roomId?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomReserveDto
     */
    bookedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomReserveDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomReserveDto
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomReserveDto
     */
    bookedFor?: RoomReserveDtoBookedForEnum;
    /**
     * 
     * @type {string}
     * @memberof RoomReserveDto
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomReserveDto
     */
    categoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomReserveDto
     */
    educationProviderId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RoomReserveDtoBookedForEnum {
    Meeting = 'meeting',
    Course = 'course'
}

/**
 * 
 * @export
 * @interface SEODataDto
 */
export interface SEODataDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SEODataDto
     */
    metaDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof SEODataDto
     */
    metaKeywords?: string;
    /**
     * 
     * @type {string}
     * @memberof SEODataDto
     */
    pageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof SEODataDto
     */
    metaImage?: string;
}
/**
 * 
 * @export
 * @interface SampleStudentScheduleDto
 */
export interface SampleStudentScheduleDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SampleStudentScheduleDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SampleStudentScheduleDto
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof SampleStudentScheduleDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof SampleStudentScheduleDto
     */
    backgroundImage?: string;
    /**
     * 
     * @type {string}
     * @memberof SampleStudentScheduleDto
     */
    backgroundColor?: string;
}
/**
 * 
 * @export
 * @interface SampleStudentScheduleResponse
 */
export interface SampleStudentScheduleResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {SampleStudentScheduleDto}
     * @memberof SampleStudentScheduleResponse
     */
    data: SampleStudentScheduleDto;
}
/**
 * 
 * @export
 * @interface SaveCardForBillingRequest
 */
export interface SaveCardForBillingRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingRequest
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingRequest
     */
    customerEmail: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingRequest
     */
    customerName: string;
    /**
     * 
     * @type {number}
     * @memberof SaveCardForBillingRequest
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingRequest
     */
    paymentMethod: SaveCardForBillingRequestPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingRequest
     */
    subscriptionId: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveCardForBillingRequest
     */
    sendEmail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingRequest
     */
    saveCard?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingRequest
     */
    cycle?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingRequest
     */
    startDate?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SaveCardForBillingRequestPaymentMethodEnum {
    Giro = 'giro',
    Card = 'card'
}

/**
 * 
 * @export
 * @interface SaveCardForBillingResponse
 */
export interface SaveCardForBillingResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    business_recurring_plans_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    customer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    customer_email?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    cycle?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    currency?: string;
    /**
     * 
     * @type {number}
     * @memberof SaveCardForBillingResponse
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof SaveCardForBillingResponse
     */
    times_to_be_charged?: number;
    /**
     * 
     * @type {number}
     * @memberof SaveCardForBillingResponse
     */
    times_charged?: number;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveCardForBillingResponse
     */
    send_email?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SaveCardForBillingResponse
     */
    save_card?: number;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    redirect_url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SaveCardForBillingResponse
     */
    payment_methods?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    expires_at?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCardForBillingResponse
     */
    webhook?: string;
}
/**
 * 
 * @export
 * @interface SaveDeviceTokenRequest
 */
export interface SaveDeviceTokenRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SaveDeviceTokenRequest
     */
    tokenId: string;
    /**
     * 
     * @type {MobilePlatformType}
     * @memberof SaveDeviceTokenRequest
     */
    mobilePlatform?: MobilePlatformType;
}
/**
 * 
 * @export
 * @interface ScheduleJobAttributes
 */
export interface ScheduleJobAttributes {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ScheduleJobAttributes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleJobAttributes
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ScheduleJobAttributes
     */
    numberOfRecordsAdded?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleJobAttributes
     */
    numberOfRecordsRemoved?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleJobAttributes
     */
    status?: ScheduleJobAttributesStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleJobAttributes
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ScheduleJobAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleJobAttributes
     */
    updatedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ScheduleJobAttributesStatusEnum {
    Completed = 'completed',
    Failed = 'failed',
    Processing = 'processing'
}

/**
 * 
 * @export
 * @interface SchedulesDto
 */
export interface SchedulesDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SchedulesDto
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulesDto
     */
    childrenId?: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulesDto
     */
    childrenName?: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulesDto
     */
    childrenColor?: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulesDto
     */
    ageGroupName?: string;
    /**
     * 
     * @type {number}
     * @memberof SchedulesDto
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof SchedulesDto
     */
    maxAge?: number;
    /**
     * 
     * @type {Array<CourseSessionDto>}
     * @memberof SchedulesDto
     */
    courseSessions?: Array<CourseSessionDto>;
    /**
     * 
     * @type {Array<AgoraCareUsageDto>}
     * @memberof SchedulesDto
     */
    agoraCareUsage?: Array<AgoraCareUsageDto>;
}
/**
 * 
 * @export
 * @interface SchoolDto
 */
export interface SchoolDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SchoolDto
     */
    id: string | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolDto
     */
    createdByFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolDto
     */
    updatedByFullName?: string;
    /**
     * 
     * @type {Array<ClassDto>}
     * @memberof SchoolDto
     */
    classes?: Array<ClassDto>;
    /**
     * 
     * @type {string}
     * @memberof SchoolDto
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolDto
     */
    email?: string;
    /**
     * 
     * @type {CompanyDto}
     * @memberof SchoolDto
     */
    venue?: CompanyDto;
}
/**
 * 
 * @export
 * @interface SelectDto
 */
export interface SelectDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ItemSelectDto>}
     * @memberof SelectDto
     */
    items: Array<ItemSelectDto>;
}
/**
 * 
 * @export
 * @interface SelectOptionDto
 */
export interface SelectOptionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SelectOptionDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDto
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SelectOptionDto
     */
    rate?: number;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDto
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDto
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDto
     */
    startTime?: string;
}
/**
 * 
 * @export
 * @interface SendBroadcastRequest
 */
export interface SendBroadcastRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<string>}
     * @memberof SendBroadcastRequest
     */
    courseInstanceIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendBroadcastRequest
     */
    parentIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendBroadcastRequest
     */
    studentIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SendBroadcastRequest
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof SendBroadcastRequest
     */
    contentType: SendBroadcastRequestContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SendBroadcastRequest
     */
    fileName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SendBroadcastRequestContentTypeEnum {
    Text = 'text',
    Image = 'image',
    Document = 'document'
}

/**
 * 
 * @export
 * @interface SendMessageRequest
 */
export interface SendMessageRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SendMessageRequest
     */
    messageGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof SendMessageRequest
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof SendMessageRequest
     */
    contentType: SendMessageRequestContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SendMessageRequest
     */
    fileName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SendMessageRequestContentTypeEnum {
    Text = 'text',
    Image = 'image',
    Document = 'document'
}

/**
 * 
 * @export
 * @interface SendNotifyReplacementRequest
 */
export interface SendNotifyReplacementRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SendNotifyReplacementRequest
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof SendNotifyReplacementRequest
     */
    parentName: string;
    /**
     * 
     * @type {string}
     * @memberof SendNotifyReplacementRequest
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof SendNotifyReplacementRequest
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof SendNotifyReplacementRequest
     */
    oldCourseInstanceId: string;
}
/**
 * 
 * @export
 * @interface SendNotifyResponse
 */
export interface SendNotifyResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<BatchNotificationEmailDto>}
     * @memberof SendNotifyResponse
     */
    data: Array<BatchNotificationEmailDto>;
}
/**
 * 
 * @export
 * @interface SendSmsResponse
 */
export interface SendSmsResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SmsDto>}
     * @memberof SendSmsResponse
     */
    data: Array<SmsDto>;
}
/**
 * 
 * @export
 * @interface SentEmailsAttributes
 */
export interface SentEmailsAttributes {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    htmlBody?: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    textBody?: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    lastStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    receivedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SentEmailsAttributes
     */
    trackOpens?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    trackLinks?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SentEmailsAttributes
     */
    recipients?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    messageEvents?: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    messageId?: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    metaData?: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    sendFrom?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SentEmailsAttributes
     */
    isSynchronizedDetails?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof SentEmailsAttributes
     */
    brandId?: string;
}
/**
 * 
 * @export
 * @interface SessionPackAdditionDto
 */
export interface SessionPackAdditionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    courseSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionPackAdditionDto
     */
    sessionUsed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SessionPackAdditionDto
     */
    sessionRefunded?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SessionPackAdditionDto
     */
    sessionTotal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SessionPackAdditionDto
     */
    sessionRemaining?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SessionPackAdditionDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    expiryDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    startDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    status?: SessionPackAdditionDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    purchaseDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    sessionPackAdditionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SessionPackAdditionDto
     */
    sessionPackName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SessionPackAdditionDtoStatusEnum {
    Reserved = 'reserved',
    Active = 'active',
    Expired = 'expired',
    Consumed = 'consumed',
    PartiallyRefunded = 'partially_refunded'
}

/**
 * 
 * @export
 * @interface SessionPackDto
 */
export interface SessionPackDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SessionPackDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof SessionPackDto
     */
    numberOfSessions?: number;
    /**
     * 
     * @type {number}
     * @memberof SessionPackDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof SessionPackDto
     */
    validFor?: number;
    /**
     * 
     * @type {number}
     * @memberof SessionPackDto
     */
    revenueShare?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionPackDto
     */
    status?: SessionPackDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SessionPackDto
     */
    showOnWebsite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionPackDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackDto
     */
    statusDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackDto
     */
    courseSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackDto
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackDto
     */
    providerSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackDto
     */
    providerImage?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionPackDto
     */
    gstPercent?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SessionPackDto
     */
    isSuppressExpiryRunningLowAlerts?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionPackDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackDto
     */
    schoolId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SessionPackDtoStatusEnum {
    Active = 'active',
    Inactive = 'inactive'
}

/**
 * 
 * @export
 * @interface SessionPackUsageDto
 */
export interface SessionPackUsageDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SessionPackUsageDto
     */
    sessionPackUsageId?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionPackUsageDto
     */
    numberOfSessions?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionPackUsageDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackUsageDto
     */
    studentId?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionPackUsageDto
     */
    numberOfSessionsUsage: number;
    /**
     * 
     * @type {number}
     * @memberof SessionPackUsageDto
     */
    numberOfSessionsRefund: number;
    /**
     * 
     * @type {number}
     * @memberof SessionPackUsageDto
     */
    numberOfSessionPackLeft: number;
    /**
     * 
     * @type {string}
     * @memberof SessionPackUsageDto
     */
    courseId: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackUsageDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackUsageDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackUsageDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackUsageDto
     */
    courseSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackUsageDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackUsageDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackUsageDto
     */
    courseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPackUsageDto
     */
    studentAvatar?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SessionPackUsageDto
     */
    isSuppressExpiryRunningLowAlerts?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionPackUsageDto
     */
    startDate?: string;
}
/**
 * 
 * @export
 * @interface SessionsAbleCheckinWithSessionPackDto
 */
export interface SessionsAbleCheckinWithSessionPackDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    sessionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    courseSessionIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    courseSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    roomName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    subjectName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    subjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    teacherName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    subjectImage?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    epImage?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    epId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    epName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    attendId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    attendStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    originalCourseSessionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    isReplaced?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    isTrialSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    schoolSlug?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    isCourseInstanceBookingRoom?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    venueId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    hasPriceModelSessionPacks?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    sharedSessionPackStudentId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    useSessionPackStudentId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SessionsAbleCheckinWithSessionPackDto
     */
    isUsedSessionPack?: boolean;
}
/**
 * 
 * @export
 * @interface SetUserTemporaryPasswordByAdminRequest
 */
export interface SetUserTemporaryPasswordByAdminRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SetUserTemporaryPasswordByAdminRequest
     */
    brandId?: string;
    /**
     * 
     * @type {string}
     * @memberof SetUserTemporaryPasswordByAdminRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SetUserTemporaryPasswordByAdminRequest
     */
    userType?: SetUserTemporaryPasswordByAdminRequestUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SetUserTemporaryPasswordByAdminRequest
     */
    email: string;
    /**
     * 
     * @type {IBrand}
     * @memberof SetUserTemporaryPasswordByAdminRequest
     */
    brand?: IBrand | null;
    /**
     * 
     * @type {string}
     * @memberof SetUserTemporaryPasswordByAdminRequest
     */
    venueEmailSender?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SetUserTemporaryPasswordByAdminRequestUserTypeEnum {
    Teacher = 'teacher',
    EpAdmin = 'ep_admin',
    EpAdminTeacher = 'ep_admin_teacher',
    AgoraAdmin = 'agora_admin',
    Parent = 'parent'
}

/**
 * 
 * @export
 * @interface ShoppingCartDto
 */
export interface ShoppingCartDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    shoppingCartId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    cartItemId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    reservedId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    trialId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShoppingCartDto
     */
    exclusiveIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    cartItemCreatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    agoraId?: string;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    cartType?: ShoppingCartDtoCartTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    agoraStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    agoraName?: string;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    totalHours?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    balanceHours?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isAgoraValid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    instanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    courseSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    courseImage?: string;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    courseDuration?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    dateSessionStart?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    dateSessionEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    timeSessionStart?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    timeSessionEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    timeStartDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    timeEndDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    subjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    subjectName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShoppingCartDto
     */
    subjectTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    providerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    ageGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    ageGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    minAge?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    maxAge?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    rangeTime?: string;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    priceOriginal?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    priceOriginalDisplay?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    trialPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    agegroupName?: string;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    reviewers?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    bookmarks?: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    courseStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    courseInstanceStatus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isComingSoon?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    numSessions?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    numOfSeat?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    numOfChild?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    seatsReserved?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isFull?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isGst?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    gstPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    gstAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    fees?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    discount?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    unitPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    typeConflict?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isDuplicateCourse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isConflictTimeEnrollment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isConflictTimeCart?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isStarted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isCanceled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isCourseValid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isReserved?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isTrial?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    expiredTime?: string;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    seatsInCart?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    availableSeats?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isExceedSeatsToBook?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isExceedSeatsToBuy?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    totalPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    enrollmentId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isEnrolled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    promotionStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    promotionEndDate?: string;
    /**
     * 
     * @type {Array<ValidateCourseTimeForPaymentDto>}
     * @memberof ShoppingCartDto
     */
    courseSessions?: Array<ValidateCourseTimeForPaymentDto>;
    /**
     * 
     * @type {Array<ValidateCourseTimeForPaymentDto>}
     * @memberof ShoppingCartDto
     */
    validJoinableSessions?: Array<ValidateCourseTimeForPaymentDto>;
    /**
     * 
     * @type {Array<ValidateCourseTimeForPaymentDto>}
     * @memberof ShoppingCartDto
     */
    activeTimes?: Array<ValidateCourseTimeForPaymentDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShoppingCartDto
     */
    classTypeIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isTrialSingleSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    coursePeriodId?: string | null;
    /**
     * 
     * @type {CampaignDto}
     * @memberof ShoppingCartDto
     */
    campaignApplied?: CampaignDto;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    coursePeriodName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    appliedDiscountType?: ShoppingCartDtoAppliedDiscountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    cartItemData?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    allowConcurrentSessions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    schoolId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    venueId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    allowBookingCourseStarted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    sessionPackId?: string;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    sessionPackNumberOfSessions?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    sessionPackRevenueShare?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    sessionPackValidFor?: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    sessionPackStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    schoolSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    productSku?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isSuppressCourseConfirmationEmail?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    discountAmountByDiscountCode?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    epBears?: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    courseSeriesId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    invoiceLineId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ShoppingCartDtoCartTypeEnum {
    Course = 'course',
    AgoraCare = 'agora_care',
    SessionPack = 'session_pack',
    Product = 'product'
}
/**
    * @export
    * @enum {string}
    */
export enum ShoppingCartDtoAppliedDiscountTypeEnum {
    EarlyBirdPromo = 'early_bird_promo',
    FixedDiscount = 'fixed_discount',
    None = 'none'
}

/**
 * 
 * @export
 * @interface ShoppingCartPolicyContentDto
 */
export interface ShoppingCartPolicyContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartPolicyContentDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartPolicyContentDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartPolicyContentDto
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartPolicyContentDto
     */
    startIcon: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartPolicyContentDto
     */
    endIcon: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartPolicyContentDto
     */
    redirectTo?: string;
}
/**
 * 
 * @export
 * @interface ShoppingCartPolicyContentResponse
 */
export interface ShoppingCartPolicyContentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {ShoppingCartPolicyContentResponseData}
     * @memberof ShoppingCartPolicyContentResponse
     */
    data: ShoppingCartPolicyContentResponseData;
}
/**
 * 
 * @export
 * @interface ShoppingCartPolicyContentResponseData
 */
export interface ShoppingCartPolicyContentResponseData {
    /**
     * 
     * @type {ShoppingCartPolicyContentResponseDataConfirmationText}
     * @memberof ShoppingCartPolicyContentResponseData
     */
    confirmationText: ShoppingCartPolicyContentResponseDataConfirmationText;
    /**
     * 
     * @type {Array<ShoppingCartPolicyContentDto>}
     * @memberof ShoppingCartPolicyContentResponseData
     */
    content: Array<ShoppingCartPolicyContentDto>;
}
/**
 * 
 * @export
 * @interface ShoppingCartPolicyContentResponseDataConfirmationText
 */
export interface ShoppingCartPolicyContentResponseDataConfirmationText {
    /**
     * 
     * @type {ShoppingCartPolicyContentResponseDataConfirmationTextLinkConfig}
     * @memberof ShoppingCartPolicyContentResponseDataConfirmationText
     */
    linkConfig: ShoppingCartPolicyContentResponseDataConfirmationTextLinkConfig;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartPolicyContentResponseDataConfirmationText
     */
    content: string;
}
/**
 * 
 * @export
 * @interface ShoppingCartPolicyContentResponseDataConfirmationTextLinkConfig
 */
export interface ShoppingCartPolicyContentResponseDataConfirmationTextLinkConfig {
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartPolicyContentResponseDataConfirmationTextLinkConfig
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartPolicyContentResponseDataConfirmationTextLinkConfig
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ShowcaseCampaignPanelDto
 */
export interface ShowcaseCampaignPanelDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ShowcaseCampaignPanelDto
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowcaseCampaignPanelDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowcaseCampaignPanelDto
     */
    background?: string;
}
/**
 * 
 * @export
 * @interface SignUpRequest
 */
export interface SignUpRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    promoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    signUpFromPage?: SignUpRequestSignUpFromPageEnum;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    schoolSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    googleRecaptchaToken?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    venueSlug?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SignUpRequestSignUpFromPageEnum {
    AtSchool = 'at_school',
    Agora = 'agora'
}

/**
 * 
 * @export
 * @interface SignUpResponse
 */
export interface SignUpResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SignUpResponse
     */
    sub: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpResponse
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpResponse
     */
    userType: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpResponse
     */
    phoneNumber: string;
}
/**
 * 
 * @export
 * @interface SliderDto
 */
export interface SliderDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SliderDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SliderDto
     */
    backgroundMain: string;
    /**
     * 
     * @type {string}
     * @memberof SliderDto
     */
    backgroundContent: string;
    /**
     * 
     * @type {string}
     * @memberof SliderDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SliderDto
     */
    dotColor: string;
    /**
     * 
     * @type {string}
     * @memberof SliderDto
     */
    dotColorActive: string;
    /**
     * 
     * @type {string}
     * @memberof SliderDto
     */
    imageUrl: string;
}
/**
 * 
 * @export
 * @interface SmsDto
 */
export interface SmsDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SmsDto
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDto
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDto
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface SqsDeleteMessageRequest
 */
export interface SqsDeleteMessageRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SqsDeleteMessageRequest
     */
    receiptHandle: string;
}
/**
 * 
 * @export
 * @interface SqsSendMessageRequest
 */
export interface SqsSendMessageRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SqsSendMessageRequest
     */
    sqsResponseBody: string;
}
/**
 * 
 * @export
 * @interface StartDateForInvoiceLineDto
 */
export interface StartDateForInvoiceLineDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StartDateForInvoiceLineDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof StartDateForInvoiceLineDto
     */
    invoiceLineId?: string;
    /**
     * 
     * @type {string}
     * @memberof StartDateForInvoiceLineDto
     */
    reservedId?: string;
    /**
     * 
     * @type {string}
     * @memberof StartDateForInvoiceLineDto
     */
    reservedStartTime?: string;
    /**
     * 
     * @type {string}
     * @memberof StartDateForInvoiceLineDto
     */
    startTime?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StartDateForInvoiceLineDto
     */
    sessionStartTimes?: Array<string>;
}
/**
 * 
 * @export
 * @interface StaticPageMetaKeywordDto
 */
export interface StaticPageMetaKeywordDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StaticPageMetaKeywordDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof StaticPageMetaKeywordDto
     */
    keywords?: string;
}
/**
 * 
 * @export
 * @interface StaticPageMetaKeywordResponse
 */
export interface StaticPageMetaKeywordResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<StaticPageMetaKeywordDto>}
     * @memberof StaticPageMetaKeywordResponse
     */
    data: Array<StaticPageMetaKeywordDto>;
}
/**
 * 
 * @export
 * @interface StrapiHomepageButtonDto
 */
export interface StrapiHomepageButtonDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StrapiHomepageButtonDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof StrapiHomepageButtonDto
     */
    redirectTo: string;
}
/**
 * 
 * @export
 * @interface StrapiMediaDto
 */
export interface StrapiMediaDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StrapiMediaDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StrapiMediaDto
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface StudentAttendingSessionDto
 */
export interface StudentAttendingSessionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentAttendingSessionDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentAttendingSessionDto
     */
    attendanceStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentAttendingSessionDto
     */
    attendanceCheckedTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentAttendingSessionDto
     */
    checkInTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentAttendingSessionDto
     */
    checkOutTime?: string | null;
}
/**
 * 
 * @export
 * @interface StudentCareAdaptiveLearningDto
 */
export interface StudentCareAdaptiveLearningDto {
    [key: string]: object | any;

    /**
     * 
     * @type {FeatureDto}
     * @memberof StudentCareAdaptiveLearningDto
     */
    feature?: FeatureDto;
    /**
     * 
     * @type {string}
     * @memberof StudentCareAdaptiveLearningDto
     */
    imageUrl?: string;
}
/**
 * 
 * @export
 * @interface StudentCareCurriculumDto
 */
export interface StudentCareCurriculumDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentCareCurriculumDto
     */
    bannerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCareCurriculumDto
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCareCurriculumDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCareCurriculumDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCareCurriculumDto
     */
    pageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCareCurriculumDto
     */
    metaDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCareCurriculumDto
     */
    metaKeywords?: string;
}
/**
 * 
 * @export
 * @interface StudentCareFeatureDto
 */
export interface StudentCareFeatureDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<FeatureDto>}
     * @memberof StudentCareFeatureDto
     */
    featureList?: Array<FeatureDto>;
    /**
     * 
     * @type {string}
     * @memberof StudentCareFeatureDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCareFeatureDto
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface StudentCareFeatureSuccessfulDto
 */
export interface StudentCareFeatureSuccessfulDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<FeatureDto>}
     * @memberof StudentCareFeatureSuccessfulDto
     */
    featureList?: Array<FeatureDto>;
    /**
     * 
     * @type {string}
     * @memberof StudentCareFeatureSuccessfulDto
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface StudentCareFeedbackDto
 */
export interface StudentCareFeedbackDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentCareFeedbackDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCareFeedbackDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCareFeedbackDto
     */
    relationship: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCareFeedbackDto
     */
    avatar: string;
}
/**
 * 
 * @export
 * @interface StudentCareHeroSectionDto
 */
export interface StudentCareHeroSectionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentCareHeroSectionDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCareHeroSectionDto
     */
    subTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCareHeroSectionDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {StrapiHomepageButtonDto}
     * @memberof StudentCareHeroSectionDto
     */
    primaryButton?: StrapiHomepageButtonDto;
    /**
     * 
     * @type {StrapiHomepageButtonDto}
     * @memberof StudentCareHeroSectionDto
     */
    ghostButton?: StrapiHomepageButtonDto;
    /**
     * 
     * @type {SEODataDto}
     * @memberof StudentCareHeroSectionDto
     */
    seo?: SEODataDto;
}
/**
 * 
 * @export
 * @interface StudentCarePageResponse
 */
export interface StudentCarePageResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {StudentCarePageResponseData}
     * @memberof StudentCarePageResponse
     */
    data: StudentCarePageResponseData;
}
/**
 * 
 * @export
 * @interface StudentCarePageResponseData
 */
export interface StudentCarePageResponseData {
    /**
     * 
     * @type {StudentCareFeedbackDto}
     * @memberof StudentCarePageResponseData
     */
    feedback?: StudentCareFeedbackDto;
    /**
     * 
     * @type {StudentCareAdaptiveLearningDto}
     * @memberof StudentCarePageResponseData
     */
    adaptiveLearning?: StudentCareAdaptiveLearningDto;
    /**
     * 
     * @type {StudentCareFeatureSuccessfulDto}
     * @memberof StudentCarePageResponseData
     */
    featureSuccessful?: StudentCareFeatureSuccessfulDto;
    /**
     * 
     * @type {StudentCareSliderDto}
     * @memberof StudentCarePageResponseData
     */
    slider?: StudentCareSliderDto;
    /**
     * 
     * @type {StudentCareFeatureDto}
     * @memberof StudentCarePageResponseData
     */
    featureSection?: StudentCareFeatureDto;
    /**
     * 
     * @type {StudentCareHeroSectionDto}
     * @memberof StudentCarePageResponseData
     */
    heroSection?: StudentCareHeroSectionDto;
}
/**
 * 
 * @export
 * @interface StudentCarePricingAddonsDto
 */
export interface StudentCarePricingAddonsDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<FeatureDto>}
     * @memberof StudentCarePricingAddonsDto
     */
    featureList: Array<FeatureDto>;
    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingAddonsDto
     */
    title: string;
}
/**
 * 
 * @export
 * @interface StudentCarePricingPromotionDto
 */
export interface StudentCarePricingPromotionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingPromotionDto
     */
    title?: string;
    /**
     * 
     * @type {Array<StudentCarePricingPromotionItemDto>}
     * @memberof StudentCarePricingPromotionDto
     */
    items: Array<StudentCarePricingPromotionItemDto>;
}
/**
 * 
 * @export
 * @interface StudentCarePricingPromotionItemDto
 */
export interface StudentCarePricingPromotionItemDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingPromotionItemDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingPromotionItemDto
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface StudentCarePricingSchoolDto
 */
export interface StudentCarePricingSchoolDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingSchoolDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingSchoolDto
     */
    description: string;
    /**
     * 
     * @type {Array<StudentCarePricingSchoolItemDto>}
     * @memberof StudentCarePricingSchoolDto
     */
    items: Array<StudentCarePricingSchoolItemDto>;
}
/**
 * 
 * @export
 * @interface StudentCarePricingSchoolItemDto
 */
export interface StudentCarePricingSchoolItemDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingSchoolItemDto
     */
    schoolName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingSchoolItemDto
     */
    schoolLogo: string;
    /**
     * 
     * @type {number}
     * @memberof StudentCarePricingSchoolItemDto
     */
    order: number;
}
/**
 * 
 * @export
 * @interface StudentCarePricingTableCellDto
 */
export interface StudentCarePricingTableCellDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingTableCellDto
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingTableCellDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StudentCarePricingTableCellDto
     */
    isCheckedIcon?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof StudentCarePricingTableCellDto
     */
    coordinate?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof StudentCarePricingTableCellDto
     */
    isHeader?: boolean;
    /**
     * 
     * @type {StudentCarePricingTableCellDtoPrice}
     * @memberof StudentCarePricingTableCellDto
     */
    price?: StudentCarePricingTableCellDtoPrice;
}
/**
 * 
 * @export
 * @interface StudentCarePricingTableCellDtoPrice
 */
export interface StudentCarePricingTableCellDtoPrice {
    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingTableCellDtoPrice
     */
    alternativeText: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingTableCellDtoPrice
     */
    per: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingTableCellDtoPrice
     */
    amount: string;
}
/**
 * 
 * @export
 * @interface StudentCarePricingTableColumnDto
 */
export interface StudentCarePricingTableColumnDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingTableColumnDto
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof StudentCarePricingTableColumnDto
     */
    coordinateX?: number;
}
/**
 * 
 * @export
 * @interface StudentCarePricingTableDto
 */
export interface StudentCarePricingTableDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingTableDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingTableDto
     */
    subTitle?: string;
    /**
     * 
     * @type {Array<StudentCarePricingTableRowDto>}
     * @memberof StudentCarePricingTableDto
     */
    rows: Array<StudentCarePricingTableRowDto>;
    /**
     * 
     * @type {Array<StudentCarePricingTableColumnDto>}
     * @memberof StudentCarePricingTableDto
     */
    columns: Array<StudentCarePricingTableColumnDto>;
    /**
     * 
     * @type {Array<StudentCarePricingTableCellDto>}
     * @memberof StudentCarePricingTableDto
     */
    cells: Array<StudentCarePricingTableCellDto>;
}
/**
 * 
 * @export
 * @interface StudentCarePricingTableRowDto
 */
export interface StudentCarePricingTableRowDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentCarePricingTableRowDto
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof StudentCarePricingTableRowDto
     */
    coordinateX?: number;
}
/**
 * 
 * @export
 * @interface StudentCareSliderDto
 */
export interface StudentCareSliderDto {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<SliderDto>}
     * @memberof StudentCareSliderDto
     */
    sliderList?: Array<SliderDto>;
    /**
     * 
     * @type {string}
     * @memberof StudentCareSliderDto
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface StudentCareTechnologyHero
 */
export interface StudentCareTechnologyHero {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<TitleConcatenationDto>}
     * @memberof StudentCareTechnologyHero
     */
    titleConcatenations: Array<TitleConcatenationDto>;
    /**
     * 
     * @type {string}
     * @memberof StudentCareTechnologyHero
     */
    subTitle: string;
    /**
     * 
     * @type {SEODataDto}
     * @memberof StudentCareTechnologyHero
     */
    seo: SEODataDto;
}
/**
 * 
 * @export
 * @interface StudentCheckInCheckOutDto
 */
export interface StudentCheckInCheckOutDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentCheckInCheckOutDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckInCheckOutDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckInCheckOutDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckInCheckOutDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckInCheckOutDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckInCheckOutDto
     */
    parentPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckInCheckOutDto
     */
    checkLogId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckInCheckOutDto
     */
    checkInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckInCheckOutDto
     */
    checkOutTime?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StudentCheckInCheckOutDto
     */
    sessionCheckInTimes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StudentCheckInCheckOutDto
     */
    sessionCheckOutTimes?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof StudentCheckInCheckOutDto
     */
    isUseSessionPack?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudentCheckInCheckOutDto
     */
    isCheckedIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudentCheckInCheckOutDto
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StudentCheckInCheckOutDto
     */
    kisiUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StudentCheckInCheckOutDto
     */
    isAllowStudentLeave?: boolean;
}
/**
 * 
 * @export
 * @interface StudentCheckedInHistoryRequest
 */
export interface StudentCheckedInHistoryRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof StudentCheckedInHistoryRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentCheckedInHistoryRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInHistoryRequest
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInHistoryRequest
     */
    studentId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StudentCheckedInHistoryRequest
     */
    isPushNotification?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInHistoryRequest
     */
    checkInLogId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInHistoryRequest
     */
    studentCourseSessionId?: string;
}
/**
 * 
 * @export
 * @interface StudentCheckedInHistoryResponse
 */
export interface StudentCheckedInHistoryResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<StudentCheckedInOutDto>}
     * @memberof StudentCheckedInHistoryResponse
     */
    data: Array<StudentCheckedInOutDto>;
}
/**
 * 
 * @export
 * @interface StudentCheckedInOutDto
 */
export interface StudentCheckedInOutDto {
    [key: string]: object | any;

    /**
     * 
     * @type {CheckInType}
     * @memberof StudentCheckedInOutDto
     */
    type: CheckInType;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    checkInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    checkOutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    sessionTime?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    studentName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    parentId: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    parentName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    venueName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    sortDate?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    checkedInPictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    checkedOutPictureUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof StudentCheckedInOutDto
     */
    numberOfTemperatures?: number;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    studentCourseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCheckedInOutDto
     */
    sessionDate?: string;
}
/**
 * 
 * @export
 * @interface StudentCourseSessionAttributes
 */
export interface StudentCourseSessionAttributes {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    courseSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    status?: StudentCourseSessionAttributesStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    attendanceChecked?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StudentCourseSessionAttributes
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    originalSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StudentCourseSessionAttributes
     */
    isReplaced?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudentCourseSessionAttributes
     */
    isTrialSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    approverId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StudentCourseSessionAttributes
     */
    isCancelled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudentCourseSessionAttributes
     */
    isWithdrawn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    checkinTime?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    checkoutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    invoiceLineId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StudentCourseSessionAttributes
     */
    isSessionPack?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    checkinSource?: StudentCourseSessionAttributesCheckinSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    checkoutSource?: StudentCourseSessionAttributesCheckoutSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    studentSessionType?: StudentCourseSessionAttributesStudentSessionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    checkoutByName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    checkoutByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    checkinByName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCourseSessionAttributes
     */
    checkinByUserId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum StudentCourseSessionAttributesStatusEnum {
    Absent = 'absent',
    Attending = 'attending',
    NotAttending = 'not_attending',
    Refunded = 'refunded',
    Rescheduled = 'rescheduled'
}
/**
    * @export
    * @enum {string}
    */
export enum StudentCourseSessionAttributesCheckinSourceEnum {
    AdminPortal = 'admin-portal',
    Kisi = 'kisi',
    MobileApp = 'mobile-app'
}
/**
    * @export
    * @enum {string}
    */
export enum StudentCourseSessionAttributesCheckoutSourceEnum {
    AdminPortal = 'admin-portal',
    Kisi = 'kisi',
    MobileApp = 'mobile-app'
}
/**
    * @export
    * @enum {string}
    */
export enum StudentCourseSessionAttributesStudentSessionTypeEnum {
    Makeup = 'makeup',
    Trial = 'trial'
}

/**
 * 
 * @export
 * @interface StudentCoursesDto
 */
export interface StudentCoursesDto {
    /**
     * 
     * @type {string}
     * @memberof StudentCoursesDto
     */
    courseId: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCoursesDto
     */
    courseName: string;
}
/**
 * 
 * @export
 * @interface StudentCoursesLevelDto
 */
export interface StudentCoursesLevelDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentCoursesLevelDto
     */
    courseName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCoursesLevelDto
     */
    courseId: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCoursesLevelDto
     */
    providerId: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCoursesLevelDto
     */
    providerName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCoursesLevelDto
     */
    studentId: string;
}
/**
 * 
 * @export
 * @interface StudentImportDto
 */
export interface StudentImportDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentImportDto
     */
    studentFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentImportDto
     */
    studentLastName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentImportDto
     */
    studentGender: StudentImportDtoStudentGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof StudentImportDto
     */
    studentDateOfBirth: string;
    /**
     * 
     * @type {string}
     * @memberof StudentImportDto
     */
    className: string;
    /**
     * 
     * @type {string}
     * @memberof StudentImportDto
     */
    gradeLevelId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum StudentImportDtoStudentGenderEnum {
    Male = 'male',
    Female = 'female'
}

/**
 * 
 * @export
 * @interface StudentNoteTypeDto
 */
export interface StudentNoteTypeDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentNoteTypeDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StudentNoteTypeDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StudentNoteTypeDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof StudentNoteTypeDto
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface StudentNotesDto
 */
export interface StudentNotesDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentNotesDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentNotesDto
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentNotesDto
     */
    typeId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentNotesDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentNotesDto
     */
    courseId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StudentNotesDto
     */
    courseInstanceIds?: Array<string>;
    /**
     * 
     * @type {Array<CourseInstanceDto>}
     * @memberof StudentNotesDto
     */
    courseInstances?: Array<CourseInstanceDto>;
    /**
     * 
     * @type {boolean}
     * @memberof StudentNotesDto
     */
    showOnCheckin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StudentNotesDto
     */
    note?: string;
    /**
     * 
     * @type {Array<MediaDto>}
     * @memberof StudentNotesDto
     */
    media?: Array<MediaDto>;
    /**
     * 
     * @type {boolean}
     * @memberof StudentNotesDto
     */
    pin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StudentNotesDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentNotesDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentNotesDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentNotesDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentNotesDto
     */
    epName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentNotesDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentNotesDto
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface StudentSessionDatesDto
 */
export interface StudentSessionDatesDto {
    /**
     * 
     * @type {string}
     * @memberof StudentSessionDatesDto
     */
    sessionDate: string;
}
/**
 * 
 * @export
 * @interface StudentSessionTimeCheckinDto
 */
export interface StudentSessionTimeCheckinDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof StudentSessionTimeCheckinDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentSessionTimeCheckinDto
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentSessionTimeCheckinDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentSessionTimeCheckinDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentSessionTimeCheckinDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentSessionTimeCheckinDto
     */
    timeLabel?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum StudentSessionType {
    Trial = 'trial',
    Makeup = 'makeup'
}

/**
 * 
 * @export
 * @interface SubjectDto
 */
export interface SubjectDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SubjectDto
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubjectDto
     */
    subjectType?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubjectDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectDto
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SubjectDto
     */
    costFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectDto
     */
    costTo?: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectDto
     */
    createAt?: string;
    /**
     * 
     * @type {MediaDto}
     * @memberof SubjectDto
     */
    media?: MediaDto;
    /**
     * 
     * @type {string}
     * @memberof SubjectDto
     */
    newestCourseCreatedDate?: string;
}
/**
 * 
 * @export
 * @interface SubjectFilterDto
 */
export interface SubjectFilterDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SubjectFilterDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectFilterDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectFilterDto
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SubjectFilterDto
     */
    costFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectFilterDto
     */
    costTo?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectFilterDto
     */
    availability: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectFilterDto
     */
    comingSoon: number;
}
/**
 * 
 * @export
 * @interface SubjectSummaryDto
 */
export interface SubjectSummaryDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SubjectSummaryDto
     */
    subjectId: string;
    /**
     * 
     * @type {number}
     * @memberof SubjectSummaryDto
     */
    availability: number;
}
/**
 * 
 * @export
 * @interface SubmitForApprovalInvoiceRequest
 */
export interface SubmitForApprovalInvoiceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SubmitForApprovalInvoiceRequest
     */
    invoiceId: string;
}
/**
 * 
 * @export
 * @interface SubscriptionDto
 */
export interface SubscriptionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    collectionDate?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDto
     */
    invoiceDueDays?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    status?: SubscriptionDtoStatusEnum;
    /**
     * 
     * @type {SubscriptionPaymentMethod}
     * @memberof SubscriptionDto
     */
    paymentMethod?: SubscriptionPaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    referenceId?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDto
     */
    amountLeftToPay?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {CompanyDto}
     * @memberof SubscriptionDto
     */
    venueFrom?: CompanyDto;
    /**
     * 
     * @type {UserDto}
     * @memberof SubscriptionDto
     */
    parentTo?: UserDto;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionDto
     */
    isInvoiceProduct?: boolean;
    /**
     * 
     * @type {Array<SubscriptionLineDto>}
     * @memberof SubscriptionDto
     */
    subscriptionLines?: Array<SubscriptionLineDto>;
    /**
     * 
     * @type {Array<AuditHistoryDto>}
     * @memberof SubscriptionDto
     */
    histories?: Array<AuditHistoryDto>;
    /**
     * 
     * @type {ProviderDto}
     * @memberof SubscriptionDto
     */
    provider?: ProviderDto;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    sentDate?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDto
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDto
     */
    subTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDto
     */
    discount?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDto
     */
    gst?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    lastChargeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    nextChargeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    frequency?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    setupLink?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    userCardId?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDto
     */
    unitPrice2?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionDto
     */
    isManualPayment?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum SubscriptionDtoStatusEnum {
    Active = 'active',
    Canceled = 'canceled',
    Draft = 'draft',
    Failed = 'failed',
    Sent = 'sent'
}

/**
 * 
 * @export
 * @interface SubscriptionLineDto
 */
export interface SubscriptionLineDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    subscriptionId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    subscriptionReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    productType?: SubscriptionLineDtoProductTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    productCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionLineDto
     */
    unitPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionLineDto
     */
    gstPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionLineDto
     */
    gstAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionLineDto
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionLineDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionLineDto
     */
    nett?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionLineDto
     */
    nett2?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionLineDto
     */
    autoReEnrol?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    discountId?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionLineDto
     */
    discountAmount?: number;
    /**
     * 
     * @type {string | number}
     * @memberof SubscriptionLineDto
     */
    discountInput?: string | number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    discountInputLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    discountType?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    courseSeriesId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    sessionPackId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    firstStartTime?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    studentFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    parentFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    enrollmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    reservedId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    courseInstanceReferenceId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionLineDto
     */
    courseSessionIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    invoiceLineReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    invoiceLineId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    subscriptionCollectionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    subscriptionStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    subscriptionPaymentMethod?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof SubscriptionLineDto
     */
    subscriptionParent?: UserDto;
    /**
     * 
     * @type {CompanyDto}
     * @memberof SubscriptionLineDto
     */
    subscriptionVenue?: CompanyDto;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    subscriptionSentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDto
     */
    subscriptionChargeDate?: string;
    /**
     * 
     * @type {SubscriptionLineDtoSubscriptionData}
     * @memberof SubscriptionLineDto
     */
    subscriptionData?: SubscriptionLineDtoSubscriptionData;
    /**
     * 
     * @type {SubscriptionLineStatusType}
     * @memberof SubscriptionLineDto
     */
    status?: SubscriptionLineStatusType;
    /**
     * 
     * @type {ProductDto}
     * @memberof SubscriptionLineDto
     */
    product?: ProductDto;
    /**
     * 
     * @type {ChildDto}
     * @memberof SubscriptionLineDto
     */
    student?: ChildDto;
    /**
     * 
     * @type {BusinessUnitDto}
     * @memberof SubscriptionLineDto
     */
    businessUnit?: BusinessUnitDto;
    /**
     * 
     * @type {SubscriptionDto}
     * @memberof SubscriptionLineDto
     */
    recurringPayment?: SubscriptionDto;
}

/**
    * @export
    * @enum {string}
    */
export enum SubscriptionLineDtoProductTypeEnum {
    Course = 'course',
    SessionPack = 'session_pack'
}

/**
 * 
 * @export
 * @interface SubscriptionLineDtoSubscriptionData
 */
export interface SubscriptionLineDtoSubscriptionData {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    notes?: string;
    /**
     * 
     * @type {SubscriptionLineDtoSubscriptionDataInvoice}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    invoice?: SubscriptionLineDtoSubscriptionDataInvoice;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    userCardId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    setupLink?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    chargeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    sentDate?: string;
    /**
     * 
     * @type {CompanyDto}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    venue?: CompanyDto;
    /**
     * 
     * @type {UserDto}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    parent?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    paymentMethod?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    collectionDate: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    referenceId: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionData
     */
    id: string;
}
/**
 * 
 * @export
 * @interface SubscriptionLineDtoSubscriptionDataInvoice
 */
export interface SubscriptionLineDtoSubscriptionDataInvoice {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionDataInvoice
     */
    paidDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionDataInvoice
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionDataInvoice
     */
    referenceId: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLineDtoSubscriptionDataInvoice
     */
    id: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SubscriptionLineStatusType {
    Active = 'active',
    Paused = 'paused'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum SubscriptionPaymentMethod {
    Giro = 'giro',
    CreditCard = 'credit_card',
    ManualCreditCardOrManualPaynow = 'manual_credit_card_or_manual_paynow',
    ManualBankTransfer = 'manual_bank_transfer',
    Egiro = 'egiro'
}

/**
 * 
 * @export
 * @interface TeacherAddRoomMediaRequest
 */
export interface TeacherAddRoomMediaRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<MediaDto>}
     * @memberof TeacherAddRoomMediaRequest
     */
    mediaList: Array<MediaDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeacherAddRoomMediaRequest
     */
    oldMediaIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface TeacherAddRoomMediaResponse
 */
export interface TeacherAddRoomMediaResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {TeacherAddRoomMediaResponseData}
     * @memberof TeacherAddRoomMediaResponse
     */
    data: TeacherAddRoomMediaResponseData;
}
/**
 * 
 * @export
 * @interface TeacherAddRoomMediaResponseData
 */
export interface TeacherAddRoomMediaResponseData {
    /**
     * 
     * @type {Array<string>}
     * @memberof TeacherAddRoomMediaResponseData
     */
    mediaIds: Array<string>;
}
/**
 * 
 * @export
 * @interface TeacherCheckInRoomMediaRequest
 */
export interface TeacherCheckInRoomMediaRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TeacherCheckInRoomMediaRequest
     */
    roomCheckinId: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCheckInRoomMediaRequest
     */
    checkinType: TeacherCheckInRoomMediaRequestCheckinTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeacherCheckInRoomMediaRequest
     */
    oldMediaIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeacherCheckInRoomMediaRequest
     */
    mediaIds: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum TeacherCheckInRoomMediaRequestCheckinTypeEnum {
    In = 'check_in',
    Out = 'check_out'
}

/**
 * 
 * @export
 * @interface TeacherCheckInRoomRequest
 */
export interface TeacherCheckInRoomRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TeacherCheckInRoomRequest
     */
    roomCheckInId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCheckInRoomRequest
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCheckInRoomRequest
     */
    courseSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCheckInRoomRequest
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCheckInRoomRequest
     */
    checkinType?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCheckInRoomRequest
     */
    checkInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCheckInRoomRequest
     */
    checkOutTime?: string;
}
/**
 * 
 * @export
 * @interface TeacherCheckInRoomResponse
 */
export interface TeacherCheckInRoomResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {TeacherCheckInRoomResponseData}
     * @memberof TeacherCheckInRoomResponse
     */
    data: TeacherCheckInRoomResponseData;
}
/**
 * 
 * @export
 * @interface TeacherCheckInRoomResponseData
 */
export interface TeacherCheckInRoomResponseData {
    /**
     * 
     * @type {string}
     * @memberof TeacherCheckInRoomResponseData
     */
    roomCheckinId: string;
}
/**
 * 
 * @export
 * @interface TeacherConversationDto
 */
export interface TeacherConversationDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TeacherConversationDto
     */
    teacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherConversationDto
     */
    teacherName?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherConversationDto
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherConversationDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherConversationDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherConversationDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherConversationDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherConversationDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherConversationDto
     */
    parentAvatar?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherConversationDto
     */
    courseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherConversationDto
     */
    courseInstanceStartTime?: string;
}
/**
 * 
 * @export
 * @interface TeacherCreateOrUpdateRoomBookingRequest
 */
export interface TeacherCreateOrUpdateRoomBookingRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TeacherCreateOrUpdateRoomBookingRequest
     */
    roomId: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCreateOrUpdateRoomBookingRequest
     */
    courseSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCreateOrUpdateRoomBookingRequest
     */
    bookedDate: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCreateOrUpdateRoomBookingRequest
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCreateOrUpdateRoomBookingRequest
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCreateOrUpdateRoomBookingRequest
     */
    roomBookingId?: string | null;
}
/**
 * 
 * @export
 * @interface TeacherDeletePhotoMessageRequest
 */
export interface TeacherDeletePhotoMessageRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TeacherDeletePhotoMessageRequest
     */
    messageId: string;
}
/**
 * 
 * @export
 * @interface TeacherDetailDto
 */
export interface TeacherDetailDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TeacherDetailDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDetailDto
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDetailDto
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDetailDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDetailDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDetailDto
     */
    address?: string;
}
/**
 * 
 * @export
 * @interface TeacherDto
 */
export interface TeacherDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    fullname?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    userType?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    otherUserType?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    grade?: string;
    /**
     * 
     * @type {number}
     * @memberof TeacherDto
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherDto
     */
    maxAge?: number;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    parentRelationShipType?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    gender?: TeacherDtoGenderEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeacherDto
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeacherDto
     */
    venueNames?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof TeacherDto
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TeacherDto
     */
    kisiUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TeacherDto
     */
    isInvited?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    invoiceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    invoiceEmailAddress?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeacherDto
     */
    isEmergencyContact?: boolean;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof TeacherDto
     */
    venues?: Array<CompanyDto>;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    tempPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    postCode?: string;
    /**
     * 
     * @type {Array<IBrand>}
     * @memberof TeacherDto
     */
    brands?: Array<IBrand>;
    /**
     * 
     * @type {Array<CourseInstanceDto>}
     * @memberof TeacherDto
     */
    courseInstances?: Array<CourseInstanceDto>;
    /**
     * 
     * @type {Array<CourseClassDto>}
     * @memberof TeacherDto
     */
    courseClasses?: Array<CourseClassDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum TeacherDtoGenderEnum {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface TeacherEditPhotoMessageRequest
 */
export interface TeacherEditPhotoMessageRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TeacherEditPhotoMessageRequest
     */
    messageId: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherEditPhotoMessageRequest
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherEditPhotoMessageRequest
     */
    richTextContent?: string;
}
/**
 * 
 * @export
 * @interface TeacherGetRoomCheckinDto
 */
export interface TeacherGetRoomCheckinDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TeacherGetRoomCheckinDto
     */
    roomCheckinId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherGetRoomCheckinDto
     */
    checkinType?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherGetRoomCheckinDto
     */
    checkinNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherGetRoomCheckinDto
     */
    checkoutNotes?: string;
    /**
     * 
     * @type {Array<MediaDto>}
     * @memberof TeacherGetRoomCheckinDto
     */
    mediaList?: Array<MediaDto>;
}
/**
 * 
 * @export
 * @interface TeacherGetRoomCheckinResponse
 */
export interface TeacherGetRoomCheckinResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<TeacherGetRoomCheckinDto>}
     * @memberof TeacherGetRoomCheckinResponse
     */
    data: Array<TeacherGetRoomCheckinDto>;
}
/**
 * 
 * @export
 * @interface TeacherPhotoUpdateDetailResponse
 */
export interface TeacherPhotoUpdateDetailResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {MessageNewDto}
     * @memberof TeacherPhotoUpdateDetailResponse
     */
    data: MessageNewDto;
}
/**
 * 
 * @export
 * @interface TeacherPhotoUpdateRequest
 */
export interface TeacherPhotoUpdateRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof TeacherPhotoUpdateRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherPhotoUpdateRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof TeacherPhotoUpdateRequest
     */
    maxUpdatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherPhotoUpdateRequest
     */
    minUpdatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeacherPhotoUpdateRequest
     */
    includeDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface TeacherPhotoUpdateResponse
 */
export interface TeacherPhotoUpdateResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<MessageNewDto>}
     * @memberof TeacherPhotoUpdateResponse
     */
    data: Array<MessageNewDto>;
}
/**
 * 
 * @export
 * @interface TeacherPostStudentNotesRequest
 */
export interface TeacherPostStudentNotesRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TeacherPostStudentNotesRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherPostStudentNotesRequest
     */
    parentId: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherPostStudentNotesRequest
     */
    courseId: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherPostStudentNotesRequest
     */
    courseInstanceId: string;
}
/**
 * 
 * @export
 * @interface TeacherPostStudentNotesResponse
 */
export interface TeacherPostStudentNotesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<StudentNotesDto>}
     * @memberof TeacherPostStudentNotesResponse
     */
    data: Array<StudentNotesDto>;
}
/**
 * 
 * @export
 * @interface Template
 */
export interface Template {
    [key: string]: object | any;

    /**
     * 
     * @type {TemplateTypes}
     * @memberof Template
     */
    TemplateType: TemplateTypes;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    Name: string;
    /**
     * 
     * @type {number}
     * @memberof Template
     */
    TemplateId: number;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    Alias: string | null;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    Subject: string;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    HtmlBody: string | null;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    TextBody: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Template
     */
    Active: boolean;
    /**
     * 
     * @type {number}
     * @memberof Template
     */
    AssociatedServerId: number;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    LayoutTemplate: string | null;
}
/**
 * 
 * @export
 * @interface TemplateInList
 */
export interface TemplateInList {
    [key: string]: object | any;

    /**
     * 
     * @type {TemplateTypes}
     * @memberof TemplateInList
     */
    TemplateType: TemplateTypes;
    /**
     * 
     * @type {boolean}
     * @memberof TemplateInList
     */
    Active: boolean;
    /**
     * 
     * @type {number}
     * @memberof TemplateInList
     */
    TemplateId: number;
    /**
     * 
     * @type {string}
     * @memberof TemplateInList
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateInList
     */
    Alias: string | null;
    /**
     * 
     * @type {string}
     * @memberof TemplateInList
     */
    LayoutTemplate: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TemplateTypes {
    Standard = 'Standard',
    Layout = 'Layout'
}

/**
 * 
 * @export
 * @interface TermsAcceptedDto
 */
export interface TermsAcceptedDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TermsAcceptedDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TermsAcceptedDto
     */
    acceptedPhrase: string;
}
/**
 * 
 * @export
 * @interface TermsAndConditionsDto
 */
export interface TermsAndConditionsDto {
    [key: string]: object | any;

    /**
     * 
     * @type {TermsTemplatePageDto}
     * @memberof TermsAndConditionsDto
     */
    pageDecor: TermsTemplatePageDto;
    /**
     * 
     * @type {Array<TermsTemplateContentDto>}
     * @memberof TermsAndConditionsDto
     */
    contents: Array<TermsTemplateContentDto>;
}
/**
 * 
 * @export
 * @interface TermsOfBusinessDto
 */
export interface TermsOfBusinessDto {
    [key: string]: object | any;

    /**
     * 
     * @type {TermsTemplatePageDto}
     * @memberof TermsOfBusinessDto
     */
    pageDecor: TermsTemplatePageDto;
    /**
     * 
     * @type {Array<TermsTemplateContentDto>}
     * @memberof TermsOfBusinessDto
     */
    contents: Array<TermsTemplateContentDto>;
}
/**
 * 
 * @export
 * @interface TermsOfBusinessResponse
 */
export interface TermsOfBusinessResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {TermsOfBusinessDto}
     * @memberof TermsOfBusinessResponse
     */
    data: TermsOfBusinessDto;
}
/**
 * 
 * @export
 * @interface TermsTemplateContentDto
 */
export interface TermsTemplateContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof TermsTemplateContentDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof TermsTemplateContentDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof TermsTemplateContentDto
     */
    content?: string;
}
/**
 * 
 * @export
 * @interface TermsTemplatePageDto
 */
export interface TermsTemplatePageDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TermsTemplatePageDto
     */
    headerTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof TermsTemplatePageDto
     */
    sidebarTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof TermsTemplatePageDto
     */
    selectOptionTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof TermsTemplatePageDto
     */
    warningText?: string;
    /**
     * 
     * @type {string}
     * @memberof TermsTemplatePageDto
     */
    headerBackgroundColor?: string;
}
/**
 * 
 * @export
 * @interface TheSpaceBodyContent
 */
export interface TheSpaceBodyContent {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof TheSpaceBodyContent
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof TheSpaceBodyContent
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TheSpaceBodyContent
     */
    subTitle: string;
    /**
     * 
     * @type {string}
     * @memberof TheSpaceBodyContent
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof TheSpaceBodyContent
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof TheSpaceBodyContent
     */
    shadowColor?: string;
    /**
     * 
     * @type {string}
     * @memberof TheSpaceBodyContent
     */
    imageSmall: string;
}
/**
 * 
 * @export
 * @interface TheSpaceFacilitiesContent
 */
export interface TheSpaceFacilitiesContent {
    [key: string]: object | any;

    /**
     * 
     * @type {number}
     * @memberof TheSpaceFacilitiesContent
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof TheSpaceFacilitiesContent
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof TheSpaceFacilitiesContent
     */
    description: string;
}
/**
 * 
 * @export
 * @interface TheSpacePageContentDto
 */
export interface TheSpacePageContentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TheSpacePageContentDto
     */
    heroImage: string;
    /**
     * 
     * @type {string}
     * @memberof TheSpacePageContentDto
     */
    activitiesImages: string;
    /**
     * 
     * @type {Array<TheSpaceBodyContent>}
     * @memberof TheSpacePageContentDto
     */
    contentList: Array<TheSpaceBodyContent>;
    /**
     * 
     * @type {Array<TheSpaceFacilitiesContent>}
     * @memberof TheSpacePageContentDto
     */
    facilityList: Array<TheSpaceFacilitiesContent>;
    /**
     * 
     * @type {MetaDto}
     * @memberof TheSpacePageContentDto
     */
    meta?: MetaDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TicketStatusEnum {
    New = 'new',
    Open = 'open',
    Closed = 'closed'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TicketStatusFilteringEnum {
    All = 'all',
    New = 'new',
    Open = 'open',
    Closed = 'closed'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TicketTypeEnum {
    Ticket = 'ticket',
    AbsenceNotice = 'absence_notice'
}

/**
 * 
 * @export
 * @interface TitleConcatenationDto
 */
export interface TitleConcatenationDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TitleConcatenationDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TitleConcatenationDto
     */
    color: string;
}
/**
 * 
 * @export
 * @interface ToggleStudentResponse
 */
export interface ToggleStudentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<ChildDto>}
     * @memberof ToggleStudentResponse
     */
    data: Array<ChildDto>;
}
/**
 * 
 * @export
 * @interface TransactionDto
 */
export interface TransactionDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    invoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    price?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    agoraCare?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    transactionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionDto
     */
    isMulti?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    packId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    instanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    transactionType?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    productType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionDto
     */
    isCancelled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    sessionPackId?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionDto
     */
    sessionPackNumberOfSessions?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof TransactionDto
     */
    userInfo?: UserDto;
    /**
     * 
     * @type {number}
     * @memberof TransactionDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    subscriptionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionDto
     */
    isSubscription?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionDto
     */
    isUnpaidInvoice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    transactionReference?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TransactionPaymentType {
    Payment = 'payment',
    Refund = 'refund',
    Voucher = 'voucher'
}

/**
 * 
 * @export
 * @interface TransferSessionPackDto
 */
export interface TransferSessionPackDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TransferSessionPackDto
     */
    sessionPackAdditionalId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferSessionPackDto
     */
    studentId: string;
}
/**
 * 
 * @export
 * @interface TrialCourseInstanceDto
 */
export interface TrialCourseInstanceDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TrialCourseInstanceDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof TrialCourseInstanceDto
     */
    studentAvatar?: string;
    /**
     * 
     * @type {string}
     * @memberof TrialCourseInstanceDto
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof TrialCourseInstanceDto
     */
    studentChineseName?: string;
    /**
     * 
     * @type {string}
     * @memberof TrialCourseInstanceDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof TrialCourseInstanceDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof TrialCourseInstanceDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string | number}
     * @memberof TrialCourseInstanceDto
     */
    sessionNo?: string | number;
    /**
     * 
     * @type {string}
     * @memberof TrialCourseInstanceDto
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof TrialCourseInstanceDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TrialCourseInstanceDto
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TrialCourseInstanceDto
     */
    room?: string;
    /**
     * 
     * @type {string}
     * @memberof TrialCourseInstanceDto
     */
    sessionStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof TrialCourseInstanceDto
     */
    reservedId?: string;
    /**
     * 
     * @type {string}
     * @memberof TrialCourseInstanceDto
     */
    studentCourseSessionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrialCourseInstanceDto
     */
    isWithdrawn?: boolean;
}
/**
 * 
 * @export
 * @interface TrialForEpTeacherRequest
 */
export interface TrialForEpTeacherRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof TrialForEpTeacherRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof TrialForEpTeacherRequest
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof TrialForEpTeacherRequest
     */
    instanceId: string;
    /**
     * 
     * @type {string}
     * @memberof TrialForEpTeacherRequest
     */
    studentSessionType?: TrialForEpTeacherRequestStudentSessionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TrialForEpTeacherRequestStudentSessionTypeEnum {
    Trial = 'trial',
    Makeup = 'makeup'
}

/**
 * 
 * @export
 * @interface UTMParamDto
 */
export interface UTMParamDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UTMParamDto
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof UTMParamDto
     */
    value: string;
}
/**
 * 
 * @export
 * @interface UnCheckinStudentRequest
 */
export interface UnCheckinStudentRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UnCheckinStudentRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof UnCheckinStudentRequest
     */
    courseSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof UnCheckinStudentRequest
     */
    sessionPackUsageId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnCheckinStudentRequest
     */
    checkinLogIds: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateAttendanceRequest
 */
export interface UpdateAttendanceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UpdateAttendanceRequest
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttendanceRequest
     */
    childrenId: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAttendanceRequest
     */
    isWebPlatform?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttendanceRequest
     */
    status?: UpdateAttendanceRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttendanceRequest
     */
    remark?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttendanceRequest
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttendanceRequest
     */
    courseName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAttendanceRequest
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttendanceRequest
     */
    sharedSessionPackStudentId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAttendanceRequest
     */
    isUseSessionPack?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttendanceRequest
     */
    mediaId?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAttendanceRequest
     */
    numberOfTemperatures?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttendanceRequest
     */
    absenceNote?: string;
    /**
     * 
     * @type {CheckinOutSourceEnum}
     * @memberof UpdateAttendanceRequest
     */
    checkinOutSource?: CheckinOutSourceEnum;
    /**
     * 
     * @type {UpdateAttendanceRequestCheckInBy}
     * @memberof UpdateAttendanceRequest
     */
    checkInBy?: UpdateAttendanceRequestCheckInBy;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateAttendanceRequestStatusEnum {
    Attending = 'attending',
    Absent = 'absent'
}

/**
 * 
 * @export
 * @interface UpdateAttendanceRequestCheckInBy
 */
export interface UpdateAttendanceRequestCheckInBy {
    /**
     * 
     * @type {string}
     * @memberof UpdateAttendanceRequestCheckInBy
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttendanceRequestCheckInBy
     */
    name: string;
}
/**
 * 
 * @export
 * @interface UpdateBirthdayCertificateRequest
 */
export interface UpdateBirthdayCertificateRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBirthdayCertificateRequest
     */
    mediaId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBirthdayCertificateRequest
     */
    studentId: string;
}
/**
 * 
 * @export
 * @interface UpdateBulkEnrolDuplicateStudentsRequestDto
 */
export interface UpdateBulkEnrolDuplicateStudentsRequestDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UpdateBulkEnrolDuplicateStudentsRequestDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBulkEnrolDuplicateStudentsRequestDto
     */
    newStudentName: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBulkEnrolDuplicateStudentsRequestDto
     */
    numberOfExistingStudentByName: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBulkEnrolDuplicateStudentsRequestDto
     */
    enrollType: UpdateBulkEnrolDuplicateStudentsRequestDtoEnrollTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateBulkEnrolDuplicateStudentsRequestDtoEnrollTypeEnum {
    EnrollExisting = 'enroll_existing',
    EnrollNew = 'enroll_new',
    DoNotEnroll = 'do_not_enroll'
}

/**
 * 
 * @export
 * @interface UpdateBulkEnrolWithDuplicateStudentsRequest
 */
export interface UpdateBulkEnrolWithDuplicateStudentsRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {Array<UpdateBulkEnrolDuplicateStudentsRequestDto>}
     * @memberof UpdateBulkEnrolWithDuplicateStudentsRequest
     */
    students: Array<UpdateBulkEnrolDuplicateStudentsRequestDto>;
    /**
     * 
     * @type {string}
     * @memberof UpdateBulkEnrolWithDuplicateStudentsRequest
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBulkEnrolWithDuplicateStudentsRequest
     */
    educationProviderId: string;
}
/**
 * 
 * @export
 * @interface UpdateCartItemRequest
 */
export interface UpdateCartItemRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UpdateCartItemRequest
     */
    cartItemId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCartItemRequest
     */
    childrenId: string;
}
/**
 * 
 * @export
 * @interface UpdateCourseInstanceTeacherRequest
 */
export interface UpdateCourseInstanceTeacherRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UpdateCourseInstanceTeacherRequest
     */
    teacherId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseInstanceTeacherRequest
     */
    courseInstanceId: string;
}
/**
 * 
 * @export
 * @interface UpdateGuardianResponse
 */
export interface UpdateGuardianResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {UserDto}
     * @memberof UpdateGuardianResponse
     */
    data: UserDto | null;
}
/**
 * 
 * @export
 * @interface UpdateIsShareParentContactRequest
 */
export interface UpdateIsShareParentContactRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UpdateIsShareParentContactRequest
     */
    venueId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIsShareParentContactRequest
     */
    venueEducationProviderId: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIsShareParentContactRequest
     */
    isShareParentContact: boolean;
}
/**
 * 
 * @export
 * @interface UpdateRevenueWithoutSelectedRefundSessionRequest
 */
export interface UpdateRevenueWithoutSelectedRefundSessionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UpdateRevenueWithoutSelectedRefundSessionRequest
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRevenueWithoutSelectedRefundSessionRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRevenueWithoutSelectedRefundSessionRequest
     */
    invoiceLineId: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateRevenueWithoutSelectedRefundSessionRequest
     */
    price: number;
}
/**
 * 
 * @export
 * @interface UpdateUserByAdminRequest
 */
export interface UpdateUserByAdminRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UpdateUserByAdminRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserByAdminRequest
     */
    roleUpdate: UpdateUserByAdminRequestRoleUpdateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateUserByAdminRequestRoleUpdateEnum {
    Teacher = 'teacher',
    EpAdmin = 'ep_admin',
    EpAdminTeacher = 'ep_admin_teacher'
}

/**
 * 
 * @export
 * @interface UpdateUserByAdminResponse
 */
export interface UpdateUserByAdminResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UpdateUserByAdminResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserByAdminResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserByAdminResponse
     */
    userType: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserByAdminResponse
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface UploadFileRequest
 */
export interface UploadFileRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UploadFileRequest
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileRequest
     */
    fileType: string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileRequest
     */
    buffer: string;
}
/**
 * 
 * @export
 * @interface UpsertContextSensitiveHelpRequest
 */
export interface UpsertContextSensitiveHelpRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UpsertContextSensitiveHelpRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertContextSensitiveHelpRequest
     */
    section: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertContextSensitiveHelpRequest
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertContextSensitiveHelpRequest
     */
    forObject: UpsertContextSensitiveHelpRequestForObjectEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpsertContextSensitiveHelpRequestForObjectEnum {
    Course = 'course',
    CourseInstance = 'course_instance',
    Refund = 'refund'
}

/**
 * 
 * @export
 * @interface UpsertDiscountCodesRequest
 */
export interface UpsertDiscountCodesRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UpsertDiscountCodesRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertDiscountCodesRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertDiscountCodesRequest
     */
    code: string;
    /**
     * 
     * @type {CampaignTypeStatusEnum}
     * @memberof UpsertDiscountCodesRequest
     */
    status: CampaignTypeStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof UpsertDiscountCodesRequest
     */
    discountAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertDiscountCodesRequest
     */
    discountPercent?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertDiscountCodesRequest
     */
    epBears: number;
    /**
     * 
     * @type {number}
     * @memberof UpsertDiscountCodesRequest
     */
    agoraBears: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertDiscountCodesRequest
     */
    limitOneTimePerChild?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertDiscountCodesRequest
     */
    venueId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertDiscountCodesRequest
     */
    coursePeriodIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertDiscountCodesRequest
     */
    educationProviderIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertDiscountCodesRequest
     */
    courseIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertDiscountCodesRequest
     */
    courseInstanceIds?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpsertDiscountCodesResponse
 */
export interface UpsertDiscountCodesResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UpsertDiscountCodesResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertDiscountCodesResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertDiscountCodesResponse
     */
    campaignType?: string;
}
/**
 * 
 * @export
 * @interface UpsertKisiConfigurationRequest
 */
export interface UpsertKisiConfigurationRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UpsertKisiConfigurationRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertKisiConfigurationRequest
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof UpsertKisiConfigurationRequest
     */
    requestExpirySeconds?: number;
}
/**
 * 
 * @export
 * @interface UpsertReasonRequest
 */
export interface UpsertReasonRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UpsertReasonRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertReasonRequest
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertReasonRequest
     */
    type: UpsertReasonRequestTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpsertReasonRequestTypeEnum {
    CourseInstanceCancellation = 'course_instance_cancellation',
    AddCredit = 'add_credit',
    RemoveBulkEnrolledStudents = 'remove_bulk_enrolled_students',
    Withdraw = 'withdraw'
}

/**
 * 
 * @export
 * @interface UserCardDto
 */
export interface UserCardDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UserCardDto
     */
    cardId: string;
    /**
     * 
     * @type {string}
     * @memberof UserCardDto
     */
    userCardId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCardDto
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCardDto
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCardDto
     */
    last4?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCardDto
     */
    setupLink?: string;
}
/**
 * 
 * @export
 * @interface UserCheckin
 */
export interface UserCheckin {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    fullname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    userType?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    otherUserType?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    grade?: string;
    /**
     * 
     * @type {number}
     * @memberof UserCheckin
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCheckin
     */
    maxAge?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    parentRelationShipType?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    gender?: UserCheckinGenderEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCheckin
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCheckin
     */
    venueNames?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserCheckin
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserCheckin
     */
    kisiUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserCheckin
     */
    isInvited?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    invoiceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    invoiceEmailAddress?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCheckin
     */
    isEmergencyContact?: boolean;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof UserCheckin
     */
    venues?: Array<CompanyDto>;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    tempPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    postCode?: string;
    /**
     * 
     * @type {Array<IBrand>}
     * @memberof UserCheckin
     */
    brands?: Array<IBrand>;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    parentPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    parentAvatar?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCheckin
     */
    isTrialSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCheckin
     */
    isShareParentContact?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    chineseName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    allergies?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    school?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    schoolId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    otherSchool?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {MediaDto}
     * @memberof UserCheckin
     */
    birthdayCertificate?: MediaDto;
    /**
     * 
     * @type {number}
     * @memberof UserCheckin
     */
    age?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCheckin
     */
    totalCount?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    studentReferenceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCheckin
     */
    hasBirthdayCertificate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    checkInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    checkOutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    subName?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof UserCheckin
     */
    parent?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    centreCheckinTime?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    centreCheckoutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCheckin
     */
    checkInLogId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserCheckinGenderEnum {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface UserConfirmSubscriptionRequest
 */
export interface UserConfirmSubscriptionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UserConfirmSubscriptionRequest
     */
    subscriptionId: string;
    /**
     * 
     * @type {string}
     * @memberof UserConfirmSubscriptionRequest
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface UserConfirmSubscriptionResponse
 */
export interface UserConfirmSubscriptionResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UserConfirmSubscriptionResponse
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    fullname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    userType?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    otherUserType?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    grade?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    maxAge?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    parentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    parentRelationShipType?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    gender?: UserDtoGenderEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    venueIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    venueNames?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isOffPlatform?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    kisiUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isInvited?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    invoiceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    invoiceEmailAddress?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isEmergencyContact?: boolean;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof UserDto
     */
    venues?: Array<CompanyDto>;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    tempPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    postCode?: string;
    /**
     * 
     * @type {Array<IBrand>}
     * @memberof UserDto
     */
    brands?: Array<IBrand>;
}

/**
    * @export
    * @enum {string}
    */
export enum UserDtoGenderEnum {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {UserDto}
     * @memberof UserResponse
     */
    data: UserDto;
}
/**
 * 
 * @export
 * @interface UserSaveCardResponse
 */
export interface UserSaveCardResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UserSaveCardResponse
     */
    url: string;
}
/**
 * 
 * @export
 * @interface UserSubscriptionPlan
 */
export interface UserSubscriptionPlan {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionPlan
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionPlan
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof UserSubscriptionPlan
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSubscriptionPlan
     */
    costPerHour?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSubscriptionPlan
     */
    pricePerMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSubscriptionPlan
     */
    discount?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionPlan
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionPlan
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionPlan
     */
    subscriptionId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionPlan
     */
    agoraCareHoursId?: string;
    /**
     * 
     * @type {number}
     * @memberof UserSubscriptionPlan
     */
    balanceHours?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionPlan
     */
    dateRedeemed?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionPlan
     */
    expireDate?: string;
}
/**
 * 
 * @export
 * @interface ValidateCourseSessionRequest
 */
export interface ValidateCourseSessionRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ValidateCourseSessionRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateCourseSessionRequest
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateCourseSessionRequest
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateCourseSessionRequest
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateCourseSessionRequest
     */
    teacherId: string | null;
}
/**
 * 
 * @export
 * @interface ValidateCourseSessionResponse
 */
export interface ValidateCourseSessionResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ValidateCourseSessionResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateCourseSessionResponse
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateCourseSessionResponse
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateCourseSessionResponse
     */
    endTime: string;
}
/**
 * 
 * @export
 * @interface ValidateCourseTimeForPaymentDto
 */
export interface ValidateCourseTimeForPaymentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ValidateCourseTimeForPaymentDto
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateCourseTimeForPaymentDto
     */
    instanceId: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateCourseTimeForPaymentDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateCourseTimeForPaymentDto
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateCourseTimeForPaymentDto
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateCourseTimeForPaymentDto
     */
    timeType: ValidateCourseTimeForPaymentDtoTimeTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ValidateCourseTimeForPaymentDto
     */
    isTrialSession: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ValidateCourseTimeForPaymentDtoTimeTypeEnum {
    Enrollment = 'enrollment',
    Agora = 'agora',
    FutureEnrollment = 'future_enrollment'
}

/**
 * 
 * @export
 * @interface ValidateDiscountCodeRequest
 */
export interface ValidateDiscountCodeRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ValidateDiscountCodeRequest
     */
    promotionCode: string;
    /**
     * 
     * @type {Array<ShoppingCartDto>}
     * @memberof ValidateDiscountCodeRequest
     */
    cartList: Array<ShoppingCartDto>;
}
/**
 * 
 * @export
 * @interface ValidateDiscountCodeResponse
 */
export interface ValidateDiscountCodeResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AvailableDiscountCodeDto}
     * @memberof ValidateDiscountCodeResponse
     */
    data: AvailableDiscountCodeDto | null;
}
/**
 * 
 * @export
 * @interface ValidateDto
 */
export interface ValidateDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ValidateDto
     */
    message: string;
    /**
     * 
     * @type {ValidateErrorCode}
     * @memberof ValidateDto
     */
    errorCode?: ValidateErrorCode;
    /**
     * 
     * @type {any}
     * @memberof ValidateDto
     */
    data?: any | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ValidateErrorCode {
    TeacherHaveOverlappingSessions = 'TEACHER_HAVE_OVERLAPPING_SESSIONS',
    StudentHaveOverlappingSessions = 'STUDENT_HAVE_OVERLAPPING_SESSIONS',
    FullCapacity = 'FULL_CAPACITY',
    FullSomeSessions = 'FULL_SOME_SESSIONS',
    RescheduleNotMeetCondition = 'RESCHEDULE_NOT_MEET_CONDITION',
    RescheduleNeedApproval = 'RESCHEDULE_NEED_APPROVAL',
    StudentAlreadyUsedSessionPackForThisSession = 'STUDENT_ALREADY_USED_SESSION_PACK_FOR_THIS_SESSION',
    CourseInstanceAlreadyBooked = 'COURSE_INSTANCE_ALREADY_BOOKED',
    FirstCourseSessionCompleted = 'FIRST_COURSE_SESSION_COMPLETED',
    OutstandingInvoicesRelatedCourseInstance = 'OUTSTANDING_INVOICES_RELATED_COURSE_INSTANCE',
    RevenueRecognitionAlreadyExist = 'REVENUE_RECOGNITION_ALREADY_EXIST',
    InvalidCreditForInvoice = 'INVALID_CREDIT_FOR_INVOICE',
    InsufficientCredit = 'INSUFFICIENT_CREDIT',
    CreditExceedsInvoice = 'CREDIT_EXCEEDS_INVOICE'
}

/**
 * 
 * @export
 * @interface ValidateGradeLevelRequest
 */
export interface ValidateGradeLevelRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ValidateGradeLevelRequest
     */
    gradeId: string;
    /**
     * 
     * @type {number}
     * @memberof ValidateGradeLevelRequest
     */
    minAge: number;
    /**
     * 
     * @type {number}
     * @memberof ValidateGradeLevelRequest
     */
    maxAge: number;
}
/**
 * 
 * @export
 * @interface ValidateStudentBeforeRegistrationResponse
 */
export interface ValidateStudentBeforeRegistrationResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {ValidateStudentBeforeRegistrationResponseData}
     * @memberof ValidateStudentBeforeRegistrationResponse
     */
    data: ValidateStudentBeforeRegistrationResponseData;
}
/**
 * 
 * @export
 * @interface ValidateStudentBeforeRegistrationResponseData
 */
export interface ValidateStudentBeforeRegistrationResponseData {
    /**
     * 
     * @type {string}
     * @memberof ValidateStudentBeforeRegistrationResponseData
     */
    message: string;
}
/**
 * 
 * @export
 * @interface ValidateStudentsRequest
 */
export interface ValidateStudentsRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ValidateStudentsRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateStudentsRequest
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateStudentsRequest
     */
    subscriptionLineId?: string;
}
/**
 * 
 * @export
 * @interface ValidateVoucherCampaignResponse
 */
export interface ValidateVoucherCampaignResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {AvailableVoucherCampaignDto}
     * @memberof ValidateVoucherCampaignResponse
     */
    data: AvailableVoucherCampaignDto | null;
}
/**
 * 
 * @export
 * @interface VerifyUserRequest
 */
export interface VerifyUserRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof VerifyUserRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyUserRequest
     */
    platform: VerifyUserRequestPlatformEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum VerifyUserRequestPlatformEnum {
    Web = 'web',
    Mobile = 'mobile',
    AdminPortal = 'admin-portal'
}

/**
 * 
 * @export
 * @interface VoidInvoiceRequest
 */
export interface VoidInvoiceRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof VoidInvoiceRequest
     */
    invoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof VoidInvoiceRequest
     */
    voidedReason: string;
}
/**
 * 
 * @export
 * @interface VoucherHistoryDto
 */
export interface VoucherHistoryDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof VoucherHistoryDto
     */
    voucherNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherHistoryDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherHistoryDto
     */
    courseInstanceStartTime?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherHistoryDto
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherHistoryDto
     */
    educationProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherHistoryDto
     */
    productType?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherHistoryDto
     */
    redemptionDate?: string;
}
/**
 * 
 * @export
 * @interface WithdrawStudentRequest
 */
export interface WithdrawStudentRequest {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof WithdrawStudentRequest
     */
    courseEnrolmentId: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawStudentRequest
     */
    reasonId: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawStudentRequest
     */
    lastDateWithdrawal: string;
}
/**
 * 
 * @export
 * @interface WithdrawStudentResponse
 */
export interface WithdrawStudentResponse {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof WithdrawStudentResponse
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawStudentResponse
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawStudentResponse
     */
    status: WithdrawStudentResponseStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum WithdrawStudentResponseStatusEnum {
    Success = 'success',
    Warning = 'warning'
}

/**
 * 
 * @export
 * @interface WithdrawTrialStudentDto
 */
export interface WithdrawTrialStudentDto {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof WithdrawTrialStudentDto
     */
    parentName: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTrialStudentDto
     */
    courseName: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTrialStudentDto
     */
    courseInstanceId: string;
    /**
     * 
     * @type {string | number}
     * @memberof WithdrawTrialStudentDto
     */
    sessionNumber: string | number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTrialStudentDto
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTrialStudentDto
     */
    timeSessionStart: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTrialStudentDto
     */
    timeSessionEnd: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTrialStudentDto
     */
    parentEmail: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTrialStudentDto
     */
    studentName: string;
    /**
     * 
     * @type {StudentSessionType}
     * @memberof WithdrawTrialStudentDto
     */
    studentSessionType: StudentSessionType;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRevenueByInvoiceLines: async (invoiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('addRevenueByInvoiceLines', 'invoiceId', invoiceId)
            const localVarPath = `/admin/invoices/{invoiceId}/revenues`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceReferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStudentCourseSessionDataForSentInvoices: async (invoiceReferenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceReferenceId' is not null or undefined
            assertParamExists('addStudentCourseSessionDataForSentInvoices', 'invoiceReferenceId', invoiceReferenceId)
            const localVarPath = `/admin/add-student-course-session-data-for-sent-invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (invoiceReferenceId !== undefined) {
                localVarQueryParameter['invoiceReferenceId'] = invoiceReferenceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriptionLineId 
         * @param {string} invoiceId 
         * @param {string} collectionDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalDataSubscriptionInvoiceLine: async (subscriptionLineId: string, invoiceId: string, collectionDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionLineId' is not null or undefined
            assertParamExists('additionalDataSubscriptionInvoiceLine', 'subscriptionLineId', subscriptionLineId)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('additionalDataSubscriptionInvoiceLine', 'invoiceId', invoiceId)
            // verify required parameter 'collectionDate' is not null or undefined
            assertParamExists('additionalDataSubscriptionInvoiceLine', 'collectionDate', collectionDate)
            const localVarPath = `/admin/additional-data-subscription-invoice-Line`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (subscriptionLineId !== undefined) {
                localVarQueryParameter['subscriptionLineId'] = subscriptionLineId;
            }

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }

            if (collectionDate !== undefined) {
                localVarQueryParameter['collectionDate'] = collectionDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddCredit: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminAddCredit', 'requestBody', requestBody)
            const localVarPath = `/admin/add-credit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddEpAgreement: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminAddEpAgreement', 'requestBody', requestBody)
            const localVarPath = `/admin/add-ep-agreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddSessionPackToCart: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminAddSessionPackToCart', 'requestBody', requestBody)
            const localVarPath = `/admin/courses/session-packs/add-to-basket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddTrialSession: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminAddTrialSession', 'requestBody', requestBody)
            const localVarPath = `/admin/add-trial-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddTrialSingleSession: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminAddTrialSingleSession', 'requestBody', requestBody)
            const localVarPath = `/admin/add-trial-single-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddVenueRoom: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminAddVenueRoom', 'requestBody', requestBody)
            const localVarPath = `/admin/venues/add/room`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApprovalCourseInstance: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminApprovalCourseInstance', 'requestBody', requestBody)
            const localVarPath = `/admin/course-instances/approved`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApproveCourse: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminApproveCourse', 'requestBody', requestBody)
            const localVarPath = `/admin/approve-course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAssignEducationProvidersForVenue: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminAssignEducationProvidersForVenue', 'requestBody', requestBody)
            const localVarPath = `/admin/assign-education-providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} classId 
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAssignStudentToClass: async (courseId: string, classId: string, inlineObject4: InlineObject4, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminAssignStudentToClass', 'courseId', courseId)
            // verify required parameter 'classId' is not null or undefined
            assertParamExists('adminAssignStudentToClass', 'classId', classId)
            // verify required parameter 'inlineObject4' is not null or undefined
            assertParamExists('adminAssignStudentToClass', 'inlineObject4', inlineObject4)
            const localVarPath = `/admin/courses/{courseId}/class/{classId}/assign-student`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"classId"}}`, encodeURIComponent(String(classId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AssignStudentToCourseSeriesRequest} assignStudentToCourseSeriesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAssignStudentToCourseSeries: async (assignStudentToCourseSeriesRequest: AssignStudentToCourseSeriesRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignStudentToCourseSeriesRequest' is not null or undefined
            assertParamExists('adminAssignStudentToCourseSeries', 'assignStudentToCourseSeriesRequest', assignStudentToCourseSeriesRequest)
            const localVarPath = `/admin/courses/assign-student-to-course-series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignStudentToCourseSeriesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<RoomReserveDto>} roomReserveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBulkCreateReserveRoom: async (roomReserveDto: Array<RoomReserveDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomReserveDto' is not null or undefined
            assertParamExists('adminBulkCreateReserveRoom', 'roomReserveDto', roomReserveDto)
            const localVarPath = `/admin/rooms/reserve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roomReserveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBulkEnrollOffPlatform: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminBulkEnrollOffPlatform', 'requestBody', requestBody)
            const localVarPath = `/admin/courses/bulk-enroll-off-platform`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBusinessUnitsSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/business-units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseEnrolmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCancelCourseEnrolment: async (courseEnrolmentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseEnrolmentId' is not null or undefined
            assertParamExists('adminCancelCourseEnrolment', 'courseEnrolmentId', courseEnrolmentId)
            const localVarPath = `/admin/course-enrollment/{courseEnrolmentId}/cancel`
                .replace(`{${"courseEnrolmentId"}}`, encodeURIComponent(String(courseEnrolmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCancelCourseInstance: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCancelCourseInstance', 'requestBody', requestBody)
            const localVarPath = `/admin/course-instances/cancel-course-instance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCancelRevenues: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCancelRevenues', 'requestBody', requestBody)
            const localVarPath = `/admin/cancel-revenues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCancelSubscription: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCancelSubscription', 'requestBody', requestBody)
            const localVarPath = `/admin/subscriptions/cancel-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCancelTrialSingleSession: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCancelTrialSingleSession', 'requestBody', requestBody)
            const localVarPath = `/admin/cancel-trial-single-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {string} educationProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminChangeTeacherToEP: async (teacherId: string, educationProviderId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('adminChangeTeacherToEP', 'teacherId', teacherId)
            // verify required parameter 'educationProviderId' is not null or undefined
            assertParamExists('adminChangeTeacherToEP', 'educationProviderId', educationProviderId)
            const localVarPath = `/admin/education-providers/changeTeacher/{teacherId}/to/{educationProviderId}`
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)))
                .replace(`{${"educationProviderId"}}`, encodeURIComponent(String(educationProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckExistUserEmail: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('adminCheckExistUserEmail', 'email', email)
            const localVarPath = `/admin/users/is-exist-email/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceReferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckInvoiceValidToDelete: async (invoiceReferenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceReferenceId' is not null or undefined
            assertParamExists('adminCheckInvoiceValidToDelete', 'invoiceReferenceId', invoiceReferenceId)
            const localVarPath = `/admin/invoices/check-invoice-valid-to-delete/{invoiceReferenceId}`
                .replace(`{${"invoiceReferenceId"}}`, encodeURIComponent(String(invoiceReferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckIsAfterSellingCourse: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminCheckIsAfterSellingCourse', 'courseId', courseId)
            const localVarPath = `/admin/is-after-selling-course/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckSomeClassesUsed: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('adminCheckSomeClassesUsed', 'venueId', venueId)
            const localVarPath = `/admin/venues/check-some-classes-used/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckValidToArchiveDiscount: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCheckValidToArchiveDiscount', 'requestBody', requestBody)
            const localVarPath = `/admin/discounts/valid-to-archive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckinStudents: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCheckinStudents', 'requestBody', requestBody)
            const localVarPath = `/admin/schedule/checkin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckoutStudents: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCheckoutStudents', 'requestBody', requestBody)
            const localVarPath = `/admin/schedule/checkout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCopyCourseInstance: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCopyCourseInstance', 'requestBody', requestBody)
            const localVarPath = `/admin/course/copy-course-instance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountriesSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseFormatSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/course-format`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [venueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseInstancesSelect: async (venueId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/campaigns/select/course-instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (venueId !== undefined) {
                localVarQueryParameter['venueId'] = venueId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseTypeSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/course-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesSelect: async (includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateChild: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateChild', 'requestBody', requestBody)
            const localVarPath = `/admin/students/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseMedia: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateCourseMedia', 'requestBody', requestBody)
            const localVarPath = `/admin/create-course-media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} secretKey 
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateInstanceBySeries: async (secretKey: string, inlineObject3: InlineObject3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'secretKey' is not null or undefined
            assertParamExists('adminCreateInstanceBySeries', 'secretKey', secretKey)
            // verify required parameter 'inlineObject3' is not null or undefined
            assertParamExists('adminCreateInstanceBySeries', 'inlineObject3', inlineObject3)
            const localVarPath = `/admin/course-series/course-instance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (secretKey !== undefined && secretKey !== null) {
                localVarHeaderParameter['secretKey'] = String(secretKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateMessageCategory: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateMessageCategory', 'requestBody', requestBody)
            const localVarPath = `/admin/messages/message-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateNotificationReplacement: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateNotificationReplacement', 'requestBody', requestBody)
            const localVarPath = `/admin/create-notification-replacement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateCourseClass: async (courseId: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminCreateOrUpdateCourseClass', 'courseId', courseId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateOrUpdateCourseClass', 'requestBody', requestBody)
            const localVarPath = `/admin/courses/{courseId}/classes`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateCourseMedia: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateOrUpdateCourseMedia', 'requestBody', requestBody)
            const localVarPath = `/admin/create-or-update-course-media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateInvoiceAttachments: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateOrUpdateInvoiceAttachments', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/create-or-update-invoice-attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateOrUpdateMessageRequest} adminCreateOrUpdateMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateMessage: async (adminCreateOrUpdateMessageRequest: AdminCreateOrUpdateMessageRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateOrUpdateMessageRequest' is not null or undefined
            assertParamExists('adminCreateOrUpdateMessage', 'adminCreateOrUpdateMessageRequest', adminCreateOrUpdateMessageRequest)
            const localVarPath = `/admin/messages/create-or-update-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateOrUpdateMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateParentOrEPInvoice: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateOrUpdateParentOrEPInvoice', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/create-or-update-parent-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {boolean} [skipValidation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateReservation: async (requestBody: { [key: string]: object; }, skipValidation?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateOrUpdateReservation', 'requestBody', requestBody)
            const localVarPath = `/admin/reservations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (skipValidation !== undefined) {
                localVarQueryParameter['skipValidation'] = skipValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateStudentNoteAttachments: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateOrUpdateStudentNoteAttachments', 'requestBody', requestBody)
            const localVarPath = `/admin/student-notes/attachment-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateStudentNotes: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateOrUpdateStudentNotes', 'requestBody', requestBody)
            const localVarPath = `/admin/student-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateSubscription: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateOrUpdateSubscription', 'requestBody', requestBody)
            const localVarPath = `/admin/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreatePaidInvoice: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreatePaidInvoice', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/create-paid-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateReplacementHistory: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateReplacementHistory', 'requestBody', requestBody)
            const localVarPath = `/admin/create-replacement-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RescheduleRequest} rescheduleRequest 
         * @param {boolean} [skipValidation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateReschedule: async (rescheduleRequest: RescheduleRequest, skipValidation?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rescheduleRequest' is not null or undefined
            assertParamExists('adminCreateReschedule', 'rescheduleRequest', rescheduleRequest)
            const localVarPath = `/admin/reschedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (skipValidation !== undefined) {
                localVarQueryParameter['skipValidation'] = skipValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rescheduleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateSOAOrInvoiceReportData: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateSOAOrInvoiceReportData', 'requestBody', requestBody)
            const localVarPath = `/admin/soa-invoices/create-report-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateStudentSession: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateStudentSession', 'requestBody', requestBody)
            const localVarPath = `/admin/create-student-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateUser: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateUser', 'requestBody', requestBody)
            const localVarPath = `/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateVoucherCampaigns: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminCreateVoucherCampaigns', 'requestBody', requestBody)
            const localVarPath = `/admin/create-edit-voucher-campaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeductSessionPackAddition: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminDeductSessionPackAddition', 'requestBody', requestBody)
            const localVarPath = `/admin/deduct-session-pack-addition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteAllCourseSessions: async (courseInstanceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseInstanceId' is not null or undefined
            assertParamExists('adminDeleteAllCourseSessions', 'courseInstanceId', courseInstanceId)
            const localVarPath = `/admin/delete-all-course-sessions/{courseInstanceId}`
                .replace(`{${"courseInstanceId"}}`, encodeURIComponent(String(courseInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourse: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminDeleteCourse', 'referenceId', referenceId)
            const localVarPath = `/admin/courses/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseInstance: async (courseInstanceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseInstanceId' is not null or undefined
            assertParamExists('adminDeleteCourseInstance', 'courseInstanceId', courseInstanceId)
            const localVarPath = `/admin/course-instance/{courseInstanceId}`
                .replace(`{${"courseInstanceId"}}`, encodeURIComponent(String(courseInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseSession: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminDeleteCourseSession', 'requestBody', requestBody)
            const localVarPath = `/admin/delete-course-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCreditReason: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDeleteCreditReason', 'id', id)
            const localVarPath = `/admin/delete-credit-reason/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteEPReport: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminDeleteEPReport', 'requestBody', requestBody)
            const localVarPath = `/admin/soa-invoices/delete-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteEducationProvider: async (educationProviderId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationProviderId' is not null or undefined
            assertParamExists('adminDeleteEducationProvider', 'educationProviderId', educationProviderId)
            const localVarPath = `/admin/education-providers/{educationProviderId}`
                .replace(`{${"educationProviderId"}}`, encodeURIComponent(String(educationProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceReferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteInvoice: async (invoiceReferenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceReferenceId' is not null or undefined
            assertParamExists('adminDeleteInvoice', 'invoiceReferenceId', invoiceReferenceId)
            const localVarPath = `/admin/invoices/delete/{invoiceReferenceId}`
                .replace(`{${"invoiceReferenceId"}}`, encodeURIComponent(String(invoiceReferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteMessageCategory: async (categoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('adminDeleteMessageCategory', 'categoryId', categoryId)
            const localVarPath = `/admin/messages/message-categories/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refundHistoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteRefundHistory: async (refundHistoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'refundHistoryId' is not null or undefined
            assertParamExists('adminDeleteRefundHistory', 'refundHistoryId', refundHistoryId)
            const localVarPath = `/admin/delete-refund-history/{refundHistoryId}`
                .replace(`{${"refundHistoryId"}}`, encodeURIComponent(String(refundHistoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteRoomBooking: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDeleteRoomBooking', 'id', id)
            const localVarPath = `/admin/rooms/room-booking/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteSessionPack: async (referenceId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminDeleteSessionPack', 'referenceId', referenceId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDeleteSessionPack', 'id', id)
            const localVarPath = `/admin/courses/{referenceId}/session-packs/{id}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueEpId 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteStatusEpsForVenue: async (venueEpId: string, inlineObject1: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueEpId' is not null or undefined
            assertParamExists('adminDeleteStatusEpsForVenue', 'venueEpId', venueEpId)
            // verify required parameter 'inlineObject1' is not null or undefined
            assertParamExists('adminDeleteStatusEpsForVenue', 'inlineObject1', inlineObject1)
            const localVarPath = `/admin/delete-assign-ep/{venueEpId}`
                .replace(`{${"venueEpId"}}`, encodeURIComponent(String(venueEpId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} typeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteStudentNoteType: async (typeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeId' is not null or undefined
            assertParamExists('adminDeleteStudentNoteType', 'typeId', typeId)
            const localVarPath = `/admin/student-notes/types/{typeId}`
                .replace(`{${"typeId"}}`, encodeURIComponent(String(typeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteStudentNotes: async (studentNoteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentNoteId' is not null or undefined
            assertParamExists('adminDeleteStudentNotes', 'studentNoteId', studentNoteId)
            const localVarPath = `/admin/student-notes/{studentNoteId}`
                .replace(`{${"studentNoteId"}}`, encodeURIComponent(String(studentNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} originalSessionId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteStudentSession: async (originalSessionId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'originalSessionId' is not null or undefined
            assertParamExists('adminDeleteStudentSession', 'originalSessionId', originalSessionId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminDeleteStudentSession', 'userId', userId)
            const localVarPath = `/admin/delete-student-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (originalSessionId !== undefined) {
                localVarQueryParameter['originalSessionId'] = originalSessionId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} moeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteTeacherMOE: async (moeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'moeId' is not null or undefined
            assertParamExists('adminDeleteTeacherMOE', 'moeId', moeId)
            const localVarPath = `/admin/teachers/qualification-moes/{moeId}`
                .replace(`{${"moeId"}}`, encodeURIComponent(String(moeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} moeAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteTeacherMOEArea: async (moeAreaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'moeAreaId' is not null or undefined
            assertParamExists('adminDeleteTeacherMOEArea', 'moeAreaId', moeAreaId)
            const localVarPath = `/admin/teacher-moe-areas/{moeAreaId}`
                .replace(`{${"moeAreaId"}}`, encodeURIComponent(String(moeAreaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteVenue: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('adminDeleteVenue', 'venueId', venueId)
            const localVarPath = `/admin/venues/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteVenueRoom: async (roomId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('adminDeleteVenueRoom', 'roomId', roomId)
            const localVarPath = `/admin/venues/rooms/delete/{roomId}`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteVoucherCampaigns: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminDeleteVoucherCampaigns', 'requestBody', requestBody)
            const localVarPath = `/admin/delete-voucher-campaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDownloadDBS: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminDownloadDBS', 'requestBody', requestBody)
            const localVarPath = `/admin/dbs-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditCourseDescription: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminEditCourseDescription', 'requestBody', requestBody)
            const localVarPath = `/admin/edit-course-description`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditCreditConfig: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminEditCreditConfig', 'requestBody', requestBody)
            const localVarPath = `/admin/edit-credit-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditInvoice: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminEditInvoice', 'requestBody', requestBody)
            const localVarPath = `/admin/room-booking-invoices/edit-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditNumberOfSession: async (courseInstanceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseInstanceId' is not null or undefined
            assertParamExists('adminEditNumberOfSession', 'courseInstanceId', courseInstanceId)
            const localVarPath = `/admin/post-number-of-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (courseInstanceId !== undefined) {
                localVarQueryParameter['courseInstanceId'] = courseInstanceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditStatusEpForVenue: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminEditStatusEpForVenue', 'requestBody', requestBody)
            const localVarPath = `/admin/edit-status-assign-ep`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEquipmentSelects: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/equipments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExportAttendanceReportToSpreadSheets: async (venueId: string, inlineObject: InlineObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('adminExportAttendanceReportToSpreadSheets', 'venueId', venueId)
            // verify required parameter 'inlineObject' is not null or undefined
            assertParamExists('adminExportAttendanceReportToSpreadSheets', 'inlineObject', inlineObject)
            const localVarPath = `/admin/venues/{venueId}/export-attendance-report-to-spread-sheets`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtendExpirationDateSessionPackAddition: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminExtendExpirationDateSessionPackAddition', 'requestBody', requestBody)
            const localVarPath = `/admin/extend-expiration-date-session-pack-addition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {SubscriptionPaymentMethod} paymentMethod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFindSubscriptionPaymentCard: async (userId: string, paymentMethod: SubscriptionPaymentMethod, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminFindSubscriptionPaymentCard', 'userId', userId)
            // verify required parameter 'paymentMethod' is not null or undefined
            assertParamExists('adminFindSubscriptionPaymentCard', 'paymentMethod', paymentMethod)
            const localVarPath = `/admin/subscriptions/payment-card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (paymentMethod !== undefined) {
                localVarQueryParameter['paymentMethod'] = paymentMethod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {string} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGenerateAttendanceReport: async (venueId: string, instanceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('adminGenerateAttendanceReport', 'venueId', venueId)
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('adminGenerateAttendanceReport', 'instanceId', instanceId)
            const localVarPath = `/admin/venues/{venueId}/generate-attendance-report/{instanceId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CourseSessionRequest>} courseSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGenerateCourseSessions: async (courseSessionRequest: Array<CourseSessionRequest>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseSessionRequest' is not null or undefined
            assertParamExists('adminGenerateCourseSessions', 'courseSessionRequest', courseSessionRequest)
            const localVarPath = `/admin/course-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGenerateInvoicePdf: async (reference: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('adminGenerateInvoicePdf', 'reference', reference)
            const localVarPath = `/admin/invoices/generate/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGenerateSingleReport: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGenerateSingleReport', 'requestBody', requestBody)
            const localVarPath = `/admin/soa-invoices/generate-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAgoraCareUsages: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetAgoraCareUsages', 'referenceId', referenceId)
            const localVarPath = `/admin/agora-care-usages/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAgreementByCourseId: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminGetAgreementByCourseId', 'courseId', courseId)
            const localVarPath = `/admin/education-providers/courses/{courseId}/view-agreement`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAllEmailTemplates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/all-email-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAllKisiConfigurations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/kisi-configuration/get-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetApproverSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/approver`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAttendanceBySession: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetAttendanceBySession', 'requestBody', requestBody)
            const localVarPath = `/admin/attendance-by-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {string} [providerId] 
         * @param {string} [month] 
         * @param {boolean} [onlyShowEPsWithOffPlatformStudent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAttendanceReportsByVenue: async (venueId: string, providerId?: string, month?: string, onlyShowEPsWithOffPlatformStudent?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('adminGetAttendanceReportsByVenue', 'venueId', venueId)
            const localVarPath = `/admin/venues/{venueId}/attendance-reports`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (onlyShowEPsWithOffPlatformStudent !== undefined) {
                localVarQueryParameter['onlyShowEPsWithOffPlatformStudent'] = onlyShowEPsWithOffPlatformStudent;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAvailableCourseInstance: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/parents-students-courses/available-course-instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAvailableRooms: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetAvailableRooms', 'requestBody', requestBody)
            const localVarPath = `/admin/available-rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCampaignsParent: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetCampaignsParent', 'referenceId', referenceId)
            const localVarPath = `/admin/campaigns/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCategoryList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/products/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentId 
         * @param {any} date 
         * @param {string} [venueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCheckinAbleSessionsForStudentAndDateWithSessionPack: async (studentId: string, date: any, venueId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('adminGetCheckinAbleSessionsForStudentAndDateWithSessionPack', 'studentId', studentId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('adminGetCheckinAbleSessionsForStudentAndDateWithSessionPack', 'date', date)
            const localVarPath = `/admin/checkin-able-sessions-for-student-with-session-pack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (venueId !== undefined) {
                localVarQueryParameter['venueId'] = venueId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetChildrenByParentForMessage: async (parentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('adminGetChildrenByParentForMessage', 'parentId', parentId)
            const localVarPath = `/admin/messages/children-list-by-parent/{parentId}`
                .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetChildrenInfo: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetChildrenInfo', 'referenceId', referenceId)
            const localVarPath = `/admin/children-list/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetChildrenListToAddStudentCare: async (parentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('adminGetChildrenListToAddStudentCare', 'parentId', parentId)
            const localVarPath = `/admin/products/children-list/{parentId}`
                .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} forObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContextSensitiveHelps: async (forObject: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'forObject' is not null or undefined
            assertParamExists('adminGetContextSensitiveHelps', 'forObject', forObject)
            const localVarPath = `/admin/context-sensitive-help/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (forObject !== undefined) {
                localVarQueryParameter['forObject'] = forObject;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseClassDetail: async (courseId: string, classId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminGetCourseClassDetail', 'courseId', courseId)
            // verify required parameter 'classId' is not null or undefined
            assertParamExists('adminGetCourseClassDetail', 'classId', classId)
            const localVarPath = `/admin/courses/{courseId}/classes/{classId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"classId"}}`, encodeURIComponent(String(classId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseClasses: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminGetCourseClasses', 'courseId', courseId)
            const localVarPath = `/admin/courses/{courseId}/classes`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseClassesForMessage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/messages/course-class-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseDetails: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetCourseDetails', 'referenceId', referenceId)
            const localVarPath = `/admin/courses/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseEnrolledStudents: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminGetCourseEnrolledStudents', 'courseId', courseId)
            const localVarPath = `/admin/courses/{courseId}/enrolled-students`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseEnrolmentsViewDataV2: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/course-enrolments-view-v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceCancellationReasons: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/course-instances/get-course-instance-cancellation-reasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceCode: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminGetCourseInstanceCode', 'courseId', courseId)
            const localVarPath = `/admin/get-course-instance-code/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceDetails: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetCourseInstanceDetails', 'referenceId', referenceId)
            const localVarPath = `/admin/course-instance-details/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceFrom: async (studentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('adminGetCourseInstanceFrom', 'studentId', studentId)
            const localVarPath = `/admin/reschedule/students/{studentId}/course-instance-from`
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceListView: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetCourseInstanceListView', 'requestBody', requestBody)
            const localVarPath = `/admin/course-instance-list-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceReservations: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetCourseInstanceReservations', 'referenceId', referenceId)
            const localVarPath = `/admin/course-instance-reservations/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceStartDate: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetCourseInstanceStartDate', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/course-instances/get-start-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fromCourseInstanceId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceTo: async (fromCourseInstanceId: string, studentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromCourseInstanceId' is not null or undefined
            assertParamExists('adminGetCourseInstanceTo', 'fromCourseInstanceId', fromCourseInstanceId)
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('adminGetCourseInstanceTo', 'studentId', studentId)
            const localVarPath = `/admin/reschedule/{fromCourseInstanceId}/students/{studentId}/course-instances-to`
                .replace(`{${"fromCourseInstanceId"}}`, encodeURIComponent(String(fromCourseInstanceId)))
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceToAddStudentCare: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminGetCourseInstanceToAddStudentCare', 'courseId', courseId)
            const localVarPath = `/admin/products/course-instance-list/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstancesForCreateMessage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/messages/course-instance-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstancesOfBulkEnrolStudents: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetCourseInstancesOfBulkEnrolStudents', 'requestBody', requestBody)
            const localVarPath = `/admin/courses/bulk-enrol/courses-of-bulk-enrol-students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCoursePeriodSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/course-period`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseSessionDetail: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('adminGetCourseSessionDetail', 'sessionId', sessionId)
            const localVarPath = `/admin/course-session-detail/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseSessions: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetCourseSessions', 'referenceId', referenceId)
            const localVarPath = `/admin/course-sessions/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseStudents: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminGetCourseStudents', 'courseId', courseId)
            const localVarPath = `/admin/courses/{courseId}/students`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherReferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCoursesAssignedByTeacherId: async (teacherReferenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherReferenceId' is not null or undefined
            assertParamExists('adminGetCoursesAssignedByTeacherId', 'teacherReferenceId', teacherReferenceId)
            const localVarPath = `/admin/education-providers/team/courses-assigned/{teacherReferenceId}`
                .replace(`{${"teacherReferenceId"}}`, encodeURIComponent(String(teacherReferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCoursesByProvider: async (providerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('adminGetCoursesByProvider', 'providerId', providerId)
            const localVarPath = `/admin/course/courses-by-provider/{providerId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCoursesList: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetCoursesList', 'requestBody', requestBody)
            const localVarPath = `/admin/get-courses-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCoursesSeriesForAssignStudent: async (courseId: string, studentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminGetCoursesSeriesForAssignStudent', 'courseId', courseId)
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('adminGetCoursesSeriesForAssignStudent', 'studentId', studentId)
            const localVarPath = `/admin/course/get-course-series-for-assign-student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCreditConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/credit-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCreditList: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetCreditList', 'referenceId', referenceId)
            const localVarPath = `/admin/credit-list/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCreditReason: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/credit-reason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDailyScheduleData: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetDailyScheduleData', 'requestBody', requestBody)
            const localVarPath = `/admin/daily-schedule-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDiscountCodes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/campaigns/get/discount-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDiscountCodesById: async (campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('adminGetDiscountCodesById', 'campaignId', campaignId)
            const localVarPath = `/admin/campaigns/get/discount-codes/{campaignId}`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDiscountList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/discounts/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDiscounts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDiscountsById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGetDiscountsById', 'id', id)
            const localVarPath = `/admin/discounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEPReports: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/soa-invoices/ep-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [venueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEducationProviderOptions: async (venueId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/education-providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (venueId !== undefined) {
                localVarQueryParameter['venueId'] = venueId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} searchKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEducationProviders: async (searchKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchKey' is not null or undefined
            assertParamExists('adminGetEducationProviders', 'searchKey', searchKey)
            const localVarPath = `/admin/education-providers/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (searchKey !== undefined) {
                localVarQueryParameter['searchKey'] = searchKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEducationProvidersById: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetEducationProvidersById', 'referenceId', referenceId)
            const localVarPath = `/admin/education-providers/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEducationProvidersByVenueId: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('adminGetEducationProvidersByVenueId', 'venueId', venueId)
            const localVarPath = `/admin/venue-education-providers/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEducationProvidersSelect: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('adminGetEducationProvidersSelect', 'venueId', venueId)
            const localVarPath = `/admin/education-providers-select/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEmailTemplateDetailsWithoutBrand: async (templateAlias: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateAlias' is not null or undefined
            assertParamExists('adminGetEmailTemplateDetailsWithoutBrand', 'templateAlias', templateAlias)
            const localVarPath = `/admin/email-templates-without-brand/{templateAlias}`
                .replace(`{${"templateAlias"}}`, encodeURIComponent(String(templateAlias)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEmailTemplates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/email-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {Array<string>} [aliasPrefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEmailTemplatesWithoutBrand: async (search?: string, aliasPrefix?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/email-templates-without-brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (aliasPrefix) {
                localVarQueryParameter['alias-prefix'] = aliasPrefix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminGetEnrolledInstanceByStudentRequest} adminGetEnrolledInstanceByStudentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEnrolledInstanceByStudent: async (adminGetEnrolledInstanceByStudentRequest: AdminGetEnrolledInstanceByStudentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminGetEnrolledInstanceByStudentRequest' is not null or undefined
            assertParamExists('adminGetEnrolledInstanceByStudent', 'adminGetEnrolledInstanceByStudentRequest', adminGetEnrolledInstanceByStudentRequest)
            const localVarPath = `/admin/get-enrolled-instances-by-student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminGetEnrolledInstanceByStudentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEnrolledStudents: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetEnrolledStudents', 'referenceId', referenceId)
            const localVarPath = `/admin/enrolled-students/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetGradeLevelSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/age`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetGuardians: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetGuardians', 'referenceId', referenceId)
            const localVarPath = `/admin/guardians/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetInvoiceDraftOrPendingByEpId: async (educationProviderId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationProviderId' is not null or undefined
            assertParamExists('adminGetInvoiceDraftOrPendingByEpId', 'educationProviderId', educationProviderId)
            const localVarPath = `/admin/course-instances/invoice-draft-or-pending/{educationProviderId}`
                .replace(`{${"educationProviderId"}}`, encodeURIComponent(String(educationProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetInvoiceLineList: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetInvoiceLineList', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/invoice-lines/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetInvoiceListCount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/invoices/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetInvoiceListRefactor: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetInvoiceListRefactor', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/v2/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [venueId] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetInvoiceProductList: async (venueId?: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/invoices/product-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (venueId !== undefined) {
                localVarQueryParameter['venueId'] = venueId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMailDomains: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/email-server/mail-domains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMessageCategories: async (limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/messages/message-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMessageCategoryById: async (categoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('adminGetMessageCategoryById', 'categoryId', categoryId)
            const localVarPath = `/admin/messages/message-categories/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MessageNewTypeEnum} [type] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMessages: async (type?: MessageNewTypeEnum, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [parentId] 
         * @param {string} [studentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMessagesByParent: async (parentId?: string, studentId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/parents-students/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMessagingDetails: async (messageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('adminGetMessagingDetails', 'messageId', messageId)
            const localVarPath = `/admin/messages/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetNextProductNo: async (sku: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sku' is not null or undefined
            assertParamExists('adminGetNextProductNo', 'sku', sku)
            const localVarPath = `/admin/products/number-product-no`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentAvailableCredit: async (parentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('adminGetParentAvailableCredit', 'parentId', parentId)
            const localVarPath = `/admin/parents/{parentId}/available-credit`
                .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentByStudentId: async (studentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('adminGetParentByStudentId', 'studentId', studentId)
            const localVarPath = `/admin/parent-by-student/{studentId}`
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentGuardianByUser: async (parentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('adminGetParentGuardianByUser', 'parentId', parentId)
            const localVarPath = `/admin/parent-guardian-info/{parentId}`
                .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentInfo: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetParentInfo', 'referenceId', referenceId)
            const localVarPath = `/admin/parent-info/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentNotes: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetParentNotes', 'referenceId', referenceId)
            const localVarPath = `/admin/parent-notes/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parent 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentStudentSchedule: async (parent: string, start?: string, end?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'parent' is not null or undefined
            assertParamExists('adminGetParentStudentSchedule', 'parent', parent)
            const localVarPath = `/admin/parent/student-schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (parent !== undefined) {
                localVarQueryParameter['parent'] = parent;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentsForCreateMessage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/messages/parent-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentsStudentsCourses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/parents-students-courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminGetStudentWithPaymentHistoryNewRequest} adminGetStudentWithPaymentHistoryNewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPaymentHistoriesByCourseId: async (adminGetStudentWithPaymentHistoryNewRequest: AdminGetStudentWithPaymentHistoryNewRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminGetStudentWithPaymentHistoryNewRequest' is not null or undefined
            assertParamExists('adminGetPaymentHistoriesByCourseId', 'adminGetStudentWithPaymentHistoryNewRequest', adminGetStudentWithPaymentHistoryNewRequest)
            const localVarPath = `/admin/students/payment-histories-new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminGetStudentWithPaymentHistoryNewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPermissions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPresignedPutURL: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetPresignedPutURL', 'requestBody', requestBody)
            const localVarPath = `/admin/get-presigned-put-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProductBySKU: async (sku: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sku' is not null or undefined
            assertParamExists('adminGetProductBySKU', 'sku', sku)
            const localVarPath = `/admin/products/sku/{sku}`
                .replace(`{${"sku"}}`, encodeURIComponent(String(sku)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProductList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/products/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProviderSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetQuickSightAgoraAtSchoolReportsUrl: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/quicksight-agora-at-school-reports-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetQuickSightAgoraEpMarketplaceReportsUrl: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/quicksight-ep-marketplace-reports-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetQuickSightAgoraStudentCareReportUrl: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/quicksight/student-care-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetQuickSightEarlyYearsReportUrl: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/quicksight/early-years-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetQuickSightEmbedUrl: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/quicksight-course-availability-embed-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetQuickSightFinanceUpdateReportsUrl: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/quicksight-finance-update-reports-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetQuickSightSharedOpsReportsUrl: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/quicksight-shared-ops-reports-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRateCards: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/rate-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRefundConfigs: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/refund-configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRefundParent: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetRefundParent', 'referenceId', referenceId)
            const localVarPath = `/admin/refund/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRegistrations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/registrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetReplacementConfiguration: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/replacement-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetReplacementHistory: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetReplacementHistory', 'requestBody', requestBody)
            const localVarPath = `/admin/replacement-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetReservedData: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetReservedData', 'referenceId', referenceId)
            const localVarPath = `/admin/reserved-data/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetReservedStartDate: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetReservedStartDate', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/reserved/get-start-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetReservedTrialSingleSession: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetReservedTrialSingleSession', 'referenceId', referenceId)
            const localVarPath = `/admin/reserved-trial-single-session/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRevenueRefund: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetRevenueRefund', 'requestBody', requestBody)
            const localVarPath = `/admin/revenue-refund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRolePermissions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/roles-permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRoles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRoomBookingInvoiceDetail: async (reference: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('adminGetRoomBookingInvoiceDetail', 'reference', reference)
            const localVarPath = `/admin/room-booking-invoices/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} startDate 
         * @param {any} endDate 
         * @param {string} searchKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRoomCalendar: async (startDate: any, endDate: any, searchKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('adminGetRoomCalendar', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('adminGetRoomCalendar', 'endDate', endDate)
            // verify required parameter 'searchKey' is not null or undefined
            assertParamExists('adminGetRoomCalendar', 'searchKey', searchKey)
            const localVarPath = `/admin/rooms/calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (searchKey !== undefined) {
                localVarQueryParameter['searchKey'] = searchKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} roomBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRoomCheckInMedia: async (roomBookingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomBookingId' is not null or undefined
            assertParamExists('adminGetRoomCheckInMedia', 'roomBookingId', roomBookingId)
            const localVarPath = `/admin/venues/room-check-in-media/{roomBookingId}`
                .replace(`{${"roomBookingId"}}`, encodeURIComponent(String(roomBookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRoomCheckInReport: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('adminGetRoomCheckInReport', 'venueId', venueId)
            const localVarPath = `/admin/venues/room-check-in-report/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRooms: async (providerId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSchoolSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/schools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSchools: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/schools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSchoolsSelect: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('adminGetSchoolsSelect', 'venueId', venueId)
            const localVarPath = `/admin/select/schools/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSentEmailsByUserId: async (referenceId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetSentEmailsByUserId', 'referenceId', referenceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminGetSentEmailsByUserId', 'userId', userId)
            const localVarPath = `/admin/parents/{referenceId}/sent-emails`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [date] 
         * @param {string} [venueId] 
         * @param {Array<string>} [courseInstanceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionByDate: async (date?: any, venueId?: string, courseInstanceIds?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/course-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (venueId !== undefined) {
                localVarQueryParameter['venueId'] = venueId;
            }

            if (courseInstanceIds) {
                localVarQueryParameter['courseInstanceIds'] = courseInstanceIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionPackHistoryByCourseId: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetSessionPackHistoryByCourseId', 'referenceId', referenceId)
            const localVarPath = `/admin/courses/{referenceId}/session-packs/histories`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionPackStartDate: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetSessionPackStartDate', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/session-packs/get-start-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionPacksByCourseId: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetSessionPacksByCourseId', 'referenceId', referenceId)
            const localVarPath = `/admin/courses/{referenceId}/session-packs`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionPacksByParentId: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetSessionPacksByParentId', 'referenceId', referenceId)
            const localVarPath = `/admin/parents/{referenceId}/session-packs/histories`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionPacksSelect: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminGetSessionPacksSelect', 'courseId', courseId)
            const localVarPath = `/admin/select/session-packs/courses/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionPacksUsageBySessionPackAdditionId: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGetSessionPacksUsageBySessionPackAdditionId', 'id', id)
            const localVarPath = `/admin/parents/session-packs-usage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentId 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionStudentNotAttending: async (studentId: string, courseInstanceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('adminGetSessionStudentNotAttending', 'studentId', studentId)
            // verify required parameter 'courseInstanceId' is not null or undefined
            assertParamExists('adminGetSessionStudentNotAttending', 'courseInstanceId', courseInstanceId)
            const localVarPath = `/admin/session-student-not-attending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            if (courseInstanceId !== undefined) {
                localVarQueryParameter['courseInstanceId'] = courseInstanceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionsByCourse: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminGetSessionsByCourse', 'courseId', courseId)
            const localVarPath = `/admin/course/{courseId}/course-sessions`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSiblingToUseSessionPack: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetSiblingToUseSessionPack', 'requestBody', requestBody)
            const localVarPath = `/admin/student-check-in/sibling-can-use-session-pack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentCourseInstancesLevel: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGetStudentCourseInstancesLevel', 'id', id)
            const localVarPath = `/admin/student/{id}/course-instance-level`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentRefernceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentCourses: async (studentRefernceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentRefernceId' is not null or undefined
            assertParamExists('adminGetStudentCourses', 'studentRefernceId', studentRefernceId)
            const localVarPath = `/admin/student-courses/{studentRefernceId}`
                .replace(`{${"studentRefernceId"}}`, encodeURIComponent(String(studentRefernceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentCoursesLevel: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGetStudentCoursesLevel', 'id', id)
            const localVarPath = `/admin/student/{id}/courses-level`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentForUploadBirthdayCertificate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/migration/get-student-list-for-migrate-birthday-certificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentInfo: async (studentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('adminGetStudentInfo', 'studentId', studentId)
            const localVarPath = `/admin/student-info/{studentId}`
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentNoteTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/student-notes/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [studentId] 
         * @param {string} [parentId] 
         * @param {string} [courseInstanceId] 
         * @param {string} [courseSessionId] 
         * @param {boolean} [showOnCheckin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentNotes: async (studentId?: string, parentId?: string, courseInstanceId?: string, courseSessionId?: string, showOnCheckin?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/student-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (courseInstanceId !== undefined) {
                localVarQueryParameter['courseInstanceId'] = courseInstanceId;
            }

            if (courseSessionId !== undefined) {
                localVarQueryParameter['courseSessionId'] = courseSessionId;
            }

            if (showOnCheckin !== undefined) {
                localVarQueryParameter['showOnCheckin'] = showOnCheckin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentReservedCourseInstances: async (studentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('adminGetStudentReservedCourseInstances', 'studentId', studentId)
            const localVarPath = `/admin/student-reserved-course-instance/{studentId}`
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentsCanReserve: async (courseInstanceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseInstanceId' is not null or undefined
            assertParamExists('adminGetStudentsCanReserve', 'courseInstanceId', courseInstanceId)
            const localVarPath = `/admin/students-can-reserve/{courseInstanceId}`
                .replace(`{${"courseInstanceId"}}`, encodeURIComponent(String(courseInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentsCanTrialSession: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetStudentsCanTrialSession', 'requestBody', requestBody)
            const localVarPath = `/admin/students-can-trial-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentsCheckIn: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetStudentsCheckIn', 'requestBody', requestBody)
            const localVarPath = `/admin/student-check-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentsCheckOut: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetStudentsCheckOut', 'requestBody', requestBody)
            const localVarPath = `/admin/student-check-out`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentsWithBookings: async (limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/parents-students-courses/students-with-bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentsWithoutBookings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/parents-students-courses/students-without-bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSubjectAreaSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/subject-area`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSubjectSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/subject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSubscriptionById: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetSubscriptionById', 'referenceId', referenceId)
            const localVarPath = `/admin/subscriptions/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSubscriptionList: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminGetSubscriptionList', 'requestBody', requestBody)
            const localVarPath = `/admin/subscriptions/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTags: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTeacherMOEAreas: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/teacher-moe-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} moeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTeacherMOEsByMOE: async (moeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'moeId' is not null or undefined
            assertParamExists('adminGetTeacherMOEsByMOE', 'moeId', moeId)
            const localVarPath = `/admin/teachers/qualification-moes/{moeId}`
                .replace(`{${"moeId"}}`, encodeURIComponent(String(moeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTeachers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {boolean} [includeAdminUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTeachersByEp: async (educationProviderId: string, includeAdminUsers?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationProviderId' is not null or undefined
            assertParamExists('adminGetTeachersByEp', 'educationProviderId', educationProviderId)
            const localVarPath = `/admin/education-providers/{educationProviderId}/teachers`
                .replace(`{${"educationProviderId"}}`, encodeURIComponent(String(educationProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (includeAdminUsers !== undefined) {
                localVarQueryParameter['includeAdminUsers'] = includeAdminUsers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTeachersById: async (teacherId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('adminGetTeachersById', 'teacherId', teacherId)
            const localVarPath = `/admin/teachers/{teacherId}`
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} epReferenceId 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTeamByEpId: async (epReferenceId: string, search: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'epReferenceId' is not null or undefined
            assertParamExists('adminGetTeamByEpId', 'epReferenceId', epReferenceId)
            // verify required parameter 'search' is not null or undefined
            assertParamExists('adminGetTeamByEpId', 'search', search)
            const localVarPath = `/admin/education-providers/{epReferenceId}/team`
                .replace(`{${"epReferenceId"}}`, encodeURIComponent(String(epReferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {string} teacherId 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTeamByEpTeacherId: async (educationProviderId: string, teacherId: string, search: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationProviderId' is not null or undefined
            assertParamExists('adminGetTeamByEpTeacherId', 'educationProviderId', educationProviderId)
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('adminGetTeamByEpTeacherId', 'teacherId', teacherId)
            // verify required parameter 'search' is not null or undefined
            assertParamExists('adminGetTeamByEpTeacherId', 'search', search)
            const localVarPath = `/admin/education-providers/{educationProviderId}/team/{teacherId}`
                .replace(`{${"educationProviderId"}}`, encodeURIComponent(String(educationProviderId)))
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} emailParent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTicketParent: async (emailParent: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailParent' is not null or undefined
            assertParamExists('adminGetTicketParent', 'emailParent', emailParent)
            const localVarPath = `/admin/ticket/{emailParent}`
                .replace(`{${"emailParent"}}`, encodeURIComponent(String(emailParent)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminGetTotalParentsRequest} adminGetTotalParentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTotalParentsForConfirmMessage: async (adminGetTotalParentsRequest: AdminGetTotalParentsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminGetTotalParentsRequest' is not null or undefined
            assertParamExists('adminGetTotalParentsForConfirmMessage', 'adminGetTotalParentsRequest', adminGetTotalParentsRequest)
            const localVarPath = `/admin/messages/total-parents-will-send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminGetTotalParentsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTransactionParent: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetTransactionParent', 'referenceId', referenceId)
            const localVarPath = `/admin/transaction/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} financialTransactionsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTransactionRefund: async (financialTransactionsId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'financialTransactionsId' is not null or undefined
            assertParamExists('adminGetTransactionRefund', 'financialTransactionsId', financialTransactionsId)
            const localVarPath = `/admin/transaction-refund/{financialTransactionsId}`
                .replace(`{${"financialTransactionsId"}}`, encodeURIComponent(String(financialTransactionsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {string} studentSessionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTrialCourseInstances: async (referenceId: string, studentSessionType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminGetTrialCourseInstances', 'referenceId', referenceId)
            // verify required parameter 'studentSessionType' is not null or undefined
            assertParamExists('adminGetTrialCourseInstances', 'studentSessionType', studentSessionType)
            const localVarPath = `/admin/trial-course-instance/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (studentSessionType !== undefined) {
                localVarQueryParameter['studentSessionType'] = studentSessionType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTrialSessionsOfStudent: async (studentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('adminGetTrialSessionsOfStudent', 'studentId', studentId)
            const localVarPath = `/admin/trial-sessions-of-student/{studentId}`
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVenueRoomDetail: async (roomId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('adminGetVenueRoomDetail', 'roomId', roomId)
            const localVarPath = `/admin/venues/rooms/get/{roomId}`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVenueRooms: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('adminGetVenueRooms', 'venueId', venueId)
            const localVarPath = `/admin/venues/{venueId}/rooms`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVenues: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/venues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVenuesById: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('adminGetVenuesById', 'venueId', venueId)
            const localVarPath = `/admin/venues/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetVenuesForCreateMessageType} [venueType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVenuesForCreateMessage: async (venueType?: GetVenuesForCreateMessageType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/messages/venue-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (venueType !== undefined) {
                localVarQueryParameter['venueType'] = venueType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVoucherCampaignHistories: async (campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('adminGetVoucherCampaignHistories', 'campaignId', campaignId)
            const localVarPath = `/admin/voucher-campaign-histories/{campaignId}`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVoucherCampaigns: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/voucher-campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVoucherRecords: async (campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('adminGetVoucherRecords', 'campaignId', campaignId)
            const localVarPath = `/admin/voucher-records/{campaignId}`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGstRatesSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/gst-rates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminHandleAfterConfirmSeatsCopyCourseInstanceRequest} adminHandleAfterConfirmSeatsCopyCourseInstanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminHandleAfterConfirmSeatsCopyCourseInstance: async (adminHandleAfterConfirmSeatsCopyCourseInstanceRequest: AdminHandleAfterConfirmSeatsCopyCourseInstanceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminHandleAfterConfirmSeatsCopyCourseInstanceRequest' is not null or undefined
            assertParamExists('adminHandleAfterConfirmSeatsCopyCourseInstance', 'adminHandleAfterConfirmSeatsCopyCourseInstanceRequest', adminHandleAfterConfirmSeatsCopyCourseInstanceRequest)
            const localVarPath = `/admin/course/handle-after-confirm-seats-copy-course-instance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminHandleAfterConfirmSeatsCopyCourseInstanceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminImportPlayschoolCheckinData: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminImportPlayschoolCheckinData', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/import-playschool-checkin-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvoiceAmountLeftToPay: async (reference: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('adminInvoiceAmountLeftToPay', 'reference', reference)
            const localVarPath = `/admin/invoices/amount-left-to-pay/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIsAfterSellingInstance: async (courseInstanceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseInstanceId' is not null or undefined
            assertParamExists('adminIsAfterSellingInstance', 'courseInstanceId', courseInstanceId)
            const localVarPath = `/admin/is-after-selling-instance/{courseInstanceId}`
                .replace(`{${"courseInstanceId"}}`, encodeURIComponent(String(courseInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMarkAttendingSession: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminMarkAttendingSession', 'requestBody', requestBody)
            const localVarPath = `/admin/mark-attending-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMergeParentsAndStudents: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminMergeParentsAndStudents', 'requestBody', requestBody)
            const localVarPath = `/admin/parents/mergeInto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminMigrateExistingStudentRequest} adminMigrateExistingStudentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMigrateExistingStudent: async (adminMigrateExistingStudentRequest: AdminMigrateExistingStudentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminMigrateExistingStudentRequest' is not null or undefined
            assertParamExists('adminMigrateExistingStudent', 'adminMigrateExistingStudentRequest', adminMigrateExistingStudentRequest)
            const localVarPath = `/admin/migrate-existing-student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminMigrateExistingStudentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminMigrateExistingStudentCareStudentsRequest} adminMigrateExistingStudentCareStudentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMigrateExistingStudentCareStudents: async (adminMigrateExistingStudentCareStudentsRequest: AdminMigrateExistingStudentCareStudentsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminMigrateExistingStudentCareStudentsRequest' is not null or undefined
            assertParamExists('adminMigrateExistingStudentCareStudents', 'adminMigrateExistingStudentCareStudentsRequest', adminMigrateExistingStudentCareStudentsRequest)
            const localVarPath = `/admin/migrate-existing-student-care-students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminMigrateExistingStudentCareStudentsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userReferenceId 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMixpanelGetUserActivityFeed: async (userReferenceId: string, fromDate: string, toDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userReferenceId' is not null or undefined
            assertParamExists('adminMixpanelGetUserActivityFeed', 'userReferenceId', userReferenceId)
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('adminMixpanelGetUserActivityFeed', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('adminMixpanelGetUserActivityFeed', 'toDate', toDate)
            const localVarPath = `/admin/mixpanel/query/user-activity-feed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (userReferenceId !== undefined) {
                localVarQueryParameter['userReferenceId'] = userReferenceId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPinStudentNote: async (studentNoteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentNoteId' is not null or undefined
            assertParamExists('adminPinStudentNote', 'studentNoteId', studentNoteId)
            const localVarPath = `/admin/student-notes/{studentNoteId}/pin`
                .replace(`{${"studentNoteId"}}`, encodeURIComponent(String(studentNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPostCourse: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminPostCourse', 'requestBody', requestBody)
            const localVarPath = `/admin/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPostParentNotes: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminPostParentNotes', 'requestBody', requestBody)
            const localVarPath = `/admin/parent-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPostUserSaveCard: async (inlineObject2: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject2' is not null or undefined
            assertParamExists('adminPostUserSaveCard', 'inlineObject2', inlineObject2)
            const localVarPath = `/admin/subscriptions/user-save-card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPrepareOldInvoiceData: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminPrepareOldInvoiceData', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/prepare-old-invoice-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPreparePlayschoolCheckinData: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminPreparePlayschoolCheckinData', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/prepare-playschool-checkin-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPutSubscriptionLines: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminPutSubscriptionLines', 'requestBody', requestBody)
            const localVarPath = `/admin/subscriptions/subscription-lines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReActiveSubscription: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminReActiveSubscription', 'requestBody', requestBody)
            const localVarPath = `/admin/subscriptions/re-active-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReGenerateRoomBookingInvoice: async (inlineObject5: InlineObject5, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject5' is not null or undefined
            assertParamExists('adminReGenerateRoomBookingInvoice', 'inlineObject5', inlineObject5)
            const localVarPath = `/admin/re-generate-room-booking-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject5, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {boolean} [isNotSendMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRecordPayment: async (requestBody: { [key: string]: object; }, isNotSendMail?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminRecordPayment', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/record-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (isNotSendMail !== undefined) {
                localVarQueryParameter['isNotSendMail'] = isNotSendMail;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {boolean} [isNotSendMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRefundCourseSessions: async (requestBody: { [key: string]: object; }, isNotSendMail?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminRefundCourseSessions', 'requestBody', requestBody)
            const localVarPath = `/admin/refund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (isNotSendMail !== undefined) {
                localVarQueryParameter['isNotSendMail'] = isNotSendMail;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveBulkEnrol: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminRemoveBulkEnrol', 'requestBody', requestBody)
            const localVarPath = `/admin/courses/bulk-enrol/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveCourseMedia: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminRemoveCourseMedia', 'requestBody', requestBody)
            const localVarPath = `/admin/remove-course-media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveRoomBookingInvoiceLine: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminRemoveRoomBookingInvoiceLine', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/remove-room-booking-invoice-line`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResendPassword: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminResendPassword', 'requestBody', requestBody)
            const localVarPath = `/admin/set-user-temporary-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} roomId 
         * @param {string} courseSessionId 
         * @param {string} [sessionGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRoomCalendar: async (roomId: string, courseSessionId: string, sessionGroup?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('adminRoomCalendar', 'roomId', roomId)
            // verify required parameter 'courseSessionId' is not null or undefined
            assertParamExists('adminRoomCalendar', 'courseSessionId', courseSessionId)
            const localVarPath = `/admin/rooms/{roomId}/more-detail`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (courseSessionId !== undefined) {
                localVarQueryParameter['courseSessionId'] = courseSessionId;
            }

            if (sessionGroup !== undefined) {
                localVarQueryParameter['sessionGroup'] = sessionGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [venueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRoomTypeSelects: async (venueId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/room-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (venueId !== undefined) {
                localVarQueryParameter['venueId'] = venueId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<AdminSendReservationLinksRequest>} adminSendReservationLinksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendBookingLinks: async (adminSendReservationLinksRequest: Array<AdminSendReservationLinksRequest>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminSendReservationLinksRequest' is not null or undefined
            assertParamExists('adminSendBookingLinks', 'adminSendReservationLinksRequest', adminSendReservationLinksRequest)
            const localVarPath = `/admin/reservation/send-booking-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSendReservationLinksRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendEpAgreement: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminSendEpAgreement', 'requestBody', requestBody)
            const localVarPath = `/admin/send-ep-agreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetParentCourseReminderEmailDataWithConditionRequest} getParentCourseReminderEmailDataWithConditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendMailParentCourseReminderWithCondition: async (getParentCourseReminderEmailDataWithConditionRequest: GetParentCourseReminderEmailDataWithConditionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getParentCourseReminderEmailDataWithConditionRequest' is not null or undefined
            assertParamExists('adminSendMailParentCourseReminderWithCondition', 'getParentCourseReminderEmailDataWithConditionRequest', getParentCourseReminderEmailDataWithConditionRequest)
            const localVarPath = `/admin/mail/send-reminder-courses-with-condition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getParentCourseReminderEmailDataWithConditionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendNotifyReplacementEmail: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminSendNotifyReplacementEmail', 'requestBody', requestBody)
            const localVarPath = `/admin/notify-replacement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendOrResendLoginInviteToParent: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminSendOrResendLoginInviteToParent', 'requestBody', requestBody)
            const localVarPath = `/admin/parents/send-or-resend-login-invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendReplacementEmailAndNotiToEpTeacher: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminSendReplacementEmailAndNotiToEpTeacher', 'requestBody', requestBody)
            const localVarPath = `/admin/replacement-email-for-ep-teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendRoomBookingInvoiceMail: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminSendRoomBookingInvoiceMail', 'requestBody', requestBody)
            const localVarPath = `/admin/room-booking-invoices/send-mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendSubscriptionMail: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminSendSubscriptionMail', 'requestBody', requestBody)
            const localVarPath = `/admin/subscriptions/send-mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendWithdrawTrialStudentEmail: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminSendWithdrawTrialStudentEmail', 'requestBody', requestBody)
            const localVarPath = `/admin/send-withdraw-trial-student-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSubmitForApprovalCourse: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminSubmitForApprovalCourse', 'requestBody', requestBody)
            const localVarPath = `/admin/submit-for-approval`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSubmitForApprovalCourseInstance: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminSubmitForApprovalCourseInstance', 'requestBody', requestBody)
            const localVarPath = `/admin/course-instances/submit-for-approval`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSubmitForApprovalInvoice: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminSubmitForApprovalInvoice', 'requestBody', requestBody)
            const localVarPath = `/admin/room-booking-invoices/submit-for-approval-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTransferSessionPackAddition: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminTransferSessionPackAddition', 'requestBody', requestBody)
            const localVarPath = `/admin/transfer-session-pack-addition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnCheckinStudent: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUnCheckinStudent', 'requestBody', requestBody)
            const localVarPath = `/admin/schedule/uncheckin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnPinStudentNote: async (studentNoteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentNoteId' is not null or undefined
            assertParamExists('adminUnPinStudentNote', 'studentNoteId', studentNoteId)
            const localVarPath = `/admin/student-notes/{studentNoteId}/unpin`
                .replace(`{${"studentNoteId"}}`, encodeURIComponent(String(studentNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBirthdayCertificateRequest} updateBirthdayCertificateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBirthdayCertificate: async (updateBirthdayCertificateRequest: UpdateBirthdayCertificateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBirthdayCertificateRequest' is not null or undefined
            assertParamExists('adminUpdateBirthdayCertificate', 'updateBirthdayCertificateRequest', updateBirthdayCertificateRequest)
            const localVarPath = `/admin/migration/update-birthday-certificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBirthdayCertificateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBulkEnrollOffPlatform: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpdateBulkEnrollOffPlatform', 'requestBody', requestBody)
            const localVarPath = `/admin/courses/update-bulk-enroll-off-platform`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateChild: async (id: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUpdateChild', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpdateChild', 'requestBody', requestBody)
            const localVarPath = `/admin/students/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseMetaData: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpdateCourseMetaData', 'requestBody', requestBody)
            const localVarPath = `/admin/course/meta-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateInvoicesOverdue: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/invoices/update-invoices-overdue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateIsShareParentContact: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpdateIsShareParentContact', 'requestBody', requestBody)
            const localVarPath = `/admin/education-providers/update-is-share-parent-contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateMessageCategory: async (categoryId: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('adminUpdateMessageCategory', 'categoryId', categoryId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpdateMessageCategory', 'requestBody', requestBody)
            const localVarPath = `/admin/messages/message-categories/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateOriginalSession: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpdateOriginalSession', 'requestBody', requestBody)
            const localVarPath = `/admin/edit-original-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateReservationStatus: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpdateReservationStatus', 'requestBody', requestBody)
            const localVarPath = `/admin/update-reservation-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateRevenueCaseWithoutSelectedRefundSession: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpdateRevenueCaseWithoutSelectedRefundSession', 'requestBody', requestBody)
            const localVarPath = `/admin/update-revenue-without-selected-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertContextSensitiveHelp: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpsertContextSensitiveHelp', 'requestBody', requestBody)
            const localVarPath = `/admin/context-sensitive-help/upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertCourseInstance: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpsertCourseInstance', 'requestBody', requestBody)
            const localVarPath = `/admin/upsert-course-instance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertCreditReason: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpsertCreditReason', 'requestBody', requestBody)
            const localVarPath = `/admin/upsert-credit-reason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertDiscount: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpsertDiscount', 'requestBody', requestBody)
            const localVarPath = `/admin/discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertDiscountCodes: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpsertDiscountCodes', 'requestBody', requestBody)
            const localVarPath = `/admin/campaigns/discount-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertEducationProvider: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpsertEducationProvider', 'requestBody', requestBody)
            const localVarPath = `/admin/education-providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertKisiConfiguration: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpsertKisiConfiguration', 'requestBody', requestBody)
            const localVarPath = `/admin/kisi-configuration/upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} educationProviderReferenceId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertMember: async (educationProviderReferenceId: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationProviderReferenceId' is not null or undefined
            assertParamExists('adminUpsertMember', 'educationProviderReferenceId', educationProviderReferenceId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpsertMember', 'requestBody', requestBody)
            const localVarPath = `/admin/education-providers/{educationProviderReferenceId}/team`
                .replace(`{${"educationProviderReferenceId"}}`, encodeURIComponent(String(educationProviderReferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertParent: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpsertParent', 'requestBody', requestBody)
            const localVarPath = `/admin/parents/upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertProduct: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpsertProduct', 'requestBody', requestBody)
            const localVarPath = `/admin/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertRolePermission: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpsertRolePermission', 'requestBody', requestBody)
            const localVarPath = `/admin/role-permission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertSessionPack: async (referenceId: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('adminUpsertSessionPack', 'referenceId', referenceId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpsertSessionPack', 'requestBody', requestBody)
            const localVarPath = `/admin/courses/{referenceId}/session-packs`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertVenues: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUpsertVenues', 'requestBody', requestBody)
            const localVarPath = `/admin/venues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminValidateBeforeCancelCourseInstance: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminValidateBeforeCancelCourseInstance', 'requestBody', requestBody)
            const localVarPath = `/admin/course-instances/validate-before-cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminValidateChangeTeacher: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminValidateChangeTeacher', 'requestBody', requestBody)
            const localVarPath = `/admin/validate-change-teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminValidateClassUsed: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminValidateClassUsed', 'requestBody', requestBody)
            const localVarPath = `/admin/venues/validate-class-used`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ValidateCourseSessionRequest>} validateCourseSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminValidateCourseSessions: async (validateCourseSessionRequest: Array<ValidateCourseSessionRequest>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateCourseSessionRequest' is not null or undefined
            assertParamExists('adminValidateCourseSessions', 'validateCourseSessionRequest', validateCourseSessionRequest)
            const localVarPath = `/admin/validate-course-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateCourseSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminValidateStudentIsSignedUpCourseSeries: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminValidateStudentIsSignedUpCourseSeries', 'requestBody', requestBody)
            const localVarPath = `/admin/subscriptions/validate-students/signed-up-courses-series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminValidateTrialSessionRequest: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminValidateTrialSessionRequest', 'requestBody', requestBody)
            const localVarPath = `/admin/validate-trial-session-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminValidateUpsertReservation: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminValidateUpsertReservation', 'requestBody', requestBody)
            const localVarPath = `/admin/reservations/validate-upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVenueSelects: async (educationProviderId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationProviderId' is not null or undefined
            assertParamExists('adminVenueSelects', 'educationProviderId', educationProviderId)
            const localVarPath = `/admin/select/venues/{educationProviderId}`
                .replace(`{${"educationProviderId"}}`, encodeURIComponent(String(educationProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVenuesSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/venues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVoidInvoice: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminVoidInvoice', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/void-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminWithdrawStudentFromSubscription: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminWithdrawStudentFromSubscription', 'requestBody', requestBody)
            const localVarPath = `/admin/subscriptions/withdraw-students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminWithdrawTrialStudent: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminWithdrawTrialStudent', 'requestBody', requestBody)
            const localVarPath = `/admin/withdraw-trial-student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveInvoice: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('approveInvoice', 'requestBody', requestBody)
            const localVarPath = `/admin/invoices/approve-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoUpdateCourseAndCourseInstanceStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/courses/auto-update-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkOverlapSession: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('checkOverlapSession', 'requestBody', requestBody)
            const localVarPath = `/admin/check-overlap-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourseSeries: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCourseSeries', 'id', id)
            const localVarPath = `/admin/courses/course-series/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} scheduledDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollStudentsNextCourseInstance: async (scheduledDate: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledDate' is not null or undefined
            assertParamExists('enrollStudentsNextCourseInstance', 'scheduledDate', scheduledDate)
            const localVarPath = `/admin/enroll-students-next-course-instance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (scheduledDate !== undefined) {
                localVarQueryParameter['scheduled-date'] = scheduledDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCourseReferenceID: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/generate/course-reference-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminOptionsByProviderId: async (providerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getAdminOptionsByProviderId', 'providerId', providerId)
            const localVarPath = `/admin/select/admin-providers/{providerId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckinLogListBeforeUncheckin: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getCheckinLogListBeforeUncheckin', 'requestBody', requestBody)
            const localVarPath = `/admin/schedule/checkin-log-before-uncheckin-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSeriesByCourse: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCourseSeriesByCourse', 'courseId', courseId)
            const localVarPath = `/admin/courses/{courseId}/course-series`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSeriesDetails: async (seriesId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('getCourseSeriesDetails', 'seriesId', seriesId)
            const localVarPath = `/admin/courses/course-series/{seriesId}`
                .replace(`{${"seriesId"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExtraRoomsBySessionId: async (courseSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseSessionId' is not null or undefined
            assertParamExists('getExtraRoomsBySessionId', 'courseSessionId', courseSessionId)
            const localVarPath = `/admin/get-extra-rooms/{courseSessionId}`
                .replace(`{${"courseSessionId"}}`, encodeURIComponent(String(courseSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHolidaysBySession: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getHolidaysBySession', 'requestBody', requestBody)
            const localVarPath = `/admin/get-holidays-by-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageAuditHistories: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMessageAuditHistories', 'id', id)
            const localVarPath = `/admin/messages/{id}/audit-histories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentListForImpersonation: async (search?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/impersonate/parents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentListView: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getParentListView', 'requestBody', requestBody)
            const localVarPath = `/admin/parent-list-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReasonList: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getReasonList', 'requestBody', requestBody)
            const localVarPath = `/admin/reasons/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationLog: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRegistrationLog', 'id', id)
            const localVarPath = `/admin/registration-log/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReplacementConfig: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('getReplacementConfig', 'referenceId', referenceId)
            const localVarPath = `/admin/replacement-config/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReplacementSessionAvailable: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getReplacementSessionAvailable', 'requestBody', requestBody)
            const localVarPath = `/admin/replacement-session-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleJobs: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/schedule-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionNumberById: async (instanceId: string, sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('getSessionNumberById', 'instanceId', instanceId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('getSessionNumberById', 'sessionId', sessionId)
            const localVarPath = `/admin/session-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (instanceId !== undefined) {
                localVarQueryParameter['instanceId'] = instanceId;
            }

            if (sessionId !== undefined) {
                localVarQueryParameter['sessionId'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentsSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/select/students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherMOEsByTeacher: async (teacherId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('getTeacherMOEsByTeacher', 'teacherId', teacherId)
            const localVarPath = `/admin/teachers/{teacherId}/qualification-moes`
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} searchKey 
         * @param {string} filterStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersAdmin: async (searchKey: string, filterStatus: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchKey' is not null or undefined
            assertParamExists('getUsersAdmin', 'searchKey', searchKey)
            // verify required parameter 'filterStatus' is not null or undefined
            assertParamExists('getUsersAdmin', 'filterStatus', filterStatus)
            const localVarPath = `/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (searchKey !== undefined) {
                localVarQueryParameter['searchKey'] = searchKey;
            }

            if (filterStatus !== undefined) {
                localVarQueryParameter['filterStatus'] = filterStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEPGEnrolment: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('importEPGEnrolment', 'requestBody', requestBody)
            const localVarPath = `/admin/import-lpg-enrolment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MigrateParentEmergencyContactRequest} migrateParentEmergencyContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateParentEmergencyContact: async (migrateParentEmergencyContactRequest: MigrateParentEmergencyContactRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'migrateParentEmergencyContactRequest' is not null or undefined
            assertParamExists('migrateParentEmergencyContact', 'migrateParentEmergencyContactRequest', migrateParentEmergencyContactRequest)
            const localVarPath = `/admin/migrate-parent-emergency-contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(migrateParentEmergencyContactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminPostCourseSeriesRequest} adminPostCourseSeriesRequest 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCourseSeries: async (adminPostCourseSeriesRequest: AdminPostCourseSeriesRequest, date?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminPostCourseSeriesRequest' is not null or undefined
            assertParamExists('postCourseSeries', 'adminPostCourseSeriesRequest', adminPostCourseSeriesRequest)
            const localVarPath = `/admin/courses/course-series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminPostCourseSeriesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processOldInvoiceVoided: async (inlineObject6: InlineObject6, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject6' is not null or undefined
            assertParamExists('processOldInvoiceVoided', 'inlineObject6', inlineObject6)
            const localVarPath = `/admin/process-old-invoice-voided`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject6, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceLineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundInvoiceLine: async (invoiceLineId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceLineId' is not null or undefined
            assertParamExists('refundInvoiceLine', 'invoiceLineId', invoiceLineId)
            const localVarPath = `/admin/refund/invoice-line/{invoiceLineId}`
                .replace(`{${"invoiceLineId"}}`, encodeURIComponent(String(invoiceLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeReason: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeReason', 'id', id)
            const localVarPath = `/admin/reasons/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPostmarkMessagesDetails: async (limit: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('syncPostmarkMessagesDetails', 'limit', limit)
            const localVarPath = `/admin/sync-postmark-message-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {boolean} [skipValidation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourseSession: async (requestBody: { [key: string]: object; }, skipValidation?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateCourseSession', 'requestBody', requestBody)
            const localVarPath = `/admin/update-course-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (skipValidation !== undefined) {
                localVarQueryParameter['skipValidation'] = skipValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRole: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateUserRole', 'requestBody', requestBody)
            const localVarPath = `/admin/users/change-role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserStatus: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateUserStatus', 'requestBody', requestBody)
            const localVarPath = `/admin/users/change-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertNoteTypes: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('upsertNoteTypes', 'requestBody', requestBody)
            const localVarPath = `/admin/student-notes/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertReason: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('upsertReason', 'requestBody', requestBody)
            const localVarPath = `/admin/reasons/upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertTeacherMOE: async (teacherId: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('upsertTeacherMOE', 'teacherId', teacherId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('upsertTeacherMOE', 'requestBody', requestBody)
            const localVarPath = `/admin/teachers/{teacherId}/qualification-moes`
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertTeacherMOEArea: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('upsertTeacherMOEArea', 'requestBody', requestBody)
            const localVarPath = `/admin/teacher-moe-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRevenueByInvoiceLines(invoiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRevenueByInvoiceLines(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} invoiceReferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStudentCourseSessionDataForSentInvoices(invoiceReferenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStudentCourseSessionDataForSentInvoices(invoiceReferenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} subscriptionLineId 
         * @param {string} invoiceId 
         * @param {string} collectionDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async additionalDataSubscriptionInvoiceLine(subscriptionLineId: string, invoiceId: string, collectionDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.additionalDataSubscriptionInvoiceLine(subscriptionLineId, invoiceId, collectionDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAddCredit(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateCreditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAddCredit(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAddEpAgreement(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAddEpAgreement(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAddSessionPackToCart(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAddSessionPackToCart(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAddTrialSession(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAddTrialSession(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAddTrialSingleSession(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAddTrialSingleSession(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAddVenueRoom(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAddVenueRoom(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApprovalCourseInstance(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApproveCourseInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApprovalCourseInstance(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApproveCourse(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApproveCourse(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAssignEducationProvidersForVenue(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAssignEducationProvidersForVenue(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} classId 
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAssignStudentToClass(courseId: string, classId: string, inlineObject4: InlineObject4, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAssignStudentToClass(courseId, classId, inlineObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AssignStudentToCourseSeriesRequest} assignStudentToCourseSeriesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAssignStudentToCourseSeries(assignStudentToCourseSeriesRequest: AssignStudentToCourseSeriesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAssignStudentToCourseSeries(assignStudentToCourseSeriesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<RoomReserveDto>} roomReserveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBulkCreateReserveRoom(roomReserveDto: Array<RoomReserveDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminBulkCreateReserveRoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBulkCreateReserveRoom(roomReserveDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBulkEnrollOffPlatform(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkEnrolOffPlatformStudentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBulkEnrollOffPlatform(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBusinessUnitsSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdminByProviderOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBusinessUnitsSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseEnrolmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCancelCourseEnrolment(courseEnrolmentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCancelCourseEnrolment(courseEnrolmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCancelCourseInstance(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCancelCourseInstance(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCancelRevenues(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCancelRevenues(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCancelSubscription(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCancelSubscription(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCancelTrialSingleSession(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCancelTrialSingleSession(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoriesSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdminByProviderOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoriesSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {string} educationProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminChangeTeacherToEP(teacherId: string, educationProviderId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminChangeTeacherToEP(teacherId, educationProviderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckExistUserEmail(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckExistUserEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} invoiceReferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckInvoiceValidToDelete(invoiceReferenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckInvoiceValidToDelete(invoiceReferenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckIsAfterSellingCourse(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckIsAfterSellingCourse(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckSomeClassesUsed(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckSomeClassesUsed(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckValidToArchiveDiscount(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckValidToArchiveDiscount(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckinStudents(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckinStudents(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckoutStudents(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckoutStudents(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCopyCourseInstance(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCopyCourseInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCopyCourseInstance(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCountriesSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCountriesSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCourseFormatSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCourseFormatSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [venueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCourseInstancesSelect(venueId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdminByProviderOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCourseInstancesSelect(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCourseTypeSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCourseTypeSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCoursesSelect(includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCoursesSelect(includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateChild(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateChild(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateCourseMedia(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateCourseMediaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateCourseMedia(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} secretKey 
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateInstanceBySeries(secretKey: string, inlineObject3: InlineObject3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateInstanceBySeries(secretKey, inlineObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateMessageCategory(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateOrUpdateMessageCategoryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateMessageCategory(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateNotificationReplacement(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationAttributes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateNotificationReplacement(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateOrUpdateCourseClass(courseId: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateOrUpdateCourseClass(courseId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateOrUpdateCourseMedia(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateOrUpdateCourseMedia(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateOrUpdateInvoiceAttachments(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateOrUpdateInvoiceAttachments(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminCreateOrUpdateMessageRequest} adminCreateOrUpdateMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateOrUpdateMessage(adminCreateOrUpdateMessageRequest: AdminCreateOrUpdateMessageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateOrUpdateMessage(adminCreateOrUpdateMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateOrUpdateParentOrEPInvoice(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateOrUpdateInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateOrUpdateParentOrEPInvoice(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {boolean} [skipValidation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateOrUpdateReservation(requestBody: { [key: string]: object; }, skipValidation?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCourseInstanceReservation | ValidateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateOrUpdateReservation(requestBody, skipValidation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateOrUpdateStudentNoteAttachments(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateOrUpdateStudentNoteAttachments(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateOrUpdateStudentNotes(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUpsertStudentNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateOrUpdateStudentNotes(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateOrUpdateSubscription(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateOrUpdateSubscription(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreatePaidInvoice(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateOrUpdateInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreatePaidInvoice(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateReplacementHistory(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplacementHistoryAttributes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateReplacementHistory(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RescheduleRequest} rescheduleRequest 
         * @param {boolean} [skipValidation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateReschedule(rescheduleRequest: RescheduleRequest, skipValidation?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateReschedule(rescheduleRequest, skipValidation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateSOAOrInvoiceReportData(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSOAOrInvoiceReportDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateSOAOrInvoiceReportData(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateStudentSession(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentCourseSessionAttributes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateStudentSession(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateUser(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPostUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateUser(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateVoucherCampaigns(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateVoucherCampaigns(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeductSessionPackAddition(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeductSessionPackAddition(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteAllCourseSessions(courseInstanceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteAllCourseSessions(courseInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteCourse(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteCourse(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteCourseInstance(courseInstanceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteCourseInstance(courseInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteCourseSession(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteCourseSession(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteCreditReason(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteCreditReason(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteEPReport(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteEPReport(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteEducationProvider(educationProviderId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteEducationProvider(educationProviderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} invoiceReferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteInvoice(invoiceReferenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteInvoice(invoiceReferenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteMessageCategory(categoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteMessageCategory(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refundHistoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteRefundHistory(refundHistoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteRefundHistory(refundHistoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteRoomBooking(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteRoomBooking(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteSessionPack(referenceId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteSessionPack(referenceId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueEpId 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteStatusEpsForVenue(venueEpId: string, inlineObject1: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteStatusEpsForVenue(venueEpId, inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} typeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteStudentNoteType(typeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteStudentNoteType(typeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteStudentNotes(studentNoteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteStudentNotes(studentNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} originalSessionId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteStudentSession(originalSessionId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteStudentSession(originalSessionId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} moeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteTeacherMOE(moeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteTeacherMOE(moeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} moeAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteTeacherMOEArea(moeAreaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteTeacherMOEArea(moeAreaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteVenue(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteVenue(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteVenueRoom(roomId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteVenueRoom(roomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteVoucherCampaigns(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteVoucherCampaigns(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDownloadDBS(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDownloadDBS(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditCourseDescription(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditCourseDescription(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditCreditConfig(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCreditConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditCreditConfig(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditInvoice(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditInvoice(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditNumberOfSession(courseInstanceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditNumberOfSession(courseInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditStatusEpForVenue(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditStatusEpForVenue(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEquipmentSelects(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetEquipmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEquipmentSelects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExportAttendanceReportToSpreadSheets(venueId: string, inlineObject: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExportAttendanceReportToSpreadSheets(venueId, inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtendExpirationDateSessionPackAddition(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtendExpirationDateSessionPackAddition(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {SubscriptionPaymentMethod} paymentMethod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFindSubscriptionPaymentCard(userId: string, paymentMethod: SubscriptionPaymentMethod, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindSubscriptionPaymentCardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFindSubscriptionPaymentCard(userId, paymentMethod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {string} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGenerateAttendanceReport(venueId: string, instanceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratePDFResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGenerateAttendanceReport(venueId, instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<CourseSessionRequest>} courseSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGenerateCourseSessions(courseSessionRequest: Array<CourseSessionRequest>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCourseSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGenerateCourseSessions(courseSessionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGenerateInvoicePdf(reference: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateInvoicePdfResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGenerateInvoicePdf(reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGenerateSingleReport(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateSingleReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGenerateSingleReport(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAgoraCareUsages(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAgoraCareUsagesParentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAgoraCareUsages(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAgreementByCourseId(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCoursesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAgreementByCourseId(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAllEmailTemplates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEmailTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAllEmailTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAllKisiConfigurations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAllKisiConfigurationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAllKisiConfigurations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetApproverSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetApproverSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAttendanceBySession(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAttendanceBySessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAttendanceBySession(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {string} [providerId] 
         * @param {string} [month] 
         * @param {boolean} [onlyShowEPsWithOffPlatformStudent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAttendanceReportsByVenue(venueId: string, providerId?: string, month?: string, onlyShowEPsWithOffPlatformStudent?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAttendanceReportsByVenueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAttendanceReportsByVenue(venueId, providerId, month, onlyShowEPsWithOffPlatformStudent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAvailableCourseInstance(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentsStudentsCoursesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAvailableCourseInstance(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAvailableRooms(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableRoomsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAvailableRooms(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCampaignsParent(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCampaignsParentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCampaignsParent(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCategoryList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCategoryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCategoryList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentId 
         * @param {any} date 
         * @param {string} [venueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCheckinAbleSessionsForStudentAndDateWithSessionPack(studentId: string, date: any, venueId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCheckinAbleSessionsForStudentAndDateWithSessionPackResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCheckinAbleSessionsForStudentAndDateWithSessionPack(studentId, date, venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetChildrenByParentForMessage(parentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentsForMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetChildrenByParentForMessage(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetChildrenInfo(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetChildrenInfo(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetChildrenListToAddStudentCare(parentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentListToAddStudentCareResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetChildrenListToAddStudentCare(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} forObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetContextSensitiveHelps(forObject: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetContextSensitiveHelpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetContextSensitiveHelps(forObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseClassDetail(courseId: string, classId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseClassDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseClassDetail(courseId, classId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseClasses(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseClassDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseClasses(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseClassesForMessage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetClassesForMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseClassesForMessage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseDetails(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCourseDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseDetails(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseEnrolledStudents(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCourseEnrolledStudentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseEnrolledStudents(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseEnrolmentsViewDataV2(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCourseEnrolmentsViewDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseEnrolmentsViewDataV2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseInstanceCancellationReasons(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseInstanceCancellationReasonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseInstanceCancellationReasons(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseInstanceCode(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCourseInstanceCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseInstanceCode(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseInstanceDetails(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCourseInstanceDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseInstanceDetails(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseInstanceFrom(studentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseInstanceRescheduleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseInstanceFrom(studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseInstanceListView(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAllCourseInstanceListViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseInstanceListView(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseInstanceReservations(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetReservedSeatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseInstanceReservations(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseInstanceStartDate(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStartDateForInvoiceLineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseInstanceStartDate(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fromCourseInstanceId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseInstanceTo(fromCourseInstanceId: string, studentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseInstanceRescheduleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseInstanceTo(fromCourseInstanceId, studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseInstanceToAddStudentCare(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCourseInstanceListToAddStudentCareResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseInstanceToAddStudentCare(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseInstancesForCreateMessage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCourseInstancesForMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseInstancesForCreateMessage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseInstancesOfBulkEnrolStudents(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseInstancesOfBulkEnrolStudentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseInstancesOfBulkEnrolStudents(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCoursePeriodSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCoursePeriodSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseSessionDetail(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCourseSessionDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseSessionDetail(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseSessions(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCourseSessionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseSessions(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCourseStudents(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseStudentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCourseStudents(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherReferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCoursesAssignedByTeacherId(teacherReferenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCoursesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCoursesAssignedByTeacherId(teacherReferenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCoursesByProvider(providerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCoursesByProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCoursesByProvider(providerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCoursesList(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCoursesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCoursesList(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCoursesSeriesForAssignStudent(courseId: string, studentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCourseSeriesForAssignStudentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCoursesSeriesForAssignStudent(courseId, studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCreditConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCreditConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCreditConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCreditList(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCreditListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCreditList(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCreditReason(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCreditReasonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCreditReason(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetDailyScheduleData(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetDailyScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetDailyScheduleData(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetDiscountCodes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDiscountCodesCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetDiscountCodes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetDiscountCodesById(campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDiscountCodesByCampaignIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetDiscountCodesById(campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetDiscountList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetDiscountListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetDiscountList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetDiscounts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDiscountListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetDiscounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetDiscountsById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountUpsertResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetDiscountsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetEPReports(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEPReportsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetEPReports(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [venueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetEducationProviderOptions(venueId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEducationProviderOptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetEducationProviderOptions(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} searchKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetEducationProviders(searchKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEducationProvidersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetEducationProviders(searchKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetEducationProvidersById(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEducationProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetEducationProvidersById(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetEducationProvidersByVenueId(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetEpsByVenueIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetEducationProvidersByVenueId(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetEducationProvidersSelect(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetEducationProvidersSelect(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} templateAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetEmailTemplateDetailsWithoutBrand(templateAlias: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAdminGetEmailTemplateDetailsWithoutBrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetEmailTemplateDetailsWithoutBrand(templateAlias, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetEmailTemplates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEmailTemplateOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetEmailTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {Array<string>} [aliasPrefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetEmailTemplatesWithoutBrand(search?: string, aliasPrefix?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAdminGetEmailTemplatesWithoutBrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetEmailTemplatesWithoutBrand(search, aliasPrefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminGetEnrolledInstanceByStudentRequest} adminGetEnrolledInstanceByStudentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetEnrolledInstanceByStudent(adminGetEnrolledInstanceByStudentRequest: AdminGetEnrolledInstanceByStudentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetEnrolledInstanceByStudentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetEnrolledInstanceByStudent(adminGetEnrolledInstanceByStudentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetEnrolledStudents(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetEnrolledStudentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetEnrolledStudents(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetGradeLevelSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetGradeLevelSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetGuardians(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetGuardians(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetInvoiceDraftOrPendingByEpId(educationProviderId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInvoiceDraftOrPendingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetInvoiceDraftOrPendingByEpId(educationProviderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetInvoiceLineList(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetInvoiceLinesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetInvoiceLineList(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetInvoiceListCount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAdminGetInvoiceListCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetInvoiceListCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetInvoiceListRefactor(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAdminGetInvoiceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetInvoiceListRefactor(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [venueId] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetInvoiceProductList(venueId?: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetProductListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetInvoiceProductList(venueId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMailDomains(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetMailDomainsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMailDomains(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMessageCategories(limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCategoryParentMessageListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMessageCategories(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMessageCategoryById(categoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetMessageCategoryByIdResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMessageCategoryById(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MessageNewTypeEnum} [type] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMessages(type?: MessageNewTypeEnum, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetMessagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMessages(type, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [parentId] 
         * @param {string} [studentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMessagesByParent(parentId?: string, studentId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetMessageListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMessagesByParent(parentId, studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetMessagingDetails(messageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetMessageDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetMessagingDetails(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetNextProductNo(sku: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetNextProductNo(sku, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetParentAvailableCredit(parentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBalanceCreditAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetParentAvailableCredit(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetParentByStudentId(studentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetParentByStudentIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetParentByStudentId(studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetParentGuardianByUser(parentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetParentGuardianByUser(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetParentInfo(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetParentInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetParentInfo(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetParentNotes(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetParentNotes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetParentNotes(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} parent 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetParentStudentSchedule(parent: string, start?: string, end?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetParentStudentScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetParentStudentSchedule(parent, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetParentsForCreateMessage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetParentsForMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetParentsForCreateMessage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetParentsStudentsCourses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentsStudentsCoursesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetParentsStudentsCourses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminGetStudentWithPaymentHistoryNewRequest} adminGetStudentWithPaymentHistoryNewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPaymentHistoriesByCourseId(adminGetStudentWithPaymentHistoryNewRequest: AdminGetStudentWithPaymentHistoryNewRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentWithPaymentHistoryNewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPaymentHistoriesByCourseId(adminGetStudentWithPaymentHistoryNewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPermissions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetPermissionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPermissions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPresignedPutURL(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPresignedPutURLResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPresignedPutURL(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetProductBySKU(sku: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetProductBySKUResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetProductBySKU(sku, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetProductList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetProductBySKUResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetProductList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetProviderSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetProviderSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetQuickSightAgoraAtSchoolReportsUrl(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuickSightEmbedUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetQuickSightAgoraAtSchoolReportsUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetQuickSightAgoraEpMarketplaceReportsUrl(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuickSightEmbedUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetQuickSightAgoraEpMarketplaceReportsUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetQuickSightAgoraStudentCareReportUrl(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuickSightEmbedUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetQuickSightAgoraStudentCareReportUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetQuickSightEarlyYearsReportUrl(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuickSightEmbedUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetQuickSightEarlyYearsReportUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetQuickSightEmbedUrl(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuickSightEmbedUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetQuickSightEmbedUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetQuickSightFinanceUpdateReportsUrl(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuickSightEmbedUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetQuickSightFinanceUpdateReportsUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetQuickSightSharedOpsReportsUrl(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuickSightEmbedUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetQuickSightSharedOpsReportsUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRateCards(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetRateCardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRateCards(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRefundConfigs(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetRefundConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRefundConfigs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRefundParent(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetRefundParentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRefundParent(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRegistrations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetRegistrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRegistrations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetReplacementConfiguration(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetReplacementConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetReplacementConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetReplacementHistory(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetReplacementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetReplacementHistory(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetReservedData(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetReservedDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetReservedData(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetReservedStartDate(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStartDateForInvoiceLineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetReservedStartDate(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetReservedTrialSingleSession(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetTrialCourseInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetReservedTrialSingleSession(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRevenueRefund(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetRevenueRefundResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRevenueRefund(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRolePermissions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetRolesPermissionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRolePermissions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRoles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRoomBookingInvoiceDetail(reference: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomBookingInvoiceDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRoomBookingInvoiceDetail(reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} startDate 
         * @param {any} endDate 
         * @param {string} searchKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRoomCalendar(startDate: any, endDate: any, searchKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetRoomCalendarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRoomCalendar(startDate, endDate, searchKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} roomBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRoomCheckInMedia(roomBookingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetRoomCheckInMediaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRoomCheckInMedia(roomBookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRoomCheckInReport(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetRoomCheckInReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRoomCheckInReport(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRooms(providerId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetVenueRoomsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRooms(providerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSchoolSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSchoolForChildResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSchoolSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSchools(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSchoolRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSchools(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSchoolsSelect(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSchoolsSelect(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSentEmailsByUserId(referenceId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSentEmailsByUserIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSentEmailsByUserId(referenceId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [date] 
         * @param {string} [venueId] 
         * @param {Array<string>} [courseInstanceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSessionByDate(date?: any, venueId?: string, courseInstanceIds?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSessionsByDateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSessionByDate(date, venueId, courseInstanceIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSessionPackHistoryByCourseId(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSessionPackAdditionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSessionPackHistoryByCourseId(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSessionPackStartDate(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStartDateForInvoiceLineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSessionPackStartDate(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSessionPacksByCourseId(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSessionPackResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSessionPacksByCourseId(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSessionPacksByParentId(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSessionPackAdditionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSessionPacksByParentId(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSessionPacksSelect(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSessionPackResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSessionPacksSelect(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSessionPacksUsageBySessionPackAdditionId(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSessionPackUsageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSessionPacksUsageBySessionPackAdditionId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentId 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSessionStudentNotAttending(studentId: string, courseInstanceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSessionStudentNotAttendingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSessionStudentNotAttending(studentId, courseInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSessionsByCourse(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSessionsByCourseIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSessionsByCourse(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSiblingToUseSessionPack(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSiblingCheckinResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSiblingToUseSessionPack(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetStudentCourseInstancesLevel(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentCourseInstancesLevelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetStudentCourseInstancesLevel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentRefernceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetStudentCourses(studentRefernceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentCoursesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetStudentCourses(studentRefernceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetStudentCoursesLevel(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentCoursesLevelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetStudentCoursesLevel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetStudentForUploadBirthdayCertificate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChildrenListForRegistrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetStudentForUploadBirthdayCertificate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetStudentInfo(studentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetStudentInfo(studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetStudentNoteTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentNoteTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetStudentNoteTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [studentId] 
         * @param {string} [parentId] 
         * @param {string} [courseInstanceId] 
         * @param {string} [courseSessionId] 
         * @param {boolean} [showOnCheckin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetStudentNotes(studentId?: string, parentId?: string, courseInstanceId?: string, courseSessionId?: string, showOnCheckin?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentNotesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetStudentNotes(studentId, parentId, courseInstanceId, courseSessionId, showOnCheckin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetStudentReservedCourseInstances(studentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentReservedCourseInstancesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetStudentReservedCourseInstances(studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetStudentsCanReserve(courseInstanceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentsCanReserveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetStudentsCanReserve(courseInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetStudentsCanTrialSession(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentCanTrialSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetStudentsCanTrialSession(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetStudentsCheckIn(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentCheckInCheckOutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetStudentsCheckIn(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetStudentsCheckOut(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentCheckInCheckOutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetStudentsCheckOut(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetStudentsWithBookings(limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentsStudentsCoursesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetStudentsWithBookings(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetStudentsWithoutBookings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentsStudentsCoursesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetStudentsWithoutBookings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSubjectAreaSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSubjectAreaSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSubjectSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSubjectSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSubscriptionById(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSubscriptionByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSubscriptionById(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSubscriptionList(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSubscriptionListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSubscriptionList(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTags(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetTagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTeacherMOEAreas(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetTeacherMOEAreasResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTeacherMOEAreas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} moeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTeacherMOEsByMOE(moeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTeacherMOEDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTeacherMOEsByMOE(moeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTeachers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetTeachersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTeachers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {boolean} [includeAdminUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTeachersByEp(educationProviderId: string, includeAdminUsers?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAdminGetTeacherResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTeachersByEp(educationProviderId, includeAdminUsers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTeachersById(teacherId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTeacherDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTeachersById(teacherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} epReferenceId 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTeamByEpId(epReferenceId: string, search: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetTeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTeamByEpId(epReferenceId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {string} teacherId 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTeamByEpTeacherId(educationProviderId: string, teacherId: string, search: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetTeamDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTeamByEpTeacherId(educationProviderId, teacherId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} emailParent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTicketParent(emailParent: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetTicketParentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTicketParent(emailParent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminGetTotalParentsRequest} adminGetTotalParentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTotalParentsForConfirmMessage(adminGetTotalParentsRequest: AdminGetTotalParentsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetParentsForMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTotalParentsForConfirmMessage(adminGetTotalParentsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTransactionParent(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetTransactionsParentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTransactionParent(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} financialTransactionsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTransactionRefund(financialTransactionsId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetTransactionRefundedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTransactionRefund(financialTransactionsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {string} studentSessionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTrialCourseInstances(referenceId: string, studentSessionType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetTrialCourseInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTrialCourseInstances(referenceId, studentSessionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetTrialSessionsOfStudent(studentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetTrialSessionsOfStudentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetTrialSessionsOfStudent(studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetVenueRoomDetail(roomId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTeacherGetVenueRoomDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetVenueRoomDetail(roomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetVenueRooms(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetVenueRoomsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetVenueRooms(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetVenues(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetVenueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetVenues(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetVenuesById(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetVenuesById(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetVenuesForCreateMessageType} [venueType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetVenuesForCreateMessage(venueType?: GetVenuesForCreateMessageType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetVenuesForMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetVenuesForCreateMessage(venueType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetVoucherCampaignHistories(campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetVoucherHistoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetVoucherCampaignHistories(campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetVoucherCampaigns(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetVoucherCampaignsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetVoucherCampaigns(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetVoucherRecords(campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetVoucherRecordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetVoucherRecords(campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGstRatesSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdminByProviderOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGstRatesSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminHandleAfterConfirmSeatsCopyCourseInstanceRequest} adminHandleAfterConfirmSeatsCopyCourseInstanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminHandleAfterConfirmSeatsCopyCourseInstance(adminHandleAfterConfirmSeatsCopyCourseInstanceRequest: AdminHandleAfterConfirmSeatsCopyCourseInstanceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminHandleAfterConfirmSeatsCopyCourseInstance(adminHandleAfterConfirmSeatsCopyCourseInstanceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminImportPlayschoolCheckinData(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminImportPlayschoolCheckinData(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminInvoiceAmountLeftToPay(reference: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminInvoiceAmountLeftToPay(reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminIsAfterSellingInstance(courseInstanceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminIsAfterSellingInstance(courseInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMarkAttendingSession(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminMarkAttendingSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMarkAttendingSession(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMergeParentsAndStudents(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMergeParentsAndStudents(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminMigrateExistingStudentRequest} adminMigrateExistingStudentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMigrateExistingStudent(adminMigrateExistingStudentRequest: AdminMigrateExistingStudentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMigrateExistingStudent(adminMigrateExistingStudentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminMigrateExistingStudentCareStudentsRequest} adminMigrateExistingStudentCareStudentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMigrateExistingStudentCareStudents(adminMigrateExistingStudentCareStudentsRequest: AdminMigrateExistingStudentCareStudentsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMigrateExistingStudentCareStudents(adminMigrateExistingStudentCareStudentsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userReferenceId 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMixpanelGetUserActivityFeed(userReferenceId: string, fromDate: string, toDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserActivityFeedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMixpanelGetUserActivityFeed(userReferenceId, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPinStudentNote(studentNoteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPinStudentNote(studentNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPostCourse(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPostCourseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPostCourse(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPostParentNotes(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPostParentNotes(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPostUserSaveCard(inlineObject2: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSaveCardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPostUserSaveCard(inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPrepareOldInvoiceData(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateOrUpdateInvoiceRequest | string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPrepareOldInvoiceData(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPreparePlayschoolCheckinData(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrepareCheckinPlayschoolResponse | string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPreparePlayschoolCheckinData(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPutSubscriptionLines(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPutSubscriptionLines(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReActiveSubscription(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReActiveSubscription(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReGenerateRoomBookingInvoice(inlineObject5: InlineObject5, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReGenerateRoomBookingInvoice(inlineObject5, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {boolean} [isNotSendMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRecordPayment(requestBody: { [key: string]: object; }, isNotSendMail?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRecordPayment(requestBody, isNotSendMail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {boolean} [isNotSendMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRefundCourseSessions(requestBody: { [key: string]: object; }, isNotSendMail?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRefundCourseSessions(requestBody, isNotSendMail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRemoveBulkEnrol(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRemoveBulkEnrol(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRemoveCourseMedia(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRemoveCourseMedia(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRemoveRoomBookingInvoiceLine(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRemoveRoomBookingInvoiceLine(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminResendPassword(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminResendPassword(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} roomId 
         * @param {string} courseSessionId 
         * @param {string} [sessionGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRoomCalendar(roomId: string, courseSessionId: string, sessionGroup?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetMoreDataRoomViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRoomCalendar(roomId, courseSessionId, sessionGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [venueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRoomTypeSelects(venueId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRoomTypeSelects(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<AdminSendReservationLinksRequest>} adminSendReservationLinksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSendBookingLinks(adminSendReservationLinksRequest: Array<AdminSendReservationLinksRequest>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSendBookingLinks(adminSendReservationLinksRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSendEpAgreement(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSendEpAgreement(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetParentCourseReminderEmailDataWithConditionRequest} getParentCourseReminderEmailDataWithConditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSendMailParentCourseReminderWithCondition(getParentCourseReminderEmailDataWithConditionRequest: GetParentCourseReminderEmailDataWithConditionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSendMailParentCourseReminderWithCondition(getParentCourseReminderEmailDataWithConditionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSendNotifyReplacementEmail(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSendNotifyReplacementEmail(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSendOrResendLoginInviteToParent(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSendOrResendLoginInviteToParent(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSendReplacementEmailAndNotiToEpTeacher(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSendReplacementEmailAndNotiToEpTeacher(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSendRoomBookingInvoiceMail(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSendInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSendRoomBookingInvoiceMail(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSendSubscriptionMail(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSendSubscriptionMail(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSendWithdrawTrialStudentEmail(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSendWithdrawTrialStudentEmail(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSubmitForApprovalCourse(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSubmitForApprovalCourse(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSubmitForApprovalCourseInstance(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApproveCourseInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSubmitForApprovalCourseInstance(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSubmitForApprovalInvoice(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSubmitForApprovalInvoice(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTransferSessionPackAddition(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTransferSessionPackAddition(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUnCheckinStudent(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUnCheckinStudent(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUnPinStudentNote(studentNoteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUnPinStudentNote(studentNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBirthdayCertificateRequest} updateBirthdayCertificateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateBirthdayCertificate(updateBirthdayCertificateRequest: UpdateBirthdayCertificateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateBirthdayCertificate(updateBirthdayCertificateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateBulkEnrollOffPlatform(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateBulkEnrollOffPlatform(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateChild(id: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateChild(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateCourseMetaData(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateCourseMetaData(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateInvoicesOverdue(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateInvoicesOverdue(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateIsShareParentContact(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateIsShareParentContact(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateMessageCategory(categoryId: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateOrUpdateMessageCategoryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateMessageCategory(categoryId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateOriginalSession(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateOriginalSession(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateReservationStatus(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateReservationStatus(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateRevenueCaseWithoutSelectedRefundSession(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateRevenueCaseWithoutSelectedRefundSession(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpsertContextSensitiveHelp(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpsertContextSensitiveHelp(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpsertCourseInstance(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUpsertCourseInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpsertCourseInstance(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpsertCreditReason(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUpsertCreditReasonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpsertCreditReason(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpsertDiscount(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountUpsertResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpsertDiscount(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpsertDiscountCodes(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertDiscountCodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpsertDiscountCodes(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpsertEducationProvider(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUpsertEducationProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpsertEducationProvider(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpsertKisiConfiguration(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpsertKisiConfiguration(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} educationProviderReferenceId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpsertMember(educationProviderReferenceId: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUpsertMemberResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpsertMember(educationProviderReferenceId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpsertParent(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUpsertParentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpsertParent(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpsertProduct(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpsertProduct(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpsertRolePermission(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpsertRolePermission(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpsertSessionPack(referenceId: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUpsertSessionPackResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpsertSessionPack(referenceId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpsertVenues(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpsertVenues(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminValidateBeforeCancelCourseInstance(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminValidateBeforeCancelCourseInstance(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminValidateChangeTeacher(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminValidateChangeTeacher(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminValidateClassUsed(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminValidateClassUsed(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<ValidateCourseSessionRequest>} validateCourseSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminValidateCourseSessions(validateCourseSessionRequest: Array<ValidateCourseSessionRequest>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ValidateCourseSessionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminValidateCourseSessions(validateCourseSessionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminValidateStudentIsSignedUpCourseSeries(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminValidateStudentIsSignedUpCourseSeries(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminValidateTrialSessionRequest(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminValidateTrialSessionRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminValidateTrialSessionRequest(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminValidateUpsertReservation(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminValidateUpsertReservation(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVenueSelects(educationProviderId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVenueSelects(educationProviderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVenuesSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVenuesSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVoidInvoice(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVoidInvoice(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminWithdrawStudentFromSubscription(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawStudentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminWithdrawStudentFromSubscription(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminWithdrawTrialStudent(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminWithdrawTrialStudent(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveInvoice(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveInvoice(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoUpdateCourseAndCourseInstanceStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoUpdateCourseAndCourseInstanceStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkOverlapSession(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCheckOverlapResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkOverlapSession(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCourseSeries(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCourseSeries(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} scheduledDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enrollStudentsNextCourseInstance(scheduledDate: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enrollStudentsNextCourseInstance(scheduledDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCourseReferenceID(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCourseReferenceID(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminOptionsByProviderId(providerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdminByProviderOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminOptionsByProviderId(providerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCheckinLogListBeforeUncheckin(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCheckinLogListBeforeUncheckinResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCheckinLogListBeforeUncheckin(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseSeriesByCourse(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCourseSeriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseSeriesByCourse(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseSeriesDetails(seriesId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCourseSeriesDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseSeriesDetails(seriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExtraRoomsBySessionId(courseSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetExtraRoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExtraRoomsBySessionId(courseSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilterUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetFilterUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilterUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHolidaysBySession(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHolidaysBySession(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageAuditHistories(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAuditHistoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageAuditHistories(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentListForImpersonation(search?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetParentListForImpersonationResponseInterface>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentListForImpersonation(search, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentListView(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetParentListViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentListView(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReasonList(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReasonListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReasonList(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistrationLog(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetRegistrationLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistrationLog(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReplacementConfig(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplacementConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReplacementConfig(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReplacementSessionAvailable(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetReplacementSessionAvailableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReplacementSessionAvailable(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduleJobs(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetScheduleJobsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduleJobs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessionNumberById(instanceId: string, sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessionNumberById(instanceId, sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentsSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentsCanReserveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentsSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeacherMOEsByTeacher(teacherId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetTeacherMOEsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeacherMOEsByTeacher(teacherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} searchKey 
         * @param {string} filterStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersAdmin(searchKey: string, filterStatus: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersAdmin(searchKey, filterStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importEPGEnrolment(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importEPGEnrolment(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MigrateParentEmergencyContactRequest} migrateParentEmergencyContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateParentEmergencyContact(migrateParentEmergencyContactRequest: MigrateParentEmergencyContactRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateParentEmergencyContact(migrateParentEmergencyContactRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminPostCourseSeriesRequest} adminPostCourseSeriesRequest 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCourseSeries(adminPostCourseSeriesRequest: AdminPostCourseSeriesRequest, date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPostCourseSeriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCourseSeries(adminPostCourseSeriesRequest, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processOldInvoiceVoided(inlineObject6: InlineObject6, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processOldInvoiceVoided(inlineObject6, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} invoiceLineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundInvoiceLine(invoiceLineId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundInvoiceLine(invoiceLineId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeReason(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeReason(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncPostmarkMessagesDetails(limit: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncPostmarkMessagesDetails(limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {boolean} [skipValidation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCourseSession(requestBody: { [key: string]: object; }, skipValidation?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCourseSession(requestBody, skipValidation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserRole(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminChangeUserRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserRole(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserStatus(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminChangeUserStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserStatus(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertNoteTypes(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertNoteTypes(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertReason(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertReason(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertTeacherMOE(teacherId: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertTeacherMOE(teacherId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertTeacherMOEArea(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertTeacherMOEArea(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRevenueByInvoiceLines(invoiceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.addRevenueByInvoiceLines(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invoiceReferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStudentCourseSessionDataForSentInvoices(invoiceReferenceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.addStudentCourseSessionDataForSentInvoices(invoiceReferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriptionLineId 
         * @param {string} invoiceId 
         * @param {string} collectionDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalDataSubscriptionInvoiceLine(subscriptionLineId: string, invoiceId: string, collectionDate: string, options?: any): AxiosPromise<void> {
            return localVarFp.additionalDataSubscriptionInvoiceLine(subscriptionLineId, invoiceId, collectionDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddCredit(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminCreateCreditResponse> {
            return localVarFp.adminAddCredit(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddEpAgreement(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminAddEpAgreement(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddSessionPackToCart(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminAddSessionPackToCart(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddTrialSession(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminAddTrialSession(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddTrialSingleSession(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminAddTrialSingleSession(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddVenueRoom(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminAddVenueRoom(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApprovalCourseInstance(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<ApproveCourseInstanceResponse> {
            return localVarFp.adminApprovalCourseInstance(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApproveCourse(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminApproveCourse(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAssignEducationProvidersForVenue(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminAssignEducationProvidersForVenue(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} classId 
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAssignStudentToClass(courseId: string, classId: string, inlineObject4: InlineObject4, options?: any): AxiosPromise<void> {
            return localVarFp.adminAssignStudentToClass(courseId, classId, inlineObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssignStudentToCourseSeriesRequest} assignStudentToCourseSeriesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAssignStudentToCourseSeries(assignStudentToCourseSeriesRequest: AssignStudentToCourseSeriesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminAssignStudentToCourseSeries(assignStudentToCourseSeriesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<RoomReserveDto>} roomReserveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBulkCreateReserveRoom(roomReserveDto: Array<RoomReserveDto>, options?: any): AxiosPromise<AdminBulkCreateReserveRoomResponse> {
            return localVarFp.adminBulkCreateReserveRoom(roomReserveDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBulkEnrollOffPlatform(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<BulkEnrolOffPlatformStudentsResponse> {
            return localVarFp.adminBulkEnrollOffPlatform(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBusinessUnitsSelect(options?: any): AxiosPromise<GetAdminByProviderOptionResponse> {
            return localVarFp.adminBusinessUnitsSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseEnrolmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCancelCourseEnrolment(courseEnrolmentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminCancelCourseEnrolment(courseEnrolmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCancelCourseInstance(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminCancelCourseInstance(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCancelRevenues(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminCancelRevenues(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCancelSubscription(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminCancelSubscription(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCancelTrialSingleSession(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminCancelTrialSingleSession(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesSelect(options?: any): AxiosPromise<GetAdminByProviderOptionResponse> {
            return localVarFp.adminCategoriesSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {string} educationProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminChangeTeacherToEP(teacherId: string, educationProviderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminChangeTeacherToEP(teacherId, educationProviderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckExistUserEmail(email: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminCheckExistUserEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invoiceReferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckInvoiceValidToDelete(invoiceReferenceId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminCheckInvoiceValidToDelete(invoiceReferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckIsAfterSellingCourse(courseId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminCheckIsAfterSellingCourse(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckSomeClassesUsed(venueId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminCheckSomeClassesUsed(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckValidToArchiveDiscount(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminCheckValidToArchiveDiscount(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckinStudents(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminCheckinStudents(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckoutStudents(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminCheckoutStudents(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCopyCourseInstance(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminCopyCourseInstanceResponse> {
            return localVarFp.adminCopyCourseInstance(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountriesSelect(options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminCountriesSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseFormatSelect(options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminCourseFormatSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [venueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseInstancesSelect(venueId?: string, options?: any): AxiosPromise<GetAdminByProviderOptionResponse> {
            return localVarFp.adminCourseInstancesSelect(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseTypeSelect(options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminCourseTypeSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesSelect(includeInactive?: boolean, options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminCoursesSelect(includeInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateChild(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminCreateChild(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseMedia(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminCreateCourseMediaResponse> {
            return localVarFp.adminCreateCourseMedia(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} secretKey 
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateInstanceBySeries(secretKey: string, inlineObject3: InlineObject3, options?: any): AxiosPromise<void> {
            return localVarFp.adminCreateInstanceBySeries(secretKey, inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateMessageCategory(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminCreateOrUpdateMessageCategoryResponseDto> {
            return localVarFp.adminCreateMessageCategory(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateNotificationReplacement(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<NotificationAttributes> {
            return localVarFp.adminCreateNotificationReplacement(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateCourseClass(courseId: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminCreateOrUpdateCourseClass(courseId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateCourseMedia(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminCreateOrUpdateCourseMedia(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateInvoiceAttachments(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminCreateOrUpdateInvoiceAttachments(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminCreateOrUpdateMessageRequest} adminCreateOrUpdateMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateMessage(adminCreateOrUpdateMessageRequest: AdminCreateOrUpdateMessageRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateOrUpdateMessage(adminCreateOrUpdateMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateParentOrEPInvoice(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminCreateOrUpdateInvoiceResponse> {
            return localVarFp.adminCreateOrUpdateParentOrEPInvoice(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {boolean} [skipValidation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateReservation(requestBody: { [key: string]: object; }, skipValidation?: boolean, options?: any): AxiosPromise<AdminGetCourseInstanceReservation | ValidateDto> {
            return localVarFp.adminCreateOrUpdateReservation(requestBody, skipValidation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateStudentNoteAttachments(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminCreateOrUpdateStudentNoteAttachments(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateStudentNotes(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminUpsertStudentNoteResponse> {
            return localVarFp.adminCreateOrUpdateStudentNotes(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOrUpdateSubscription(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<string> {
            return localVarFp.adminCreateOrUpdateSubscription(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreatePaidInvoice(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminCreateOrUpdateInvoiceResponse> {
            return localVarFp.adminCreatePaidInvoice(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateReplacementHistory(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<ReplacementHistoryAttributes> {
            return localVarFp.adminCreateReplacementHistory(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RescheduleRequest} rescheduleRequest 
         * @param {boolean} [skipValidation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateReschedule(rescheduleRequest: RescheduleRequest, skipValidation?: boolean, options?: any): AxiosPromise<ValidateDto> {
            return localVarFp.adminCreateReschedule(rescheduleRequest, skipValidation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateSOAOrInvoiceReportData(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<CreateSOAOrInvoiceReportDataResponse> {
            return localVarFp.adminCreateSOAOrInvoiceReportData(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateStudentSession(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<StudentCourseSessionAttributes> {
            return localVarFp.adminCreateStudentSession(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateUser(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminPostUserResponse> {
            return localVarFp.adminCreateUser(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateVoucherCampaigns(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminCreateVoucherCampaigns(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeductSessionPackAddition(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeductSessionPackAddition(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteAllCourseSessions(courseInstanceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteAllCourseSessions(courseInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourse(referenceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteCourse(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseInstance(courseInstanceId: string, options?: any): AxiosPromise<ValidateDto> {
            return localVarFp.adminDeleteCourseInstance(courseInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseSession(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteCourseSession(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCreditReason(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteCreditReason(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteEPReport(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteEPReport(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteEducationProvider(educationProviderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteEducationProvider(educationProviderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invoiceReferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteInvoice(invoiceReferenceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteInvoice(invoiceReferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteMessageCategory(categoryId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminDeleteMessageCategory(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} refundHistoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteRefundHistory(refundHistoryId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteRefundHistory(refundHistoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteRoomBooking(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteRoomBooking(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteSessionPack(referenceId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteSessionPack(referenceId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueEpId 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteStatusEpsForVenue(venueEpId: string, inlineObject1: InlineObject1, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteStatusEpsForVenue(venueEpId, inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} typeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteStudentNoteType(typeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteStudentNoteType(typeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteStudentNotes(studentNoteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteStudentNotes(studentNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} originalSessionId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteStudentSession(originalSessionId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteStudentSession(originalSessionId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} moeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteTeacherMOE(moeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteTeacherMOE(moeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} moeAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteTeacherMOEArea(moeAreaId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteTeacherMOEArea(moeAreaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteVenue(venueId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteVenue(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteVenueRoom(roomId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteVenueRoom(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteVoucherCampaigns(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminDeleteVoucherCampaigns(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDownloadDBS(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.adminDownloadDBS(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditCourseDescription(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminEditCourseDescription(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditCreditConfig(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetCreditConfigResponse> {
            return localVarFp.adminEditCreditConfig(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditInvoice(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<string> {
            return localVarFp.adminEditInvoice(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditNumberOfSession(courseInstanceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminEditNumberOfSession(courseInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditStatusEpForVenue(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminEditStatusEpForVenue(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEquipmentSelects(options?: any): AxiosPromise<AdminGetEquipmentResponse> {
            return localVarFp.adminEquipmentSelects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExportAttendanceReportToSpreadSheets(venueId: string, inlineObject: InlineObject, options?: any): AxiosPromise<string> {
            return localVarFp.adminExportAttendanceReportToSpreadSheets(venueId, inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtendExpirationDateSessionPackAddition(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminExtendExpirationDateSessionPackAddition(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {SubscriptionPaymentMethod} paymentMethod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFindSubscriptionPaymentCard(userId: string, paymentMethod: SubscriptionPaymentMethod, options?: any): AxiosPromise<FindSubscriptionPaymentCardResponse> {
            return localVarFp.adminFindSubscriptionPaymentCard(userId, paymentMethod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {string} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGenerateAttendanceReport(venueId: string, instanceId: string, options?: any): AxiosPromise<GeneratePDFResponse> {
            return localVarFp.adminGenerateAttendanceReport(venueId, instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CourseSessionRequest>} courseSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGenerateCourseSessions(courseSessionRequest: Array<CourseSessionRequest>, options?: any): AxiosPromise<CreateCourseSessionResponse> {
            return localVarFp.adminGenerateCourseSessions(courseSessionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGenerateInvoicePdf(reference: string, options?: any): AxiosPromise<GenerateInvoicePdfResponse> {
            return localVarFp.adminGenerateInvoicePdf(reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGenerateSingleReport(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GenerateSingleReportResponse> {
            return localVarFp.adminGenerateSingleReport(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAgoraCareUsages(referenceId: string, options?: any): AxiosPromise<AdminGetAgoraCareUsagesParentResponse> {
            return localVarFp.adminGetAgoraCareUsages(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAgreementByCourseId(courseId: string, options?: any): AxiosPromise<AdminGetCoursesResponse> {
            return localVarFp.adminGetAgreementByCourseId(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAllEmailTemplates(options?: any): AxiosPromise<GetEmailTemplateResponse> {
            return localVarFp.adminGetAllEmailTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAllKisiConfigurations(options?: any): AxiosPromise<AdminGetAllKisiConfigurationsResponse> {
            return localVarFp.adminGetAllKisiConfigurations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetApproverSelect(options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminGetApproverSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAttendanceBySession(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetAttendanceBySessionResponse> {
            return localVarFp.adminGetAttendanceBySession(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {string} [providerId] 
         * @param {string} [month] 
         * @param {boolean} [onlyShowEPsWithOffPlatformStudent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAttendanceReportsByVenue(venueId: string, providerId?: string, month?: string, onlyShowEPsWithOffPlatformStudent?: boolean, options?: any): AxiosPromise<AdminGetAttendanceReportsByVenueResponse> {
            return localVarFp.adminGetAttendanceReportsByVenue(venueId, providerId, month, onlyShowEPsWithOffPlatformStudent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAvailableCourseInstance(options?: any): AxiosPromise<ParentsStudentsCoursesResponse> {
            return localVarFp.adminGetAvailableCourseInstance(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAvailableRooms(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<Array<AvailableRoomsResponse>> {
            return localVarFp.adminGetAvailableRooms(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCampaignsParent(referenceId: string, options?: any): AxiosPromise<AdminGetCampaignsParentResponse> {
            return localVarFp.adminGetCampaignsParent(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCategoryList(options?: any): AxiosPromise<AdminGetCategoryListResponse> {
            return localVarFp.adminGetCategoryList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentId 
         * @param {any} date 
         * @param {string} [venueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCheckinAbleSessionsForStudentAndDateWithSessionPack(studentId: string, date: any, venueId?: string, options?: any): AxiosPromise<GetCheckinAbleSessionsForStudentAndDateWithSessionPackResponse> {
            return localVarFp.adminGetCheckinAbleSessionsForStudentAndDateWithSessionPack(studentId, date, venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetChildrenByParentForMessage(parentId: string, options?: any): AxiosPromise<AdminGetStudentsForMessageResponse> {
            return localVarFp.adminGetChildrenByParentForMessage(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetChildrenInfo(referenceId: string, options?: any): AxiosPromise<GetUserInfoResponse> {
            return localVarFp.adminGetChildrenInfo(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetChildrenListToAddStudentCare(parentId: string, options?: any): AxiosPromise<AdminGetStudentListToAddStudentCareResponse> {
            return localVarFp.adminGetChildrenListToAddStudentCare(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} forObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContextSensitiveHelps(forObject: string, options?: any): AxiosPromise<GetContextSensitiveHelpResponse> {
            return localVarFp.adminGetContextSensitiveHelps(forObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseClassDetail(courseId: string, classId: string, options?: any): AxiosPromise<CourseClassDto> {
            return localVarFp.adminGetCourseClassDetail(courseId, classId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseClasses(courseId: string, options?: any): AxiosPromise<Array<CourseClassDto>> {
            return localVarFp.adminGetCourseClasses(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseClassesForMessage(options?: any): AxiosPromise<AdminGetClassesForMessageResponse> {
            return localVarFp.adminGetCourseClassesForMessage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseDetails(referenceId: string, options?: any): AxiosPromise<AdminGetCourseDetailResponse> {
            return localVarFp.adminGetCourseDetails(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseEnrolledStudents(courseId: string, options?: any): AxiosPromise<AdminGetCourseEnrolledStudentsResponse> {
            return localVarFp.adminGetCourseEnrolledStudents(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseEnrolmentsViewDataV2(options?: any): AxiosPromise<AdminGetCourseEnrolmentsViewDataResponse> {
            return localVarFp.adminGetCourseEnrolmentsViewDataV2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceCancellationReasons(options?: any): AxiosPromise<GetCourseInstanceCancellationReasonResponse> {
            return localVarFp.adminGetCourseInstanceCancellationReasons(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceCode(courseId: string, options?: any): AxiosPromise<AdminGetCourseInstanceCode> {
            return localVarFp.adminGetCourseInstanceCode(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceDetails(referenceId: string, options?: any): AxiosPromise<AdminGetCourseInstanceDetailsResponse> {
            return localVarFp.adminGetCourseInstanceDetails(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceFrom(studentId: string, options?: any): AxiosPromise<Array<CourseInstanceRescheduleDto>> {
            return localVarFp.adminGetCourseInstanceFrom(studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceListView(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetAllCourseInstanceListViewResponse> {
            return localVarFp.adminGetCourseInstanceListView(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceReservations(referenceId: string, options?: any): AxiosPromise<AdminGetReservedSeatsResponse> {
            return localVarFp.adminGetCourseInstanceReservations(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceStartDate(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetStartDateForInvoiceLineResponse> {
            return localVarFp.adminGetCourseInstanceStartDate(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fromCourseInstanceId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceTo(fromCourseInstanceId: string, studentId: string, options?: any): AxiosPromise<Array<CourseInstanceRescheduleDto>> {
            return localVarFp.adminGetCourseInstanceTo(fromCourseInstanceId, studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstanceToAddStudentCare(courseId: string, options?: any): AxiosPromise<AdminGetCourseInstanceListToAddStudentCareResponse> {
            return localVarFp.adminGetCourseInstanceToAddStudentCare(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstancesForCreateMessage(options?: any): AxiosPromise<AdminGetCourseInstancesForMessageResponse> {
            return localVarFp.adminGetCourseInstancesForCreateMessage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseInstancesOfBulkEnrolStudents(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetCourseInstancesOfBulkEnrolStudentsResponse> {
            return localVarFp.adminGetCourseInstancesOfBulkEnrolStudents(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCoursePeriodSelect(options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminGetCoursePeriodSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseSessionDetail(sessionId: string, options?: any): AxiosPromise<AdminGetCourseSessionDetailsResponse> {
            return localVarFp.adminGetCourseSessionDetail(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseSessions(referenceId: string, options?: any): AxiosPromise<AdminGetCourseSessionsResponse> {
            return localVarFp.adminGetCourseSessions(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCourseStudents(courseId: string, options?: any): AxiosPromise<Array<CourseStudentDto>> {
            return localVarFp.adminGetCourseStudents(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherReferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCoursesAssignedByTeacherId(teacherReferenceId: string, options?: any): AxiosPromise<AdminGetCoursesResponse> {
            return localVarFp.adminGetCoursesAssignedByTeacherId(teacherReferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCoursesByProvider(providerId: string, options?: any): AxiosPromise<AdminGetCoursesByProviderResponse> {
            return localVarFp.adminGetCoursesByProvider(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCoursesList(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetCoursesResponse> {
            return localVarFp.adminGetCoursesList(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCoursesSeriesForAssignStudent(courseId: string, studentId: string, options?: any): AxiosPromise<AdminGetCourseSeriesForAssignStudentResponse> {
            return localVarFp.adminGetCoursesSeriesForAssignStudent(courseId, studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCreditConfig(options?: any): AxiosPromise<AdminGetCreditConfigResponse> {
            return localVarFp.adminGetCreditConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCreditList(referenceId: string, options?: any): AxiosPromise<AdminGetCreditListResponse> {
            return localVarFp.adminGetCreditList(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCreditReason(options?: any): AxiosPromise<AdminGetCreditReasonResponse> {
            return localVarFp.adminGetCreditReason(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDailyScheduleData(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetDailyScheduleResponse> {
            return localVarFp.adminGetDailyScheduleData(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDiscountCodes(options?: any): AxiosPromise<GetDiscountCodesCampaignResponse> {
            return localVarFp.adminGetDiscountCodes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDiscountCodesById(campaignId: string, options?: any): AxiosPromise<GetDiscountCodesByCampaignIdResponse> {
            return localVarFp.adminGetDiscountCodesById(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDiscountList(options?: any): AxiosPromise<AdminGetDiscountListResponse> {
            return localVarFp.adminGetDiscountList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDiscounts(options?: any): AxiosPromise<GetDiscountListResponse> {
            return localVarFp.adminGetDiscounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDiscountsById(id: string, options?: any): AxiosPromise<DiscountUpsertResponse> {
            return localVarFp.adminGetDiscountsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEPReports(options?: any): AxiosPromise<GetEPReportsResponse> {
            return localVarFp.adminGetEPReports(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [venueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEducationProviderOptions(venueId?: string, options?: any): AxiosPromise<GetEducationProviderOptionsResponse> {
            return localVarFp.adminGetEducationProviderOptions(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} searchKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEducationProviders(searchKey: string, options?: any): AxiosPromise<AdminEducationProvidersResponse> {
            return localVarFp.adminGetEducationProviders(searchKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEducationProvidersById(referenceId: string, options?: any): AxiosPromise<AdminEducationProviderResponse> {
            return localVarFp.adminGetEducationProvidersById(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEducationProvidersByVenueId(venueId: string, options?: any): AxiosPromise<AdminGetEpsByVenueIdResponse> {
            return localVarFp.adminGetEducationProvidersByVenueId(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEducationProvidersSelect(venueId: string, options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminGetEducationProvidersSelect(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEmailTemplateDetailsWithoutBrand(templateAlias: string, options?: any): AxiosPromise<IAdminGetEmailTemplateDetailsWithoutBrandResponse> {
            return localVarFp.adminGetEmailTemplateDetailsWithoutBrand(templateAlias, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEmailTemplates(options?: any): AxiosPromise<GetEmailTemplateOptionResponse> {
            return localVarFp.adminGetEmailTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {Array<string>} [aliasPrefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEmailTemplatesWithoutBrand(search?: string, aliasPrefix?: Array<string>, options?: any): AxiosPromise<IAdminGetEmailTemplatesWithoutBrandResponse> {
            return localVarFp.adminGetEmailTemplatesWithoutBrand(search, aliasPrefix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminGetEnrolledInstanceByStudentRequest} adminGetEnrolledInstanceByStudentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEnrolledInstanceByStudent(adminGetEnrolledInstanceByStudentRequest: AdminGetEnrolledInstanceByStudentRequest, options?: any): AxiosPromise<AdminGetEnrolledInstanceByStudentResponse> {
            return localVarFp.adminGetEnrolledInstanceByStudent(adminGetEnrolledInstanceByStudentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEnrolledStudents(referenceId: string, options?: any): AxiosPromise<AdminGetEnrolledStudentsResponse> {
            return localVarFp.adminGetEnrolledStudents(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetGradeLevelSelect(options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminGetGradeLevelSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetGuardians(referenceId: string, options?: any): AxiosPromise<GetUserInfoResponse> {
            return localVarFp.adminGetGuardians(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetInvoiceDraftOrPendingByEpId(educationProviderId: string, options?: any): AxiosPromise<GetInvoiceDraftOrPendingResponse> {
            return localVarFp.adminGetInvoiceDraftOrPendingByEpId(educationProviderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetInvoiceLineList(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetInvoiceLinesResponse> {
            return localVarFp.adminGetInvoiceLineList(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetInvoiceListCount(options?: any): AxiosPromise<IAdminGetInvoiceListCount> {
            return localVarFp.adminGetInvoiceListCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetInvoiceListRefactor(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<IAdminGetInvoiceListResponse> {
            return localVarFp.adminGetInvoiceListRefactor(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [venueId] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetInvoiceProductList(venueId?: string, type?: string, options?: any): AxiosPromise<AdminGetProductListResponse> {
            return localVarFp.adminGetInvoiceProductList(venueId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMailDomains(options?: any): AxiosPromise<AdminGetMailDomainsResponse> {
            return localVarFp.adminGetMailDomains(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMessageCategories(limit?: number, offset?: number, options?: any): AxiosPromise<AdminGetCategoryParentMessageListResponse> {
            return localVarFp.adminGetMessageCategories(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMessageCategoryById(categoryId: string, options?: any): AxiosPromise<AdminGetMessageCategoryByIdResponseDto> {
            return localVarFp.adminGetMessageCategoryById(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MessageNewTypeEnum} [type] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMessages(type?: MessageNewTypeEnum, limit?: number, offset?: number, options?: any): AxiosPromise<AdminGetMessagesResponse> {
            return localVarFp.adminGetMessages(type, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [parentId] 
         * @param {string} [studentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMessagesByParent(parentId?: string, studentId?: string, options?: any): AxiosPromise<AdminGetMessageListResponse> {
            return localVarFp.adminGetMessagesByParent(parentId, studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetMessagingDetails(messageId: string, options?: any): AxiosPromise<AdminGetMessageDetailsResponse> {
            return localVarFp.adminGetMessagingDetails(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetNextProductNo(sku: string, options?: any): AxiosPromise<string> {
            return localVarFp.adminGetNextProductNo(sku, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentAvailableCredit(parentId: string, options?: any): AxiosPromise<GetBalanceCreditAmountResponse> {
            return localVarFp.adminGetParentAvailableCredit(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentByStudentId(studentId: string, options?: any): AxiosPromise<AdminGetParentByStudentIdResponse> {
            return localVarFp.adminGetParentByStudentId(studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentGuardianByUser(parentId: string, options?: any): AxiosPromise<GetUserInfoResponse> {
            return localVarFp.adminGetParentGuardianByUser(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentInfo(referenceId: string, options?: any): AxiosPromise<AdminGetParentInfoResponse> {
            return localVarFp.adminGetParentInfo(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentNotes(referenceId: string, options?: any): AxiosPromise<AdminGetParentNotes> {
            return localVarFp.adminGetParentNotes(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} parent 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentStudentSchedule(parent: string, start?: string, end?: string, options?: any): AxiosPromise<AdminGetParentStudentScheduleResponse> {
            return localVarFp.adminGetParentStudentSchedule(parent, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentsForCreateMessage(options?: any): AxiosPromise<AdminGetParentsForMessageResponse> {
            return localVarFp.adminGetParentsForCreateMessage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetParentsStudentsCourses(options?: any): AxiosPromise<ParentsStudentsCoursesResponse> {
            return localVarFp.adminGetParentsStudentsCourses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminGetStudentWithPaymentHistoryNewRequest} adminGetStudentWithPaymentHistoryNewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPaymentHistoriesByCourseId(adminGetStudentWithPaymentHistoryNewRequest: AdminGetStudentWithPaymentHistoryNewRequest, options?: any): AxiosPromise<AdminGetStudentWithPaymentHistoryNewResponse> {
            return localVarFp.adminGetPaymentHistoriesByCourseId(adminGetStudentWithPaymentHistoryNewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPermissions(options?: any): AxiosPromise<AdminGetPermissionsResponse> {
            return localVarFp.adminGetPermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPresignedPutURL(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetPresignedPutURLResponse> {
            return localVarFp.adminGetPresignedPutURL(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProductBySKU(sku: string, options?: any): AxiosPromise<AdminGetProductBySKUResponse> {
            return localVarFp.adminGetProductBySKU(sku, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProductList(options?: any): AxiosPromise<AdminGetProductBySKUResponse> {
            return localVarFp.adminGetProductList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProviderSelect(options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminGetProviderSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetQuickSightAgoraAtSchoolReportsUrl(options?: any): AxiosPromise<AdminQuickSightEmbedUrlResponse> {
            return localVarFp.adminGetQuickSightAgoraAtSchoolReportsUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetQuickSightAgoraEpMarketplaceReportsUrl(options?: any): AxiosPromise<AdminQuickSightEmbedUrlResponse> {
            return localVarFp.adminGetQuickSightAgoraEpMarketplaceReportsUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetQuickSightAgoraStudentCareReportUrl(options?: any): AxiosPromise<AdminQuickSightEmbedUrlResponse> {
            return localVarFp.adminGetQuickSightAgoraStudentCareReportUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetQuickSightEarlyYearsReportUrl(options?: any): AxiosPromise<AdminQuickSightEmbedUrlResponse> {
            return localVarFp.adminGetQuickSightEarlyYearsReportUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetQuickSightEmbedUrl(options?: any): AxiosPromise<AdminQuickSightEmbedUrlResponse> {
            return localVarFp.adminGetQuickSightEmbedUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetQuickSightFinanceUpdateReportsUrl(options?: any): AxiosPromise<AdminQuickSightEmbedUrlResponse> {
            return localVarFp.adminGetQuickSightFinanceUpdateReportsUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetQuickSightSharedOpsReportsUrl(options?: any): AxiosPromise<AdminQuickSightEmbedUrlResponse> {
            return localVarFp.adminGetQuickSightSharedOpsReportsUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRateCards(options?: any): AxiosPromise<AdminGetRateCardResponse> {
            return localVarFp.adminGetRateCards(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRefundConfigs(options?: any): AxiosPromise<AdminGetRefundConfigResponse> {
            return localVarFp.adminGetRefundConfigs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRefundParent(referenceId: string, options?: any): AxiosPromise<AdminGetRefundParentResponse> {
            return localVarFp.adminGetRefundParent(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRegistrations(options?: any): AxiosPromise<AdminGetRegistrationResponse> {
            return localVarFp.adminGetRegistrations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetReplacementConfiguration(options?: any): AxiosPromise<AdminGetReplacementConfiguration> {
            return localVarFp.adminGetReplacementConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetReplacementHistory(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetReplacementResponse> {
            return localVarFp.adminGetReplacementHistory(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetReservedData(referenceId: string, options?: any): AxiosPromise<AdminGetReservedDataResponse> {
            return localVarFp.adminGetReservedData(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetReservedStartDate(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetStartDateForInvoiceLineResponse> {
            return localVarFp.adminGetReservedStartDate(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetReservedTrialSingleSession(referenceId: string, options?: any): AxiosPromise<AdminGetTrialCourseInstanceResponse> {
            return localVarFp.adminGetReservedTrialSingleSession(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRevenueRefund(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetRevenueRefundResponse> {
            return localVarFp.adminGetRevenueRefund(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRolePermissions(options?: any): AxiosPromise<AdminGetRolesPermissionsResponse> {
            return localVarFp.adminGetRolePermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRoles(options?: any): AxiosPromise<AdminGetRolesResponse> {
            return localVarFp.adminGetRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRoomBookingInvoiceDetail(reference: string, options?: any): AxiosPromise<GetRoomBookingInvoiceDetailResponse> {
            return localVarFp.adminGetRoomBookingInvoiceDetail(reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} startDate 
         * @param {any} endDate 
         * @param {string} searchKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRoomCalendar(startDate: any, endDate: any, searchKey: string, options?: any): AxiosPromise<AdminGetRoomCalendarResponse> {
            return localVarFp.adminGetRoomCalendar(startDate, endDate, searchKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} roomBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRoomCheckInMedia(roomBookingId: string, options?: any): AxiosPromise<AdminGetRoomCheckInMediaResponse> {
            return localVarFp.adminGetRoomCheckInMedia(roomBookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRoomCheckInReport(venueId: string, options?: any): AxiosPromise<AdminGetRoomCheckInReportResponse> {
            return localVarFp.adminGetRoomCheckInReport(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [providerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRooms(providerId?: string, options?: any): AxiosPromise<AdminGetVenueRoomsResponse> {
            return localVarFp.adminGetRooms(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSchoolSelect(options?: any): AxiosPromise<GetSchoolForChildResponse> {
            return localVarFp.adminGetSchoolSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSchools(options?: any): AxiosPromise<AdminGetSchoolRequest> {
            return localVarFp.adminGetSchools(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSchoolsSelect(venueId: string, options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminGetSchoolsSelect(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSentEmailsByUserId(referenceId: string, userId: string, options?: any): AxiosPromise<AdminGetSentEmailsByUserIdResponse> {
            return localVarFp.adminGetSentEmailsByUserId(referenceId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [date] 
         * @param {string} [venueId] 
         * @param {Array<string>} [courseInstanceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionByDate(date?: any, venueId?: string, courseInstanceIds?: Array<string>, options?: any): AxiosPromise<AdminGetSessionsByDateResponse> {
            return localVarFp.adminGetSessionByDate(date, venueId, courseInstanceIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionPackHistoryByCourseId(referenceId: string, options?: any): AxiosPromise<AdminSessionPackAdditionResponse> {
            return localVarFp.adminGetSessionPackHistoryByCourseId(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionPackStartDate(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetStartDateForInvoiceLineResponse> {
            return localVarFp.adminGetSessionPackStartDate(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionPacksByCourseId(referenceId: string, options?: any): AxiosPromise<AdminGetSessionPackResponse> {
            return localVarFp.adminGetSessionPacksByCourseId(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionPacksByParentId(referenceId: string, options?: any): AxiosPromise<AdminSessionPackAdditionResponse> {
            return localVarFp.adminGetSessionPacksByParentId(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionPacksSelect(courseId: string, options?: any): AxiosPromise<AdminGetSessionPackResponse> {
            return localVarFp.adminGetSessionPacksSelect(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionPacksUsageBySessionPackAdditionId(id: string, options?: any): AxiosPromise<AdminSessionPackUsageResponse> {
            return localVarFp.adminGetSessionPacksUsageBySessionPackAdditionId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentId 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionStudentNotAttending(studentId: string, courseInstanceId: string, options?: any): AxiosPromise<AdminGetSessionStudentNotAttendingResponse> {
            return localVarFp.adminGetSessionStudentNotAttending(studentId, courseInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSessionsByCourse(courseId: string, options?: any): AxiosPromise<AdminGetSessionsByCourseIdResponse> {
            return localVarFp.adminGetSessionsByCourse(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSiblingToUseSessionPack(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetSiblingCheckinResponse> {
            return localVarFp.adminGetSiblingToUseSessionPack(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentCourseInstancesLevel(id: string, options?: any): AxiosPromise<AdminGetStudentCourseInstancesLevelResponse> {
            return localVarFp.adminGetStudentCourseInstancesLevel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentRefernceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentCourses(studentRefernceId: string, options?: any): AxiosPromise<AdminGetStudentCoursesResponse> {
            return localVarFp.adminGetStudentCourses(studentRefernceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentCoursesLevel(id: string, options?: any): AxiosPromise<AdminGetStudentCoursesLevelResponse> {
            return localVarFp.adminGetStudentCoursesLevel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentForUploadBirthdayCertificate(options?: any): AxiosPromise<GetChildrenListForRegistrationResponse> {
            return localVarFp.adminGetStudentForUploadBirthdayCertificate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentInfo(studentId: string, options?: any): AxiosPromise<AdminGetStudentInfoResponse> {
            return localVarFp.adminGetStudentInfo(studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentNoteTypes(options?: any): AxiosPromise<AdminGetStudentNoteTypesResponse> {
            return localVarFp.adminGetStudentNoteTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [studentId] 
         * @param {string} [parentId] 
         * @param {string} [courseInstanceId] 
         * @param {string} [courseSessionId] 
         * @param {boolean} [showOnCheckin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentNotes(studentId?: string, parentId?: string, courseInstanceId?: string, courseSessionId?: string, showOnCheckin?: boolean, options?: any): AxiosPromise<AdminGetStudentNotesResponse> {
            return localVarFp.adminGetStudentNotes(studentId, parentId, courseInstanceId, courseSessionId, showOnCheckin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentReservedCourseInstances(studentId: string, options?: any): AxiosPromise<AdminGetStudentReservedCourseInstancesResponse> {
            return localVarFp.adminGetStudentReservedCourseInstances(studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentsCanReserve(courseInstanceId: string, options?: any): AxiosPromise<AdminGetStudentsCanReserveResponse> {
            return localVarFp.adminGetStudentsCanReserve(courseInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentsCanTrialSession(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetStudentCanTrialSessionResponse> {
            return localVarFp.adminGetStudentsCanTrialSession(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentsCheckIn(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetStudentCheckInCheckOutResponse> {
            return localVarFp.adminGetStudentsCheckIn(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentsCheckOut(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetStudentCheckInCheckOutResponse> {
            return localVarFp.adminGetStudentsCheckOut(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentsWithBookings(limit?: number, offset?: number, options?: any): AxiosPromise<ParentsStudentsCoursesResponse> {
            return localVarFp.adminGetStudentsWithBookings(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetStudentsWithoutBookings(options?: any): AxiosPromise<ParentsStudentsCoursesResponse> {
            return localVarFp.adminGetStudentsWithoutBookings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSubjectAreaSelect(options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminGetSubjectAreaSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSubjectSelect(options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminGetSubjectSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSubscriptionById(referenceId: string, options?: any): AxiosPromise<AdminGetSubscriptionByIdResponse> {
            return localVarFp.adminGetSubscriptionById(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSubscriptionList(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetSubscriptionListResponse> {
            return localVarFp.adminGetSubscriptionList(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTags(options?: any): AxiosPromise<AdminGetTagResponse> {
            return localVarFp.adminGetTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTeacherMOEAreas(options?: any): AxiosPromise<AdminGetTeacherMOEAreasResponse> {
            return localVarFp.adminGetTeacherMOEAreas(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} moeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTeacherMOEsByMOE(moeId: string, options?: any): AxiosPromise<AdminTeacherMOEDto> {
            return localVarFp.adminGetTeacherMOEsByMOE(moeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTeachers(options?: any): AxiosPromise<AdminGetTeachersResponse> {
            return localVarFp.adminGetTeachers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {boolean} [includeAdminUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTeachersByEp(educationProviderId: string, includeAdminUsers?: boolean, options?: any): AxiosPromise<AdminGetAdminGetTeacherResponse> {
            return localVarFp.adminGetTeachersByEp(educationProviderId, includeAdminUsers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTeachersById(teacherId: string, options?: any): AxiosPromise<AdminTeacherDto> {
            return localVarFp.adminGetTeachersById(teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} epReferenceId 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTeamByEpId(epReferenceId: string, search: string, options?: any): AxiosPromise<AdminGetTeamResponse> {
            return localVarFp.adminGetTeamByEpId(epReferenceId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {string} teacherId 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTeamByEpTeacherId(educationProviderId: string, teacherId: string, search: string, options?: any): AxiosPromise<AdminGetTeamDetailResponse> {
            return localVarFp.adminGetTeamByEpTeacherId(educationProviderId, teacherId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} emailParent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTicketParent(emailParent: string, options?: any): AxiosPromise<AdminGetTicketParentResponse> {
            return localVarFp.adminGetTicketParent(emailParent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminGetTotalParentsRequest} adminGetTotalParentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTotalParentsForConfirmMessage(adminGetTotalParentsRequest: AdminGetTotalParentsRequest, options?: any): AxiosPromise<AdminGetParentsForMessageResponse> {
            return localVarFp.adminGetTotalParentsForConfirmMessage(adminGetTotalParentsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTransactionParent(referenceId: string, options?: any): AxiosPromise<AdminGetTransactionsParentResponse> {
            return localVarFp.adminGetTransactionParent(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} financialTransactionsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTransactionRefund(financialTransactionsId: string, options?: any): AxiosPromise<AdminGetTransactionRefundedResponse> {
            return localVarFp.adminGetTransactionRefund(financialTransactionsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {string} studentSessionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTrialCourseInstances(referenceId: string, studentSessionType: string, options?: any): AxiosPromise<AdminGetTrialCourseInstanceResponse> {
            return localVarFp.adminGetTrialCourseInstances(referenceId, studentSessionType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetTrialSessionsOfStudent(studentId: string, options?: any): AxiosPromise<AdminGetTrialSessionsOfStudentResponse> {
            return localVarFp.adminGetTrialSessionsOfStudent(studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVenueRoomDetail(roomId: string, options?: any): AxiosPromise<AdminTeacherGetVenueRoomDetailResponse> {
            return localVarFp.adminGetVenueRoomDetail(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVenueRooms(venueId: string, options?: any): AxiosPromise<AdminGetVenueRoomsResponse> {
            return localVarFp.adminGetVenueRooms(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVenues(options?: any): AxiosPromise<AdminGetVenueResponse> {
            return localVarFp.adminGetVenues(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVenuesById(venueId: string, options?: any): AxiosPromise<CompanyDto> {
            return localVarFp.adminGetVenuesById(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetVenuesForCreateMessageType} [venueType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVenuesForCreateMessage(venueType?: GetVenuesForCreateMessageType, options?: any): AxiosPromise<AdminGetVenuesForMessageResponse> {
            return localVarFp.adminGetVenuesForCreateMessage(venueType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVoucherCampaignHistories(campaignId: string, options?: any): AxiosPromise<AdminGetVoucherHistoriesResponse> {
            return localVarFp.adminGetVoucherCampaignHistories(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVoucherCampaigns(options?: any): AxiosPromise<AdminGetVoucherCampaignsResponse> {
            return localVarFp.adminGetVoucherCampaigns(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetVoucherRecords(campaignId: string, options?: any): AxiosPromise<AdminGetVoucherRecordsResponse> {
            return localVarFp.adminGetVoucherRecords(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGstRatesSelect(options?: any): AxiosPromise<GetAdminByProviderOptionResponse> {
            return localVarFp.adminGstRatesSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminHandleAfterConfirmSeatsCopyCourseInstanceRequest} adminHandleAfterConfirmSeatsCopyCourseInstanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminHandleAfterConfirmSeatsCopyCourseInstance(adminHandleAfterConfirmSeatsCopyCourseInstanceRequest: AdminHandleAfterConfirmSeatsCopyCourseInstanceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminHandleAfterConfirmSeatsCopyCourseInstance(adminHandleAfterConfirmSeatsCopyCourseInstanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminImportPlayschoolCheckinData(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminImportPlayschoolCheckinData(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvoiceAmountLeftToPay(reference: string, options?: any): AxiosPromise<number> {
            return localVarFp.adminInvoiceAmountLeftToPay(reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIsAfterSellingInstance(courseInstanceId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminIsAfterSellingInstance(courseInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMarkAttendingSession(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminMarkAttendingSessionResponse> {
            return localVarFp.adminMarkAttendingSession(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMergeParentsAndStudents(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminMergeParentsAndStudents(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminMigrateExistingStudentRequest} adminMigrateExistingStudentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMigrateExistingStudent(adminMigrateExistingStudentRequest: AdminMigrateExistingStudentRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminMigrateExistingStudent(adminMigrateExistingStudentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminMigrateExistingStudentCareStudentsRequest} adminMigrateExistingStudentCareStudentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMigrateExistingStudentCareStudents(adminMigrateExistingStudentCareStudentsRequest: AdminMigrateExistingStudentCareStudentsRequest, options?: any): AxiosPromise<string> {
            return localVarFp.adminMigrateExistingStudentCareStudents(adminMigrateExistingStudentCareStudentsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userReferenceId 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMixpanelGetUserActivityFeed(userReferenceId: string, fromDate: string, toDate: string, options?: any): AxiosPromise<GetUserActivityFeedResponse> {
            return localVarFp.adminMixpanelGetUserActivityFeed(userReferenceId, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPinStudentNote(studentNoteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminPinStudentNote(studentNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPostCourse(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminPostCourseResponse> {
            return localVarFp.adminPostCourse(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPostParentNotes(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<string> {
            return localVarFp.adminPostParentNotes(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPostUserSaveCard(inlineObject2: InlineObject2, options?: any): AxiosPromise<UserSaveCardResponse> {
            return localVarFp.adminPostUserSaveCard(inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPrepareOldInvoiceData(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminCreateOrUpdateInvoiceRequest | string> {
            return localVarFp.adminPrepareOldInvoiceData(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPreparePlayschoolCheckinData(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PrepareCheckinPlayschoolResponse | string> {
            return localVarFp.adminPreparePlayschoolCheckinData(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPutSubscriptionLines(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminPutSubscriptionLines(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReActiveSubscription(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminReActiveSubscription(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReGenerateRoomBookingInvoice(inlineObject5: InlineObject5, options?: any): AxiosPromise<void> {
            return localVarFp.adminReGenerateRoomBookingInvoice(inlineObject5, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {boolean} [isNotSendMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRecordPayment(requestBody: { [key: string]: object; }, isNotSendMail?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.adminRecordPayment(requestBody, isNotSendMail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {boolean} [isNotSendMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRefundCourseSessions(requestBody: { [key: string]: object; }, isNotSendMail?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.adminRefundCourseSessions(requestBody, isNotSendMail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveBulkEnrol(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminRemoveBulkEnrol(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveCourseMedia(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminRemoveCourseMedia(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveRoomBookingInvoiceLine(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminRemoveRoomBookingInvoiceLine(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResendPassword(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminResendPassword(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} roomId 
         * @param {string} courseSessionId 
         * @param {string} [sessionGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRoomCalendar(roomId: string, courseSessionId: string, sessionGroup?: string, options?: any): AxiosPromise<AdminGetMoreDataRoomViewResponse> {
            return localVarFp.adminRoomCalendar(roomId, courseSessionId, sessionGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [venueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRoomTypeSelects(venueId?: string, options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminRoomTypeSelects(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<AdminSendReservationLinksRequest>} adminSendReservationLinksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendBookingLinks(adminSendReservationLinksRequest: Array<AdminSendReservationLinksRequest>, options?: any): AxiosPromise<void> {
            return localVarFp.adminSendBookingLinks(adminSendReservationLinksRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendEpAgreement(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<string> {
            return localVarFp.adminSendEpAgreement(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetParentCourseReminderEmailDataWithConditionRequest} getParentCourseReminderEmailDataWithConditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendMailParentCourseReminderWithCondition(getParentCourseReminderEmailDataWithConditionRequest: GetParentCourseReminderEmailDataWithConditionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminSendMailParentCourseReminderWithCondition(getParentCourseReminderEmailDataWithConditionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendNotifyReplacementEmail(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminSendNotifyReplacementEmail(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendOrResendLoginInviteToParent(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminSendOrResendLoginInviteToParent(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendReplacementEmailAndNotiToEpTeacher(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminSendReplacementEmailAndNotiToEpTeacher(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendRoomBookingInvoiceMail(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminSendInvoiceResponse> {
            return localVarFp.adminSendRoomBookingInvoiceMail(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendSubscriptionMail(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminSendSubscriptionMail(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSendWithdrawTrialStudentEmail(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminSendWithdrawTrialStudentEmail(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSubmitForApprovalCourse(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminSubmitForApprovalCourse(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSubmitForApprovalCourseInstance(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<ApproveCourseInstanceResponse> {
            return localVarFp.adminSubmitForApprovalCourseInstance(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSubmitForApprovalInvoice(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminSubmitForApprovalInvoice(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTransferSessionPackAddition(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminTransferSessionPackAddition(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnCheckinStudent(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminUnCheckinStudent(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnPinStudentNote(studentNoteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminUnPinStudentNote(studentNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBirthdayCertificateRequest} updateBirthdayCertificateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBirthdayCertificate(updateBirthdayCertificateRequest: UpdateBirthdayCertificateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateBirthdayCertificate(updateBirthdayCertificateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBulkEnrollOffPlatform(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateBulkEnrollOffPlatform(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateChild(id: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateChild(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseMetaData(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateCourseMetaData(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateInvoicesOverdue(options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateInvoicesOverdue(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateIsShareParentContact(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateIsShareParentContact(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateMessageCategory(categoryId: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminCreateOrUpdateMessageCategoryResponseDto> {
            return localVarFp.adminUpdateMessageCategory(categoryId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateOriginalSession(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateOriginalSession(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateReservationStatus(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateReservationStatus(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateRevenueCaseWithoutSelectedRefundSession(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateRevenueCaseWithoutSelectedRefundSession(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertContextSensitiveHelp(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpsertContextSensitiveHelp(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertCourseInstance(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminUpsertCourseInstanceResponse> {
            return localVarFp.adminUpsertCourseInstance(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertCreditReason(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminUpsertCreditReasonResponse> {
            return localVarFp.adminUpsertCreditReason(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertDiscount(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<DiscountUpsertResponse> {
            return localVarFp.adminUpsertDiscount(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertDiscountCodes(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<UpsertDiscountCodesResponse> {
            return localVarFp.adminUpsertDiscountCodes(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertEducationProvider(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminUpsertEducationProviderResponse> {
            return localVarFp.adminUpsertEducationProvider(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertKisiConfiguration(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpsertKisiConfiguration(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} educationProviderReferenceId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertMember(educationProviderReferenceId: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminUpsertMemberResponse> {
            return localVarFp.adminUpsertMember(educationProviderReferenceId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertParent(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminUpsertParentResponse> {
            return localVarFp.adminUpsertParent(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertProduct(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpsertProduct(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertRolePermission(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpsertRolePermission(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertSessionPack(referenceId: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminUpsertSessionPackResponse> {
            return localVarFp.adminUpsertSessionPack(referenceId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpsertVenues(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<CompanyDto> {
            return localVarFp.adminUpsertVenues(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminValidateBeforeCancelCourseInstance(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<string> {
            return localVarFp.adminValidateBeforeCancelCourseInstance(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminValidateChangeTeacher(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<string> {
            return localVarFp.adminValidateChangeTeacher(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminValidateClassUsed(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminValidateClassUsed(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<ValidateCourseSessionRequest>} validateCourseSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminValidateCourseSessions(validateCourseSessionRequest: Array<ValidateCourseSessionRequest>, options?: any): AxiosPromise<Array<ValidateCourseSessionResponse>> {
            return localVarFp.adminValidateCourseSessions(validateCourseSessionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminValidateStudentIsSignedUpCourseSeries(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminValidateStudentIsSignedUpCourseSeries(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminValidateTrialSessionRequest(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminValidateTrialSessionRequestResponse> {
            return localVarFp.adminValidateTrialSessionRequest(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminValidateUpsertReservation(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<ValidateDto> {
            return localVarFp.adminValidateUpsertReservation(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} educationProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVenueSelects(educationProviderId: string, options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminVenueSelects(educationProviderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVenuesSelect(options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.adminVenuesSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVoidInvoice(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminVoidInvoice(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminWithdrawStudentFromSubscription(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<WithdrawStudentResponse> {
            return localVarFp.adminWithdrawStudentFromSubscription(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminWithdrawTrialStudent(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.adminWithdrawTrialStudent(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveInvoice(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.approveInvoice(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoUpdateCourseAndCourseInstanceStatus(options?: any): AxiosPromise<void> {
            return localVarFp.autoUpdateCourseAndCourseInstanceStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkOverlapSession(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminCheckOverlapResponse> {
            return localVarFp.checkOverlapSession(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourseSeries(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCourseSeries(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} scheduledDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollStudentsNextCourseInstance(scheduledDate: any, options?: any): AxiosPromise<void> {
            return localVarFp.enrollStudentsNextCourseInstance(scheduledDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCourseReferenceID(options?: any): AxiosPromise<string> {
            return localVarFp.generateCourseReferenceID(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminOptionsByProviderId(providerId: string, options?: any): AxiosPromise<GetAdminByProviderOptionResponse> {
            return localVarFp.getAdminOptionsByProviderId(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckinLogListBeforeUncheckin(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetCheckinLogListBeforeUncheckinResponse> {
            return localVarFp.getCheckinLogListBeforeUncheckin(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSeriesByCourse(courseId: string, options?: any): AxiosPromise<AdminGetCourseSeriesResponse> {
            return localVarFp.getCourseSeriesByCourse(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSeriesDetails(seriesId: string, options?: any): AxiosPromise<AdminGetCourseSeriesDetailsResponse> {
            return localVarFp.getCourseSeriesDetails(seriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExtraRoomsBySessionId(courseSessionId: string, options?: any): AxiosPromise<AdminGetExtraRoomResponse> {
            return localVarFp.getExtraRoomsBySessionId(courseSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterUser(options?: any): AxiosPromise<AdminGetFilterUserResponse> {
            return localVarFp.getFilterUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHolidaysBySession(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getHolidaysBySession(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageAuditHistories(id: string, options?: any): AxiosPromise<AdminGetAuditHistoriesResponse> {
            return localVarFp.getMessageAuditHistories(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentListForImpersonation(search?: string, limit?: number, offset?: number, options?: any): AxiosPromise<GetParentListForImpersonationResponseInterface> {
            return localVarFp.getParentListForImpersonation(search, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentListView(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetParentListViewResponse> {
            return localVarFp.getParentListView(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReasonList(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetReasonListResponse> {
            return localVarFp.getReasonList(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationLog(id: string, options?: any): AxiosPromise<IGetRegistrationLogResponse> {
            return localVarFp.getRegistrationLog(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReplacementConfig(referenceId: string, options?: any): AxiosPromise<ReplacementConfigDto> {
            return localVarFp.getReplacementConfig(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReplacementSessionAvailable(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminGetReplacementSessionAvailableResponse> {
            return localVarFp.getReplacementSessionAvailable(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleJobs(options?: any): AxiosPromise<AdminGetScheduleJobsResponse> {
            return localVarFp.getScheduleJobs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionNumberById(instanceId: string, sessionId: string, options?: any): AxiosPromise<number> {
            return localVarFp.getSessionNumberById(instanceId, sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentsSelect(options?: any): AxiosPromise<AdminGetStudentsCanReserveResponse> {
            return localVarFp.getStudentsSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherMOEsByTeacher(teacherId: string, options?: any): AxiosPromise<AdminGetTeacherMOEsResponse> {
            return localVarFp.getTeacherMOEsByTeacher(teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} searchKey 
         * @param {string} filterStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersAdmin(searchKey: string, filterStatus: string, options?: any): AxiosPromise<AdminGetUserResponse> {
            return localVarFp.getUsersAdmin(searchKey, filterStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEPGEnrolment(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.importEPGEnrolment(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MigrateParentEmergencyContactRequest} migrateParentEmergencyContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateParentEmergencyContact(migrateParentEmergencyContactRequest: MigrateParentEmergencyContactRequest, options?: any): AxiosPromise<string> {
            return localVarFp.migrateParentEmergencyContact(migrateParentEmergencyContactRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminPostCourseSeriesRequest} adminPostCourseSeriesRequest 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCourseSeries(adminPostCourseSeriesRequest: AdminPostCourseSeriesRequest, date?: string, options?: any): AxiosPromise<AdminPostCourseSeriesResponse> {
            return localVarFp.postCourseSeries(adminPostCourseSeriesRequest, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processOldInvoiceVoided(inlineObject6: InlineObject6, options?: any): AxiosPromise<void> {
            return localVarFp.processOldInvoiceVoided(inlineObject6, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invoiceLineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundInvoiceLine(invoiceLineId: string, options?: any): AxiosPromise<void> {
            return localVarFp.refundInvoiceLine(invoiceLineId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeReason(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeReason(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPostmarkMessagesDetails(limit: number, options?: any): AxiosPromise<void> {
            return localVarFp.syncPostmarkMessagesDetails(limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {boolean} [skipValidation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourseSession(requestBody: { [key: string]: object; }, skipValidation?: boolean, options?: any): AxiosPromise<ValidateDto> {
            return localVarFp.updateCourseSession(requestBody, skipValidation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRole(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminChangeUserRoleResponse> {
            return localVarFp.updateUserRole(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserStatus(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AdminChangeUserStatusResponse> {
            return localVarFp.updateUserStatus(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertNoteTypes(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.upsertNoteTypes(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertReason(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.upsertReason(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertTeacherMOE(teacherId: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.upsertTeacherMOE(teacherId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertTeacherMOEArea(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.upsertTeacherMOEArea(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {string} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public addRevenueByInvoiceLines(invoiceId: string, options?: any) {
        return AdminApiFp(this.configuration).addRevenueByInvoiceLines(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invoiceReferenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public addStudentCourseSessionDataForSentInvoices(invoiceReferenceId: string, options?: any) {
        return AdminApiFp(this.configuration).addStudentCourseSessionDataForSentInvoices(invoiceReferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} subscriptionLineId 
     * @param {string} invoiceId 
     * @param {string} collectionDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public additionalDataSubscriptionInvoiceLine(subscriptionLineId: string, invoiceId: string, collectionDate: string, options?: any) {
        return AdminApiFp(this.configuration).additionalDataSubscriptionInvoiceLine(subscriptionLineId, invoiceId, collectionDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAddCredit(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminAddCredit(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAddEpAgreement(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminAddEpAgreement(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAddSessionPackToCart(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminAddSessionPackToCart(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAddTrialSession(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminAddTrialSession(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAddTrialSingleSession(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminAddTrialSingleSession(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAddVenueRoom(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminAddVenueRoom(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminApprovalCourseInstance(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminApprovalCourseInstance(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminApproveCourse(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminApproveCourse(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAssignEducationProvidersForVenue(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminAssignEducationProvidersForVenue(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} classId 
     * @param {InlineObject4} inlineObject4 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAssignStudentToClass(courseId: string, classId: string, inlineObject4: InlineObject4, options?: any) {
        return AdminApiFp(this.configuration).adminAssignStudentToClass(courseId, classId, inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssignStudentToCourseSeriesRequest} assignStudentToCourseSeriesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAssignStudentToCourseSeries(assignStudentToCourseSeriesRequest: AssignStudentToCourseSeriesRequest, options?: any) {
        return AdminApiFp(this.configuration).adminAssignStudentToCourseSeries(assignStudentToCourseSeriesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<RoomReserveDto>} roomReserveDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminBulkCreateReserveRoom(roomReserveDto: Array<RoomReserveDto>, options?: any) {
        return AdminApiFp(this.configuration).adminBulkCreateReserveRoom(roomReserveDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminBulkEnrollOffPlatform(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminBulkEnrollOffPlatform(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminBusinessUnitsSelect(options?: any) {
        return AdminApiFp(this.configuration).adminBusinessUnitsSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseEnrolmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCancelCourseEnrolment(courseEnrolmentId: string, options?: any) {
        return AdminApiFp(this.configuration).adminCancelCourseEnrolment(courseEnrolmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCancelCourseInstance(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCancelCourseInstance(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCancelRevenues(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCancelRevenues(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCancelSubscription(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCancelSubscription(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCancelTrialSingleSession(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCancelTrialSingleSession(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCategoriesSelect(options?: any) {
        return AdminApiFp(this.configuration).adminCategoriesSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {string} educationProviderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminChangeTeacherToEP(teacherId: string, educationProviderId: string, options?: any) {
        return AdminApiFp(this.configuration).adminChangeTeacherToEP(teacherId, educationProviderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCheckExistUserEmail(email: string, options?: any) {
        return AdminApiFp(this.configuration).adminCheckExistUserEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invoiceReferenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCheckInvoiceValidToDelete(invoiceReferenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminCheckInvoiceValidToDelete(invoiceReferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCheckIsAfterSellingCourse(courseId: string, options?: any) {
        return AdminApiFp(this.configuration).adminCheckIsAfterSellingCourse(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCheckSomeClassesUsed(venueId: string, options?: any) {
        return AdminApiFp(this.configuration).adminCheckSomeClassesUsed(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCheckValidToArchiveDiscount(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCheckValidToArchiveDiscount(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCheckinStudents(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCheckinStudents(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCheckoutStudents(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCheckoutStudents(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCopyCourseInstance(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCopyCourseInstance(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCountriesSelect(options?: any) {
        return AdminApiFp(this.configuration).adminCountriesSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCourseFormatSelect(options?: any) {
        return AdminApiFp(this.configuration).adminCourseFormatSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [venueId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCourseInstancesSelect(venueId?: string, options?: any) {
        return AdminApiFp(this.configuration).adminCourseInstancesSelect(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCourseTypeSelect(options?: any) {
        return AdminApiFp(this.configuration).adminCourseTypeSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCoursesSelect(includeInactive?: boolean, options?: any) {
        return AdminApiFp(this.configuration).adminCoursesSelect(includeInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateChild(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateChild(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateCourseMedia(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateCourseMedia(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} secretKey 
     * @param {InlineObject3} inlineObject3 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateInstanceBySeries(secretKey: string, inlineObject3: InlineObject3, options?: any) {
        return AdminApiFp(this.configuration).adminCreateInstanceBySeries(secretKey, inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateMessageCategory(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateMessageCategory(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateNotificationReplacement(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateNotificationReplacement(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateOrUpdateCourseClass(courseId: string, requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateOrUpdateCourseClass(courseId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateOrUpdateCourseMedia(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateOrUpdateCourseMedia(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateOrUpdateInvoiceAttachments(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateOrUpdateInvoiceAttachments(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminCreateOrUpdateMessageRequest} adminCreateOrUpdateMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateOrUpdateMessage(adminCreateOrUpdateMessageRequest: AdminCreateOrUpdateMessageRequest, options?: any) {
        return AdminApiFp(this.configuration).adminCreateOrUpdateMessage(adminCreateOrUpdateMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateOrUpdateParentOrEPInvoice(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateOrUpdateParentOrEPInvoice(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {boolean} [skipValidation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateOrUpdateReservation(requestBody: { [key: string]: object; }, skipValidation?: boolean, options?: any) {
        return AdminApiFp(this.configuration).adminCreateOrUpdateReservation(requestBody, skipValidation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateOrUpdateStudentNoteAttachments(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateOrUpdateStudentNoteAttachments(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateOrUpdateStudentNotes(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateOrUpdateStudentNotes(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateOrUpdateSubscription(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateOrUpdateSubscription(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreatePaidInvoice(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreatePaidInvoice(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateReplacementHistory(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateReplacementHistory(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RescheduleRequest} rescheduleRequest 
     * @param {boolean} [skipValidation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateReschedule(rescheduleRequest: RescheduleRequest, skipValidation?: boolean, options?: any) {
        return AdminApiFp(this.configuration).adminCreateReschedule(rescheduleRequest, skipValidation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateSOAOrInvoiceReportData(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateSOAOrInvoiceReportData(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateStudentSession(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateStudentSession(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateUser(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateUser(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateVoucherCampaigns(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminCreateVoucherCampaigns(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeductSessionPackAddition(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminDeductSessionPackAddition(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteAllCourseSessions(courseInstanceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteAllCourseSessions(courseInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteCourse(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteCourse(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteCourseInstance(courseInstanceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteCourseInstance(courseInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteCourseSession(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteCourseSession(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteCreditReason(id: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteCreditReason(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteEPReport(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteEPReport(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} educationProviderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteEducationProvider(educationProviderId: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteEducationProvider(educationProviderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invoiceReferenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteInvoice(invoiceReferenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteInvoice(invoiceReferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteMessageCategory(categoryId: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteMessageCategory(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} refundHistoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteRefundHistory(refundHistoryId: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteRefundHistory(refundHistoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteRoomBooking(id: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteRoomBooking(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteSessionPack(referenceId: string, id: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteSessionPack(referenceId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueEpId 
     * @param {InlineObject1} inlineObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteStatusEpsForVenue(venueEpId: string, inlineObject1: InlineObject1, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteStatusEpsForVenue(venueEpId, inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} typeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteStudentNoteType(typeId: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteStudentNoteType(typeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteStudentNotes(studentNoteId: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteStudentNotes(studentNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} originalSessionId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteStudentSession(originalSessionId: string, userId: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteStudentSession(originalSessionId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} moeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteTeacherMOE(moeId: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteTeacherMOE(moeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} moeAreaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteTeacherMOEArea(moeAreaId: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteTeacherMOEArea(moeAreaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteVenue(venueId: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteVenue(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} roomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteVenueRoom(roomId: string, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteVenueRoom(roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteVoucherCampaigns(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminDeleteVoucherCampaigns(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDownloadDBS(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminDownloadDBS(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditCourseDescription(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminEditCourseDescription(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditCreditConfig(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminEditCreditConfig(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditInvoice(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminEditInvoice(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditNumberOfSession(courseInstanceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminEditNumberOfSession(courseInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditStatusEpForVenue(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminEditStatusEpForVenue(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEquipmentSelects(options?: any) {
        return AdminApiFp(this.configuration).adminEquipmentSelects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {InlineObject} inlineObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminExportAttendanceReportToSpreadSheets(venueId: string, inlineObject: InlineObject, options?: any) {
        return AdminApiFp(this.configuration).adminExportAttendanceReportToSpreadSheets(venueId, inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminExtendExpirationDateSessionPackAddition(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminExtendExpirationDateSessionPackAddition(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {SubscriptionPaymentMethod} paymentMethod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminFindSubscriptionPaymentCard(userId: string, paymentMethod: SubscriptionPaymentMethod, options?: any) {
        return AdminApiFp(this.configuration).adminFindSubscriptionPaymentCard(userId, paymentMethod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {string} instanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGenerateAttendanceReport(venueId: string, instanceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGenerateAttendanceReport(venueId, instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<CourseSessionRequest>} courseSessionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGenerateCourseSessions(courseSessionRequest: Array<CourseSessionRequest>, options?: any) {
        return AdminApiFp(this.configuration).adminGenerateCourseSessions(courseSessionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGenerateInvoicePdf(reference: string, options?: any) {
        return AdminApiFp(this.configuration).adminGenerateInvoicePdf(reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGenerateSingleReport(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGenerateSingleReport(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetAgoraCareUsages(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetAgoraCareUsages(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetAgreementByCourseId(courseId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetAgreementByCourseId(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetAllEmailTemplates(options?: any) {
        return AdminApiFp(this.configuration).adminGetAllEmailTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetAllKisiConfigurations(options?: any) {
        return AdminApiFp(this.configuration).adminGetAllKisiConfigurations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetApproverSelect(options?: any) {
        return AdminApiFp(this.configuration).adminGetApproverSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetAttendanceBySession(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetAttendanceBySession(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {string} [providerId] 
     * @param {string} [month] 
     * @param {boolean} [onlyShowEPsWithOffPlatformStudent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetAttendanceReportsByVenue(venueId: string, providerId?: string, month?: string, onlyShowEPsWithOffPlatformStudent?: boolean, options?: any) {
        return AdminApiFp(this.configuration).adminGetAttendanceReportsByVenue(venueId, providerId, month, onlyShowEPsWithOffPlatformStudent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetAvailableCourseInstance(options?: any) {
        return AdminApiFp(this.configuration).adminGetAvailableCourseInstance(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetAvailableRooms(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetAvailableRooms(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCampaignsParent(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCampaignsParent(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCategoryList(options?: any) {
        return AdminApiFp(this.configuration).adminGetCategoryList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentId 
     * @param {any} date 
     * @param {string} [venueId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCheckinAbleSessionsForStudentAndDateWithSessionPack(studentId: string, date: any, venueId?: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCheckinAbleSessionsForStudentAndDateWithSessionPack(studentId, date, venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetChildrenByParentForMessage(parentId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetChildrenByParentForMessage(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetChildrenInfo(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetChildrenInfo(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetChildrenListToAddStudentCare(parentId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetChildrenListToAddStudentCare(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} forObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetContextSensitiveHelps(forObject: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetContextSensitiveHelps(forObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} classId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseClassDetail(courseId: string, classId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseClassDetail(courseId, classId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseClasses(courseId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseClasses(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseClassesForMessage(options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseClassesForMessage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseDetails(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseDetails(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseEnrolledStudents(courseId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseEnrolledStudents(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseEnrolmentsViewDataV2(options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseEnrolmentsViewDataV2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseInstanceCancellationReasons(options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseInstanceCancellationReasons(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseInstanceCode(courseId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseInstanceCode(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseInstanceDetails(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseInstanceDetails(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseInstanceFrom(studentId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseInstanceFrom(studentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseInstanceListView(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseInstanceListView(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseInstanceReservations(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseInstanceReservations(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseInstanceStartDate(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseInstanceStartDate(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fromCourseInstanceId 
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseInstanceTo(fromCourseInstanceId: string, studentId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseInstanceTo(fromCourseInstanceId, studentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseInstanceToAddStudentCare(courseId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseInstanceToAddStudentCare(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseInstancesForCreateMessage(options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseInstancesForCreateMessage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseInstancesOfBulkEnrolStudents(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseInstancesOfBulkEnrolStudents(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCoursePeriodSelect(options?: any) {
        return AdminApiFp(this.configuration).adminGetCoursePeriodSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseSessionDetail(sessionId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseSessionDetail(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseSessions(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseSessions(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCourseStudents(courseId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCourseStudents(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherReferenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCoursesAssignedByTeacherId(teacherReferenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCoursesAssignedByTeacherId(teacherReferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCoursesByProvider(providerId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCoursesByProvider(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCoursesList(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetCoursesList(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCoursesSeriesForAssignStudent(courseId: string, studentId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCoursesSeriesForAssignStudent(courseId, studentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCreditConfig(options?: any) {
        return AdminApiFp(this.configuration).adminGetCreditConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCreditList(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetCreditList(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCreditReason(options?: any) {
        return AdminApiFp(this.configuration).adminGetCreditReason(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetDailyScheduleData(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetDailyScheduleData(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetDiscountCodes(options?: any) {
        return AdminApiFp(this.configuration).adminGetDiscountCodes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetDiscountCodesById(campaignId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetDiscountCodesById(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetDiscountList(options?: any) {
        return AdminApiFp(this.configuration).adminGetDiscountList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetDiscounts(options?: any) {
        return AdminApiFp(this.configuration).adminGetDiscounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetDiscountsById(id: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetDiscountsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetEPReports(options?: any) {
        return AdminApiFp(this.configuration).adminGetEPReports(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [venueId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetEducationProviderOptions(venueId?: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetEducationProviderOptions(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} searchKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetEducationProviders(searchKey: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetEducationProviders(searchKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetEducationProvidersById(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetEducationProvidersById(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetEducationProvidersByVenueId(venueId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetEducationProvidersByVenueId(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetEducationProvidersSelect(venueId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetEducationProvidersSelect(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetEmailTemplateDetailsWithoutBrand(templateAlias: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetEmailTemplateDetailsWithoutBrand(templateAlias, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetEmailTemplates(options?: any) {
        return AdminApiFp(this.configuration).adminGetEmailTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {Array<string>} [aliasPrefix] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetEmailTemplatesWithoutBrand(search?: string, aliasPrefix?: Array<string>, options?: any) {
        return AdminApiFp(this.configuration).adminGetEmailTemplatesWithoutBrand(search, aliasPrefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminGetEnrolledInstanceByStudentRequest} adminGetEnrolledInstanceByStudentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetEnrolledInstanceByStudent(adminGetEnrolledInstanceByStudentRequest: AdminGetEnrolledInstanceByStudentRequest, options?: any) {
        return AdminApiFp(this.configuration).adminGetEnrolledInstanceByStudent(adminGetEnrolledInstanceByStudentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetEnrolledStudents(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetEnrolledStudents(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetGradeLevelSelect(options?: any) {
        return AdminApiFp(this.configuration).adminGetGradeLevelSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetGuardians(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetGuardians(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} educationProviderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetInvoiceDraftOrPendingByEpId(educationProviderId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetInvoiceDraftOrPendingByEpId(educationProviderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetInvoiceLineList(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetInvoiceLineList(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetInvoiceListCount(options?: any) {
        return AdminApiFp(this.configuration).adminGetInvoiceListCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetInvoiceListRefactor(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetInvoiceListRefactor(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [venueId] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetInvoiceProductList(venueId?: string, type?: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetInvoiceProductList(venueId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetMailDomains(options?: any) {
        return AdminApiFp(this.configuration).adminGetMailDomains(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetMessageCategories(limit?: number, offset?: number, options?: any) {
        return AdminApiFp(this.configuration).adminGetMessageCategories(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetMessageCategoryById(categoryId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetMessageCategoryById(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessageNewTypeEnum} [type] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetMessages(type?: MessageNewTypeEnum, limit?: number, offset?: number, options?: any) {
        return AdminApiFp(this.configuration).adminGetMessages(type, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [parentId] 
     * @param {string} [studentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetMessagesByParent(parentId?: string, studentId?: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetMessagesByParent(parentId, studentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetMessagingDetails(messageId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetMessagingDetails(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sku 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetNextProductNo(sku: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetNextProductNo(sku, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetParentAvailableCredit(parentId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetParentAvailableCredit(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetParentByStudentId(studentId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetParentByStudentId(studentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetParentGuardianByUser(parentId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetParentGuardianByUser(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetParentInfo(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetParentInfo(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetParentNotes(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetParentNotes(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} parent 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetParentStudentSchedule(parent: string, start?: string, end?: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetParentStudentSchedule(parent, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetParentsForCreateMessage(options?: any) {
        return AdminApiFp(this.configuration).adminGetParentsForCreateMessage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetParentsStudentsCourses(options?: any) {
        return AdminApiFp(this.configuration).adminGetParentsStudentsCourses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminGetStudentWithPaymentHistoryNewRequest} adminGetStudentWithPaymentHistoryNewRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetPaymentHistoriesByCourseId(adminGetStudentWithPaymentHistoryNewRequest: AdminGetStudentWithPaymentHistoryNewRequest, options?: any) {
        return AdminApiFp(this.configuration).adminGetPaymentHistoriesByCourseId(adminGetStudentWithPaymentHistoryNewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetPermissions(options?: any) {
        return AdminApiFp(this.configuration).adminGetPermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetPresignedPutURL(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetPresignedPutURL(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sku 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetProductBySKU(sku: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetProductBySKU(sku, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetProductList(options?: any) {
        return AdminApiFp(this.configuration).adminGetProductList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetProviderSelect(options?: any) {
        return AdminApiFp(this.configuration).adminGetProviderSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetQuickSightAgoraAtSchoolReportsUrl(options?: any) {
        return AdminApiFp(this.configuration).adminGetQuickSightAgoraAtSchoolReportsUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetQuickSightAgoraEpMarketplaceReportsUrl(options?: any) {
        return AdminApiFp(this.configuration).adminGetQuickSightAgoraEpMarketplaceReportsUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetQuickSightAgoraStudentCareReportUrl(options?: any) {
        return AdminApiFp(this.configuration).adminGetQuickSightAgoraStudentCareReportUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetQuickSightEarlyYearsReportUrl(options?: any) {
        return AdminApiFp(this.configuration).adminGetQuickSightEarlyYearsReportUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetQuickSightEmbedUrl(options?: any) {
        return AdminApiFp(this.configuration).adminGetQuickSightEmbedUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetQuickSightFinanceUpdateReportsUrl(options?: any) {
        return AdminApiFp(this.configuration).adminGetQuickSightFinanceUpdateReportsUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetQuickSightSharedOpsReportsUrl(options?: any) {
        return AdminApiFp(this.configuration).adminGetQuickSightSharedOpsReportsUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetRateCards(options?: any) {
        return AdminApiFp(this.configuration).adminGetRateCards(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetRefundConfigs(options?: any) {
        return AdminApiFp(this.configuration).adminGetRefundConfigs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetRefundParent(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetRefundParent(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetRegistrations(options?: any) {
        return AdminApiFp(this.configuration).adminGetRegistrations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetReplacementConfiguration(options?: any) {
        return AdminApiFp(this.configuration).adminGetReplacementConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetReplacementHistory(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetReplacementHistory(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetReservedData(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetReservedData(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetReservedStartDate(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetReservedStartDate(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetReservedTrialSingleSession(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetReservedTrialSingleSession(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetRevenueRefund(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetRevenueRefund(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetRolePermissions(options?: any) {
        return AdminApiFp(this.configuration).adminGetRolePermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetRoles(options?: any) {
        return AdminApiFp(this.configuration).adminGetRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetRoomBookingInvoiceDetail(reference: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetRoomBookingInvoiceDetail(reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} startDate 
     * @param {any} endDate 
     * @param {string} searchKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetRoomCalendar(startDate: any, endDate: any, searchKey: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetRoomCalendar(startDate, endDate, searchKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} roomBookingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetRoomCheckInMedia(roomBookingId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetRoomCheckInMedia(roomBookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetRoomCheckInReport(venueId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetRoomCheckInReport(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [providerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetRooms(providerId?: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetRooms(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSchoolSelect(options?: any) {
        return AdminApiFp(this.configuration).adminGetSchoolSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSchools(options?: any) {
        return AdminApiFp(this.configuration).adminGetSchools(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSchoolsSelect(venueId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetSchoolsSelect(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSentEmailsByUserId(referenceId: string, userId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetSentEmailsByUserId(referenceId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [date] 
     * @param {string} [venueId] 
     * @param {Array<string>} [courseInstanceIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSessionByDate(date?: any, venueId?: string, courseInstanceIds?: Array<string>, options?: any) {
        return AdminApiFp(this.configuration).adminGetSessionByDate(date, venueId, courseInstanceIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSessionPackHistoryByCourseId(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetSessionPackHistoryByCourseId(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSessionPackStartDate(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetSessionPackStartDate(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSessionPacksByCourseId(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetSessionPacksByCourseId(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSessionPacksByParentId(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetSessionPacksByParentId(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSessionPacksSelect(courseId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetSessionPacksSelect(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSessionPacksUsageBySessionPackAdditionId(id: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetSessionPacksUsageBySessionPackAdditionId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentId 
     * @param {string} courseInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSessionStudentNotAttending(studentId: string, courseInstanceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetSessionStudentNotAttending(studentId, courseInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSessionsByCourse(courseId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetSessionsByCourse(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSiblingToUseSessionPack(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetSiblingToUseSessionPack(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetStudentCourseInstancesLevel(id: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetStudentCourseInstancesLevel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentRefernceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetStudentCourses(studentRefernceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetStudentCourses(studentRefernceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetStudentCoursesLevel(id: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetStudentCoursesLevel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetStudentForUploadBirthdayCertificate(options?: any) {
        return AdminApiFp(this.configuration).adminGetStudentForUploadBirthdayCertificate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetStudentInfo(studentId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetStudentInfo(studentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetStudentNoteTypes(options?: any) {
        return AdminApiFp(this.configuration).adminGetStudentNoteTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [studentId] 
     * @param {string} [parentId] 
     * @param {string} [courseInstanceId] 
     * @param {string} [courseSessionId] 
     * @param {boolean} [showOnCheckin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetStudentNotes(studentId?: string, parentId?: string, courseInstanceId?: string, courseSessionId?: string, showOnCheckin?: boolean, options?: any) {
        return AdminApiFp(this.configuration).adminGetStudentNotes(studentId, parentId, courseInstanceId, courseSessionId, showOnCheckin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetStudentReservedCourseInstances(studentId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetStudentReservedCourseInstances(studentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetStudentsCanReserve(courseInstanceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetStudentsCanReserve(courseInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetStudentsCanTrialSession(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetStudentsCanTrialSession(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetStudentsCheckIn(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetStudentsCheckIn(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetStudentsCheckOut(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetStudentsCheckOut(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetStudentsWithBookings(limit?: number, offset?: number, options?: any) {
        return AdminApiFp(this.configuration).adminGetStudentsWithBookings(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetStudentsWithoutBookings(options?: any) {
        return AdminApiFp(this.configuration).adminGetStudentsWithoutBookings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSubjectAreaSelect(options?: any) {
        return AdminApiFp(this.configuration).adminGetSubjectAreaSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSubjectSelect(options?: any) {
        return AdminApiFp(this.configuration).adminGetSubjectSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSubscriptionById(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetSubscriptionById(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetSubscriptionList(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminGetSubscriptionList(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTags(options?: any) {
        return AdminApiFp(this.configuration).adminGetTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTeacherMOEAreas(options?: any) {
        return AdminApiFp(this.configuration).adminGetTeacherMOEAreas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} moeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTeacherMOEsByMOE(moeId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetTeacherMOEsByMOE(moeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTeachers(options?: any) {
        return AdminApiFp(this.configuration).adminGetTeachers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} educationProviderId 
     * @param {boolean} [includeAdminUsers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTeachersByEp(educationProviderId: string, includeAdminUsers?: boolean, options?: any) {
        return AdminApiFp(this.configuration).adminGetTeachersByEp(educationProviderId, includeAdminUsers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTeachersById(teacherId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetTeachersById(teacherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} epReferenceId 
     * @param {string} search 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTeamByEpId(epReferenceId: string, search: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetTeamByEpId(epReferenceId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} educationProviderId 
     * @param {string} teacherId 
     * @param {string} search 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTeamByEpTeacherId(educationProviderId: string, teacherId: string, search: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetTeamByEpTeacherId(educationProviderId, teacherId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} emailParent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTicketParent(emailParent: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetTicketParent(emailParent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminGetTotalParentsRequest} adminGetTotalParentsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTotalParentsForConfirmMessage(adminGetTotalParentsRequest: AdminGetTotalParentsRequest, options?: any) {
        return AdminApiFp(this.configuration).adminGetTotalParentsForConfirmMessage(adminGetTotalParentsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTransactionParent(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetTransactionParent(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} financialTransactionsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTransactionRefund(financialTransactionsId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetTransactionRefund(financialTransactionsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {string} studentSessionType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTrialCourseInstances(referenceId: string, studentSessionType: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetTrialCourseInstances(referenceId, studentSessionType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetTrialSessionsOfStudent(studentId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetTrialSessionsOfStudent(studentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} roomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetVenueRoomDetail(roomId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetVenueRoomDetail(roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetVenueRooms(venueId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetVenueRooms(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetVenues(options?: any) {
        return AdminApiFp(this.configuration).adminGetVenues(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetVenuesById(venueId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetVenuesById(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetVenuesForCreateMessageType} [venueType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetVenuesForCreateMessage(venueType?: GetVenuesForCreateMessageType, options?: any) {
        return AdminApiFp(this.configuration).adminGetVenuesForCreateMessage(venueType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetVoucherCampaignHistories(campaignId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetVoucherCampaignHistories(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetVoucherCampaigns(options?: any) {
        return AdminApiFp(this.configuration).adminGetVoucherCampaigns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetVoucherRecords(campaignId: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetVoucherRecords(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGstRatesSelect(options?: any) {
        return AdminApiFp(this.configuration).adminGstRatesSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminHandleAfterConfirmSeatsCopyCourseInstanceRequest} adminHandleAfterConfirmSeatsCopyCourseInstanceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminHandleAfterConfirmSeatsCopyCourseInstance(adminHandleAfterConfirmSeatsCopyCourseInstanceRequest: AdminHandleAfterConfirmSeatsCopyCourseInstanceRequest, options?: any) {
        return AdminApiFp(this.configuration).adminHandleAfterConfirmSeatsCopyCourseInstance(adminHandleAfterConfirmSeatsCopyCourseInstanceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminImportPlayschoolCheckinData(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminImportPlayschoolCheckinData(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminInvoiceAmountLeftToPay(reference: string, options?: any) {
        return AdminApiFp(this.configuration).adminInvoiceAmountLeftToPay(reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminIsAfterSellingInstance(courseInstanceId: string, options?: any) {
        return AdminApiFp(this.configuration).adminIsAfterSellingInstance(courseInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminMarkAttendingSession(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminMarkAttendingSession(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminMergeParentsAndStudents(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminMergeParentsAndStudents(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminMigrateExistingStudentRequest} adminMigrateExistingStudentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminMigrateExistingStudent(adminMigrateExistingStudentRequest: AdminMigrateExistingStudentRequest, options?: any) {
        return AdminApiFp(this.configuration).adminMigrateExistingStudent(adminMigrateExistingStudentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminMigrateExistingStudentCareStudentsRequest} adminMigrateExistingStudentCareStudentsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminMigrateExistingStudentCareStudents(adminMigrateExistingStudentCareStudentsRequest: AdminMigrateExistingStudentCareStudentsRequest, options?: any) {
        return AdminApiFp(this.configuration).adminMigrateExistingStudentCareStudents(adminMigrateExistingStudentCareStudentsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userReferenceId 
     * @param {string} fromDate 
     * @param {string} toDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminMixpanelGetUserActivityFeed(userReferenceId: string, fromDate: string, toDate: string, options?: any) {
        return AdminApiFp(this.configuration).adminMixpanelGetUserActivityFeed(userReferenceId, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPinStudentNote(studentNoteId: string, options?: any) {
        return AdminApiFp(this.configuration).adminPinStudentNote(studentNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPostCourse(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminPostCourse(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPostParentNotes(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminPostParentNotes(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject2} inlineObject2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPostUserSaveCard(inlineObject2: InlineObject2, options?: any) {
        return AdminApiFp(this.configuration).adminPostUserSaveCard(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPrepareOldInvoiceData(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminPrepareOldInvoiceData(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPreparePlayschoolCheckinData(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminPreparePlayschoolCheckinData(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPutSubscriptionLines(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminPutSubscriptionLines(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReActiveSubscription(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminReActiveSubscription(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject5} inlineObject5 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReGenerateRoomBookingInvoice(inlineObject5: InlineObject5, options?: any) {
        return AdminApiFp(this.configuration).adminReGenerateRoomBookingInvoice(inlineObject5, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {boolean} [isNotSendMail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminRecordPayment(requestBody: { [key: string]: object; }, isNotSendMail?: boolean, options?: any) {
        return AdminApiFp(this.configuration).adminRecordPayment(requestBody, isNotSendMail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {boolean} [isNotSendMail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminRefundCourseSessions(requestBody: { [key: string]: object; }, isNotSendMail?: boolean, options?: any) {
        return AdminApiFp(this.configuration).adminRefundCourseSessions(requestBody, isNotSendMail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminRemoveBulkEnrol(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminRemoveBulkEnrol(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminRemoveCourseMedia(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminRemoveCourseMedia(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminRemoveRoomBookingInvoiceLine(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminRemoveRoomBookingInvoiceLine(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminResendPassword(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminResendPassword(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} roomId 
     * @param {string} courseSessionId 
     * @param {string} [sessionGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminRoomCalendar(roomId: string, courseSessionId: string, sessionGroup?: string, options?: any) {
        return AdminApiFp(this.configuration).adminRoomCalendar(roomId, courseSessionId, sessionGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [venueId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminRoomTypeSelects(venueId?: string, options?: any) {
        return AdminApiFp(this.configuration).adminRoomTypeSelects(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<AdminSendReservationLinksRequest>} adminSendReservationLinksRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSendBookingLinks(adminSendReservationLinksRequest: Array<AdminSendReservationLinksRequest>, options?: any) {
        return AdminApiFp(this.configuration).adminSendBookingLinks(adminSendReservationLinksRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSendEpAgreement(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminSendEpAgreement(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetParentCourseReminderEmailDataWithConditionRequest} getParentCourseReminderEmailDataWithConditionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSendMailParentCourseReminderWithCondition(getParentCourseReminderEmailDataWithConditionRequest: GetParentCourseReminderEmailDataWithConditionRequest, options?: any) {
        return AdminApiFp(this.configuration).adminSendMailParentCourseReminderWithCondition(getParentCourseReminderEmailDataWithConditionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSendNotifyReplacementEmail(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminSendNotifyReplacementEmail(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSendOrResendLoginInviteToParent(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminSendOrResendLoginInviteToParent(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSendReplacementEmailAndNotiToEpTeacher(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminSendReplacementEmailAndNotiToEpTeacher(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSendRoomBookingInvoiceMail(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminSendRoomBookingInvoiceMail(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSendSubscriptionMail(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminSendSubscriptionMail(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSendWithdrawTrialStudentEmail(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminSendWithdrawTrialStudentEmail(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSubmitForApprovalCourse(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminSubmitForApprovalCourse(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSubmitForApprovalCourseInstance(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminSubmitForApprovalCourseInstance(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSubmitForApprovalInvoice(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminSubmitForApprovalInvoice(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminTransferSessionPackAddition(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminTransferSessionPackAddition(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUnCheckinStudent(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUnCheckinStudent(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUnPinStudentNote(studentNoteId: string, options?: any) {
        return AdminApiFp(this.configuration).adminUnPinStudentNote(studentNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateBirthdayCertificateRequest} updateBirthdayCertificateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateBirthdayCertificate(updateBirthdayCertificateRequest: UpdateBirthdayCertificateRequest, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateBirthdayCertificate(updateBirthdayCertificateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateBulkEnrollOffPlatform(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateBulkEnrollOffPlatform(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateChild(id: string, requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateChild(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateCourseMetaData(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateCourseMetaData(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateInvoicesOverdue(options?: any) {
        return AdminApiFp(this.configuration).adminUpdateInvoicesOverdue(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateIsShareParentContact(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateIsShareParentContact(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} categoryId 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateMessageCategory(categoryId: string, requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateMessageCategory(categoryId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateOriginalSession(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateOriginalSession(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateReservationStatus(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateReservationStatus(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateRevenueCaseWithoutSelectedRefundSession(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateRevenueCaseWithoutSelectedRefundSession(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpsertContextSensitiveHelp(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpsertContextSensitiveHelp(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpsertCourseInstance(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpsertCourseInstance(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpsertCreditReason(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpsertCreditReason(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpsertDiscount(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpsertDiscount(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpsertDiscountCodes(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpsertDiscountCodes(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpsertEducationProvider(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpsertEducationProvider(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpsertKisiConfiguration(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpsertKisiConfiguration(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} educationProviderReferenceId 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpsertMember(educationProviderReferenceId: string, requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpsertMember(educationProviderReferenceId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpsertParent(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpsertParent(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpsertProduct(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpsertProduct(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpsertRolePermission(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpsertRolePermission(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpsertSessionPack(referenceId: string, requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpsertSessionPack(referenceId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpsertVenues(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminUpsertVenues(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminValidateBeforeCancelCourseInstance(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminValidateBeforeCancelCourseInstance(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminValidateChangeTeacher(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminValidateChangeTeacher(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminValidateClassUsed(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminValidateClassUsed(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<ValidateCourseSessionRequest>} validateCourseSessionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminValidateCourseSessions(validateCourseSessionRequest: Array<ValidateCourseSessionRequest>, options?: any) {
        return AdminApiFp(this.configuration).adminValidateCourseSessions(validateCourseSessionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminValidateStudentIsSignedUpCourseSeries(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminValidateStudentIsSignedUpCourseSeries(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminValidateTrialSessionRequest(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminValidateTrialSessionRequest(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminValidateUpsertReservation(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminValidateUpsertReservation(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} educationProviderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminVenueSelects(educationProviderId: string, options?: any) {
        return AdminApiFp(this.configuration).adminVenueSelects(educationProviderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminVenuesSelect(options?: any) {
        return AdminApiFp(this.configuration).adminVenuesSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminVoidInvoice(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminVoidInvoice(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminWithdrawStudentFromSubscription(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminWithdrawStudentFromSubscription(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminWithdrawTrialStudent(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).adminWithdrawTrialStudent(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public approveInvoice(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).approveInvoice(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public autoUpdateCourseAndCourseInstanceStatus(options?: any) {
        return AdminApiFp(this.configuration).autoUpdateCourseAndCourseInstanceStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public checkOverlapSession(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).checkOverlapSession(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteCourseSeries(id: string, options?: any) {
        return AdminApiFp(this.configuration).deleteCourseSeries(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} scheduledDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public enrollStudentsNextCourseInstance(scheduledDate: any, options?: any) {
        return AdminApiFp(this.configuration).enrollStudentsNextCourseInstance(scheduledDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public generateCourseReferenceID(options?: any) {
        return AdminApiFp(this.configuration).generateCourseReferenceID(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminOptionsByProviderId(providerId: string, options?: any) {
        return AdminApiFp(this.configuration).getAdminOptionsByProviderId(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getCheckinLogListBeforeUncheckin(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).getCheckinLogListBeforeUncheckin(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getCourseSeriesByCourse(courseId: string, options?: any) {
        return AdminApiFp(this.configuration).getCourseSeriesByCourse(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} seriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getCourseSeriesDetails(seriesId: string, options?: any) {
        return AdminApiFp(this.configuration).getCourseSeriesDetails(seriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getExtraRoomsBySessionId(courseSessionId: string, options?: any) {
        return AdminApiFp(this.configuration).getExtraRoomsBySessionId(courseSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getFilterUser(options?: any) {
        return AdminApiFp(this.configuration).getFilterUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getHolidaysBySession(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).getHolidaysBySession(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getMessageAuditHistories(id: string, options?: any) {
        return AdminApiFp(this.configuration).getMessageAuditHistories(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getParentListForImpersonation(search?: string, limit?: number, offset?: number, options?: any) {
        return AdminApiFp(this.configuration).getParentListForImpersonation(search, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getParentListView(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).getParentListView(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getReasonList(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).getReasonList(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getRegistrationLog(id: string, options?: any) {
        return AdminApiFp(this.configuration).getRegistrationLog(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getReplacementConfig(referenceId: string, options?: any) {
        return AdminApiFp(this.configuration).getReplacementConfig(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getReplacementSessionAvailable(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).getReplacementSessionAvailable(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getScheduleJobs(options?: any) {
        return AdminApiFp(this.configuration).getScheduleJobs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceId 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getSessionNumberById(instanceId: string, sessionId: string, options?: any) {
        return AdminApiFp(this.configuration).getSessionNumberById(instanceId, sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getStudentsSelect(options?: any) {
        return AdminApiFp(this.configuration).getStudentsSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getTeacherMOEsByTeacher(teacherId: string, options?: any) {
        return AdminApiFp(this.configuration).getTeacherMOEsByTeacher(teacherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} searchKey 
     * @param {string} filterStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getUsersAdmin(searchKey: string, filterStatus: string, options?: any) {
        return AdminApiFp(this.configuration).getUsersAdmin(searchKey, filterStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public importEPGEnrolment(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).importEPGEnrolment(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MigrateParentEmergencyContactRequest} migrateParentEmergencyContactRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public migrateParentEmergencyContact(migrateParentEmergencyContactRequest: MigrateParentEmergencyContactRequest, options?: any) {
        return AdminApiFp(this.configuration).migrateParentEmergencyContact(migrateParentEmergencyContactRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminPostCourseSeriesRequest} adminPostCourseSeriesRequest 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postCourseSeries(adminPostCourseSeriesRequest: AdminPostCourseSeriesRequest, date?: string, options?: any) {
        return AdminApiFp(this.configuration).postCourseSeries(adminPostCourseSeriesRequest, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject6} inlineObject6 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public processOldInvoiceVoided(inlineObject6: InlineObject6, options?: any) {
        return AdminApiFp(this.configuration).processOldInvoiceVoided(inlineObject6, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invoiceLineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public refundInvoiceLine(invoiceLineId: string, options?: any) {
        return AdminApiFp(this.configuration).refundInvoiceLine(invoiceLineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public removeReason(id: string, options?: any) {
        return AdminApiFp(this.configuration).removeReason(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public syncPostmarkMessagesDetails(limit: number, options?: any) {
        return AdminApiFp(this.configuration).syncPostmarkMessagesDetails(limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {boolean} [skipValidation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateCourseSession(requestBody: { [key: string]: object; }, skipValidation?: boolean, options?: any) {
        return AdminApiFp(this.configuration).updateCourseSession(requestBody, skipValidation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateUserRole(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).updateUserRole(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateUserStatus(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).updateUserStatus(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public upsertNoteTypes(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).upsertNoteTypes(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public upsertReason(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).upsertReason(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public upsertTeacherMOE(teacherId: string, requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).upsertTeacherMOE(teacherId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public upsertTeacherMOEArea(requestBody: { [key: string]: object; }, options?: any) {
        return AdminApiFp(this.configuration).upsertTeacherMOEArea(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgoraCaresApi - axios parameter creator
 * @export
 */
export const AgoraCaresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscriptionPlan: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/agora-cares/cancel-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargeAgoraHours: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('chargeAgoraHours', 'requestBody', requestBody)
            const localVarPath = `/agora-cares/charge-agora-hours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraAccessHeader: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/agora-cares/agora-access-header`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {boolean} [isSubscription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraCareHours: async (userId: string, isSubscription?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAgoraCareHours', 'userId', userId)
            const localVarPath = `/agora-cares/hours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (isSubscription !== undefined) {
                localVarQueryParameter['isSubscription'] = isSubscription;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraCarePacks: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/agora-cares`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraCareUsages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/agora-cares/usages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraSubscriptionHeader: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/agora-cares/agora-subscription-header`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraSubscriptionUsages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/agora-cares/subscription-usages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPlans: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/agora-cares/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSubscription: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/agora-cares/user-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgoraCaresApi - functional programming interface
 * @export
 */
export const AgoraCaresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgoraCaresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscriptionPlan(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscriptionPlan(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chargeAgoraHours(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chargeAgoraHours(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgoraAccessHeader(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgoraAccessHeaderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgoraAccessHeader(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {boolean} [isSubscription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgoraCareHours(userId: string, isSubscription?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgoraCareHoursResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgoraCareHours(userId, isSubscription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgoraCarePacks(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgoraCarePacksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgoraCarePacks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgoraCareUsages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgoraUsagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgoraCareUsages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgoraSubscriptionHeader(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgoraSubscriptionHeaderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgoraSubscriptionHeader(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgoraSubscriptionUsages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgoraUsagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgoraSubscriptionUsages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionPlans(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgoraCareSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionPlans(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSubscription(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserSubscriptionPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSubscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgoraCaresApi - factory interface
 * @export
 */
export const AgoraCaresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgoraCaresApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscriptionPlan(options?: any): AxiosPromise<void> {
            return localVarFp.cancelSubscriptionPlan(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargeAgoraHours(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.chargeAgoraHours(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraAccessHeader(options?: any): AxiosPromise<GetAgoraAccessHeaderResponse> {
            return localVarFp.getAgoraAccessHeader(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {boolean} [isSubscription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraCareHours(userId: string, isSubscription?: boolean, options?: any): AxiosPromise<GetAgoraCareHoursResponse> {
            return localVarFp.getAgoraCareHours(userId, isSubscription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraCarePacks(options?: any): AxiosPromise<GetAgoraCarePacksResponse> {
            return localVarFp.getAgoraCarePacks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraCareUsages(options?: any): AxiosPromise<AgoraUsagesResponse> {
            return localVarFp.getAgoraCareUsages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraSubscriptionHeader(options?: any): AxiosPromise<GetAgoraSubscriptionHeaderResponse> {
            return localVarFp.getAgoraSubscriptionHeader(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraSubscriptionUsages(options?: any): AxiosPromise<AgoraUsagesResponse> {
            return localVarFp.getAgoraSubscriptionUsages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPlans(options?: any): AxiosPromise<GetAgoraCareSubscriptionResponse> {
            return localVarFp.getSubscriptionPlans(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSubscription(options?: any): AxiosPromise<GetUserSubscriptionPlanResponse> {
            return localVarFp.getUserSubscription(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgoraCaresApi - object-oriented interface
 * @export
 * @class AgoraCaresApi
 * @extends {BaseAPI}
 */
export class AgoraCaresApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgoraCaresApi
     */
    public cancelSubscriptionPlan(options?: any) {
        return AgoraCaresApiFp(this.configuration).cancelSubscriptionPlan(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgoraCaresApi
     */
    public chargeAgoraHours(requestBody: { [key: string]: object; }, options?: any) {
        return AgoraCaresApiFp(this.configuration).chargeAgoraHours(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgoraCaresApi
     */
    public getAgoraAccessHeader(options?: any) {
        return AgoraCaresApiFp(this.configuration).getAgoraAccessHeader(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {boolean} [isSubscription] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgoraCaresApi
     */
    public getAgoraCareHours(userId: string, isSubscription?: boolean, options?: any) {
        return AgoraCaresApiFp(this.configuration).getAgoraCareHours(userId, isSubscription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgoraCaresApi
     */
    public getAgoraCarePacks(options?: any) {
        return AgoraCaresApiFp(this.configuration).getAgoraCarePacks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgoraCaresApi
     */
    public getAgoraCareUsages(options?: any) {
        return AgoraCaresApiFp(this.configuration).getAgoraCareUsages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgoraCaresApi
     */
    public getAgoraSubscriptionHeader(options?: any) {
        return AgoraCaresApiFp(this.configuration).getAgoraSubscriptionHeader(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgoraCaresApi
     */
    public getAgoraSubscriptionUsages(options?: any) {
        return AgoraCaresApiFp(this.configuration).getAgoraSubscriptionUsages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgoraCaresApi
     */
    public getSubscriptionPlans(options?: any) {
        return AgoraCaresApiFp(this.configuration).getSubscriptionPlans(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgoraCaresApi
     */
    public getUserSubscription(options?: any) {
        return AgoraCaresApiFp(this.configuration).getUserSubscription(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AtSchoolsApi - axios parameter creator
 * @export
 */
export const AtSchoolsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} schoolSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAtSchoolCourseFilterData: async (schoolSlug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolSlug' is not null or undefined
            assertParamExists('getAtSchoolCourseFilterData', 'schoolSlug', schoolSlug)
            const localVarPath = `/at-schools/course-filter-data/{schoolSlug}`
                .replace(`{${"schoolSlug"}}`, encodeURIComponent(String(schoolSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} schoolSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseHeroData: async (schoolSlug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolSlug' is not null or undefined
            assertParamExists('getCourseHeroData', 'schoolSlug', schoolSlug)
            const localVarPath = `/at-schools/course-hero-data/{schoolSlug}`
                .replace(`{${"schoolSlug"}}`, encodeURIComponent(String(schoolSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchools: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/at-schools/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchoolsByParentId: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/at-schools/header`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerToAtSchool: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('registerToAtSchool', 'requestBody', requestBody)
            const localVarPath = `/at-schools/register-to-at-school`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AtSchoolsApi - functional programming interface
 * @export
 */
export const AtSchoolsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AtSchoolsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} schoolSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAtSchoolCourseFilterData(schoolSlug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAtSchoolCourseFilterDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAtSchoolCourseFilterData(schoolSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} schoolSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseHeroData(schoolSlug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseHeroDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseHeroData(schoolSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchools(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AtSchoolGetSchoolListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchools(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchoolsByParentId(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAtSchoolHeaderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchoolsByParentId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerToAtSchool(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerToAtSchool(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AtSchoolsApi - factory interface
 * @export
 */
export const AtSchoolsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AtSchoolsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} schoolSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAtSchoolCourseFilterData(schoolSlug: string, options?: any): AxiosPromise<GetAtSchoolCourseFilterDataResponse> {
            return localVarFp.getAtSchoolCourseFilterData(schoolSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} schoolSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseHeroData(schoolSlug: string, options?: any): AxiosPromise<GetCourseHeroDataResponse> {
            return localVarFp.getCourseHeroData(schoolSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchools(options?: any): AxiosPromise<AtSchoolGetSchoolListResponse> {
            return localVarFp.getSchools(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchoolsByParentId(options?: any): AxiosPromise<GetAtSchoolHeaderResponse> {
            return localVarFp.getSchoolsByParentId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerToAtSchool(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.registerToAtSchool(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AtSchoolsApi - object-oriented interface
 * @export
 * @class AtSchoolsApi
 * @extends {BaseAPI}
 */
export class AtSchoolsApi extends BaseAPI {
    /**
     * 
     * @param {string} schoolSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtSchoolsApi
     */
    public getAtSchoolCourseFilterData(schoolSlug: string, options?: any) {
        return AtSchoolsApiFp(this.configuration).getAtSchoolCourseFilterData(schoolSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} schoolSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtSchoolsApi
     */
    public getCourseHeroData(schoolSlug: string, options?: any) {
        return AtSchoolsApiFp(this.configuration).getCourseHeroData(schoolSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtSchoolsApi
     */
    public getSchools(options?: any) {
        return AtSchoolsApiFp(this.configuration).getSchools(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtSchoolsApi
     */
    public getSchoolsByParentId(options?: any) {
        return AtSchoolsApiFp(this.configuration).getSchoolsByParentId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtSchoolsApi
     */
    public registerToAtSchool(requestBody: { [key: string]: object; }, options?: any) {
        return AtSchoolsApiFp(this.configuration).registerToAtSchool(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AttendancesApi - axios parameter creator
 * @export
 */
export const AttendancesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} studentIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttendanceHistory: async (studentIds: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentIds' is not null or undefined
            assertParamExists('getAttendanceHistory', 'studentIds', studentIds)
            const localVarPath = `/attendances/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (studentIds) {
                localVarQueryParameter['studentIds'] = studentIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttendancesApi - functional programming interface
 * @export
 */
export const AttendancesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttendancesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} studentIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttendanceHistory(studentIds: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAttendanceHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttendanceHistory(studentIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttendancesApi - factory interface
 * @export
 */
export const AttendancesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttendancesApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} studentIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttendanceHistory(studentIds: Array<string>, options?: any): AxiosPromise<GetAttendanceHistoryResponse> {
            return localVarFp.getAttendanceHistory(studentIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttendancesApi - object-oriented interface
 * @export
 * @class AttendancesApi
 * @extends {BaseAPI}
 */
export class AttendancesApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} studentIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttendancesApi
     */
    public getAttendanceHistory(studentIds: Array<string>, options?: any) {
        return AttendancesApiFp(this.configuration).getAttendanceHistory(studentIds, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPassword: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('confirmPassword', 'requestBody', requestBody)
            const localVarPath = `/auth/confirm-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceToken: async (tokenId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('deleteDeviceToken', 'tokenId', tokenId)
            const localVarPath = `/auth/device-token/{tokenId}`
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('forgotPassword', 'requestBody', requestBody)
            const localVarPath = `/auth/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('login', 'requestBody', requestBody)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChallenge: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('passwordChallenge', 'requestBody', requestBody)
            const localVarPath = `/auth/password-challenge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordReset: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('passwordReset', 'requestBody', requestBody)
            const localVarPath = `/auth/password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRefreshToken: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postRefreshToken', 'requestBody', requestBody)
            const localVarPath = `/auth/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('signUp', 'requestBody', requestBody)
            const localVarPath = `/auth/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateGoogleRecaptchaToken: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('validateGoogleRecaptchaToken', 'token', token)
            const localVarPath = `/auth/validate/google-recaptcha-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmPassword(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmPassword(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeviceToken(tokenId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeviceToken(tokenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordChallenge(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordChallenge(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordReset(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordReset(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRefreshToken(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRefreshToken(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUp(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignUpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signUp(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateGoogleRecaptchaToken(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateGoogleRecaptchaToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPassword(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<boolean> {
            return localVarFp.confirmPassword(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceToken(tokenId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDeviceToken(tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<boolean> {
            return localVarFp.forgotPassword(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.login(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChallenge(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<boolean> {
            return localVarFp.passwordChallenge(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordReset(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PasswordResetResponse> {
            return localVarFp.passwordReset(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRefreshToken(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.postRefreshToken(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<SignUpResponse> {
            return localVarFp.signUp(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateGoogleRecaptchaToken(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.validateGoogleRecaptchaToken(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public confirmPassword(requestBody: { [key: string]: object; }, options?: any) {
        return AuthApiFp(this.configuration).confirmPassword(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tokenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public deleteDeviceToken(tokenId: string, options?: any) {
        return AuthApiFp(this.configuration).deleteDeviceToken(tokenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public forgotPassword(requestBody: { [key: string]: object; }, options?: any) {
        return AuthApiFp(this.configuration).forgotPassword(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(requestBody: { [key: string]: object; }, options?: any) {
        return AuthApiFp(this.configuration).login(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public passwordChallenge(requestBody: { [key: string]: object; }, options?: any) {
        return AuthApiFp(this.configuration).passwordChallenge(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public passwordReset(requestBody: { [key: string]: object; }, options?: any) {
        return AuthApiFp(this.configuration).passwordReset(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postRefreshToken(requestBody: { [key: string]: object; }, options?: any) {
        return AuthApiFp(this.configuration).postRefreshToken(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signUp(requestBody: { [key: string]: object; }, options?: any) {
        return AuthApiFp(this.configuration).signUp(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public validateGoogleRecaptchaToken(token: string, options?: any) {
        return AuthApiFp(this.configuration).validateGoogleRecaptchaToken(token, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BillingOrganisationsApi - axios parameter creator
 * @export
 */
export const BillingOrganisationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingOrganisation: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createBillingOrganisation', 'requestBody', requestBody)
            const localVarPath = `/billing-organisations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billingId 
         * @param {PartialCreateBillingOrganisationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBillingOrganisation: async (billingId: string, body: PartialCreateBillingOrganisationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingId' is not null or undefined
            assertParamExists('editBillingOrganisation', 'billingId', billingId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('editBillingOrganisation', 'body', body)
            const localVarPath = `/billing-organisations/{billingId}`
                .replace(`{${"billingId"}}`, encodeURIComponent(String(billingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingOrganisationById: async (billingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingId' is not null or undefined
            assertParamExists('getBillingOrganisationById', 'billingId', billingId)
            const localVarPath = `/billing-organisations/{billingId}`
                .replace(`{${"billingId"}}`, encodeURIComponent(String(billingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isUsedByVenue] 
         * @param {boolean} [isGetPaymentMethods] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingOrganisations: async (isUsedByVenue?: boolean, isGetPaymentMethods?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/billing-organisations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (isUsedByVenue !== undefined) {
                localVarQueryParameter['is_used_by_venue'] = isUsedByVenue;
            }

            if (isGetPaymentMethods !== undefined) {
                localVarQueryParameter['is_get_payment_methods'] = isGetPaymentMethods;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingOrganisationsApi - functional programming interface
 * @export
 */
export const BillingOrganisationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingOrganisationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBillingOrganisation(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBillingOrganisationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBillingOrganisation(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billingId 
         * @param {PartialCreateBillingOrganisationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editBillingOrganisation(billingId: string, body: PartialCreateBillingOrganisationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBillingOrganisationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editBillingOrganisation(billingId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBillingOrganisationById(billingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBillingOrganisationByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBillingOrganisationById(billingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [isUsedByVenue] 
         * @param {boolean} [isGetPaymentMethods] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBillingOrganisations(isUsedByVenue?: boolean, isGetPaymentMethods?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBillingOrganisationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBillingOrganisations(isUsedByVenue, isGetPaymentMethods, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingOrganisationsApi - factory interface
 * @export
 */
export const BillingOrganisationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingOrganisationsApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingOrganisation(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<CreateBillingOrganisationResponse> {
            return localVarFp.createBillingOrganisation(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billingId 
         * @param {PartialCreateBillingOrganisationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBillingOrganisation(billingId: string, body: PartialCreateBillingOrganisationDto, options?: any): AxiosPromise<CreateBillingOrganisationResponse> {
            return localVarFp.editBillingOrganisation(billingId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingOrganisationById(billingId: string, options?: any): AxiosPromise<GetBillingOrganisationByIdResponse> {
            return localVarFp.getBillingOrganisationById(billingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isUsedByVenue] 
         * @param {boolean} [isGetPaymentMethods] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingOrganisations(isUsedByVenue?: boolean, isGetPaymentMethods?: boolean, options?: any): AxiosPromise<GetBillingOrganisationsResponse> {
            return localVarFp.getBillingOrganisations(isUsedByVenue, isGetPaymentMethods, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillingOrganisationsApi - object-oriented interface
 * @export
 * @class BillingOrganisationsApi
 * @extends {BaseAPI}
 */
export class BillingOrganisationsApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingOrganisationsApi
     */
    public createBillingOrganisation(requestBody: { [key: string]: object; }, options?: any) {
        return BillingOrganisationsApiFp(this.configuration).createBillingOrganisation(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billingId 
     * @param {PartialCreateBillingOrganisationDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingOrganisationsApi
     */
    public editBillingOrganisation(billingId: string, body: PartialCreateBillingOrganisationDto, options?: any) {
        return BillingOrganisationsApiFp(this.configuration).editBillingOrganisation(billingId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingOrganisationsApi
     */
    public getBillingOrganisationById(billingId: string, options?: any) {
        return BillingOrganisationsApiFp(this.configuration).getBillingOrganisationById(billingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isUsedByVenue] 
     * @param {boolean} [isGetPaymentMethods] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingOrganisationsApi
     */
    public getBillingOrganisations(isUsedByVenue?: boolean, isGetPaymentMethods?: boolean, options?: any) {
        return BillingOrganisationsApiFp(this.configuration).getBillingOrganisations(isUsedByVenue, isGetPaymentMethods, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BookingsApi - axios parameter creator
 * @export
 */
export const BookingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingWith: async (courseInstanceId: string, childId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseInstanceId' is not null or undefined
            assertParamExists('getBookingWith', 'courseInstanceId', courseInstanceId)
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getBookingWith', 'childId', childId)
            const localVarPath = `/bookings/{courseInstanceId}`
                .replace(`{${"courseInstanceId"}}`, encodeURIComponent(String(courseInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [courseInstanceId] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservedSeats: async (courseInstanceId?: string, search?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/bookings/reserved-seats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (courseInstanceId !== undefined) {
                localVarQueryParameter['course_instance_id'] = courseInstanceId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingsApi - functional programming interface
 * @export
 */
export const BookingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingWith(courseInstanceId: string, childId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBookingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingWith(courseInstanceId, childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [courseInstanceId] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReservedSeats(courseInstanceId?: string, search?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetReservedSeatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReservedSeats(courseInstanceId, search, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingsApi - factory interface
 * @export
 */
export const BookingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingWith(courseInstanceId: string, childId: string, options?: any): AxiosPromise<GetBookingResponse> {
            return localVarFp.getBookingWith(courseInstanceId, childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [courseInstanceId] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservedSeats(courseInstanceId?: string, search?: string, limit?: number, offset?: number, options?: any): AxiosPromise<IGetReservedSeatsResponse> {
            return localVarFp.getReservedSeats(courseInstanceId, search, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingsApi - object-oriented interface
 * @export
 * @class BookingsApi
 * @extends {BaseAPI}
 */
export class BookingsApi extends BaseAPI {
    /**
     * 
     * @param {string} courseInstanceId 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public getBookingWith(courseInstanceId: string, childId: string, options?: any) {
        return BookingsApiFp(this.configuration).getBookingWith(courseInstanceId, childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [courseInstanceId] 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public getReservedSeats(courseInstanceId?: string, search?: string, limit?: number, offset?: number, options?: any) {
        return BookingsApiFp(this.configuration).getReservedSeats(courseInstanceId, search, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrandsApi - axios parameter creator
 * @export
 */
export const BrandsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrand: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createBrand', 'requestBody', requestBody)
            const localVarPath = `/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandDetailsById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBrandDetailsById', 'id', id)
            const localVarPath = `/brands/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Array<string>} [brandIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands: async (search?: string, limit?: number, offset?: number, brandIds?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids'] = brandIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} templateAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplateDetails: async (id: string, templateAlias: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEmailTemplateDetails', 'id', id)
            // verify required parameter 'templateAlias' is not null or undefined
            assertParamExists('getEmailTemplateDetails', 'templateAlias', templateAlias)
            const localVarPath = `/brands/{id}/email-templates/{templateAlias}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"templateAlias"}}`, encodeURIComponent(String(templateAlias)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [search] 
         * @param {Array<string>} [aliasPrefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplatesByBrand: async (id: string, search?: string, aliasPrefix?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEmailTemplatesByBrand', 'id', id)
            const localVarPath = `/brands/{id}/email-templates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (aliasPrefix) {
                localVarQueryParameter['alias-prefix'] = aliasPrefix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialCreateBrandDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBrand: async (id: string, body: PartialCreateBrandDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBrand', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateBrand', 'body', body)
            const localVarPath = `/brands/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandsApi - functional programming interface
 * @export
 */
export const BrandsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBrand(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICreateBrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBrand(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrandDetailsById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetBrandDetailByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandDetailsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Array<string>} [brandIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrands(search?: string, limit?: number, offset?: number, brandIds?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetBrandsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrands(search, limit, offset, brandIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} templateAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailTemplateDetails(id: string, templateAlias: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEmailTemplatesDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailTemplateDetails(id, templateAlias, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [search] 
         * @param {Array<string>} [aliasPrefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailTemplatesByBrand(id: string, search?: string, aliasPrefix?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEmailTemplatesByBrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailTemplatesByBrand(id, search, aliasPrefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialCreateBrandDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBrand(id: string, body: PartialCreateBrandDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICreateBrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBrand(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandsApi - factory interface
 * @export
 */
export const BrandsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandsApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrand(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<ICreateBrandResponse> {
            return localVarFp.createBrand(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandDetailsById(id: string, options?: any): AxiosPromise<IGetBrandDetailByIdResponse> {
            return localVarFp.getBrandDetailsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Array<string>} [brandIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands(search?: string, limit?: number, offset?: number, brandIds?: Array<string>, options?: any): AxiosPromise<IGetBrandsResponse> {
            return localVarFp.getBrands(search, limit, offset, brandIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} templateAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplateDetails(id: string, templateAlias: string, options?: any): AxiosPromise<GetEmailTemplatesDetailsResponse> {
            return localVarFp.getEmailTemplateDetails(id, templateAlias, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [search] 
         * @param {Array<string>} [aliasPrefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplatesByBrand(id: string, search?: string, aliasPrefix?: Array<string>, options?: any): AxiosPromise<GetEmailTemplatesByBrandResponse> {
            return localVarFp.getEmailTemplatesByBrand(id, search, aliasPrefix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialCreateBrandDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBrand(id: string, body: PartialCreateBrandDto, options?: any): AxiosPromise<ICreateBrandResponse> {
            return localVarFp.updateBrand(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandsApi - object-oriented interface
 * @export
 * @class BrandsApi
 * @extends {BaseAPI}
 */
export class BrandsApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApi
     */
    public createBrand(requestBody: { [key: string]: object; }, options?: any) {
        return BrandsApiFp(this.configuration).createBrand(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApi
     */
    public getBrandDetailsById(id: string, options?: any) {
        return BrandsApiFp(this.configuration).getBrandDetailsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {Array<string>} [brandIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApi
     */
    public getBrands(search?: string, limit?: number, offset?: number, brandIds?: Array<string>, options?: any) {
        return BrandsApiFp(this.configuration).getBrands(search, limit, offset, brandIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} templateAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApi
     */
    public getEmailTemplateDetails(id: string, templateAlias: string, options?: any) {
        return BrandsApiFp(this.configuration).getEmailTemplateDetails(id, templateAlias, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [search] 
     * @param {Array<string>} [aliasPrefix] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApi
     */
    public getEmailTemplatesByBrand(id: string, search?: string, aliasPrefix?: Array<string>, options?: any) {
        return BrandsApiFp(this.configuration).getEmailTemplatesByBrand(id, search, aliasPrefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PartialCreateBrandDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApi
     */
    public updateBrand(id: string, body: PartialCreateBrandDto, options?: any) {
        return BrandsApiFp(this.configuration).updateBrand(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CampaignsApi - axios parameter creator
 * @export
 */
export const CampaignsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCampaignHistory: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addCampaignHistory', 'requestBody', requestBody)
            const localVarPath = `/campaigns/campaign-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} promoCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignByCode: async (promoCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'promoCode' is not null or undefined
            assertParamExists('getCampaignByCode', 'promoCode', promoCode)
            const localVarPath = `/campaigns/campaign-by-code/{promoCode}`
                .replace(`{${"promoCode"}}`, encodeURIComponent(String(promoCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaigns: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentGiveGetCampaignActive: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/campaigns/current-give-get-campaign-active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentGiveGetCampaignActiveWithSecurity: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/campaigns/current-give-get-campaign-active-with-security`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveAndGetCampaign: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/campaigns/give-get-campaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveAndGetCampaignWithSecurity: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/campaigns/give-get-campaign-with-security`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinCheckoutToApplied: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/campaigns/min-checkout-to-applied`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentBalanceCredit: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/campaigns/get-balance-credit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromoList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/campaigns/promo-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTitleContentShowcaseCampaignPanel: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/campaigns/title-content-campaign-showcase-panel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} promoCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWelcomeMessage: async (promoCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'promoCode' is not null or undefined
            assertParamExists('getWelcomeMessage', 'promoCode', promoCode)
            const localVarPath = `/campaigns/welcome-message/{promoCode}`
                .replace(`{${"promoCode"}}`, encodeURIComponent(String(promoCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignsApi - functional programming interface
 * @export
 */
export const CampaignsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCampaignHistory(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCampaignHistory(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} promoCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignByCode(promoCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignByCode(promoCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaigns(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCampaignsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaigns(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentGiveGetCampaignActive(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentGiveGetCampaignActive(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentGiveGetCampaignActiveWithSecurity(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentGiveGetCampaignActiveWithSecurity(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGiveAndGetCampaign(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGiveAndGetCampaign(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGiveAndGetCampaignWithSecurity(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGiveAndGetCampaignWithSecurity(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMinCheckoutToApplied(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMinCheckoutToAppliedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMinCheckoutToApplied(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentBalanceCredit(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBalanceCreditAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentBalanceCredit(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromoList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPromoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromoList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTitleContentShowcaseCampaignPanel(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCampaignShowcasePanelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTitleContentShowcaseCampaignPanel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} promoCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWelcomeMessage(promoCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWelcomeMessage(promoCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignsApi - factory interface
 * @export
 */
export const CampaignsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignsApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCampaignHistory(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.addCampaignHistory(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} promoCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignByCode(promoCode: string, options?: any): AxiosPromise<GetCampaignResponse> {
            return localVarFp.getCampaignByCode(promoCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaigns(options?: any): AxiosPromise<GetCampaignsResponse> {
            return localVarFp.getCampaigns(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentGiveGetCampaignActive(options?: any): AxiosPromise<GetCampaignResponse> {
            return localVarFp.getCurrentGiveGetCampaignActive(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentGiveGetCampaignActiveWithSecurity(options?: any): AxiosPromise<GetCampaignResponse> {
            return localVarFp.getCurrentGiveGetCampaignActiveWithSecurity(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveAndGetCampaign(options?: any): AxiosPromise<GetCampaignResponse> {
            return localVarFp.getGiveAndGetCampaign(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveAndGetCampaignWithSecurity(options?: any): AxiosPromise<GetCampaignResponse> {
            return localVarFp.getGiveAndGetCampaignWithSecurity(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinCheckoutToApplied(options?: any): AxiosPromise<GetMinCheckoutToAppliedResponse> {
            return localVarFp.getMinCheckoutToApplied(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentBalanceCredit(options?: any): AxiosPromise<GetBalanceCreditAmountResponse> {
            return localVarFp.getParentBalanceCredit(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromoList(options?: any): AxiosPromise<GetPromoListResponse> {
            return localVarFp.getPromoList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTitleContentShowcaseCampaignPanel(options?: any): AxiosPromise<GetCampaignShowcasePanelResponse> {
            return localVarFp.getTitleContentShowcaseCampaignPanel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} promoCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWelcomeMessage(promoCode: string, options?: any): AxiosPromise<string> {
            return localVarFp.getWelcomeMessage(promoCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignsApi - object-oriented interface
 * @export
 * @class CampaignsApi
 * @extends {BaseAPI}
 */
export class CampaignsApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public addCampaignHistory(requestBody: { [key: string]: object; }, options?: any) {
        return CampaignsApiFp(this.configuration).addCampaignHistory(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} promoCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getCampaignByCode(promoCode: string, options?: any) {
        return CampaignsApiFp(this.configuration).getCampaignByCode(promoCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getCampaigns(options?: any) {
        return CampaignsApiFp(this.configuration).getCampaigns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getCurrentGiveGetCampaignActive(options?: any) {
        return CampaignsApiFp(this.configuration).getCurrentGiveGetCampaignActive(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getCurrentGiveGetCampaignActiveWithSecurity(options?: any) {
        return CampaignsApiFp(this.configuration).getCurrentGiveGetCampaignActiveWithSecurity(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getGiveAndGetCampaign(options?: any) {
        return CampaignsApiFp(this.configuration).getGiveAndGetCampaign(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getGiveAndGetCampaignWithSecurity(options?: any) {
        return CampaignsApiFp(this.configuration).getGiveAndGetCampaignWithSecurity(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getMinCheckoutToApplied(options?: any) {
        return CampaignsApiFp(this.configuration).getMinCheckoutToApplied(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getParentBalanceCredit(options?: any) {
        return CampaignsApiFp(this.configuration).getParentBalanceCredit(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getPromoList(options?: any) {
        return CampaignsApiFp(this.configuration).getPromoList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getTitleContentShowcaseCampaignPanel(options?: any) {
        return CampaignsApiFp(this.configuration).getTitleContentShowcaseCampaignPanel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} promoCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getWelcomeMessage(promoCode: string, options?: any) {
        return CampaignsApiFp(this.configuration).getWelcomeMessage(promoCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} venueSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCurrentUserToVenue: async (venueSlug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueSlug' is not null or undefined
            assertParamExists('addCurrentUserToVenue', 'venueSlug', venueSlug)
            const localVarPath = `/companies/{venueSlug}/users/register`
                .replace(`{${"venueSlug"}}`, encodeURIComponent(String(venueSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkVenueExistence: async (venueSlug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueSlug' is not null or undefined
            assertParamExists('checkVenueExistence', 'venueSlug', venueSlug)
            const localVarPath = `/companies/{venueSlug}/exist`
                .replace(`{${"venueSlug"}}`, encodeURIComponent(String(venueSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} venueSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCurrentUserToVenue(venueSlug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCurrentUserToVenue(venueSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkVenueExistence(venueSlug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckVenueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkVenueExistence(venueSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} venueSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCurrentUserToVenue(venueSlug: string, options?: any): AxiosPromise<void> {
            return localVarFp.addCurrentUserToVenue(venueSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkVenueExistence(venueSlug: string, options?: any): AxiosPromise<CheckVenueResponse> {
            return localVarFp.checkVenueExistence(venueSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany(options?: any): AxiosPromise<GetCompanyResponse> {
            return localVarFp.getCompany(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * 
     * @param {string} venueSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public addCurrentUserToVenue(venueSlug: string, options?: any) {
        return CompaniesApiFp(this.configuration).addCurrentUserToVenue(venueSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public checkVenueExistence(venueSlug: string, options?: any) {
        return CompaniesApiFp(this.configuration).checkVenueExistence(venueSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getCompany(options?: any) {
        return CompaniesApiFp(this.configuration).getCompany(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CourseInstancesApi - axios parameter creator
 * @export
 */
export const CourseInstancesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [courseInstanceReferenceId] 
         * @param {string} [courseSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoUpdateStatus: async (courseInstanceReferenceId?: string, courseSlug?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/course-instances/auto-update-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (courseInstanceReferenceId !== undefined) {
                localVarQueryParameter['courseInstanceReferenceId'] = courseInstanceReferenceId;
            }

            if (courseSlug !== undefined) {
                localVarQueryParameter['courseSlug'] = courseSlug;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseInstancePayment: async (courseInstanceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseInstanceId' is not null or undefined
            assertParamExists('getCourseInstancePayment', 'courseInstanceId', courseInstanceId)
            const localVarPath = `/course-instances/{courseInstanceId}`
                .replace(`{${"courseInstanceId"}}`, encodeURIComponent(String(courseInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [venueId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseInstances: async (search?: string, venueId?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/course-instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (venueId !== undefined) {
                localVarQueryParameter['venue_id'] = venueId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseInstancesApi - functional programming interface
 * @export
 */
export const CourseInstancesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CourseInstancesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [courseInstanceReferenceId] 
         * @param {string} [courseSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoUpdateStatus(courseInstanceReferenceId?: string, courseSlug?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoUpdateStatus(courseInstanceReferenceId, courseSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseInstancePayment(courseInstanceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseInstancePaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseInstancePayment(courseInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [venueId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseInstances(search?: string, venueId?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseInstances(search, venueId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CourseInstancesApi - factory interface
 * @export
 */
export const CourseInstancesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CourseInstancesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [courseInstanceReferenceId] 
         * @param {string} [courseSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoUpdateStatus(courseInstanceReferenceId?: string, courseSlug?: string, options?: any): AxiosPromise<void> {
            return localVarFp.autoUpdateStatus(courseInstanceReferenceId, courseSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseInstancePayment(courseInstanceId: string, options?: any): AxiosPromise<GetCourseInstancePaymentResponse> {
            return localVarFp.getCourseInstancePayment(courseInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [venueId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseInstances(search?: string, venueId?: string, limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.getCourseInstances(search, venueId, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CourseInstancesApi - object-oriented interface
 * @export
 * @class CourseInstancesApi
 * @extends {BaseAPI}
 */
export class CourseInstancesApi extends BaseAPI {
    /**
     * 
     * @param {string} [courseInstanceReferenceId] 
     * @param {string} [courseSlug] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseInstancesApi
     */
    public autoUpdateStatus(courseInstanceReferenceId?: string, courseSlug?: string, options?: any) {
        return CourseInstancesApiFp(this.configuration).autoUpdateStatus(courseInstanceReferenceId, courseSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseInstancesApi
     */
    public getCourseInstancePayment(courseInstanceId: string, options?: any) {
        return CourseInstancesApiFp(this.configuration).getCourseInstancePayment(courseInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {string} [venueId] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseInstancesApi
     */
    public getCourseInstances(search?: string, venueId?: string, limit?: number, offset?: number, options?: any) {
        return CourseInstancesApiFp(this.configuration).getCourseInstances(search, venueId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoursesApi - axios parameter creator
 * @export
 */
export const CoursesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [slug] 
         * @param {string} [courseInstanceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRedirectParams: async (slug?: string, courseInstanceId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/check-redirect-params`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (courseInstanceId !== undefined) {
                localVarQueryParameter['courseInstanceId'] = courseInstanceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkingIsPaymentCourse: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkingIsPaymentCourse', 'id', id)
            const localVarPath = `/courses/is-payment-course/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [enroledStudentId] 
         * @param {boolean} [ticketCreatable] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCourses: async (enroledStudentId?: string, ticketCreatable?: boolean, search?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (enroledStudentId !== undefined) {
                localVarQueryParameter['enroled_student_id'] = enroledStudentId;
            }

            if (ticketCreatable !== undefined) {
                localVarQueryParameter['ticket_creatable'] = ticketCreatable;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarCourseSessionsWithFilter: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getCalendarCourseSessionsWithFilter', 'requestBody', requestBody)
            const localVarPath = `/courses/calendar-course-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildCourses: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getChildCourses', 'requestBody', requestBody)
            const localVarPath = `/courses/child-courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getCourse', 'requestBody', requestBody)
            const localVarPath = `/courses/course/course-detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseAttendanceOptionsById: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCourseAttendanceOptionsById', 'courseId', courseId)
            const localVarPath = `/courses/{courseId}/attendance-options`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [subjectId] 
         * @param {string} [educationProviderId] 
         * @param {string} [minAge] 
         * @param {string} [maxAge] 
         * @param {string} [gradeLevelId] 
         * @param {string} [coursePeriodId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseCalendars: async (startTime: string, endTime: string, subjectId?: string, educationProviderId?: string, minAge?: string, maxAge?: string, gradeLevelId?: string, coursePeriodId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getCourseCalendars', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getCourseCalendars', 'endTime', endTime)
            const localVarPath = `/courses/calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (subjectId !== undefined) {
                localVarQueryParameter['subjectId'] = subjectId;
            }

            if (educationProviderId !== undefined) {
                localVarQueryParameter['educationProviderId'] = educationProviderId;
            }

            if (minAge !== undefined) {
                localVarQueryParameter['minAge'] = minAge;
            }

            if (maxAge !== undefined) {
                localVarQueryParameter['maxAge'] = maxAge;
            }

            if (gradeLevelId !== undefined) {
                localVarQueryParameter['gradeLevelId'] = gradeLevelId;
            }

            if (coursePeriodId !== undefined) {
                localVarQueryParameter['coursePeriodId'] = coursePeriodId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseCheckoutOptionsById: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCourseCheckoutOptionsById', 'courseId', courseId)
            const localVarPath = `/courses/{courseId}/check-out-options`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseEventsHomepage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/course-events-homepage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseListWithFilterRefactor: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getCourseListWithFilterRefactor', 'requestBody', requestBody)
            const localVarPath = `/courses/course-list-filtered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseRelated: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getCourseRelated', 'requestBody', requestBody)
            const localVarPath = `/courses/course-related`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursesByChild: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/child-courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursesPrivate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/courses-private`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [courseId] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursesRecommend: async (courseId?: string, timezone?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/recommend-course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursesSitemap: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/courses-sitemap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursesSupportData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/support-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} packId 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionPackDetail: async (packId: string, courseInstanceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packId' is not null or undefined
            assertParamExists('getSessionPackDetail', 'packId', packId)
            // verify required parameter 'courseInstanceId' is not null or undefined
            assertParamExists('getSessionPackDetail', 'courseInstanceId', courseInstanceId)
            const localVarPath = `/courses/session-pack-detail/{packId}/course-instance/{courseInstanceId}`
                .replace(`{${"packId"}}`, encodeURIComponent(String(packId)))
                .replace(`{${"courseInstanceId"}}`, encodeURIComponent(String(courseInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportDataEnhanced: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getSupportDataEnhanced', 'requestBody', requestBody)
            const localVarPath = `/courses/support-data-enhanced`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestCronSendFeedback: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/test-cron-send-feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCourseFilterData: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postCourseFilterData', 'requestBody', requestBody)
            const localVarPath = `/courses/course-page-filter-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFilterCoursesSessionList: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postFilterCoursesSessionList', 'requestBody', requestBody)
            const localVarPath = `/courses/course-sessions-filtered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFilteredCourseList: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postFilteredCourseList', 'requestBody', requestBody)
            const localVarPath = `/courses/filtered-courses-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRegisterCourse: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postRegisterCourse', 'requestBody', requestBody)
            const localVarPath = `/courses/register-course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoursesApi - functional programming interface
 * @export
 */
export const CoursesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoursesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [slug] 
         * @param {string} [courseInstanceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkRedirectParams(slug?: string, courseInstanceId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckRedirectParamsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkRedirectParams(slug, courseInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkingIsPaymentCourse(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckingIsPaymentCourseOrSessionPackResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkingIsPaymentCourse(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [enroledStudentId] 
         * @param {boolean} [ticketCreatable] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCourses(enroledStudentId?: string, ticketCreatable?: boolean, search?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetAllCoursesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCourses(enroledStudentId, ticketCreatable, search, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarCourseSessionsWithFilter(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetCalendarCourseSessionsWithFilterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarCourseSessionsWithFilter(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildCourses(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChildCoursesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildCourses(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourse(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourse(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseAttendanceOptionsById(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseAttendanceOptionsByIdResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseAttendanceOptionsById(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [subjectId] 
         * @param {string} [educationProviderId] 
         * @param {string} [minAge] 
         * @param {string} [maxAge] 
         * @param {string} [gradeLevelId] 
         * @param {string} [coursePeriodId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseCalendars(startTime: string, endTime: string, subjectId?: string, educationProviderId?: string, minAge?: string, maxAge?: string, gradeLevelId?: string, coursePeriodId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseCalendarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseCalendars(startTime, endTime, subjectId, educationProviderId, minAge, maxAge, gradeLevelId, coursePeriodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseCheckoutOptionsById(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseCheckoutOptionsByIdResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseCheckoutOptionsById(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseEventsHomepage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseEventsHomepageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseEventsHomepage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseListWithFilterRefactor(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetCourseListWithFilterRefactorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseListWithFilterRefactor(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseRelated(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCoursesWithResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseRelated(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoursesByChild(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseByChildResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoursesByChild(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoursesPrivate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoursesPrivate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [courseId] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoursesRecommend(courseId?: string, timezone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCoursesWithResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoursesRecommend(courseId, timezone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoursesSitemap(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoursesSitemap(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoursesSupportData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCoursesSupportDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoursesSupportData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} packId 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessionPackDetail(packId: string, courseInstanceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSessionPackDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessionPackDetail(packId, courseInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportDataEnhanced(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSupportDataEnhancedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupportDataEnhanced(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestCronSendFeedback(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestCronSendFeedback(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCourseFilterData(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostCourseFilterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCourseFilterData(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFilterCoursesSessionList(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseCalendarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFilterCoursesSessionList(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFilteredCourseList(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseWithEnhancedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFilteredCourseList(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRegisterCourse(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostRegisterCourseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRegisterCourse(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CoursesApi - factory interface
 * @export
 */
export const CoursesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoursesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [slug] 
         * @param {string} [courseInstanceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRedirectParams(slug?: string, courseInstanceId?: string, options?: any): AxiosPromise<CheckRedirectParamsDto> {
            return localVarFp.checkRedirectParams(slug, courseInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkingIsPaymentCourse(id: string, options?: any): AxiosPromise<CheckingIsPaymentCourseOrSessionPackResponse> {
            return localVarFp.checkingIsPaymentCourse(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [enroledStudentId] 
         * @param {boolean} [ticketCreatable] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCourses(enroledStudentId?: string, ticketCreatable?: boolean, search?: string, limit?: number, offset?: number, options?: any): AxiosPromise<IGetAllCoursesResponse> {
            return localVarFp.getAllCourses(enroledStudentId, ticketCreatable, search, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarCourseSessionsWithFilter(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<IGetCalendarCourseSessionsWithFilterResponse> {
            return localVarFp.getCalendarCourseSessionsWithFilter(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildCourses(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetChildCoursesResponse> {
            return localVarFp.getChildCourses(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetCourseResponse> {
            return localVarFp.getCourse(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseAttendanceOptionsById(courseId: string, options?: any): AxiosPromise<GetCourseAttendanceOptionsByIdResponseDto> {
            return localVarFp.getCourseAttendanceOptionsById(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [subjectId] 
         * @param {string} [educationProviderId] 
         * @param {string} [minAge] 
         * @param {string} [maxAge] 
         * @param {string} [gradeLevelId] 
         * @param {string} [coursePeriodId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseCalendars(startTime: string, endTime: string, subjectId?: string, educationProviderId?: string, minAge?: string, maxAge?: string, gradeLevelId?: string, coursePeriodId?: string, options?: any): AxiosPromise<GetCourseCalendarResponse> {
            return localVarFp.getCourseCalendars(startTime, endTime, subjectId, educationProviderId, minAge, maxAge, gradeLevelId, coursePeriodId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseCheckoutOptionsById(courseId: string, options?: any): AxiosPromise<GetCourseCheckoutOptionsByIdResponseDto> {
            return localVarFp.getCourseCheckoutOptionsById(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseEventsHomepage(options?: any): AxiosPromise<GetCourseEventsHomepageResponse> {
            return localVarFp.getCourseEventsHomepage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseListWithFilterRefactor(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<IGetCourseListWithFilterRefactorResponse> {
            return localVarFp.getCourseListWithFilterRefactor(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseRelated(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetCoursesWithResponse> {
            return localVarFp.getCourseRelated(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursesByChild(options?: any): AxiosPromise<GetCourseByChildResponse> {
            return localVarFp.getCoursesByChild(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursesPrivate(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getCoursesPrivate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [courseId] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursesRecommend(courseId?: string, timezone?: string, options?: any): AxiosPromise<GetCoursesWithResponse> {
            return localVarFp.getCoursesRecommend(courseId, timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursesSitemap(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getCoursesSitemap(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursesSupportData(options?: any): AxiosPromise<GetCoursesSupportDataResponse> {
            return localVarFp.getCoursesSupportData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} packId 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionPackDetail(packId: string, courseInstanceId: string, options?: any): AxiosPromise<GetSessionPackDetailResponse> {
            return localVarFp.getSessionPackDetail(packId, courseInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportDataEnhanced(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetSupportDataEnhancedResponse> {
            return localVarFp.getSupportDataEnhanced(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestCronSendFeedback(options?: any): AxiosPromise<void> {
            return localVarFp.getTestCronSendFeedback(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCourseFilterData(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PostCourseFilterResponse> {
            return localVarFp.postCourseFilterData(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFilterCoursesSessionList(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetCourseCalendarResponse> {
            return localVarFp.postFilterCoursesSessionList(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFilteredCourseList(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetCourseWithEnhancedResponse> {
            return localVarFp.postFilteredCourseList(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRegisterCourse(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PostRegisterCourseResponse> {
            return localVarFp.postRegisterCourse(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoursesApi - object-oriented interface
 * @export
 * @class CoursesApi
 * @extends {BaseAPI}
 */
export class CoursesApi extends BaseAPI {
    /**
     * 
     * @param {string} [slug] 
     * @param {string} [courseInstanceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public checkRedirectParams(slug?: string, courseInstanceId?: string, options?: any) {
        return CoursesApiFp(this.configuration).checkRedirectParams(slug, courseInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public checkingIsPaymentCourse(id: string, options?: any) {
        return CoursesApiFp(this.configuration).checkingIsPaymentCourse(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [enroledStudentId] 
     * @param {boolean} [ticketCreatable] 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getAllCourses(enroledStudentId?: string, ticketCreatable?: boolean, search?: string, limit?: number, offset?: number, options?: any) {
        return CoursesApiFp(this.configuration).getAllCourses(enroledStudentId, ticketCreatable, search, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCalendarCourseSessionsWithFilter(requestBody: { [key: string]: object; }, options?: any) {
        return CoursesApiFp(this.configuration).getCalendarCourseSessionsWithFilter(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getChildCourses(requestBody: { [key: string]: object; }, options?: any) {
        return CoursesApiFp(this.configuration).getChildCourses(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCourse(requestBody: { [key: string]: object; }, options?: any) {
        return CoursesApiFp(this.configuration).getCourse(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCourseAttendanceOptionsById(courseId: string, options?: any) {
        return CoursesApiFp(this.configuration).getCourseAttendanceOptionsById(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [subjectId] 
     * @param {string} [educationProviderId] 
     * @param {string} [minAge] 
     * @param {string} [maxAge] 
     * @param {string} [gradeLevelId] 
     * @param {string} [coursePeriodId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCourseCalendars(startTime: string, endTime: string, subjectId?: string, educationProviderId?: string, minAge?: string, maxAge?: string, gradeLevelId?: string, coursePeriodId?: string, options?: any) {
        return CoursesApiFp(this.configuration).getCourseCalendars(startTime, endTime, subjectId, educationProviderId, minAge, maxAge, gradeLevelId, coursePeriodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCourseCheckoutOptionsById(courseId: string, options?: any) {
        return CoursesApiFp(this.configuration).getCourseCheckoutOptionsById(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCourseEventsHomepage(options?: any) {
        return CoursesApiFp(this.configuration).getCourseEventsHomepage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCourseListWithFilterRefactor(requestBody: { [key: string]: object; }, options?: any) {
        return CoursesApiFp(this.configuration).getCourseListWithFilterRefactor(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCourseRelated(requestBody: { [key: string]: object; }, options?: any) {
        return CoursesApiFp(this.configuration).getCourseRelated(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCoursesByChild(options?: any) {
        return CoursesApiFp(this.configuration).getCoursesByChild(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCoursesPrivate(options?: any) {
        return CoursesApiFp(this.configuration).getCoursesPrivate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [courseId] 
     * @param {string} [timezone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCoursesRecommend(courseId?: string, timezone?: string, options?: any) {
        return CoursesApiFp(this.configuration).getCoursesRecommend(courseId, timezone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCoursesSitemap(options?: any) {
        return CoursesApiFp(this.configuration).getCoursesSitemap(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCoursesSupportData(options?: any) {
        return CoursesApiFp(this.configuration).getCoursesSupportData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} packId 
     * @param {string} courseInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getSessionPackDetail(packId: string, courseInstanceId: string, options?: any) {
        return CoursesApiFp(this.configuration).getSessionPackDetail(packId, courseInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getSupportDataEnhanced(requestBody: { [key: string]: object; }, options?: any) {
        return CoursesApiFp(this.configuration).getSupportDataEnhanced(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getTestCronSendFeedback(options?: any) {
        return CoursesApiFp(this.configuration).getTestCronSendFeedback(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public postCourseFilterData(requestBody: { [key: string]: object; }, options?: any) {
        return CoursesApiFp(this.configuration).postCourseFilterData(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public postFilterCoursesSessionList(requestBody: { [key: string]: object; }, options?: any) {
        return CoursesApiFp(this.configuration).postFilterCoursesSessionList(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public postFilteredCourseList(requestBody: { [key: string]: object; }, options?: any) {
        return CoursesApiFp(this.configuration).postFilteredCourseList(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public postRegisterCourse(requestBody: { [key: string]: object; }, options?: any) {
        return CoursesApiFp(this.configuration).postRegisterCourse(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExportsApi - axios parameter creator
 * @export
 */
export const ExportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTeachers: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('exportTeachers', 'requestBody', requestBody)
            const localVarPath = `/exports/teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportsApi - functional programming interface
 * @export
 */
export const ExportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportTeachers(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExportTeacherDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportTeachers(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExportsApi - factory interface
 * @export
 */
export const ExportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportsApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTeachers(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<IExportTeacherDataResponse> {
            return localVarFp.exportTeachers(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportsApi - object-oriented interface
 * @export
 * @class ExportsApi
 * @extends {BaseAPI}
 */
export class ExportsApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportsApi
     */
    public exportTeachers(requestBody: { [key: string]: object; }, options?: any) {
        return ExportsApiFp(this.configuration).exportTeachers(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntercomApi - axios parameter creator
 * @export
 */
export const IntercomApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeEmailToLead: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('subscribeEmailToLead', 'requestBody', requestBody)
            const localVarPath = `/intercom/subscribe-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntercomApi - functional programming interface
 * @export
 */
export const IntercomApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntercomApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribeEmailToLead(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribeEmailToLead(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntercomApi - factory interface
 * @export
 */
export const IntercomApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntercomApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeEmailToLead(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.subscribeEmailToLead(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntercomApi - object-oriented interface
 * @export
 * @class IntercomApi
 * @extends {BaseAPI}
 */
export class IntercomApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntercomApi
     */
    public subscribeEmailToLead(requestBody: { [key: string]: object; }, options?: any) {
        return IntercomApiFp(this.configuration).subscribeEmailToLead(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInvoicePdf: async (reference: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('generateInvoicePdf', 'reference', reference)
            const localVarPath = `/invoices/generate/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceInfo: async (invoiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('getInvoiceInfo', 'invoiceId', invoiceId)
            const localVarPath = `/invoices/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateInvoicePdf(reference: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateInvoicePdfResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateInvoicePdf(reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceInfo(invoiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceInfo(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoicesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInvoicePdf(reference: string, options?: any): AxiosPromise<GenerateInvoicePdfResponse> {
            return localVarFp.generateInvoicePdf(reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceInfo(invoiceId: string, options?: any): AxiosPromise<InvoiceResponse> {
            return localVarFp.getInvoiceInfo(invoiceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public generateInvoicePdf(reference: string, options?: any) {
        return InvoicesApiFp(this.configuration).generateInvoicePdf(reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getInvoiceInfo(invoiceId: string, options?: any) {
        return InvoicesApiFp(this.configuration).getInvoiceInfo(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KisiApi - axios parameter creator
 * @export
 */
export const KisiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWebhookKisi: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/kisi/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KisiApi - functional programming interface
 * @export
 */
export const KisiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KisiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWebhookKisi(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWebhookKisi(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KisiApi - factory interface
 * @export
 */
export const KisiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KisiApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWebhookKisi(options?: any): AxiosPromise<void> {
            return localVarFp.postWebhookKisi(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KisiApi - object-oriented interface
 * @export
 * @class KisiApi
 * @extends {BaseAPI}
 */
export class KisiApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KisiApi
     */
    public postWebhookKisi(options?: any) {
        return KisiApiFp(this.configuration).postWebhookKisi(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogsApi - axios parameter creator
 * @export
 */
export const LogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEvent: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('logEvent', 'requestBody', requestBody)
            const localVarPath = `/logs/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogsApi - functional programming interface
 * @export
 */
export const LogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logEvent(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logEvent(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogsApi - factory interface
 * @export
 */
export const LogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogsApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEvent(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.logEvent(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogsApi - object-oriented interface
 * @export
 * @class LogsApi
 * @extends {BaseAPI}
 */
export class LogsApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public logEvent(requestBody: { [key: string]: object; }, options?: any) {
        return LogsApiFp(this.configuration).logEvent(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessagesApi - axios parameter creator
 * @export
 */
export const MessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMessage: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addMessage', 'requestBody', requestBody)
            const localVarPath = `/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMessageGroup: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addMessageGroup', 'requestBody', requestBody)
            const localVarPath = `/messages/add-message-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fromId 
         * @param {string} toId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationDetail: async (fromId: string, toId: string, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromId' is not null or undefined
            assertParamExists('getConversationDetail', 'fromId', fromId)
            // verify required parameter 'toId' is not null or undefined
            assertParamExists('getConversationDetail', 'toId', toId)
            const localVarPath = `/messages/conversation-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (fromId !== undefined) {
                localVarQueryParameter['fromId'] = fromId;
            }

            if (toId !== undefined) {
                localVarQueryParameter['toId'] = toId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationList: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getConversationList', 'requestBody', requestBody)
            const localVarPath = `/messages/conversations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} messageGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageGroupInfo: async (messageGroupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageGroupId' is not null or undefined
            assertParamExists('getMessageGroupInfo', 'messageGroupId', messageGroupId)
            const localVarPath = `/messages/message-group/{messageGroupId}`
                .replace(`{${"messageGroupId"}}`, encodeURIComponent(String(messageGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadMessageAmount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/messages/unread-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        seenMessage: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('seenMessage', 'requestBody', requestBody)
            const localVarPath = `/messages/seen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMessage(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMessage(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMessageGroup(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMessageGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMessageGroup(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fromId 
         * @param {string} toId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationDetail(fromId: string, toId: string, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConversationDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationDetail(fromId, toId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationList(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConversationListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationList(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} messageGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageGroupInfo(messageGroupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMessageGroupInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageGroupInfo(messageGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadMessageAmount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUnreadMessageAmount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadMessageAmount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async seenMessage(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.seenMessage(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagesApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMessage(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.addMessage(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMessageGroup(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<CreateMessageGroupResponse> {
            return localVarFp.addMessageGroup(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fromId 
         * @param {string} toId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationDetail(fromId: string, toId: string, offset?: number, limit?: number, options?: any): AxiosPromise<GetConversationDetailResponse> {
            return localVarFp.getConversationDetail(fromId, toId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationList(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetConversationListResponse> {
            return localVarFp.getConversationList(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} messageGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageGroupInfo(messageGroupId: string, options?: any): AxiosPromise<GetMessageGroupInfoResponse> {
            return localVarFp.getMessageGroupInfo(messageGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadMessageAmount(options?: any): AxiosPromise<GetUnreadMessageAmount> {
            return localVarFp.getUnreadMessageAmount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        seenMessage(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.seenMessage(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public addMessage(requestBody: { [key: string]: object; }, options?: any) {
        return MessagesApiFp(this.configuration).addMessage(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public addMessageGroup(requestBody: { [key: string]: object; }, options?: any) {
        return MessagesApiFp(this.configuration).addMessageGroup(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fromId 
     * @param {string} toId 
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getConversationDetail(fromId: string, toId: string, offset?: number, limit?: number, options?: any) {
        return MessagesApiFp(this.configuration).getConversationDetail(fromId, toId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getConversationList(requestBody: { [key: string]: object; }, options?: any) {
        return MessagesApiFp(this.configuration).getConversationList(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} messageGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getMessageGroupInfo(messageGroupId: string, options?: any) {
        return MessagesApiFp(this.configuration).getMessageGroupInfo(messageGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getUnreadMessageAmount(options?: any) {
        return MessagesApiFp(this.configuration).getUnreadMessageAmount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public seenMessage(requestBody: { [key: string]: object; }, options?: any) {
        return MessagesApiFp(this.configuration).seenMessage(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (requestBody: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getNotifications', 'requestBody', requestBody)
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifyConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWebhookPostmark: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<NotificationEmailDto>} notificationEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail: async (notificationEmailDto: Array<NotificationEmailDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationEmailDto' is not null or undefined
            assertParamExists('sendEmail', 'notificationEmailDto', notificationEmailDto)
            const localVarPath = `/notifications/send-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotifyReplacementEmail: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('sendNotifyReplacementEmail', 'requestBody', requestBody)
            const localVarPath = `/notifications/notify-replacement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<SmsDto>} smsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSms: async (smsDto: Array<SmsDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'smsDto' is not null or undefined
            assertParamExists('sendSms', 'smsDto', smsDto)
            const localVarPath = `/notifications/send-sms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(smsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifyConfig: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateNotifyConfig', 'requestBody', requestBody)
            const localVarPath = `/notifications/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusNotifications: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookOutboundMessagePostmark: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/webhook-outbound-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(requestBody: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNotifyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifyConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNotifyConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifyConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWebhookPostmark(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWebhookPostmark(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<NotificationEmailDto>} notificationEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmail(notificationEmailDto: Array<NotificationEmailDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendNotifyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmail(notificationEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotifyReplacementEmail(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotifyReplacementEmail(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<SmsDto>} smsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSms(smsDto: Array<SmsDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendSmsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSms(smsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotifyConfig(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNotifyConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotifyConfig(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStatusNotifications(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStatusNotifications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhookOutboundMessagePostmark(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhookOutboundMessagePostmark(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(requestBody: Array<string>, options?: any): AxiosPromise<GetNotifyResponse> {
            return localVarFp.getNotifications(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifyConfig(options?: any): AxiosPromise<GetNotifyConfigResponse> {
            return localVarFp.getNotifyConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWebhookPostmark(options?: any): AxiosPromise<void> {
            return localVarFp.postWebhookPostmark(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<NotificationEmailDto>} notificationEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail(notificationEmailDto: Array<NotificationEmailDto>, options?: any): AxiosPromise<SendNotifyResponse> {
            return localVarFp.sendEmail(notificationEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotifyReplacementEmail(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.sendNotifyReplacementEmail(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<SmsDto>} smsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSms(smsDto: Array<SmsDto>, options?: any): AxiosPromise<SendSmsResponse> {
            return localVarFp.sendSms(smsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifyConfig(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetNotifyConfigResponse> {
            return localVarFp.updateNotifyConfig(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusNotifications(options?: any): AxiosPromise<void> {
            return localVarFp.updateStatusNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookOutboundMessagePostmark(options?: any): AxiosPromise<void> {
            return localVarFp.webhookOutboundMessagePostmark(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotifications(requestBody: Array<string>, options?: any) {
        return NotificationsApiFp(this.configuration).getNotifications(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotifyConfig(options?: any) {
        return NotificationsApiFp(this.configuration).getNotifyConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public postWebhookPostmark(options?: any) {
        return NotificationsApiFp(this.configuration).postWebhookPostmark(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<NotificationEmailDto>} notificationEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public sendEmail(notificationEmailDto: Array<NotificationEmailDto>, options?: any) {
        return NotificationsApiFp(this.configuration).sendEmail(notificationEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public sendNotifyReplacementEmail(requestBody: { [key: string]: object; }, options?: any) {
        return NotificationsApiFp(this.configuration).sendNotifyReplacementEmail(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<SmsDto>} smsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public sendSms(smsDto: Array<SmsDto>, options?: any) {
        return NotificationsApiFp(this.configuration).sendSms(smsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateNotifyConfig(requestBody: { [key: string]: object; }, options?: any) {
        return NotificationsApiFp(this.configuration).updateNotifyConfig(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateStatusNotifications(options?: any) {
        return NotificationsApiFp(this.configuration).updateStatusNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public webhookOutboundMessagePostmark(options?: any) {
        return NotificationsApiFp(this.configuration).webhookOutboundMessagePostmark(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParentsApi - axios parameter creator
 * @export
 */
export const ParentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countUnreadMessageRecipients: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/parents/count-unread-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterUpdates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/parents/photo-updates/get/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHelpScreenUrl: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/parents/help-screen-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhotoUpdateDetails: async (messageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('getPhotoUpdateDetails', 'messageId', messageId)
            const localVarPath = `/parents/photo-updates/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/parents/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} isGetItemAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentsByParent: async (isGetItemAll: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'isGetItemAll' is not null or undefined
            assertParamExists('getStudentsByParent', 'isGetItemAll', isGetItemAll)
            const localVarPath = `/parents/students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (isGetItemAll !== undefined) {
                localVarQueryParameter['isGetItemAll'] = isGetItemAll;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentsCheckedInHistories: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getStudentsCheckedInHistories', 'requestBody', requestBody)
            const localVarPath = `/parents/student-checked-in-histories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseShareCountMessage: async (messageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('increaseShareCountMessage', 'messageId', messageId)
            const localVarPath = `/parents/photo-updates/increase-share-count-message/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteGuardian: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('inviteGuardian', 'requestBody', requestBody)
            const localVarPath = `/parents/guardian/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPhotoUpdates: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postPhotoUpdates', 'requestBody', requestBody)
            const localVarPath = `/parents/photo-updates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMessageRecipientToRead: async (messageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('putMessageRecipientToRead', 'messageId', messageId)
            const localVarPath = `/parents/photo-updates/mark-read-message/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDeviceToken: async (tokenId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('removeDeviceToken', 'tokenId', tokenId)
            const localVarPath = `/parents/remove-device-token/{tokenId}`
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGuardian: async (guardianId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('removeGuardian', 'guardianId', guardianId)
            const localVarPath = `/parents/guardian/{guardianId}`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDeviceToken: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('saveDeviceToken', 'requestBody', requestBody)
            const localVarPath = `/parents/save-device-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentCourses: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('studentCourses', 'requestBody', requestBody)
            const localVarPath = `/parents/students/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentSessionDates: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('studentSessionDates', 'requestBody', requestBody)
            const localVarPath = `/parents/students/session-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatchChildInfoRequest} patchChildInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChild: async (patchChildInfoRequest: PatchChildInfoRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchChildInfoRequest' is not null or undefined
            assertParamExists('updateChild', 'patchChildInfoRequest', patchChildInfoRequest)
            const localVarPath = `/parents/color`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchChildInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guardianId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGuardian: async (guardianId: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('updateGuardian', 'guardianId', guardianId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateGuardian', 'requestBody', requestBody)
            const localVarPath = `/parents/guardian/{guardianId}`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParentsApi - functional programming interface
 * @export
 */
export const ParentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countUnreadMessageRecipients(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countUnreadMessageRecipients(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilterUpdates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhotoUpdateFilterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilterUpdates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHelpScreenUrl(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHelpScreenUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhotoUpdateDetails(messageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhotoUpdateDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhotoUpdateDetails(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} isGetItemAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentsByParent(isGetItemAll: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ToggleStudentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentsByParent(isGetItemAll, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentsCheckedInHistories(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentCheckedInHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentsCheckedInHistories(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async increaseShareCountMessage(messageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.increaseShareCountMessage(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteGuardian(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteGuardianResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteGuardian(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPhotoUpdates(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhotoUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPhotoUpdates(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMessageRecipientToRead(messageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMessageRecipientToRead(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDeviceToken(tokenId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeDeviceToken(tokenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeGuardian(guardianId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeGuardian(guardianId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDeviceToken(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDeviceToken(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentCourses(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStudentCoursesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studentCourses(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentSessionDates(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStudentSessionDatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studentSessionDates(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PatchChildInfoRequest} patchChildInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChild(patchChildInfoRequest: PatchChildInfoRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChild(patchChildInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guardianId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGuardian(guardianId: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateGuardianResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGuardian(guardianId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParentsApi - factory interface
 * @export
 */
export const ParentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParentsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countUnreadMessageRecipients(options?: any): AxiosPromise<number> {
            return localVarFp.countUnreadMessageRecipients(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterUpdates(options?: any): AxiosPromise<PhotoUpdateFilterResponse> {
            return localVarFp.getFilterUpdates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHelpScreenUrl(options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.getHelpScreenUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhotoUpdateDetails(messageId: string, options?: any): AxiosPromise<PhotoUpdateDetailResponse> {
            return localVarFp.getPhotoUpdateDetails(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<GetUserInfoResponse> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} isGetItemAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentsByParent(isGetItemAll: boolean, options?: any): AxiosPromise<ToggleStudentResponse> {
            return localVarFp.getStudentsByParent(isGetItemAll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentsCheckedInHistories(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<StudentCheckedInHistoryResponse> {
            return localVarFp.getStudentsCheckedInHistories(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseShareCountMessage(messageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.increaseShareCountMessage(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteGuardian(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<InviteGuardianResponse> {
            return localVarFp.inviteGuardian(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPhotoUpdates(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PhotoUpdateResponse> {
            return localVarFp.postPhotoUpdates(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMessageRecipientToRead(messageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.putMessageRecipientToRead(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDeviceToken(tokenId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeDeviceToken(tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGuardian(guardianId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeGuardian(guardianId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDeviceToken(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.saveDeviceToken(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentCourses(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetStudentCoursesResponse> {
            return localVarFp.studentCourses(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentSessionDates(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetStudentSessionDatesResponse> {
            return localVarFp.studentSessionDates(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatchChildInfoRequest} patchChildInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChild(patchChildInfoRequest: PatchChildInfoRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateChild(patchChildInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guardianId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGuardian(guardianId: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<UpdateGuardianResponse> {
            return localVarFp.updateGuardian(guardianId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParentsApi - object-oriented interface
 * @export
 * @class ParentsApi
 * @extends {BaseAPI}
 */
export class ParentsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public countUnreadMessageRecipients(options?: any) {
        return ParentsApiFp(this.configuration).countUnreadMessageRecipients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public getFilterUpdates(options?: any) {
        return ParentsApiFp(this.configuration).getFilterUpdates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public getHelpScreenUrl(options?: any) {
        return ParentsApiFp(this.configuration).getHelpScreenUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public getPhotoUpdateDetails(messageId: string, options?: any) {
        return ParentsApiFp(this.configuration).getPhotoUpdateDetails(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public getProfile(options?: any) {
        return ParentsApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} isGetItemAll 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public getStudentsByParent(isGetItemAll: boolean, options?: any) {
        return ParentsApiFp(this.configuration).getStudentsByParent(isGetItemAll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public getStudentsCheckedInHistories(requestBody: { [key: string]: object; }, options?: any) {
        return ParentsApiFp(this.configuration).getStudentsCheckedInHistories(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public increaseShareCountMessage(messageId: string, options?: any) {
        return ParentsApiFp(this.configuration).increaseShareCountMessage(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public inviteGuardian(requestBody: { [key: string]: object; }, options?: any) {
        return ParentsApiFp(this.configuration).inviteGuardian(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public postPhotoUpdates(requestBody: { [key: string]: object; }, options?: any) {
        return ParentsApiFp(this.configuration).postPhotoUpdates(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public putMessageRecipientToRead(messageId: string, options?: any) {
        return ParentsApiFp(this.configuration).putMessageRecipientToRead(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tokenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public removeDeviceToken(tokenId: string, options?: any) {
        return ParentsApiFp(this.configuration).removeDeviceToken(tokenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guardianId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public removeGuardian(guardianId: string, options?: any) {
        return ParentsApiFp(this.configuration).removeGuardian(guardianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public saveDeviceToken(requestBody: { [key: string]: object; }, options?: any) {
        return ParentsApiFp(this.configuration).saveDeviceToken(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public studentCourses(requestBody: { [key: string]: object; }, options?: any) {
        return ParentsApiFp(this.configuration).studentCourses(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public studentSessionDates(requestBody: { [key: string]: object; }, options?: any) {
        return ParentsApiFp(this.configuration).studentSessionDates(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatchChildInfoRequest} patchChildInfoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public updateChild(patchChildInfoRequest: PatchChildInfoRequest, options?: any) {
        return ParentsApiFp(this.configuration).updateChild(patchChildInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guardianId 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public updateGuardian(guardianId: string, requestBody: { [key: string]: object; }, options?: any) {
        return ParentsApiFp(this.configuration).updateGuardian(guardianId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQueueMessage: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteQueueMessage', 'requestBody', requestBody)
            const localVarPath = `/payments/delete-queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} isInsert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentStatus: async (id: string, isInsert: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPaymentStatus', 'id', id)
            // verify required parameter 'isInsert' is not null or undefined
            assertParamExists('getPaymentStatus', 'isInsert', isInsert)
            const localVarPath = `/payments/status/{id}/{isInsert}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"isInsert"}}`, encodeURIComponent(String(isInsert)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentAutoConfirmEnroll: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('paymentAutoConfirmEnroll', 'requestBody', requestBody)
            const localVarPath = `/payments/payment-auto-confirm-enroll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentByTransaction: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('paymentByTransaction', 'transactionId', transactionId)
            const localVarPath = `/payments/transaction/{transactionId}`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentHandler: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('paymentHandler', 'requestBody', requestBody)
            const localVarPath = `/payments/payment-handler`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfirmWebhook: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/payments/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfirmWebhookForEPInvoice: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/payments/webhook-ep-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreatePaymentEpInvoiceRequest: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postCreatePaymentEpInvoiceRequest', 'requestBody', requestBody)
            const localVarPath = `/payments/payment-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreatePaymentRequest: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postCreatePaymentRequest', 'requestBody', requestBody)
            const localVarPath = `/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseHiddenPlan: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('purchaseHiddenPlan', 'requestBody', requestBody)
            const localVarPath = `/payments/purchase-hidden-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundPayment: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('refundPayment', 'requestBody', requestBody)
            const localVarPath = `/payments/refund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQueueMessage(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQueueMessage(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} isInsert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentStatus(id: string, isInsert: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HitPayGetPaymentStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentStatus(id, isInsert, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentAutoConfirmEnroll(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HitPayCreateRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentAutoConfirmEnroll(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentByTransaction(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaymentByTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentByTransaction(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentHandler(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentHandler(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postConfirmWebhook(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postConfirmWebhook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postConfirmWebhookForEPInvoice(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postConfirmWebhookForEPInvoice(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreatePaymentEpInvoiceRequest(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HitPayCreateRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreatePaymentEpInvoiceRequest(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreatePaymentRequest(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HitPayCreateRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreatePaymentRequest(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseHiddenPlan(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseHiddenPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseHiddenPlan(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundPayment(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefundPaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundPayment(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQueueMessage(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.deleteQueueMessage(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} isInsert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentStatus(id: string, isInsert: boolean, options?: any): AxiosPromise<HitPayGetPaymentStatusResponse> {
            return localVarFp.getPaymentStatus(id, isInsert, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentAutoConfirmEnroll(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<HitPayCreateRequestResponse> {
            return localVarFp.paymentAutoConfirmEnroll(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentByTransaction(transactionId: string, options?: any): AxiosPromise<GetPaymentByTransactionResponse> {
            return localVarFp.paymentByTransaction(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentHandler(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.paymentHandler(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfirmWebhook(options?: any): AxiosPromise<void> {
            return localVarFp.postConfirmWebhook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfirmWebhookForEPInvoice(options?: any): AxiosPromise<void> {
            return localVarFp.postConfirmWebhookForEPInvoice(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreatePaymentEpInvoiceRequest(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<HitPayCreateRequestResponse> {
            return localVarFp.postCreatePaymentEpInvoiceRequest(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreatePaymentRequest(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<HitPayCreateRequestResponse> {
            return localVarFp.postCreatePaymentRequest(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseHiddenPlan(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PurchaseHiddenPlanResponse> {
            return localVarFp.purchaseHiddenPlan(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundPayment(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<RefundPaymentResponse> {
            return localVarFp.refundPayment(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public deleteQueueMessage(requestBody: { [key: string]: object; }, options?: any) {
        return PaymentsApiFp(this.configuration).deleteQueueMessage(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {boolean} isInsert 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getPaymentStatus(id: string, isInsert: boolean, options?: any) {
        return PaymentsApiFp(this.configuration).getPaymentStatus(id, isInsert, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentAutoConfirmEnroll(requestBody: { [key: string]: object; }, options?: any) {
        return PaymentsApiFp(this.configuration).paymentAutoConfirmEnroll(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} transactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentByTransaction(transactionId: string, options?: any) {
        return PaymentsApiFp(this.configuration).paymentByTransaction(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentHandler(requestBody: { [key: string]: object; }, options?: any) {
        return PaymentsApiFp(this.configuration).paymentHandler(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public postConfirmWebhook(options?: any) {
        return PaymentsApiFp(this.configuration).postConfirmWebhook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public postConfirmWebhookForEPInvoice(options?: any) {
        return PaymentsApiFp(this.configuration).postConfirmWebhookForEPInvoice(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public postCreatePaymentEpInvoiceRequest(requestBody: { [key: string]: object; }, options?: any) {
        return PaymentsApiFp(this.configuration).postCreatePaymentEpInvoiceRequest(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public postCreatePaymentRequest(requestBody: { [key: string]: object; }, options?: any) {
        return PaymentsApiFp(this.configuration).postCreatePaymentRequest(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public purchaseHiddenPlan(requestBody: { [key: string]: object; }, options?: any) {
        return PaymentsApiFp(this.configuration).purchaseHiddenPlan(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public refundPayment(requestBody: { [key: string]: object; }, options?: any) {
        return PaymentsApiFp(this.configuration).refundPayment(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProvidersApi - axios parameter creator
 * @export
 */
export const ProvidersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} teacherEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailExisted: async (teacherEmail: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherEmail' is not null or undefined
            assertParamExists('checkEmailExisted', 'teacherEmail', teacherEmail)
            const localVarPath = `/providers/teacher-email-existed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (teacherEmail !== undefined) {
                localVarQueryParameter['teacherEmail'] = teacherEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTeacherHasSessionAvailable: async (teacherId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('checkTeacherHasSessionAvailable', 'teacherId', teacherId)
            const localVarPath = `/providers/teacher-has-session-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingList: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getBookingList', 'requestBody', requestBody)
            const localVarPath = `/providers/booking-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversations: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getConversations', 'requestBody', requestBody)
            const localVarPath = `/providers/conversations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardFilters: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers/dashboard-filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} providerSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvider: async (providerSlug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerSlug' is not null or undefined
            assertParamExists('getProvider', 'providerSlug', providerSlug)
            const localVarPath = `/providers/provider/{providerSlug}`
                .replace(`{${"providerSlug"}}`, encodeURIComponent(String(providerSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderCourseDetail: async (courseSlug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseSlug' is not null or undefined
            assertParamExists('getProviderCourseDetail', 'courseSlug', courseSlug)
            const localVarPath = `/providers/provider-course-detail/{courseSlug}`
                .replace(`{${"courseSlug"}}`, encodeURIComponent(String(courseSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderCourseInstanceDetail: async (courseInstanceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseInstanceId' is not null or undefined
            assertParamExists('getProviderCourseInstanceDetail', 'courseInstanceId', courseInstanceId)
            const localVarPath = `/providers/provider-instance-detail/{courseInstanceId}`
                .replace(`{${"courseInstanceId"}}`, encodeURIComponent(String(courseInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderDetail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers/provider-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderInformation: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject7} inlineObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviders: async (inlineObject7: InlineObject7, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject7' is not null or undefined
            assertParamExists('getProviders', 'inlineObject7', inlineObject7)
            const localVarPath = `/providers/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject7, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddTeacherByEpAdmin: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postAddTeacherByEpAdmin', 'requestBody', requestBody)
            const localVarPath = `/providers/add-teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAttendanceList: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postAttendanceList', 'requestBody', requestBody)
            const localVarPath = `/providers/attendance-student-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCourseSessionAttendance: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postCourseSessionAttendance', 'requestBody', requestBody)
            const localVarPath = `/providers/attendance-course-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCourseSessions: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postCourseSessions', 'requestBody', requestBody)
            const localVarPath = `/providers/course-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDashboard: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postDashboard', 'requestBody', requestBody)
            const localVarPath = `/providers/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEditTeacherByEpAdmin: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postEditTeacherByEpAdmin', 'requestBody', requestBody)
            const localVarPath = `/providers/edit-teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProviderAgreement: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postProviderAgreement', 'requestBody', requestBody)
            const localVarPath = `/providers/agreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCoursesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProviderCourseInstances: async (body: GetCoursesRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postProviderCourseInstances', 'body', body)
            const localVarPath = `/providers/provider-course-instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProviderCourses: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postProviderCourses', 'requestBody', requestBody)
            const localVarPath = `/providers/provider-courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject8} inlineObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRegistration: async (inlineObject8: InlineObject8, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject8' is not null or undefined
            assertParamExists('postRegistration', 'inlineObject8', inlineObject8)
            const localVarPath = `/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject8, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRemoveTeacherByEpAdmin: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postRemoveTeacherByEpAdmin', 'requestBody', requestBody)
            const localVarPath = `/providers/remove-teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postScheduleDate: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postScheduleDate', 'requestBody', requestBody)
            const localVarPath = `/providers/schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStudentList: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postStudentList', 'requestBody', requestBody)
            const localVarPath = `/providers/course-student-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTeacherByProvider: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postTeacherByProvider', 'requestBody', requestBody)
            const localVarPath = `/providers/teacher-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourseInstanceTeacher: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateCourseInstanceTeacher', 'requestBody', requestBody)
            const localVarPath = `/providers/update-course-instance-teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProvidersApi - functional programming interface
 * @export
 */
export const ProvidersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProvidersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} teacherEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkEmailExisted(teacherEmail: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkEmailExisted(teacherEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkTeacherHasSessionAvailable(teacherId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCheckTeacherHasSessionAvailableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkTeacherHasSessionAvailable(teacherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingList(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProviderBookingListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingList(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversations(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEpConversations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversations(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardFilters(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEPDashboardFiltersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardFilters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} providerSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvider(providerSlug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProviderInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvider(providerSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderCourseDetail(courseSlug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderCourseDetail(courseSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderCourseInstanceDetail(courseInstanceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseInstanceDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderCourseInstanceDetail(courseInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderDetail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEPDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderDetail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderInformation(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProviderInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderInformation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject7} inlineObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviders(inlineObject7: InlineObject7, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProvidersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviders(inlineObject7, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddTeacherByEpAdmin(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddTeacherByEpAdmin(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAttendanceList(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostEPAttendanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAttendanceList(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCourseSessionAttendance(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostEPAttendanceCourseSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCourseSessionAttendance(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCourseSessions(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSessionsOfCourseInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCourseSessions(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDashboard(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostEPDashboardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDashboard(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEditTeacherByEpAdmin(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEditTeacherByEpAdmin(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProviderAgreement(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostEPAgreementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProviderAgreement(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetCoursesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProviderCourseInstances(body: GetCoursesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseInstancesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProviderCourseInstances(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProviderCourses(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCoursesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProviderCourses(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject8} inlineObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRegistration(inlineObject8: InlineObject8, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRegistration(inlineObject8, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRemoveTeacherByEpAdmin(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRemoveTeacherByEpAdmin(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postScheduleDate(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostEPSchedulesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postScheduleDate(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStudentList(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStudentsOfCourseInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStudentList(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTeacherByProvider(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostEPTeacherDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTeacherByProvider(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCourseInstanceTeacher(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCourseInstanceTeacher(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProvidersApi - factory interface
 * @export
 */
export const ProvidersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProvidersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} teacherEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailExisted(teacherEmail: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.checkEmailExisted(teacherEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTeacherHasSessionAvailable(teacherId: string, options?: any): AxiosPromise<GetCheckTeacherHasSessionAvailableResponse> {
            return localVarFp.checkTeacherHasSessionAvailable(teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingList(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetProviderBookingListResponse> {
            return localVarFp.getBookingList(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversations(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetEpConversations> {
            return localVarFp.getConversations(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardFilters(options?: any): AxiosPromise<GetEPDashboardFiltersResponse> {
            return localVarFp.getDashboardFilters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} providerSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvider(providerSlug: string, options?: any): AxiosPromise<GetProviderInfoResponse> {
            return localVarFp.getProvider(providerSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderCourseDetail(courseSlug: string, options?: any): AxiosPromise<GetCourseDetailResponse> {
            return localVarFp.getProviderCourseDetail(courseSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderCourseInstanceDetail(courseInstanceId: string, options?: any): AxiosPromise<GetCourseInstanceDetailResponse> {
            return localVarFp.getProviderCourseInstanceDetail(courseInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderDetail(options?: any): AxiosPromise<GetEPDetailResponse> {
            return localVarFp.getProviderDetail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderInformation(options?: any): AxiosPromise<GetProviderInfoResponse> {
            return localVarFp.getProviderInformation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject7} inlineObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviders(inlineObject7: InlineObject7, options?: any): AxiosPromise<GetProvidersResponse> {
            return localVarFp.getProviders(inlineObject7, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddTeacherByEpAdmin(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.postAddTeacherByEpAdmin(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAttendanceList(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PostEPAttendanceResponse> {
            return localVarFp.postAttendanceList(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCourseSessionAttendance(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PostEPAttendanceCourseSessionResponse> {
            return localVarFp.postCourseSessionAttendance(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCourseSessions(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetSessionsOfCourseInstanceResponse> {
            return localVarFp.postCourseSessions(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDashboard(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PostEPDashboardResponse> {
            return localVarFp.postDashboard(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEditTeacherByEpAdmin(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.postEditTeacherByEpAdmin(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProviderAgreement(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PostEPAgreementResponse> {
            return localVarFp.postProviderAgreement(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetCoursesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProviderCourseInstances(body: GetCoursesRequest, options?: any): AxiosPromise<GetCourseInstancesResponse> {
            return localVarFp.postProviderCourseInstances(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProviderCourses(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetCoursesResponse> {
            return localVarFp.postProviderCourses(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject8} inlineObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRegistration(inlineObject8: InlineObject8, options?: any): AxiosPromise<void> {
            return localVarFp.postRegistration(inlineObject8, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRemoveTeacherByEpAdmin(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.postRemoveTeacherByEpAdmin(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postScheduleDate(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PostEPSchedulesResponse> {
            return localVarFp.postScheduleDate(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStudentList(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetStudentsOfCourseInstanceResponse> {
            return localVarFp.postStudentList(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTeacherByProvider(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PostEPTeacherDetailResponse> {
            return localVarFp.postTeacherByProvider(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourseInstanceTeacher(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.updateCourseInstanceTeacher(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProvidersApi - object-oriented interface
 * @export
 * @class ProvidersApi
 * @extends {BaseAPI}
 */
export class ProvidersApi extends BaseAPI {
    /**
     * 
     * @param {string} teacherEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public checkEmailExisted(teacherEmail: string, options?: any) {
        return ProvidersApiFp(this.configuration).checkEmailExisted(teacherEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public checkTeacherHasSessionAvailable(teacherId: string, options?: any) {
        return ProvidersApiFp(this.configuration).checkTeacherHasSessionAvailable(teacherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getBookingList(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).getBookingList(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getConversations(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).getConversations(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getDashboardFilters(options?: any) {
        return ProvidersApiFp(this.configuration).getDashboardFilters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} providerSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getProvider(providerSlug: string, options?: any) {
        return ProvidersApiFp(this.configuration).getProvider(providerSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getProviderCourseDetail(courseSlug: string, options?: any) {
        return ProvidersApiFp(this.configuration).getProviderCourseDetail(courseSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getProviderCourseInstanceDetail(courseInstanceId: string, options?: any) {
        return ProvidersApiFp(this.configuration).getProviderCourseInstanceDetail(courseInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getProviderDetail(options?: any) {
        return ProvidersApiFp(this.configuration).getProviderDetail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getProviderInformation(options?: any) {
        return ProvidersApiFp(this.configuration).getProviderInformation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject7} inlineObject7 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getProviders(inlineObject7: InlineObject7, options?: any) {
        return ProvidersApiFp(this.configuration).getProviders(inlineObject7, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postAddTeacherByEpAdmin(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).postAddTeacherByEpAdmin(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postAttendanceList(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).postAttendanceList(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postCourseSessionAttendance(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).postCourseSessionAttendance(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postCourseSessions(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).postCourseSessions(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postDashboard(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).postDashboard(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postEditTeacherByEpAdmin(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).postEditTeacherByEpAdmin(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postProviderAgreement(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).postProviderAgreement(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetCoursesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postProviderCourseInstances(body: GetCoursesRequest, options?: any) {
        return ProvidersApiFp(this.configuration).postProviderCourseInstances(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postProviderCourses(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).postProviderCourses(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject8} inlineObject8 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postRegistration(inlineObject8: InlineObject8, options?: any) {
        return ProvidersApiFp(this.configuration).postRegistration(inlineObject8, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postRemoveTeacherByEpAdmin(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).postRemoveTeacherByEpAdmin(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postScheduleDate(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).postScheduleDate(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postStudentList(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).postStudentList(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postTeacherByProvider(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).postTeacherByProvider(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public updateCourseInstanceTeacher(requestBody: { [key: string]: object; }, options?: any) {
        return ProvidersApiFp(this.configuration).updateCourseInstanceTeacher(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RecurringApi - axios parameter creator
 * @export
 */
export const RecurringApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRecurringPlan: async (planId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('cancelRecurringPlan', 'planId', planId)
            const localVarPath = `/recurring/recurring-plan/{planId}`
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargeWithSavedCard: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('chargeWithSavedCard', 'requestBody', requestBody)
            const localVarPath = `/recurring/charge-with-saved-card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecurringOrSaveCard: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createRecurringOrSaveCard', 'requestBody', requestBody)
            const localVarPath = `/recurring/recurring-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionPlan: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createSubscriptionPlan', 'requestBody', requestBody)
            const localVarPath = `/recurring/subscription-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusRecurringPlan: async (planId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('getStatusRecurringPlan', 'planId', planId)
            const localVarPath = `/recurring/recurring-plan/{planId}`
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleAfterPurchaseSubscription: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('handleAfterPurchaseSubscription', 'requestBody', requestBody)
            const localVarPath = `/recurring/handle-after-purchase-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {string} amount 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recurringWebhook: async (subscriptionId: string, amount: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('recurringWebhook', 'subscriptionId', subscriptionId)
            // verify required parameter 'amount' is not null or undefined
            assertParamExists('recurringWebhook', 'amount', amount)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('recurringWebhook', 'userId', userId)
            const localVarPath = `/recurring/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscriptionId'] = subscriptionId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecurringApi - functional programming interface
 * @export
 */
export const RecurringApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecurringApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelRecurringPlan(planId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurringPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelRecurringPlan(planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chargeWithSavedCard(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChargeWithSavedCardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chargeWithSavedCard(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecurringOrSaveCard(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurringPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRecurringOrSaveCard(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscriptionPlan(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSubscriptionPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscriptionPlan(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusRecurringPlan(planId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurringPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatusRecurringPlan(planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleAfterPurchaseSubscription(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandleAfterPurchaseSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleAfterPurchaseSubscription(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {string} amount 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recurringWebhook(subscriptionId: string, amount: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recurringWebhook(subscriptionId, amount, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecurringApi - factory interface
 * @export
 */
export const RecurringApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecurringApiFp(configuration)
    return {
        /**
         * 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRecurringPlan(planId: string, options?: any): AxiosPromise<RecurringPlanDto> {
            return localVarFp.cancelRecurringPlan(planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargeWithSavedCard(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<ChargeWithSavedCardResponse> {
            return localVarFp.chargeWithSavedCard(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecurringOrSaveCard(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<RecurringPlanDto> {
            return localVarFp.createRecurringOrSaveCard(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionPlan(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<CreateSubscriptionPlanResponse> {
            return localVarFp.createSubscriptionPlan(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusRecurringPlan(planId: string, options?: any): AxiosPromise<RecurringPlanDto> {
            return localVarFp.getStatusRecurringPlan(planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleAfterPurchaseSubscription(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<HandleAfterPurchaseSubscriptionResponse> {
            return localVarFp.handleAfterPurchaseSubscription(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {string} amount 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recurringWebhook(subscriptionId: string, amount: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.recurringWebhook(subscriptionId, amount, userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecurringApi - object-oriented interface
 * @export
 * @class RecurringApi
 * @extends {BaseAPI}
 */
export class RecurringApi extends BaseAPI {
    /**
     * 
     * @param {string} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringApi
     */
    public cancelRecurringPlan(planId: string, options?: any) {
        return RecurringApiFp(this.configuration).cancelRecurringPlan(planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringApi
     */
    public chargeWithSavedCard(requestBody: { [key: string]: object; }, options?: any) {
        return RecurringApiFp(this.configuration).chargeWithSavedCard(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringApi
     */
    public createRecurringOrSaveCard(requestBody: { [key: string]: object; }, options?: any) {
        return RecurringApiFp(this.configuration).createRecurringOrSaveCard(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringApi
     */
    public createSubscriptionPlan(requestBody: { [key: string]: object; }, options?: any) {
        return RecurringApiFp(this.configuration).createSubscriptionPlan(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringApi
     */
    public getStatusRecurringPlan(planId: string, options?: any) {
        return RecurringApiFp(this.configuration).getStatusRecurringPlan(planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringApi
     */
    public handleAfterPurchaseSubscription(requestBody: { [key: string]: object; }, options?: any) {
        return RecurringApiFp(this.configuration).handleAfterPurchaseSubscription(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} subscriptionId 
     * @param {string} amount 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringApi
     */
    public recurringWebhook(subscriptionId: string, amount: string, userId: string, options?: any) {
        return RecurringApiFp(this.configuration).recurringWebhook(subscriptionId, amount, userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RefundsApi - axios parameter creator
 * @export
 */
export const RefundsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundWebhook: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/refunds/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RefundsApi - functional programming interface
 * @export
 */
export const RefundsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RefundsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundWebhook(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundWebhook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RefundsApi - factory interface
 * @export
 */
export const RefundsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RefundsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundWebhook(options?: any): AxiosPromise<void> {
            return localVarFp.refundWebhook(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RefundsApi - object-oriented interface
 * @export
 * @class RefundsApi
 * @extends {BaseAPI}
 */
export class RefundsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefundsApi
     */
    public refundWebhook(options?: any) {
        return RefundsApiFp(this.configuration).refundWebhook(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RetoolApi - axios parameter creator
 * @export
 */
export const RetoolApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InlineObject9} inlineObject9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReservedTrialToCartRetool: async (inlineObject9: InlineObject9, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject9' is not null or undefined
            assertParamExists('addReservedTrialToCartRetool', 'inlineObject9', inlineObject9)
            const localVarPath = `/retool/add-reserved-trial-retool`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject9, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject10} inlineObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRevenues: async (inlineObject10: InlineObject10, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject10' is not null or undefined
            assertParamExists('addRevenues', 'inlineObject10', inlineObject10)
            const localVarPath = `/retool/add-revenues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject10, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRefundCourseInstance: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminRefundCourseInstance', 'requestBody', requestBody)
            const localVarPath = `/retool/refund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRevenues: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('cancelRevenues', 'requestBody', requestBody)
            const localVarPath = `/retool/cancel-revenues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTakeRateFlatFeeRevenue: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('changeTakeRateFlatFeeRevenue', 'requestBody', requestBody)
            const localVarPath = `/retool/change-take-rate-flat-fee-revenue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserByAdmin: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createUserByAdmin', 'requestBody', requestBody)
            const localVarPath = `/retool/create-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailAccountByAdmin: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteEmailAccountByAdmin', 'requestBody', requestBody)
            const localVarPath = `/retool/delete-user-email-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserByAdmin: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('editUserByAdmin', 'requestBody', requestBody)
            const localVarPath = `/retool/edit-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CourseSessionRequest>} courseSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCourseSessions: async (courseSessionRequest: Array<CourseSessionRequest>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseSessionRequest' is not null or undefined
            assertParamExists('generateCourseSessions', 'courseSessionRequest', courseSessionRequest)
            const localVarPath = `/retool/course-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} dates 
         * @param {number} numberOfSeat 
         * @param {string} [roomTypeId] 
         * @param {string} [keepRoomIdsInList] 
         * @param {string} [exceptRoomBookingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRooms: async (startTime: string, endTime: string, dates: string, numberOfSeat: number, roomTypeId?: string, keepRoomIdsInList?: string, exceptRoomBookingIds?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAvailableRooms', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAvailableRooms', 'endTime', endTime)
            // verify required parameter 'dates' is not null or undefined
            assertParamExists('getAvailableRooms', 'dates', dates)
            // verify required parameter 'numberOfSeat' is not null or undefined
            assertParamExists('getAvailableRooms', 'numberOfSeat', numberOfSeat)
            const localVarPath = `/retool/available-rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (dates !== undefined) {
                localVarQueryParameter['dates'] = dates;
            }

            if (numberOfSeat !== undefined) {
                localVarQueryParameter['numberOfSeat'] = numberOfSeat;
            }

            if (roomTypeId !== undefined) {
                localVarQueryParameter['roomTypeId'] = roomTypeId;
            }

            if (keepRoomIdsInList !== undefined) {
                localVarQueryParameter['keepRoomIdsInList'] = keepRoomIdsInList;
            }

            if (exceptRoomBookingIds !== undefined) {
                localVarQueryParameter['exceptRoomBookingIds'] = exceptRoomBookingIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateUserByAdmin: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postCreateUserByAdmin', 'requestBody', requestBody)
            const localVarPath = `/retool/create-user-by-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEPAgreement: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postEPAgreement', 'requestBody', requestBody)
            const localVarPath = `/retool/ep-agreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEPInvoice: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postEPInvoice', 'requestBody', requestBody)
            const localVarPath = `/retool/ep-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSOAEducationProvider: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postSOAEducationProvider', 'requestBody', requestBody)
            const localVarPath = `/retool/soa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<AdminSendMailRequest>} adminSendMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendBatchEmails: async (adminSendMailRequest: Array<AdminSendMailRequest>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminSendMailRequest' is not null or undefined
            assertParamExists('sendBatchEmails', 'adminSendMailRequest', adminSendMailRequest)
            const localVarPath = `/retool/send-batch-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSendMailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailToEPFinance: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('sendEmailToEPFinance', 'requestBody', requestBody)
            const localVarPath = `/retool/send-emails-ep-finance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReplacementEmailAndNotiToEpTeacher: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('sendReplacementEmailAndNotiToEpTeacher', 'requestBody', requestBody)
            const localVarPath = `/retool/replacement-email-for-ep-teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRescheduleCourseInstanceEP: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('sendRescheduleCourseInstanceEP', 'requestBody', requestBody)
            const localVarPath = `/retool/send-reschedule-course-instance-ep`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRescheduleEmailAndNotiToEpTeacher: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('sendRescheduleEmailAndNotiToEpTeacher', 'requestBody', requestBody)
            const localVarPath = `/retool/reschedule-email-for-ep-teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTrialEmailAndNotiToEpTeacher: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('sendTrialEmailAndNotiToEpTeacher', 'requestBody', requestBody)
            const localVarPath = `/retool/trial-email-for-ep-teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendWithdrawTrialStudentEmail: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('sendWithdrawTrialStudentEmail', 'requestBody', requestBody)
            const localVarPath = `/retool/send-withdraw-trial-student-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserTemporaryPasswordByAdmin: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('setUserTemporaryPasswordByAdmin', 'requestBody', requestBody)
            const localVarPath = `/retool/set-user-temporary-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ValidateCourseSessionRequest>} validateCourseSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCourseSessions: async (validateCourseSessionRequest: Array<ValidateCourseSessionRequest>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateCourseSessionRequest' is not null or undefined
            assertParamExists('validateCourseSessions', 'validateCourseSessionRequest', validateCourseSessionRequest)
            const localVarPath = `/retool/validate-course-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateCourseSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        websiteURL: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/retool/website-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RetoolApi - functional programming interface
 * @export
 */
export const RetoolApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RetoolApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InlineObject9} inlineObject9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addReservedTrialToCartRetool(inlineObject9: InlineObject9, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addReservedTrialToCartRetool(inlineObject9, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject10} inlineObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRevenues(inlineObject10: InlineObject10, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRevenues(inlineObject10, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRefundCourseInstance(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRefundCourseInstance(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelRevenues(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelRevenues(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeTakeRateFlatFeeRevenue(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeTakeRateFlatFeeRevenue(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserByAdmin(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserByAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserByAdmin(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmailAccountByAdmin(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmailAccountByAdmin(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editUserByAdmin(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostUserInfoByAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editUserByAdmin(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<CourseSessionRequest>} courseSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCourseSessions(courseSessionRequest: Array<CourseSessionRequest>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCourseSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCourseSessions(courseSessionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} dates 
         * @param {number} numberOfSeat 
         * @param {string} [roomTypeId] 
         * @param {string} [keepRoomIdsInList] 
         * @param {string} [exceptRoomBookingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableRooms(startTime: string, endTime: string, dates: string, numberOfSeat: number, roomTypeId?: string, keepRoomIdsInList?: string, exceptRoomBookingIds?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableRoomsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableRooms(startTime, endTime, dates, numberOfSeat, roomTypeId, keepRoomIdsInList, exceptRoomBookingIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreateUserByAdmin(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostUserInfoByAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreateUserByAdmin(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEPAgreement(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEPAgreement(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEPInvoice(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratePDFResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEPInvoice(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSOAEducationProvider(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratePDFResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSOAEducationProvider(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<AdminSendMailRequest>} adminSendMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendBatchEmails(adminSendMailRequest: Array<AdminSendMailRequest>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageSendingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendBatchEmails(adminSendMailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailToEPFinance(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailToEPFinance(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendReplacementEmailAndNotiToEpTeacher(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendReplacementEmailAndNotiToEpTeacher(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendRescheduleCourseInstanceEP(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageSendingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendRescheduleCourseInstanceEP(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendRescheduleEmailAndNotiToEpTeacher(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendRescheduleEmailAndNotiToEpTeacher(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendTrialEmailAndNotiToEpTeacher(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendTrialEmailAndNotiToEpTeacher(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendWithdrawTrialStudentEmail(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any> | MessageSendingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendWithdrawTrialStudentEmail(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserTemporaryPasswordByAdmin(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserTemporaryPasswordByAdmin(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<ValidateCourseSessionRequest>} validateCourseSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateCourseSessions(validateCourseSessionRequest: Array<ValidateCourseSessionRequest>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ValidateCourseSessionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateCourseSessions(validateCourseSessionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async websiteURL(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.websiteURL(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RetoolApi - factory interface
 * @export
 */
export const RetoolApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RetoolApiFp(configuration)
    return {
        /**
         * 
         * @param {InlineObject9} inlineObject9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReservedTrialToCartRetool(inlineObject9: InlineObject9, options?: any): AxiosPromise<void> {
            return localVarFp.addReservedTrialToCartRetool(inlineObject9, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject10} inlineObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRevenues(inlineObject10: InlineObject10, options?: any): AxiosPromise<void> {
            return localVarFp.addRevenues(inlineObject10, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRefundCourseInstance(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<string> {
            return localVarFp.adminRefundCourseInstance(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRevenues(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.cancelRevenues(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTakeRateFlatFeeRevenue(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.changeTakeRateFlatFeeRevenue(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserByAdmin(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<CreateUserByAdminResponse> {
            return localVarFp.createUserByAdmin(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailAccountByAdmin(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEmailAccountByAdmin(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserByAdmin(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PostUserInfoByAdminResponse> {
            return localVarFp.editUserByAdmin(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CourseSessionRequest>} courseSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCourseSessions(courseSessionRequest: Array<CourseSessionRequest>, options?: any): AxiosPromise<CreateCourseSessionResponse> {
            return localVarFp.generateCourseSessions(courseSessionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} dates 
         * @param {number} numberOfSeat 
         * @param {string} [roomTypeId] 
         * @param {string} [keepRoomIdsInList] 
         * @param {string} [exceptRoomBookingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRooms(startTime: string, endTime: string, dates: string, numberOfSeat: number, roomTypeId?: string, keepRoomIdsInList?: string, exceptRoomBookingIds?: string, options?: any): AxiosPromise<Array<AvailableRoomsResponse>> {
            return localVarFp.getAvailableRooms(startTime, endTime, dates, numberOfSeat, roomTypeId, keepRoomIdsInList, exceptRoomBookingIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateUserByAdmin(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PostUserInfoByAdminResponse> {
            return localVarFp.postCreateUserByAdmin(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEPAgreement(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<string> {
            return localVarFp.postEPAgreement(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEPInvoice(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GeneratePDFResponse> {
            return localVarFp.postEPInvoice(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSOAEducationProvider(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GeneratePDFResponse> {
            return localVarFp.postSOAEducationProvider(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<AdminSendMailRequest>} adminSendMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendBatchEmails(adminSendMailRequest: Array<AdminSendMailRequest>, options?: any): AxiosPromise<Array<MessageSendingResponse>> {
            return localVarFp.sendBatchEmails(adminSendMailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailToEPFinance(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.sendEmailToEPFinance(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReplacementEmailAndNotiToEpTeacher(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.sendReplacementEmailAndNotiToEpTeacher(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRescheduleCourseInstanceEP(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<Array<MessageSendingResponse>> {
            return localVarFp.sendRescheduleCourseInstanceEP(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRescheduleEmailAndNotiToEpTeacher(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.sendRescheduleEmailAndNotiToEpTeacher(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTrialEmailAndNotiToEpTeacher(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.sendTrialEmailAndNotiToEpTeacher(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendWithdrawTrialStudentEmail(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<Array<any> | MessageSendingResponse> {
            return localVarFp.sendWithdrawTrialStudentEmail(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserTemporaryPasswordByAdmin(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.setUserTemporaryPasswordByAdmin(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<ValidateCourseSessionRequest>} validateCourseSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCourseSessions(validateCourseSessionRequest: Array<ValidateCourseSessionRequest>, options?: any): AxiosPromise<Array<ValidateCourseSessionResponse>> {
            return localVarFp.validateCourseSessions(validateCourseSessionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        websiteURL(options?: any): AxiosPromise<string> {
            return localVarFp.websiteURL(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RetoolApi - object-oriented interface
 * @export
 * @class RetoolApi
 * @extends {BaseAPI}
 */
export class RetoolApi extends BaseAPI {
    /**
     * 
     * @param {InlineObject9} inlineObject9 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public addReservedTrialToCartRetool(inlineObject9: InlineObject9, options?: any) {
        return RetoolApiFp(this.configuration).addReservedTrialToCartRetool(inlineObject9, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject10} inlineObject10 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public addRevenues(inlineObject10: InlineObject10, options?: any) {
        return RetoolApiFp(this.configuration).addRevenues(inlineObject10, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public adminRefundCourseInstance(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).adminRefundCourseInstance(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public cancelRevenues(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).cancelRevenues(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public changeTakeRateFlatFeeRevenue(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).changeTakeRateFlatFeeRevenue(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public createUserByAdmin(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).createUserByAdmin(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public deleteEmailAccountByAdmin(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).deleteEmailAccountByAdmin(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public editUserByAdmin(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).editUserByAdmin(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<CourseSessionRequest>} courseSessionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public generateCourseSessions(courseSessionRequest: Array<CourseSessionRequest>, options?: any) {
        return RetoolApiFp(this.configuration).generateCourseSessions(courseSessionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} dates 
     * @param {number} numberOfSeat 
     * @param {string} [roomTypeId] 
     * @param {string} [keepRoomIdsInList] 
     * @param {string} [exceptRoomBookingIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public getAvailableRooms(startTime: string, endTime: string, dates: string, numberOfSeat: number, roomTypeId?: string, keepRoomIdsInList?: string, exceptRoomBookingIds?: string, options?: any) {
        return RetoolApiFp(this.configuration).getAvailableRooms(startTime, endTime, dates, numberOfSeat, roomTypeId, keepRoomIdsInList, exceptRoomBookingIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public postCreateUserByAdmin(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).postCreateUserByAdmin(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public postEPAgreement(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).postEPAgreement(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public postEPInvoice(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).postEPInvoice(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public postSOAEducationProvider(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).postSOAEducationProvider(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<AdminSendMailRequest>} adminSendMailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public sendBatchEmails(adminSendMailRequest: Array<AdminSendMailRequest>, options?: any) {
        return RetoolApiFp(this.configuration).sendBatchEmails(adminSendMailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public sendEmailToEPFinance(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).sendEmailToEPFinance(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public sendReplacementEmailAndNotiToEpTeacher(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).sendReplacementEmailAndNotiToEpTeacher(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public sendRescheduleCourseInstanceEP(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).sendRescheduleCourseInstanceEP(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public sendRescheduleEmailAndNotiToEpTeacher(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).sendRescheduleEmailAndNotiToEpTeacher(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public sendTrialEmailAndNotiToEpTeacher(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).sendTrialEmailAndNotiToEpTeacher(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public sendWithdrawTrialStudentEmail(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).sendWithdrawTrialStudentEmail(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public setUserTemporaryPasswordByAdmin(requestBody: { [key: string]: object; }, options?: any) {
        return RetoolApiFp(this.configuration).setUserTemporaryPasswordByAdmin(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<ValidateCourseSessionRequest>} validateCourseSessionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public validateCourseSessions(validateCourseSessionRequest: Array<ValidateCourseSessionRequest>, options?: any) {
        return RetoolApiFp(this.configuration).validateCourseSessions(validateCourseSessionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetoolApi
     */
    public websiteURL(options?: any) {
        return RetoolApiFp(this.configuration).websiteURL(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoomBookingInvoicesApi - axios parameter creator
 * @export
 */
export const RoomBookingInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomBookingInvoiceDetail: async (invoiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('getRoomBookingInvoiceDetail', 'invoiceId', invoiceId)
            const localVarPath = `/room-booking-invoices/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomBookingInvoicesApi - functional programming interface
 * @export
 */
export const RoomBookingInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomBookingInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoomBookingInvoiceDetail(invoiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomBookingInvoicesDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoomBookingInvoiceDetail(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomBookingInvoicesApi - factory interface
 * @export
 */
export const RoomBookingInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomBookingInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomBookingInvoiceDetail(invoiceId: string, options?: any): AxiosPromise<GetRoomBookingInvoicesDetailResponse> {
            return localVarFp.getRoomBookingInvoiceDetail(invoiceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomBookingInvoicesApi - object-oriented interface
 * @export
 * @class RoomBookingInvoicesApi
 * @extends {BaseAPI}
 */
export class RoomBookingInvoicesApi extends BaseAPI {
    /**
     * 
     * @param {string} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomBookingInvoicesApi
     */
    public getRoomBookingInvoiceDetail(invoiceId: string, options?: any) {
        return RoomBookingInvoicesApiFp(this.configuration).getRoomBookingInvoiceDetail(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SchedulesApi - axios parameter creator
 * @export
 */
export const SchedulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attendingSession: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('attendingSession', 'requestBody', requestBody)
            const localVarPath = `/schedules/attending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateEvent: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postCreateEvent', 'requestBody', requestBody)
            const localVarPath = `/schedules/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postListView: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postListView', 'requestBody', requestBody)
            const localVarPath = `/schedules/list-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postScheduleData: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postScheduleData', 'requestBody', requestBody)
            const localVarPath = `/schedules/schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchedulesApi - functional programming interface
 * @export
 */
export const SchedulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchedulesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attendingSession(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostAttendingSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attendingSession(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreateEvent(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreateEvent(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postListView(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostSchedulesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postListView(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postScheduleData(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostSchedulesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postScheduleData(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SchedulesApi - factory interface
 * @export
 */
export const SchedulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchedulesApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attendingSession(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PostAttendingSessionResponse> {
            return localVarFp.attendingSession(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateEvent(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AddEventsResponse> {
            return localVarFp.postCreateEvent(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postListView(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PostSchedulesResponse> {
            return localVarFp.postListView(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postScheduleData(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<PostSchedulesResponse> {
            return localVarFp.postScheduleData(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchedulesApi - object-oriented interface
 * @export
 * @class SchedulesApi
 * @extends {BaseAPI}
 */
export class SchedulesApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulesApi
     */
    public attendingSession(requestBody: { [key: string]: object; }, options?: any) {
        return SchedulesApiFp(this.configuration).attendingSession(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulesApi
     */
    public postCreateEvent(requestBody: { [key: string]: object; }, options?: any) {
        return SchedulesApiFp(this.configuration).postCreateEvent(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulesApi
     */
    public postListView(requestBody: { [key: string]: object; }, options?: any) {
        return SchedulesApiFp(this.configuration).postListView(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulesApi
     */
    public postScheduleData(requestBody: { [key: string]: object; }, options?: any) {
        return SchedulesApiFp(this.configuration).postScheduleData(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShoppingCartApi - axios parameter creator
 * @export
 */
export const ShoppingCartApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} reserveId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReservedTrialToCart: async (reserveId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reserveId' is not null or undefined
            assertParamExists('addReservedTrialToCart', 'reserveId', reserveId)
            const localVarPath = `/shopping-cart/add-reserved-trial/{reserveId}`
                .replace(`{${"reserveId"}}`, encodeURIComponent(String(reserveId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCartItem: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createCartItem', 'requestBody', requestBody)
            const localVarPath = `/shopping-cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBadgeShoppingCart: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopping-cart/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [instanceId] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingCart: async (instanceId?: string, userId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopping-cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (instanceId !== undefined) {
                localVarQueryParameter['instanceId'] = instanceId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cartItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCartItem: async (cartItemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartItemId' is not null or undefined
            assertParamExists('removeCartItem', 'cartItemId', cartItemId)
            const localVarPath = `/shopping-cart/{cartItemId}`
                .replace(`{${"cartItemId"}}`, encodeURIComponent(String(cartItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCartItem: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateCartItem', 'requestBody', requestBody)
            const localVarPath = `/shopping-cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reserveId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateBeforeAddToCard: async (reserveId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reserveId' is not null or undefined
            assertParamExists('validateBeforeAddToCard', 'reserveId', reserveId)
            const localVarPath = `/shopping-cart/valid-reserved-trial/{reserveId}`
                .replace(`{${"reserveId"}}`, encodeURIComponent(String(reserveId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateDiscountCode: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('validateDiscountCode', 'requestBody', requestBody)
            const localVarPath = `/shopping-cart/validate-promo-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateVoucher: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('validateVoucher', 'requestBody', requestBody)
            const localVarPath = `/shopping-cart/validate-voucher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShoppingCartApi - functional programming interface
 * @export
 */
export const ShoppingCartApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShoppingCartApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} reserveId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addReservedTrialToCart(reserveId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addReservedTrialToCart(reserveId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCartItem(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCartItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCartItem(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBadgeShoppingCart(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBadgeShoppingCart(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [instanceId] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShoppingCart(instanceId?: string, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetShoppingCartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShoppingCart(instanceId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} cartItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCartItem(cartItemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCartItem(cartItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCartItem(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCartItem(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reserveId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateBeforeAddToCard(reserveId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateBeforeAddToCard(reserveId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateDiscountCode(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateDiscountCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateDiscountCode(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateVoucher(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateVoucherCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateVoucher(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShoppingCartApi - factory interface
 * @export
 */
export const ShoppingCartApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShoppingCartApiFp(configuration)
    return {
        /**
         * 
         * @param {string} reserveId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReservedTrialToCart(reserveId: string, options?: any): AxiosPromise<void> {
            return localVarFp.addReservedTrialToCart(reserveId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCartItem(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<CreateCartItemResponse> {
            return localVarFp.createCartItem(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBadgeShoppingCart(options?: any): AxiosPromise<number> {
            return localVarFp.getBadgeShoppingCart(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [instanceId] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingCart(instanceId?: string, userId?: string, options?: any): AxiosPromise<GetShoppingCartResponse> {
            return localVarFp.getShoppingCart(instanceId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cartItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCartItem(cartItemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeCartItem(cartItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCartItem(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.updateCartItem(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reserveId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateBeforeAddToCard(reserveId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.validateBeforeAddToCard(reserveId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateDiscountCode(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<ValidateDiscountCodeResponse> {
            return localVarFp.validateDiscountCode(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateVoucher(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<ValidateVoucherCampaignResponse> {
            return localVarFp.validateVoucher(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShoppingCartApi - object-oriented interface
 * @export
 * @class ShoppingCartApi
 * @extends {BaseAPI}
 */
export class ShoppingCartApi extends BaseAPI {
    /**
     * 
     * @param {string} reserveId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartApi
     */
    public addReservedTrialToCart(reserveId: string, options?: any) {
        return ShoppingCartApiFp(this.configuration).addReservedTrialToCart(reserveId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartApi
     */
    public createCartItem(requestBody: { [key: string]: object; }, options?: any) {
        return ShoppingCartApiFp(this.configuration).createCartItem(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartApi
     */
    public getBadgeShoppingCart(options?: any) {
        return ShoppingCartApiFp(this.configuration).getBadgeShoppingCart(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [instanceId] 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartApi
     */
    public getShoppingCart(instanceId?: string, userId?: string, options?: any) {
        return ShoppingCartApiFp(this.configuration).getShoppingCart(instanceId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cartItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartApi
     */
    public removeCartItem(cartItemId: string, options?: any) {
        return ShoppingCartApiFp(this.configuration).removeCartItem(cartItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartApi
     */
    public updateCartItem(requestBody: { [key: string]: object; }, options?: any) {
        return ShoppingCartApiFp(this.configuration).updateCartItem(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reserveId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartApi
     */
    public validateBeforeAddToCard(reserveId: string, options?: any) {
        return ShoppingCartApiFp(this.configuration).validateBeforeAddToCard(reserveId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartApi
     */
    public validateDiscountCode(requestBody: { [key: string]: object; }, options?: any) {
        return ShoppingCartApiFp(this.configuration).validateDiscountCode(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartApi
     */
    public validateVoucher(requestBody: { [key: string]: object; }, options?: any) {
        return ShoppingCartApiFp(this.configuration).validateVoucher(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SqsApi - axios parameter creator
 * @export
 */
export const SqsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processCheckoutRequestExpired: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sqs/checkout-requests/expired`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackFailedQueueMessage: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('trackFailedQueueMessage', 'requestBody', requestBody)
            const localVarPath = `/sqs/track-failed-queue-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SqsApi - functional programming interface
 * @export
 */
export const SqsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SqsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processCheckoutRequestExpired(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processCheckoutRequestExpired(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackFailedQueueMessage(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackFailedQueueMessage(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SqsApi - factory interface
 * @export
 */
export const SqsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SqsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processCheckoutRequestExpired(options?: any): AxiosPromise<void> {
            return localVarFp.processCheckoutRequestExpired(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackFailedQueueMessage(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.trackFailedQueueMessage(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SqsApi - object-oriented interface
 * @export
 * @class SqsApi
 * @extends {BaseAPI}
 */
export class SqsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqsApi
     */
    public processCheckoutRequestExpired(options?: any) {
        return SqsApiFp(this.configuration).processCheckoutRequestExpired(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqsApi
     */
    public trackFailedQueueMessage(requestBody: { [key: string]: object; }, options?: any) {
        return SqsApiFp(this.configuration).trackFailedQueueMessage(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StrapiApi - axios parameter creator
 * @export
 */
export const StrapiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atSchoolGetBrowseCourseHeaderContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/at-school/browse-course-header-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationPaymentTerms: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/confirmation-payment-terms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsPageContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutPageContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/about-page-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraCarePlans: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/agora-care-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraCareTopContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/agora-care-top-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppHelpPage: async (brandSlug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandSlug' is not null or undefined
            assertParamExists('getAppHelpPage', 'brandSlug', brandSlug)
            const localVarPath = `/strapi/app-help-page/{brandSlug}`
                .replace(`{${"brandSlug"}}`, encodeURIComponent(String(brandSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAtSchoolIndexPageContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/at-school-index-page-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogNewDetailPageContent: async (postId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('getBlogNewDetailPageContent', 'postId', postId)
            const localVarPath = `/strapi/blog-new-detail-page-content/{postId}`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogNewPageContent: async (type: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getBlogNewPageContent', 'type', type)
            const localVarPath = `/strapi/blog-new-page-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookAVisitExplanations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/book-a-visit-explanations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrowseCourseButton: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/browse-course-button`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignPageContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/campaign-page-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignShowCasePanel: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/campaign-showcase-panel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChineseAboutUsContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/chinese-about-us-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursePageContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/course-page-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isAtSchool] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursePolicy: async (isAtSchool?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/course-policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isAtSchool !== undefined) {
                localVarQueryParameter['is_at_school'] = isAtSchool;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursesHero: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/courses-hero`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarlyYearPage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/early-year-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarlyYearPlayschoolPage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/early-year-playschool-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarlyYearsCurriculum: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/early-years-curriculum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarlyYearsPricingPage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/early-year-pricing-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventHostingData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/event-hosting-page-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQ: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} faqType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQCategories: async (faqType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'faqType' is not null or undefined
            assertParamExists('getFAQCategories', 'faqType', faqType)
            const localVarPath = `/strapi/faq-categories/{faqType}`
                .replace(`{${"faqType"}}`, encodeURIComponent(String(faqType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQForEP: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/faq-for-ep`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQHero: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/faq-hero`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQOnPages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/faq-on-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [schoolSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFooterContent: async (schoolSlug?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/footer-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (schoolSlug !== undefined) {
                localVarQueryParameter['schoolSlug'] = schoolSlug;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'preschool-playschool' | 'events'} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGalleryPageContent: async (page: 'preschool-playschool' | 'events', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getGalleryPageContent', 'page', page)
            const localVarPath = `/strapi/gallery-page-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGettingThereHero: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/getting-there-hero`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGettingTherePageContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/getting-there-page-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHelpForEp: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/help-for-ep`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHelpPage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/help-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomePageImage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/homepage-image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomePageWhatWeDo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/homepage-what-we-do`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomepageContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/homepage-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHostAnEventWithUsPageContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/host-an-event-with-us-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHowItWorksContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/how-it-works-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJoinUsPageContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/join-us-page-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandingMessageContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/landing-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalAndPrivacyPolicy: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/legal-and-privacy-policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMorningPassPageContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/morning-pass`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlansContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/plans-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicyContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductServicePageContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/product-service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromptMessageAfterSignedUp: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/prompt-message-after-signed-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleStudentScheduleContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/sample-student-schedule-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [courseSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingCartPolicyContents: async (courseSlug?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/shopping-cart-policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (courseSlug !== undefined) {
                localVarQueryParameter['courseSlug'] = courseSlug;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentCareCurriculum: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/student-care-curriculum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentCarePage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/student-care-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentCarePricingHero: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/student-care-pricing-hero`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentCarePricingPage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/student-care-pricing-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentCareTechnologyPage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/student-care-technology-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermsAndConditions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/terms-and-conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermsOfBusiness: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/terms-of-business`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTheSpacePageContent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/the-space-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staticPageMetaKeywords: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/strapi/static-page-meta-keywords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StrapiApi - functional programming interface
 * @export
 */
export const StrapiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StrapiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async atSchoolGetBrowseCourseHeaderContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AtSchoolBrowseCoursesHeaderContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.atSchoolGetBrowseCourseHeaderContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmationPaymentTerms(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConfirmationPaymentTermsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmationPaymentTerms(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventsPageContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventsPageContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAboutPageContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAboutPageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAboutPageContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgoraCarePlans(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgoraPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgoraCarePlans(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgoraCareTopContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgoraTopContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgoraCareTopContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppHelpPage(brandSlug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHelpPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppHelpPage(brandSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAtSchoolIndexPageContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAtSchoolIndexPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAtSchoolIndexPageContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlogNewDetailPageContent(postId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBlogNewDetailContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlogNewDetailPageContent(postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlogNewPageContent(type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBlogNewContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlogNewPageContent(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookAVisitExplanations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookAVisitExplanationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookAVisitExplanations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrowseCourseButton(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrowseCourseButtonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrowseCourseButton(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignPageContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCampaignPageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignPageContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignShowCasePanel(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetShowcaseCampaignPanelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignShowCasePanel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChineseAboutUsContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChineseAboutUsPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChineseAboutUsContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoursePageContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCoursePageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoursePageContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [isAtSchool] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoursePolicy(isAtSchool?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCoursePolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoursePolicy(isAtSchool, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoursesHero(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCoursesHeroResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoursesHero(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEarlyYearPage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EarlyYearPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEarlyYearPage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEarlyYearPlayschoolPage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EarlyYearPagePlayschoolResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEarlyYearPlayschoolPage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEarlyYearsCurriculum(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStudentCareCurriculumPageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEarlyYearsCurriculum(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEarlyYearsPricingPage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEarlyYearPricingPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEarlyYearsPricingPage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventHostingData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventHostingPageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventHostingData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFAQ(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFAQResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFAQ(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} faqType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFAQCategories(faqType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFAQCategoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFAQCategories(faqType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFAQForEP(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFAQResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFAQForEP(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFAQHero(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFAQHeroResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFAQHero(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFAQOnPages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFAQCategoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFAQOnPages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [schoolSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFooterContent(schoolSlug?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FooterContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFooterContent(schoolSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'preschool-playschool' | 'events'} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGalleryPageContent(page: 'preschool-playschool' | 'events', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetGalleryPageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGalleryPageContent(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGettingThereHero(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGettingThereHeroResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGettingThereHero(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGettingTherePageContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGettingTherePageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGettingTherePageContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHelpForEp(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHelpForEpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHelpForEp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHelpPage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHelpPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHelpPage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomePageImage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomePageImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomePageImage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomePageWhatWeDo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomePageWhatWeDoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomePageWhatWeDo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomepageContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomepageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomepageContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHostAnEventWithUsPageContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHostAnEventWithUsPageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHostAnEventWithUsPageContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHowItWorksContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HowItWorksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHowItWorksContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJoinUsPageContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetJoinUsPageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJoinUsPageContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLandingMessageContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLandingMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLandingMessageContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalAndPrivacyPolicy(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLegalAndPrivacyPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalAndPrivacyPolicy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMorningPassPageContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMorningPassPageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMorningPassPageContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlansContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlansContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlansContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPolicyContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPolicyStrapiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPolicyContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductServicePageContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductServicePageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductServicePageContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromptMessageAfterSignedUp(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPromptMessageAfterSignedUp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromptMessageAfterSignedUp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSampleStudentScheduleContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SampleStudentScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSampleStudentScheduleContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [courseSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShoppingCartPolicyContents(courseSlug?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingCartPolicyContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShoppingCartPolicyContents(courseSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentCareCurriculum(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStudentCareCurriculumPageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentCareCurriculum(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentCarePage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentCarePageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentCarePage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentCarePricingHero(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStudentCarePricingHeroResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentCarePricingHero(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentCarePricingPage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStudentCarePricingPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentCarePricingPage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentCareTechnologyPage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStudentCareTechnologyPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentCareTechnologyPage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTermsAndConditions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTermsAndConditionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTermsAndConditions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTermsOfBusiness(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermsOfBusinessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTermsOfBusiness(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTheSpacePageContent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTheSpacePageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTheSpacePageContent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async staticPageMetaKeywords(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaticPageMetaKeywordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.staticPageMetaKeywords(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StrapiApi - factory interface
 * @export
 */
export const StrapiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StrapiApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atSchoolGetBrowseCourseHeaderContent(options?: any): AxiosPromise<AtSchoolBrowseCoursesHeaderContentResponse> {
            return localVarFp.atSchoolGetBrowseCourseHeaderContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationPaymentTerms(options?: any): AxiosPromise<GetConfirmationPaymentTermsResponse> {
            return localVarFp.confirmationPaymentTerms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsPageContent(options?: any): AxiosPromise<EventsPageResponse> {
            return localVarFp.eventsPageContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutPageContent(options?: any): AxiosPromise<GetAboutPageContentResponse> {
            return localVarFp.getAboutPageContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraCarePlans(options?: any): AxiosPromise<GetAgoraPlanResponse> {
            return localVarFp.getAgoraCarePlans(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraCareTopContent(options?: any): AxiosPromise<GetAgoraTopContentResponse> {
            return localVarFp.getAgoraCareTopContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppHelpPage(brandSlug: string, options?: any): AxiosPromise<GetHelpPageResponse> {
            return localVarFp.getAppHelpPage(brandSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAtSchoolIndexPageContent(options?: any): AxiosPromise<GetAtSchoolIndexPageResponse> {
            return localVarFp.getAtSchoolIndexPageContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogNewDetailPageContent(postId: string, options?: any): AxiosPromise<GetBlogNewDetailContentResponse> {
            return localVarFp.getBlogNewDetailPageContent(postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlogNewPageContent(type: string, options?: any): AxiosPromise<GetBlogNewContentResponse> {
            return localVarFp.getBlogNewPageContent(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookAVisitExplanations(options?: any): AxiosPromise<BookAVisitExplanationsResponse> {
            return localVarFp.getBookAVisitExplanations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrowseCourseButton(options?: any): AxiosPromise<BrowseCourseButtonResponse> {
            return localVarFp.getBrowseCourseButton(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignPageContent(options?: any): AxiosPromise<GetCampaignPageContentResponse> {
            return localVarFp.getCampaignPageContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignShowCasePanel(options?: any): AxiosPromise<GetShowcaseCampaignPanelResponse> {
            return localVarFp.getCampaignShowCasePanel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChineseAboutUsContent(options?: any): AxiosPromise<GetChineseAboutUsPageResponse> {
            return localVarFp.getChineseAboutUsContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursePageContent(options?: any): AxiosPromise<GetCoursePageContentResponse> {
            return localVarFp.getCoursePageContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isAtSchool] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursePolicy(isAtSchool?: boolean, options?: any): AxiosPromise<GetCoursePolicyResponse> {
            return localVarFp.getCoursePolicy(isAtSchool, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursesHero(options?: any): AxiosPromise<GetCoursesHeroResponse> {
            return localVarFp.getCoursesHero(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarlyYearPage(options?: any): AxiosPromise<EarlyYearPageResponse> {
            return localVarFp.getEarlyYearPage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarlyYearPlayschoolPage(options?: any): AxiosPromise<EarlyYearPagePlayschoolResponse> {
            return localVarFp.getEarlyYearPlayschoolPage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarlyYearsCurriculum(options?: any): AxiosPromise<GetStudentCareCurriculumPageContentResponse> {
            return localVarFp.getEarlyYearsCurriculum(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarlyYearsPricingPage(options?: any): AxiosPromise<GetEarlyYearPricingPageResponse> {
            return localVarFp.getEarlyYearsPricingPage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventHostingData(options?: any): AxiosPromise<EventHostingPageContentResponse> {
            return localVarFp.getEventHostingData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQ(options?: any): AxiosPromise<GetFAQResponse> {
            return localVarFp.getFAQ(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} faqType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQCategories(faqType: string, options?: any): AxiosPromise<GetFAQCategoriesResponse> {
            return localVarFp.getFAQCategories(faqType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQForEP(options?: any): AxiosPromise<GetFAQResponse> {
            return localVarFp.getFAQForEP(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQHero(options?: any): AxiosPromise<GetFAQHeroResponse> {
            return localVarFp.getFAQHero(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQOnPages(options?: any): AxiosPromise<GetFAQCategoriesResponse> {
            return localVarFp.getFAQOnPages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [schoolSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFooterContent(schoolSlug?: string, options?: any): AxiosPromise<FooterContentResponse> {
            return localVarFp.getFooterContent(schoolSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'preschool-playschool' | 'events'} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGalleryPageContent(page: 'preschool-playschool' | 'events', options?: any): AxiosPromise<IGetGalleryPageContentResponse> {
            return localVarFp.getGalleryPageContent(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGettingThereHero(options?: any): AxiosPromise<GetGettingThereHeroResponse> {
            return localVarFp.getGettingThereHero(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGettingTherePageContent(options?: any): AxiosPromise<GetGettingTherePageContentResponse> {
            return localVarFp.getGettingTherePageContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHelpForEp(options?: any): AxiosPromise<GetHelpForEpResponse> {
            return localVarFp.getHelpForEp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHelpPage(options?: any): AxiosPromise<GetHelpPageResponse> {
            return localVarFp.getHelpPage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomePageImage(options?: any): AxiosPromise<GetHomePageImageResponse> {
            return localVarFp.getHomePageImage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomePageWhatWeDo(options?: any): AxiosPromise<GetHomePageWhatWeDoResponse> {
            return localVarFp.getHomePageWhatWeDo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomepageContent(options?: any): AxiosPromise<GetHomepageContentResponse> {
            return localVarFp.getHomepageContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHostAnEventWithUsPageContent(options?: any): AxiosPromise<GetHostAnEventWithUsPageContentResponse> {
            return localVarFp.getHostAnEventWithUsPageContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHowItWorksContent(options?: any): AxiosPromise<HowItWorksResponse> {
            return localVarFp.getHowItWorksContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJoinUsPageContent(options?: any): AxiosPromise<GetJoinUsPageContentResponse> {
            return localVarFp.getJoinUsPageContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandingMessageContent(options?: any): AxiosPromise<GetLandingMessageResponse> {
            return localVarFp.getLandingMessageContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalAndPrivacyPolicy(options?: any): AxiosPromise<GetLegalAndPrivacyPolicyResponse> {
            return localVarFp.getLegalAndPrivacyPolicy(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMorningPassPageContent(options?: any): AxiosPromise<GetMorningPassPageContentResponse> {
            return localVarFp.getMorningPassPageContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlansContent(options?: any): AxiosPromise<PlansContentResponse> {
            return localVarFp.getPlansContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicyContent(options?: any): AxiosPromise<GetPolicyStrapiResponse> {
            return localVarFp.getPolicyContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductServicePageContent(options?: any): AxiosPromise<GetProductServicePageContentResponse> {
            return localVarFp.getProductServicePageContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromptMessageAfterSignedUp(options?: any): AxiosPromise<GetPromptMessageAfterSignedUp> {
            return localVarFp.getPromptMessageAfterSignedUp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleStudentScheduleContent(options?: any): AxiosPromise<SampleStudentScheduleResponse> {
            return localVarFp.getSampleStudentScheduleContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [courseSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingCartPolicyContents(courseSlug?: string, options?: any): AxiosPromise<ShoppingCartPolicyContentResponse> {
            return localVarFp.getShoppingCartPolicyContents(courseSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentCareCurriculum(options?: any): AxiosPromise<GetStudentCareCurriculumPageContentResponse> {
            return localVarFp.getStudentCareCurriculum(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentCarePage(options?: any): AxiosPromise<StudentCarePageResponse> {
            return localVarFp.getStudentCarePage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentCarePricingHero(options?: any): AxiosPromise<GetStudentCarePricingHeroResponse> {
            return localVarFp.getStudentCarePricingHero(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentCarePricingPage(options?: any): AxiosPromise<GetStudentCarePricingPageResponse> {
            return localVarFp.getStudentCarePricingPage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentCareTechnologyPage(options?: any): AxiosPromise<GetStudentCareTechnologyPageResponse> {
            return localVarFp.getStudentCareTechnologyPage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermsAndConditions(options?: any): AxiosPromise<GetTermsAndConditionsResponse> {
            return localVarFp.getTermsAndConditions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermsOfBusiness(options?: any): AxiosPromise<TermsOfBusinessResponse> {
            return localVarFp.getTermsOfBusiness(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTheSpacePageContent(options?: any): AxiosPromise<GetTheSpacePageContentResponse> {
            return localVarFp.getTheSpacePageContent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staticPageMetaKeywords(options?: any): AxiosPromise<StaticPageMetaKeywordResponse> {
            return localVarFp.staticPageMetaKeywords(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StrapiApi - object-oriented interface
 * @export
 * @class StrapiApi
 * @extends {BaseAPI}
 */
export class StrapiApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public atSchoolGetBrowseCourseHeaderContent(options?: any) {
        return StrapiApiFp(this.configuration).atSchoolGetBrowseCourseHeaderContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public confirmationPaymentTerms(options?: any) {
        return StrapiApiFp(this.configuration).confirmationPaymentTerms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public eventsPageContent(options?: any) {
        return StrapiApiFp(this.configuration).eventsPageContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getAboutPageContent(options?: any) {
        return StrapiApiFp(this.configuration).getAboutPageContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getAgoraCarePlans(options?: any) {
        return StrapiApiFp(this.configuration).getAgoraCarePlans(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getAgoraCareTopContent(options?: any) {
        return StrapiApiFp(this.configuration).getAgoraCareTopContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getAppHelpPage(brandSlug: string, options?: any) {
        return StrapiApiFp(this.configuration).getAppHelpPage(brandSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getAtSchoolIndexPageContent(options?: any) {
        return StrapiApiFp(this.configuration).getAtSchoolIndexPageContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getBlogNewDetailPageContent(postId: string, options?: any) {
        return StrapiApiFp(this.configuration).getBlogNewDetailPageContent(postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getBlogNewPageContent(type: string, options?: any) {
        return StrapiApiFp(this.configuration).getBlogNewPageContent(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getBookAVisitExplanations(options?: any) {
        return StrapiApiFp(this.configuration).getBookAVisitExplanations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getBrowseCourseButton(options?: any) {
        return StrapiApiFp(this.configuration).getBrowseCourseButton(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getCampaignPageContent(options?: any) {
        return StrapiApiFp(this.configuration).getCampaignPageContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getCampaignShowCasePanel(options?: any) {
        return StrapiApiFp(this.configuration).getCampaignShowCasePanel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getChineseAboutUsContent(options?: any) {
        return StrapiApiFp(this.configuration).getChineseAboutUsContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getCoursePageContent(options?: any) {
        return StrapiApiFp(this.configuration).getCoursePageContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isAtSchool] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getCoursePolicy(isAtSchool?: boolean, options?: any) {
        return StrapiApiFp(this.configuration).getCoursePolicy(isAtSchool, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getCoursesHero(options?: any) {
        return StrapiApiFp(this.configuration).getCoursesHero(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getEarlyYearPage(options?: any) {
        return StrapiApiFp(this.configuration).getEarlyYearPage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getEarlyYearPlayschoolPage(options?: any) {
        return StrapiApiFp(this.configuration).getEarlyYearPlayschoolPage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getEarlyYearsCurriculum(options?: any) {
        return StrapiApiFp(this.configuration).getEarlyYearsCurriculum(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getEarlyYearsPricingPage(options?: any) {
        return StrapiApiFp(this.configuration).getEarlyYearsPricingPage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getEventHostingData(options?: any) {
        return StrapiApiFp(this.configuration).getEventHostingData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getFAQ(options?: any) {
        return StrapiApiFp(this.configuration).getFAQ(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} faqType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getFAQCategories(faqType: string, options?: any) {
        return StrapiApiFp(this.configuration).getFAQCategories(faqType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getFAQForEP(options?: any) {
        return StrapiApiFp(this.configuration).getFAQForEP(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getFAQHero(options?: any) {
        return StrapiApiFp(this.configuration).getFAQHero(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getFAQOnPages(options?: any) {
        return StrapiApiFp(this.configuration).getFAQOnPages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [schoolSlug] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getFooterContent(schoolSlug?: string, options?: any) {
        return StrapiApiFp(this.configuration).getFooterContent(schoolSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'preschool-playschool' | 'events'} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getGalleryPageContent(page: 'preschool-playschool' | 'events', options?: any) {
        return StrapiApiFp(this.configuration).getGalleryPageContent(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getGettingThereHero(options?: any) {
        return StrapiApiFp(this.configuration).getGettingThereHero(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getGettingTherePageContent(options?: any) {
        return StrapiApiFp(this.configuration).getGettingTherePageContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getHelpForEp(options?: any) {
        return StrapiApiFp(this.configuration).getHelpForEp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getHelpPage(options?: any) {
        return StrapiApiFp(this.configuration).getHelpPage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getHomePageImage(options?: any) {
        return StrapiApiFp(this.configuration).getHomePageImage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getHomePageWhatWeDo(options?: any) {
        return StrapiApiFp(this.configuration).getHomePageWhatWeDo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getHomepageContent(options?: any) {
        return StrapiApiFp(this.configuration).getHomepageContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getHostAnEventWithUsPageContent(options?: any) {
        return StrapiApiFp(this.configuration).getHostAnEventWithUsPageContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getHowItWorksContent(options?: any) {
        return StrapiApiFp(this.configuration).getHowItWorksContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getJoinUsPageContent(options?: any) {
        return StrapiApiFp(this.configuration).getJoinUsPageContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getLandingMessageContent(options?: any) {
        return StrapiApiFp(this.configuration).getLandingMessageContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getLegalAndPrivacyPolicy(options?: any) {
        return StrapiApiFp(this.configuration).getLegalAndPrivacyPolicy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getMorningPassPageContent(options?: any) {
        return StrapiApiFp(this.configuration).getMorningPassPageContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getPlansContent(options?: any) {
        return StrapiApiFp(this.configuration).getPlansContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getPolicyContent(options?: any) {
        return StrapiApiFp(this.configuration).getPolicyContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getProductServicePageContent(options?: any) {
        return StrapiApiFp(this.configuration).getProductServicePageContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getPromptMessageAfterSignedUp(options?: any) {
        return StrapiApiFp(this.configuration).getPromptMessageAfterSignedUp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getSampleStudentScheduleContent(options?: any) {
        return StrapiApiFp(this.configuration).getSampleStudentScheduleContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [courseSlug] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getShoppingCartPolicyContents(courseSlug?: string, options?: any) {
        return StrapiApiFp(this.configuration).getShoppingCartPolicyContents(courseSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getStudentCareCurriculum(options?: any) {
        return StrapiApiFp(this.configuration).getStudentCareCurriculum(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getStudentCarePage(options?: any) {
        return StrapiApiFp(this.configuration).getStudentCarePage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getStudentCarePricingHero(options?: any) {
        return StrapiApiFp(this.configuration).getStudentCarePricingHero(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getStudentCarePricingPage(options?: any) {
        return StrapiApiFp(this.configuration).getStudentCarePricingPage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getStudentCareTechnologyPage(options?: any) {
        return StrapiApiFp(this.configuration).getStudentCareTechnologyPage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getTermsAndConditions(options?: any) {
        return StrapiApiFp(this.configuration).getTermsAndConditions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getTermsOfBusiness(options?: any) {
        return StrapiApiFp(this.configuration).getTermsOfBusiness(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public getTheSpacePageContent(options?: any) {
        return StrapiApiFp(this.configuration).getTheSpacePageContent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrapiApi
     */
    public staticPageMetaKeywords(options?: any) {
        return StrapiApiFp(this.configuration).staticPageMetaKeywords(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StudentsApi - axios parameter creator
 * @export
 */
export const StudentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {boolean} [assignedStudentsOnly] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStudents: async (search?: string, assignedStudentsOnly?: boolean, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (assignedStudentsOnly !== undefined) {
                localVarQueryParameter['assigned_students_only'] = assignedStudentsOnly;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactableTeacherByStudentId: async (studentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('getContactableTeacherByStudentId', 'studentId', studentId)
            const localVarPath = `/students/{studentId}/contactable-teachers`
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentsApi - functional programming interface
 * @export
 */
export const StudentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {boolean} [assignedStudentsOnly] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllStudents(search?: string, assignedStudentsOnly?: boolean, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetAllStudentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllStudents(search, assignedStudentsOnly, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactableTeacherByStudentId(studentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetContactableTeacherByStudentIdResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactableTeacherByStudentId(studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StudentsApi - factory interface
 * @export
 */
export const StudentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {boolean} [assignedStudentsOnly] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStudents(search?: string, assignedStudentsOnly?: boolean, limit?: number, offset?: number, options?: any): AxiosPromise<IGetAllStudentsResponse> {
            return localVarFp.getAllStudents(search, assignedStudentsOnly, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactableTeacherByStudentId(studentId: string, options?: any): AxiosPromise<GetContactableTeacherByStudentIdResponseDto> {
            return localVarFp.getContactableTeacherByStudentId(studentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudentsApi - object-oriented interface
 * @export
 * @class StudentsApi
 * @extends {BaseAPI}
 */
export class StudentsApi extends BaseAPI {
    /**
     * 
     * @param {string} [search] 
     * @param {boolean} [assignedStudentsOnly] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public getAllStudents(search?: string, assignedStudentsOnly?: boolean, limit?: number, offset?: number, options?: any) {
        return StudentsApiFp(this.configuration).getAllStudents(search, assignedStudentsOnly, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public getContactableTeacherByStudentId(studentId: string, options?: any) {
        return StudentsApiFp(this.configuration).getContactableTeacherByStudentId(studentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubjectsApi - axios parameter creator
 * @export
 */
export const SubjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} subjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubject: async (subjectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subjectId' is not null or undefined
            assertParamExists('getSubject', 'subjectId', subjectId)
            const localVarPath = `/subjects/subject/{subjectId}`
                .replace(`{${"subjectId"}}`, encodeURIComponent(String(subjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubjects: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [subjectType] 
         * @param {string} [areaId] 
         * @param {string} [ageGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubjectsWith: async (subjectType?: string, areaId?: string, ageGroupId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subjects/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (subjectType !== undefined) {
                localVarQueryParameter['subjectType'] = subjectType;
            }

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (ageGroupId !== undefined) {
                localVarQueryParameter['ageGroupId'] = ageGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subjects/support-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubjectsApi - functional programming interface
 * @export
 */
export const SubjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} subjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubject(subjectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubject(subjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubjects(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [subjectType] 
         * @param {string} [areaId] 
         * @param {string} [ageGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubjectsWith(subjectType?: string, areaId?: string, ageGroupId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubjectsWithResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubjectsWith(subjectType, areaId, ageGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSupportDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupportData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubjectsApi - factory interface
 * @export
 */
export const SubjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubjectsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} subjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubject(subjectId: string, options?: any): AxiosPromise<GetSubjectResponse> {
            return localVarFp.getSubject(subjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubjects(options?: any): AxiosPromise<GetSubjectsResponse> {
            return localVarFp.getSubjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [subjectType] 
         * @param {string} [areaId] 
         * @param {string} [ageGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubjectsWith(subjectType?: string, areaId?: string, ageGroupId?: string, options?: any): AxiosPromise<GetSubjectsWithResponse> {
            return localVarFp.getSubjectsWith(subjectType, areaId, ageGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportData(options?: any): AxiosPromise<GetSupportDataResponse> {
            return localVarFp.getSupportData(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubjectsApi - object-oriented interface
 * @export
 * @class SubjectsApi
 * @extends {BaseAPI}
 */
export class SubjectsApi extends BaseAPI {
    /**
     * 
     * @param {string} subjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public getSubject(subjectId: string, options?: any) {
        return SubjectsApiFp(this.configuration).getSubject(subjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public getSubjects(options?: any) {
        return SubjectsApiFp(this.configuration).getSubjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [subjectType] 
     * @param {string} [areaId] 
     * @param {string} [ageGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public getSubjectsWith(subjectType?: string, areaId?: string, ageGroupId?: string, options?: any) {
        return SubjectsApiFp(this.configuration).getSubjectsWith(subjectType, areaId, ageGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public getSupportData(options?: any) {
        return SubjectsApiFp(this.configuration).getSupportData(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'recurring-payments' | 'recurring-payment-lines'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurringPayments: async (type?: 'recurring-payments' | 'recurring-payment-lines', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscription/recurring-payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusRecurringBilling: async (cardId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('getStatusRecurringBilling', 'cardId', cardId)
            const localVarPath = `/subscription/recurring-billing/{cardId}`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionDetail: async (referenceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('getSubscriptionDetail', 'referenceId', referenceId)
            const localVarPath = `/subscription/detail/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleAfterChargeRecurring: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscription/handle-after-charge-recurring`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleAfterSavedCard: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('handleAfterSavedCard', 'requestBody', requestBody)
            const localVarPath = `/subscription/handle-after-saved-card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSubscriptionWebhook: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscription/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserConfirmSubscription: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postUserConfirmSubscription', 'requestBody', requestBody)
            const localVarPath = `/subscription/confirm-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCardForBilling: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('saveCardForBilling', 'requestBody', requestBody)
            const localVarPath = `/subscription/recurring-billing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'recurring-payments' | 'recurring-payment-lines'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecurringPayments(type?: 'recurring-payments' | 'recurring-payment-lines', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecurringPaymentResponseInterface>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecurringPayments(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusRecurringBilling(cardId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatusRecurringBillingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatusRecurringBilling(cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionDetail(referenceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSubscriptionByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionDetail(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleAfterChargeRecurring(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleAfterChargeRecurring(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleAfterSavedCard(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleAfterSavedCard(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSubscriptionWebhook(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSubscriptionWebhook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserConfirmSubscription(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserConfirmSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserConfirmSubscription(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCardForBilling(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveCardForBillingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCardForBilling(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionApiFp(configuration)
    return {
        /**
         * 
         * @param {'recurring-payments' | 'recurring-payment-lines'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurringPayments(type?: 'recurring-payments' | 'recurring-payment-lines', options?: any): AxiosPromise<GetRecurringPaymentResponseInterface> {
            return localVarFp.getRecurringPayments(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusRecurringBilling(cardId: string, options?: any): AxiosPromise<GetStatusRecurringBillingResponse> {
            return localVarFp.getStatusRecurringBilling(cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionDetail(referenceId: string, options?: any): AxiosPromise<AdminGetSubscriptionByIdResponse> {
            return localVarFp.getSubscriptionDetail(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleAfterChargeRecurring(options?: any): AxiosPromise<void> {
            return localVarFp.handleAfterChargeRecurring(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleAfterSavedCard(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.handleAfterSavedCard(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSubscriptionWebhook(options?: any): AxiosPromise<void> {
            return localVarFp.postSubscriptionWebhook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserConfirmSubscription(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<UserConfirmSubscriptionResponse> {
            return localVarFp.postUserConfirmSubscription(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCardForBilling(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<SaveCardForBillingResponse> {
            return localVarFp.saveCardForBilling(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * 
     * @param {'recurring-payments' | 'recurring-payment-lines'} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getRecurringPayments(type?: 'recurring-payments' | 'recurring-payment-lines', options?: any) {
        return SubscriptionApiFp(this.configuration).getRecurringPayments(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getStatusRecurringBilling(cardId: string, options?: any) {
        return SubscriptionApiFp(this.configuration).getStatusRecurringBilling(cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getSubscriptionDetail(referenceId: string, options?: any) {
        return SubscriptionApiFp(this.configuration).getSubscriptionDetail(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public handleAfterChargeRecurring(options?: any) {
        return SubscriptionApiFp(this.configuration).handleAfterChargeRecurring(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public handleAfterSavedCard(requestBody: { [key: string]: object; }, options?: any) {
        return SubscriptionApiFp(this.configuration).handleAfterSavedCard(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public postSubscriptionWebhook(options?: any) {
        return SubscriptionApiFp(this.configuration).postSubscriptionWebhook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public postUserConfirmSubscription(requestBody: { [key: string]: object; }, options?: any) {
        return SubscriptionApiFp(this.configuration).postUserConfirmSubscription(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public saveCardForBilling(requestBody: { [key: string]: object; }, options?: any) {
        return SubscriptionApiFp(this.configuration).saveCardForBilling(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeachersApi - axios parameter creator
 * @export
 */
export const TeachersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMessageGroupByTeacher: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addMessageGroupByTeacher', 'requestBody', requestBody)
            const localVarPath = `/teachers/add-message-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateRoomBookingByTeacher: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createOrUpdateRoomBookingByTeacher', 'requestBody', requestBody)
            const localVarPath = `/teachers/room-booking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teachers/agora-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {string} courseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttendances: async (courseInstanceId: string, courseSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseInstanceId' is not null or undefined
            assertParamExists('getAttendances', 'courseInstanceId', courseInstanceId)
            // verify required parameter 'courseSessionId' is not null or undefined
            assertParamExists('getAttendances', 'courseSessionId', courseSessionId)
            const localVarPath = `/teachers/attendances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (courseInstanceId !== undefined) {
                localVarQueryParameter['courseInstanceId'] = courseInstanceId;
            }

            if (courseSessionId !== undefined) {
                localVarQueryParameter['courseSessionId'] = courseSessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} messageGroupId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationDetailForTeacher: async (messageGroupId: string, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageGroupId' is not null or undefined
            assertParamExists('getConversationDetailForTeacher', 'messageGroupId', messageGroupId)
            const localVarPath = `/teachers/conversation-detail-teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (messageGroupId !== undefined) {
                localVarQueryParameter['messageGroupId'] = messageGroupId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationListForTeacher: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getConversationListForTeacher', 'requestBody', requestBody)
            const localVarPath = `/teachers/conversation-teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseDetails: async (courseInstanceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseInstanceId' is not null or undefined
            assertParamExists('getCourseDetails', 'courseInstanceId', courseInstanceId)
            const localVarPath = `/teachers/course-details/{courseInstanceId}`
                .replace(`{${"courseInstanceId"}}`, encodeURIComponent(String(courseInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSessionsByDate: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getCourseSessionsByDate', 'requestBody', requestBody)
            const localVarPath = `/teachers/course-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSessionsByDateDeprecated: async (date: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getCourseSessionsByDateDeprecated', 'date', date)
            const localVarPath = `/teachers/course-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSessionsById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourseSessionsById', 'id', id)
            const localVarPath = `/teachers/course-sessions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [courseInstanceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudents: async (search?: string, courseInstanceIds?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teachers/students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (courseInstanceIds !== undefined) {
                localVarQueryParameter['courseInstanceIds'] = courseInstanceIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teachers/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        seenMessages: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('seenMessages', 'requestBody', requestBody)
            const localVarPath = `/teachers/seen-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendBroadcast: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('sendBroadcast', 'requestBody', requestBody)
            const localVarPath = `/teachers/send-broadcast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('sendMessage', 'requestBody', requestBody)
            const localVarPath = `/teachers/send-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherAddMediaList: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('teacherAddMediaList', 'requestBody', requestBody)
            const localVarPath = `/teachers/room-checkin/add/media-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherAddRoomCheckInMedia: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('teacherAddRoomCheckInMedia', 'requestBody', requestBody)
            const localVarPath = `/teachers/room-checkin/add/room-checkin-media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {{ [key: string]: object; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCheckOutMultipleStudents: async (sessionId: string, requestBody?: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('teacherCheckOutMultipleStudents', 'sessionId', sessionId)
            const localVarPath = `/teachers/check-out-sessions/{sessionId}/multiple-students`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCheckinCheckoutRoom: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('teacherCheckinCheckoutRoom', 'requestBody', requestBody)
            const localVarPath = `/teachers/room-checkin/add/checkin-checkout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCheckoutSingleStudent: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('teacherCheckoutSingleStudent', 'requestBody', requestBody)
            const localVarPath = `/teachers/check-out-sessions/single-student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateOrUpdateMessageRequest} adminCreateOrUpdateMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCountParentMessageRecipient: async (adminCreateOrUpdateMessageRequest: AdminCreateOrUpdateMessageRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateOrUpdateMessageRequest' is not null or undefined
            assertParamExists('teacherCountParentMessageRecipient', 'adminCreateOrUpdateMessageRequest', adminCreateOrUpdateMessageRequest)
            const localVarPath = `/teachers/photo-updates/count-parent-message-recipient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateOrUpdateMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDeletePhotoUpdates: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('teacherDeletePhotoUpdates', 'requestBody', requestBody)
            const localVarPath = `/teachers/photo-updates/delete-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherEditCheckInRoom: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('teacherEditCheckInRoom', 'requestBody', requestBody)
            const localVarPath = `/teachers/room-checkin/edit/checkin-checkout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherEditMediaList: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('teacherEditMediaList', 'requestBody', requestBody)
            const localVarPath = `/teachers/room-checkin/edit/media-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherEditPhotoUpdates: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('teacherEditPhotoUpdates', 'requestBody', requestBody)
            const localVarPath = `/teachers/photo-updates/edit-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherEditRoomCheckInMedia: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('teacherEditRoomCheckInMedia', 'requestBody', requestBody)
            const localVarPath = `/teachers/room-checkin/edit/room-checkin-media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetAvailableRooms: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('teacherGetAvailableRooms', 'requestBody', requestBody)
            const localVarPath = `/teachers/available-rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetCategoriesForSendMessage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teachers/photo-updates/select/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetClassesForSendMessage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teachers/photo-updates/select/classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetCourseClasses: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('teacherGetCourseClasses', 'courseId', courseId)
            const localVarPath = `/teachers/course/{courseId}/classes`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetCourseInstances: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teachers/course-instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetCourseInstancesForSendMessage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teachers/photo-updates/select/course-instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetParentInfo: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teacherGetParentInfo', 'id', id)
            const localVarPath = `/teachers/parent-info/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetParentsForSendMessage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teachers/photo-updates/select/parents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetPhotoUpdateDetails: async (messageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('teacherGetPhotoUpdateDetails', 'messageId', messageId)
            const localVarPath = `/teachers/photo-updates/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetPresignedPutURL: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('teacherGetPresignedPutURL', 'requestBody', requestBody)
            const localVarPath = `/teachers/get/presigned-put-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetRoomCheckIn: async (courseSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseSessionId' is not null or undefined
            assertParamExists('teacherGetRoomCheckIn', 'courseSessionId', courseSessionId)
            const localVarPath = `/teachers/room-checkin/get/{courseSessionId}`
                .replace(`{${"courseSessionId"}}`, encodeURIComponent(String(courseSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetStudentNotes: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('teacherGetStudentNotes', 'requestBody', requestBody)
            const localVarPath = `/teachers/student-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetStudentsByParentIdForSendMessage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teachers/photo-updates/select/students-by-parents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherPostPhotoUpdateList: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('teacherPostPhotoUpdateList', 'requestBody', requestBody)
            const localVarPath = `/teachers/photo-updates/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherSaveDeviceToken: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('teacherSaveDeviceToken', 'requestBody', requestBody)
            const localVarPath = `/teachers/save-device-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateOrUpdateMessageRequest} adminCreateOrUpdateMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherSendPhotoUpdates: async (adminCreateOrUpdateMessageRequest: AdminCreateOrUpdateMessageRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateOrUpdateMessageRequest' is not null or undefined
            assertParamExists('teacherSendPhotoUpdates', 'adminCreateOrUpdateMessageRequest', adminCreateOrUpdateMessageRequest)
            const localVarPath = `/teachers/photo-updates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateOrUpdateMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentCourseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUndoCheckin: async (studentCourseSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentCourseSessionId' is not null or undefined
            assertParamExists('teacherUndoCheckin', 'studentCourseSessionId', studentCourseSessionId)
            const localVarPath = `/teachers/undo-check-in/{studentCourseSessionId}`
                .replace(`{${"studentCourseSessionId"}}`, encodeURIComponent(String(studentCourseSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentCourseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUndoCheckout: async (studentCourseSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentCourseSessionId' is not null or undefined
            assertParamExists('teacherUndoCheckout', 'studentCourseSessionId', studentCourseSessionId)
            const localVarPath = `/teachers/undo-check-out/{studentCourseSessionId}`
                .replace(`{${"studentCourseSessionId"}}`, encodeURIComponent(String(studentCourseSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherVenuesSelect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teachers/select/venues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAttendance: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateAttendance', 'requestBody', requestBody)
            const localVarPath = `/teachers/attendance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileByTeacher: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('uploadFileByTeacher', 'requestBody', requestBody)
            const localVarPath = `/teachers/upload-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeachersApi - functional programming interface
 * @export
 */
export const TeachersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeachersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMessageGroupByTeacher(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMessageGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMessageGroupByTeacher(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateRoomBookingByTeacher(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateRoomBookingByTeacher(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgoraInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgoraInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {string} courseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttendances(courseInstanceId: string, courseSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAttendancesTeacherResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttendances(courseInstanceId, courseSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} messageGroupId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationDetailForTeacher(messageGroupId: string, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConversationDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationDetailForTeacher(messageGroupId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationListForTeacher(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConversationListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationListForTeacher(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseDetails(courseInstanceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseDetailsTeacherResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseDetails(courseInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseSessionsByDate(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseSessionTeacherDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseSessionsByDate(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseSessionsByDateDeprecated(date: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseSessionTeacherDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseSessionsByDateDeprecated(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseSessionsById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseSessionDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseSessionsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [courseInstanceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudents(search?: string, courseInstanceIds?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTeacherConversationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudents(search, courseInstanceIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeacherInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeacherInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async seenMessages(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.seenMessages(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendBroadcast(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendBroadcast(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessage(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMessage(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherAddMediaList(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherAddRoomMediaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherAddMediaList(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherAddRoomCheckInMedia(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherAddRoomCheckInMedia(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {{ [key: string]: object; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherCheckOutMultipleStudents(sessionId: string, requestBody?: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherCheckOutMultipleStudents(sessionId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherCheckinCheckoutRoom(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherCheckInRoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherCheckinCheckoutRoom(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherCheckoutSingleStudent(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherCheckoutSingleStudent(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminCreateOrUpdateMessageRequest} adminCreateOrUpdateMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherCountParentMessageRecipient(adminCreateOrUpdateMessageRequest: AdminCreateOrUpdateMessageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherCountParentMessageRecipient(adminCreateOrUpdateMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherDeletePhotoUpdates(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherDeletePhotoUpdates(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherEditCheckInRoom(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherCheckInRoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherEditCheckInRoom(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherEditMediaList(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherAddRoomMediaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherEditMediaList(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherEditPhotoUpdates(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherEditPhotoUpdates(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherEditRoomCheckInMedia(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherEditRoomCheckInMedia(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherGetAvailableRooms(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableRoomsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherGetAvailableRooms(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherGetCategoriesForSendMessage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCategoryParentMessageListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherGetCategoriesForSendMessage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherGetClassesForSendMessage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetClassesForMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherGetClassesForSendMessage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherGetCourseClasses(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseClassesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherGetCourseClasses(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherGetCourseInstances(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseInstancesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherGetCourseInstances(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherGetCourseInstancesForSendMessage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCourseInstancesForMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherGetCourseInstancesForSendMessage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherGetParentInfo(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherGetParentInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherGetParentsForSendMessage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetParentsForMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherGetParentsForSendMessage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherGetPhotoUpdateDetails(messageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherPhotoUpdateDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherGetPhotoUpdateDetails(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherGetPresignedPutURL(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPresignedPutURLResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherGetPresignedPutURL(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherGetRoomCheckIn(courseSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherGetRoomCheckinResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherGetRoomCheckIn(courseSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherGetStudentNotes(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherPostStudentNotesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherGetStudentNotes(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherGetStudentsByParentIdForSendMessage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetStudentsForMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherGetStudentsByParentIdForSendMessage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherPostPhotoUpdateList(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherPhotoUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherPostPhotoUpdateList(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherSaveDeviceToken(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherSaveDeviceToken(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminCreateOrUpdateMessageRequest} adminCreateOrUpdateMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherSendPhotoUpdates(adminCreateOrUpdateMessageRequest: AdminCreateOrUpdateMessageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherSendPhotoUpdates(adminCreateOrUpdateMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentCourseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherUndoCheckin(studentCourseSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherUndoCheckin(studentCourseSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentCourseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherUndoCheckout(studentCourseSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherUndoCheckout(studentCourseSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherVenuesSelect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherVenuesSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAttendance(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAttendance(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFileByTeacher(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFileByTeacher(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeachersApi - factory interface
 * @export
 */
export const TeachersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeachersApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMessageGroupByTeacher(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<CreateMessageGroupResponse> {
            return localVarFp.addMessageGroupByTeacher(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateRoomBookingByTeacher(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.createOrUpdateRoomBookingByTeacher(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgoraInfo(options?: any): AxiosPromise<GetCompanyResponse> {
            return localVarFp.getAgoraInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {string} courseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttendances(courseInstanceId: string, courseSessionId: string, options?: any): AxiosPromise<GetAttendancesTeacherResponse> {
            return localVarFp.getAttendances(courseInstanceId, courseSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} messageGroupId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationDetailForTeacher(messageGroupId: string, offset?: number, limit?: number, options?: any): AxiosPromise<GetConversationDetailResponse> {
            return localVarFp.getConversationDetailForTeacher(messageGroupId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationListForTeacher(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetConversationListResponse> {
            return localVarFp.getConversationListForTeacher(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseDetails(courseInstanceId: string, options?: any): AxiosPromise<GetCourseDetailsTeacherResponse> {
            return localVarFp.getCourseDetails(courseInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSessionsByDate(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<Array<CourseSessionTeacherDto>> {
            return localVarFp.getCourseSessionsByDate(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSessionsByDateDeprecated(date: string, options?: any): AxiosPromise<Array<CourseSessionTeacherDto>> {
            return localVarFp.getCourseSessionsByDateDeprecated(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSessionsById(id: string, options?: any): AxiosPromise<GetCourseSessionDetailsResponse> {
            return localVarFp.getCourseSessionsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [courseInstanceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudents(search?: string, courseInstanceIds?: string, options?: any): AxiosPromise<GetTeacherConversationResponse> {
            return localVarFp.getStudents(search, courseInstanceIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherInfo(options?: any): AxiosPromise<UserDto> {
            return localVarFp.getTeacherInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        seenMessages(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.seenMessages(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendBroadcast(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<boolean> {
            return localVarFp.sendBroadcast(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.sendMessage(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherAddMediaList(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<TeacherAddRoomMediaResponse> {
            return localVarFp.teacherAddMediaList(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherAddRoomCheckInMedia(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.teacherAddRoomCheckInMedia(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {{ [key: string]: object; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCheckOutMultipleStudents(sessionId: string, requestBody?: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.teacherCheckOutMultipleStudents(sessionId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCheckinCheckoutRoom(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<TeacherCheckInRoomResponse> {
            return localVarFp.teacherCheckinCheckoutRoom(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCheckoutSingleStudent(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.teacherCheckoutSingleStudent(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminCreateOrUpdateMessageRequest} adminCreateOrUpdateMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCountParentMessageRecipient(adminCreateOrUpdateMessageRequest: AdminCreateOrUpdateMessageRequest, options?: any): AxiosPromise<number> {
            return localVarFp.teacherCountParentMessageRecipient(adminCreateOrUpdateMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDeletePhotoUpdates(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.teacherDeletePhotoUpdates(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherEditCheckInRoom(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<TeacherCheckInRoomResponse> {
            return localVarFp.teacherEditCheckInRoom(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherEditMediaList(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<TeacherAddRoomMediaResponse> {
            return localVarFp.teacherEditMediaList(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherEditPhotoUpdates(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.teacherEditPhotoUpdates(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherEditRoomCheckInMedia(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.teacherEditRoomCheckInMedia(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetAvailableRooms(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<Array<AvailableRoomsResponse>> {
            return localVarFp.teacherGetAvailableRooms(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetCategoriesForSendMessage(options?: any): AxiosPromise<AdminGetCategoryParentMessageListResponse> {
            return localVarFp.teacherGetCategoriesForSendMessage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetClassesForSendMessage(options?: any): AxiosPromise<AdminGetClassesForMessageResponse> {
            return localVarFp.teacherGetClassesForSendMessage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetCourseClasses(courseId: string, options?: any): AxiosPromise<CourseClassesResponse> {
            return localVarFp.teacherGetCourseClasses(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetCourseInstances(options?: any): AxiosPromise<Array<CourseInstancesDto>> {
            return localVarFp.teacherGetCourseInstances(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetCourseInstancesForSendMessage(options?: any): AxiosPromise<AdminGetCourseInstancesForMessageResponse> {
            return localVarFp.teacherGetCourseInstancesForSendMessage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetParentInfo(id: string, options?: any): AxiosPromise<GetUserInfoResponse> {
            return localVarFp.teacherGetParentInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetParentsForSendMessage(options?: any): AxiosPromise<AdminGetParentsForMessageResponse> {
            return localVarFp.teacherGetParentsForSendMessage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetPhotoUpdateDetails(messageId: string, options?: any): AxiosPromise<TeacherPhotoUpdateDetailResponse> {
            return localVarFp.teacherGetPhotoUpdateDetails(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetPresignedPutURL(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetPresignedPutURLResponse> {
            return localVarFp.teacherGetPresignedPutURL(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetRoomCheckIn(courseSessionId: string, options?: any): AxiosPromise<TeacherGetRoomCheckinResponse> {
            return localVarFp.teacherGetRoomCheckIn(courseSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetStudentNotes(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<TeacherPostStudentNotesResponse> {
            return localVarFp.teacherGetStudentNotes(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherGetStudentsByParentIdForSendMessage(options?: any): AxiosPromise<AdminGetStudentsForMessageResponse> {
            return localVarFp.teacherGetStudentsByParentIdForSendMessage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherPostPhotoUpdateList(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<TeacherPhotoUpdateResponse> {
            return localVarFp.teacherPostPhotoUpdateList(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherSaveDeviceToken(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.teacherSaveDeviceToken(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminCreateOrUpdateMessageRequest} adminCreateOrUpdateMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherSendPhotoUpdates(adminCreateOrUpdateMessageRequest: AdminCreateOrUpdateMessageRequest, options?: any): AxiosPromise<string> {
            return localVarFp.teacherSendPhotoUpdates(adminCreateOrUpdateMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentCourseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUndoCheckin(studentCourseSessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.teacherUndoCheckin(studentCourseSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentCourseSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUndoCheckout(studentCourseSessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.teacherUndoCheckout(studentCourseSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherVenuesSelect(options?: any): AxiosPromise<AdminGetSelectResponse> {
            return localVarFp.teacherVenuesSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAttendance(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<boolean> {
            return localVarFp.updateAttendance(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileByTeacher(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<string> {
            return localVarFp.uploadFileByTeacher(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeachersApi - object-oriented interface
 * @export
 * @class TeachersApi
 * @extends {BaseAPI}
 */
export class TeachersApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public addMessageGroupByTeacher(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).addMessageGroupByTeacher(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public createOrUpdateRoomBookingByTeacher(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).createOrUpdateRoomBookingByTeacher(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public getAgoraInfo(options?: any) {
        return TeachersApiFp(this.configuration).getAgoraInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseInstanceId 
     * @param {string} courseSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public getAttendances(courseInstanceId: string, courseSessionId: string, options?: any) {
        return TeachersApiFp(this.configuration).getAttendances(courseInstanceId, courseSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} messageGroupId 
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public getConversationDetailForTeacher(messageGroupId: string, offset?: number, limit?: number, options?: any) {
        return TeachersApiFp(this.configuration).getConversationDetailForTeacher(messageGroupId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public getConversationListForTeacher(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).getConversationListForTeacher(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public getCourseDetails(courseInstanceId: string, options?: any) {
        return TeachersApiFp(this.configuration).getCourseDetails(courseInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public getCourseSessionsByDate(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).getCourseSessionsByDate(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public getCourseSessionsByDateDeprecated(date: string, options?: any) {
        return TeachersApiFp(this.configuration).getCourseSessionsByDateDeprecated(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public getCourseSessionsById(id: string, options?: any) {
        return TeachersApiFp(this.configuration).getCourseSessionsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {string} [courseInstanceIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public getStudents(search?: string, courseInstanceIds?: string, options?: any) {
        return TeachersApiFp(this.configuration).getStudents(search, courseInstanceIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public getTeacherInfo(options?: any) {
        return TeachersApiFp(this.configuration).getTeacherInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public seenMessages(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).seenMessages(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public sendBroadcast(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).sendBroadcast(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public sendMessage(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).sendMessage(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherAddMediaList(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherAddMediaList(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherAddRoomCheckInMedia(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherAddRoomCheckInMedia(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {{ [key: string]: object; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherCheckOutMultipleStudents(sessionId: string, requestBody?: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherCheckOutMultipleStudents(sessionId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherCheckinCheckoutRoom(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherCheckinCheckoutRoom(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherCheckoutSingleStudent(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherCheckoutSingleStudent(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminCreateOrUpdateMessageRequest} adminCreateOrUpdateMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherCountParentMessageRecipient(adminCreateOrUpdateMessageRequest: AdminCreateOrUpdateMessageRequest, options?: any) {
        return TeachersApiFp(this.configuration).teacherCountParentMessageRecipient(adminCreateOrUpdateMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherDeletePhotoUpdates(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherDeletePhotoUpdates(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherEditCheckInRoom(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherEditCheckInRoom(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherEditMediaList(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherEditMediaList(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherEditPhotoUpdates(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherEditPhotoUpdates(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherEditRoomCheckInMedia(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherEditRoomCheckInMedia(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherGetAvailableRooms(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherGetAvailableRooms(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherGetCategoriesForSendMessage(options?: any) {
        return TeachersApiFp(this.configuration).teacherGetCategoriesForSendMessage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherGetClassesForSendMessage(options?: any) {
        return TeachersApiFp(this.configuration).teacherGetClassesForSendMessage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherGetCourseClasses(courseId: string, options?: any) {
        return TeachersApiFp(this.configuration).teacherGetCourseClasses(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherGetCourseInstances(options?: any) {
        return TeachersApiFp(this.configuration).teacherGetCourseInstances(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherGetCourseInstancesForSendMessage(options?: any) {
        return TeachersApiFp(this.configuration).teacherGetCourseInstancesForSendMessage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherGetParentInfo(id: string, options?: any) {
        return TeachersApiFp(this.configuration).teacherGetParentInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherGetParentsForSendMessage(options?: any) {
        return TeachersApiFp(this.configuration).teacherGetParentsForSendMessage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherGetPhotoUpdateDetails(messageId: string, options?: any) {
        return TeachersApiFp(this.configuration).teacherGetPhotoUpdateDetails(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherGetPresignedPutURL(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherGetPresignedPutURL(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherGetRoomCheckIn(courseSessionId: string, options?: any) {
        return TeachersApiFp(this.configuration).teacherGetRoomCheckIn(courseSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherGetStudentNotes(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherGetStudentNotes(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherGetStudentsByParentIdForSendMessage(options?: any) {
        return TeachersApiFp(this.configuration).teacherGetStudentsByParentIdForSendMessage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherPostPhotoUpdateList(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherPostPhotoUpdateList(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherSaveDeviceToken(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).teacherSaveDeviceToken(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminCreateOrUpdateMessageRequest} adminCreateOrUpdateMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherSendPhotoUpdates(adminCreateOrUpdateMessageRequest: AdminCreateOrUpdateMessageRequest, options?: any) {
        return TeachersApiFp(this.configuration).teacherSendPhotoUpdates(adminCreateOrUpdateMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentCourseSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherUndoCheckin(studentCourseSessionId: string, options?: any) {
        return TeachersApiFp(this.configuration).teacherUndoCheckin(studentCourseSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentCourseSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherUndoCheckout(studentCourseSessionId: string, options?: any) {
        return TeachersApiFp(this.configuration).teacherUndoCheckout(studentCourseSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public teacherVenuesSelect(options?: any) {
        return TeachersApiFp(this.configuration).teacherVenuesSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public updateAttendance(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).updateAttendance(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public uploadFileByTeacher(requestBody: { [key: string]: object; }, options?: any) {
        return TeachersApiFp(this.configuration).uploadFileByTeacher(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TicketsApi - axios parameter creator
 * @export
 */
export const TicketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTicketStatus: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('changeTicketStatus', 'requestBody', requestBody)
            const localVarPath = `/tickets/change-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAllUnreadTickets: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets/clear-all-unread-tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAbsenceNotice: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createAbsenceNotice', 'requestBody', requestBody)
            const localVarPath = `/tickets/absence-notices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createTicket', 'requestBody', requestBody)
            const localVarPath = `/tickets/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {TicketStatusFilteringEnum} [status] 
         * @param {string} [businessUnitId] 
         * @param {string} [teacherId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Array<TicketStatusFilteringEnum>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTickets: async (search?: string, status?: TicketStatusFilteringEnum, businessUnitId?: string, teacherId?: string, limit?: number, offset?: number, statuses?: Array<TicketStatusFilteringEnum>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (businessUnitId !== undefined) {
                localVarQueryParameter['business_unit_id'] = businessUnitId;
            }

            if (teacherId !== undefined) {
                localVarQueryParameter['teacher_id'] = teacherId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookedCourseForCreateTicket: async (studentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('getBookedCourseForCreateTicket', 'studentId', studentId)
            const localVarPath = `/tickets/booked-courses/{studentId}`
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketDetailById: async (ticketId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getTicketDetailById', 'ticketId', ticketId)
            const localVarPath = `/tickets/details/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketFilters: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets/ticket-filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadTicketsCount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets/unread-ticket-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTicket: async (ticketId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('readTicket', 'ticketId', ticketId)
            const localVarPath = `/tickets/read-ticket/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replyTicket: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('replyTicket', 'requestBody', requestBody)
            const localVarPath = `/tickets/reply-ticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketsApi - functional programming interface
 * @export
 */
export const TicketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeTicketStatus(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeTicketStatus(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearAllUnreadTickets(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearAllUnreadTickets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAbsenceNotice(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAbsenceNotice(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTicket(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTicket(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {TicketStatusFilteringEnum} [status] 
         * @param {string} [businessUnitId] 
         * @param {string} [teacherId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Array<TicketStatusFilteringEnum>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTickets(search?: string, status?: TicketStatusFilteringEnum, businessUnitId?: string, teacherId?: string, limit?: number, offset?: number, statuses?: Array<TicketStatusFilteringEnum>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllTicketsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTickets(search, status, businessUnitId, teacherId, limit, offset, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookedCourseForCreateTicket(studentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBookedCoursesForCreateTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookedCourseForCreateTicket(studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketDetailById(ticketId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTicketByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketDetailById(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketFilters(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTicketFilterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketFilters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadTicketsCount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUnreadTicketsCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadTicketsCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTicket(ticketId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTicket(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replyTicket(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replyTicket(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketsApi - factory interface
 * @export
 */
export const TicketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketsApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTicketStatus(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<ChangeTicketResponse> {
            return localVarFp.changeTicketStatus(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAllUnreadTickets(options?: any): AxiosPromise<void> {
            return localVarFp.clearAllUnreadTickets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAbsenceNotice(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.createAbsenceNotice(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<CreateTicketResponse> {
            return localVarFp.createTicket(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {TicketStatusFilteringEnum} [status] 
         * @param {string} [businessUnitId] 
         * @param {string} [teacherId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Array<TicketStatusFilteringEnum>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTickets(search?: string, status?: TicketStatusFilteringEnum, businessUnitId?: string, teacherId?: string, limit?: number, offset?: number, statuses?: Array<TicketStatusFilteringEnum>, options?: any): AxiosPromise<GetAllTicketsResponse> {
            return localVarFp.getAllTickets(search, status, businessUnitId, teacherId, limit, offset, statuses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookedCourseForCreateTicket(studentId: string, options?: any): AxiosPromise<GetBookedCoursesForCreateTicketResponse> {
            return localVarFp.getBookedCourseForCreateTicket(studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketDetailById(ticketId: string, options?: any): AxiosPromise<GetTicketByIdResponse> {
            return localVarFp.getTicketDetailById(ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketFilters(options?: any): AxiosPromise<GetTicketFilterResponse> {
            return localVarFp.getTicketFilters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadTicketsCount(options?: any): AxiosPromise<GetUnreadTicketsCountResponse> {
            return localVarFp.getUnreadTicketsCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTicket(ticketId: string, options?: any): AxiosPromise<void> {
            return localVarFp.readTicket(ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replyTicket(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.replyTicket(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketsApi - object-oriented interface
 * @export
 * @class TicketsApi
 * @extends {BaseAPI}
 */
export class TicketsApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public changeTicketStatus(requestBody: { [key: string]: object; }, options?: any) {
        return TicketsApiFp(this.configuration).changeTicketStatus(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public clearAllUnreadTickets(options?: any) {
        return TicketsApiFp(this.configuration).clearAllUnreadTickets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public createAbsenceNotice(requestBody: { [key: string]: object; }, options?: any) {
        return TicketsApiFp(this.configuration).createAbsenceNotice(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public createTicket(requestBody: { [key: string]: object; }, options?: any) {
        return TicketsApiFp(this.configuration).createTicket(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {TicketStatusFilteringEnum} [status] 
     * @param {string} [businessUnitId] 
     * @param {string} [teacherId] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {Array<TicketStatusFilteringEnum>} [statuses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getAllTickets(search?: string, status?: TicketStatusFilteringEnum, businessUnitId?: string, teacherId?: string, limit?: number, offset?: number, statuses?: Array<TicketStatusFilteringEnum>, options?: any) {
        return TicketsApiFp(this.configuration).getAllTickets(search, status, businessUnitId, teacherId, limit, offset, statuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getBookedCourseForCreateTicket(studentId: string, options?: any) {
        return TicketsApiFp(this.configuration).getBookedCourseForCreateTicket(studentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ticketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getTicketDetailById(ticketId: string, options?: any) {
        return TicketsApiFp(this.configuration).getTicketDetailById(ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getTicketFilters(options?: any) {
        return TicketsApiFp(this.configuration).getTicketFilters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getUnreadTicketsCount(options?: any) {
        return TicketsApiFp(this.configuration).getUnreadTicketsCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ticketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public readTicket(ticketId: string, options?: any) {
        return TicketsApiFp(this.configuration).readTicket(ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public replyTicket(requestBody: { [key: string]: object; }, options?: any) {
        return TicketsApiFp(this.configuration).replyTicket(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UploadsApi - axios parameter creator
 * @export
 */
export const UploadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToMedia: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addToMedia', 'requestBody', requestBody)
            const localVarPath = `/uploads/add-to-media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresignedPutURL: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getPresignedPutURL', 'requestBody', requestBody)
            const localVarPath = `/uploads/get-presigned-put-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoadFile: async (file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('updateLoadFile', 'file', file)
            const localVarPath = `/uploads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadsApi - functional programming interface
 * @export
 */
export const UploadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToMedia(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddToMediaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addToMedia(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresignedPutURL(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPresignedPutURLResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresignedPutURL(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLoadFile(file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLoadFile(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadsApi - factory interface
 * @export
 */
export const UploadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadsApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToMedia(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<AddToMediaResponse> {
            return localVarFp.addToMedia(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresignedPutURL(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<GetPresignedPutURLResponse> {
            return localVarFp.getPresignedPutURL(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoadFile(file: any, options?: any): AxiosPromise<string> {
            return localVarFp.updateLoadFile(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadsApi - object-oriented interface
 * @export
 * @class UploadsApi
 * @extends {BaseAPI}
 */
export class UploadsApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public addToMedia(requestBody: { [key: string]: object; }, options?: any) {
        return UploadsApiFp(this.configuration).addToMedia(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public getPresignedPutURL(requestBody: { [key: string]: object; }, options?: any) {
        return UploadsApiFp(this.configuration).getPresignedPutURL(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public updateLoadFile(file: any, options?: any) {
        return UploadsApiFp(this.configuration).updateLoadFile(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserCardsApi - axios parameter creator
 * @export
 */
export const UserCardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCard: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserCardsApi - functional programming interface
 * @export
 */
export const UserCardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserCardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCard(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserCardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCard(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserCardsApi - factory interface
 * @export
 */
export const UserCardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserCardsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCard(options?: any): AxiosPromise<GetUserCardResponse> {
            return localVarFp.getUserCard(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserCardsApi - object-oriented interface
 * @export
 * @class UserCardsApi
 * @extends {BaseAPI}
 */
export class UserCardsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCardsApi
     */
    public getUserCard(options?: any) {
        return UserCardsApiFp(this.configuration).getUserCard(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserSegmentApi - axios parameter creator
 * @export
 */
export const UserSegmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InlineObject12} inlineObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserSegment: async (inlineObject12: InlineObject12, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject12' is not null or undefined
            assertParamExists('createUserSegment', 'inlineObject12', inlineObject12)
            const localVarPath = `/user-segment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject12, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isExist: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('isExist', 'requestBody', requestBody)
            const localVarPath = `/user-segment/is-exist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSegmentApi - functional programming interface
 * @export
 */
export const UserSegmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserSegmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InlineObject12} inlineObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserSegment(inlineObject12: InlineObject12, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserSegment(inlineObject12, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isExist(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isExist(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserSegmentApi - factory interface
 * @export
 */
export const UserSegmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserSegmentApiFp(configuration)
    return {
        /**
         * 
         * @param {InlineObject12} inlineObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserSegment(inlineObject12: InlineObject12, options?: any): AxiosPromise<void> {
            return localVarFp.createUserSegment(inlineObject12, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isExist(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<boolean> {
            return localVarFp.isExist(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserSegmentApi - object-oriented interface
 * @export
 * @class UserSegmentApi
 * @extends {BaseAPI}
 */
export class UserSegmentApi extends BaseAPI {
    /**
     * 
     * @param {InlineObject12} inlineObject12 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSegmentApi
     */
    public createUserSegment(inlineObject12: InlineObject12, options?: any) {
        return UserSegmentApiFp(this.configuration).createUserSegment(inlineObject12, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSegmentApi
     */
    public isExist(requestBody: { [key: string]: object; }, options?: any) {
        return UserSegmentApiFp(this.configuration).isExist(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addChildren: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addChildren', 'requestBody', requestBody)
            const localVarPath = `/users/add-children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} secretKey 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalAttendanceReportToSpreadSheet: async (secretKey: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'secretKey' is not null or undefined
            assertParamExists('additionalAttendanceReportToSpreadSheet', 'secretKey', secretKey)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('additionalAttendanceReportToSpreadSheet', 'requestBody', requestBody)
            const localVarPath = `/users/additional-attendance-report-spreadsheet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (secretKey !== undefined && secretKey !== null) {
                localVarHeaderParameter['secretKey'] = String(secretKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExistUserEmail: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('checkExistUserEmail', 'email', email)
            const localVarPath = `/users/is-exist-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChildById: async (childId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('deleteChildById', 'childId', childId)
            const localVarPath = `/users/delete-child/{childId}`
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} secretKey 
         * @param {DynamicFunctionNameEnum} functionName 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeDynamicFunction: async (secretKey: string, functionName: DynamicFunctionNameEnum, body: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'secretKey' is not null or undefined
            assertParamExists('executeDynamicFunction', 'secretKey', secretKey)
            // verify required parameter 'functionName' is not null or undefined
            assertParamExists('executeDynamicFunction', 'functionName', functionName)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('executeDynamicFunction', 'body', body)
            const localVarPath = `/users/execute-dynamic-function`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (functionName !== undefined) {
                localVarQueryParameter['functionName'] = functionName;
            }

            if (secretKey !== undefined && secretKey !== null) {
                localVarHeaderParameter['secretKey'] = String(secretKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcceptedTerms: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/accepted-terms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgeGroup: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/age-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookmarks: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBookmarks', 'id', id)
            const localVarPath = `/users/bookmarks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [schoolId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildren: async (schoolId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/children-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (schoolId !== undefined) {
                localVarQueryParameter['schoolId'] = schoolId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenListForRegistration: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/registrations/get-children-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationTeacherList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/conversation-teacher-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/current-user-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardians: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGuardians', 'id', id)
            const localVarPath = `/users/{id}/guardians`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneChildren: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneChildren', 'id', id)
            const localVarPath = `/users/children/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPastTransactions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/past-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefundHistories: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/refund-histories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchoolForChildren: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/childrens/schools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachers: async (parentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getTeachers', 'parentId', parentId)
            const localVarPath = `/users/teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCards: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/user-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserInfo', 'id', id)
            const localVarPath = `/users/info/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inviteCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoByInviteCode: async (inviteCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteCode' is not null or undefined
            assertParamExists('getUserInfoByInviteCode', 'inviteCode', inviteCode)
            const localVarPath = `/users/info-by-invite-code/{inviteCode}`
                .replace(`{${"inviteCode"}}`, encodeURIComponent(String(inviteCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSubscriptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/subscriptions-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegistrationRequest} registrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRequestRegistration: async (registrationRequest: RegistrationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationRequest' is not null or undefined
            assertParamExists('handleRequestRegistration', 'registrationRequest', registrationRequest)
            const localVarPath = `/users/parents/registrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddAlertStatus: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postAddAlertStatus', 'requestBody', requestBody)
            const localVarPath = `/users/alert/course-instance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddBookmark: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postAddBookmark', 'requestBody', requestBody)
            const localVarPath = `/users/bookmark/course-instance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdateParentInfo: async (id: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postUpdateParentInfo', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('postUpdateParentInfo', 'requestBody', requestBody)
            const localVarPath = `/users/information/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} date 
         * @param {string} secretKey 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runSubscription: async (date: any, secretKey: string, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('runSubscription', 'date', date)
            // verify required parameter 'secretKey' is not null or undefined
            assertParamExists('runSubscription', 'secretKey', secretKey)
            const localVarPath = `/users/run-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (secretKey !== undefined && secretKey !== null) {
                localVarHeaderParameter['secretKey'] = String(secretKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} packageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncFirebasePackage: async (packageName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageName' is not null or undefined
            assertParamExists('syncFirebasePackage', 'packageName', packageName)
            const localVarPath = `/users/sync-package-from-s3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (packageName !== undefined) {
                localVarQueryParameter['packageName'] = packageName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAndSendMailCreditExpired: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/credit-expired`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildren: async (id: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateChildren', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateChildren', 'requestBody', requestBody)
            const localVarPath = `/users/update-children/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateGradeLevel: async (childId: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('validateGradeLevel', 'childId', childId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('validateGradeLevel', 'requestBody', requestBody)
            const localVarPath = `/users/children/{childId}`
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegistrationRequest} registrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateStudentBeforeRegistration: async (registrationRequest: RegistrationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationRequest' is not null or undefined
            assertParamExists('validateStudentBeforeRegistration', 'registrationRequest', registrationRequest)
            const localVarPath = `/users/registrations/validate-before-registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addChildren(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addChildren(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} secretKey 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async additionalAttendanceReportToSpreadSheet(secretKey: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.additionalAttendanceReportToSpreadSheet(secretKey, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkExistUserEmail(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkExistUserEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChildById(childId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteChildById(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} secretKey 
         * @param {DynamicFunctionNameEnum} functionName 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeDynamicFunction(secretKey: string, functionName: DynamicFunctionNameEnum, body: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeDynamicFunction(secretKey, functionName, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAcceptedTerms(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAcceptedTermsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAcceptedTerms(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgeGroup(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgeGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgeGroup(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookmarks(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCoursesWithResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookmarks(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [schoolId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildren(schoolId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildren(schoolId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildrenListForRegistration(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChildrenListForRegistrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildrenListForRegistration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationTeacherList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTeacherConversationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationTeacherList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUserInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCurrentUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuardians(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuardians(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneChildren(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChildrenInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneChildren(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPastTransactions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPastTransactionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPastTransactions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRefundHistories(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRefundHistoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRefundHistories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchoolForChildren(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSchoolForChildResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchoolForChildren(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeachers(parentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTeachersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeachers(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCards(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserCardsByUserIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCards(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inviteCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfoByInviteCode(inviteCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfoByInviteCode(inviteCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSubscriptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserSubscriptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSubscriptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegistrationRequest} registrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleRequestRegistration(registrationRequest: RegistrationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleRequestRegistration(registrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddAlertStatus(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddAlertStatus(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddBookmark(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddBookmark(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUpdateParentInfo(id: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUpdateParentInfo(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} date 
         * @param {string} secretKey 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runSubscription(date: any, secretKey: string, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runSubscription(date, secretKey, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} packageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncFirebasePackage(packageName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncFirebasePackage(packageName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAndSendMailCreditExpired(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAndSendMailCreditExpired(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChildren(id: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChildren(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateGradeLevel(childId: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateGradeLevel(childId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegistrationRequest} registrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateStudentBeforeRegistration(registrationRequest: RegistrationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateStudentBeforeRegistrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateStudentBeforeRegistration(registrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addChildren(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.addChildren(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} secretKey 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalAttendanceReportToSpreadSheet(secretKey: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.additionalAttendanceReportToSpreadSheet(secretKey, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExistUserEmail(email: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.checkExistUserEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChildById(childId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteChildById(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} secretKey 
         * @param {DynamicFunctionNameEnum} functionName 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeDynamicFunction(secretKey: string, functionName: DynamicFunctionNameEnum, body: any, options?: any): AxiosPromise<void> {
            return localVarFp.executeDynamicFunction(secretKey, functionName, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcceptedTerms(options?: any): AxiosPromise<GetAcceptedTermsResponse> {
            return localVarFp.getAcceptedTerms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgeGroup(options?: any): AxiosPromise<GetAgeGroupResponse> {
            return localVarFp.getAgeGroup(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookmarks(id: string, options?: any): AxiosPromise<GetCoursesWithResponse> {
            return localVarFp.getBookmarks(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [schoolId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildren(schoolId?: string, options?: any): AxiosPromise<GetUserInfoResponse> {
            return localVarFp.getChildren(schoolId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenListForRegistration(options?: any): AxiosPromise<GetChildrenListForRegistrationResponse> {
            return localVarFp.getChildrenListForRegistration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationTeacherList(options?: any): AxiosPromise<GetTeacherConversationResponse> {
            return localVarFp.getConversationTeacherList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserInfo(options?: any): AxiosPromise<GetCurrentUserResponse> {
            return localVarFp.getCurrentUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardians(id: string, options?: any): AxiosPromise<GetUserInfoResponse> {
            return localVarFp.getGuardians(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneChildren(id: string, options?: any): AxiosPromise<GetChildrenInfoResponse> {
            return localVarFp.getOneChildren(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentInfo(options?: any): AxiosPromise<GetUserInfoResponse> {
            return localVarFp.getParentInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPastTransactions(options?: any): AxiosPromise<GetPastTransactionsResponse> {
            return localVarFp.getPastTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefundHistories(options?: any): AxiosPromise<GetRefundHistoriesResponse> {
            return localVarFp.getRefundHistories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchoolForChildren(options?: any): AxiosPromise<GetSchoolForChildResponse> {
            return localVarFp.getSchoolForChildren(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachers(parentId: string, options?: any): AxiosPromise<GetTeachersResponse> {
            return localVarFp.getTeachers(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCards(options?: any): AxiosPromise<GetUserCardsByUserIdResponse> {
            return localVarFp.getUserCards(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(id: string, options?: any): AxiosPromise<GetUserInfoResponse> {
            return localVarFp.getUserInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inviteCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoByInviteCode(inviteCode: string, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getUserInfoByInviteCode(inviteCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSubscriptions(options?: any): AxiosPromise<GetUserSubscriptionsResponse> {
            return localVarFp.getUserSubscriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegistrationRequest} registrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRequestRegistration(registrationRequest: RegistrationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.handleRequestRegistration(registrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddAlertStatus(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<string> {
            return localVarFp.postAddAlertStatus(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddBookmark(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<string> {
            return localVarFp.postAddBookmark(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdateParentInfo(id: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.postUpdateParentInfo(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} date 
         * @param {string} secretKey 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runSubscription(date: any, secretKey: string, userId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.runSubscription(date, secretKey, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} packageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncFirebasePackage(packageName: string, options?: any): AxiosPromise<void> {
            return localVarFp.syncFirebasePackage(packageName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAndSendMailCreditExpired(options?: any): AxiosPromise<void> {
            return localVarFp.updateAndSendMailCreditExpired(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildren(id: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.updateChildren(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateGradeLevel(childId: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<boolean> {
            return localVarFp.validateGradeLevel(childId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegistrationRequest} registrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateStudentBeforeRegistration(registrationRequest: RegistrationRequest, options?: any): AxiosPromise<ValidateStudentBeforeRegistrationResponse> {
            return localVarFp.validateStudentBeforeRegistration(registrationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addChildren(requestBody: { [key: string]: object; }, options?: any) {
        return UsersApiFp(this.configuration).addChildren(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} secretKey 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public additionalAttendanceReportToSpreadSheet(secretKey: string, requestBody: { [key: string]: object; }, options?: any) {
        return UsersApiFp(this.configuration).additionalAttendanceReportToSpreadSheet(secretKey, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public checkExistUserEmail(email: string, options?: any) {
        return UsersApiFp(this.configuration).checkExistUserEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteChildById(childId: string, options?: any) {
        return UsersApiFp(this.configuration).deleteChildById(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} secretKey 
     * @param {DynamicFunctionNameEnum} functionName 
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public executeDynamicFunction(secretKey: string, functionName: DynamicFunctionNameEnum, body: any, options?: any) {
        return UsersApiFp(this.configuration).executeDynamicFunction(secretKey, functionName, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAcceptedTerms(options?: any) {
        return UsersApiFp(this.configuration).getAcceptedTerms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAgeGroup(options?: any) {
        return UsersApiFp(this.configuration).getAgeGroup(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getBookmarks(id: string, options?: any) {
        return UsersApiFp(this.configuration).getBookmarks(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [schoolId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getChildren(schoolId?: string, options?: any) {
        return UsersApiFp(this.configuration).getChildren(schoolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getChildrenListForRegistration(options?: any) {
        return UsersApiFp(this.configuration).getChildrenListForRegistration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getConversationTeacherList(options?: any) {
        return UsersApiFp(this.configuration).getConversationTeacherList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getCurrentUserInfo(options?: any) {
        return UsersApiFp(this.configuration).getCurrentUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getGuardians(id: string, options?: any) {
        return UsersApiFp(this.configuration).getGuardians(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getOneChildren(id: string, options?: any) {
        return UsersApiFp(this.configuration).getOneChildren(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getParentInfo(options?: any) {
        return UsersApiFp(this.configuration).getParentInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getPastTransactions(options?: any) {
        return UsersApiFp(this.configuration).getPastTransactions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getRefundHistories(options?: any) {
        return UsersApiFp(this.configuration).getRefundHistories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getSchoolForChildren(options?: any) {
        return UsersApiFp(this.configuration).getSchoolForChildren(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getTeachers(parentId: string, options?: any) {
        return UsersApiFp(this.configuration).getTeachers(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserCards(options?: any) {
        return UsersApiFp(this.configuration).getUserCards(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserInfo(id: string, options?: any) {
        return UsersApiFp(this.configuration).getUserInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inviteCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserInfoByInviteCode(inviteCode: string, options?: any) {
        return UsersApiFp(this.configuration).getUserInfoByInviteCode(inviteCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserSubscriptions(options?: any) {
        return UsersApiFp(this.configuration).getUserSubscriptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegistrationRequest} registrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public handleRequestRegistration(registrationRequest: RegistrationRequest, options?: any) {
        return UsersApiFp(this.configuration).handleRequestRegistration(registrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postAddAlertStatus(requestBody: { [key: string]: object; }, options?: any) {
        return UsersApiFp(this.configuration).postAddAlertStatus(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postAddBookmark(requestBody: { [key: string]: object; }, options?: any) {
        return UsersApiFp(this.configuration).postAddBookmark(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postUpdateParentInfo(id: string, requestBody: { [key: string]: object; }, options?: any) {
        return UsersApiFp(this.configuration).postUpdateParentInfo(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} date 
     * @param {string} secretKey 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public runSubscription(date: any, secretKey: string, userId?: string, options?: any) {
        return UsersApiFp(this.configuration).runSubscription(date, secretKey, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} packageName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public syncFirebasePackage(packageName: string, options?: any) {
        return UsersApiFp(this.configuration).syncFirebasePackage(packageName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateAndSendMailCreditExpired(options?: any) {
        return UsersApiFp(this.configuration).updateAndSendMailCreditExpired(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateChildren(id: string, requestBody: { [key: string]: object; }, options?: any) {
        return UsersApiFp(this.configuration).updateChildren(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public validateGradeLevel(childId: string, requestBody: { [key: string]: object; }, options?: any) {
        return UsersApiFp(this.configuration).validateGradeLevel(childId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegistrationRequest} registrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public validateStudentBeforeRegistration(registrationRequest: RegistrationRequest, options?: any) {
        return UsersApiFp(this.configuration).validateStudentBeforeRegistration(registrationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ZendeskApi - axios parameter creator
 * @export
 */
export const ZendeskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLead: async (requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createLead', 'requestBody', requestBody)
            const localVarPath = `/zendesk/create-lead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZendeskApi - functional programming interface
 * @export
 */
export const ZendeskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZendeskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLead(requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLead(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZendeskApi - factory interface
 * @export
 */
export const ZendeskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZendeskApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLead(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.createLead(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZendeskApi - object-oriented interface
 * @export
 * @class ZendeskApi
 * @extends {BaseAPI}
 */
export class ZendeskApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZendeskApi
     */
    public createLead(requestBody: { [key: string]: object; }, options?: any) {
        return ZendeskApiFp(this.configuration).createLead(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


