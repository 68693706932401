







import { Component, Vue } from 'nuxt-property-decorator'
import ElfsightWidget from '~/components/ElfsightWidget.vue'

@Component({
  components: {
    ElfsightWidget
  }
})
export default class InvoiceLayout extends Vue {
}

