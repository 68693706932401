import { render, staticRenderFns } from "./auth.vue?vue&type=template&id=3efe3cf2&scoped=true&"
import script from "./auth.vue?vue&type=script&lang=ts&"
export * from "./auth.vue?vue&type=script&lang=ts&"
import style0 from "./auth.vue?vue&type=style&index=0&id=3efe3cf2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3efe3cf2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElfsightWidget: require('/var/www/dockerize-nuxt/agora-app/components/ElfsightWidget.vue').default})
