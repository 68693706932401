import { extend } from 'vee-validate'
import { required, alpha, email, min, regex, confirmed, length, digits, max } from 'vee-validate/dist/rules'
import { validationPassword } from '~/utils/functions'

extend('required', {
  ...required,
  message: (field) => {
    if (field === 'auth-email') {
      return 'Please input email'
    }
    const message = `Please input ${field !== '{field}' ? field : 'date-of-birth'}`
    return message.replace(/-/g, ' ')
  }
})

extend('select', {
  ...required,
  message: field => `Please select ${field}`
})

extend('alpha', {
  ...alpha,
  message: 'This field must only contain alphabetic characters'
})

extend('email', {
  ...email,
  message: (field) => {
    if (field === 'auth-email') {
      return 'Enter a valid email ID'
    }
    return 'Email is incorrect.'
  }
})

extend('min', {
  ...min,
  message: '{_field_} must have at least {length} characters'
})

extend('max', {
  ...max,
  message: '{_field_} should not exceed the max length {length}'
})

extend('password', {
  params: ['isSignIn'],
  validate: (value: string, params: any) => {
    const validateMessage = validationPassword(value)
    const message = validateMessage && params.isSignIn ? 'Password format is invalid' : validateMessage
    return message || true
  }
})

extend('regex', {
  ...regex,
  message: (field) => {
    return `${field} format is invalid`
  }
})

extend('confirmed', {
  ...confirmed,
  message: '{_field_} does not match'
})

extend('length', {
  ...length,
  message: '{_field_} must be {length} characters'
})

extend('digits', {
  ...digits,
  message: '{_field_} must be numeric and exactly contain {length} digits'
})
