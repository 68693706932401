import moment from 'moment'
import Swal from 'sweetalert2'
import { capitalize } from 'lodash'
import { ToastConfig } from '~/types/common'

export const AVATAR_LIST:string [] = [
  'https://agora-dev-resource.s3.ap-southeast-1.amazonaws.com/media/c1.png',
  'https://agora-dev-resource.s3.ap-southeast-1.amazonaws.com/media/c2.png',
  'https://agora-dev-resource.s3.ap-southeast-1.amazonaws.com/media/c3.png',
  'https://agora-dev-resource.s3.ap-southeast-1.amazonaws.com/media/c4.png',
  'https://agora-dev-resource.s3.ap-southeast-1.amazonaws.com/media/c5.png'
]
export const getRandomAvatar = (avatarsUsed:string[]) :string => {
  const occurrences = [0, 0, 0, 0, 0]
  AVATAR_LIST.forEach((avatar, index) => {
    const count = avatarsUsed.filter(x => x === avatar).length
    occurrences[index] = count
  })
  let index = 0
  let value = occurrences[0]
  for (let i = 1; i < occurrences.length; i++) {
    if (occurrences[i] < value) {
      value = occurrences[i]
      index = i
    }
  }
  return AVATAR_LIST[index]
}

export const createUUID = ():string => {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: any) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

export const showToast = (config?: ToastConfig) => {
  const defaultConfig: ToastConfig = {
    position: 'top-end',
    icon: 'success',
    title: 'Success',
    text: 'Success',
    timer: 3000
  }
  Swal.fire({
    toast: true,
    showConfirmButton: false,
    ...defaultConfig,
    ...config
  })
}

export const getCourseInstanceStatusDecoration = (status: string) => {
  switch (status) {
    case 'approved':
      return {
        label: 'Available',
        color: '#6074EA'
      }
    case 'fully_booked':
      return {
        label: 'Live - fully booked',
        color: '#FB923C'
      }
    case 'currently_underway':
      return {
        label: 'Live - possible slots',
        color: '#8B5CF6'
      }
    case 'completed':
      return {
        label: 'Completed',
        color: '#FBBF24'
      }
    case 'cancelled':
      return {
        label: 'Cancelled',
        color: '#FBBF24'
      }
    default:
      return {
        label: convertVariableSqlToText(status),
        color: '#FBBF24'
      }
  }
}

export const formatCurrency = (number: string | number, isRound?: boolean, isNonPrefix?: boolean) => {
  const num = Number(number)
  let prefix = 'S$'
  if (isNonPrefix) {
    prefix = ''
  }
  if (!num) {
    return `${prefix}0`
  }
  if (isRound) {
    return prefix + Math.round(num).toLocaleString('en', { useGrouping: true })
  }
  if ((num === 0 || num % 1 === 0) && !isRound) {
    return prefix + parseFloat((num).toFixed(2)).toLocaleString('en', { useGrouping: true })
  } else {
    return prefix + parseFloat((num).toFixed(2)).toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2 })
  }
}

export const convertVariableSqlToText = (variable?: string) => {
  if (!variable) {
    return '-'
  }
  const removedUnderscore = variable.replace(/_/g, ' ')
  const capitalizeFirstLetter = removedUnderscore.charAt(0).toUpperCase() + removedUnderscore.slice(1)
  return capitalizeFirstLetter
}

export const getCourseStatus = (type: 'course' | 'instance', status: string) => {
  if (type === 'course') {
    return convertVariableSqlToText(status)
  } else if (type === 'instance') {
    switch (status) {
      case 'approved':
        return 'Available'
      case 'fully_booked':
        return 'Live - fully booked'
      case 'currently_underway':
        return 'Live - possible slots'
      case 'cancelled':
        return 'Cancelled'
      default:
        return convertVariableSqlToText(status)
    }
  }
}

export const formatPromotionDate = (date: Date | string) => {
  return moment(date).format('MMM DD')
}

export const countTouchPromotionEndDate = (date: Date | string) => {
  const now = moment(new Date())
  const end = moment(date)
  const durationDays = Math.floor(moment(date).diff(moment(), 'days', true))
  const duration = moment.duration(now.diff(end))
  return {
    days: Math.abs(durationDays),
    hours: Math.abs(duration.hours())
  }
}

export const validationPassword = (pwd: string): string => {
  const passwordErrors: string[] = []
  let pwdErrorText = ''
  if (pwd.length < 8) {
    passwordErrors.push('at least 8 characters')
  }

  if (pwd.search(/[A-Z]/) < 0) {
    passwordErrors.push('one uppercase')
  }

  if (pwd.search(/[a-z]/) < 0) {
    passwordErrors.push('one lowercase')
  }

  if (pwd.search(/[0-9]/) < 0) {
    passwordErrors.push('one number')
  }

  if (passwordErrors.length > 0) {
    pwdErrorText =
      `Password must contain ${passwordErrors.slice(0, passwordErrors.length - 1).join(', ')}${passwordErrors.length > 1 ? ' and ' : ''}${passwordErrors[passwordErrors.length - 1]}`
  }

  return pwdErrorText
}

export const findAndConvertLinkElToNewPage = (param: string) => {
  const linkEl = param.indexOf('<a href')
  if (linkEl > 0) {
    param = param.replace(/<a href/g, '<a target="_blank" href')
  }
  return param
}

export function capitalizeFirstLetter (text: string): string {
  if (!text) {
    return ''
  }
  const arr = text.split(' ')
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }
  const result = arr.join(' ')
  return result
}

export const pluralNoun = (val: string, amount: number | string) => {
  return `${val}${Number(amount) === 1 ? '' : 's'}`
}

export const getFullName = (firstName?: string, lastName?: string) => {
  const validFirstName = firstName ? firstName.trim() : ''
  const validLastName = lastName ? ` ${lastName.trim()}` : ''
  return `${validFirstName}${validLastName}`
}

export const convertToSlug = (text: string) => {
  return text.toLowerCase()
    .replace(/\s\W\s/g, '-')
    .replace(/\s/g, '-')
    .replace(/[^\w-]+/g, '')
}

export const lowerCaseText = (text: string) => {
  return text?.toLowerCase() || ''
}

export const shortenString = (text: string | undefined, maxLength: number) => {
  if (!text) {
    return ''
  }

  const trimmedString = text?.replace(/(<([^>]+)>)/gi, '')?.slice(0, maxLength)?.trim()
  return trimmedString
}

export const hoursConvert = (decimalTime: number) => {
  decimalTime = decimalTime * 60 * 60
  const hours = Math.floor((decimalTime / (60 * 60)))
  decimalTime = decimalTime - (hours * 60 * 60)
  const minutes = Math.floor((decimalTime / 60))
  let hoursString = ''

  if (hours > 0) {
    hoursString = hours === 1 ? `${hours} hr` : `${hours} hrs`
  }

  if (minutes > 0) {
    hoursString += ` ${minutes} mins`
  }

  return hoursString
}

export const convertToTimeRange = (data: string) => {
  const times = data.split('-')
  return { minTime: Number(times[0]), maxTime: Number(times[1]) }
}

export const sleep = async (milliseconds: number) => {
  return await new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const setBodyBackgroundColor = (color: string) => {
  const bodyElement = document.querySelector('body') as HTMLElement

  if (bodyElement) {
    bodyElement.style.backgroundColor = color
  }
}

export const dateFormat = (val: string, format: string): string => {
  return moment(val, "YYYY-MM-DD'T'HH:mm:ssZ")
    .format(format)
    .toString()
}

export const formatNumberRework = (input?: string | number, numOfFixed?: number, withZeroFraction?: boolean) => {
  let replacedComma = String(input || '').replace(/,/g, '')
  if (replacedComma.endsWith('.')) {
    replacedComma += '0'
  }

  const value = Number(replacedComma)

  const formattedValue = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: withZeroFraction ? 2 : 0,
    maximumFractionDigits: !numOfFixed ? 2 : numOfFixed
  }).format(value)

  return {
    value,
    label: formattedValue
  }
}

export const formatCurrencyRework = (input?: string | number, numOfFixed?: number, withZeroFraction = true) => {
  const formattedValue = formatNumberRework(input, numOfFixed, withZeroFraction)

  return {
    value: formattedValue.value,
    label: `S$ ${formattedValue.label}`
  }
}

export const formatPercentValue = (input?: string | number, numOfFixed?: number) => {
  const formattedNumber = formatNumberRework(input, numOfFixed)

  return {
    value: formattedNumber.value,
    label: `${formattedNumber.label} %`
  }
}

export function getSubscriptionStatusDecoration (status: string) {
  const statusLabel = convertVariableSqlToText(status)

  switch (status) {
    case 'draft':
      return { label: statusLabel, customStyles: 'tw-bg-gray-100 tw-text-gray-600' }
    case 'sent':
      return { label: statusLabel, customStyles: 'tw-bg-purple-100 tw-text-purple-700' }
    case 'active':
      return { label: statusLabel, customStyles: 'tw-bg-green-100 tw-text-green-700' }
    case 'failed':
      return { label: statusLabel, customStyles: 'tw-bg-yellow-100 tw-text-yellow-800' }
    case 'canceled':
      return { label: statusLabel, customStyles: 'tw-bg-red-100 tw-text-red-700' }
    default:
      return { label: statusLabel, customStyles: 'tw-bg-gray-100 tw-text-gray-600' }
  }
}

export const formatLongTextStrapi = (text: string): string => {
  return text.replace('\n', '<div class="tw-mt-3"></div>')
}

export function isEmailValid (email: string): boolean {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return emailRegex.test(email)
}

export function toValidFullName (name: string) {
  const arr = name.split(/(\s+)/).map(val => val.toLowerCase().trim()).filter(val => val)

  return arr.map(val => capitalize(val)).join(' ')
}

export function safeReturn (
  value: unknown,
  returnValueCallBack: unknown | (() => unknown),
  nullishCallBack: unknown | (() => unknown)
) {
  return value ? returnValueCallBack : nullishCallBack
}

export function getQueryParams (url: string) {
  const params: any = new URLSearchParams(url.split('?')[1])
  const queryParams: Record<string, string> = {}

  // Iterate over each key-value pair in the URLSearchParams object
  for (const [key, value] of params.entries()) {
    queryParams[key] = value
  }

  return queryParams
}

export function doValidateGoogleRecaptchaToken (callback: (token: string) => void) {
  let googleRecaptchaSiteKey = process.env.GOOGLE_RECAPTCHA_SITE_KEY_DEV

  if (process.env.NODE_ENV === 'production') {
    googleRecaptchaSiteKey = process.env.GOOGLE_RECAPTCHA_SITE_KEY_PROD
  }

  (window as any).grecaptcha.ready(function () {
    (window as any).grecaptcha.execute(`${googleRecaptchaSiteKey}`, { action: 'submit' })
      .then(function (token: string) {
        callback(token)
      })
  })
}

export const convertToArray = (value: string) => {
  try {
    return JSON.parse(value) as string[]
  } catch (error) {
    return [value]
  }
}

export const prepString = (value?: string | number | null) => {
  if (typeof value === 'string' && value) {
    return value.trim()
  }

  if (typeof value === 'number') {
    return String(value)
  }

  return '-'
}
